import React, { useEffect } from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  Typography,
  DialogContent as MuiDialogContent,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Alert } from '@material-ui/lab'
import { connect } from 'react-redux'

import { removeMembersStart } from '../../../redux/modules/teamMembers'
import ButtonSubmit from '../../common/ButtonSubmit'
import { removeActionableMember, resetMemberRemoveState } from '../../../redux/modules/teamMembers'
import { selectRemovableMembers } from '../../../redux/selectors/teamMembersSelectors'
import ButtonDialogClose from '../common/ButtonDialogClose'
import TableActionableMembers from '../common/TableActionableMembers'

const DialogContent = withStyles({
  root: {
    maxHeight: '580px',
  },
})(MuiDialogContent)

const DialogRemoveMember = ({
  removableMembers: { members, containsAdmin },
  isRemovingMembers,
  successRemovingMembers,
  errorRemovingMembers,
  removeMembersStart,
  resetMemberRemoveState,
  removeActionableMember,
  onClose,
}) => {
  useEffect(() => {
    if (successRemovingMembers) {
      resetMemberRemoveState()
      onClose()
    }
  }, [members, onClose, resetMemberRemoveState, successRemovingMembers])

  const handleSubmit = () => {
    removeMembersStart(members)
  }

  const handleClose = () => {
    resetMemberRemoveState()
    onClose()
  }

  const handleExcludeMember = member => {
    removeActionableMember(member)
  }

  return (
    <Dialog open>
      <DialogTitle>
        Remove members
        <ButtonDialogClose onClick={handleClose} />
      </DialogTitle>
      <DialogContent>
        <Box marginBottom={3}>
          <Typography>What happens when you remove team members?</Typography>
          <List dense>
            <ListItem>• The members will no longer receive any team benefits.</ListItem>
            <ListItem>• The members will still be able to order from Foodsby.</ListItem>
          </List>
        </Box>

        <Box marginBottom={2}>
          <TableActionableMembers members={members} onRemove={handleExcludeMember} />
        </Box>
      </DialogContent>
      <DialogContent>
        {containsAdmin && (
          <Alert icon={false} severity="warning">
            Note: A team admin cannot be removed from the team.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonSubmit
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          submitting={isRemovingMembers}
        >
          Confirm
        </ButtonSubmit>
      </DialogActions>

      {errorRemovingMembers && (
        <Alert severity="error">
          Something went wrong when removing the member. Please try again or contact support.
        </Alert>
      )}
    </Dialog>
  )
}

const mapStateToProps = state => {
  const {
    selectedMembers,
    sort,
    isRemovingMembers,
    successRemovingMembers,
    errorRemovingMembers,
  } = state.teamMembers
  const removableMembers = selectRemovableMembers(state)

  return {
    selectedMembers,
    removableMembers,
    sort,
    isRemovingMembers,
    successRemovingMembers,
    errorRemovingMembers,
  }
}

const mapDispatchToProps = {
  removeMembersStart,
  resetMemberRemoveState,
  removeActionableMember,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogRemoveMember)
