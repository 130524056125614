import { Card } from '@material-ui/core'
import React, { Component } from 'react'

import { Article } from '../common/Layout'
import PrivacyPolicy from './PrivacyPolicy'

export class PrivacyPolicyPage extends Component {
  render() {
    return (
      <Article>
        <Card>
          <PrivacyPolicy />
        </Card>
      </Article>
    )
  }
}

export default PrivacyPolicyPage
