import { useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useDispatch } from 'react-redux'
import { createSetupIntent, getCaptcha } from '../api/api'
import { enqueueSnackbar } from '../redux/modules/snackbar'

const useAddPaymentMethod = ({ ownerType, referenceId, onSuccess }) => {
  const [isCreatingPaymentMethod, setCreatingPaymentMethod] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState(undefined)
  const dispatch = useDispatch()
  const elements = useElements()
  const stripe = useStripe()

  const handleAddPaymentMethod = async () => {
    setCreatingPaymentMethod(true)
    const captchaToken = await getCaptcha()
    const setupIntentRes = await createSetupIntent({
      captchaToken,
      ownerType,
      referenceId,
    })
    if (setupIntentRes.error) {
      dispatch(enqueueSnackbar(setupIntentRes.error))
      return
    }

    const reqBody = {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    }

    const { setupIntent, error: confirmCardError } = await stripe.confirmCardSetup(
      setupIntentRes.clientSecret,
      reqBody,
    )

    if (confirmCardError) {
      dispatch(enqueueSnackbar(confirmCardError))
      setCreatingPaymentMethod(false)
      return
    }

    const pm = setupIntent.payment_method
    setPaymentMethodId(pm)
    onSuccess && onSuccess(pm)
    setCreatingPaymentMethod(false)
  }

  return {
    paymentMethodId,
    isCreatingPaymentMethod,
    handleAddPaymentMethod,
  }
}

export default useAddPaymentMethod
