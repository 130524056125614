import { compareAsc, differenceInCalendarDays } from 'date-fns'
import * as Cookies from 'js-cookie'
import { last } from 'lodash'
import ReactGA from 'react-ga'
import { waitPlz } from './promiseUtils'

import { logException } from './errorUtils'

const NO_COOKIE_ERR = 'Could not find a user info cookie'
const IS_NOT_PROD = process.env.REACT_APP_ENV !== 'production'
ReactGA.initialize(
  [
    {
      trackingId: 'UA-31269268-3',
    },
  ],
  {
    debug: IS_NOT_PROD,
    testMode: IS_NOT_PROD,
  },
)

export const pushAnalyticsEvent = (category, action, label, value) => {
  ReactGA.event({
    action,
    category,
    label,
    value,
  })
  if (IS_NOT_PROD) {
    console.debug(last(ReactGA.testModeAPI.calls))
  }
}

export const pushGTMDataLayer = data => window && window.dataLayer && window.dataLayer.push(data)

export async function pushGTMUserInfo() {
  try {
    var cookie = await getUserCookie(20, 250) // 5 second timeout
  } catch (e) {
    e.message !== NO_COOKIE_ERR && logException(e)
  }

  if (window && window.dataLayer && cookie) {
    window.dataLayer.push(JSON.parse(cookie))
    pushGTMDataLayer({
      event: 'user-info-set',
      userId: JSON.parse(cookie).userId,
    })
  }
}

export function setGTMBuilding(eventName, location) {
  if (location) {
    const isActive = compareAsc(Date.now(), location.activationDate) >= 0
    window.dataLayer.push({
      event: eventName,
      userBuildingActivationDate: location.activationDate,
      userBuildingActivationDaysFromToday: isActive
        ? 'isActive'
        : differenceInCalendarDays(location.activationDate, Date.now()),
      userBuildingCity: location.city,
      userBuildingId: location.deliveryLocationId,
      userBuildingName: location.deliveryLocationName,
      userBuildingState: location.state,
      userBuildingStreet: location.street,
      userBuildingZip: location.zip,
    })
  }
}

async function getUserCookie(numAttempts, msWaitTime) {
  let cookie = Cookies.get(process.env.REACT_APP_COOKIE_NAME + '-user')
  if (cookie) {
    return cookie
  } else {
    for (var i = 0; i < numAttempts; i++) {
      await waitPlz(msWaitTime)
      cookie = Cookies.get(process.env.REACT_APP_COOKIE_NAME + '-user')
      if (!cookie) {
        throw new Error(NO_COOKIE_ERR)
      }
      return cookie
    }
  }
}
