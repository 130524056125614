import React from 'react'
import { connect } from 'react-redux'
import { Box, Typography } from '@material-ui/core'
import { selectCurrentLocationWithAddress } from '../../../redux/modules/selectors'

const HeaderLocation = ({ currentLocation }) => {
  const currentLocationStreet = currentLocation?.street

  return (
    <Box display="flex" flexDirection="column" alignItems="start" paddingTop={0.5}>
      <Typography
        variant="caption"
        color="textSecondary"
        style={{ fontSize: '14px', fontWeight: 'bold' }}
      >
        Location
      </Typography>
      <Typography style={{ fontSize: '14px' }}>{currentLocationStreet}</Typography>
    </Box>
  )
}

const mapStateToProps = state => {
  const currentLocation = selectCurrentLocationWithAddress(state)

  return {
    currentLocation,
  }
}

export default connect(mapStateToProps, null)(HeaderLocation)
