import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { getEmail, getFullName } from './adminUtils'

const useStyles = makeStyles({
  primaryText: {
    fontWeight: 500,
  },
})

const MemberName = ({ member }) => {
  const classes = useStyles()
  const fullName = getFullName(member)
  const email = getEmail(member)

  if (fullName) {
    return (
      <>
        <Typography className={classes.primaryText}>{fullName}</Typography>
        <Typography variant="body2" color="textSecondary">
          {email}
        </Typography>
      </>
    )
  }

  return <Typography className={classes.primaryText}>{email}</Typography>
}

export default MemberName
