import React from 'react'
import { mapValues } from 'lodash'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { selectUserLocationId } from '../../redux/modules/selectors'
import { locationRoute, loginRoute, profileRoute, rootRoute } from '../../routes/routes'
import { formatUrl } from '../../util/formatUtils'

const HomeRedirect = ({ isAuthenticated, isCurrentUserLoading, locationId, query }) => {
  if (isCurrentUserLoading) {
    return null
  }

  if (locationId === 0 && isAuthenticated) {
    return <Redirect to={profileRoute.path} />
  }

  const queryParams = Object.keys(query).map(key => ({ key, value: query[key] }))
  const path = formatUrl(locationRoute.path, { locationId: locationId }, queryParams)
  if (isAuthenticated) {
    return <Redirect to={path} />
  }

  const refer = formatUrl(rootRoute.path, {}, queryParams)
  return <Redirect to={{ pathname: loginRoute.path, refer }} />
}

const mapStateToProps = state => {
  const locationId = selectUserLocationId(state)
  const { isAuthenticated, isCurrentUserLoading } = state.user
  const {
    location: { query },
  } = state.router

  return {
    isAuthenticated,
    isCurrentUserLoading,
    locationId,
    query: mapValues(query, value => decodeURI(value)),
  }
}
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(HomeRedirect)
