import { Box, Divider, Typography } from '@material-ui/core'
import React from 'react'
import { themeConWeb } from '@app/util/modernThemeConweb'
import { formatCentsToDollars } from '../teams/perks/giftCardUtils'
import { parseLeadTimeInHours } from '../../util/cateringUtils'

const CateringRestaurantDeliveryDetails = ({
  leadTime,
  maxWeight,
  deliveryFeeInCents,
  moneyMinimumInCents,
}) => {
  const leadTimeInHours = parseLeadTimeInHours(leadTime)

  return (
    <>
      <div className="-leadTime">
        <div>
          <Typography className="-cardDetailTitle" variant="body2">
            Delivery notice
          </Typography>
          <p className="-cardDetail">
            <span style={{ fontWeight: 500 }}>{leadTimeInHours}</span>
            <span style={{ fontSize: themeConWeb.fontSizes.base }}>
              {' '}
              hour{leadTimeInHours > 1 && 's'}
            </span>
          </p>
        </div>
      </div>
      <Divider className="-divider" flexItem light orientation="vertical" />
      <div className="-moneyMinimum">
        <div>
          <Box display={'flex'} alignItems={'center'}>
            <Typography className="-cardDetailTitle" variant="body2">
              Minimum
            </Typography>
          </Box>
          <p className="-cardDetail">
            <span style={{ fontWeight: 500 }}>{formatCentsToDollars(moneyMinimumInCents)}</span>
          </p>
        </div>
      </div>
      <Divider className="-divider" flexItem light orientation="vertical" />
      <div className="-deliveryFee">
        <div>
          <Box display={'flex'} alignItems={'center'}>
            <Typography className="-cardDetailTitle" variant="body2">
              Delivery fee
            </Typography>
          </Box>
          <p className="-cardDetail">
            <span style={{ fontWeight: 500 }}>{formatCentsToDollars(deliveryFeeInCents)}</span>
          </p>
        </div>
      </div>
    </>
  )
}

export default CateringRestaurantDeliveryDetails
