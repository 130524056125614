import { api, subscriptionsCache } from '../api/api'

export const API_MONO = '/api-monolith'

export const getSubscriptionDetailsApi = () => api.get('/api/v1/credit-cards/subscriptions')

export const getSubscriptionOfferApi = () => api.get('/api/v1/credit-cards/subscriptions/offer')

export const getIsSubscribed = () =>
  api.get(`/api/v1/credit-cards/subscriptions/enrolled`, null, {
    cache: subscriptionsCache,
  })

export const createSubscriptionApi = paymentMethodId =>
  api.post(`/api/v1/salad/web/subscriptions`, {
    paymentMethodId,
  })

export const updateSubscriptionApi = (subscriptionId, paymentMethodId) =>
  api.patch(`/api/v1/salad/web/subscriptions/${subscriptionId}`, {
    paymentMethodId,
  })

export const cancelSubscriptionApi = subscriptionId =>
  api.post(`/api/v1/credit-cards/subscriptions/${subscriptionId}/cancel`)

export const getSubscriptionPriceApi = (teamId, memberCount) =>
  api.get(`/api/v1/accounts/${teamId}/plan/quote?memberCount=${memberCount}`)

export const getAccountSubscriptionPricePerHeadInCentsApi = accountId =>
  api.get(`/api/v1/accounts/${accountId}/plan/price`)

export const subscribeApi = (teamId, payload) =>
  api.post(`/api/v1/accounts/${teamId}/plan/`, {
    userIds: payload.userIds,
    autoEnrollNewMembers: payload.autoEnrollNewMembers,
  })

export const deleteSubscribeApi = teamId => api.delete(`/api/v1/accounts/${teamId}/plan/`)
