import { Container } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import {
  loadLocationsUserManagesStart,
  loadLocationManagersStart,
  loadLocationManagerOfficesStart,
  loadLocationImagesStart,
  editOfficeStart,
  removeOfficeStart,
  setPageOffices,
} from '../redux/modules/locationManager'
import { selectCurrentLocationWithAddress } from '../redux/modules/selectors'
import NewPageLayout from '../layouts/NewPageLayout'
import {
  locationManagerDeliveryRoute,
  locationManagerOfficesRoute,
  locationManagerRoute,
  loginRoute,
} from '../routes/routes'
import LocationInformationTab from '../components/location-manager/LocationInformationTab'
import LocationOfficesTab from '../components/location-manager/LocationOfficesTab'
import LocationDeliveryTab from '../components/location-manager/LocationDeliveryTab'

class PageLocationManager extends Component {
  componentDidMount() {
    const { isAuthenticated } = this.props
    let {
      match: {
        params: { locationId },
      },
    } = this.props
    locationId = Number(locationId)
    if (isAuthenticated) {
      this.fetchLocationsManagers(locationId)
      this.fetchLocationsOffices(locationId)
      this.fetchLocationImages(locationId)
    }
    window && window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    const { isAuthenticated } = this.props
    let {
      match: {
        params: { locationId = 0 },
      },
    } = this.props
    locationId = Number(locationId)

    if (Number(prevProps.match.params.locationId) !== locationId) {
      this.fetchLocationsManagers(locationId)
      this.fetchLocationsOffices(locationId)
      this.fetchLocationImages(locationId)
    }
    if (prevProps.isAuthenticated === false && isAuthenticated === true) {
      this.fetchLocationsManagers(locationId)
      this.fetchLocationsOffices(locationId)
      this.fetchLocationImages(locationId)
    }
  }

  fetchLocationsUserManages = () => {
    const { loadLocationsUserManagesStart } = this.props
    loadLocationsUserManagesStart()
  }

  fetchLocationsManagers = locationId => {
    const { loadLocationManagersStart } = this.props
    loadLocationManagersStart(locationId)
  }

  fetchLocationsOffices = locationId => {
    const { loadLocationManagerOfficesStart } = this.props
    loadLocationManagerOfficesStart(locationId)
  }

  fetchLocationImages = locationId => {
    const { loadLocationImagesStart } = this.props
    loadLocationImagesStart(locationId)
  }

  getLocationUserManages = (locations, locationId, isLoadingLocationsUserManages) => {
    if (isLoadingLocationsUserManages) {
      return null
    }
    return locations.find(item => item.location.locationId === locationId) || {}
  }

  handleEditOffice = values => {
    let {
      editOfficeStart,
      match: {
        params: { locationId },
      },
    } = this.props
    locationId = Number(locationId)
    const { officeId, ...rest } = values
    editOfficeStart(officeId, locationId, rest)
  }

  handleRemoveOffice = officeId => {
    let {
      removeOfficeStart,
      match: {
        params: { locationId },
      },
    } = this.props
    locationId = Number(locationId)
    removeOfficeStart(officeId, locationId)
  }

  render() {
    let {
      match: {
        params: { locationId },
      },
      isAuthenticated,
      location,
      locationsUserManages,
      isLoadingLocationsUserManages,
      isLocationLoading,
      locationManagers,
      locationOffices,
      isLoadingLocationOffices,
      isLoadingLocationManagers,
      locationImages,
      isLoadingLocationImages,
      isEditingOffice,
      errorEditingOffice,
      isRemovingOffice,
      errorRemovingOffice,
      pageOffices,
      setPageOffices,
    } = this.props
    locationId = Number(locationId)

    const locationUserManages = this.getLocationUserManages(
      locationsUserManages,
      locationId,
      isLoadingLocationsUserManages,
    )

    if (!isAuthenticated || (!isLoadingLocationsUserManages && !locationUserManages)) {
      return <Redirect to={loginRoute.path} />
    }

    return (
      <NewPageLayout>
        <Container maxWidth="lg">
          <Switch>
            <Route path={locationManagerRoute.path} exact>
              <LocationInformationTab
                loading={
                  isLocationLoading || isLoadingLocationsUserManages || isLoadingLocationManagers
                }
                locationUserManages={locationUserManages?.location}
                locationInfo={location}
                locationManagers={locationManagers}
                name={location?.deliveryLocationName}
                locationId={locationId}
              />
            </Route>

            <Route path={locationManagerOfficesRoute.path} exact>
              <LocationOfficesTab
                loadingTable={isLoadingLocationOffices}
                locationOffices={locationOffices}
                pageOffices={pageOffices}
                setPageOffices={setPageOffices}
                name={location?.deliveryLocationName}
                locationId={locationId}
                handleEditOffice={this.handleEditOffice}
                isEditingOffice={isEditingOffice}
                errorEditingOffice={errorEditingOffice}
                handleRemoveOffice={this.handleRemoveOffice}
                isRemovingOffice={isRemovingOffice}
                errorRemovingOffice={errorRemovingOffice}
              />
            </Route>

            <Route path={locationManagerDeliveryRoute.path} exact>
              <LocationDeliveryTab
                loading={isLoadingLocationsUserManages || isLoadingLocationImages}
                locationUserManages={locationUserManages?.location}
                name={location?.deliveryLocationName}
                locationId={locationId}
                locationImages={locationImages}
              />
            </Route>
          </Switch>
        </Container>
      </NewPageLayout>
    )
  }
}

const mapStateToProps = state => {
  const {
    locationsUserManages,
    isLoadingLocationsUserManages,
    isLoadingLocationManagers,
    locationManagers,
    locationOffices,
    isLoadingLocationOffices,
    errorLoadingLocationOffices,
    locationImages,
    isLoadingLocationImages,
    isEditingOffice,
    errorEditingOffice,
    isRemovingOffice,
    errorRemovingOffice,
    pageOffices,
  } = state.locationManager
  const location = selectCurrentLocationWithAddress(state)
  const { isAuthenticated } = state.user
  const { isLocationLoading } = state.location

  return {
    isAuthenticated,
    location,
    isLocationLoading,
    locationsUserManages,
    isLoadingLocationsUserManages,
    isLoadingLocationManagers,
    locationManagers,
    locationOffices,
    isLoadingLocationOffices,
    errorLoadingLocationOffices,
    locationImages,
    isLoadingLocationImages,
    isEditingOffice,
    errorEditingOffice,
    isRemovingOffice,
    errorRemovingOffice,
    pageOffices,
  }
}

const mapDispatchToProps = {
  loadLocationsUserManagesStart,
  loadLocationManagersStart,
  loadLocationManagerOfficesStart,
  loadLocationImagesStart,
  editOfficeStart,
  removeOfficeStart,
  setPageOffices,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageLocationManager)
