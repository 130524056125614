import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LayoutInnerPage from '../layouts/LayoutInnerPage'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    height: '50px',
    width: '50px',
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
}))

const LayoutMessage = ({ title, description, Icon, Action }) => {
  const classes = useStyles()
  return (
    <LayoutInnerPage
      MainComponent={
        <div className={classes.container}>
          {Icon && <div className={classes.iconContainer}>{Icon}</div>}

          <Container maxWidth="xs" component={Box} textAlign="center" marginBottom={4}>
            <Typography variant="h3" component="h1" className={classes.title}>
              {title}
            </Typography>

            <Typography>{description}</Typography>
          </Container>

          {Action}
        </div>
      }
    />
  )
}

LayoutMessage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  Icon: PropTypes.element,
  Action: PropTypes.element,
}

export default LayoutMessage
