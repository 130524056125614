import React, { useEffect } from 'react'
import { Dialog, DialogTitle, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { push } from 'connected-react-router'
import queryString from 'query-string'
import { number, object } from 'yup'

import { selectQuery } from '../../../redux/selectors/routerSelectors'
import { selectUserTeam } from '../../../redux/selectors/userTeamSelectors'
import { hasPotentialTeam, hasTeam, POTENTIAL_TEAM_TYPES } from '../teamUtils'
import ButtonDialogClose from '../common/ButtonDialogClose'
import {
  createTeamStart,
  joinTeamStart,
  loadPotentialTeamsStart,
} from '../../../redux/modules/teamOnboarding'
import FormJoinTeam from './FormJoinTeam'

const schema = object().shape({
  id: number().required('Please select a team to join.'),
})

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      padding: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
      },
    },
    '& .MuiDialogContent-root': {
      maxHeight: '500px',
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  createTeamButton: {
    marginRight: theme.spacing(2),
  },
  radioOptionLabel: {
    fontWeight: 500,
    fontSize: '20px',
  },
  radioOptionLabelDisabled: {
    color: theme.palette.text.disabled,
    fontWeight: 500,
    fontSize: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const DialogJoinTeam = ({
  potentialTeams,
  isJoiningTeam,
  successJoiningTeam,
  isCreatingTeam,
  createTeamStart,
  joinTeamStart,
  loadPotentialTeamsStart,
  push,
}) => {
  const classes = useStyles()

  const handleSubmit = values => {
    if (values.type === POTENTIAL_TEAM_TYPES.COMPANY) {
      createTeamStart(values.name, values.id)
    } else {
      joinTeamStart(values.id, values.name)
    }
  }

  const handleCreateNewTeam = () => {
    push({ search: queryString.stringify({ submitTeam: 'true' }) })
  }

  const handleChange = (id, type, name, setFieldValue) => {
    setFieldValue('id', Number(id))
    setFieldValue('type', type, false)
    setFieldValue('name', name, false)
  }

  const handleClose = () => {
    push({ search: undefined })
  }

  useEffect(() => {
    loadPotentialTeamsStart()
  }, [loadPotentialTeamsStart, potentialTeams])

  useEffect(() => {
    if (successJoiningTeam) {
      push({ search: undefined })
    }
  }, [push, successJoiningTeam])

  if (!potentialTeams) {
    return null
  }

  return (
    <Dialog open maxWidth="sm" className={classes.root}>
      <DialogTitle disableTypography>
        <Typography variant="h5" align="center" gutterBottom>
          {'We found your team on Foodsby 🎉'}
        </Typography>
        <Typography variant="body1" align="center">
          Join your team to get instant access to exclusive perks, special offers, and more!
        </Typography>
        <ButtonDialogClose onClick={handleClose} />
      </DialogTitle>
      <Formik
        initialValues={{ id: '', type: '', name: '' }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ values, errors, setFieldValue }) => (
          <FormJoinTeam
            potentialTeams={potentialTeams}
            values={values}
            errors={errors}
            classes={classes}
            submitting={isJoiningTeam || isCreatingTeam}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            onCreateNewTeam={handleCreateNewTeam}
          />
        )}
      </Formik>
    </Dialog>
  )
}

const DialogJoinTeamWrapper = ({
  isAuthenticated,
  isCurrentUserLoading,
  query,
  userTeam,
  potentialTeams,
  errorLoadingPotentialTeams,
  push,
  ...rest
}) => {
  useEffect(() => {
    if (Boolean(query?.joinTeam) && !isAuthenticated && !isCurrentUserLoading) {
      push({ search: queryString.stringify({ ...query, authenticate: 'true' }) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isCurrentUserLoading, push, query?.joinTeam])

  // If the user does not have potential teams or it fails to load, just send them to the create team flow
  useEffect(() => {
    if (
      Boolean(query?.joinTeam) &&
      ((potentialTeams && !hasPotentialTeam(potentialTeams)) || errorLoadingPotentialTeams)
    ) {
      push({ search: queryString.stringify({ submitTeam: 'true' }) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorLoadingPotentialTeams, potentialTeams, push, query?.joinTeam])

  const open =
    isAuthenticated &&
    Boolean(query?.joinTeam) &&
    !hasTeam(userTeam) &&
    hasPotentialTeam(potentialTeams)
  return open ? <DialogJoinTeam potentialTeams={potentialTeams} push={push} {...rest} /> : null
}

const mapStateToProps = state => {
  const { isAuthenticated, isCurrentUserLoading } = state.user
  const query = selectQuery(state)
  const userTeam = selectUserTeam(state)
  const {
    potentialTeams,
    errorLoadingPotentialTeams,
    isJoiningTeam,
    successJoiningTeam,
    isCreatingTeam,
  } = state.teamOnboarding

  return {
    isAuthenticated,
    isCurrentUserLoading,
    query,
    userTeam,
    potentialTeams,
    errorLoadingPotentialTeams,
    isJoiningTeam,
    successJoiningTeam,
    isCreatingTeam,
  }
}

const mapDispatchToProps = {
  loadPotentialTeamsStart,
  createTeamStart,
  joinTeamStart,
  push,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogJoinTeamWrapper)
