import React, { useCallback, useEffect, useState } from 'react'
import { Box, Dialog, DialogContent, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Alert } from '@material-ui/lab'
import queryString from 'query-string'
import { push } from 'connected-react-router'

import LinkButton from '@app/components/common/LinkButton'
import { inviteMembersStart, resetInviteMembersState } from '../../../redux/modules/teamMembers'
import { selectTeam } from '../../../redux/selectors/teamSelectors'
import InviteByEmail, { TITLE as TITLE_BY_EMAIL } from './InviteByEmail'
import InviteByLink, { TITLE as TITLE_BY_LINK } from './InviteByLink'
import InviteByCsv, { TITLE as TITLE_BY_CSV } from './InviteByCsv'

const MODES = Object.freeze({
  INVITE_LINK: 'INVITE_LINK',
  INVITE_CSV: 'INVITE_CSV',
  INVITE_EMAIL: 'INVITE_EMAIL',
})

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto',
  },
  modeChangeContainer: {
    paddingBottom: theme.spacing(2),
  },
}))

const ButtonModeChange = ({ text, onClick }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.modeChangeContainer}>
      <DialogContent>
        <LinkButton onClick={onClick}>{text}</LinkButton>
      </DialogContent>
    </Paper>
  )
}

const Content = ({
  onClose,
  link,
  isInvitingMembers,
  errorInvitingMembers,
  inviteMembersStart,
}) => {
  const [activeMode, setActiveMode] = useState(MODES.INVITE_CSV)

  const handleSubmitInvite = ({ memberEmails }) => {
    inviteMembersStart(memberEmails)
  }

  return (
    <Box display="flex" flexDirection="column" width="70%" maxWidth="600px">
      <Box marginBottom={3} position="relative">
        {activeMode !== MODES.INVITE_CSV && (
          <ButtonModeChange text={TITLE_BY_CSV} onClick={() => setActiveMode(MODES.INVITE_CSV)} />
        )}

        {activeMode === MODES.INVITE_CSV && (
          <InviteByCsv
            onSubmit={handleSubmitInvite}
            onClose={onClose}
            submitting={isInvitingMembers}
          />
        )}
      </Box>

      <Box marginBottom={3} position="relative">
        {activeMode !== MODES.INVITE_LINK && (
          <ButtonModeChange text={TITLE_BY_LINK} onClick={() => setActiveMode(MODES.INVITE_LINK)} />
        )}

        {activeMode === MODES.INVITE_LINK && <InviteByLink onClose={onClose} link={link} />}
      </Box>

      <Box position="relative">
        {activeMode !== MODES.INVITE_EMAIL && (
          <ButtonModeChange
            text={TITLE_BY_EMAIL}
            onClick={() => setActiveMode(MODES.INVITE_EMAIL)}
          />
        )}

        {activeMode === MODES.INVITE_EMAIL && (
          <InviteByEmail
            onSubmit={handleSubmitInvite}
            onClose={onClose}
            submitting={isInvitingMembers}
          />
        )}

        {Boolean(errorInvitingMembers) && (
          <Alert severity="error">Something went wrong inviting members.</Alert>
        )}
      </Box>
    </Box>
  )
}

const DialogInviteMembers = ({
  team,
  isInvitingMembers,
  successInvitingMembers,
  errorInvitingMembers,
  push,
  resetInviteMembersState,
  inviteMembersStart,
}) => {
  const classes = useStyles()

  const handleClose = useCallback(() => {
    resetInviteMembersState()
    push({ search: queryString.stringify({ inviteMembers: undefined }) })
  }, [push, resetInviteMembersState])

  useEffect(() => {
    if (successInvitingMembers) {
      handleClose()
    }
  }, [handleClose, successInvitingMembers])

  return (
    <Dialog
      open
      aria-describedby="Invite members modal"
      className={classes.root}
      PaperComponent={Content}
      PaperProps={{
        onClose: handleClose,
        link: team?.teamInviteLink,
        isInvitingMembers,
        errorInvitingMembers,
        inviteMembersStart,
      }}
    />
  )
}

const DialogInviteMembersWrapper = ({ inviteMembers, team, ...rest }) => {
  const open = Boolean(inviteMembers) && Boolean(team)
  return open ? <DialogInviteMembers team={team} {...rest} /> : null
}

const mapStateToProps = state => {
  const team = selectTeam(state)
  const { inviteMembers } = state.router.location.query
  const { isInvitingMembers, successInvitingMembers, errorInvitingMembers } = state.teamMembers

  return {
    team,
    inviteMembers,
    isInvitingMembers,
    successInvitingMembers,
    errorInvitingMembers,
  }
}

const mapDispatchToProps = {
  push,
  resetInviteMembersState,
  inviteMembersStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogInviteMembersWrapper)
