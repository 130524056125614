import React from 'react'
import { Box, Typography, Link } from '@material-ui/core'

const AccountWidget = ({ title, name, classes, manageLink, showManageLink }) => (
  <Box display="flex" flexDirection="column" alignItems="start" marginTop={3}>
    <Typography className={classes.locationTitle}>{title}</Typography>
    <Typography className={classes.firstAddressLine}>{name}</Typography>
    {showManageLink ? (
      <span className={classes.manageOffice}>
        <Link href={manageLink}>Manage</Link>
      </span>
    ) : null}
  </Box>
)

export default AccountWidget
