import { Box, Link, Typography } from '@material-ui/core'
import React from 'react'

const ContactSupport = () => {
  return (
    <Box m={'2rem'}>
      <Typography gutterBottom>
        <strong>Need Help?</strong>
      </Typography>
      <Typography display="block">
        Visit{' '}
        <Link href="https://home.foodsby.com/faq" target="_blank" underline={'always'}>
          FAQs
        </Link>{' '}
        or contact{' '}
        <Link href="mailto:support@foodsby.com" underline={'always'}>
          support@foodsby.com
        </Link>
        .
      </Typography>
    </Box>
  )
}

export default ContactSupport
