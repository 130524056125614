import React, { useState } from 'react'
import { Box, FormControl, FormControlLabel, Checkbox } from '@material-ui/core'
import { Skeleton, Alert } from '@material-ui/lab'
import { isEmpty, has, unset } from 'lodash'
import CardTeam, { CardTeamContent, CardTeamTitle } from '../common/CardTeam'
import CardActionButton from './CardActionButton'

function TeamVisibility({ team, onChange, pendingChanges }) {
  const [checkedVisibility, setCheckedVisibility] = useState(team.privacy === 'OPEN_TO_DOMAIN')

  const handleChange = event => {
    setCheckedVisibility(event.target.checked)
    const visibilityValue = event.target.checked ? 'OPEN_TO_DOMAIN' : 'INVITE_ONLY'
    onChange({
      ...event,
      target: {
        name: event.target.name,
        value: visibilityValue,
      },
    })
  }

  return (
    <Box mt={2}>
      <FormControl>
        <FormControlLabel
          value={pendingChanges.privacy || team.privacy}
          control={
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'public joinable' }}
              checked={checkedVisibility}
              onChange={handleChange}
              name="privacy"
            />
          }
          label={
            team.emailDomain
              ? `Anyone with a verified "@${team.emailDomain}" email to join`
              : 'Anyone with a verified email can join'
          }
          disabled={!team.emailDomain}
        />
        {!team.emailDomain && (
          <Alert severity="info">
            You must have a company email domain to adjust this setting.
          </Alert>
        )}
      </FormControl>
    </Box>
  )
}

export default function CardAccountSettings({ team, loading, onSubmit }) {
  const [pendingChanges, setPendingChanges] = useState({})

  const onSettingChange = event => {
    const { name, value } = event.target

    //remove from pending changes if same as saved value
    if (has(pendingChanges, name) && team[name] === value) {
      const copiedChanges = JSON.parse(JSON.stringify(pendingChanges))
      unset(copiedChanges, name)
      setPendingChanges(copiedChanges)
    } else {
      setPendingChanges({
        ...pendingChanges,
        [name]: value,
      })
    }
  }

  const onFinalSubmit = () => {
    onSubmit(pendingChanges)
    setPendingChanges({})
  }

  if (loading) {
    return (
      <CardTeam>
        <CardTeamTitle>Settings</CardTeamTitle>
        <Skeleton height={150} />
      </CardTeam>
    )
  }

  return (
    <CardTeam>
      <CardTeamTitle>Settings</CardTeamTitle>
      <CardTeamContent>
        <TeamVisibility team={team} pendingChanges={pendingChanges} onChange={onSettingChange} />
      </CardTeamContent>

      {!isEmpty(pendingChanges) && (
        <Box height={35}>
          <CardActionButton
            name="Submit Changes"
            onClick={onFinalSubmit}
            variant="outlined"
            fullHeight
          />
        </Box>
      )}
    </CardTeam>
  )
}
