export const rootRoute = {
  path: '/',
  title: 'Home',
}

export const homeRoute = {
  path: '/home',
  title: 'Home',
}

export const cateringRoute = {
  path: '/:locationId(\\d+)/catering',
  title: 'Catering',
}

export const reserveRoute = {
  path: '/:locationId(\\d+)/reserve',
  title: 'Reserve',
}

export const locationRoute = {
  path: '/:locationId(\\d+)',
  title: 'Home',
}

export const selectLocationRoute = {
  path: '/select-location',
  title: 'Search for an address',
}

export const loginRoute = {
  path: process.env.REACT_APP_LOGIN_URL,
  title: 'Login',
}

export const logoutRoute = {
  path: '/logout',
  title: 'Logout',
}

export const forgotPasswordRoute = {
  path: '/forgot-password',
  title: 'Forgot Password',
}

export const changePasswordRoute = {
  path: '/change-password/:token',
  title: 'Change Password',
}

// An old page that we now redirect from
export const unsubscribeRoute = {
  path: '/unsubscribe',
  title: 'Unsubscribe',
}

export const profileRoute = {
  path: '/profile',
  title: 'Profile',
}

export const historyRoute = {
  path: '/history',
  title: 'History',
}

// Used by mobile
export const termsAndConditionsRoute = {
  path: '/terms-and-conditions',
  title: 'Terms and Conditions',
}

export const termsAndConditionsRewardsRoute = {
  path: '/terms-and-conditions-rewards',
  title: 'Terms and Conditions - Rewards',
}

// Used by mobile
export const privacyPolicyRoute = {
  path: '/privacy',
  title: 'Privacy',
}

export const contactRoute = {
  path: '/contact',
  title: 'Contact',
}

export const placeOrderRoute = {
  path: '/:locationId(\\d+)/deliveries/:dropoffId(\\d+)/order',
  title: 'Place Order',
}

export const placeCateringOrderRoute = {
  path: '/:locationId(\\d+)/catering/:storeId(\\d+)/order',
  title: 'Place Catering Order',
}

export const placeReserveOrderRoute = {
  path: '/:locationId(\\d+)/reserve/:storeId(\\d+)/order',
  title: 'Place Reserve Order',
}

export const pickCateringDeliveryDateAndTimeRoute = {
  path: '/:locationId(\\d+)/catering/:storeId(\\d+)/date',
  title: 'Delivery Date & Time',
}

export const pickReserveDeliveryDateAndTimeRoute = {
  path: '/:locationId(\\d+)/reserve/:storeId(\\d+)/date',
  title: 'Delivery Date & Time',
}

export const setDeliveryReserveFeeRoute = {
  path: '/:locationId(\\d+)/reserve/:storeId(\\d+)/fee',
  title: 'Set Delivery Fee',
}

export const checkoutRoute = {
  path: '/:locationId(\\d+)/deliveries/:dropoffId(\\d+)/checkout',
  title: 'Checkout',
}

export const confirmationRoute = {
  path: '/confirmation/:orderId(\\d+)/:locationId(\\d+)',
  title: 'Confirmation',
}

export const maintenanceRoute = {
  path: '/maintenance',
  title: 'This page is under maintenance',
}

export const friendsRoute = {
  path: '/friends',
  title: 'Friends',
}

export const completeAccountRoute = {
  path: '/complete-account/:token',
  title: 'Complete Your Account',
}

export const slackRoute = {
  path: '/slack',
  title: 'Slack',
}

// 07-19-2020 This route is temporary and routes to the friends page until branch is 100% working.
export const referralsRoute = {
  path: '/referrals',
  title: 'Referral',
}

export const referralRoute = {
  path: '/referral',
  title: 'Referral',
}

export const branchReferralRoute = {
  path: '/referral-redemption',
  title: 'Referral',
}

export const subscriptionTermsAndConditionsRoute = {
  path: '/subscription-terms-and-conditions',
  title: 'Subscription Terms And Conditions',
}

export const referralTermsAndConditionsRoute = {
  path: '/referral-terms-and-conditions',
  title: 'Referral Terms And Conditions',
}

export const redeemGiftRoute = {
  path: '/gift/:giftCode',
  title: 'Redeem Gift',
}

export const uiRoute = {
  path: '/ui-components',
  title: 'UI Components',
}

export const perksCreditsRoute = {
  path: '/perks-credits',
  title: 'Perks & Credits',
}

export const subscriptionsPromoRoute = {
  path: '/subscription-promo',
  title: 'Subscription Promotional',
}

export const confirmEmailAddressRoute = {
  path: '/confirm-email-address',
  title: 'Confirm your email address',
}

// Used by mobile
export const subscriptionsRoute = {
  path: '/subscriptions',
  title: 'Subscriptions',
}

export const registerRoute = {
  path: '/register',
  title: 'Sign Up for Foodsby',
}

export const teamRoute = {
  path: '/team/:accountId(\\d+)',
  title: 'Manage your team',
}

export const teamMembersRoute = {
  path: '/team/:accountId(\\d+)/members',
  title: 'Manage your members',
}

export const teamPerksRoute = {
  path: '/team/:accountId(\\d+)/perks',
  title: 'Manage your perks',
}

export const teamPaymentsRoute = {
  path: '/team/:accountId(\\d+)/payments',
  title: 'Manage payment information',
}

export const teamAccountRoute = {
  path: '/team/:accountId(\\d+)/account',
  title: 'Manage your account',
}

export const teamRecurringPerkRoute = {
  path: '/team/:accountId(\\d+)/perks/recurring-perk',
  title: 'Manage your account',
}

export const teamUnlimitedDeliveryRoute = {
  path: '/team/:accountId(\\d+)/perks/unlimited-delivery',
  title: 'Manage your account',
}

export const teamOneTimePerkRoute = {
  path: '/team/:accountId(\\d+)/perks/one-time-perk',
  title: 'Manage your account',
}

export const teamInviteRoute = {
  path: '/teams-invite',
  title: 'Welcome to Foodsby',
}

export const surveyRoute = {
  path: '/survey/:id',
  title: 'Survey',
}

export const formRoute = {
  path: '/form/:id',
  title: 'Form',
}

export const locationSearchRoute = {
  path: '/location-search',
  title: 'Welcome to Foodsby',
}

export const errorRoute = {
  path: '/error',
  title: 'This page is unavailable',
}

export const savedLocationsRoute = {
  path: '/saved-locations',
  title: 'Saved Locations',
}

export const locationManagerRoute = {
  path: '/:locationId(\\d+)/manage',
  title: 'Location Manager',
}

export const locationManagerOfficesRoute = {
  path: '/:locationId(\\d+)/manage/offices',
  title: 'Location Manager Offices',
}

export const locationManagerDeliveryRoute = {
  path: '/:locationId(\\d+)/manage/delivery',
  title: 'Location Manager Delivery',
}
