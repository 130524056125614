import { put } from 'redux-saga-test-plan/matchers'
import { call, select, take } from 'redux-saga/effects'

import { api } from '../../api/api'
import { selectCurrentUser } from '../../redux/modules/selectors'
import { apiSaga } from './sagas'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const LOAD_CREDIT_TRANSACTIONS = 'foodsby/credits/LOAD_CREDIT_TRANSACTIONS'
export const LOAD_CREDIT_TRANSACTIONS_SUCCESS = 'foodsby/credits/LOAD_CREDIT_TRANSACTIONS_SUCCESS'
export const LOAD_CREDIT_TRANSACTIONS_FAILURE = 'foodsby/credits/LOAD_CREDIT_TRANSACTIONS_FAILURE'

export const loadCreditTransactionStart = (size, page) => ({
  payload: { page, size },
  type: LOAD_CREDIT_TRANSACTIONS,
})

export const loadCreditTransactionSuccess = response => ({
  payload: { response },
  type: LOAD_CREDIT_TRANSACTIONS_SUCCESS,
})

export const loadCreditTransactionFailure = error => ({
  error,
  type: LOAD_CREDIT_TRANSACTIONS_FAILURE,
})

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [LOAD_CREDIT_TRANSACTIONS]: state => ({
    ...state,
    creditTransactionError: undefined,
    loading: true,
  }),
  [LOAD_CREDIT_TRANSACTIONS_FAILURE]: (state, action) => ({
    ...state,
    creditTransactionError: action.error,
    creditTransactions: undefined,
    loading: false,
  }),
  [LOAD_CREDIT_TRANSACTIONS_SUCCESS]: (state, action) => ({
    ...state,
    creditTransactionError: undefined,
    creditTransactions: action.payload.response,
    loading: false,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

export default function credits(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* watchLoadCreditTransactions() {
  while (true) {
    const {
      payload: { page, size },
    } = yield take(LOAD_CREDIT_TRANSACTIONS)
    const user = yield select(selectCurrentUser)
    if (user) {
      yield call(
        apiSaga,
        creditTransactions,
        [user.userId, size, page],
        loadCreditTransactionSuccess,
        loadCreditTransactionFailure,
      )
    } else {
      yield put(loadCreditTransactionFailure())
    }
  }
}

export const creditTransactions = (userId, size, page) =>
  api.get(`/api/v1/wallets/transactions?userId=${userId}&size=${size}&page=${page}&sort=id,desc`)
