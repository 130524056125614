import { themeConWeb } from '@app/util/modernThemeConweb'
import { Avatar, Link, Tooltip, Typography } from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import currency from 'currency.js'
import React from 'react'
import { Element as ScrollElement } from 'react-scroll'
import styled from 'styled-components'

import Favorite from '../community/Favorite'

export const Menu = ({ friendsHasOrdered, menuData, onSelectMenuItem }) => {
  const maxFriendBubbles = 6
  return (
    <Component>
      {menuData.subMenus.map(submenu => (
        <ScrollElement key={submenu.id} name={`${submenu.id}`}>
          <section className="-categorySection" id={`menu-section-${submenu.id}`}>
            <Typography component="h2" gutterBottom variant="h5">
              {submenu.name}
            </Typography>
            <Typography className="-categoryDescription" variant="body1">
              {submenu.description}
            </Typography>
            <ul>
              {submenu.menuItems.map(item => {
                const friendsOrderedThisItem = friendsHasOrdered?.filter(friend =>
                  friend.orderItems?.map(orderItem => orderItem.menuItemId).includes(item.id),
                )
                const additionalFriendsOrderCount =
                  friendsOrderedThisItem?.length - maxFriendBubbles
                return (
                  <li className="-categoryItem" key={item.id}>
                    <Favorite className="-categoryFavorite" id={item.id} />
                    <button
                      className="-categoryPrimaryContents"
                      onClick={evt => {
                        onSelectMenuItem(item.id)
                      }}
                    >
                      <div className="-categoryPrimaryContent">
                        <Link className="-categoryItemTitle" variant="h6" color="textPrimary">
                          {item.name}
                        </Link>
                        <Typography variant="h6" className="-categoryItemPrice">
                          {currency(item.price).format()}
                        </Typography>
                      </div>

                      <Typography className="-itemDescription" variant="body1">
                        {item.description}
                      </Typography>
                      {friendsOrderedThisItem && (
                        <AvatarGroup className="-avatarGroup" style={{ zIndex: 1 }}>
                          {friendsOrderedThisItem
                            .slice(0, maxFriendBubbles)
                            .map(friendHasOrdered => {
                              const name = `${friendHasOrdered.friend.firstName} ${friendHasOrdered.friend.lastName}`
                              return (
                                friendHasOrdered.orderItems?.find(
                                  orderItem => orderItem.menuItemId === item.id,
                                ) && (
                                  <Tooltip key={friendHasOrdered.friend.userId} title={name}>
                                    <Avatar
                                      alt={name}
                                      src={
                                        friendHasOrdered.friend.avatarId &&
                                        encodeURI(
                                          `${process.env.REACT_APP_IMAGE_LAMBDA}/profiles/${friendHasOrdered.friend.avatarId}.jpg?height=50&width=50`,
                                        )
                                      }
                                      variant="circular"
                                    >
                                      {`${friendHasOrdered.friend.firstName[0].toUpperCase()}${friendHasOrdered.friend.lastName[0].toUpperCase()}`}
                                    </Avatar>
                                  </Tooltip>
                                )
                              )
                            })}
                          {additionalFriendsOrderCount > 0 && (
                            <Avatar variant="circular">+ {additionalFriendsOrderCount}</Avatar>
                          )}
                        </AvatarGroup>
                      )}
                    </button>
                  </li>
                )
              })}
            </ul>
          </section>
        </ScrollElement>
      ))}
    </Component>
  )
}

Menu.defaultProps = {
  menuData: {
    subMenus: [],
  },
}

export default Menu

/**
 *  The styled component is used in MenuPastOrders and MenuPopularItems
 *  Any changes here will effect those components as well
 */

export const Component = styled.section`
  & .-avatarGroup {
    margin-left: calc(${themeConWeb.baseUnit} * 2);
    & .MuiAvatar-circular {
      font-size: ${themeConWeb.fontSizes.base};
    }
  }

  & .-categorySection {
    padding-top: calc(${themeConWeb.baseUnit} * 10);
  }

  & .-categoryDescription {
    border-bottom: 1px solid ${themeConWeb.color.greyShade2};
    padding-bottom: ${themeConWeb.baseUnit};
  }

  & .-categoryItem {
    padding-top: calc(${themeConWeb.baseUnit} * 2);
    padding-bottom: calc(${themeConWeb.baseUnit} * 4);
    position: relative;

    &:first-child {
      padding-top: calc(${themeConWeb.baseUnit} * 4);

      & .-categoryFavorite {
        padding-top: calc(${themeConWeb.baseUnit} * 4);
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${themeConWeb.color.greyShade2};
    }

    :hover {
      background-color: ${themeConWeb.color.grey};
    }
  }

  & .-categoryItem {
    padding-bottom: calc(${themeConWeb.baseUnit} * 4);
    position: relative;
  }

  & .-categoryPrimaryContents {
    cursor: pointer;
    width: 100%;
  }

  & .-categoryPrimaryContent {
    display: flex;
    justify-content: space-between;
  }

  & .-categoryFavorite {
    padding-top: calc(${themeConWeb.baseUnit} * 2);
    left: -4px;
    position: absolute;
    top: 2px;
  }

  & .-categoryFavoritePastOrder {
    left: -4px;
    position: absolute;
    top: 2px;
  }

  & .-categoryItemTitle {
    padding-left: calc(${themeConWeb.baseUnit} * 8);
    font-weight: 400;
  }

  & .-categoryItemPrice {
    font-weight: 400;
  }

  & .-itemDescription {
    text-align: left;
  }
`
