import { call, put, take } from 'redux-saga/effects'

import { loadLocationReserveStoreById } from '../../api/api'
import { apiSaga } from './sagas'

// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const LOAD_RESERVE_STORE = 'foodsby/catering/LOAD_RESERVE_STORE'
export const LOAD_RESERVE_STORE_SUCCESS = 'foodsby/catering/LOAD_RESERVE_STORE_SUCCESS'
export const LOAD_RESERVE_STORE_FAILURE = 'foodsby/catering/LOAD_RESERVE_STORE_FAILURE'

export const CLEAR_RESERVE_STORE = 'foodsby/catering/CLEAR_RESERVE_STORE'

export const loadReserveStoreStart = (storeId, locationId) => {
  return {
    payload: {
      storeId,
      locationId,
    },
    type: LOAD_RESERVE_STORE,
  }
}

export const loadReserveStoreSuccess = reserveStore => {
  return {
    payload: { isReserveStoreLoading: false, reserveStore },
    type: LOAD_RESERVE_STORE_SUCCESS,
  }
}

export const loadReserveStoreFailure = error => {
  return {
    error,
    type: LOAD_RESERVE_STORE_FAILURE,
  }
}
export const clearReserveStore = () => {
  return {
    type: CLEAR_RESERVE_STORE,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CLEAR_RESERVE_STORE]: state => {
    return {
      ...state,
      storeId: undefined,
      locationId: undefined,
    }
  },
  [LOAD_RESERVE_STORE]: (state, action) => {
    const {
      payload: { locationId, storeId },
    } = action
    return {
      ...state,
      locationId: locationId,
      storeId: storeId,
      isReserveStoreLoading: true,
    }
  },
  [LOAD_RESERVE_STORE_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      error,
      isReserveStoreLoading: false,
    }
  },
  [LOAD_RESERVE_STORE_SUCCESS]: (state, action) => {
    const {
      payload: { reserveStore },
    } = action
    return {
      ...state,
      isReserveStoreLoading: false,
      reserveStore,
    }
  },
}
// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  isReserveStoreLoading: true,
  locationId: undefined,
  storeId: undefined,
  reserveStore: undefined,
}

export default function reserve(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* watchLoadReserveStore() {
  while (true) {
    try {
      const {
        payload: { storeId, locationId },
      } = yield take(LOAD_RESERVE_STORE)
      yield call(
        apiSaga,
        loadLocationReserveStoreById,
        [storeId, locationId],
        loadReserveStoreSuccess,
        loadReserveStoreFailure,
      )
    } catch (error) {
      yield put(loadReserveStoreFailure(error))
    }
  }
}
