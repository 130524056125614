import { Box, Icon, Tooltip } from '@material-ui/core'
import pluralize from 'pluralize'
import React from 'react'

const RestaurantName = ({ name, favoritesCount }) => {
  return (
    <Box alignContent="center" display="flex" flexDirection="row">
      <h2 className="-title">{name}</h2>
      {favoritesCount > 0 && (
        <Tooltip title={`${favoritesCount} ${pluralize('favorite', favoritesCount)}`}>
          <Icon className="-favoriteIcon">favorite</Icon>
        </Tooltip>
      )}
    </Box>
  )
}

export default RestaurantName
