import React from 'react'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { contactRoute } from '../../routes/routes'

const CheckoutError = ({ serviceMessage, hasNoItem }) => {
  if (hasNoItem) {
    return <Typography color="error">Your cart is empty. Add more items to continue.</Typography>
  }

  return (
    <>
      <Typography color="error" gutterBottom>
        There was a problem loading your cart.
      </Typography>
      <Typography color="error">
        Please refresh the page and try again. If this problem persists,{' '}
        <Link color="secondary" to={contactRoute.path}>
          contact support.
        </Link>
      </Typography>
      <Typography variant="caption">{serviceMessage}</Typography>
    </>
  )
}

export default CheckoutError
