import styled from 'styled-components'
import { themeConWeb } from '@app/util/modernThemeConweb'

/**
 * @deprecated in favor of `LayoutInnerPage`.
 */
const NewPageLayout = styled.div`
  background-color: ${({ whiteBackground }) =>
    whiteBackground ? themeConWeb.color.white : themeConWeb.color.grey};
  min-height: calc(100vh - 217px);
  padding: 32px 0;
`

export default NewPageLayout
