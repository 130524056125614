import React from 'react'
import { ArrowBackIos } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { formatUrl } from '../../../util/formatUtils'
import { homeRoute } from '../../../routes/routes'

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
    fontWeight: 'normal',
    color: 'gray',
  },
  buttonIcon: {
    color: theme.palette.common.gray,
    transform: 'translateX(-1px)',
    fontSize: '12px',
  },
}))

const BackButton = props => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClick = () => {
    return dispatch(push(formatUrl(homeRoute.path, {})))
  }

  return (
    <Button className={classes.button} onClick={handleClick}>
      <ArrowBackIos className={classes.buttonIcon} /> Back to Order
    </Button>
  )
}

export default BackButton
