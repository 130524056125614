import React from 'react'
import { ErrorOutline } from '@material-ui/icons'
import { Button } from '@material-ui/core'

import LayoutMessage from '../layouts/LayoutMessage'

const PageMaintenance = () => {
  return (
    <LayoutMessage
      title="Service Unavailable"
      description="This page can't be reached at this time. Please check our status page for updates and try again later."
      Icon={<ErrorOutline />}
      Action={
        <Button
          href={process.env.REACT_APP_FOODSBY_STATUS_PAGE_URL}
          variant="contained"
          color="primary"
        >
          See service status
        </Button>
      }
    />
  )
}

export default PageMaintenance
