import { call, put, take } from 'redux-saga/effects'

import { loadLocationCateringStoreById } from '../../api/api'
import { apiSaga } from './sagas'

// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const LOAD_CATERING_STORE = 'foodsby/catering/LOAD_CATERING_STORE'
export const LOAD_CATERING_STORE_SUCCESS = 'foodsby/catering/LOAD_CATERING_STORE_SUCCESS'
export const LOAD_CATERING_STORE_FAILURE = 'foodsby/catering/LOAD_CATERING_STORE_FAILURE'

export const CLEAR_CATERING_STORE = 'foodsby/catering/CLEAR_CATERING_STORE'

export const loadCateringStoreStart = (storeId, locationId) => {
  return {
    payload: {
      storeId,
      locationId,
    },
    type: LOAD_CATERING_STORE,
  }
}

export const loadCateringStoreSuccess = cateringStore => {
  return {
    payload: { isCateringStoreLoading: false, cateringStore },
    type: LOAD_CATERING_STORE_SUCCESS,
  }
}

export const loadCateringStoreFailure = error => {
  return {
    error,
    type: LOAD_CATERING_STORE_FAILURE,
  }
}
export const clearCateringStore = () => {
  return {
    type: CLEAR_CATERING_STORE,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CLEAR_CATERING_STORE]: state => {
    return {
      ...state,
      storeId: undefined,
      locationId: undefined,
    }
  },
  [LOAD_CATERING_STORE]: (state, action) => {
    const {
      payload: { locationId, storeId },
    } = action
    return {
      ...state,
      locationId: locationId,
      storeId: storeId,
      isCateringStoreLoading: true,
    }
  },
  [LOAD_CATERING_STORE_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      error,
      isCateringStoreLoading: false,
    }
  },
  [LOAD_CATERING_STORE_SUCCESS]: (state, action) => {
    const {
      payload: { cateringStore },
    } = action
    return {
      ...state,
      isCateringStoreLoading: false,
      cateringStore,
    }
  },
}
// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  isCateringStoreLoading: true,
  locationId: undefined,
  storeId: undefined,
  cateringStore: undefined,
}

export default function catering(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* watchLoadCateringStore() {
  while (true) {
    try {
      const {
        payload: { storeId, locationId },
      } = yield take(LOAD_CATERING_STORE)
      yield call(
        apiSaga,
        loadLocationCateringStoreById,
        [storeId, locationId],
        loadCateringStoreSuccess,
        loadCateringStoreFailure,
      )
    } catch (error) {
      yield put(loadCateringStoreFailure(error))
    }
  }
}
