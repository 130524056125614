import { call, put, race, take } from 'redux-saga/effects'

import { loadMenuByStoreId, loadCateringMenuByStoreId } from '../../api/api'
import { FULFILLED, REJECTED } from '../utils'
import { DELIVERY_DROPOFF_SCHEDULE_SET } from './delivery'
import { apiSaga } from './sagas'

// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const LOAD_MENU = 'foodsby/menu/LOAD_MENU'
export const LOAD_MENU_SUCCESS = 'foodsby/menu/LOAD_MENU_SUCCESS'
export const LOAD_MENU_FAILURE = 'foodsby/menu/LOAD_MENU_FAILURE'

export const LOAD_CATERING_MENU = 'foodsby/menu/LOAD_CATERING_MENU'
export const LOAD_CATERING_MENU_SUCCESS = 'foodsby/menu/LOAD_CATERING_MENU_SUCCESS'
export const LOAD_CATERING_MENU_FAILURE = 'foodsby/menu/LOAD_CATERING_MENU_FAILURE'

export const SELECT_MENU_ITEM = 'foodsby/menu/SELECT_MENU_ITEM'
export const CLEAR_MENU_ITEM = 'foodsby/menu/CLEAR_MENU_ITEM'

export const loadMenuStart = deliveryDropoffId => {
  return {
    payload: {
      deliveryDropoffId,
      isMenuLoading: true,
    },
    type: LOAD_MENU,
  }
}

export const loadReserveMenuStart = storeId => {
  return {
    payload: {
      storeId,
      isMenuLoading: true,
    },
    type: LOAD_MENU,
  }
}

export const loadMenuSuccess = menu => {
  return {
    payload: { isMenuLoading: false, menu },
    type: LOAD_MENU_SUCCESS,
  }
}

export const loadMenuFailure = error => {
  return {
    error,
    payload: { isMenuLoading: false },
    type: LOAD_MENU_FAILURE,
  }
}

export const loadCateringMenuStart = storeId => {
  return {
    payload: {
      storeId,
      isMenuLoading: true,
    },
    type: LOAD_CATERING_MENU,
  }
}

export const selectMenuItem = menuItemId => {
  return {
    payload: { menuItemId },
    type: SELECT_MENU_ITEM,
  }
}

export const clearMenuItem = () => {
  return {
    type: CLEAR_MENU_ITEM,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CLEAR_MENU_ITEM]: state => {
    return {
      ...state,
      menuItemId: undefined,
    }
  },
  [LOAD_MENU]: (state, action) => {
    const {
      payload: { deliveryDropoffId },
    } = action
    return {
      ...state,
      deliveryDropoffId: deliveryDropoffId,
      isMenuLoading: true,
    }
  },
  [LOAD_MENU_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      error,
      isMenuLoading: false,
    }
  },
  [LOAD_MENU_SUCCESS]: (state, action) => {
    const {
      payload: { menu },
    } = action
    return {
      ...state,
      isMenuLoading: false,
      menu,
      error: undefined,
    }
  },
  [LOAD_CATERING_MENU]: (state, action) => {
    const {
      payload: { storeId },
    } = action
    return {
      ...state,
      storeId: storeId,
      isMenuLoading: true,
    }
  },
  [LOAD_CATERING_MENU_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      error,
      isMenuLoading: false,
    }
  },
  [LOAD_CATERING_MENU_SUCCESS]: (state, action) => {
    const {
      payload: { menu },
    } = action
    return {
      ...state,
      isMenuLoading: false,
      menu,
      error: undefined,
    }
  },
  [SELECT_MENU_ITEM]: (state, action) => {
    const {
      payload: { menuItemId },
    } = action
    return {
      ...state,
      menuItemId: menuItemId,
    }
  },
}
// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  isMenuLoading: true,
  menu: {
    subMenus: [],
  },
}

export default function menu(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* watchLoadMenu() {
  while (true) {
    try {
      yield take(LOAD_MENU)
      let [result, error] = yield race([
        take(FULFILLED(DELIVERY_DROPOFF_SCHEDULE_SET)),
        take(REJECTED(DELIVERY_DROPOFF_SCHEDULE_SET)),
      ])
      if (error) {
        throw error
      }
      yield call(
        apiSaga,
        loadMenuByStoreId,
        [result?.payload?.storeId],
        loadMenuSuccess,
        loadMenuFailure,
      )
    } catch (error) {
      yield put(loadMenuFailure(error))
    }
  }
}

export function* watchLoadReserveMenu() {
  while (true) {
    try {
      let result = yield take(LOAD_MENU)
      yield call(
        apiSaga,
        loadMenuByStoreId,
        [result?.payload?.storeId],
        loadMenuSuccess,
        loadMenuFailure,
      )
    } catch (error) {
      yield put(loadMenuFailure(error))
    }
  }
}

export function* watchLoadCateringMenu() {
  while (true) {
    try {
      let result = yield take(LOAD_CATERING_MENU)
      yield call(
        apiSaga,
        loadCateringMenuByStoreId,
        [result?.payload?.storeId],
        loadMenuSuccess,
        loadMenuFailure,
      )
    } catch (error) {
      yield put(loadMenuFailure(error))
    }
  }
}
