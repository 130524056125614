import { themeConWeb } from '@app/util/modernThemeConweb'
import { Card } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { reduxForm } from 'redux-form'

import ChangePasswordForm from '../components/changePassword/ChangePasswordForm'
import { Article, SpinnerCard } from '../components/common/Layout'
import { completeResetPasswordStart, validateTokenStart } from '../redux/modules/forgotPassword'
import { forgotPasswordRoute, loginRoute } from '../routes/routes'

const ChangePasswordContainer = reduxForm({ form: 'completeForgotPassword' })(ChangePasswordForm)

const LoginOrRegisterLinks = () => (
  <div className="row pt-15">
    <div className="col-xs-6 center-xs">
      <Link to={loginRoute.path}>
        <span className="icon-user" style={{ color: themeConWeb.color.greyShade3 }} /> Log In
      </Link>
    </div>
    <div className="col-xs-6 center-xs">
      <Link to={forgotPasswordRoute.path}>Forgot Password?</Link>
    </div>
  </div>
)

const InvalidTokenCard = () => (
  <div className="row">
    <div className="col-xs-12">
      This page has expired. Please reset your password again for a new link.
    </div>
  </div>
)

export class PageChangePassword extends Component {
  componentDidMount() {
    this.props.validateTokenStart(this.props.match.params.token)
  }

  render() {
    const {
      completeResetError,
      completeResetPasswordStart,
      initialValues,
      showCompleteResetSuccess,
      submittingCompleteReset,
      tokenValid,
      validatingToken,
    } = this.props
    return (
      <Article>
        {validatingToken ? (
          <SpinnerCard spinnerActive={validatingToken} />
        ) : (
          <Card>
            <div className="center-xs">
              {tokenValid ? (
                <ChangePasswordContainer
                  completeResetError={completeResetError}
                  initialValues={initialValues}
                  onSubmit={completeResetPasswordStart}
                  showSuccess={showCompleteResetSuccess}
                  submitting={submittingCompleteReset}
                />
              ) : (
                <InvalidTokenCard />
              )}
            </div>
            <LoginOrRegisterLinks />
          </Card>
        )}
      </Article>
    )
  }
}

const mapStateToProps = (state, entryProps) => {
  const {
    completeResetError,
    showCompleteResetFailure,
    showCompleteResetSuccess,
    submittingCompleteReset,
    tokenValid,
    validatingToken,
  } = state.forgotPassword
  return {
    completeResetError,
    initialValues: {
      resetToken: entryProps.match.params.token,
    },
    showCompleteResetFailure,
    showCompleteResetSuccess,
    submittingCompleteReset,
    tokenValid,
    validatingToken,
  }
}

const mapDispatchToProps = {
  completeResetPasswordStart,
  validateTokenStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageChangePassword)
