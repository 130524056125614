import React from 'react'
import { Grid, IconButton, Link, Typography } from '@material-ui/core'
import { Close, CloudUpload } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useDropzone } from 'react-dropzone'

const useStyles = makeStyles(theme => ({
  dropzone: {
    padding: theme.spacing(4),
    border: ({ success, error }) =>
      `solid 1px ${
        success
          ? theme.palette.success.main
          : error
          ? theme.palette.error.main
          : theme.palette.text.disabled
      }`,
    borderRadius: theme.shape.borderRadius,
  },
  dropzoneInput: {
    display: 'none',
  },
  removeButton: {
    zIndex: 1,
  },
  dropzoneTextContainer: {
    paddingLeft: theme.spacing(3),
  },
  csvTemplateText: {
    marginRight: theme.spacing(1),
  },
}))

const FileDropzone = ({ success, error, fileName, onDrop, onRemove }) => {
  const classes = useStyles({ success, error })
  const { getInputProps, getRootProps } = useDropzone({
    accept: '.csv',
    multiple: false,
    onDrop,
  })

  const primaryUploadText = fileName || (
    <>
      Drag, drop, or <Link>browse files</Link> to upload here
    </>
  )
  return (
    <Grid container className={classes.dropzone} alignItems="center" {...getRootProps()}>
      {!fileName && <input {...getInputProps()} data-cy="file-dropzone" />}
      <Grid item xs={1}>
        <CloudUpload />
      </Grid>
      <Grid item xs={9} className={classes.dropzoneTextContainer}>
        <Typography>{primaryUploadText}</Typography>
        <Typography variant="body2" color="textSecondary">
          CSV file upload
        </Typography>
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end">
        {fileName && (
          <IconButton size="small" onClick={onRemove} className={classes.removeButton}>
            <Close />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}

export default FileDropzone
