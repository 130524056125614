import React from 'react'
import { Chip, Typography } from '@material-ui/core'
import { connect } from 'react-redux'

import { setPageGiftRecipients, setSortGiftRecipients } from '../../../redux/modules/teamBilling'
import SortableTable from '../../common/SortableTable'
import {
  selectGiftRecipientsRows,
  selectGiftTransactionDetails,
} from '../../../redux/selectors/teamBillingHistorySelectors'
import HeaderTableMembers from '../common/HeaderTableMembers'
import { getFullName } from './adminUtils'

const TableGiftRecipients = ({
  recipients,
  currentPageRows,
  pageGiftRecipients,
  sortGiftRecipients,
  setPageGiftRecipients,
  setSortGiftRecipients,
}) => {
  const handleSort = (e, property) => {
    const isAsc = sortGiftRecipients.field === property && sortGiftRecipients.direction === 'asc'
    setSortGiftRecipients({
      field: property,
      direction: isAsc ? 'desc' : 'asc',
    })
  }

  const columns = [
    {
      id: 'lastName', // This field is named lastName for sorting purposes
      label: 'Name',
      sortable: true,
    },
    {
      id: 'email',
      label: 'Email',
      sortable: true,
    },
    {
      id: 'redeemed',
      label: '',
      sortable: false,
    },
  ]

  const rowCells = currentPageRows.map(recipient => ({
    memberId: recipient.userId,
    cells: [
      {
        Component: <Typography>{getFullName(recipient)}</Typography>,
      },
      {
        Component: <Typography>{recipient.email}</Typography>,
      },
      {
        Component: !recipient.redeemed && (
          <Chip label="Not redeemed" color="primary" size="small" />
        ),
      },
    ],
  }))

  return (
    <SortableTable
      data={recipients}
      rowCells={rowCells}
      page={pageGiftRecipients}
      setPage={setPageGiftRecipients}
      HeaderComponent={
        <HeaderTableMembers
          teamMembers={recipients}
          columns={columns}
          rows={currentPageRows}
          sort={sortGiftRecipients}
          onSort={handleSort}
        />
      }
    />
  )
}

const mapStateToProps = state => {
  const currentPageRows = selectGiftRecipientsRows(state)
  const giftTransactionDetails = selectGiftTransactionDetails(state)
  const { pageGiftRecipients, sortGiftRecipients } = state.teamBilling
  return {
    currentPageRows,
    pageGiftRecipients,
    sortGiftRecipients,
    recipients: giftTransactionDetails?.recipients,
  }
}

const mapDispatchToProps = {
  setPageGiftRecipients,
  setSortGiftRecipients,
}

export default connect(mapStateToProps, mapDispatchToProps)(TableGiftRecipients)
