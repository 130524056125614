import React from 'react'
import { Typography } from '@material-ui/core'
import scriptLoader from 'react-async-script-loader'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'

import { ArticleLarge, SpinnerCard } from '../components/common/Layout'
import { MAPS_SCRIPT_URL } from '../components/location-search/LocationSearchBox'
import LocationSearchContainer from '../components/location-search/LocationSearchContainer'
import LoginFormContainer from '../components/login/LoginFormContainer'
import { DialogSelectLocation } from '../components/register/DialogSelectLocation'
import { rootRoute } from '../routes/routes'

const styles = {
  Header: {
    paddingTop: '60px',
    textTransform: 'Uppercase',
  },
  Login: {
    padding: '32px',
  },
  Search: {
    borderLeft: '1px solid #bfbfbf',
    padding: '32px',
  },
  Title: {
    marginBottom: '16px',
    textAlign: 'center',
  },
}

const PageLogin = ({
  isAuthenticated,
  isCurrentUserLoading,
  isScriptLoadSucceed,
  isScriptLoaded,
  location: { refer },
}) => {
  if (isAuthenticated && !isCurrentUserLoading) {
    return <Redirect to={rootRoute.path} />
  }

  return (
    <ArticleLarge>
      <div>
        <div className="row center-xs">
          <div className="col-xs-12">
            <h1 className="primary" style={styles.Header}>
              Ready to Order?
            </h1>
            <Typography>Not a member yet? Search your address!</Typography>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6" style={styles.Login}>
            <h2 className="title" style={styles.Title}>
              Login
            </h2>
            <SpinnerCard spinnerActive={isCurrentUserLoading}>
              <LoginFormContainer path={refer} />
            </SpinnerCard>
          </div>
          <div className="col-xs-12 col-md-6" style={styles.Search}>
            <h3 className="title" style={styles.Title}>
              Not a member? Find your location
            </h3>
            <SpinnerCard spinnerActive={isCurrentUserLoading}>
              <LocationSearchContainer />
            </SpinnerCard>
          </div>
        </div>
      </div>
      <DialogSelectLocation
        isScriptLoaded={isScriptLoaded}
        isScriptLoadSucceed={isScriptLoadSucceed}
      />
    </ArticleLarge>
  )
}

const mapStateToProps = state => {
  const { isAuthenticated, isCurrentUserLoading } = state.user

  return {
    isAuthenticated,
    isCurrentUserLoading,
  }
}

export default scriptLoader(MAPS_SCRIPT_URL)(withRouter(connect(mapStateToProps)(PageLogin)))
