import React from 'react'
import DocumentTitle from 'react-document-title'
import { Route } from 'react-router-dom'
import GlobalDataLoader from '../GlobalDataLoader'
import { FOODSBY_GENERIC_TITLE } from './layoutRoutes'

export const RouteWithGlobalLoader = ({
  component: Component,
  title = FOODSBY_GENERIC_TITLE,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <GlobalDataLoader match={matchProps.match}>
          <DocumentTitle title={title}>
            <Component {...matchProps} />
          </DocumentTitle>
        </GlobalDataLoader>
      )}
    />
  )
}

export default RouteWithGlobalLoader
