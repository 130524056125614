import React from 'react'
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ROWS_PER_PAGE } from '../../util/constants'

const useStyles = makeStyles({
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
})

const SortableTable = ({
  data,
  filteredData,
  rowCells,
  page,
  setPage,
  loading,
  HeaderComponent,
}) => {
  const classes = useStyles()

  const handleChangePage = (e, page) => {
    setPage(page)
  }

  const totalAvailableData = filteredData?.length || data.length
  return (
    <>
      <TableContainer component={Paper}>
        {loading && <CircularProgress className={classes.circularProgress} />}
        <Table>
          {HeaderComponent}
          <TableBody>
            {rowCells.map(rowCell => (
              <TableRow key={rowCell.memberId}>
                {rowCell.cells.map(({ labelId, Component }, index) => (
                  <TableCell key={index} id={labelId}>
                    {Component}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalAvailableData > ROWS_PER_PAGE && (
        <TablePagination
          component="div"
          count={totalAvailableData}
          page={page}
          rowsPerPage={ROWS_PER_PAGE}
          rowsPerPageOptions={[]}
          onChangePage={handleChangePage}
        />
      )}
    </>
  )
}

export default SortableTable
