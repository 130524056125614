export const DAYS_TO_FETCH_ORDER_DETAILS = 10

export const orderStateText = {
  CANCELED: 'Cancelled',
  CREATED: 'On Time',
  DELIVERED: 'Delivered',
  LATE: 'Running Late',
  ON_TIME: 'On Time',
}

export function sortOrdersFromEarliestToLatest(orders) {
  return orders.sort(
    (a, b) => new Date(a.orderDateTime).getTime() - new Date(b.orderDateTime).getTime(),
  )
}

export function excludeReplacedCanceledOrders(orders) {
  return orders.filter(order => {
    return order.orderState === 'CANCELED' &&
      orders.find(
        o => o.orderState !== 'CANCELED' && o.deliveryDropoffId === order.deliveryDropoffId,
      )
      ? false
      : true
  })
}

export const findFriendProfileForUser = (friendsOrders, userId) =>
  friendsOrders?.find(friendOrdered => friendOrdered.friend.userId === userId)?.friend

export const findOrdersByDate = (orders, selectedDate) => {
  let year = parseInt(selectedDate.substring(0, 4))
  let month = parseInt(selectedDate.substring(4, 6)) - 1 // Months are 0-indexed in JS Date
  let day = parseInt(selectedDate.substring(6, 8))
  let parsedSelectedDate = new Date(year, month, day)

  return orders.filter(order => {
    let orderDate = new Date(order.orderDate)
    return orderDate.toDateString() === parsedSelectedDate.toDateString()
  })
}
