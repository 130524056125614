import { push } from 'connected-react-router'
import queryString from 'query-string'
import {
  acceptInviteApi,
  declineInviteApi,
  getAggregatedTeamApi,
  getTeamForInviteCodeApi,
  selfRemoveFromTeamApi,
} from '../../services/team'
import { selectQuery } from '../selectors/routerSelectors'
import { selectUserTeam } from '../selectors/userTeamSelectors'

import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import { selectCurrentUser } from './selectors'
import { enqueueSnackbar } from './snackbar'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const USER_TEAM_SET = 'foodsby/userTeam/USER_TEAM_SET'
export const INVITE_ACCEPT = 'foodsby/userTeam/INVITE_ACCEPT'
export const INVITE_DECLINE = 'foodsby/userTeam/INVITE_DECLINE'
export const INVITER_TEAM_NAME_SET = 'foodsby/userTeam/INVITER_TEAM_NAME_SET'
export const LEAVE = 'foodsby/userTeam/LEAVE'
export const STATE_RESET_LEAVE = 'foodsby/userTeam/STATE_RESET_LEAVE'
export const PENDING_ACCEPTED_INVITE_SET = 'foodsby/userTeam/PENDING_ACCEPTED_INVITE_SET'

export const setUserTeam = createAsyncAction(USER_TEAM_SET)
export const acceptInvite = createAsyncAction(INVITE_ACCEPT)
export const declineInvite = createAsyncAction(INVITE_DECLINE)
export const setInviterTeamName = createAsyncAction(INVITER_TEAM_NAME_SET)
export const leave = createAsyncAction(LEAVE)
export const resetLeaveState = createAction(STATE_RESET_LEAVE)
export const setPendingAcceptedInvite = createAction(PENDING_ACCEPTED_INVITE_SET)

// ------------------------------------
// Thunks
// ------------------------------------
export const loadUserTeamStart = () => {
  return async dispatch => {
    await dispatch(setUserTeam(getAggregatedTeamApi()))
  }
}

export const acceptInviteStart = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const userTeam = selectUserTeam(state)
    const { teamInviteCode } = selectQuery(state)
    const pendingAcceptedInvite = state.pendingAcceptedInvite

    if (userTeam?.invitation || teamInviteCode) {
      try {
        await dispatch(
          acceptInvite(acceptInviteApi(userTeam?.invitation?.teamInviteCode || teamInviteCode)),
        )
        dispatch(enqueueSnackbar({ message: 'Congrats! You just joined the office.' }))
      } catch (ex) {
        return dispatch(
          enqueueSnackbar({
            message:
              'Something went wrong accepting the invite. Please try again or contact support.',
          }),
        )
      } finally {
        dispatch(push({ search: queryString.stringify({ teamInviteCode: undefined }) }))
        dispatch(loadUserTeamStart())
        if (pendingAcceptedInvite) {
          dispatch(setPendingAcceptedInvite(false))
        }
      }
    }
  }
}

export const declineInviteStart = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const userTeam = selectUserTeam(state)
    const { teamInviteCode } = selectQuery(state)

    if (userTeam?.invitation) {
      // Only if the user was invited by email, decline the invitation
      try {
        await dispatch(declineInvite(declineInviteApi()))
      } catch (ex) {
        dispatch(
          enqueueSnackbar({
            message: `An error occurred. ${ex?.message}`,
          }),
        )
      }
    }

    if (teamInviteCode) {
      await dispatch(push({ search: queryString.stringify({ teamInviteCode: undefined }) }))
    }

    await dispatch(loadUserTeamStart())
  }
}

export const loadInviterTeamNameStart = teamInviteCode => {
  return async dispatch => {
    try {
      await dispatch(setInviterTeamName(getTeamForInviteCodeApi(teamInviteCode)))
    } catch ({ code }) {
      if (code === 404) {
        const message = 'Invalid invitation link'
        return dispatch(enqueueSnackbar({ message }))
      }

      dispatch(
        enqueueSnackbar({
          message:
            'Something went wrong retrieving office info. Please try again or contact support.',
        }),
      )
    }
  }
}

export const leaveTeamStart = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const userTeam = selectUserTeam(state)
    const currentUser = selectCurrentUser(state)

    try {
      await dispatch(leave(selfRemoveFromTeamApi(userTeam.team.accountId, currentUser.userId)))
      dispatch(loadUserTeamStart())
      dispatch(
        enqueueSnackbar({
          message: "You've successfully left the team.",
        }),
      )
    } catch (ex) {
      dispatch(
        enqueueSnackbar({
          message: `An error occurred. ${ex?.message}`,
        }),
      )
    }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PENDING(USER_TEAM_SET)]: state => {
    return {
      ...state,
      isLoadingUserTeam: true,
    }
  },
  [FULFILLED(USER_TEAM_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingUserTeam: false,
      userTeamInitialized: true,
      errorLoadingUserTeam: undefined,
      userTeam: action.payload,
    }
  },
  [REJECTED(USER_TEAM_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingUserTeam: false,
      userTeamInitialized: true,
      errorLoadingUserTeam: action.error,
    }
  },
  [PENDING(INVITE_ACCEPT)]: state => {
    return {
      ...state,
      isAcceptingInvite: true,
    }
  },
  [FULFILLED(INVITE_ACCEPT)]: state => {
    return {
      ...state,
      isAcceptingInvite: false,
    }
  },
  [REJECTED(INVITE_ACCEPT)]: state => {
    return {
      ...state,
      isAcceptingInvite: false,
    }
  },
  [PENDING(INVITE_DECLINE)]: state => {
    return {
      ...state,
      isDecliningInvite: true,
    }
  },
  [FULFILLED(INVITE_DECLINE)]: state => {
    return {
      ...state,
      isDecliningInvite: false,
    }
  },
  [REJECTED(INVITE_DECLINE)]: state => {
    return {
      ...state,
      isDecliningInvite: false,
    }
  },
  [FULFILLED(INVITER_TEAM_NAME_SET)]: (state, action) => {
    return {
      ...state,
      inviterTeamName: action.payload?.name,
    }
  },
  [PENDING(LEAVE)]: state => {
    return {
      ...state,
      isLeaving: true,
      errorLeaving: undefined,
    }
  },
  [FULFILLED(LEAVE)]: state => {
    return {
      ...state,
      isLeaving: false,
      errorLeaving: undefined,
    }
  },
  [REJECTED(LEAVE)]: (state, action) => {
    return {
      ...state,
      isLeaving: false,
      errorLeaving:
        action.payload?.message ||
        'Something went wrong leaving the team. Please try again later or contact support.',
    }
  },
  [STATE_RESET_LEAVE]: state => {
    return {
      ...state,
      isLeaving: false,
      errorLeaving: undefined,
    }
  },
  [PENDING_ACCEPTED_INVITE_SET]: state => {
    return {
      ...state,
      pendingAcceptedInvite: true,
    }
  },
}

export const initialState = {
  // This is the aggregated team response that includes if the user has a team, has an invitation, and is eligible
  // TODO We can split this state into `teamMembership`, `invitation`, and `eligible` so we know what is aggregated inside of this object
  userTeam: undefined,
  userTeamInitialized: false,
  pendingAcceptedInvite: false,
  // Loading states
  isLoadingUserTeam: true,
  isAcceptingInvite: false,
  isDecliningInvite: false,
  isLeaving: false,
  // Error states
  errorLoadingUserTeam: undefined,
  errorLeaving: undefined,
}

export default function userTeam(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
