import { parse, format } from 'date-fns'
import { createSelector } from 'reselect'

import { getLocationActivationDate } from '../modules/selectors'
import { convertTo12HourFormat } from './utils/time'

export const selectDeliveries = createSelector(
  [state => state.delivery.deliveries],
  deliveries => deliveries,
)

export const selectSelectedDate = createSelector(
  [state => state.delivery.selectedDate],
  selectedDate => selectedDate,
)

export const isSelectedDeliveryDateActive = createSelector(
  [selectSelectedDate, getLocationActivationDate],
  (selectedDate, activationDate) => {
    let comparableSelectedDate = selectedDate === undefined ? new Date() : parse(selectedDate)
    let parsedactivationDate = parse(activationDate)
    let comparableactivationDate = new Date(
      parsedactivationDate.getFullYear(),
      parsedactivationDate.getMonth(),
      parsedactivationDate.getDate(),
    )
    return comparableSelectedDate >= comparableactivationDate
  },
)

export const selectFormattedCateringDeliveryDate = createSelector(
  [state => state.delivery.cateringDeliveryDate],
  cateringDeliveryDate => {
    if (!cateringDeliveryDate) {
      return
    }
    const parsedDate = parse(cateringDeliveryDate, 'YYYY-MM-DD', new Date())
    const formattedDate = format(parsedDate, 'MM/DD/YYYY')
    return formattedDate
  },
)

export const selectFormattedCateringDeliveryTime = createSelector(
  [state => state.delivery.cateringDeliveryTime],
  cateringDeliveryTime => {
    return convertTo12HourFormat(cateringDeliveryTime)
  },
)

export const selectFormattedReserveDeliveryDate = createSelector(
  [state => state.delivery.reserveDeliveryDate],
  reserveDeliveryDate => {
    if (!reserveDeliveryDate) {
      return
    }
    const parsedDate = parse(reserveDeliveryDate, 'YYYY-MM-DD', new Date())
    const formattedDate = format(parsedDate, 'MM/DD/YYYY')
    return formattedDate
  },
)

export const selectFormattedReserveDeliveryTime = createSelector(
  [state => state.delivery.reserveDeliveryTime],
  reserveDeliveryTime => {
    return convertTo12HourFormat(reserveDeliveryTime)
  },
)
