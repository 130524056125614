import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextField, Button, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import currency from 'currency.js'

import { TextMaskDollarBills } from '../common/InputMasks'
import { applyGiftStart } from '../../redux/modules/checkout'
import { selectSuggestedGifts } from '../../redux/modules/selectors'

const useStyles = makeStyles({
  inputRoot: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  applyButton: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    height: 40,
  },
})

export const Gifts = ({ isSubscribed, storedCurrencyUsage, isCheckoutCartLoading }) => {
  const classes = useStyles()
  const [value, setValue] = useState('')
  const dispatch = useDispatch()
  const error = useSelector(state => state.checkout.errorApplyingGift)
  const submitting = useSelector(state => state.checkout.isGiftApplying)
  const suggestedGifts = useSelector(selectSuggestedGifts)

  const applyGift = async giftAmount => {
    dispatch(applyGiftStart(currency(giftAmount).intValue, isSubscribed))
  }

  const handleApplyGift = event => {
    event.preventDefault()
    applyGift(value)
  }

  const handleRemoveGift = event => {
    event.preventDefault()
    applyGift(0)
  }

  useEffect(() => {
    setValue(suggestedGifts)
  }, [suggestedGifts])

  const giftBalance = storedCurrencyUsage?.storedCurrencyBalance
  const giftUsedAmount = storedCurrencyUsage?.storedCurrencyBalanceUsed / 100
  const giftApplied = storedCurrencyUsage?.storedCurrencyApplied
  return (
    <Box mb={3}>
      <Box display="flex" alignItems="flex-start">
        <TextField
          disabled={giftApplied}
          label="Gift Amount"
          fullWidth
          variant="outlined"
          color="secondary"
          size="small"
          InputProps={{
            classes: {
              root: classes.inputRoot,
            },
            inputComponent: TextMaskDollarBills,
          }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              e.preventDefault()
              applyGift(value)
            }
          }}
          onChange={val => setValue(val)}
          value={giftApplied ? giftUsedAmount : value}
          error={error}
          helperText={
            error ||
            `of available: ${currency(giftBalance / 100, {
              formatWithSymbol: true,
            }).format()}`
          }
        />
        <Button
          variant="contained"
          disableElevation
          color="secondary"
          classes={{ root: classes.applyButton }}
          disabled={submitting || isCheckoutCartLoading}
          onClick={giftApplied ? handleRemoveGift : handleApplyGift}
        >
          {giftApplied ? 'Remove' : 'Apply'}
        </Button>
      </Box>
    </Box>
  )
}

export default Gifts
