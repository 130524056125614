import React from 'react'

const TermsOfUseRewards = () => (
  <div>
    <section>
      <div className="row center-xs">
        <div className="col-xs-12 center-xs">
          <h1 className="title">Foodsby Rewards Program</h1>
        </div>
      </div>
    </section>

    <section>
      <div className="row">
        <div className="col-xs-12 start-xs">
          <p>
            These Program Terms apply to your participation in the Foodsby Rewards program (the
            “Program”) which is operated by Foodsby, Inc. (“Foodsby”). By participating in the
            Program, you agree to abide by these Program Terms. The Program is available only to
            U.S. residents who are at least eighteen (18) years of age and who are active
            Foodsby.com account holders. If you are not currently a Foodsby.com account holder,
            please visit{' '}
            <a href="https://order.foodsby.com/login">https://order.foodsby.com/login</a> and search
            for your office building. If your office building is in the Foodsby.com network, follow
            the prompts to register for an account. Members of employer paid programs are not
            eligible to participate in the Program.
          </p>
          <p>
            <strong style={{ textDecoration: 'underline' }}>How to Participate</strong>
          </p>
          <p>
            To be eligible to earn Credits (as described further below), you must add other Foodsby
            account holders as friends. To add a friend, log in to your Foodsby.com account and go
            to the Manage Friends page. On the Manage Friends page, enter the e-mail address of the
            account holder you wish to be friends with and send a friend request. If your friend
            request is accepted, the account holder will become your friend on Foodsby.com (a
            “Friend”) and you will have access to certain information about one another’s ordering
            activity, including what restaurants you’ve ordered from, what items you’ve ordered, and
            information regarding frequently ordered meals at given restaurants. You may “unfriend”
            a Friend at any time via your Manage Friends page. A Friend may also “unfriend” you at
            any time. To learn more about how Foodsby may use and share your information, please
            review our privacy policy, at{' '}
            <a href="https://www.foodsby.com/main/privacy-policy/">
              https://www.foodsby.com/main/privacy-policy/
            </a>
            .
          </p>
          <p>
            <strong style={{ textDecoration: 'underline' }}>How to Earn Credits</strong>
          </p>
          <div>
            You will earn a credit of $1.00 toward a future Foodsby.com order (each, a “Credit”)
            when:
            <p />
            <ul>
              <li>You and at least five (5) of your Friends place orders on Foodsby.com; and</li>
              <li>Those orders are delivered to the same building on the same business day.</li>
            </ul>
            Deliveries need not all be from the same restaurant. You can earn a maximum of one (1)
            Credit per day. Information on available Credits can be viewed on your Profile Page on
            Foodsby.com.
          </div>
          <br />
          <p>
            <strong style={{ textDecoration: 'underline' }}>How to Redeem Credits</strong>
          </p>
          <p>
            When placing an order on Foodsby.com, your available Credits, if any, will automatically
            display on the Order Page. You may select how many available Credits you wish to apply
            to your order, however, you may not redeem Credits in excess of the total cost of the
            order. Credits that are applied to your order will be deducted from your order total.
            Credits are applied to the pre-tax amount of your order and may not be applied to taxes.
            If redeeming sufficient Credits to cover the entire pre-tax cost of your order, you will
            not be eligible to earn a Credit on that order.
          </p>
          <p>
            <strong>
              Credits are valid for ninety (90) days only. Credits will expire and be deemed
              forfeited if not redeemed within ninety (90) days from the date the Credit was issued.
              Foodsby will apply Credits in reverse chronological order, applying the oldest Credit
              first.
            </strong>
          </p>
          <p>
            Credits accumulated under the Program are promotional only. Credits: (a) have no cash
            value; (b) may not be redeemed or exchanged for cash or any cash equivalent; (c) may not
            be combined with any other offer, promotion, discounts, or coupons; (d) are not
            transferable; and (e) may not be substituted (except by Foodsby, in its sole
            discretion). You are not obligated to utilize any of your Credits.
          </p>

          <p>
            Foodsby is not responsible for: (a) incorrect or inaccurate transcription or posting of
            Program information, including orders placed and issuance or redemption of Credits; (b)
            the failure of any restaurant to receive, process, or deliver an order; (c) technical
            failures of any kind, including but not limited to the malfunctioning of any telephone,
            computer online systems, computer equipment, website, server provider, network, hardware
            or software; (d) the unavailability or inaccessibility of any website or service; (e)
            unauthorized intervention in any part of the Program; (f) any injury or damage to
            persons or property, including but not limited to entrant’s computer, phone or other
            devices, which may be caused, directly or indirectly, in whole or in part, from
            entrant’s participation in the Program; or (g) lost, stolen or expired Credits, none of
            which will be replaced.
          </p>

          <p>
            Foodsby reserves the right to change, modify, terminate, discontinue, or cancel the
            Program and/or any portion of the Program at any time in its sole discretion without
            notice to you. Any changes or modifications will be effective immediately upon posting
            the revisions to www.foodsby.com. Your participation in the Program confirms your
            acceptance of these Program Terms and any such changes or modifications; therefore, you
            should review these Program Terms frequently.
          </p>

          <p>
            The Program is void where prohibited or restricted by federal, state or local law. These
            Program Terms complement and incorporate by reference the Foodsby.com Terms of Use,
            which are located at
            <a href="https://www.foodsby.com/main/terms-of-use">
              https://www.foodsby.com/main/terms-of-use
            </a>
            . In the event of any conflict between the Foodsby.com Terms of Use and these Program
            Terms, the Program Terms control. If any provision of these Program Terms is found to be
            invalid or unenforceable by a court of competent jurisdiction, such provision shall be
            severed from the remainder of these Program Terms, which will otherwise remain in full
            force and effect. The Program is valid only in the United States.
          </p>

          <p>
            If you have any questions regarding the Program, please contact us at{' '}
            <a href="mailto:support@foodsby.com">support@foodsby.com</a>.
          </p>
        </div>
      </div>
    </section>
  </div>
)

export default TermsOfUseRewards
