import { Box, Grid, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { Skeleton } from '@material-ui/lab'
import LocationDeliveryMap from './LocationDeliveryMap'
import LocationManagerHeader from './LocationManagerHeader'
import LocationImages from './LocationImages'
import CardLocation from './CardLocation'

const LocationDeliveryTab = ({
  loading,
  locationUserManages,
  name,
  locationId,
  locationImages,
}) => {
  if (loading) {
    return <SkeletonLocationDeliveryTab locationId={locationId} name={name} />
  }

  return (
    <Grid>
      <LocationManagerHeader locationId={locationId} name={name} />
      <Box marginTop={3}>
        <CardLocation>
          <Typography variant="h6">Search & Parking Setup</Typography>
          <Box marginTop={'1em'}>
            <LocationDeliveryMap
              latitude={locationUserManages.latitude}
              longitude={locationUserManages.longitude}
              parkingLatitude={locationUserManages.parkingLatitude}
              parkingLongitude={locationUserManages.parkingLongitude}
              polygonCoordinates={locationUserManages.locationShape.slice(0, -1).map(point => ({
                lng: point.x,
                lat: point.y,
              }))}
            />
          </Box>
        </CardLocation>
        <Box marginTop={3}>
          <CardLocation>
            <Typography variant="h6">Delivery Instructions</Typography>
            <Grid container spacing={3} style={{ marginTop: '1em' }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  variant="outlined"
                  fullWidth
                  multiline
                  rows="4"
                  label="Delivery Instructions"
                  value={locationUserManages.dropoffInstruction}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  variant="outlined"
                  fullWidth
                  multiline
                  rows="4"
                  label="Pickup Instructions"
                  value={locationUserManages.pickupInstruction}
                />
              </Grid>
            </Grid>
          </CardLocation>
        </Box>
        <Box marginTop={3}>
          <CardLocation>
            <Typography variant="h6">Images</Typography>
            <Box marginTop={'1em'}>
              {locationImages.length > 0 ? (
                <LocationImages images={locationImages} />
              ) : (
                <Typography>No images uploaded.</Typography>
              )}
            </Box>
          </CardLocation>
        </Box>
      </Box>
    </Grid>
  )
}

const SkeletonLocationDeliveryTab = ({ locationId, name }) => {
  return (
    <>
      <LocationManagerHeader locationId={locationId} name={name} />
      <Box mt={3}>
        <CardLocation>
          <Typography variant="h6">Search & Parking Setup</Typography>
          <Box marginTop={'1em'} height={450} overflow="hidden">
            <Skeleton variant="rect" height="100%" />
          </Box>
        </CardLocation>
        <Box mt={3}>
          <CardLocation>
            <Typography variant="h6">Delivery Instructions</Typography>
            <Grid container spacing={3} style={{ marginTop: '1em' }}>
              <Grid item xs={12} sm={6}>
                <Box height={100} width={'100%'} overflow="hidden">
                  <Skeleton variant="rect" height="100%" width="100%" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box height={100} width={'100%'} overflow="hidden">
                  <Skeleton variant="rect" height="100%" width="100%" />
                </Box>
              </Grid>
            </Grid>
          </CardLocation>
        </Box>
        <Box mt={3}>
          <CardLocation>
            <Typography variant="h6">Images</Typography>
            <Box marginTop="1em">
              <Box height={100} width={'100%'} overflow="hidden">
                <Skeleton variant="rect" height="100%" width="100%" />
              </Box>
              <Box marginTop="1em" height={100} width={'100%'} overflow="hidden">
                <Skeleton variant="rect" height="100%" width="100%" />
              </Box>
              <Box marginTop="1em" height={100} width={'100%'} overflow="hidden">
                <Skeleton variant="rect" height="100%" width="100%" />
              </Box>
            </Box>
          </CardLocation>
        </Box>
      </Box>
    </>
  )
}

export default LocationDeliveryTab
