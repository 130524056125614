import React, { useEffect, useRef, useState } from 'react'

import AsideContentCards from './AsideContentCards'
import ButtonContentCards from './ButtonContentCards'

const ContentCards = () => {
  const contentCardsRef = useRef()
  const [showContentCards, setShowContentCards] = useState(false)
  const [hasUnviewedContentCard, setHasUnviewedContentCard] = useState(false)
  const [errorRefreshingContentCards, setErrorRefreshingContentCards] = useState(undefined)

  const handleClick = () => {
    if (showContentCards) {
      handleClose()
    } else {
      setShowContentCards(true)
    }
  }

  const handleClose = () => {
    setShowContentCards(false)
    setHasUnviewedContentCard(false)
  }

  const handleRefreshSuccess = () => {
    contentCardsRef.current = window.braze.getCachedContentCards().cards
  }

  const handleRefreshError = () => {
    setErrorRefreshingContentCards(
      'Something went wrong loading your notifications. Please try again or contact support.',
    )
  }

  useEffect(() => {
    window.braze.requestContentCardsRefresh(handleRefreshSuccess, handleRefreshError)

    window.braze.subscribeToContentCardsUpdates(({ cards }) => {
      contentCardsRef.current = cards
      setHasUnviewedContentCard(cards.some(card => card.viewed === false))
    })
  }, [])

  return (
    <>
      <ButtonContentCards onClick={handleClick} unviewed={hasUnviewedContentCard} />
      {showContentCards && (
        <AsideContentCards
          ref={contentCardsRef}
          error={errorRefreshingContentCards}
          onClose={handleClose}
        />
      )}
    </>
  )
}

export default ContentCards
