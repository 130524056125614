import { InputError } from '@foodsby/nutrient'
import { CircularProgress, Fade, Grid, Switch, Typography } from '@material-ui/core'
import React from 'react'

import { Panel } from '../common/Layout'
import PanelHeader from '../common/PanelHeader'

export const PanelPrivacyPreferences = ({
  privacy,
  updateUserPrivacyError,
  updateUserPrivacyStart,
  userPrivacyLoading,
}) => {
  return (
    <Panel>
      <PanelHeader
        headerRight={
          <Fade in={userPrivacyLoading}>
            <CircularProgress size={16} />
          </Fade>
        }
        title="Co-worker visibility"
      />

      <Grid container>
        <Grid item xs={9}>
          <Typography variant="body2">
            Appear to your co-workers on Foodsby, like for ordering favorites.
          </Typography>
        </Grid>
        {privacy && (
          <Grid item xs={3}>
            <Switch
              color="primary"
              defaultChecked={privacy.searchable}
              inputProps={{ 'aria-label': 'public profile preference switch' }}
              name="searchable"
              onChange={e => updateUserPrivacyStart({ searchable: e.target.checked })}
            />
          </Grid>
        )}
      </Grid>

      {updateUserPrivacyError && (
        <InputError visible={updateUserPrivacyError && updateUserPrivacyError !== null}>
          {(updateUserPrivacyError.data && updateUserPrivacyError.data.error) ||
            'Oops! Something went wrong. If this continues to happen, please contact support.'}
        </InputError>
      )}
    </Panel>
  )
}

export default PanelPrivacyPreferences
