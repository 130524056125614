import { push } from 'connected-react-router'
import { differenceInCalendarDays } from 'date-fns'

import { resetCaches } from '../../api/api'
import { confirmationRoute } from '../../routes/routes'
import {
  submitCoupon,
  submitCredit,
  getProgramForUser,
  submitGift,
  createOrUpdateCateringDeliveryInstructionsApi,
} from '../../services/checkout'
import { patchUserProfile } from '../../services/profile'
import { BRAZE_CUSTOM_EVENTS, publishBrazeEvent } from '../../util/braze'
import {
  logException,
  normalizeApplyCouponErrorResponse,
  normalizeErrorResponse,
} from '../../util/errorUtils'
import { formatUrl } from '../../util/formatUtils'
import { pushAnalyticsEvent, pushGTMDataLayer } from '../../util/gtmUtils'
import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import { combineCateringDeliveryInstructionsWithUtensilsHeadcount } from '../../util/cateringUtils'
import { loadCheckoutCartStart } from './cart'
import { updateUserSavedLocationStart } from './location'
import { loadProfileStart } from './profile'
import {
  selectCouponUsage,
  selectCurrentDeliveryDropoff,
  selectCurrentSavedLocation,
  selectCurrentUser,
  selectLocationDeliveryId,
  selectOrderTotal,
  selectProfile,
} from './selectors'
import { resetSelectedDateToToday } from './delivery'

// ------------------------------------
// CONSTs
// ------------------------------------
const CHECKOUT_ERROR = 'There was an error checking out.'
export const CREDIT_CARDS_ERROR = 'There was an error loading your saved credit cards.'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const READY_TO_PROCESS_SET = 'foodsby/checkout/READY_TO_PROCESS_SET'
export const CHECKOUT = 'foodsby/checkout/CHECKOUT'
export const CHECKOUT_ERROR_RESET = 'foodsby/checkout/CHECKOUT_ERROR_RESET'
export const COUPON_APPLY = 'foodsby/checkout/COUPON_APPLY'
export const CREDIT_APPLY = 'foodsby/checkout/CREDIT_APPLY'
export const GIFT_APPLY = 'foodsby/checkout/GIFT_APPLY'
export const AUTO_FAVORITE_MODAL_CLOSE = 'foodsby/checkout/AUTO_FAVORITE_MODAL_CLOSE'
export const AVAILABLE_PROGRAM_SET = 'foodsby/checkout/AVAILABLE_PROGRAM_SET'
export const CATERING_DELIVERY_INSTRUCTIONS_SET =
  'foodsby/checkout/CATERING_DELIVERY_INSTRUCTIONS_SET'

export const setReadyToProcessPending = createAction(PENDING(READY_TO_PROCESS_SET))
export const setReadyToProcessFulfilled = createAction(FULFILLED(READY_TO_PROCESS_SET))
export const setReadyToProcessRejected = createAction(REJECTED(READY_TO_PROCESS_SET))
export const checkoutPending = createAction(PENDING(CHECKOUT))
export const checkoutRejected = createAction(REJECTED(CHECKOUT))
export const resetCheckoutError = createAction(CHECKOUT_ERROR_RESET)
export const applyCoupon = createAsyncAction(COUPON_APPLY)
export const applyCredit = createAsyncAction(CREDIT_APPLY)
export const applyGift = createAsyncAction(GIFT_APPLY)
export const closeAutoFavorite = createAction(AUTO_FAVORITE_MODAL_CLOSE)
export const setAvailableProgram = createAsyncAction(AVAILABLE_PROGRAM_SET)
export const setCateringDeliveryInstructions = createAsyncAction(CATERING_DELIVERY_INSTRUCTIONS_SET)

// ------------------------------------
// Thunks
// ------------------------------------
export const checkoutStart = checkoutData => {
  return async (dispatch, getState) => {
    const state = getState()
    const curSavedLocation = selectCurrentSavedLocation(state)
    const { userId } = selectCurrentUser(state)
    const { firstName, lastName, phone } = selectProfile(state)
    const { dropoffId } = selectCurrentDeliveryDropoff(state)

    dispatch(setReadyToProcessPending())

    if (
      firstName !== checkoutData.User.firstName ||
      lastName !== checkoutData.User.lastName ||
      phone !== checkoutData.User.phone
    ) {
      try {
        await patchUserProfile(userId, {
          firstName: checkoutData.User.firstName,
          lastName: checkoutData.User.lastName,
          phone: checkoutData.User.phone,
        })
        dispatch(loadProfileStart())
      } catch (ex) {
        dispatch(setReadyToProcessRejected(ex))
        return
      }
    }

    if (curSavedLocation?.deliveryInstructionsSupported) {
      await dispatch(
        updateUserSavedLocationStart(
          curSavedLocation.savedLocationId,
          null,
          checkoutData.deliveryInstructions,
        ),
      )
    }

    if (
      (checkoutData.User.cateringDeliveryInstructions &&
        checkoutData.User.cateringDeliveryInstructions !== '') ||
      (checkoutData.User.cateringUtensils && checkoutData.User.cateringUtensils > 0)
    ) {
      await dispatch(
        addOrUpdateCateringDeliveryInstructionsStart(
          dropoffId,
          combineCateringDeliveryInstructionsWithUtensilsHeadcount(
            checkoutData.User.cateringDeliveryInstructions,
            checkoutData.User.utensilsRequested,
            checkoutData.User.cateringHeadcount,
          ),
        ),
      )
    }

    dispatch(setReadyToProcessFulfilled(checkoutData))
  }
}

export const checkoutComplete = () => {
  return (dispatch, getState) => {
    dispatch(checkoutPending())
    resetCaches()
    const state = getState()
    const checkoutCart = selectOrderTotal(state)
    const { deliveryDateTime, storeId, storeName } = selectCurrentDeliveryDropoff(state)
    const locationId = selectLocationDeliveryId(state)
    const { couponCode } = selectCouponUsage(state)

    try {
      pushAnalyticsEvent('Ordering', 'Checked Out', undefined, checkoutCart.orderId)
      pushGTMDataLayer({
        event: 'purchase',
        orderAmount: checkoutCart.itemSubTotal,
        orderCouponAmount: checkoutCart.couponSubTotal,
        orderCouponName: couponCode,
        orderCredits: checkoutCart.orderCreditSubTotal,
        orderDeliveryDaysFromToday: differenceInCalendarDays(deliveryDateTime, new Date()),
        orderDeliveryFee: checkoutCart.foodsbyFee,
        orderId: checkoutCart.orderId,
        orderRestaurantId: storeId,
        orderRestaurantName: storeName,
        orderSubtotal: checkoutCart.preTaxSubTotal,
        orderTax: checkoutCart.taxSubTotal,
        type: 'single',
      })
    } catch (ex) {
      logException(ex)
    }

    dispatch(resetSelectedDateToToday())

    dispatch(
      push(
        formatUrl(confirmationRoute.path, {
          locationId: locationId,
          orderId: checkoutCart.orderId,
        }),
      ),
    )
    publishBrazeEvent(BRAZE_CUSTOM_EVENTS.ORDER_COMPLETED)
  }
}

export const applyCouponStart = (couponCode, willSubscribe) => {
  return async (dispatch, getState) => {
    const state = getState()
    const { dropoffId } = selectCurrentDeliveryDropoff(state)
    await dispatch(applyCoupon(submitCoupon(dropoffId, couponCode)))

    dispatch(loadCheckoutCartStart(dropoffId, willSubscribe))
  }
}

export const applyCreditStart = (creditAmount, willSubscribe) => {
  return async (dispatch, getState) => {
    const state = getState()
    const { dropoffId } = selectCurrentDeliveryDropoff(state)
    await dispatch(applyCredit(submitCredit(dropoffId, creditAmount)))

    dispatch(loadCheckoutCartStart(dropoffId, willSubscribe))
  }
}

export const applyGiftStart = (giftAmount, willSubscribe) => {
  return async (dispatch, getState) => {
    const state = getState()
    const { dropoffId } = selectCurrentDeliveryDropoff(state)
    await dispatch(applyGift(submitGift(dropoffId, giftAmount)))

    dispatch(loadCheckoutCartStart(dropoffId, willSubscribe))
  }
}

export const loadAvailableProgramStart = date => {
  return dispatch => {
    return dispatch(setAvailableProgram(getProgramForUser(date)))
  }
}

export const addOrUpdateCateringDeliveryInstructionsStart = (
  deliveryDropoffId,
  dropoffInstructions,
) => {
  return dispatch => {
    return dispatch(
      setCateringDeliveryInstructions(
        createOrUpdateCateringDeliveryInstructionsApi(deliveryDropoffId, dropoffInstructions),
      ),
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [PENDING(COUPON_APPLY)]: state => {
    return {
      ...state,
      isCouponApplying: true,
    }
  },
  [FULFILLED(COUPON_APPLY)]: state => {
    return {
      ...state,
      errorApplyingCoupon: undefined,
      isCouponApplying: false,
    }
  },
  [REJECTED(COUPON_APPLY)]: (state, action) => {
    const error = normalizeApplyCouponErrorResponse(action.payload)

    return {
      ...state,
      errorApplyingCoupon: error,
      isCouponApplying: false,
    }
  },
  [PENDING(CREDIT_APPLY)]: state => {
    return {
      ...state,
      isCreditApplying: true,
    }
  },
  [FULFILLED(CREDIT_APPLY)]: state => {
    return {
      ...state,
      errorApplyingCredit: undefined,
      isCreditApplying: false,
    }
  },
  [REJECTED(CREDIT_APPLY)]: (state, action) => {
    const error = normalizeErrorResponse(action.payload)

    return {
      ...state,
      errorApplyingCredit: error,
      isCreditApplying: false,
    }
  },
  [PENDING(GIFT_APPLY)]: state => {
    return {
      ...state,
      isGiftApplying: true,
    }
  },
  [FULFILLED(GIFT_APPLY)]: state => {
    return {
      ...state,
      errorApplyingGift: undefined,
      isGiftApplying: false,
    }
  },
  [REJECTED(GIFT_APPLY)]: (state, action) => {
    const error = normalizeErrorResponse(action.payload)

    return {
      ...state,
      errorApplyingGift: error,
      isGiftApplying: false,
    }
  },
  [PENDING(CATERING_DELIVERY_INSTRUCTIONS_SET)]: state => {
    return {
      ...state,
      isCreatingOrUpdatingCateringDeliveryInstructions: true,
    }
  },
  [FULFILLED(CATERING_DELIVERY_INSTRUCTIONS_SET)]: state => {
    return {
      ...state,
      errorCreatingOrUpdatingCateringDeliveryInstructions: undefined,
      isCreatingOrUpdatingCateringDeliveryInstructions: false,
    }
  },
  [REJECTED(CATERING_DELIVERY_INSTRUCTIONS_SET)]: (state, action) => {
    const error = normalizeErrorResponse(action.payload)

    return {
      ...state,
      errorCreatingOrUpdatingCateringDeliveryInstructions: error,
      isCreatingOrUpdatingCateringDeliveryInstructions: false,
    }
  },
  [PENDING(READY_TO_PROCESS_SET)]: state => ({
    ...state,
    isCheckoutProcessing: true,
    errorCheckingOut: undefined,
  }),
  [FULFILLED(READY_TO_PROCESS_SET)]: (state, action) => {
    const { SelectedCard, saveCard } = action.payload
    return {
      ...state,
      waitingForProcessed: true,
      selectedCard: SelectedCard,
      saveCard,
    }
  },
  [REJECTED(READY_TO_PROCESS_SET)]: (state, action) => {
    const message = normalizeErrorResponse(action.payload, CHECKOUT_ERROR)
    return {
      ...state,
      errorCheckingOut: message,
      isCheckoutProcessing: false,
    }
  },
  [PENDING(CHECKOUT)]: state => {
    return {
      ...state,
      errorCheckingOut: undefined,
      isCheckoutProcessing: false,
      waitingForProcessed: false,
    }
  },
  [REJECTED(CHECKOUT)]: (state, action) => {
    return {
      ...state,
      errorCheckingOut: action.payload,
      isCheckoutProcessing: false,
      waitingForProcessed: false,
    }
  },
  [CHECKOUT_ERROR_RESET]: state => {
    return {
      ...state,
      errorCheckingOut: undefined,
    }
  },
  [AUTO_FAVORITE_MODAL_CLOSE]: state => ({
    ...state,
    favoriteInfo: undefined,
  }),
  [PENDING(AVAILABLE_PROGRAM_SET)]: state => ({
    ...state,
    isLoadingProgram: true,
    errorLoadingProgram: undefined,
  }),
  [FULFILLED(AVAILABLE_PROGRAM_SET)]: (state, action) => ({
    ...state,
    isLoadingProgram: false,
    errorLoadingProgram: undefined,
    programInfo: action.payload,
  }),
  [REJECTED(AVAILABLE_PROGRAM_SET)]: (state, action) => ({
    ...state,
    isLoadingProgram: false,
    errorLoadingProgram: action.payload,
    programInfo: undefined,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  selectedCard: undefined,
  saveCard: true,
  waitingForProcessed: false,
  programInfo: undefined,
  // Loading states
  isCouponApplying: false,
  isCreditApplying: false,
  isGiftApplying: false,
  isCheckoutProcessing: false,
  isLoadingProgram: false,
  isCreatingOrUpdatingCateringDeliveryInstructions: false,
  // Error states
  errorApplyingCoupon: undefined,
  errorApplyingCredit: undefined,
  errorApplyingGift: undefined,
  errorCheckingOut: undefined,
  errorLoadingProgram: undefined,
  errorCreatingOrUpdatingCateringDeliveryInstructions: undefined,
}

export default function checkout(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
