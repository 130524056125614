import React from 'react'
import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  circularProgress: {
    marginRight: theme.spacing(1),
    position: 'absolute',
    zIndex: 1,
  },
  root: {
    '&:hover': {
      backgroundColor: props => props.success && green[700],
    },
    backgroundColor: props => props.success && green[500],
    position: 'relative',
    width: props => props.width && props.width,
  },
}))

/*
    This component extends the Material Button component
    Three properties added:
        - Sets the type of the button to submit type
        - submitting prop toggles the loading state of the button
        - Shows an error message
*/
const ButtonSubmit = ({
  children,
  submitting,
  success,
  errorMessage,
  variant,
  width,
  ...others
}) => {
  const classes = useStyles({ success, width })
  return (
    <>
      <Button
        {...others}
        className={cn(classes.root, others.className)}
        disabled={submitting || others.disabled}
        type="submit"
        variant={success ? 'contained' : variant}
      >
        {submitting && (
          <CircularProgress
            className={classes.circularProgress}
            size={others.size === 'large' ? 24 : 16}
          />
        )}
        {children}
      </Button>
      {errorMessage && (
        <Box marginTop={2}>
          <Typography color="error">{errorMessage}</Typography>
        </Box>
      )}
    </>
  )
}

ButtonSubmit.propTypes = {
  children: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  width: PropTypes.number,
}

ButtonSubmit.defaultProps = {
  submitting: false,
  success: false,
  variant: 'contained',
}

export default ButtonSubmit
