import React from 'react'
import { Action as DeprecatedAction } from '@foodsby/nutrient'

export default function Action(props) {
  const { className, ...otherProps } = props
  return (
    <DeprecatedAction
      className={className ? className + ' action-btn' : 'action-btn'}
      {...otherProps}
    />
  )
}
