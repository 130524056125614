import { push } from 'connected-react-router'
import { reset, startSubmit, stopSubmit, SubmissionError } from 'redux-form'
import { all, call, delay, fork, put } from 'redux-saga/effects'

import { logoutRoute, maintenanceRoute } from '../../routes/routes'
import { logException } from '../../util/errorUtils'
import {
  watchAddItemToAndAddItemToCartSuccess,
  watchAddItemToAndAddItemToCateringCartSuccess,
  watchAddItemToAndAddItemToReserveCartSuccess,
  watchAddItemToCart,
  watchAddItemToCartAndLoadOrderCartSuccess,
  watchAddItemToCateringCart,
  watchAddItemToReserveCart,
  watchEditOrder,
  watchEditOrderInCart,
  watchEditOrderInCateringCart,
  watchEditOrderInReserveCart,
  watchLoadCateringOrderCart,
  watchLoadReserveOrderCart,
  watchLoadOrderCart,
  watchRemoveItemFromCateringOrderCart,
  watchRemoveItemFromReserveOrderCart,
  watchDeleteCateringOrderCart,
  watchDeleteReserveOrderCart,
  watchRemoveItemFromCheckoutCart,
  watchRemoveItemFromOrderCart,
} from './cart'
import { watchCheckHealthEndpoints } from './checkHealth'
import { watchLoadPaymentMethods } from './paymentMethods'
import { watchDeliveringRestaurants } from './comingSoon'
import { watchLoadAvailableCredits } from './creditHistory'
import { watchLoadCreditTransactions } from './credits'
import {
  watchAddFavorite,
  watchDeleteFavorite,
  watchLoadFavorites,
  watchLoadPopularItems,
  watchLoadPopularMenuItems,
} from './favorite'
import {
  watchCompleteResetPassword,
  watchSubmitResetPassword,
  watchValidateToken,
} from './forgotPassword'
import { watchGetFriendsOrders } from './friends'
import { watchSubmitJoinInfo } from './join'
import {
  watchCreateUserSavedLocation,
  watchDeleteUserSavedLocation,
  watchLoadGroupOrderLocation,
  watchLoadIsGroupOrderLocationSuccess,
  watchLoadLocation,
  watchLoadLocationFailure,
  watchLoadLocationSuccess,
  watchLoadPreviousLocation,
  watchLoadUserSavedLocations,
  watchLoadUserSavedLocationsSuccess,
  watchSelectedKiosk,
  watchUpdateUserSavedLocation,
} from './location'
import {
  formatFromAddressId,
  watchLoadLocationsFromId,
  watchLocationSearch,
} from './locationSearch'
import { watchLoadCateringMenu, watchLoadMenu, watchLoadReserveMenu } from './menu'
import {
  watchLoadOrderById,
  watchLoadOrderRewardsById,
  watchLoadOrderDetails,
  watchLoadFutureOrderDetails,
} from './orderDetail'
import { watchLoadOrderHistory } from './orderHistory'
import {
  watchAddPastOrderToCart,
  watchAddPastOrderToCartSuccess,
  watchAddPastOrderToCateringCart,
  watchAddPastOrderToCateringCartSuccess,
  watchAddPastOrderToReserveCart,
  watchAddPastOrderToReserveCartSuccess,
  watchLoadPastCateringOrders,
  watchLoadPastReserveOrders,
  watchLoadPastOrders,
} from './pastorder'
import { watchLoadMenuItem, watchLoadMenuItemSuccess } from './personalize'
import {
  watchDeletePaymentOption,
  watchGetUserPrivacy,
  watchLoadPaymentOptions,
  watchUpdateProfilePicture,
  watchUpdateUserPrivacy,
} from './profile'
import { watchRegisterSlackbot } from './slackbot'
import {
  watchLoadReasons,
  watchLoadReasonsByOrder,
  watchSubmitSelfCancel,
  watchSubmitSelfCancelSuccess,
  watchSubmitTicket,
} from './ticket'
import { needRefresh, refreshTokenSaga } from './token'
import {
  watchConfirmUser,
  watchConfirmUserSuccess,
  watchLoadCurrentUser,
  watchLogin,
  watchLogout,
  watchRegisterLightSuccess,
  watchRegisterSuccess,
  watchRevertTemporaryLocation,
  watchSubmitRegisterLight,
  watchUpdateAndSaveUserLocation,
  watchUpdateAndSaveUserLocationSuccess,
  watchUserInfoLoaded,
} from './user'
import { watchLoadCateringStore } from './catering'
import { watchLoadReserveStore } from './reserve'

// shared sagas
export function* apiSaga(...args) {
  try {
    const shouldRefresh = yield call(needRefresh)
    let result
    if (shouldRefresh) {
      const error = yield call(refreshTokenSaga)
      if (!error) {
        // Because we are listening TOKEN_REFRESH_SUCCESS in a middleware, we need
        // to delay our reaction to the event to make sure it hit the store. Otherwise
        // we may end-up using the old tokens in our authenticated request.
        yield delay(50)
        result = yield call(makeAuthenticatedRequest, ...args)
      } else {
        return error
      }
    } else {
      result = yield call(makeAuthenticatedRequest, ...args)
    }
    return result
  } catch (err) {
    if (err instanceof SubmissionError) {
      throw err.errors
    } else {
      logException(err)
    }
    throw err
  }
}

export function* makeAuthenticatedRequest(
  fn,
  args,
  successAction,
  errorAction,
  successRedirect,
  errorRedirect,
) {
  try {
    // API response must have `data` property for this to work
    const data = yield call(fn, ...args)
    if (successAction) {
      yield put(successAction(data))
      if (successRedirect) {
        yield put(push(successRedirect))
      }
    }
    return data
  } catch (error) {
    const errorCode = error.code || error.response?.status
    if (errorCode) {
      switch (errorCode) {
        case 400:
          if (errorAction) {
            yield put(errorAction(error))
          } else if (errorRedirect) {
            yield put(push(errorRedirect))
          } else {
            throw new SubmissionError(error)
          }
          break
        case 401:
          if (badCreds(error)) {
            // this was a bad attempt at logging in
            if (errorAction) {
              yield put(errorAction(error))
            } else if (errorRedirect) {
              yield put(push(errorRedirect))
            } else {
              throw error
            }
          } else {
            try {
              // try refresh once
              const refreshError = yield call(refreshTokenSaga)
              if (!refreshError) {
                yield makeAuthenticatedRequest(
                  fn,
                  args,
                  successAction,
                  errorAction,
                  successRedirect,
                  errorRedirect,
                )
              } else {
                yield put(
                  push(
                    `${logoutRoute.path}?ref=${window.location.pathname}${window.location.search}`,
                  ),
                )
              }
            } catch (ex) {
              yield put(
                push(
                  `${logoutRoute.path}?ref=${window.location.pathname}${window.location.search}`,
                ),
              )
            }
          }
          break
        case 503:
          yield put(push(maintenanceRoute.path))
          break
        default:
          if (errorAction) {
            yield put(errorAction(error))
          } else if (errorRedirect) {
            yield put(push(errorRedirect))
          } else {
            throw error
          }
      }
    } else {
      // this means NO RESPONSE
      if (errorAction) {
        yield put(errorAction(error))
      } else if (errorRedirect) {
        yield put(push(errorRedirect))
      } else if (!errorAction) {
        throw error
      }
    }
    return error
  }
}

export const badCreds = error => error && error.data.error === 'Unauthorized'

export function* formSaga(formId, ...apiSagaArgs) {
  // Start form submit
  yield put(startSubmit(formId))
  try {
    yield call(apiSaga, ...apiSagaArgs)

    // Success
    yield put(reset(formId))
    yield put(stopSubmit(formId))
  } catch (error) {
    if (error instanceof SubmissionError) {
      yield put(stopSubmit(formId, error.errors))
    } else {
      yield put(stopSubmit(formId, { _error: error.message }))
    }
  }
}

// root saga -- add all watchers here
export default function* root() {
  yield all([
    fork(watchSubmitJoinInfo),
    fork(watchLogin),
    fork(watchUserInfoLoaded),
    fork(watchLogout),
    fork(watchSubmitRegisterLight),
    fork(watchRegisterLightSuccess),
    fork(watchRegisterSuccess),
    fork(watchRevertTemporaryLocation),
    fork(watchConfirmUser),
    fork(watchAddFavorite),
    fork(watchConfirmUserSuccess),
    fork(watchLoadCurrentUser),
    fork(watchLoadLocationsFromId),
    fork(formatFromAddressId),
    fork(watchLocationSearch),
    fork(watchLoadLocation),
    fork(watchLoadGroupOrderLocation),
    fork(watchLoadIsGroupOrderLocationSuccess),
    fork(watchLoadPreviousLocation),
    fork(watchLoadReasons),
    fork(watchSubmitTicket),
    fork(watchDeleteFavorite),
    fork(watchLoadReasonsByOrder),
    fork(watchSubmitResetPassword),
    fork(watchCompleteResetPassword),
    fork(watchValidateToken),
    fork(watchLoadPastOrders),
    fork(watchLoadPastCateringOrders),
    fork(watchLoadPastReserveOrders),
    fork(watchLoadMenu),
    fork(watchLoadReserveMenu),
    fork(watchLoadCateringMenu),
    fork(watchLoadCateringStore),
    fork(watchLoadReserveStore),
    fork(watchLoadPaymentMethods),
    fork(watchLoadMenuItem),
    fork(watchLoadMenuItemSuccess),
    fork(watchEditOrder),
    fork(watchEditOrderInCart),
    fork(watchEditOrderInCateringCart),
    fork(watchEditOrderInReserveCart),
    fork(watchLoadOrderDetails),
    fork(watchLoadFutureOrderDetails),
    fork(watchLoadOrderById),
    fork(watchLoadOrderRewardsById),
    fork(watchLoadOrderHistory),
    fork(watchAddPastOrderToCart),
    fork(watchAddPastOrderToCateringCart),
    fork(watchAddPastOrderToCateringCartSuccess),
    fork(watchAddPastOrderToReserveCart),
    fork(watchAddPastOrderToReserveCartSuccess),
    fork(watchAddPastOrderToCartSuccess),
    fork(watchLoadAvailableCredits),
    fork(watchLoadPaymentOptions),
    fork(watchDeletePaymentOption),
    fork(watchLoadOrderCart),
    fork(watchLoadCateringOrderCart),
    fork(watchLoadReserveOrderCart),
    fork(watchAddItemToCart),
    fork(watchAddItemToCateringCart),
    fork(watchAddItemToReserveCart),
    fork(watchAddItemToAndAddItemToCartSuccess),
    fork(watchAddItemToAndAddItemToCateringCartSuccess),
    fork(watchAddItemToAndAddItemToReserveCartSuccess),
    fork(watchRemoveItemFromOrderCart),
    fork(watchRemoveItemFromCateringOrderCart),
    fork(watchRemoveItemFromReserveOrderCart),
    fork(watchDeleteCateringOrderCart),
    fork(watchDeleteReserveOrderCart),
    fork(watchRemoveItemFromCheckoutCart),
    fork(watchUpdateAndSaveUserLocation),
    fork(watchUpdateAndSaveUserLocationSuccess),
    fork(watchDeliveringRestaurants),
    fork(watchGetFriendsOrders),
    fork(watchCheckHealthEndpoints),
    fork(watchSubmitSelfCancel),
    fork(watchLoadPopularItems),
    fork(watchLoadPopularMenuItems),
    fork(watchLoadFavorites),
    fork(watchSubmitSelfCancelSuccess),
    fork(watchLoadCreditTransactions),
    fork(watchGetUserPrivacy),
    fork(watchUpdateUserPrivacy),
    fork(watchUpdateProfilePicture),
    fork(watchLoadLocationSuccess),
    fork(watchLoadLocationFailure),
    fork(watchSelectedKiosk),
    fork(watchLoadUserSavedLocations),
    fork(watchLoadUserSavedLocationsSuccess),
    fork(watchCreateUserSavedLocation),
    fork(watchDeleteUserSavedLocation),
    fork(watchRegisterSlackbot),
    fork(watchAddItemToCartAndLoadOrderCartSuccess),
    fork(watchUpdateUserSavedLocation),
  ])
}
