import React from 'react'
import { Box, Button } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { selectLocationNonPreferred } from '../../redux/selectors/locationSelectors'
import HomeAd from './HomeAd'

const HomeUnlockPreferredPricing = () => {
  const isNonPreferred = useSelector(selectLocationNonPreferred)

  const dispatch = useDispatch()

  const handleOpen = () => {
    dispatch(push({ search: queryString.stringify({ requestPreferred: 'true' }) }))
  }

  if (!isNonPreferred) {
    return null
  }

  return (
    <>
      <Box>
        <HomeAd
          title="Unlock Foodsby Preferred!"
          actions={
            <Button
              onClick={() => handleOpen()}
              color="primary"
              variant="contained"
              style={{ width: '12rem' }}
            >
              Request Access
            </Button>
          }
        />
      </Box>
    </>
  )
}

export default HomeUnlockPreferredPricing
