import React from 'react'
import { Box } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import styled from 'styled-components'
import currency from 'currency.js'
import { themeConWeb } from '../../util/modernThemeConweb'

const Component = styled.div`
  margin-bottom: 10px;
  & .-delivery-details {
    margin-left: 20px;
  }
  p {
    font-style: normal;
    color: ${themeConWeb.color.greyShade3};
    font-size: ${themeConWeb.fontSizes.base};
    margin-bottom: 5px;
    margin-top: 2px;
    line-height: 14px;
  }
`

const DeliveryReserveFee = ({ reserveFee }) => {
  return (
    <Component>
      <Box display="flex" justifyContent="left" alignItems="center">
        <Box>
          <PersonIcon />
        </Box>
        <Box className="-delivery-details">
          {currency(reserveFee, { formatWithSymbol: true }).format()}
          <p>Delivery Fee (paid by others & credited to you)</p>
        </Box>
      </Box>
    </Component>
  )
}

export default DeliveryReserveFee
