import React from 'react'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

export const EMPLOYEE_COUNT_OPTIONS = [
  {
    value: 'LESS_THAN_50',
    display: '<50 people',
  },
  {
    value: 'BETWEEN_50_AND_250',
    display: '50-250 people',
  },
  {
    value: 'OVER_250',
    display: '250+ people',
  },
]

export const employeeCountDisplay = value => {
  for (let index in EMPLOYEE_COUNT_OPTIONS) {
    let option = EMPLOYEE_COUNT_OPTIONS[index]
    if (option.value === value) {
      return option.display
    }
  }
  return ''
}

export const SelectEmployeeCount = ({ name, value, onChange, ...attributes }) => {
  const notNaValue = value === null ? '' : value
  const naOnChange = nextValue => {
    onChange(nextValue || null)
  }
  return (
    <Select name={name} value={notNaValue} onChange={naOnChange} {...attributes}>
      <MenuItem value="">Choose</MenuItem>
      {EMPLOYEE_COUNT_OPTIONS.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.display}
        </MenuItem>
      ))}
    </Select>
  )
}

export const DisplayEmployeeCount = ({ value }) => {
  return <>{employeeCountDisplay(value)}</>
}
