import { push } from 'connected-react-router'
import queryString from 'query-string'

import {
  createAccountApi,
  getClaimableTeamApi,
  getUserPotentialTeamsApi,
  selfJoinTeamApi,
} from '../../services/team'
import { selectShouldLoadPotentialTeams } from '../selectors/teamOnboardingSelectors'
import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import { teamRoute } from '../../routes/routes'
import { waitPlz } from '../../util/promiseUtils'
import { selectUserTeam } from '../selectors/userTeamSelectors'
import { formatUrl } from '../../util/formatUtils'
import { enqueueSnackbar } from './snackbar'
import { loadUserTeamStart } from './userTeam'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const TEAM_JOIN = 'foodsby/teamOnboarding/TEAM_JOIN'
export const POTENTIAL_TEAMS_SET = 'foodsby/teamOnboarding/POTENTIAL_TEAMS_SET'
export const TEAM_CREATE = 'foodsby/teamOnboarding/TEAM_CREATE'
export const CLAIMABLE_TEAM_SET = 'foodsby/teamOnboarding/CLAIMABLE_TEAM_SET'

export const setPotentialTeams = createAsyncAction(POTENTIAL_TEAMS_SET)
export const joinTeam = createAsyncAction(TEAM_JOIN)
export const createTeamPending = createAction(PENDING(TEAM_CREATE))
export const createTeamFulfilled = createAction(FULFILLED(TEAM_CREATE))
export const createTeamRejected = createAction(REJECTED(TEAM_CREATE))
export const setClaimableTeam = createAsyncAction(CLAIMABLE_TEAM_SET)

// ------------------------------------
// Thunks
// ------------------------------------
export const loadPotentialTeamsStart = (reload = false) => {
  return (dispatch, getState) => {
    const state = getState()
    const shouldLoadPotentialTeams = selectShouldLoadPotentialTeams(state)
    if (shouldLoadPotentialTeams || reload) {
      return dispatch(setPotentialTeams(getUserPotentialTeamsApi()))
    }
  }
}

export const joinTeamStart = (accountId, name) => {
  return async dispatch => {
    try {
      await dispatch(joinTeam(selfJoinTeamApi(accountId)))
      dispatch(enqueueSnackbar({ message: `Congrats! You joined ${name}.` }))
      dispatch(push({ search: queryString.stringify({ joinTeam: undefined }) }))
    } catch (ex) {
      dispatch(
        enqueueSnackbar({
          message: 'Something went wrong while joining. Please try again later or contact support.',
        }),
      )
      return
    }

    dispatch(loadUserTeamStart())
  }
}

export const createTeamStart = (teamName, companyId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(createTeamPending())

      await createAccountApi(teamName, companyId)

      // Wait for fetching the user's team right after creation
      await waitPlz(1000)
      await dispatch(loadUserTeamStart())

      dispatch(
        enqueueSnackbar({
          message:
            'Congrats! Your team was successfully created. Be sure to invite everyone on your team to join.',
        }),
      )

      const userTeam = selectUserTeam(getState())
      const url = formatUrl(teamRoute.path, { accountId: `${userTeam?.team?.accountId}` })
      dispatch(push(url))

      dispatch(createTeamFulfilled())
    } catch (ex) {
      dispatch(createTeamRejected(ex))
    }
  }
}

export const loadClaimableTeamStart = companyId => {
  return async dispatch => {
    return dispatch(setClaimableTeam(getClaimableTeamApi(companyId)))
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PENDING(POTENTIAL_TEAMS_SET)]: state => {
    return {
      ...state,
      isLoadingPotentialTeams: true,
    }
  },
  [FULFILLED(POTENTIAL_TEAMS_SET)]: (state, action) => {
    return {
      ...state,
      potentialTeams: action.payload,
      isLoadingPotentialTeams: false,
    }
  },
  [REJECTED(POTENTIAL_TEAMS_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingPotentialTeams: false,
      errorLoadingPotentialTeams:
        action.payload.message ??
        'Something went wrong loading the teams to join. Please try again or contact support.',
    }
  },
  [PENDING(TEAM_JOIN)]: state => {
    return {
      ...state,
      isJoiningTeam: true,
    }
  },
  [FULFILLED(TEAM_JOIN)]: state => {
    return {
      ...state,
      isJoiningTeam: false,
      successJoiningTeam: true,
    }
  },
  [REJECTED(TEAM_JOIN)]: (state, action) => {
    return {
      ...state,
      isJoiningTeam: false,
      errorJoiningTeam: action.payload.message,
    }
  },
  [PENDING(TEAM_CREATE)]: state => {
    return {
      ...state,
      isCreatingTeam: true,
    }
  },
  [FULFILLED(TEAM_CREATE)]: state => {
    return {
      ...state,
      isCreatingTeam: false,
    }
  },
  [REJECTED(TEAM_CREATE)]: (state, action) => {
    return {
      ...state,
      isCreatingTeam: false,
      errorCreatingTeam:
        action.payload.message ??
        'Something went wrong creating the team. Please try again or contact support.',
    }
  },
  [PENDING(CLAIMABLE_TEAM_SET)]: state => {
    return {
      ...state,
      isLoadingClaimableTeam: true,
    }
  },
  [FULFILLED(CLAIMABLE_TEAM_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingClaimableTeam: false,
      claimableTeam: action.payload,
    }
  },
  [REJECTED(CLAIMABLE_TEAM_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingClaimableTeam: false,
      errorLoadingClaimableTeam:
        action.payload.message ??
        'Something went wrong loading the team. Please try again or contact support.',
    }
  },
}

export const initialState = {
  potentialTeams: undefined,
  claimableTeam: undefined,
  // Loading states
  isLoadingPotentialTeams: false,
  isJoiningTeam: false,
  isCreatingTeam: false,
  isLoadingClaimableTeam: false,
  // Success states
  successSettingNextStep: false,
  // Error states
  errorLoadingPotentialTeams: undefined,
  errorJoiningTeam: undefined,
  errorCreatingTeam: undefined,
  errorLoadingClaimableTeam: undefined,
}

export default function teamOnboarding(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
