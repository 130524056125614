import { createSelector } from 'reselect'

export const selectTeam = createSelector([state => state.team.team], team => team)
export const selectTeamPaymentMethods = createSelector(
  [state => state.team.paymentMethods],
  pms => pms,
)
export const selectDefaultTeamPaymentMethod = createSelector(
  [selectTeamPaymentMethods],
  paymentMethods => paymentMethods.find(pm => pm.default),
)
export const selectIsLoadingTeamPaymentMethods = createSelector(
  [state => state.team.isLoadingPaymentMethods],
  loading => loading,
)
