import React from 'react'
import { Box } from '@material-ui/core'
import { CardElement } from '@stripe/react-stripe-js'
import { Alert } from '@material-ui/lab'

import BillingInfo from '../../checkout/BillingInfo'
import { hasPaymentMethods, isDefaultPaymentMethod } from '../../../util/paymentMethods'

const PaymentMethodTeam = ({
  paymentMethods,
  paymentMethodsError,
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
}) => {
  const showWarning =
    hasPaymentMethods(paymentMethods) &&
    !isDefaultPaymentMethod(paymentMethods, values.SelectedCard)
  return (
    <>
      <Box marginTop={3}>
        <BillingInfo
          paymentMethods={paymentMethods}
          paymentMethodsError={paymentMethodsError}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
          CardElement={CardElement}
        />
      </Box>
      {showWarning && (
        <Box marginTop={2}>
          <Alert severity="warning">
            This credit card will become the default payment method for all perks on your account.
          </Alert>
        </Box>
      )}
    </>
  )
}

export default PaymentMethodTeam
