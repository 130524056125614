import React, { useState, useEffect } from 'react'
import { Box, Dialog, Tab, Tabs, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { isNil } from 'lodash'
import { push } from 'connected-react-router'
import queryString from 'query-string'

import CollectEmailPanel from '../home/CollectEmailPanel'
import TabPanel from '../common/TabPanel'
import { selectQuery } from '../../redux/selectors/routerSelectors'
import LoginFormContainer from './LoginFormContainer'

export const SIGNUP_LOGIN_TABS = {
  SIGNUP: 0,
  LOGIN: 1,
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      padding: 0,
    },
  },
  content: {
    padding: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
    },
  },
}))

const DialogLoginOrJoin = ({ locationId, defaultTab = SIGNUP_LOGIN_TABS.SIGNUP, onClose }) => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(defaultTab)

  const handleTabChange = (_, value) => setTabValue(value)

  return (
    <Dialog open fullWidth className={classes.root} onClose={onClose}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="Sign up" />
        <Tab label="Log in" />
      </Tabs>
      <TabPanel value={tabValue} index={SIGNUP_LOGIN_TABS.SIGNUP}>
        <Box className={classes.content}>
          <Box marginBottom={4} textAlign="center">
            <Typography variant="h5" gutterBottom>
              Sign up for Foodsby
            </Typography>
          </Box>
          <CollectEmailPanel locationId={locationId} />
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={SIGNUP_LOGIN_TABS.LOGIN}>
        <Box className={classes.content}>
          <Box marginBottom={4} textAlign="center">
            <Typography variant="h5">Log in to your account</Typography>
          </Box>
          <LoginFormContainer />
        </Box>
      </TabPanel>
    </Dialog>
  )
}

const DialogLoginOrJoinWrapper = ({
  locationId,
  isAuthenticated,
  forceOpen,
  query,
  push,
  ...rest
}) => {
  const handleClose = () => {
    push({
      search: queryString.stringify({
        ...query,
        authenticate: undefined,
        authenticateTab: undefined,
      }),
    })
  }

  useEffect(() => {
    if (isAuthenticated) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  const open = locationId && !isAuthenticated && (!isNil(query?.authenticate) || forceOpen)
  const defaultTab = query?.authenticateTab && Number(query.authenticateTab)
  return open ? (
    <DialogLoginOrJoin
      locationId={locationId}
      defaultTab={defaultTab}
      onClose={handleClose}
      {...rest}
    />
  ) : null
}

const mapStateToProps = state => {
  const query = selectQuery(state)
  const { isAuthenticated } = state.user

  return {
    isAuthenticated,
    query,
  }
}

const mapDispatchToProps = {
  push,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogLoginOrJoinWrapper)
