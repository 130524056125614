import React from 'react'
import { Box } from '@material-ui/core'
import { RemoveCircleOutline } from '@material-ui/icons'

import ToolboxAction from '../common/ToolboxAction'

const ToolboxProgramMemberActions = ({ onShowRemoveDialog }) => {
  return (
    <>
      <Box display="flex" flexDirection="row">
        <ToolboxAction
          Icon={<RemoveCircleOutline style={{ color: 'white' }} />}
          label="Remove from perk"
          onClick={onShowRemoveDialog}
        />
      </Box>
    </>
  )
}

export default ToolboxProgramMemberActions
