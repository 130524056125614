import React, { useState } from 'react'
import { Box, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'
import { object, string } from 'yup'
import Dotdotdot from 'react-dotdotdot'

import { themeConWeb } from '@app/util/modernThemeConweb'
import LinkButton from '../../common/LinkButton'
import ButtonSaveCancel from './ButtonSaveCancel'

const schema = object().shape({
  name: string()
    .required('Please enter a perk name.')
    .max(60, 'Perk name cannot exceed 60 characters.'),
})

const useStyles = makeStyles({
  input: {
    '& .MuiInputBase-root': {
      backgroundColor: themeConWeb.color.white,
    },
  },
})

const EditableProgramName = ({ programName, submitting, onSubmit }) => {
  const classes = useStyles()
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState(programName)
  const [validationError, setValidationError] = useState(undefined)

  const handleSubmit = () => {
    schema
      .validate({ name: value })
      .then(() => {
        onSubmit({ name: value })
        setEditing(false)
      })
      .catch(({ message }) => {
        setValidationError(message)
      })
  }

  const handleToggleEditMode = () => {
    if (editing) {
      setEditing(false)
      setValidationError(undefined)
      setValue(programName)
    } else {
      setEditing(true)
    }
  }

  if (submitting) {
    return <Skeleton variant="rect" height="24px" width="100%" />
  }

  if (!editing) {
    return (
      <Box width="100%">
        <Dotdotdot clamp={2}>
          <Typography display="inline" variant="h5" component="h1">
            {programName}
          </Typography>
        </Dotdotdot>
        <LinkButton color={themeConWeb.color.brandPrimary} onClick={handleToggleEditMode}>
          Edit Name
        </LinkButton>
      </Box>
    )
  }

  return (
    <Box position="relative" width="100%">
      <TextField
        name="name"
        variant="outlined"
        fullWidth
        size="small"
        label="Perk name"
        className={classes.input}
        value={value}
        onChange={e => setValue(e.target.value)}
        helperText={validationError}
        error={Boolean(validationError)}
        inputProps={{ role: 'input' }}
      />
      <ButtonSaveCancel onSave={handleSubmit} onCancel={handleToggleEditMode} />
    </Box>
  )
}

export default EditableProgramName
