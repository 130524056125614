import { Button, H5, P } from '@foodsby/nutrient'
import { themeConWeb } from '@app/util/modernThemeConweb'
import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import { pickCateringDeliveryDateAndTimeRoute } from '../../routes/routes'

import { formatUrl } from '../../util/formatUtils'
import ModalPopup from '../common/ModalPopup'

const navigate = linkRoute => {
  window.location.href = linkRoute
}

export const CateringOrderValidationModal = ({ locationId, storeId, handleRestartOrder }) => {
  const linkRoute = formatUrl(pickCateringDeliveryDateAndTimeRoute.path, {
    locationId,
    storeId,
  })

  return (
    <ModalPopup maxWidth={450} showCancel={false}>
      <Component>
        <div className="-title">
          <i className="-titleIcon icon-warning primary" />
          <H5 className="-titleText">Order Date Is No Longer Valid</H5>
        </div>
        <P className="-body">
          {
            'The date you selected for this catering order is no longer valid. Please change your delivery time and date or start a new order.'
          }
        </P>
        <Grid container justifyContent="space-around" spacing={2}>
          <Grid item>
            <Button className="-cta" onClick={() => navigate(linkRoute)}>
              Change Date & Time
            </Button>
          </Grid>
          <Grid item>
            <Button className="-cta" onClick={() => handleRestartOrder()}>
              Start New Order
            </Button>
          </Grid>
        </Grid>
      </Component>
    </ModalPopup>
  )
}

const Component = styled.div`
  display: flex;
  flex-direction: column;

  & .-title {
    margin-bottom: 16px;
  }

  & .-titleIcon {
    padding-right: 8px;
  }

  & .-titleText {
    display: inline-block;
  }

  & .-body {
    font-size: ${themeConWeb.fontSizes.basePlus1}
    margin-bottom: 16px;
  }

  & .-cta {
    font-size: ${themeConWeb.fontSizes.base}
    align-self: flex-end;
    letter-spacing: normal;
  }
`

export default CateringOrderValidationModal
