import { isNil } from 'lodash'
import { createSelector } from 'reselect'
import { getFullName } from '../../components/teams/admin/adminUtils'
import { ROWS_PER_PAGE } from '../../util/constants'

export const selectPerkMembersFilteredBySearch = createSelector(
  [state => state.teamPerkDetails.perkMembers, state => state.teamPerkDetails.search],
  (perkMembers, search) =>
    perkMembers?.filter(member => filterByNameAndEmail(member, search)) ?? [],
)

export const selectPerkMembers = createSelector(
  [state => state.teamPerkDetails.perkMembers],
  perkMembers => perkMembers,
)

export const selectPerkMembersRows = createSelector(
  [
    selectPerkMembersFilteredBySearch,
    state => state.teamPerkDetails.sort,
    state => state.teamPerkDetails.page,
  ],
  (perkMembers, sort, page) =>
    stableSort(perkMembers, getComparator(sort.field, sort.direction)).slice(
      page * ROWS_PER_PAGE,
      page * ROWS_PER_PAGE + ROWS_PER_PAGE,
    ),
)

export const selectActionableMembers = createSelector(
  [state => state.teamPerkDetails.actionableMembers],
  actionableMembers => actionableMembers,
)

export const selectPricePerHeadInCents = createSelector(
  [state => state.teamSubscription.teamSubscription.pricePerHeadInCents],
  pricePerHeadInCents => pricePerHeadInCents,
)

// TODO This is copy-pasta from `teamMembersSelectors` so they can be moved to a shared file
// ------------------------------------
// Private methods
// ------------------------------------
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const descendingComparator = (a, b, field) => {
  // TODO Handle date comparison here
  if (b[field] < a[field]) {
    return -1
  }
  if (b[field] > a[field]) {
    return 1
  }

  return 0
}

const getComparator = (field, direction) => {
  return direction === 'desc'
    ? (a, b) => descendingComparator(a, b, field)
    : (a, b) => -descendingComparator(a, b, field)
}

const filterByNameAndEmail = (member, search) => {
  if (isNil(search)) {
    return true
  }

  const stringToCompare = `${getFullName(member)} ${member.email}`.toLowerCase()
  return stringToCompare.includes(search.toLowerCase())
}
