import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { themeConWeb } from '@app/util/modernThemeConweb'

export const PickupInstructions = ({ instructions }) => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus1, fontWeight: 700 }}>
        Where will my food be delivered?
      </Typography>
      <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus1 }}>{instructions}</Typography>
    </Box>
  )
}
