import React from 'react'
import { isNil } from 'lodash'
import { Box } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import BusinessIcon from '@material-ui/icons/Business'
import styled from 'styled-components'
import { format } from 'date-fns'
import { themeConWeb } from '../../util/modernThemeConweb'

const Component = styled.div`
  margin-bottom: 15px;
  & .-delivery-details {
    margin-left: 20px;
  }
  p {
    font-style: normal;
    color: ${themeConWeb.color.greyShade3};
    font-size: ${themeConWeb.fontSizes.base};
    margin-bottom: 5px;
    margin-top: 2px;
    line-height: 14px;
  }
`

const CheckoutDeliveryDetails = ({ address, deliveryDate, deliveryTime }) => {
  if (isNil(address) || isNil(deliveryDate) || isNil(deliveryTime)) {
    return null
  }

  if (deliveryDate.includes('-')) {
    deliveryDate = format(deliveryDate, 'MM/DD/YYYY')
  }

  const { street, city, zip, state } = address

  const parsedDayAndTime = formatDateTime(deliveryDate, deliveryTime)

  function formatDateTime(deliveryDate, deliveryTime) {
    const dateTimeString = `${deliveryDate} ${deliveryTime}`
    const dateTime = new Date(dateTimeString)

    function getOrdinalSuffix(day) {
      const j = day % 10,
        k = day % 100
      if (j === 1 && k !== 11) {
        return 'st'
      }
      if (j === 2 && k !== 12) {
        return 'nd'
      }
      if (j === 3 && k !== 13) {
        return 'rd'
      }
      return 'th'
    }

    const day = dateTime.getDate()
    const ordinalSuffix = getOrdinalSuffix(day)

    const optionsDate = { weekday: 'long', month: 'long', day: 'numeric' }
    const formattedDate = new Intl.DateTimeFormat('en-US', optionsDate).format(dateTime)

    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true }
    const formattedTime = new Intl.DateTimeFormat('en-US', optionsTime).format(dateTime)

    const formattedDateTime = `${formattedDate.replace(
      ` ${day}`,
      ` ${day}${ordinalSuffix}`,
    )} at ${formattedTime}`

    return formattedDateTime
  }

  return (
    <Component>
      <Box display="flex" justifyContent="left" alignItems="center" paddingBottom={2}>
        <Box>
          <AccessTimeIcon />
        </Box>
        <Box className="-delivery-details">
          {parsedDayAndTime}
          <p>Date & scheduled delivery time</p>
        </Box>
      </Box>

      <Box display="flex" justifyContent="left" alignItems="center">
        <Box>
          <BusinessIcon />
        </Box>
        <Box className="-delivery-details">
          {street}{' '}
          <p>
            {city}, {state} {zip}
          </p>
        </Box>
      </Box>
    </Component>
  )
}

export default CheckoutDeliveryDetails
