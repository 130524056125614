import React from 'react'
import { Box, Tooltip, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { isNil } from 'lodash'

const supportedBrands = ['visa', 'amex', 'mastercard', 'discover']

const getCardSrc = brand =>
  supportedBrands.includes(brand)
    ? `${process.env.REACT_APP_BASE_ORDER_URL}/images/card_${brand}.png`
    : `${process.env.REACT_APP_BASE_ORDER_URL}/images/card_etc.svg`

export default function PaymentMethodItem({ paymentMethod, condensed }) {
  if (isNil(paymentMethod?.card)) {
    return 'Invalid payment method.'
  }

  return (
    <Box display="flex" alignItems="center">
      <Box maxWidth="35px" marginRight={3} clone>
        <img
          style={{ verticalAlign: 'middle' }}
          src={getCardSrc(paymentMethod.card.brand)}
          alt="cc"
        />
      </Box>
      <>
        {!condensed && (
          <span style={{ color: '#999', display: 'inline-block', marginRight: '10px' }}>
            •••• •••• ••••
          </span>
        )}
        <Typography color="secondary" component="span">
          {paymentMethod.card.lastFour}
        </Typography>
        <Box marginLeft={3} marginTop="2px" display="inline-block">
          <Typography variant="body2" color="textSecondary">
            {paymentMethod.card.expMonth} / {paymentMethod.card.expYear}
          </Typography>
        </Box>
        {paymentMethod.nickname !== '' && (
          <Box marginLeft={4} display="inline-block">
            <Typography style={{ fontVariantCaps: 'all-small-caps' }} color="textSecondary">
              {paymentMethod.nickname}
            </Typography>
          </Box>
        )}
        {paymentMethod.defaultAtCheckout && (
          <Box marginLeft={3} display="inline-block">
            <Tooltip title="Default at checkout">
              <CheckIcon style={{ fontSize: '1.3rem', color: 'green' }} />
            </Tooltip>
          </Box>
        )}
      </>
    </Box>
  )
}
