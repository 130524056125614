import { Box, Card, Typography } from '@material-ui/core'
import React, { useState } from 'react'

import cn from 'classnames'
import { themeConWeb } from '@app/util/modernThemeConweb'
import styled from 'styled-components'
import pluralize from 'pluralize'
import DynamicLink from '../common/DynamicLink'

const DeliverySectionCard = ({
  classNames,
  isCardActive,
  ordersRemaining,
  url,
  LogoComponent,
  HeaderComponent,
  SubheaderComponent,
  rewardsHoverMessage,
  ...props
}) => {
  const [hoverEarn, setHoverEarn] = useState(false)

  return (
    <Box marginBottom={3}>
      <DynamicLink
        isActive={isCardActive}
        style={{ marginBottom: '16px', textDecoration: 'none' }}
        to={url}
      >
        <StyledCard className={cn(classNames)} {...props}>
          <div className="-limitedOrders">
            Hurry, only {pluralize('order', ordersRemaining, true)} left!
          </div>
          <Card className="-card" color="secondary" variant="outlined">
            <LogoComponent />
            <div className="-restaurantDetails">
              <HeaderComponent />
              <div className="-details">
                <SubheaderComponent />
              </div>
            </div>
            <div
              className="-rewardsDetails"
              onMouseEnter={() => setHoverEarn(true)}
              onMouseLeave={() => setHoverEarn(false)}
            >
              {hoverEarn && props.dropoffRewards > 0 && (
                <HoverEarnMessage rewardsHoverMessage={rewardsHoverMessage} />
              )}
            </div>
          </Card>
        </StyledCard>
      </DynamicLink>
    </Box>
  )
}

const HoverEarnMessage = ({ rewardsHoverMessage }) => {
  const handleMouseEnter = e => {
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.style.cursor = 'default'
  }

  const handleClick = e => {
    e.preventDefault()
  }

  return (
    <HoverBox onMouseEnter={handleMouseEnter} onClick={handleClick}>
      <Box>
        <Typography component={'p'}>{rewardsHoverMessage}</Typography>
      </Box>
    </HoverBox>
  )
}

const HoverBox = styled.div`
  background-color: black;
  color: white;
  padding: 8px;
  position: absolute;
  top: 35px;
  transform: translateX(-15%);
  z-index: 9999;
  width: 350px;
  p {
    font-size: 14px;
    color: white;
  }
`

const StyledCard = styled.div`
  display: block;
  flex: auto;
  max-width: 100%;
  position: relative;

  & .-avatarGroup {
    margin-left: calc(${themeConWeb.baseUnit} * 2);
    margin-top: calc(${themeConWeb.baseUnit});
    & .MuiAvatar-circular {
      font-size: ${themeConWeb.fontSizes.base};
      height: 32px;
      width: 32px;
    }
  }

  & .-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;
    padding-right: calc(${themeConWeb.baseUnit} * 8);
    flex-wrap: nowrap;

    & .-restaurantDetails {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-decoration: none !important;
    }

    &:hover {
      border-color: ${themeConWeb.color.brandSecondary};
    }
  }

  &.is-reserved {
    & .-card {
      border-color: green;
      border-style: solid;
      border-width: 3px;
      &:hover {
        border-color: #00ad00;
      }
    }
  }

  & .-favoriteIcon {
    color: ${themeConWeb.color.twoShade2};
    font-size: ${themeConWeb.fontSizes.basePlus1};
    margin-top: 6px;
    margin-left: calc(${themeConWeb.baseUnit} * 2);
  }

  & .-restaurantLogoContainer {
    align-items: center;
    display: flex;
    flex-basis: 30%;
    height: inherit;
    justify-content: center;
    padding: calc(${themeConWeb.baseUnit} * 8);
  }

  // Todo use again when we have a proper image solution
  & .-restaurantLogo {
    max-height: 120px;
  }

  & .-restaurantDetails {
    display: flex;
    flex-direction: column;
    flex-basis: 70%;
    text-decoration: none !important;
  }

  & .-title {
    color: ${themeConWeb.color.brandSecondary};
    font-size: 24px;
    font-weight: 500;
    margin-bottom: calc(${themeConWeb.baseUnit} * 2);
  }

  & .-details {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  & .-divider {
    height: 40px;
    display: flex;
    align-self: center;
  }

  & .-orderTime {
    padding-right: calc(${themeConWeb.baseUnit} * 5);
  }

  & .-deliveryTime {
    padding-right: calc(${themeConWeb.baseUnit} * 5);
    padding-left: calc(${themeConWeb.baseUnit} * 5);
  }

  & .-deliveryFee {
    padding-right: calc(${themeConWeb.baseUnit} * 5);
    padding-left: calc(${themeConWeb.baseUnit} * 5);
  }

  & .-leadTime {
    padding-right: calc(${themeConWeb.baseUnit} * 5);
  }

  & .-maxWeight {
    padding-right: calc(${themeConWeb.baseUnit} * 5);
    padding-left: calc(${themeConWeb.baseUnit} * 5);
  }

  & .-moneyMinimum {
    padding-right: calc(${themeConWeb.baseUnit} * 5);
    padding-left: calc(${themeConWeb.baseUnit} * 5);
  }

  & .-friendsOrdered {
    display: flex;
    flex-direction: column;
    padding-left: calc(${themeConWeb.baseUnit} * 5);
  }

  & .-cardDetailTitle {
    color: ${themeConWeb.primaryType};
    font-size: 14px;
    text-align: left;
    margin-bottom: 4px;
  }

  & .-cardDetail {
    color: ${themeConWeb.primaryType};
    font-size: 21px;
  }

  & .-timeAbbr {
    font-size: 16px;
  }

  /** States
  *
  * Hiding initial states
  *
  **/
  & .-limitedOrders,
  & .-restaurantDetails::before,
  & .-soldOutContent,
  & .-tooLateContent,
  & .-cutOffContent,
  & .-favoriteIcon {
    display: none;
  }

  &.is-new {
    & .-restaurantDetails::before {
      border-radius: ${themeConWeb.borderRadius};
      content: 'NEW';
      color: ${themeConWeb.color.white};
      background-color: ${themeConWeb.color.success};
      display: flex;
      justify-content: center;
      background-color: ${themeConWeb.success};
      font-size: ${themeConWeb.fontSizes.baseMinus1};
      padding: calc(${themeConWeb.baseUnit} * 2) calc(${themeConWeb.baseUnit} * 2);
      width: 55px;
      position: absolute;
      left: -${themeConWeb.baseUnit};
      top: -${themeConWeb.baseUnit};
      font-weight: 500;
    }
  }

  &.is-createdByUser {
    & .-restaurantDetails::before {
      border-radius: ${themeConWeb.borderRadius};
      content: 'Reserved by ${props =>
        scheduledByInitials(props.createdByFriendProfile, props.isScheduledByCurrentUser)}';
      color: ${themeConWeb.color.white};
      background-color: ${themeConWeb.color.success};
      display: flex;
      justify-content: center;
      background-color: ${themeConWeb.success};
      font-size: ${themeConWeb.fontSizes.baseMinus1};
      padding: calc(${themeConWeb.baseUnit} * 2) calc(${themeConWeb.baseUnit} * 2);
      width: 110px;
      position: absolute;
      left: -${themeConWeb.baseUnit};
      top: -${themeConWeb.baseUnit};
      font-weight: 500;
    }
  }

  &.is-daily-dropoff {
    & .-rewardsDetails::before {
      content: '${props => rewardsDisplay(props.dropoffRewards)}';
      color: ${themeConWeb.color.success};
      display: flex;
      justify-content: center;
      font-size: ${themeConWeb.fontSizes.base};
      padding: calc(${themeConWeb.baseUnit} * 2) calc(${themeConWeb.baseUnit} * 2);
      width: 110px;
      position: absolute;
      right: ${themeConWeb.baseUnit};
      top: ${themeConWeb.baseUnit};
      font-weight: 600;
    }
  }

  &.is-limitedOrders {
    .-limitedOrders {
      font-size: ${themeConWeb.fontSizes.base};
      background-color: ${themeConWeb.color.white};
      border: 1px solid ${themeConWeb.color.brandPrimary};
      border-radius: ${themeConWeb.borderRadius};
      color: ${themeConWeb.color.brandPrimary};
      display: block;
      padding: calc(${themeConWeb.baseUnit} + 2px) calc(${themeConWeb.baseUnit} * 2.5);
      position: absolute;
      left: -${themeConWeb.baseUnit};
      top: -${themeConWeb.baseUnit};
      font-weight: 500;
    }
  }

  &.is-soldOut .-soldOutContent {
    display: block;
  }

  &.is-tooLate {
    & .-tooLateContent {
      display: block;
    }
  }

  &.is-soldOut,
  &.is-tooLate {
    & .-card:hover {
      cursor: default;
      border-color: ${themeConWeb.color.greyShade2};
    }

    & .-restaurantLogoContainer,
    & .-avatarGroup {
      opacity: 0.5;
    }

    & .-title {
      opacity: 50%;
    }

    & .-friendsTitle {
      opacity: 50%;
    }

    & .-averageExpectedOrders {
      opacity: 50%;
    }

    & .-restaurantDetails::before {
      opacity: 50%;
    }

    & .-orderTime,
    & .-deliveryTime,
    & .-deliveryFee,
    & .-divider {
      display: none;
    }

    & .-soldOutContent,
    & .-tooLateContent {
      margin-bottom: calc(${themeConWeb.baseUnit} * 4);
      flex-basis: 62%;
    }

    & .-soldOutTitle,
    & .-tooLateTitle {
      font-weight: bold;
      color: ${themeConWeb.color.dangerLight};
      font-size: ${themeConWeb.fontSizes.basePlus1};
      margin-bottom: ${themeConWeb.baseUnit};
    }

    & .-soldOutCopy,
    & .-tooLateCopy {
      font-size: ${themeConWeb.fontSizes.baseMinus1};
    }
  }

  &.is-nearCutOff {
    & .-orderTime .-cardDetail,
    & .-orderTime .-cardDetailTitle {
      color: ${themeConWeb.color.dangerLight};
    }

    & .-cutOffContent {
      font-size: ${themeConWeb.fontSizes.base};
      background-color: ${themeConWeb.color.white};
      border: 1px solid ${themeConWeb.color.dangerLight};
      border-radius: ${themeConWeb.borderRadius};
      color: ${themeConWeb.color.dangerLight};
      display: block;
      padding: calc(${themeConWeb.baseUnit} + 2px) calc(${themeConWeb.baseUnit} * 2.5);
      position: absolute;
      left: -${themeConWeb.baseUnit};
      top: -${themeConWeb.baseUnit};
      font-weight: 500;

      & .--italics {
        color: ${themeConWeb.color.dangerLight};
        font-style: italic;
      }
    }
  }

    &.is-prospect-or-user-created {
    & .-card {
      color: 50%;
    }
    & .-restaurantLogoContainer,
    & .-avatarGroup {
      opacity: 0.5;
    }

    & .-title {
      opacity: 50%;
    }

    & .-friendsTitle {
      opacity: 50%;
    }

    & .-averageExpectedOrders {
      opacity: 50%;
    }

    & .-restaurantDetails::before {
      opacity: 50%;
    }

    & .-cardDetailTitle {
      opacity: 50%;
    }

    & .-cardDetail {
      opacity: 50%;
    }

  }

  &.has-favorites {
    & .-favoriteIcon {
      display: block;
    }
  }

  // responsive styles
  @media screen and ${themeConWeb.small} {
    & .-card {
      height: auto;
      padding: 0 calc(${themeConWeb.baseUnit} * 8) calc(${themeConWeb.baseUnit} * 2);
      flex-wrap: wrap;
    }

    & .-favoriteIcon {
      margin-top: 2px;
    }

    & .-title {
      font-size: 18px;
    }

    & .-cardDetail {
      font-size: 16px;
    }

    & .-restaurantLogoContainer {
      justify-content: center;
      width: 100%;
      flex-basis: 100%;
      padding: 8px;
    }

    & .-restaurantDetails {
      width: 100%;
      flex-basis: 100%;
    }

    & .-friendsTitle {
      display: none;
    }

    & .-averageExpectedOrders {
      display: none;
    }

    & .-orderTime {
      flex-basis: 30%;
      padding-right: 0;
    }

    & .-deliveryTime {
      flex-basis: 40%;
      padding-right: 0;
      padding-left: calc(${themeConWeb.baseUnit} * 2);
    }

    & .-deliveryFee {
      flex-basis: 30%;
      padding-right: 0;
      padding-left: 1px;
      padding-top: 12px;
      padding-bottom: 8px;
    }

    & .-friendsOrdered {
      flex-basis: 40%;
      padding-left: 0;
      padding-top: 12px;
    }

    & .-leadTime {
      flex-basis: 30%;
      padding-right: 0;
    }

    & .-maxWeight {
      flex-basis: 40%;
      padding-right: 0;
      padding-left: calc(${themeConWeb.baseUnit} * 2);
    }

    & .-moneyMinimum {
      flex-basis: 30%;
      padding-right: 0;
      padding-left: 1px;
      padding-top: 12px;
      padding-bottom: 8px;
    }

    & .-divider:nth-of-type(2) {
      display: none;
    }

    &.is-soldOut,
    &.is-tooLate {
      & .-soldOutContent,
      & .-tooLateContent {
        margin-bottom: calc(${themeConWeb.baseUnit} * 2);
        flex-basis: 100%;
      }
    }
  }
`

const scheduledByInitials = (createdByFriendProfile, isScheduledByCurrentUser) => {
  if (isScheduledByCurrentUser) {
    return 'You'
  }
  if (createdByFriendProfile == null) {
    return 'FC'
  }
  const firstName = createdByFriendProfile.firstName
  const lastName = createdByFriendProfile.lastName
  return `${firstName[0]}${lastName[0]}`
}

const rewardsDisplay = dropoffRewards => {
  if (dropoffRewards === null || dropoffRewards === 0 || dropoffRewards === undefined) {
    return
  } else {
    let thisAmount = dropoffRewards * 100
    const factor = Math.pow(10, 2)
    const percentage = Math.trunc(thisAmount * factor) / factor
    return `Earn ${percentage}%`
  }
}

export default DeliverySectionCard
