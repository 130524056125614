import React, { useState } from 'react'
import { Grid, TextField, Typography, Box, Button } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { has, unset } from 'lodash'

import CardTeam, { CardTeamContent, CardTeamTitle } from '../common/CardTeam'
import { Editable, DisplayMode, EditMode } from '../../common/Editable'
import { SelectEmployeeCount, DisplayEmployeeCount } from '../common/EmployeeCount'
import CardActionButton from './CardActionButton'

export default function CardTeamDetails({ team, emailDomains, loading, onSubmit }) {
  const [editMode, setEditMode] = useState(false)
  const [updatedTeam, setUpdatedTeam] = useState({})

  const toggleEdit = () => {
    if (editMode) {
      setEditMode(false)
      setUpdatedTeam({})
    } else {
      setEditMode(true)
    }
  }

  const updateField = e => {
    if (e) {
      e.preventDefault()
    }
    const { name, value } = e.target

    //remove from pending changes if same as saved value
    if (has(updatedTeam, name) && team[name] === value) {
      const copiedChanges = JSON.parse(JSON.stringify(updatedTeam))
      unset(copiedChanges, name)
      setUpdatedTeam(copiedChanges)
    } else {
      setUpdatedTeam({
        ...updatedTeam,
        [name]: value,
      })
    }
  }

  const submitUpdate = () => {
    setEditMode(false)
    onSubmit(updatedTeam)
    setUpdatedTeam({})
  }

  if (loading) {
    return (
      <CardTeam>
        <CardTeamTitle>Account Information</CardTeamTitle>
        <Skeleton height={50} />
      </CardTeam>
    )
  }

  return (
    <CardTeam>
      <CardActionButton name={editMode ? 'Cancel' : 'Edit'} onClick={toggleEdit} />
      <CardTeamTitle>Account Information</CardTeamTitle>
      <CardTeamContent>
        <Editable editing={editMode}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="overline" color="textSecondary">
                  Name
                </Typography>
              </Box>
              <DisplayMode>
                <Typography variant="body1">{team.name}</Typography>
              </DisplayMode>
              <EditMode>
                <TextField
                  name="name"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={updatedTeam.name !== undefined ? updatedTeam.name : team.name}
                  onChange={updateField}
                />
              </EditMode>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="overline" color="textSecondary">
                  Domain
                </Typography>
              </Box>
              <DisplayMode>
                <Typography variant="body1">
                  {emailDomains ? emailDomains.join(', ') : team.emailDomain}
                </Typography>
              </DisplayMode>
              <EditMode>
                <TextField
                  name="name"
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled
                  value={emailDomains ? emailDomains.join(', ') : team.emailDomain}
                />
              </EditMode>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="overline" color="textSecondary">
                  Number of People
                </Typography>
              </Box>
              <DisplayMode>
                <Typography variant="body1">
                  <DisplayEmployeeCount value={team.estimatedEmployeeCount} />
                </Typography>
              </DisplayMode>
              <EditMode>
                <SelectEmployeeCount
                  name="estimatedEmployeeCount"
                  fullWidth
                  size="small"
                  variant="outlined"
                  displayEmpty
                  value={
                    updatedTeam.estimatedEmployeeCount !== undefined
                      ? updatedTeam.estimatedEmployeeCount
                      : team.estimatedEmployeeCount
                  }
                  onChange={updateField}
                />
              </EditMode>
            </Grid>
            <Grid item xs={12}>
              <EditMode>
                <Box display="flex" mt={2}>
                  <Box flex="1 0 0" />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={submitUpdate}
                    disabled={
                      !updatedTeam.name &&
                      !updatedTeam.emailDomain &&
                      !updatedTeam.estimatedEmployeeCount
                    }
                  >
                    Update
                  </Button>
                </Box>
              </EditMode>
            </Grid>
          </Grid>
        </Editable>
      </CardTeamContent>
    </CardTeam>
  )
}
