import { first, isEmpty, isNil } from 'lodash'

export const POTENTIAL_TEAM_TYPES = Object.freeze({
  TEAM: 'TEAM',
  COMPANY: 'COMPANY',
})

export const hasTeam = userTeam => Boolean(userTeam?.team)

export const hasPotentialTeam = potentialTeams =>
  !isEmpty(potentialTeams?.companiesWithoutTeam) || !isEmpty(potentialTeams?.joinableTeams)

export const getTotalPotentialTeams = potentialTeams =>
  potentialTeams?.companiesWithoutTeam.length + potentialTeams?.joinableTeams.length

export const hasMultiplePotentialTeams = potentialTeams =>
  getTotalPotentialTeams(potentialTeams) > 1

export const getOnlyPotentialTeam = potentialTeams => {
  if (hasMultiplePotentialTeams(potentialTeams)) {
    return undefined
  }

  const onlyCompany = first(potentialTeams?.companiesWithoutTeam)
  const onlyTeam = first(potentialTeams?.joinableTeams)

  return {
    ...onlyCompany,
    ...onlyTeam,
    type: isNil(onlyCompany) ? 'TEAM' : 'COMPANY',
  }
}
