import React from 'react'
import { Box, Button, Card, Typography, useMediaQuery } from '@material-ui/core'

import InputSearchMembers from '../admin/InputSearchMembers'
import TablePerkMembers from './TablePerkMembers'

const CardPerkMembers = ({
  members,
  errorSettingMissingMembers,
  emailFieldId,
  setPage,
  setSearch,
  onClickAddMembers,
}) => {
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const handleSearch = value => {
    setPage(0)
    setSearch(value)
  }

  return (
    <Card>
      <Box
        display="flex"
        flexDirection={mobileView ? 'column' : 'row'}
        justifyContent={mobileView ? 'flex-start' : 'space-between'}
        alignItems={mobileView ? 'flex-start' : 'center'}
      >
        <Typography variant="h6">Perk members ({members?.length})</Typography>
        <Button variant="contained" color="primary" onClick={onClickAddMembers}>
          Add members
        </Button>
      </Box>
      {errorSettingMissingMembers && (
        <Typography color="error">{errorSettingMissingMembers}</Typography>
      )}
      <Box marginY={3}>
        <InputSearchMembers onChange={handleSearch} />
      </Box>

      <TablePerkMembers emailFieldId={emailFieldId} />
    </Card>
  )
}

export default CardPerkMembers
