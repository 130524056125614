import React from 'react'
import { Container } from '@material-ui/core'
import { Redirect, Route, Switch } from 'react-router'
import { connect } from 'react-redux'

import { useEffect } from 'react'
import NewPageLayout from '../layouts/NewPageLayout'
import {
  rootRoute,
  teamAccountRoute,
  teamMembersRoute,
  teamPerksRoute,
  teamRecurringPerkRoute,
  teamUnlimitedDeliveryRoute,
  teamOneTimePerkRoute,
  teamPaymentsRoute,
  teamRoute,
} from '../routes/routes'
import { selectTeam } from '../redux/selectors/teamSelectors'
import { loadTeamStart } from '../redux/modules/team'
import { formatUrl } from '../util/formatUtils'
import PageTeamMembers from './PageTeamMembers'
import PageTeamAccount from './PageTeamAccount'
import PageTeamPerks from './PageTeamPerks'
import PageRecurringPerk from './PageRecurringPerk'
import PageOneTimePerk from './PageOneTimePerk'
import PageTeamUnlimitedDelivery from './PageTeamUnlimitedDelivery'
import PageTeamPayment from './PageTeamPayment'

const matchAccountId = match => Number(match?.params?.accountId)
const teamAccountId = team => team?.id
const correctAccountLoaded = (match, team) => matchAccountId(match) === teamAccountId(team)
const couldNotLoadAccount = errorLoadingTeam => errorLoadingTeam !== undefined

function PageTeamAdmin({
  match,
  team,
  loadTeamStart,
  isLoadingTeam,
  errorLoadingTeam,
  isLoadingProfile,
}) {
  const matchedAccountId = matchAccountId(match)

  useEffect(() => {
    if (
      !correctAccountLoaded(match, team) &&
      !isLoadingTeam &&
      !isLoadingProfile &&
      !couldNotLoadAccount(errorLoadingTeam)
    ) {
      loadTeamStart(matchedAccountId)
    }
  }, [
    errorLoadingTeam,
    isLoadingProfile,
    isLoadingTeam,
    loadTeamStart,
    match,
    matchedAccountId,
    team,
  ])

  if (couldNotLoadAccount(errorLoadingTeam)) {
    return <Redirect to={rootRoute.path} />
  }

  return (
    <NewPageLayout>
      <Container maxWidth="lg">
        <Switch>
          <Route exact path={teamMembersRoute.path} component={PageTeamMembers} />
          <Route exact path={teamPerksRoute.path} component={PageTeamPerks} />
          <Route exact path={teamAccountRoute.path} component={PageTeamAccount} />
          <Route exact path={teamPaymentsRoute.path} component={PageTeamPayment} />
          <Route exact path={teamRecurringPerkRoute.path} component={PageRecurringPerk} />
          <Route
            exact
            path={teamUnlimitedDeliveryRoute.path}
            component={PageTeamUnlimitedDelivery}
          />
          <Route exact path={teamRoute.path}>
            <Redirect to={formatUrl(teamAccountRoute.path, { accountId: matchedAccountId })} />
          </Route>
          <Route exact path={teamOneTimePerkRoute.path} component={PageOneTimePerk} />
        </Switch>
      </Container>
    </NewPageLayout>
  )
}

const mapStateToProps = state => {
  const team = selectTeam(state)
  const { isLoadingTeam, errorLoadingTeam } = state.team
  const { isLoadingProfile, profile } = state.profile
  return {
    team,
    isLoadingTeam,
    errorLoadingTeam,
    isLoadingProfile,
    profile,
  }
}

const mapDispatchToProps = {
  loadTeamStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTeamAdmin)
