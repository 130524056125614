import React from 'react'
import DocumentTitle from 'react-document-title'
import { Route } from 'react-router-dom'

import ErrorBoundary from '../components/common/ErrorBoundary'
import GlobalDataLoader from '../GlobalDataLoader'
import LayoutPrivate from '../layouts/LayoutPrivate'
import LayoutPublic from '../layouts/LayoutPublic'
import LayoutSidebar from '../layouts/LayoutSidebar'

export const FOODSBY_GENERIC_TITLE = 'Foodsby - Meal delivery for people at home and work'

export const RouteLayoutPrivate = ({
  component: Component,
  layoutProps,
  nav,
  noScroll = false,
  sidebarRight,
  title = 'Foodsby',
  showHeaderLocation = false,
  showHeaderDeliveryDate = false,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      noScroll={noScroll}
      render={matchProps => (
        <ErrorBoundary key={matchProps.location.pathname}>
          <GlobalDataLoader match={matchProps.match}>
            <LayoutPrivate
              nav={nav}
              sidebarRight={sidebarRight}
              showHeaderLocation={showHeaderLocation}
              showHeaderDeliveryDate={showHeaderDeliveryDate}
              {...matchProps}
              {...layoutProps}
            >
              <DocumentTitle title={title ?? FOODSBY_GENERIC_TITLE}>
                <Component {...matchProps} />
              </DocumentTitle>
            </LayoutPrivate>
          </GlobalDataLoader>
        </ErrorBoundary>
      )}
    />
  )
}

export const RouteLayoutPublic = ({
  component: Component,
  layoutProps,
  matchProps,
  title = 'Foodsby',
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <ErrorBoundary key={matchProps.location.pathname}>
          <GlobalDataLoader match={matchProps.match}>
            <LayoutPublic {...matchProps} {...layoutProps}>
              <DocumentTitle title={title ?? FOODSBY_GENERIC_TITLE}>
                <Component {...matchProps} />
              </DocumentTitle>
            </LayoutPublic>
          </GlobalDataLoader>
        </ErrorBoundary>
      )}
    />
  )
}

export const RouteLayoutSidebar = ({
  component: Component,
  nav,
  noScroll = false,
  sidebarRight,
  title = 'Foodsby',
  ...rest
}) => {
  return (
    <Route
      {...rest}
      noScroll={noScroll}
      render={matchProps => (
        <ErrorBoundary key={matchProps.location.pathname}>
          <GlobalDataLoader match={matchProps.match}>
            <LayoutSidebar nav={nav} sidebarRight={sidebarRight} {...matchProps}>
              <DocumentTitle title={title ?? FOODSBY_GENERIC_TITLE}>
                <Component {...matchProps} />
              </DocumentTitle>
            </LayoutSidebar>
          </GlobalDataLoader>
        </ErrorBoundary>
      )}
    />
  )
}
