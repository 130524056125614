import React from 'react'
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import { updateDefaultPaymentMethodAtCheckout, updatePaymentMethod } from '../../api/api'
import ButtonSubmit from '../common/ButtonSubmit'

const EditPaymentOptionForm = ({
  paymentMethod,
  setIsLoading,
  setCardError,
  isLoading,
  loadPaymentMethodsStart,
  setEditFieldShownFor,
}) => {
  const dispatch = useDispatch()
  const handleEditPaymentOption = async data => {
    const { nickname, defaultAtCheckout } = data

    setIsLoading(true)
    try {
      await updatePaymentMethod(paymentMethod.id, { nickname })
      await updateDefaultPaymentMethodAtCheckout(paymentMethod.id, { defaultAtCheckout })
      setTimeout(() => {
        dispatch(loadPaymentMethodsStart())
        setIsLoading(false)
        setEditFieldShownFor(null)
      }, 1500)
    } catch (e) {
      setCardError(e.message || 'Something went wrong while editing this payment method')
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={{
        nickname: paymentMethod.nickname ?? '',
        defaultAtCheckout: paymentMethod.defaultAtCheckout ?? false,
      }}
      onSubmit={handleEditPaymentOption}
    >
      {({ handleBlur, handleChange, values }) => (
        <Form>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="nickname"
                name="nickname"
                size="sm"
                margin="dense"
                variant="outlined"
                value={values.nickname}
                label="Nickname"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={values.defaultAtCheckout}
                    id="defaultAtCheckout"
                    name="defaultAtCheckout"
                    onChange={handleChange}
                  />
                }
                label="Set as default at checkout"
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" marginY={2}>
            <Button
              color="primary"
              size="small"
              onClick={() => setEditFieldShownFor(null)}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <ButtonSubmit color="primary" size="small" disabled={isLoading}>
              Update
            </ButtonSubmit>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default EditPaymentOptionForm
