import { themeConWeb } from '@app/util/modernThemeConweb'
import { Button } from '@foodsby/nutrient'
import { Box, Card, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { monolithCache } from '../../api/api'
import { loadOrderByIdStart, loadOrderStart } from '../../redux/modules/orderDetail'
import { loadOrderHistoryStart } from '../../redux/modules/orderHistory'
import { createTicket, loadReasonsByOrder, selfCancel } from '../../redux/modules/ticket'
import { SimpleSpinner } from '../common/SimpleSpinner'
import ContactUsLightForm, {
  ConfirmMessage,
  ErrorMessage,
  SelfCancelError,
  SuccessMessage,
} from './ContactUsLightForm'

class ContactUsSidebar extends Component {
  state = {
    cancelConfirmed: false,
    confirmCancel: false,
    initialValues: undefined,
    loading: true,
    payload: undefined,
    selfCancelError: false,
    submitError: undefined,
    submitSuccess: false,
    submitting: false,
    ticket: {},
  }

  componentDidMount = () => {
    this.setState({ loading: false })
  }

  confirmingCancel = async () => {
    this.setState({
      cancelConfirmed: true,
      confirmCancel: false,
      selfCancelError: false,
      submitError: false,
      submitting: true,
    })
    const selfCancelPayload = {
      comments: this.state.payload.comments,
      orderId: this.state.payload.order.id,
    }
    try {
      await selfCancel(selfCancelPayload)
      monolithCache.reset()
      this.props.loadReasonsByOrder(this.state.payload.order.id)
      this.props.loadOrderByIdStart(this.state.payload.order.id)
      this.props.loadOrderStart(
        format(this.props.order.orderDate, 'YYYYMMDD'),
        this.props.locationId,
      )
      this.props.loadOrderHistoryStart()
      this.setState({
        selfCancelError: undefined,
        submitSuccess: true,
        submitting: false,
      })
    } catch (error) {
      this.setState({ selfCancelError: error, submitting: false })
    }
  }

  cancelRejected = () => {
    this.setState({ cancelConfirmed: false, confirmCancel: false })
  }

  handleChange = e => {
    const { ticket } = this.state
    this.setState({ ticket: { ...ticket, [e.target.name]: e.target.value } })
  }

  submitTicket = e => {
    e.preventDefault()
    const { ticket } = this.state
    const reason = this.props.reasons.find(reason => reason.id === parseInt(ticket.reasonOption))
    const payload = {
      comments: ticket.comment.trim(),
      order: {
        date: this.props.order.orderDate,
        id: this.props.order.orderId,
        location: this.props.order.locationName,
        store: this.props.order.storeName,
      },
      reasonOption: {
        id: ticket.reasonOption,
        label: reason.label,
      },
      reasonType: reason.reasonTypeId,
      ticketType: 'regular',
    }
    this.setState({ payload })
    if (Number(payload.reasonOption.id) === 8) {
      this.setState({ confirmCancel: true })
    } else {
      this.setState({ submitting: true })
      createTicket(payload)
        .then(() => {
          this.props.loadReasonsByOrder(payload.order.id)
          this.props.loadOrderByIdStart(payload.order.id)
          this.props.loadOrderHistoryStart()
          this.setState({ submitSuccess: true, submitting: false })
        })
        .catch(error => {
          if (error && error.message) {
            this.setState({ submitError: error, submitting: false })
          }
        })
    }
  }

  render() {
    const { reasons } = this.props
    const {
      confirmCancel,
      loading,
      selfCancelError,
      submitError,
      submitSuccess,
      submitting,
    } = this.state
    return (
      <Card className="contact-us-sidebar">
        {submitting || loading ? (
          <SimpleSpinner />
        ) : submitSuccess ? (
          <SuccessMessage />
        ) : (
          <div className="panel">
            <Box marginBottom={'1em'}>
              <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus2, fontWeight: 500 }}>
                Contact Support
              </Typography>
            </Box>
            <Box marginBottom={'1.5em'}>
              <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus2, fontWeight: 700 }}>
                Need assistance with your order?
              </Typography>
            </Box>
            <ContactUsLightForm
              handleChange={this.handleChange}
              handleSubmit={this.submitTicket}
              reasons={reasons}
            />
          </div>
        )}
        {confirmCancel && (
          <ConfirmMessage
            cancelRejected={this.cancelRejected}
            confirmingCancel={this.confirmingCancel}
          />
        )}
        {submitError ? (
          <ErrorMessage />
        ) : selfCancelError ? (
          <SelfCancelError selfCancelError={selfCancelError} />
        ) : null}
        {!submitting && !loading && submitSuccess && (
          <div className="row center-xs">
            <Button onClick={() => this.setState({ submitSuccess: false })}>
              Submit another ticket
            </Button>
          </div>
        )}
      </Card>
    )
  }
}

const mapStateToProps = state => {
  const { reasons } = state.ticket
  const { currentUser } = state.user
  const { order } = state.orderDetail

  return {
    currentUser,
    order,
    reasons,
  }
}

const mapDispatchToProps = {
  loadOrderByIdStart,
  loadOrderHistoryStart,
  loadOrderStart,
  loadReasonsByOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsSidebar)
