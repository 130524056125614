import { format } from 'date-fns'
import { first } from 'lodash'

import {
  getCateringDeliveryDateAndTimeApi,
  getDeliveriesForLocationApi,
  getDeliveryDropoffApi,
  getDeliveryRewardsForLocationApi,
  getReserveDeliveryDateAndTimeApi,
  getReserveFeeApi,
} from '../../services/delivery'
import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import { selectLocationDeliveryId } from './selectors'

// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const DELIVERY_SCHEDULE_SET = 'foodsby/delivery/DELIVERY_SCHEDULE_SET'
export const DELIVERY_REWARDS_SET = 'foodsby/delivery/DELIVERY_REWARDS_SET'
export const DELIVERY_DROPOFF_SCHEDULE_SET = 'foodsby/delivery/DELIVERY_DROPOFF_SCHEDULE_SET'
export const SELECTED_DATE_SET = 'foodsby/delivery/SELECTED_DATE_SET'
export const CATERING_DELIVERY_DATE_AND_TIME_SET =
  'foodsby/delivery/CATERING_DELIVERY_DATE_AND_TIME_SET'
export const DELIVERY_RESERVE_FEE_SET = 'foodsby/delivery/DELIVERY_RESERVE_FEE_SET'
export const RESERVE_DELIVERY_DATE_AND_TIME_SET =
  'foodsby/delivery/RESERVE_DELIVERY_DATE_AND_TIME_SET'

export const setDeliverySchedule = createAsyncAction(DELIVERY_SCHEDULE_SET)
export const setDeliveryRewards = createAsyncAction(DELIVERY_REWARDS_SET)
export const setDeliveryDropoffSchedule = createAsyncAction(DELIVERY_DROPOFF_SCHEDULE_SET)
export const setSelectedDateFulfilled = createAction(FULFILLED(SELECTED_DATE_SET))
export const setDeliveryScheduleFulfilled = createAction(FULFILLED(DELIVERY_SCHEDULE_SET))
export const setCateringDeliveryDateAndTime = createAsyncAction(CATERING_DELIVERY_DATE_AND_TIME_SET)
export const setDeliveryReserveFee = createAsyncAction(DELIVERY_RESERVE_FEE_SET)
export const setReserveDeliveryDateAndTime = createAsyncAction(RESERVE_DELIVERY_DATE_AND_TIME_SET)

export const loadDeliveryScheduleStart = (locationId, date) => {
  return dispatch => {
    dispatch(setDeliverySchedule(getDeliveriesForLocationApi(locationId, date)))
  }
}

export const loadDeliveryRewardsStart = (locationId, date) => {
  return dispatch => {
    dispatch(setDeliveryRewards(getDeliveryRewardsForLocationApi(locationId, date)))
  }
}

export const loadDeliveryDropoffStart = deliveryDropoffId => {
  return dispatch => {
    dispatch(setDeliveryDropoffSchedule(getDeliveryDropoffApi(deliveryDropoffId)))
  }
}

export const resetSelectedDateToToday = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const locationId = selectLocationDeliveryId(state)
    const date = format(new Date(), 'YYYYMMDD')
    return dispatch(setSelectedDateFulfilled({ locationId, date }))
  }
}

export const selectDateStart = (date, locationId) => {
  return async dispatch => {
    await dispatch(loadDeliveryScheduleStart(locationId, date))
    await dispatch(loadDeliveryRewardsStart(locationId, date))
    return dispatch(setSelectedDateFulfilled({ locationId, date }))
  }
}

export const loadCateringDeliveryDateAndTimeStart = (storeId, locationId) => {
  return dispatch => {
    dispatch(setCateringDeliveryDateAndTime(getCateringDeliveryDateAndTimeApi(storeId, locationId)))
  }
}

export const loadDeliveryReserveFeeStart = deliveryDropoffId => {
  return dispatch => {
    dispatch(setDeliveryReserveFee(getReserveFeeApi(deliveryDropoffId)))
  }
}

export const loadReserveDeliveryDateAndTimeStart = (storeId, locationId) => {
  return dispatch => {
    dispatch(setReserveDeliveryDateAndTime(getReserveDeliveryDateAndTimeApi(storeId, locationId)))
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [PENDING(DELIVERY_SCHEDULE_SET)]: state => {
    return {
      ...state,
      isLoadingDeliveries: true,
    }
  },
  [FULFILLED(DELIVERY_SCHEDULE_SET)]: (state, action) => {
    return {
      ...state,
      deliveries: first(action.payload)?.deliveries ?? [],
      errorLoading: undefined,
      isLoadingDeliveries: false,
    }
  },
  [REJECTED(DELIVERY_SCHEDULE_SET)]: (state, action) => {
    return {
      ...state,
      deliveries: [],
      errorLoading: action.payload,
      isLoadingDeliveries: false,
    }
  },
  [PENDING(DELIVERY_REWARDS_SET)]: state => {
    return {
      ...state,
      isLoadingRewards: true,
    }
  },
  [FULFILLED(DELIVERY_REWARDS_SET)]: (state, action) => {
    return {
      ...state,
      rewards: action.payload ?? [],
      errorLoading: undefined,
      isLoadingRewards: false,
    }
  },
  [REJECTED(DELIVERY_REWARDS_SET)]: (state, action) => {
    return {
      ...state,
      rewards: [],
      errorLoading: action.payload,
      isLoadingDeliveries: false,
    }
  },
  [PENDING(DELIVERY_DROPOFF_SCHEDULE_SET)]: state => {
    return {
      ...state,
      errorLoading: undefined,
      isDeliveryDropoffLoading: true,
    }
  },
  [FULFILLED(DELIVERY_DROPOFF_SCHEDULE_SET)]: (state, action) => {
    return {
      ...state,
      deliveryDropoff: action.payload,
      errorLoading: undefined,
      isDeliveryDropoffLoading: false,
    }
  },
  [REJECTED(DELIVERY_DROPOFF_SCHEDULE_SET)]: (state, action) => {
    return {
      ...state,
      deliveryDropoff: initialState.deliveryDropoff,
      errorLoading: action.payload,
      isDeliveryDropoffLoading: false,
    }
  },
  [PENDING(CATERING_DELIVERY_DATE_AND_TIME_SET)]: state => {
    return {
      ...state,
      errorLoadingCateringDeliveryDateTime: undefined,
      isLoadingCateringDeliveryDateTime: true,
    }
  },
  [FULFILLED(CATERING_DELIVERY_DATE_AND_TIME_SET)]: (state, action) => {
    return {
      ...state,
      cateringDeliveryDate: action.payload.date,
      cateringDeliveryTime: action.payload.time,
      errorLoadingCateringDeliveryDateTime: undefined,
      isLoadingCateringDeliveryDateTime: false,
    }
  },
  [REJECTED(CATERING_DELIVERY_DATE_AND_TIME_SET)]: (state, action) => {
    return {
      ...state,
      cateringDeliveryDate: undefined,
      cateringDeliveryTime: undefined,
      errorLoadingCateringDeliveryDateTime: action.payload,
      isLoadingCateringDeliveryDateTime: false,
    }
  },
  [FULFILLED(SELECTED_DATE_SET)]: (state, action) => {
    const { locationId, date } = action.payload
    return {
      ...state,
      loadingSelectedDate: false,
      locationId,
      selectedDate: date,
    }
  },
  [PENDING(DELIVERY_RESERVE_FEE_SET)]: state => {
    return {
      ...state,
      errorLoadingDeliveryReserveFee: undefined,
      isLoadingDeliveryReserveFee: true,
    }
  },
  [FULFILLED(DELIVERY_RESERVE_FEE_SET)]: (state, action) => {
    return {
      ...state,
      deliveryReserveFee: action.payload,
      errorLoadingDeliveryReserveFee: undefined,
      isLoadingDeliveryReserveFee: false,
    }
  },
  [REJECTED(DELIVERY_RESERVE_FEE_SET)]: (state, action) => {
    return {
      ...state,
      errorLoadingDeliveryReserveFee: action.payload,
      isLoadingDeliveryReserveFee: false,
    }
  },
  [PENDING(RESERVE_DELIVERY_DATE_AND_TIME_SET)]: state => {
    return {
      ...state,
      errorLoadingReserveDeliveryDateTime: undefined,
      isLoadingReserveDeliveryDateTime: true,
    }
  },
  [FULFILLED(RESERVE_DELIVERY_DATE_AND_TIME_SET)]: (state, action) => {
    return {
      ...state,
      reserveDeliveryDate: action.payload.date,
      reserveDeliveryTime: action.payload.time,
      errorLoadingReserveDeliveryDateTime: undefined,
      isLoadingReserveDeliveryDateTime: false,
    }
  },
  [REJECTED(RESERVE_DELIVERY_DATE_AND_TIME_SET)]: (state, action) => {
    return {
      ...state,
      reserveDeliveryDate: undefined,
      reserveDeliveryTime: undefined,
      errorLoadingReserveDeliveryDateTime: action.payload,
      isLoadingReserveDeliveryDateTime: false,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  selectedDate: format(new Date(), 'YYYYMMDD'),
  deliveries: [],
  rewards: [],
  deliveryDropoff: {},
  cateringDeliveryDate: undefined,
  cateringDeliveryTime: undefined,
  reserveDeliveryDate: undefined,
  reserveDeliveryTime: undefined,
  deliveryReserveFee: 0.0,
  // Loading states
  isLoadingDeliveries: false,
  isLoadingRewards: false,
  isLoadingDeliveryDropoff: false,
  isLoadingCateringDeliveryDateTime: false,
  isLoadingDeliveryReserveFee: false,
  isLoadingReserveDeliveryDateTime: false,
  // Error states
  errorLoading: undefined,
  errorLoadingCateringDeliveryDateTime: undefined,
  errorLoadingDeliveryReserveFee: undefined,
  errorLoadingReserveDeliveryDateTime: undefined,
}

export default function delivery(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
