import { createSelector } from 'reselect'

// todo: move OFFICE to an enum for accountType
export const selectOfficeAccounts = createSelector(
  [state => state.offices.offices],
  offices => offices,
)
export const selectPreferredOffices = createSelector(
  [state => state.offices.preferredOffices],
  offices => offices,
)

export const selectHasOffice = createSelector(
  [selectOfficeAccounts],
  officeAccounts => officeAccounts.length !== 0,
)

export const selectAccount = createSelector([state => state.account.account], account => account)

export const selectEmailDomains = createSelector(
  [state => state.offices.emailDomains],
  emailDomains => emailDomains,
)
