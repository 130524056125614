import React from 'react'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { push } from 'connected-react-router'

import { selectQuery } from '../../redux/selectors/routerSelectors'
import DialogValueProps from '../teams/onboarding/DialogValueProps'
import people from '../../assets/people.svg'
import kiosk from '../../assets/kiosk.svg'

const DialogPreferredValueProps = ({ query, push }) => {
  const open = Boolean(query?.requestPreferred === 'true')

  const handleGetStarted = () => {
    push({
      search: queryString.stringify({
        requestPreferred: undefined,
        submitPreferred: 'true',
      }),
    })
  }

  const handleClose = () => {
    push({ search: queryString.stringify({ requestPreferred: undefined }) })
  }

  if (!open) {
    return null
  }

  return (
    <DialogValueProps
      title="Become a Preferred Location"
      description="Unlock a more consistent, reliable, and affordable lunch experience for everyone at your location. The best part? It's simple and free to get started!"
      valueProps={[
        {
          id: 1,
          image: people,
          primaryText: 'Preferred Pricing',
          secondaryText: 'Everyone at your location will get $1.99 delivery on every menu!',
        },
        {
          id: 2,
          image: kiosk,
          primaryText: 'Centralized Kiosk',
          secondaryText:
            'A designated drop spot makes it easier for restaurants to deliver and more convenient for people to pick up their meals.',
        },
      ]}
      action={
        <Button variant="contained" color="primary" onClick={handleGetStarted}>
          Get started
        </Button>
      }
      onClose={handleClose}
    />
  )
}

const mapStateToProps = state => {
  const query = selectQuery(state)

  return {
    query,
  }
}

const mapDispatchToProps = {
  push,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogPreferredValueProps)
