import React, { useEffect } from 'react'
import { Grid, Box } from '@material-ui/core'
import { connect } from 'react-redux'

import { selectTeam } from '../redux/selectors/teamSelectors'
import Header from '../components/teams/admin/Header'
import CardPaymentDetails from '../components/teams/admin/CardPaymentDetails'
import CardBillingHistory from '../components/teams/admin/CardBillingHistory'
import { loadAccountStart } from '../redux/modules/account'
import { selectAccount } from '../redux/selectors/accountSelectors'

const PageTeamPayment = ({
  isLoadingTeam,
  isLoadingPaymentMethods,
  team,
  match,
  account,
  loadAccountStart,
}) => {
  const accountId = match.params.accountId

  useEffect(() => {
    if (account === undefined) {
      loadAccountStart(accountId)
    }
  }, [account, accountId, loadAccountStart])

  return (
    <>
      <Header account={account} name={account?.name} />
      <Box mt={3}>
        <Grid container spacing={3} justifyContent="flex-start">
          <Grid item xs={12} lg={8}>
            <CardBillingHistory />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CardPaymentDetails team={team} loading={isLoadingTeam || isLoadingPaymentMethods} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const mapStateToProps = state => {
  return {
    isLoadingTeam: state.team.isLoadingTeam,
    isLoadingPaymentMethods: state.team.isLoadingPaymentMethods,
    team: selectTeam(state),
    account: selectAccount(state),
  }
}

const mapDispatchToProps = {
  loadAccountStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTeamPayment)
