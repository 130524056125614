import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import ButtonDialogClose from '../teams/common/ButtonDialogClose'
import { resetDoneUpgrading } from '../../redux/modules/account'
import { formatUrl } from '../../util/formatUtils'
import { teamMembersRoute } from '../../routes/routes'

const useStyles = makeStyles(theme => ({
  dialogAction: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
}))

const DialogUpgradeOfficeSuccess = ({ resetDoneUpgrading, push, accountId }) => {
  const classes = useStyles()
  const [showDialog, setShowDialog] = useState(true)

  const handleClose = () => {
    setShowDialog(false)
    resetDoneUpgrading()
  }

  const handleClick = () => {
    setShowDialog(false)
    resetDoneUpgrading()
    push(`${formatUrl(teamMembersRoute.path, { accountId })}?inviteMembers=true`)
  }

  return (
    <Dialog open={showDialog} maxWidth="sm" className={classes.root} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography style={{ fontWeight: '500' }} variant="h4" align="center" gutterBottom>
          Your Office is Setup!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" align="center">
          Invite everyone in the office to join Foodsby and save on lunch delivery!
        </Typography>
      </DialogContent>
      <ButtonDialogClose onClick={handleClose} />
      <DialogActions className={classes.dialogAction}>
        <Button variant="contained" color="primary" onClick={handleClick}>
          Invite People
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapDispatchToProps = {
  resetDoneUpgrading,
  push,
}

export default connect(null, mapDispatchToProps)(DialogUpgradeOfficeSuccess)
