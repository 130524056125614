import {
  getUserAttributes,
  updateUserAttributes,
  sendDownloadMobileSms,
} from '../../services/onboarding'
import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'

const MAX_RETRY = 3
const RETRY_MULTIPLIER = 1.5
let retryCount = 1
let retryDelay = 1000

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const USER_PROGRESS_SET = 'foodsby/onboarding/USER_PROGRESS_SET'
export const USER_PROGRESS_UPDATE = 'foodsby/onboarding/USER_PROGRESS_UPDATE'
export const CURRENT_STEP_SET = 'foodsby/onboarding/CURRENT_STEP_SET'
export const TEXT_MESSAGE_SEND = 'foodsby/onboarding/TEXT_MESSAGE_SEND'
export const TEXT_MESSAGE_RESET = 'foodsby/onboarding/TEXT_MESSAGE_RESET'
export const SUCCESS_SET = 'foodsby/onboarding/SUCCESS_SET'

export const setUserProgressPending = createAction(PENDING(USER_PROGRESS_SET))
export const setUserProgressFulfilled = createAction(FULFILLED(USER_PROGRESS_SET))
export const setUserProgressRejected = createAction(REJECTED(USER_PROGRESS_SET))
export const updateUserProgress = createAsyncAction(USER_PROGRESS_UPDATE)
export const setCurrentStep = createAction(CURRENT_STEP_SET)
export const sendTextMessage = createAsyncAction(TEXT_MESSAGE_SEND)
export const resetSendingTextMessage = createAction(TEXT_MESSAGE_RESET)
export const setSuccess = createAction(SUCCESS_SET)

// ------------------------------------
// Thunks
// ------------------------------------
export const loadUserProgressStart = () => {
  return async dispatch => {
    try {
      dispatch(setUserProgressPending())
      const res = await getUserAttributes()
      return dispatch(setUserProgressFulfilled(res))
    } catch (ex) {
      if (ex.code === 404 && retryCount < MAX_RETRY) {
        // Braze user not found - retry
        retryCount++
        retryDelay *= RETRY_MULTIPLIER
        return setTimeout(() => dispatch(loadUserProgressStart()), retryDelay)
      }

      return dispatch(setUserProgressRejected(ex))
    }
  }
}

export const updateUserProgressStart = userAttributes => {
  return dispatch => {
    return dispatch(updateUserProgress(updateUserAttributes(userAttributes)))
  }
}

export const sendTextMessageStart = phone => {
  return dispatch => {
    return dispatch(sendTextMessage(sendDownloadMobileSms(phone)))
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PENDING(USER_PROGRESS_SET)]: state => {
    return {
      ...state,
      isLoadingUserProgress: true,
      errorLoadingUserProgress: null,
    }
  },
  [FULFILLED(USER_PROGRESS_SET)]: (state, action) => {
    return {
      ...state,
      userProgress: action.payload,
      isLoadingUserProgress: false,
      errorLoadingUserProgress: null,
    }
  },
  [REJECTED(USER_PROGRESS_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingUserProgress: false,
      errorLoadingUserProgress: action.payload,
    }
  },
  [PENDING(USER_PROGRESS_UPDATE)]: state => {
    return {
      ...state,
      errorUpdatingUserProgress: null,
    }
  },
  [FULFILLED(USER_PROGRESS_UPDATE)]: state => {
    return {
      ...state,
      errorUpdatingUserProgress: null,
    }
  },
  [REJECTED(USER_PROGRESS_UPDATE)]: (state, action) => {
    return {
      ...state,
      errorUpdatingUserProgress: action.payload,
    }
  },
  [PENDING(TEXT_MESSAGE_SEND)]: state => {
    return {
      ...state,
      isSendingTextMessage: true,
      errorSendingTextMessage: null,
      successOnboarding: false,
    }
  },
  [FULFILLED(TEXT_MESSAGE_SEND)]: (state, action) => {
    return {
      ...state,
      isSendingTextMessage: false,
      errorSendingTextMessage: null,
      successOnboarding: true,
    }
  },
  [REJECTED(TEXT_MESSAGE_SEND)]: (state, action) => {
    return {
      ...state,
      isSendingTextMessage: false,
      errorSendingTextMessage: action.payload,
      successOnboarding: false,
    }
  },
  [CURRENT_STEP_SET]: (state, action) => {
    return {
      ...state,
      currentStep: action.payload,
    }
  },
  [TEXT_MESSAGE_RESET]: state => {
    return {
      ...state,
      isSendingTextMessage: false,
      errorSendingTextMessage: null,
      successOnboarding: false,
    }
  },
  [SUCCESS_SET]: (state, action) => {
    return {
      ...state,
      successOnboarding: action.payload,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  userProgress: null,
  currentStep: null,
  // Loading states
  isLoadingUserProgress: false,
  isSendingTextMessage: false,
  // Success states
  successOnboarding: false,
  // Error states
  errorLoadingUserProgress: null,
  errorUpdatingUserProgress: null,
  errorSendingTextMessage: null,
}

export default function onboarding(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
