import { Checkbox, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { themeConWeb } from '@app/util/modernThemeConweb'

const useStyles = makeStyles({
  toggleShowMore: {
    color: '#76A3FD',
    paddingLeft: '43px',
    fontSize: '12px',
  },
  toggleShowLess: {
    color: '#76A3FD',
    marginTop: '5px',
    fontSize: '12px',
  },
  signupDisclaimer: {
    color: themeConWeb.color.greyShade4,
    fontSize: '10px',
    lineHeight: '12px',
    marginTop: '5px',
  },
  signupTitle: {
    color: themeConWeb.color.primaryType,
    fontSize: '14px',
    paddingLeft: '10px',
  },
  disclaimerContainer: {
    paddingLeft: '43px',
  },
})

const AutoEnrollment = ({
  handleBlur,
  handleChange,
  values,
  handleToggleSeeMoreSms,
  toggleSeeMoreSms,
}) => {
  const classes = useStyles()
  return (
    <div>
      <p className={classes.signupTitle}>
        <Checkbox
          style={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 0, color: 'primary' }}
          checked={values.autoEnrollSms}
          onBlur={handleBlur}
          onChange={handleChange}
          name="autoEnrollSms"
        />
        Enroll in SMS reminders and updates
      </p>

      {!toggleSeeMoreSms ? (
        <button type="button" onClick={handleToggleSeeMoreSms} className={classes.toggleShowMore}>
          Show More
        </button>
      ) : (
        <div className={classes.disclaimerContainer}>
          <Typography className={classes.signupDisclaimer}>
            By signing up via text, you consent to receive transactional text messages from Foodsby
            such as order reminders, order and delivery updates at the number provided. Consent is
            not a condition of any purchase. Message and data rates may apply. Message frequency
            varies. You can unsubscribe at any time by replying STOP.{' '}
            <a
              href="https://home.foodsby.com/terms-privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of service
            </a>
            .
          </Typography>
          <button type="button" onClick={handleToggleSeeMoreSms} className={classes.toggleShowLess}>
            Show Less
          </button>
        </div>
      )}
    </div>
  )
}
export default AutoEnrollment
