import { Box, Grid, Switch, Typography } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Paper from '@material-ui/core/Paper'
import React from 'react'

export const DocsSwitch = () => {
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  })

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked })
  }

  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid xs={12}>
          <Typography component="h2" gutterBottom variant="h2">
            Text Fields
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Paper>
            <Box padding={4}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.checkedA}
                      onChange={handleChange('checkedA')}
                      value="checkedA"
                    />
                  }
                  label="Secondary"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.checkedB}
                      color="primary"
                      onChange={handleChange('checkedB')}
                      value="checkedB"
                    />
                  }
                  label="Primary"
                />
                <FormControlLabel control={<Switch value="checkedC" />} label="Uncontrolled" />
                <FormControlLabel control={<Switch value="checkedD" />} disabled label="Disabled" />
                <FormControlLabel
                  control={<Switch checked value="checkedE" />}
                  disabled
                  label="Disabled"
                />
              </FormGroup>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default DocsSwitch
