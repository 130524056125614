import React from 'react'
import { Box, Typography, Link } from '@material-ui/core'
import { startCase } from 'lodash'
const PricingPlan = ({ preferredType }) => (
  <>
    <Box display={'inline'}>
      <Typography display="inline" variant="h5" style={{ fontWeight: '500' }}>
        Pricing:
      </Typography>
      <Typography
        style={{
          color: 'green',
          fontWeight: '500',
        }}
        display="inline"
        variant="h5"
      >
        {' '}
        {startCase(preferredType.toLowerCase())}
      </Typography>
    </Box>
    <Box marginTop={'2em'}>
      <Typography>
        Learn more about our pricing plans at{' '}
        <Link href={'https://home.foodsby.com/offices'} target="_blank" rel="noopener noreferrer">
          home.foodsby.com/offices.
        </Link>
      </Typography>
    </Box>
  </>
)
export default PricingPlan
