import { geocodeByPlaceId } from 'react-places-autocomplete'
import { api, API_MONO } from '../api/api'

export async function getAddressByPlaceId(placeId) {
  const location = await geocodeByPlaceId(placeId)
  const latLng = {
    lat: location[0].geometry.location.lat(),
    lng: location[0].geometry.location.lng(),
  }
  const formattedSearchAddress = formatAddress(location[0])
  return { address: formattedSearchAddress, latLng }
}

/*
Location search endpoints. They call the same path but are two different methods until
we update the jwt-lib to accept an object of query params since it doesnt like getting undefined
*/
export const searchByLatLngFallbackApi = ({ lat, lng, fallback }) =>
  api.get(
    `${API_MONO}/v3/locations/building-search?latitude=${encodeURIComponent(
      lat,
    )}&longitude=${encodeURIComponent(lng)}&fallback=${fallback}`,
  )

export const searchByLatLngFallbackApiResidential = ({
  city,
  lat,
  lng,
  state,
  street,
  zip,
  fallback,
}) =>
  api.get(
    `${API_MONO}/v3/locations/building-search?latitude=${encodeURIComponent(
      lat,
    )}&longitude=${encodeURIComponent(
      lng,
    )}&fallback=${fallback}&street=${street}&city=${city}&state=${state}&zip=${zip}`,
  )

// ------------------------------------
// Helpers
// ------------------------------------
export function formatAddress(address) {
  const formattedSearchAddress = {
    city: '',
    state: '',
    street: '',
    zip: '',
  }
  if (address && address.address_components) {
    address.address_components.forEach(addr => {
      addr.types.forEach(type => {
        switch (type) {
          case 'street_number':
            formattedSearchAddress.street = addr.long_name
            break
          case 'route':
            formattedSearchAddress.street += formattedSearchAddress.street
              ? ` ${addr.long_name}`
              : addr.long_name
            break
          case 'sublocality':
          case 'locality':
          case 'neighborhood':
            formattedSearchAddress.city = addr.long_name
            break
          case 'administrative_area_level_1':
            formattedSearchAddress.state = addr.long_name
            break
          case 'postal_code':
            formattedSearchAddress.zip = addr.short_name
            break
          default:
            break
        }
      })
    })
  }
  return formattedSearchAddress
}
