import React, { useState } from 'react'
import { Typography, Box } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import cm from 'classnames'

const useStyles = makeStyles(theme => ({
  element: {
    padding: '10px',
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  elementFocused: {
    padding: '9px',
    border: `2px solid black`,
  },
  elementError: {
    padding: '9px',
    border: `2px solid ${theme.palette.error.main}`,
  },
}))

export default function StripePaymentMethodElement({ id, CardElement }) {
  const theme = useTheme()
  const classes = useStyles()

  const [errorMessage, setErrorMessage] = useState()
  const [focus, setFocus] = useState(false)

  const handleChange = ({ error }) => {
    if (errorMessage && !error) {
      setErrorMessage()
    }
    if (error) {
      setErrorMessage(error.message)
    }
  }

  return (
    <>
      <Box
        className={cm(classes.element, {
          [classes.elementFocused]: focus,
          [classes.elementError]: !!errorMessage,
        })}
      >
        <CardElement
          id={id}
          onChange={handleChange}
          options={{
            style: {
              base: {
                fontSize: '16px',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
              },
              invalid: {
                color: theme.palette.error.main,
              },
            },
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </Box>

      {errorMessage && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </>
  )
}
