import { Typography, Link, Card, Divider } from '@material-ui/core'
import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Panel } from '../common/Layout'
import PanelHeader from '../common/PanelHeader'

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    fontSize: '19px',
    fontWeight: 700,
  },
  divider: {
    marginTop: '10px',
    marginBottom: '5px',
  },
  smsFlexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const PanelNotificationPreferences = ({
  isLoadingEmailPreferenceUrl,
  emailPreferenceUrl,
  errorSettingSmsSubscriptionStatus,
  isLoadingSmsSubscriptionStatus,
  smsToggleStatus,
  findModalToDisplay,
  isLoadingUpdatedSmsSubscriptionStatus,
}) => {
  const classes = useStyles()

  return (
    <Card elevation={0}>
      {isLoadingEmailPreferenceUrl || isLoadingSmsSubscriptionStatus ? (
        <SkeletonPanelNotificationPreferences />
      ) : (
        <Panel>
          <PanelHeader title="Notification Preferences" />
          <Typography className={classes.sectionTitle}>Email</Typography>
          <Typography variant="body2">
            Update email preferences{' '}
            <Link href={emailPreferenceUrl} target="_blank">
              here.
            </Link>
          </Typography>
          <Divider className={classes.divider} />
          <div className={classes.smsFlexBox}>
            <Typography className={classes.sectionTitle}>SMS</Typography>
            {isLoadingUpdatedSmsSubscriptionStatus ? (
              <CircularProgress size={16} />
            ) : (
              <Switch checked={smsToggleStatus} onChange={findModalToDisplay} color="primary" />
            )}
          </div>
          <Typography variant="body2">
            Receive delivery updates and reminders via text/SMS.
          </Typography>
          {errorSettingSmsSubscriptionStatus && (
            <Typography color="error" variant="body2">
              There was an error with your sms subscription status.
            </Typography>
          )}
        </Panel>
      )}
    </Card>
  )
}
export default PanelNotificationPreferences

const SkeletonPanelNotificationPreferences = () => (
  <>
    <Skeleton height={40} width="100%" />
    <Skeleton height={56} width="100%" />
    <Skeleton height={56} width="100%" />
  </>
)
