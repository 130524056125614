import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { formatAsMonthDayOrWeekday } from '../../../util/dateUtils'

const HeaderDeliveryDate = ({ selectedDate }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="start" paddingTop={0.5}>
      <Typography
        variant="caption"
        color="textSecondary"
        style={{ fontSize: '14px', fontWeight: 'bold' }}
      >
        Delivery Date
      </Typography>
      <Typography style={{ flexGrow: 1, fontSize: '14px' }}>
        {formatAsMonthDayOrWeekday(selectedDate)}
      </Typography>
    </Box>
  )
}

const mapStateToProps = state => {
  const { selectedDate } = state.delivery

  return {
    selectedDate,
  }
}

export default connect(mapStateToProps, null)(HeaderDeliveryDate)
