import { push } from 'connected-react-router'
import { format } from 'date-fns'
import { isNil, omit } from 'lodash'
import { call, put, race, select, take } from 'redux-saga/effects'

import {
  addItemsToCart,
  addItemsToCateringCart,
  addItemsToReserveCart,
  loadCateringOrderCart,
  loadReserveOrderCart,
  loadMenuItem,
  loadOrderCart,
  removeItemFromCart,
  removeItemFromCateringCart,
  removeItemFromReserveCart,
  deleteCateringOrder,
  deleteReserveOrder,
  updateOrderItemWithQuantityApi,
} from '../../api/api'
import {
  checkoutRoute,
  pickCateringDeliveryDateAndTimeRoute,
  placeCateringOrderRoute,
  pickReserveDeliveryDateAndTimeRoute,
  placeReserveOrderRoute,
} from '../../routes/routes'
import {
  getCheckoutCartApi,
  getOrderForEditApi,
  getOrderFromCartForEditApi,
  updateOrderItemApi,
} from '../../services/cart'
import { BRAZE_CUSTOM_EVENTS, publishBrazeEvent } from '../../util/braze'
import { logException, normalizeErrorResponse } from '../../util/errorUtils'
import { formatUrl } from '../../util/formatUtils'
import { createAction, FULFILLED, PENDING, REJECTED } from '../utils'
import { clearMenuItem } from './menu'
import { ADD_PAST_ORDER_TO_CART_SUCCESS } from './pastorder'
import { loadMenuItemFailure, loadMenuItemSuccess } from './personalize'
import { apiSaga } from './sagas'
import { selectIsAuthenticated, selectNormalizedMenuItem, selectOrderCart } from './selectors'
import { loadCateringDeliveryDateAndTimeStart } from './delivery'

// ------------------------------------
// CONSTs
// ------------------------------------
const CHECKOUT_ERROR = 'There was an error checking out.'
const REMOVE_ITEM_ERROR = 'There was an error removing the item from your cart.'
const LOAD_ERROR = 'There was an error loading your cart.'
const ADD_ITEM_ERROR = 'There was an error adding the item to your cart.'

// ------------------------------------
// Action Types
// ------------------------------------
export const CHECKOUT_CART_SET = 'foodsby/cart/CHECKOUT_CART_SET'

export const LOAD_ORDER_CART = 'foodsby/cart/LOAD_ORDER_CART'
export const LOAD_CATERING_ORDER_CART = 'foodsby/cart/LOAD_CATERING_ORDER_CART'
export const LOAD_RESERVE_ORDER_CART = 'foodsby/cart/LOAD_RESERVE_ORDER_CART'
export const LOAD_ORDER_CART_SUCCESS = 'foodsby/cart/LOAD_ORDER_CART_SUCCESS'
export const LOAD_ORDER_CART_FAILURE = 'foodsby/cart/LOAD_ORDER_CART_FAILURE'

export const ADD_ITEM_TO_CART = 'foodsby/cart/ADD_ITEM_TO_CART'
export const ADD_ITEM_TO_CATERING_CART = 'foodsby/cart/ADD_ITEM_TO_CATERING_CART'
export const ADD_ITEM_TO_RESERVE_CART = 'foodsby/cart/ADD_ITEM_TO_RESERVE_CART'
export const ADD_ITEM_TO_CART_SUCCESS = 'foodsby/cart/ADD_ITEM_TO_CART_SUCCESS'
export const ADD_ITEM_TO_CART_FAILURE = 'foodsby/cart/ADD_ITEM_TO_CART_FAILURE'

export const REMOVE_ITEM_FROM_ORDER_CART = 'foodsby/cart/REMOVE_ITEM_FROM_ORDER_CART'
export const REMOVE_ITEM_FROM_CATERING_ORDER_CART =
  'foodsby/cart/REMOVE_ITEM_FROM_CATERING_ORDER_CART'
export const REMOVE_ITEM_FROM_RESERVE_ORDER_CART =
  'foodsby/cart/REMOVE_ITEM_FROM_RESERVE_ORDER_CART'
export const REMOVE_ITEM_FROM_ORDER_CART_SUCCESS =
  'foodsby/cart/REMOVE_ITEM_FROM_ORDER_CART_SUCCESS'
export const REMOVE_ITEM_FROM_ORDER_CART_FAILURE =
  'foodsby/cart/REMOVE_ITEM_FROM_ORDER_CART_FAILURE'

export const DELETE_CATERING_ORDER_CART = 'foodsby/cart/DELETE_CATERING_ORDER_CART'
export const DELETE_CATERING_ORDER_CART_SUCCESS = 'foodsby/cart/DELETE_CATERING_ORDER_CART_SUCCESS'
export const DELETE_CATERING_ORDER_CART_FAILURE = 'foodsby/cart/DELETE_CATERING_ORDER_CART_FAILURE'

export const DELETE_RESERVE_ORDER_CART = 'foodsby/cart/DELETE_RESERVE_ORDER_CART'
export const DELETE_RESERVE_ORDER_CART_SUCCESS = 'foodsby/cart/DELETE_RESERVE_ORDER_CART_SUCCESS'
export const DELETE_RESERVE_ORDER_CART_FAILURE = 'foodsby/cart/DELETE_RESERVE_ORDER_CART_FAILURE'

export const REMOVE_ITEM_FROM_CHECKOUT_CART = 'foodsby/cart/REMOVE_ITEM_FROM_CATERING_CHECKOUT_CART'
export const REMOVE_ITEM_FROM_RESERVE_CHECKOUT_CART =
  'foodsby/cart/REMOVE_ITEM_FROM_RESERVE_CHECKOUT_CART'
export const REMOVE_ITEM_FROM_CHECKOUT_CART_SUCCESS =
  'foodsby/cart/REMOVE_ITEM_FROM_CHECKOUT_CART_SUCCESS'
export const REMOVE_ITEM_FROM_CHECKOUT_CART_FAILURE =
  'foodsby/cart/REMOVE_ITEM_FROM_CHECKOUT_CART_FAILURE'

export const LOAD_EDIT_ORDER = 'foodsby/cart/LOAD_EDIT_ORDER'
export const LOAD_EDIT_ORDER_SUCCESS = 'foodsby/cart/LOAD_EDIT_ORDER_SUCCESS'
export const LOAD_EDIT_ORDER_FAILURE = 'foodsby/cart/LOAD_EDIT_ORDER_FAILURE'

export const EDIT_ORDER_IN_CART = 'foodsby/cart/EDIT_ORDER_IN_CART'
export const EDIT_ORDER_IN_CATERING_CART = 'foodsby/cart/EDIT_ORDER_IN_CATERING_CART'
export const EDIT_ORDER_IN_RESERVE_CART = 'foodsby/cart/EDIT_ORDER_IN_RESERVE_CART'
export const EDIT_ORDER_IN_CART_SUCCESS = 'foodsby/cart/EDIT_ORDER_IN_CART_SUCCESS'
export const EDIT_ORDER_IN_CART_FAILURE = 'foodsby/cart/EDIT_ORDER_IN_CART_FAILURE'

export const CANCEL_EDITING_ORDER = 'foodsby/cart/CANCEL_EDITING_ORDER'
export const CLEAR_MENU_ITEMS_FOR_EDIT = 'foodsby/cart/CLEAR_MENU_ITEMS_FOR_EDIT'

// ------------------------------------
// New Action Creators
// ------------------------------------
export const setCheckoutCartPending = createAction(PENDING(CHECKOUT_CART_SET))
export const setCheckoutCartFulfilled = createAction(FULFILLED(CHECKOUT_CART_SET))
export const setCheckoutCartRejected = createAction(REJECTED(CHECKOUT_CART_SET))

// ------------------------------------
// Old Action Creators
// ------------------------------------
export const loadOrderCartStart = deliveryDropoffId => {
  return {
    payload: { deliveryDropoffId },
    type: LOAD_ORDER_CART,
  }
}
export const loadCateringOrderCartStart = (storeId, locationId) => {
  return {
    payload: { storeId, locationId },
    type: LOAD_CATERING_ORDER_CART,
  }
}

export const loadReserveOrderCartStart = (storeId, locationId) => {
  return {
    payload: { storeId, locationId },
    type: LOAD_RESERVE_ORDER_CART,
  }
}

export const loadOrderCartSuccess = orderCart => {
  if (!orderCart) {
    return {
      payload: {
        orderCart: {
          additionalInstructions: null,
          orderItems: [],
        },
      },
      type: LOAD_ORDER_CART_SUCCESS,
    }
  } else {
    return {
      payload: { orderCart },
      type: LOAD_ORDER_CART_SUCCESS,
    }
  }
}

export const loadOrderCartFailure = error => {
  return {
    error,
    type: LOAD_ORDER_CART_FAILURE,
  }
}

export const addItemToCartStart = (
  locationId,
  menuItemId,
  dropoffId,
  formData,
  checkout = false,
) => {
  return {
    payload: { checkout, dropoffId, formData, locationId, menuItemId },
    type: ADD_ITEM_TO_CART,
  }
}

export const addItemToCateringCartStart = (
  locationId,
  menuItemId,
  storeId,
  formData,
  checkout = false,
) => {
  return {
    payload: { checkout, storeId, formData, locationId, menuItemId },
    type: ADD_ITEM_TO_CATERING_CART,
  }
}

export const addItemToReserveCartStart = (
  locationId,
  menuItemId,
  storeId,
  formData,
  checkout = false,
) => {
  return {
    payload: { checkout, storeId, formData, locationId, menuItemId },
    type: ADD_ITEM_TO_RESERVE_CART,
  }
}

export const addItemToCartSuccess = response => {
  return {
    payload: response,
    type: ADD_ITEM_TO_CART_SUCCESS,
  }
}

export const addItemToCartFailure = error => {
  return {
    error,
    type: ADD_ITEM_TO_CART_FAILURE,
  }
}

export const removeItemFromOrderCartStart = (menuItemId, dropoffId) => {
  return {
    payload: { dropoffId, menuItemId },
    type: REMOVE_ITEM_FROM_ORDER_CART,
  }
}

export const removeItemFromCateringOrderCartStart = (menuItemId, storeId, locationId) => {
  return {
    payload: { storeId, locationId, menuItemId },
    type: REMOVE_ITEM_FROM_CATERING_ORDER_CART,
  }
}

export const removeItemFromReserveOrderCartStart = (menuItemId, storeId, locationId) => {
  return {
    payload: { storeId, locationId, menuItemId },
    type: REMOVE_ITEM_FROM_RESERVE_ORDER_CART,
  }
}

export const deleteCateringOrderCartStart = (storeId, locationId) => {
  return {
    payload: { storeId, locationId },
    type: DELETE_CATERING_ORDER_CART,
  }
}

export const deleteReserveOrderCartStart = (storeId, locationId) => {
  return {
    payload: { storeId, locationId },
    type: DELETE_RESERVE_ORDER_CART,
  }
}

export const deleteCateringOrderCartSuccess = response => {
  return {
    payload: response,
    type: DELETE_CATERING_ORDER_CART_SUCCESS,
  }
}

export const deleteReserveOrderCartSuccess = response => {
  return {
    payload: response,
    type: DELETE_RESERVE_ORDER_CART_SUCCESS,
  }
}

export const deleteCateringOrderCartFailure = error => {
  return {
    error,
    type: DELETE_CATERING_ORDER_CART_FAILURE,
  }
}

export const deleteReserveOrderCartFailure = error => {
  return {
    error,
    type: DELETE_RESERVE_ORDER_CART_FAILURE,
  }
}

export const removeItemFromOrderCartSuccess = response => {
  return {
    payload: response,
    type: REMOVE_ITEM_FROM_ORDER_CART_SUCCESS,
  }
}

export const removeItemFromOrderCartFailure = error => {
  return {
    error,
    type: REMOVE_ITEM_FROM_ORDER_CART_FAILURE,
  }
}

export const removeItemFromCheckoutCartStart = (menuItemId, dropoffId) => {
  return {
    payload: { dropoffId, menuItemId },
    type: REMOVE_ITEM_FROM_CHECKOUT_CART,
  }
}

export const removeItemFromCheckoutCartSuccess = response => {
  return {
    payload: response,
    type: REMOVE_ITEM_FROM_CHECKOUT_CART_SUCCESS,
  }
}

export const removeItemFromCheckoutCartFailure = error => {
  return {
    error,
    type: REMOVE_ITEM_FROM_CHECKOUT_CART_FAILURE,
  }
}

export const loadEditOrder = (hash, orderItemId, isEditingPastOrder, userId, menuItemId) => {
  return {
    payload: {
      hash,
      isEditingPastOrder,
      menuItemId,
      orderItemId,
      userId,
    },
    type: LOAD_EDIT_ORDER,
  }
}

export const loadEditOrderSuccess = response => {
  return {
    payload: response,
    type: LOAD_EDIT_ORDER_SUCCESS,
  }
}

export const loadEditOrderFailure = error => {
  return {
    error,
    type: LOAD_EDIT_ORDER_FAILURE,
  }
}

export const editOrderInCart = (
  locationId,
  menuItemId,
  dropoffId,
  formData,
  orderItemId,
  isEditingFromMenu,
  checkout = false,
) => {
  return {
    payload: {
      checkout,
      dropoffId,
      formData,
      isEditingFromMenu,
      locationId,
      menuItemId,
      orderItemId,
    },
    type: EDIT_ORDER_IN_CART,
  }
}

export const editCateringOrderInCart = (
  locationId,
  menuItemId,
  storeId,
  formData,
  orderItemId,
  isEditingFromMenu,
  checkout = false,
) => {
  return {
    payload: {
      checkout,
      storeId,
      formData,
      isEditingFromMenu,
      locationId,
      menuItemId,
      orderItemId,
    },
    type: EDIT_ORDER_IN_CATERING_CART,
  }
}

export const editReserveOrderInCart = (
  locationId,
  menuItemId,
  storeId,
  formData,
  orderItemId,
  isEditingFromMenu,
  checkout = false,
) => {
  return {
    payload: {
      checkout,
      storeId,
      formData,
      isEditingFromMenu,
      locationId,
      menuItemId,
      orderItemId,
    },
    type: EDIT_ORDER_IN_RESERVE_CART,
  }
}

export const editOrderInCartSuccess = () => {
  return {
    type: EDIT_ORDER_IN_CART_SUCCESS,
  }
}

export const editOrderInCartFailure = error => {
  return {
    payload: error,
    type: EDIT_ORDER_IN_CART_FAILURE,
  }
}

export const cancelEditingOrder = () => {
  return {
    type: CANCEL_EDITING_ORDER,
  }
}

export const clearMenuItemsForEdit = () => {
  return {
    type: CLEAR_MENU_ITEMS_FOR_EDIT,
  }
}

// ------------------------------------
// Thunks
// ------------------------------------
export const loadCheckoutCartStart = (deliveryDropoffId, willSubscribe) => {
  return async dispatch => {
    try {
      dispatch(setCheckoutCartPending())
      const checkoutCart = await getCheckoutCartApi(deliveryDropoffId, willSubscribe)
      dispatch(setCheckoutCartFulfilled({ checkoutCart, willSubscribe }))
    } catch (ex) {
      logException(ex)
      dispatch(setCheckoutCartRejected(ex))
    }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ADD_ITEM_TO_CART]: state => {
    return {
      ...state,
      isCartSaving: true,
    }
  },
  [ADD_ITEM_TO_CATERING_CART]: state => {
    return {
      ...state,
      isCartSaving: true,
    }
  },
  [ADD_ITEM_TO_RESERVE_CART]: state => {
    return {
      ...state,
      isCartSaving: true,
    }
  },
  [ADD_ITEM_TO_CART_FAILURE]: (state, action) => {
    const message = normalizeErrorResponse(
      action.error.response,
      action.error.message || ADD_ITEM_ERROR,
    )
    return {
      ...state,
      isCartSaving: false,
      orderCartError: { message },
    }
  },
  [ADD_ITEM_TO_CART_SUCCESS]: state => {
    return {
      ...state,
      isCartSaving: false,
      isEditingOrder: false,
      isEditingPastOrder: false,
    }
  },
  [CANCEL_EDITING_ORDER]: state => {
    return {
      ...state,
      isEditingOrder: false,
      isEditingPastOrder: false,
      isLoadingOrderForEdit: false,
      menuItemForEdit: {},
    }
  },
  [CLEAR_MENU_ITEMS_FOR_EDIT]: state => {
    return {
      ...state,
      menuItemForEdit: {},
    }
  },
  [EDIT_ORDER_IN_CART]: state => {
    return {
      ...state,
      isEditingOrder: true,
    }
  },
  [EDIT_ORDER_IN_CATERING_CART]: state => {
    return {
      ...state,
      isEditingOrder: true,
    }
  },
  [EDIT_ORDER_IN_RESERVE_CART]: state => {
    return {
      ...state,
      isEditingOrder: true,
    }
  },
  [EDIT_ORDER_IN_CART_FAILURE]: (state, action) => {
    const message = normalizeErrorResponse(action.response)
    return {
      ...state,
      checkoutCartError: { message },
      isCartSaving: false,
      isEditingOrder: false,
    }
  },
  [EDIT_ORDER_IN_CART_SUCCESS]: state => {
    return {
      ...state,
      checkoutCartError: undefined,
      isCheckoutCartLoading: false,
      isEditingOrder: false,
      isLoadingOrderForEdit: false,
    }
  },
  [PENDING(CHECKOUT_CART_SET)]: state => {
    return {
      ...state,
      checkoutCartError: undefined,
      editOrderError: undefined,
      isCheckoutCartLoading: true,
    }
  },
  [FULFILLED(CHECKOUT_CART_SET)]: (state, action) => {
    const { checkoutCart, willSubscribe } = action.payload
    return {
      ...state,
      checkoutCart: checkoutCart.invoice.order,
      checkoutCartError: undefined,
      couponUsage: checkoutCart.invoice.couponUsage,
      creditUsage: checkoutCart.invoice.creditUsage,
      isCheckoutCartLoading: false,
      programUsage: checkoutCart.invoice.programUsage,
      storedCurrencyUsage: checkoutCart.invoice.storedCurrencyUsage,
      subscriptionDetails: checkoutCart.invoice.subscriptionDetails,
      cardPaymentDetail: checkoutCart.invoice.cardPaymentDetail,
      willSubscribe: !isNil(willSubscribe) ? willSubscribe : state.willSubscribe,
    }
  },
  [REJECTED(CHECKOUT_CART_SET)]: (state, action) => {
    const message = normalizeErrorResponse(
      action.payload?.response,
      action.payload?.data || action.payload?.message || CHECKOUT_ERROR,
    )
    return {
      ...state,
      checkoutCart: {
        orderItems: [],
      },
      checkoutCartError: {
        message,
      },
      isCheckoutCartLoading: false,
    }
  },
  [LOAD_EDIT_ORDER]: (state, action) => {
    return {
      ...state,
      isEditingOrder: false,
      isEditingPastOrder: action.payload.isEditingPastOrder,
      isLoadingOrderForEdit: true,
    }
  },
  [LOAD_EDIT_ORDER_FAILURE]: (state, action) => {
    const message = normalizeErrorResponse(action.response)
    return {
      ...state,
      editOrderError: { message },
    }
  },
  [LOAD_EDIT_ORDER_SUCCESS]: (state, action) => {
    const response = action.payload
    let menuItemForEdit = response.modifiers.reduce((all, { answers, questionId: id }) => {
      all[`q_${id}`] = answers.map(a => `${a.answerId}`)
      return all
    }, {})
    menuItemForEdit.menuItemId = response.menuItem
      ? response.menuItem.menuItemId
      : response.menuItemId
    menuItemForEdit.orderItemId = response.orderItemId
    menuItemForEdit.specialInstructions = response.specialInstructions
    menuItemForEdit.quantity = response.quantity
    return {
      ...state,
      isEditingOrder: true,
      isLoadingOrderForEdit: false,
      menuItemForEdit,
    }
  },
  [LOAD_ORDER_CART]: state => {
    return {
      ...state,
      editOrderError: undefined,
      isOrderCartLoading: true,
      orderCartError: undefined,
    }
  },
  [LOAD_CATERING_ORDER_CART]: state => {
    return {
      ...state,
      editOrderError: undefined,
      isOrderCartLoading: true,
      orderCartError: undefined,
    }
  },
  [LOAD_RESERVE_ORDER_CART]: state => {
    return {
      ...state,
      editOrderError: undefined,
      isOrderCartLoading: true,
      orderCartError: undefined,
    }
  },
  [LOAD_ORDER_CART_FAILURE]: (state, action) => {
    const message = normalizeErrorResponse(
      action.error.response,
      action.error.message || LOAD_ERROR,
    )
    return {
      ...state,
      isOrderCartLoading: false,
      orderCart: {
        orderItems: [],
      },
      orderCartError: { message },
    }
  },
  [LOAD_ORDER_CART_SUCCESS]: (state, action) => {
    const { orderCart } = action.payload
    return {
      ...state,
      isOrderCartLoading: false,
      orderCart,
      orderCartError: undefined,
    }
  },
  [REMOVE_ITEM_FROM_CHECKOUT_CART]: state => {
    return {
      ...state,
      isCartSaving: true,
    }
  },
  [REMOVE_ITEM_FROM_CHECKOUT_CART_FAILURE]: (state, action) => {
    const message = normalizeErrorResponse(
      action.error.response,
      action.error.message || REMOVE_ITEM_ERROR,
    )
    return {
      ...state,
      checkoutCartError: { message },
      isCartSaving: false,
    }
  },
  [REMOVE_ITEM_FROM_CHECKOUT_CART_SUCCESS]: state => {
    return {
      ...state,
      isCartSaving: false,
    }
  },
  [REMOVE_ITEM_FROM_ORDER_CART]: state => {
    return {
      ...state,
      isCartSaving: true,
    }
  },
  [REMOVE_ITEM_FROM_CATERING_ORDER_CART]: state => {
    return {
      ...state,
      isCartSaving: true,
    }
  },
  [REMOVE_ITEM_FROM_RESERVE_ORDER_CART]: state => {
    return {
      ...state,
      isCartSaving: true,
    }
  },
  [DELETE_CATERING_ORDER_CART]: state => {
    return {
      ...state,
      isCartSaving: true,
    }
  },
  [DELETE_RESERVE_ORDER_CART]: state => {
    return {
      ...state,
      isCartSaving: true,
    }
  },
  [DELETE_CATERING_ORDER_CART_SUCCESS]: state => {
    return {
      ...state,
      isCartSaving: false,
    }
  },
  [DELETE_RESERVE_ORDER_CART_SUCCESS]: state => {
    return {
      ...state,
      isCartSaving: false,
    }
  },
  [DELETE_CATERING_ORDER_CART_FAILURE]: state => {
    return {
      ...state,
      isCartSaving: false,
    }
  },
  [DELETE_RESERVE_ORDER_CART_FAILURE]: state => {
    return {
      ...state,
      isCartSaving: false,
    }
  },
  [REMOVE_ITEM_FROM_ORDER_CART_FAILURE]: (state, action) => {
    const message = normalizeErrorResponse(
      action.error.response,
      action.error.message || REMOVE_ITEM_ERROR,
    )
    return {
      ...state,
      isCartSaving: false,
      orderCartError: { message },
    }
  },
  [REMOVE_ITEM_FROM_ORDER_CART_SUCCESS]: state => {
    return {
      ...state,
      isCartSaving: false,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  checkoutCart: {
    orderItems: [],
  },
  couponUsage: {},
  creditUsage: {
    creditBalance: 0,
  },
  orderCart: {
    orderItems: [],
  },
  programUsage: {},
  willSubscribe: false,
}

export default function cart(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------
export function* watchLoadOrderCart() {
  while (true) {
    try {
      const {
        payload: { deliveryDropoffId },
      } = yield take(LOAD_ORDER_CART)

      const isAuthenticated = yield select(selectIsAuthenticated)
      if (isAuthenticated) {
        yield call(
          apiSaga,
          loadOrderCart,
          [deliveryDropoffId],
          loadOrderCartSuccess,
          loadOrderCartFailure,
        )
      }
    } catch (ex) {
      yield put(loadOrderCartFailure(ex))
    }
  }
}
// create a saga for deleting catering order
export function* watchLoadCateringOrderCart() {
  while (true) {
    try {
      const {
        payload: { storeId, locationId },
      } = yield take(LOAD_CATERING_ORDER_CART)

      const isAuthenticated = yield select(selectIsAuthenticated)
      if (isAuthenticated) {
        yield call(
          apiSaga,
          loadCateringOrderCart,
          [storeId, locationId],
          loadOrderCartSuccess,
          loadOrderCartFailure,
        )
      }
    } catch (ex) {
      yield put(loadOrderCartFailure(ex))
    }
  }
}

export function* watchLoadReserveOrderCart() {
  while (true) {
    try {
      const {
        payload: { storeId, locationId },
      } = yield take(LOAD_RESERVE_ORDER_CART)

      const isAuthenticated = yield select(selectIsAuthenticated)
      if (isAuthenticated) {
        yield call(
          apiSaga,
          loadReserveOrderCart,
          [storeId, locationId],
          loadOrderCartSuccess,
          loadOrderCartFailure,
        )
      }
    } catch (ex) {
      yield put(loadOrderCartFailure(ex))
    }
  }
}

export const convertFormDataToMenuItem = (menuItemId, formData, normalizedMenuItem) => {
  let convertedMenuItem = {
    menuItemId,
    specialInstructions: formData.specialInstructions || '',
    quantity: formData.quantity,
  }
  const questionList = omit(formData, 'specialInstructions', 'quantity')
  convertedMenuItem.modifiers = Object.keys(questionList).map(question => {
    const questionId = Number(question.slice(question.indexOf('_') + 1))
    const answerOrAnswerArray = questionList[question]
    return {
      answers: Array.isArray(answerOrAnswerArray)
        ? answerOrAnswerArray.map(answer => ({ answerId: answer }))
        : [{ answerId: answerOrAnswerArray }],
      depth: normalizedMenuItem.entities.questions[questionId].depth,
      questionId: questionId,
    }
  })
  return convertedMenuItem
}

export function* watchAddItemToCart() {
  while (true) {
    try {
      let {
        payload: { dropoffId, formData, menuItemId },
      } = yield take(ADD_ITEM_TO_CART)
      const normalizedMenuItem = yield select(selectNormalizedMenuItem)
      if (formData.orderItemId && formData.menuItemId) {
        formData = omit(formData, ['menuItemId', 'orderItemId'])
      }
      const convertedMenuItem = convertFormDataToMenuItem(menuItemId, formData, normalizedMenuItem)

      yield call(
        apiSaga,
        addItemsToCart,
        [dropoffId, [convertedMenuItem]],
        addItemToCartSuccess,
        addItemToCartFailure,
      )
    } catch (err) {
      logException(err)
      yield put(addItemToCartFailure(err))
    }
  }
}
export function* watchAddItemToCateringCart() {
  while (true) {
    try {
      let {
        payload: { storeId, locationId, formData, menuItemId },
      } = yield take(ADD_ITEM_TO_CATERING_CART)
      const normalizedMenuItem = yield select(selectNormalizedMenuItem)
      if (formData.orderItemId && formData.menuItemId) {
        formData = omit(formData, ['menuItemId', 'orderItemId'])
      }
      const convertedMenuItem = convertFormDataToMenuItem(menuItemId, formData, normalizedMenuItem)
      yield call(
        apiSaga,
        addItemsToCateringCart,
        [storeId, locationId, [convertedMenuItem]],
        addItemToCartSuccess,
        addItemToCartFailure,
      )
    } catch (err) {
      logException(err)
      yield put(addItemToCartFailure(err))
    }
  }
}

export function* watchAddItemToReserveCart() {
  while (true) {
    try {
      let {
        payload: { storeId, locationId, formData, menuItemId },
      } = yield take(ADD_ITEM_TO_RESERVE_CART)
      const normalizedMenuItem = yield select(selectNormalizedMenuItem)
      if (formData.orderItemId && formData.menuItemId) {
        formData = omit(formData, ['menuItemId', 'orderItemId'])
      }
      const convertedMenuItem = convertFormDataToMenuItem(menuItemId, formData, normalizedMenuItem)
      yield call(
        apiSaga,
        addItemsToReserveCart,
        [storeId, locationId, [convertedMenuItem]],
        addItemToCartSuccess,
        addItemToCartFailure,
      )
    } catch (err) {
      logException(err)
      yield put(addItemToCartFailure(err))
    }
  }
}

export function* watchAddItemToCartAndLoadOrderCartSuccess() {
  while (true) {
    yield race([take(ADD_ITEM_TO_CART_SUCCESS), take(ADD_PAST_ORDER_TO_CART_SUCCESS)])
    yield take(LOAD_ORDER_CART_SUCCESS)
    const orderCart = yield select(selectOrderCart)
    if (orderCart) {
      publishBrazeEvent(BRAZE_CUSTOM_EVENTS.ADDED_TO_CART, {
        date: format(orderCart.orderDate, 'YYYY-MM-DD'),
        orderId: orderCart.orderId,
        platform: 'web',
      })
    }
  }
}

export function* watchAddItemToAndAddItemToCartSuccess() {
  while (true) {
    const response = yield take(ADD_ITEM_TO_CART)
    yield take(ADD_ITEM_TO_CART_SUCCESS)

    const { checkout, dropoffId, locationId } = response.payload
    yield put(clearMenuItem())
    if (checkout) {
      const isAuthenticated = yield select(selectIsAuthenticated)

      const route = formatUrl(checkoutRoute.path, {
        dropoffId,
        locationId,
      })
      if (!isAuthenticated) {
        yield put(loadOrderCartStart(dropoffId))
      }
      yield put(push(route))
    } else {
      // fire an action to reload the cart
      yield put(loadOrderCartStart(dropoffId))
    }
  }
}

export function* watchAddItemToAndAddItemToCateringCartSuccess() {
  while (true) {
    const response = yield take(ADD_ITEM_TO_CATERING_CART)
    yield take(ADD_ITEM_TO_CART_SUCCESS)

    const { checkout, storeId, locationId } = response.payload
    yield put(clearMenuItem())
    if (checkout) {
      const isAuthenticated = yield select(selectIsAuthenticated)

      const route = formatUrl(pickCateringDeliveryDateAndTimeRoute.path, {
        locationId,
        storeId,
      })

      if (!isAuthenticated) {
        yield put(loadCateringOrderCartStart(storeId, locationId))
      }
      yield put(push(route))
    } else {
      // fire an action to reload the cart
      yield put(loadCateringOrderCartStart(storeId, locationId))
    }
  }
}

export function* watchAddItemToAndAddItemToReserveCartSuccess() {
  while (true) {
    const response = yield take(ADD_ITEM_TO_RESERVE_CART)
    yield take(ADD_ITEM_TO_CART_SUCCESS)

    const { checkout, storeId, locationId } = response.payload
    yield put(clearMenuItem())
    if (checkout) {
      const isAuthenticated = yield select(selectIsAuthenticated)

      const route = formatUrl(pickReserveDeliveryDateAndTimeRoute.path, {
        locationId,
        storeId,
      })

      if (!isAuthenticated) {
        yield put(loadReserveOrderCartStart(storeId, locationId))
      }
      yield put(push(route))
    } else {
      // fire an action to reload the cart
      yield put(loadReserveOrderCartStart(storeId, locationId))
    }
  }
}

export function* watchEditOrder() {
  while (true) {
    try {
      const {
        payload: { hash, orderItemId, userId },
      } = yield take(LOAD_EDIT_ORDER)
      const isAuthenticated = yield select(selectIsAuthenticated)
      if (hash) {
        yield call(
          apiSaga,
          getOrderForEditApi,
          [hash, userId],
          loadEditOrderSuccess,
          loadEditOrderFailure,
        )
      } else if (hash === null && orderItemId && isAuthenticated) {
        yield call(
          apiSaga,
          getOrderFromCartForEditApi,
          [orderItemId],
          loadEditOrderSuccess,
          loadEditOrderFailure,
        )
      }
    } catch (ex) {
      yield put(loadEditOrderFailure(ex))
    }
  }
}

export function* watchEditOrderSuccess() {
  while (true) {
    try {
      const { payload: response } = yield take(LOAD_EDIT_ORDER_SUCCESS)
      const isAuthenticated = yield select(selectIsAuthenticated)
      if (isAuthenticated) {
        yield call(
          apiSaga,
          loadMenuItem,
          [response.menuItemId],
          loadMenuItemSuccess,
          loadMenuItemFailure,
        )
      } else {
        yield put(loadMenuItemSuccess(response.menuItem))
      }
    } catch (ex) {
      yield put(loadMenuItemFailure(ex))
    }
  }
}

export function* watchEditOrderInCart() {
  while (true) {
    const {
      payload: {
        checkout,
        dropoffId,
        formData,
        isEditingFromMenu,
        locationId,
        menuItemId,
        orderItemId,
      },
    } = yield take(EDIT_ORDER_IN_CART)
    const normalizedMenuItem = yield select(selectNormalizedMenuItem)
    const sendingData = omit(formData, ['menuItemId', 'orderItemId'])
    const convertedMenuItem = convertFormDataToMenuItem(menuItemId, sendingData, normalizedMenuItem)
    const route = formatUrl(checkoutRoute.path, {
      dropoffId,
      locationId,
    })

    let updateOrderApi = updateOrderItemApi

    if (convertedMenuItem.quantity > 1) {
      updateOrderApi = updateOrderItemWithQuantityApi
    }

    yield call(
      apiSaga,
      updateOrderApi,
      [orderItemId, convertedMenuItem],
      editOrderInCartSuccess,
      editOrderInCartFailure,
      checkout ? route : undefined,
    )
    // fire an action to reload the cart
    if (isEditingFromMenu) {
      yield put(loadOrderCartStart(dropoffId))
    } else {
      yield put(loadCheckoutCartStart(dropoffId))
    }
  }
}

export function* watchEditOrderInCateringCart() {
  while (true) {
    const {
      payload: {
        checkout,
        storeId,
        formData,
        isEditingFromMenu,
        locationId,
        menuItemId,
        orderItemId,
      },
    } = yield take(EDIT_ORDER_IN_CATERING_CART)
    const normalizedMenuItem = yield select(selectNormalizedMenuItem)
    const sendingData = omit(formData, ['menuItemId', 'orderItemId'])
    const convertedMenuItem = convertFormDataToMenuItem(menuItemId, sendingData, normalizedMenuItem)
    const route = formatUrl(pickCateringDeliveryDateAndTimeRoute.path, {
      locationId,
      storeId,
    })

    yield call(
      apiSaga,
      updateOrderItemWithQuantityApi,
      [orderItemId, convertedMenuItem],
      editOrderInCartSuccess,
      editOrderInCartFailure,
      checkout ? route : undefined,
    )
    // fire an action to reload the cart
    if (isEditingFromMenu) {
      yield put(loadCateringOrderCartStart(storeId, locationId))
    } else {
      yield put(push(route))
    }
  }
}

export function* watchEditOrderInReserveCart() {
  while (true) {
    const {
      payload: {
        checkout,
        storeId,
        formData,
        isEditingFromMenu,
        locationId,
        menuItemId,
        orderItemId,
      },
    } = yield take(EDIT_ORDER_IN_RESERVE_CART)
    const normalizedMenuItem = yield select(selectNormalizedMenuItem)
    const sendingData = omit(formData, ['menuItemId', 'orderItemId'])
    const convertedMenuItem = convertFormDataToMenuItem(menuItemId, sendingData, normalizedMenuItem)
    const route = formatUrl(pickReserveDeliveryDateAndTimeRoute.path, {
      locationId,
      storeId,
    })

    yield call(
      apiSaga,
      updateOrderItemWithQuantityApi,
      [orderItemId, convertedMenuItem],
      editOrderInCartSuccess,
      editOrderInCartFailure,
      checkout ? route : undefined,
    )
    // fire an action to reload the cart
    if (isEditingFromMenu) {
      yield put(loadReserveOrderCartStart(storeId, locationId))
    } else {
      yield put(push(route))
    }
  }
}

export function* watchRemoveItemFromOrderCart() {
  while (true) {
    try {
      const {
        payload: { dropoffId, menuItemId },
      } = yield take(REMOVE_ITEM_FROM_ORDER_CART)

      const isAuthenticated = yield select(selectIsAuthenticated)
      if (isAuthenticated) {
        yield call(
          apiSaga,
          removeItemFromCart,
          [dropoffId, { orderItemId: menuItemId }],
          removeItemFromOrderCartSuccess,
          removeItemFromOrderCartFailure,
        )
      }
      // fire an action to reload the cart
      yield put(loadOrderCartStart(dropoffId))
    } catch (ex) {
      yield put(removeItemFromOrderCartFailure(ex))
    }
  }
}

export function* watchRemoveItemFromCateringOrderCart() {
  while (true) {
    try {
      const {
        payload: { storeId, locationId, menuItemId },
      } = yield take(REMOVE_ITEM_FROM_CATERING_ORDER_CART)

      const isAuthenticated = yield select(selectIsAuthenticated)
      if (isAuthenticated) {
        yield call(
          apiSaga,
          removeItemFromCateringCart,
          [storeId, locationId, { orderItemId: menuItemId }],
          removeItemFromOrderCartSuccess,
          removeItemFromOrderCartFailure,
        )
      }
      // fire an action to reload the cart
      yield put(loadCateringOrderCartStart(storeId, locationId))
    } catch (ex) {
      yield put(removeItemFromOrderCartFailure(ex))
    }
  }
}

export function* watchRemoveItemFromReserveOrderCart() {
  while (true) {
    try {
      const {
        payload: { storeId, locationId, menuItemId },
      } = yield take(REMOVE_ITEM_FROM_RESERVE_ORDER_CART)

      const isAuthenticated = yield select(selectIsAuthenticated)
      if (isAuthenticated) {
        yield call(
          apiSaga,
          removeItemFromReserveCart,
          [storeId, locationId, { orderItemId: menuItemId }],
          removeItemFromOrderCartSuccess,
          removeItemFromOrderCartFailure,
        )
      }
      // fire an action to reload the cart
      yield put(loadReserveOrderCartStart(storeId, locationId))
    } catch (ex) {
      yield put(removeItemFromOrderCartFailure(ex))
    }
  }
}

export function* watchDeleteCateringOrderCart() {
  while (true) {
    try {
      const {
        payload: { storeId, locationId },
      } = yield take(DELETE_CATERING_ORDER_CART)

      const isAuthenticated = yield select(selectIsAuthenticated)
      if (isAuthenticated) {
        yield call(
          apiSaga,
          deleteCateringOrder,
          [storeId, locationId],
          deleteCateringOrderCartSuccess,
          deleteCateringOrderCartFailure,
        )
      }
      const route = formatUrl(placeCateringOrderRoute.path, {
        locationId,
        storeId,
      })

      yield put(push(route))
      yield put(loadCateringOrderCartStart(storeId, locationId))
      yield put(loadOrderCartSuccess)
      yield put(loadCateringDeliveryDateAndTimeStart(storeId, locationId))
    } catch (ex) {
      yield put(deleteCateringOrderCartFailure(ex.message || 'An error occurred'))
    }
  }
}

export function* watchDeleteReserveOrderCart() {
  while (true) {
    try {
      const {
        payload: { storeId, locationId },
      } = yield take(DELETE_RESERVE_ORDER_CART)

      const isAuthenticated = yield select(selectIsAuthenticated)
      if (isAuthenticated) {
        yield call(
          apiSaga,
          deleteReserveOrder,
          [storeId, locationId],
          deleteReserveOrderCartSuccess,
          deleteReserveOrderCartFailure,
        )
      }
      const route = formatUrl(placeReserveOrderRoute.path, {
        locationId,
        storeId,
      })

      yield put(push(route))
      yield put(loadReserveOrderCartStart(storeId, locationId))
      yield put(loadOrderCartSuccess)
    } catch (ex) {
      yield put(deleteReserveOrderCartFailure(ex.message || 'An error occurred'))
    }
  }
}

export function* watchRemoveItemFromCheckoutCart() {
  while (true) {
    const {
      payload: { dropoffId, menuItemId },
    } = yield take(REMOVE_ITEM_FROM_CHECKOUT_CART)

    yield call(
      apiSaga,
      removeItemFromCart,
      [dropoffId, { orderItemId: menuItemId }],
      removeItemFromCheckoutCartSuccess,
      removeItemFromCheckoutCartFailure,
    )
    // fire an action to reload the cart
    yield put(loadCheckoutCartStart(dropoffId))
  }
}
