import pluralize from 'pluralize'

import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import {
  getMembersForPlanApi,
  getMembersForProgramApi,
  getProgramStatsApi,
  removePlanMembersApi,
  removeProgramMembersApi,
} from '../../services/team'
import { formatAllMemberNames, isRowSelected } from '../../components/teams/admin/adminUtils'
import { selectTeam } from '../selectors/teamSelectors'
import { enqueueSnackbar } from './snackbar'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const PERK_MEMBERS_SET = 'foodsby/teamPerkDetails/PERK_MEMBERS_SET'
export const PERK_MEMBERS_REMOVE = 'foodsby/teamPerkDetails/PERK_MEMBERS_REMOVE'
export const PAGE_SET = 'foodsby/teamPerkDetails/PAGE_SET'
export const SEARCH_SET = 'foodsby/teamPerkDetails/SEARCH_SET'
export const SORT_SET = 'foodsby/teamPerkDetails/SORT_SET'
export const MEMBER_TOGGLE_SELECT = 'foodsby/teamPerkDetails/MEMBER_TOGGLE_SELECT'
export const ONE_PAGE_MEMBERS_TOGGLE_SELECT =
  'foodsby/teamPerkDetails/ONE_PAGE_MEMBERS_TOGGLE_SELECT'
export const ALL_MEMBERS_TOGGLE_SELECT = 'foodsby/teamPerkDetails/ALL_MEMBERS_TOGGLE_SELECT'
export const ACTIONABLE_MEMBERS_SET = 'foodsby/teamPerkDetails/ACTIONABLE_MEMBERS_SET'
export const ACTIONABLE_MEMBER_REMOVE = 'foodsby/teamPerkDetails/ACTIONABLE_MEMBER_REMOVE'
export const STATE_RESET_PERK_MEMBERS_REMOVE =
  'foodsby/teamPerkDetails/STATE_RESET_PERK_MEMBERS_REMOVE'
export const STATE_RESET_PERK_DETAILS = 'foodsby/teamPerkDetails/STATE_RESET_PERK_DETAILS'

//#region Program specific action types
export const SELECTED_PROGRAM_SET = 'foodsby/teamPerkDetails/SELECTED_PROGRAM_SET'
export const PROGRAM_STATS_SET = 'foodsby/teamPerkDetails/PROGRAM_STATS_SET'
//#endregion

export const setPerkMembers = createAsyncAction(PERK_MEMBERS_SET)
export const removePerkMembers = createAsyncAction(PERK_MEMBERS_REMOVE)
export const setPage = createAction(PAGE_SET)
export const setSearch = createAction(SEARCH_SET)
export const setSort = createAction(SORT_SET)
export const toggleSelectMember = createAction(MEMBER_TOGGLE_SELECT)
export const toggleSelectOnePageMembers = createAction(ONE_PAGE_MEMBERS_TOGGLE_SELECT)
export const toggleSelectAllMembers = createAction(ALL_MEMBERS_TOGGLE_SELECT)
export const setActionableMembers = createAction(ACTIONABLE_MEMBERS_SET)
export const removeActionableMember = createAction(ACTIONABLE_MEMBER_REMOVE)
export const resetPerkMembersRemoveState = createAction(STATE_RESET_PERK_MEMBERS_REMOVE)
export const resetPerkDetailsState = createAction(STATE_RESET_PERK_DETAILS)

//#region Program specific action creators
export const setSelectedProgram = createAction(SELECTED_PROGRAM_SET)
export const setProgramStats = createAsyncAction(PROGRAM_STATS_SET)
//#endregion

// ------------------------------------
// Thunks
// ------------------------------------
export const removeProgramMembersStart = (programId, members) => {
  return async dispatch => {
    const memberIds = members.map(m => m.userId)

    try {
      await dispatch(removePerkMembers(removeProgramMembersApi(programId, memberIds)))

      createSuccessSnackbarForPerkMembersRemoved(dispatch, members)

      dispatch(loadProgramMembersStart(programId))
    } catch (ex) {
      dispatch(enqueueSnackbar({ message: 'Something went wrong removing members from the perk.' }))
    }
  }
}

export const loadProgramMembersStart = programId => dispatch =>
  dispatch(setPerkMembers(getMembersForProgramApi(programId)))

export const loadProgramStatsStart = programId => dispatch =>
  dispatch(setProgramStats(getProgramStatsApi(programId)))

export const loadSubscriptionMembersStart = () => {
  return (dispatch, getState) => {
    const state = getState()
    const team = selectTeam(state)
    return dispatch(setPerkMembers(getMembersForPlanApi(team.id)))
  }
}

export const removeSubscriptionMembersStart = members => {
  return async (dispatch, getState) => {
    const state = getState()
    const team = selectTeam(state)
    const memberIds = members.map(m => m.userId)

    try {
      await dispatch(removePerkMembers(removePlanMembersApi(team.id, memberIds)))

      createSuccessSnackbarForPerkMembersRemoved(dispatch, members)

      dispatch(loadSubscriptionMembersStart())
    } catch (ex) {
      dispatch(enqueueSnackbar({ message: 'Something went wrong removing members from the perk.' }))
    }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SELECTED_PROGRAM_SET]: (state, action) => {
    return {
      ...state,
      selectedProgram: action.payload,
    }
  },
  [PENDING(PERK_MEMBERS_REMOVE)]: state => {
    return {
      ...state,
      isRemovingPerkMembers: true,
      errorRemovingPerkMembers: undefined,
      successRemovingPerkMembers: false,
    }
  },
  [FULFILLED(PERK_MEMBERS_REMOVE)]: state => {
    return {
      ...state,
      isRemovingPerkMembers: false,
      errorRemovingPerkMembers: undefined,
      successRemovingPerkMembers: true,
    }
  },
  [REJECTED(PERK_MEMBERS_REMOVE)]: (state, action) => {
    return {
      ...state,
      isRemovingPerkMembers: false,
      errorRemovingPerkMembers: action.payload.message,
      successRemovingPerkMembers: false,
    }
  },
  [PENDING(PERK_MEMBERS_SET)]: state => {
    return {
      ...state,
      isLoadingMembers: true,
    }
  },
  [FULFILLED(PERK_MEMBERS_SET)]: (state, action) => {
    return {
      ...state,
      perkMembers: action.payload.content,
      isLoadingMembers: false,
    }
  },
  [REJECTED(PERK_MEMBERS_SET)]: state => {
    return {
      ...state,
      isLoadingMembers: false,
    }
  },
  [PENDING(PROGRAM_STATS_SET)]: state => {
    return {
      ...state,
      isLoadingProgramStats: true,
    }
  },
  [FULFILLED(PROGRAM_STATS_SET)]: (state, action) => {
    return {
      ...state,
      programStats: action.payload,
      isLoadingProgramStats: false,
    }
  },
  [REJECTED(PROGRAM_STATS_SET)]: state => {
    return {
      ...state,
      isLoadingProgramStats: false,
    }
  },
  [PAGE_SET]: (state, action) => {
    return {
      ...state,
      page: action.payload,
    }
  },
  [SEARCH_SET]: (state, action) => {
    return {
      ...state,
      search: action.payload,
    }
  },
  [SORT_SET]: (state, action) => {
    return {
      ...state,
      sort: action.payload,
    }
  },
  [MEMBER_TOGGLE_SELECT]: (state, action) => {
    const { selectedMembers } = state
    const member = action.payload

    return {
      ...state,
      selectedMembers: isRowSelected(selectedMembers, member)
        ? selectedMembers.filter(m => m.userId !== member.userId)
        : [...selectedMembers, member],
    }
  },
  [ONE_PAGE_MEMBERS_TOGGLE_SELECT]: (state, action) => {
    const { selectedMembers } = state
    const { checked, currentPageRows } = action.payload

    const selectedRowsOnOtherPages = selectedMembers.filter(
      sm => !currentPageRows.map(r => r.userId).includes(sm.userId),
    )
    return {
      ...state,
      selectedMembers: checked
        ? [...currentPageRows, ...selectedRowsOnOtherPages]
        : selectedRowsOnOtherPages,
    }
  },
  [ALL_MEMBERS_TOGGLE_SELECT]: (state, action) => {
    const { checked, allMembers } = action.payload

    return {
      ...state,
      selectedMembers: checked ? [] : allMembers,
    }
  },
  [ACTIONABLE_MEMBERS_SET]: state => {
    const { selectedMembers } = state
    return {
      ...state,
      actionableMembers: selectedMembers,
    }
  },
  [ACTIONABLE_MEMBER_REMOVE]: (state, action) => {
    const { actionableMembers } = state
    const member = action.payload
    return {
      ...state,
      actionableMembers: actionableMembers.filter(m => m !== member),
    }
  },
  [STATE_RESET_PERK_MEMBERS_REMOVE]: state => {
    return {
      ...state,
      isRemovingPerkMembers: false,
      successRemovingPerkMembers: false,
      errorRemovingPerkMembers: undefined,
    }
  },
  [STATE_RESET_PERK_DETAILS]: state => {
    return {
      ...state,
      perkMembers: [],
      programStats: undefined,
      page: 0,
      search: undefined,
      sort: { field: undefined, direction: undefined },
      selectedProgram: undefined,
      selectedMembers: [],
      actionableMembers: [],
    }
  },
}

export const initialState = {
  selectedProgram: undefined,
  perkMembers: [],
  programStats: undefined,
  page: 0,
  search: undefined,
  sort: { field: undefined, direction: undefined },
  selectedMembers: [],
  actionableMembers: [], // This list is initially a copy of selectedMembers but with a remove option
  // Loading states
  isRemovingPerkMembers: false,
  isLoadingMembers: false,
  isLoadingProgramStats: false,
  // Success states
  successRemovingPerkMembers: false,
  // Error states
  errorRemovingPerkMembers: undefined,
}

export default function teamPerkDetails(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Utils
// ------------------------------------
const createSuccessSnackbarForPerkMembersRemoved = (dispatch, members) => {
  const memberNames = formatAllMemberNames(members)
  dispatch(
    enqueueSnackbar({
      message: `${memberNames} ${pluralize('has', members.length)} been removed from the perk.`,
    }),
  )
}
