import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { resetSendingTextMessage } from '../../redux/modules/onboarding'

const HomeSnackbars = () => {
  const dispatch = useDispatch()
  const successOnboarding = useSelector(state => state.onboarding.successOnboarding)

  const handleDismiss = () => {
    dispatch(resetSendingTextMessage())
  }

  return (
    <Snackbar
      onClose={handleDismiss}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={5000}
      open={successOnboarding}
    >
      <Alert severity="success">Congrats! You&apos;ve completed your account setup.</Alert>
    </Snackbar>
  )
}

export default HomeSnackbars
