import React, { useEffect } from 'react'
import { CheckboxOption } from '@foodsby/nutrient'
import cn from 'classnames'

import useSubscriptions from '../../hooks/useSubscriptions'

export const Subscription = ({
  isSubscribed,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  values,
  willSubscribe,
  onChange,
}) => {
  const { subscriptionOffer, isSubscribedBefore } = useSubscriptions()
  const subscriptionFee = subscriptionOffer?.offer?.price
  const checkboxHint = isSubscribedBefore
    ? `Get $0 service fees on all orders when you subscribe for ${subscriptionFee}/month. Cancel any time in your profile.${' '}`
    : `Get $0 service fees on all orders when you subscribe for ${subscriptionFee}/month. Enjoy your first month free! Cancel any time in your profile.${' '}`

  useEffect(() => {
    return () => {
      setFieldValue('isSubscribed', false)
      onChange(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ marginBottom: willSubscribe ? '60px' : '0px' }}>
      {isSubscribed ? (
        <div className="Checkbox is-subscribed is-disabled">
          <p className="Checkbox-title" />
          <CheckboxOption
            checked={true}
            disabled
            hint={
              <>
                You are currently subscribed to Foodsby Unlimited.{' '}
                <a
                  href={'https://home.foodsby.com/terms-privacy'}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terms & conditions
                </a>
              </>
            }
            id="isSubscribed"
            label={<span style={{ fontWeight: '500' }}>Foodsby Unlimited</span>}
            name="isSubscribed"
            style={{ paddingBottom: '0', position: 'relative' }}
            value={values.isSubscribed}
          />
        </div>
      ) : (
        // Is subscribed class is a temp hack till we get the new version of the patten lib
        <div
          className={cn('Checkbox', {
            'is-checked': willSubscribe,
            'is-error': errors.isSubscribed,
          })}
        >
          <p className="Checkbox-title"></p>
          <CheckboxOption
            checked={values.isSubscribed}
            id="isSubscribed"
            label={<span style={{ fontWeight: '500' }}>Foodsby Unlimited</span>}
            name="isSubscribed"
            onBlur={handleBlur}
            onChange={handleChange}
            onClick={() => onChange(!willSubscribe)}
            style={{ position: 'relative' }}
            value={values.isSubscribed}
          />
          <span
            className={cn({
              'Checkbox-hint': true,
              'subscription-is-trial': !isSubscribedBefore,
            })}
          >
            {checkboxHint}
            <a
              href={'https://home.foodsby.com/terms-privacy'}
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms & conditions
            </a>
          </span>
          {errors.isSubscribed && <span className="Checkbox-error">{errors.isSubscribed}</span>}
          {willSubscribe && (
            <span
              className="Checkbox-success"
              style={{
                display: 'flex',
                fontWeight: 'bold',
                marginBottom: '8px',
                marginTop: '8px',
              }}
            >
              <span style={{ marginLeft: '8px' }}>
                You’ll officially be subscribed when you place your order! A card on file is
                required. Manage anytime in your profile.
              </span>
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default Subscription
