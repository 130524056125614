import React, { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { MoreHoriz } from '@material-ui/icons'

const DestructiveMenuItem = withStyles(theme => ({
  root: {
    color: theme.palette.error.light,
  },
}))(MenuItem)

const MenuTeamOptions = ({ isAdmin, canInvite, onManage, onInvite, onLeave }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = callback => {
    callback()
    handleClose()
  }

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreHoriz />
      </IconButton>
      <Menu keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {isAdmin && <MenuItem onClick={onManage}>Manage team</MenuItem>}
        {canInvite && <MenuItem onClick={onInvite}>Invite members</MenuItem>}
        <DestructiveMenuItem onClick={() => handleClick(onLeave)}>Leave</DestructiveMenuItem>
      </Menu>
    </>
  )
}

export default MenuTeamOptions
