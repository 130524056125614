import { Box, Button } from '@foodsby/nutrient'
import { themeConWeb } from '@app/util/modernThemeConweb'
import React from 'react'
const FOODSBY_BASE_URL = process.env.REACT_APP_FOODSBY_BASE_URL

const RestaurantLogin = () => (
  <section className="restaurant-login">
    <Box mb={3}>
      <h2 style={{ color: themeConWeb.color.white }}>
        Are you a restaurant trying to login to your account?
      </h2>
    </Box>
    <Box mb={3}>
      <p style={{ color: themeConWeb.color.white }}>
        Click on the button below to go to the restaurant login page.
      </p>
    </Box>
    <Box mb={3}>
      <Button
        className="secondary"
        onClick={() => (window.location.href = `${FOODSBY_BASE_URL}/main/restaurants-login`)}
        rel="noopener noreferrer"
        style={{ backgroundColor: themeConWeb.color.white }}
        target="_blank"
      >
        <i className="icon-lock" /> Restaurant Login
      </Button>
    </Box>
  </section>
)

export default RestaurantLogin
