import { Card } from '@material-ui/core'
import React from 'react'

const PageSubscriptionTermsAndConditions = () => {
  return (
    <article className="article">
      <Card>
        <h5>FOODSBY UNLIMITED DELIVERY Terms and Conditions</h5>
        <br />
        <p>
          FOODSBY UNLIMITED DELIVERY is an optional subscription-based delivery membership. This
          membership subtracts the delivery fee on food ordered and purchased from Restaurants
          through Foodsby Services in exchange for a single monthly Membership Fee. It is available
          to eligible customers through invitation only at this time. If you enroll in FOODSBY
          UNLIMITED DELIVERY, you will not pay a delivery fee on qualifying food orders for as long
          as your membership continues EXCEPT on orders made through FOODSBY MEETINGS. This
          membership does NOT subtract the delivery fee on orders made through FOODSBY MEETINGS.
        </p>
        <br />
        <p>
          If you choose to subscribe to FOODSBY UNLIMITED DELIVERY, the following additional terms
          will apply:
        </p>
        <br />
        <p>
          <em>Monthly Membership Period</em>
        </p>
        <p>
          Customers who are invited to join FOODSBY UNLIMITED DELIVERY will receive a limited time
          invitation from FOODSBY. FOODSBY UNLIMITED DELIVERY monthly membership begins on the date
          when the customer accepts the invitation and is subscribed as a “Member”. The membership
          will continue for another calendar month and will then automatically renew for subsequent
          calendar months. In these Terms and Conditions, “Membership Period” refers to each
          calendar month during which a Member remains subscribed.
        </p>
        <br />
        <p>
          <em>Membership Fees</em>
        </p>
        <p>
          The monthly fee the Customer pays for each Membership Period will be on their invitation.
          We may change the fee charged at any time with 30 days advance notice. FOODSBY will charge
          the monthly fee to the payment method that you have provided to us or to another payment
          method on file if the primary method fails.
        </p>
        <br />
        <p>
          <em>Automatic Renewal</em>
        </p>
        <p>
          FOODSBY UNLIMITED DELIVERY will automatically renew for another calendar month at the end
          of the Customers Membership Period and the Membership Fee will be automatically charged at
          the time of renewal.
        </p>
        <br />
        <p>
          <em>Changes to Terms and Conditions</em>
        </p>
        <p>
          FOODSBY may change Terms and Conditions applicable to FOODSBY UNLIMITED DELIVERY from time
          to time at FOODSBY&lsquo;S sole discretion. All FOODSBY UNLIMITED DELIVERY Memberships
          will immediately be subject to any changes to the Terms and Conditions.
        </p>
        <br />
        <p>
          <em>Cancellation</em>
        </p>
        <p>
          Customer may cancel their Membership at any time from their profile. Customer must cancel
          their Membership before the renewal period to avoid billing for the next Membership
          Period. If Customer cancels their Membership, they will continue to have the delivery fee
          waived from their orders for the remainder of the current Membership Period. Customer will
          not receive a refund for the Membership Fee of the current Membership Period. Other Terms
          Customers FOODSBY UNLIMITED DELIVERY Membership cannot be transferred or assigned to
          another user. FOODSBY reserves the right to accept, refuse, or cancel Customers FOODSBY
          UNLIMITED DELIVERY Membership at any time at FOODSBYS sole discretion. If FOODSBY cancels
          Customers FOODSBY UNLIMITED DELIVERY Membership, Customers will receive a refund of the
          Membership Fee on a pro rata basis calculated from the end of the Membership Period during
          which the Membership was cancelled.
        </p>
      </Card>
    </article>
  )
}

export default PageSubscriptionTermsAndConditions
