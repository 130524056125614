import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Business } from '@material-ui/icons'

import { withStyles } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles'

import scriptLoader from 'react-async-script-loader'

import { Formik, Form } from 'formik'
import { Alert } from '@material-ui/lab'
import { object, string } from 'yup'
import DialogAction from '../components/common/DialogAction'

import LayoutInnerPage, { HeaderInnerPage } from '../layouts/LayoutInnerPage'
import EditPersonalInformationFields from '../components/savedLocations/EditSavedLocationsFields'
import AddressCell from '../components/savedLocations/AddressCellSavedLocations'
import OptionsCell from '../components/savedLocations/OptionsCellSavedLocations'
import LocationStatusCell from '../components/savedLocations/LocationStatusCellSavedLocation'
import { formatUrl } from '../util/formatUtils'
import { homeRoute, loginRoute } from '../routes/routes'
import { DIALOG_OPTIONS } from '../components/savedLocations/savedLocationsUtils'
import { DialogSelectLocation } from '../components/register/DialogSelectLocation'
import LocationSearchContainer from '../components/location-search/LocationSearchContainer'
import {
  clearLocationErrors,
  createUserSavedLocationStart,
  deleteUserSavedLocationStart,
  updateUserSavedLocationStart,
} from '../redux/modules/location'
import { updateAndSaveUserLocationStart } from '../redux/modules/user'
import { locationRoute } from '../routes/routes'

import { MAPS_SCRIPT_URL } from '../components/location-search/LocationSearchBox'
import { themeConWeb } from '../util/modernThemeConweb'

const schemaNickname = object().shape({
  name: string(),
})

const schemaDeliveryInstructions = object().shape({
  deliveryInstructions: string()
    .nullable()
    .required('Delivery instructions required.')
    .max(60, 'Delivery instructions cannot exceed 60 characters.'),
})

const useStyles = makeStyles(theme => ({
  locationCard: {
    border: `2px solid ${theme.palette.error.light}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  tableHeaderRow: {
    backgroundColor: theme.palette.grey[300],
  },
  columnTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  titleButton: {
    fontWeight: 400,
  },
  header: {
    fontWeight: 500,
    fontSize: themeConWeb.fontSizes.basePlus3,
  },
  hoverRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
}))

const StyledTableCell = withStyles(theme => ({
  root: {
    paddingRight: 0,
    paddingLeft: 0,
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
}))(TableRow)

const PageSavedLocations = ({
  deliveryLocationId,
  savedLocations,
  locationsUserManages,
  createUserSavedLocationStart,
  updateUserSavedLocationStart,
  updateAndSaveUserLocationStart,
  deleteUserSavedLocationStart,
  clearLocationErrors,
  isAuthenticated,
  isCreatingSavedLocation,
  isUpdatingSavedLocation,
  isDeletingSavedLocation,
  createSavedLocationError,
  updateSavedLocationError,
  isScriptLoaded,
  isScriptLoadSucceed,
}) => {
  const classes = useStyles()

  const [dialogToShow, setDialogToShow] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [locationToAdd, setLocationToAdd] = useState(null)

  const handleSelectLocation = locationId => {
    const locationToSelect = savedLocations.find(
      savedLocation => savedLocation.deliveryLocationId === locationId,
    )
    setSelectedLocation(locationToSelect)
  }

  const handleOpenDialogNewLocation = () => {
    setDialogToShow(DIALOG_OPTIONS.NEW_LOCATION.id)
  }

  const handleDialogOnClose = () => {
    setDialogToShow(null)
    setLocationToAdd(null)
    setSelectedLocation(null)
  }

  const getFormikSchema = () => {
    switch (DIALOG_OPTIONS[dialogToShow].id) {
      case DIALOG_OPTIONS.EDIT_NICKNAME.id:
        return schemaNickname
      case DIALOG_OPTIONS.EDIT_DELIVERY_INSTRUCTIONS.id:
        return schemaDeliveryInstructions
      default:
        return null
    }
  }

  const handleSubmit = values => {
    switch (DIALOG_OPTIONS[dialogToShow].id) {
      case DIALOG_OPTIONS.EDIT_NICKNAME.id:
        updateUserSavedLocationStart(selectedLocation.savedLocationId, values.name, null)
        break
      case DIALOG_OPTIONS.EDIT_DELIVERY_INSTRUCTIONS.id:
        updateUserSavedLocationStart(
          selectedLocation.savedLocationId,
          null,
          values.deliveryInstructions,
        )

        break
      case DIALOG_OPTIONS.REMOVE_LOCATION.id:
        handleDeleteLocation(selectedLocation)
        break
      case DIALOG_OPTIONS.SAVE_LOCATION.id:
        createUserSavedLocationStart(locationToAdd)
        break
      default:
        return
    }
    clearLocationErrors()
  }

  const handleDeleteLocation = locationToDelete => {
    let newDefaultLocation = null
    // If user deletes the default location, set the next first location default
    if (locationToDelete.deliveryLocationId === deliveryLocationId) {
      // Get the first location that is not the default location
      newDefaultLocation = savedLocations?.find(
        savedLocation => savedLocation.deliveryLocationId !== locationToDelete.deliveryLocationId,
      )
    }
    deleteUserSavedLocationStart(locationToDelete.savedLocationId)
    newDefaultLocation && updateAndSaveUserLocationStart(newDefaultLocation.deliveryLocationId)
  }

  const handleSearch = address => {
    setLocationToAdd(address)
    setDialogToShow(DIALOG_OPTIONS.SAVE_LOCATION.id)
  }

  const columns = [
    {
      id: 'location',
      label: 'Location',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'options',
      label: '',
    },
  ]

  useEffect(() => {
    if (
      !isCreatingSavedLocation &&
      !isUpdatingSavedLocation &&
      !isDeletingSavedLocation &&
      !createSavedLocationError &&
      !updateSavedLocationError
    ) {
      handleDialogOnClose()
    }
  }, [
    isCreatingSavedLocation,
    isUpdatingSavedLocation,
    isDeletingSavedLocation,
    createSavedLocationError,
    updateSavedLocationError,
  ])

  const locationsUserManagesIds = locationsUserManages.map(location => location.locationId)

  const goToLocation = locationId => {
    window.location.href = formatUrl(locationRoute.path, { locationId })
  }

  if (!isAuthenticated) {
    return <Redirect to={loginRoute.path} />
  }

  return (
    <LayoutInnerPage
      HeaderComponent={
        <HeaderInnerPage
          showBackButton
          backButtonRoute={formatUrl(homeRoute.path, {})}
          sideElement={
            <Button
              className={classes.titleButton}
              variant="contained"
              color="primary"
              onClick={() => {
                handleOpenDialogNewLocation()
              }}
            >
              Search Location
            </Button>
          }
        >
          <Typography className={classes.header}>Saved Locations</Typography>
        </HeaderInnerPage>
      }
      MainComponent={
        <Box>
          <Box marginTop={4}>
            <Table>
              <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                  {columns.map(column => {
                    return (
                      <StyledTableCell key={column.id}>
                        <Box marginLeft={4} marginRight={2}>
                          <Typography className={classes.columnTitle}>{column.label}</Typography>
                        </Box>
                      </StyledTableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {savedLocations?.length > 0 &&
                  savedLocations.map((savedLocation, index) => {
                    const id = savedLocation.deliveryLocationId

                    const shouldShowLocationManagerLink = locationsUserManagesIds.includes(id)

                    return (
                      <StyledTableRow
                        key={id}
                        onClick={event => {
                          // Prevent `goToLocation` if clicking a button or link
                          if (
                            event.target.tagName !== 'BUTTON' &&
                            event.target.tagName !== 'A' &&
                            !event.target.closest('button') &&
                            !event.target.closest('a')
                          ) {
                            goToLocation(id)
                          }
                        }}
                        className={classes.hoverRow}
                      >
                        <StyledTableCell>
                          <AddressCell
                            handleSelectLocation={handleSelectLocation}
                            location={savedLocation}
                            shouldShowLocationManagerLink={shouldShowLocationManagerLink}
                            id={id}
                            setDialogToShow={setDialogToShow}
                            deliveryLocationId={deliveryLocationId}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <LocationStatusCell status={savedLocation.locationStatus} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <OptionsCell
                            id={id}
                            deliveryLocationId={deliveryLocationId}
                            deliveryInstructionsSupported={
                              savedLocation.deliveryInstructionsSupported
                            }
                            setDialogToShow={setDialogToShow}
                            locationIndex={index}
                            handleSelectLocation={handleSelectLocation}
                            shouldShowLocationManagerLink={shouldShowLocationManagerLink}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </Box>
          {dialogToShow && (
            <Formik
              initialValues={{
                deliveryInstructions: selectedLocation?.deliveryInstructions,
                name: selectedLocation?.name || '',
              }}
              onSubmit={handleSubmit}
              validationSchema={getFormikSchema()}
            >
              {props => {
                const { handleSubmit } = props
                return (
                  <DialogAction
                    cancelText={DIALOG_OPTIONS[dialogToShow].cancelText}
                    dialogTitle={DIALOG_OPTIONS[dialogToShow].title}
                    handleDialogOnClose={handleDialogOnClose}
                    handleSubmit={handleSubmit}
                    isSubmitting={isUpdatingSavedLocation || isCreatingSavedLocation}
                    show={Boolean(dialogToShow)}
                    submitText={DIALOG_OPTIONS[dialogToShow].confirmText}
                    updateSavedLocationError={updateSavedLocationError}
                  >
                    <Form>
                      <EditPersonalInformationFields
                        {...props}
                        dialogToShow={dialogToShow}
                        selectedLocation={selectedLocation}
                      />
                    </Form>
                    {DIALOG_OPTIONS[dialogToShow].id === DIALOG_OPTIONS.NEW_LOCATION.id && (
                      <LocationSearchContainer redirectOverride={true} label={null} />
                    )}
                    {DIALOG_OPTIONS[dialogToShow].id === DIALOG_OPTIONS.SAVE_LOCATION.id && (
                      <Box>
                        <Box
                          className={classes.locationCard}
                          alignItems="center"
                          display="flex"
                          flexDirection="row"
                        >
                          <Business color="action" style={{ fontSize: 64, marginRight: 16 }} />
                          <span>
                            <Box margin={2}>
                              <Typography variant="body1">{locationToAdd?.locationName}</Typography>
                            </Box>
                            <Box margin={2}>
                              <Typography variant="body2" color="textSecondary">
                                {locationToAdd?.deliveryLine1}, {locationToAdd?.lastLine}
                              </Typography>
                            </Box>
                          </span>
                        </Box>
                        {createSavedLocationError && (
                          <Alert severity="error">{createSavedLocationError}</Alert>
                        )}
                      </Box>
                    )}
                  </DialogAction>
                )
              }}
            </Formik>
          )}
          <DialogSelectLocation
            isScriptLoaded={isScriptLoaded}
            isScriptLoadSucceed={isScriptLoadSucceed}
            handleSelect={address => handleSearch(address)}
            locationListButtonText="Save this location"
          />
        </Box>
      }
    />
  )
}

const mapStateToProps = state => {
  const { locationsUserManages } = state.locationManager
  const {
    savedLocations,
    isCreatingSavedLocation,
    isUpdatingSavedLocation,
    isDeletingSavedLocation,
    createSavedLocationError,
    updateSavedLocationError,
  } = state.location
  const deliveryLocationId = state.location?.location?.deliveryLocationId
  const isAuthenticated = state.user?.isAuthenticated

  return {
    locationsUserManages,
    deliveryLocationId,
    savedLocations,
    isAuthenticated,
    isCreatingSavedLocation,
    isUpdatingSavedLocation,
    isDeletingSavedLocation,
    createSavedLocationError,
    updateSavedLocationError,
  }
}

const mapDispatchToProps = {
  createUserSavedLocationStart,
  deleteUserSavedLocationStart,
  updateUserSavedLocationStart,
  updateAndSaveUserLocationStart,
  clearLocationErrors,
}

PageSavedLocations.defaultProps = {}

export default scriptLoader(MAPS_SCRIPT_URL)(
  connect(mapStateToProps, mapDispatchToProps)(PageSavedLocations),
)
