import { Container, Box } from '@material-ui/core'
import React from 'react'
import * as Survey from 'survey-react'
import 'survey-react/survey.css'
import 'github-markdown-css/github-markdown.css'
import { useLocation } from 'react-router-dom'
import createSurvey from '../surveys/createSurvey'
import { postSurveyResults } from '../../api/api'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function SurveyContainer({ survey }) {
  let query = useQuery()
  const onComplete = async (surveyResults, options) => {
    options.showDataSaving('Submitting your response...')

    let allQueryParams = {}
    query.forEach((val, key) => {
      allQueryParams[key] = val
    })

    try {
      await postSurveyResults({
        userId: query.get('uid'),
        surveyPublicId: survey.id,
        surveyVersion: survey.version,
        payload: {
          ...surveyResults.data,
          ...allQueryParams,
        },
      })
      options.showDataSaving('Response submitted!')
    } catch (e) {
      options.showDataSaving(e.message)
    }
  }

  const surveyModel = createSurvey(survey.json)

  return (
    <Container>
      <Box textAlign="center" m={2} mb={4}>
        <img
          alt="Foodsby Logo"
          component="img"
          src={`${process.env.REACT_APP_BASE_ORDER_URL}/images/logo-foodsby.png`}
          title="Foodsby Logo"
          style={{ maxHeight: 45 }}
        />
      </Box>
      <Box maxWidth="700px" margin="auto">
        <Survey.Survey model={surveyModel} onComplete={onComplete} />
      </Box>
    </Container>
  )
}

export default SurveyContainer
