import { InputError } from '@foodsby/nutrient'
import { Box } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { loginStart } from '../../redux/modules/user'
import { forgotPasswordRoute } from '../../routes/routes'
import { pushAnalyticsEvent } from '../../util/gtmUtils'
import LoginForm from './LoginForm'

const LoginExtraButtons = () => (
  <Box marginTop={2} textAlign="center">
    <Link tabIndex="5" to={forgotPasswordRoute.path}>
      Forgot Password?
    </Link>
  </Box>
)

const LoginPageForm = ({ error, loginStart, path, submitting, numberOfFailedLoginAttempts }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  return (
    <section>
      <LoginForm
        handleSubmit={creds => {
          loginStart(creds, path)
          pushAnalyticsEvent('Registration Flow', 'Logged In')
        }}
        isPasswordVisible={isPasswordVisible}
        submitting={submitting}
        toggleVisibility={() => setIsPasswordVisible(!isPasswordVisible)}
        disablePasswordField={numberOfFailedLoginAttempts >= 10}
      />
      <div className="row center-xs">
        <div className="col-xs">
          {numberOfFailedLoginAttempts >= 3 ? (
            <WarningAlert numberOfFailedLoginAttempts={numberOfFailedLoginAttempts} />
          ) : (
            <InputError>{error ? error.message : ''}</InputError>
          )}
        </div>
      </div>
      <LoginExtraButtons />
    </section>
  )
}

const WarningAlert = ({ numberOfFailedLoginAttempts }) => {
  if (numberOfFailedLoginAttempts < 10) {
    return (
      <Alert severity="warning">
        Repeated failed login attempts will prevent you from logging in. Reset your password using
        the Forgot Password? link below.
      </Alert>
    )
  } else if (numberOfFailedLoginAttempts >= 10) {
    return (
      <Alert severity="error">
        In order to login, you must reset your password using the Forgot Password? link below.
      </Alert>
    )
  }
}

const mapStateToProps = state => {
  const { error, submitting, numberOfFailedLoginAttempts } = state.user
  const { address = '' } = state.locationSearch

  return {
    address,
    error,
    submitting,
    numberOfFailedLoginAttempts,
  }
}

const mapDispatchToProps = {
  loginStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageForm)
