import React, { useState } from 'react'
import { Box, TextField, Typography } from '@material-ui/core'

const MAX_DELIVERY_INSTRUCTION_LIMIT = 60

const DeliveryInstructionsField = ({
  defaultValue,
  error,
  handleBlur,
  handleChange,
  label = 'Delivery Instructions',
  autoFocus = false,
}) => {
  const [instrCount, setInstrCount] = useState(0)

  return (
    <TextField
      autoFocus={autoFocus}
      defaultValue={defaultValue ?? ''}
      error={Boolean(error)}
      fullWidth
      helperText={
        <Box component="span" display="flex" justifyContent="flex-end">
          <Typography variant="caption">
            {instrCount} / {MAX_DELIVERY_INSTRUCTION_LIMIT} characters
          </Typography>
        </Box>
      }
      id="deliveryInstructions"
      inputProps={{ maxLength: MAX_DELIVERY_INSTRUCTION_LIMIT }}
      label={label}
      multiline
      onBlur={handleBlur}
      onChange={e => {
        setInstrCount(e.target.value.length)
        handleChange(e)
      }}
      placeholder="Door code, who to ask for when we arrive, etc…"
      rowsMax={3}
      variant="outlined"
      color="secondary"
    />
  )
}

export default DeliveryInstructionsField
