import { jwt, stopImpersonating } from '@foodsby/webapp-jwt'
import { Button, Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Timer as TimerIcon } from '@material-ui/icons'
import React from 'react'
import Timer from 'react-compound-timer'

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
  },
  root: {
    background: '#EF3A24', // Foodsby-logo red
  },
  text: {
    color: '#FFFFFF',
    background: '#EF3A24',
  },
  title: {
    color: '#FFFFFF',
    flexGrow: 1,
  },
}))

export default function NavImpersonation() {
  const classes = useStyles()

  const accessToken = jwt.getDecodedAccessToken()
  const expiry = accessToken.exp
  const now = (Date.now() / 1000) | 0
  const diff = expiry - now

  return (
    <Toolbar className={classes.text}>
      <Typography className={classes.title} variant="body1">
        <i>Impasta!</i>{' '}
        <span aria-label="spaghetti" role="img">
          🍝
        </span>
        {` You are currently logged in as ${accessToken.user_name} (User ID: ${accessToken.user_id})`}
      </Typography>
      <TimerIcon />
      <Timer
        checkpoints={[{ callback: stopImpersonating, time: 0 }]}
        direction="backward"
        formatValue={value => (value < 10 ? `0${value}` : value)}
        initialTime={diff * 1000}
      >
        {() => (
          <>
            <Timer.Minutes />:<Timer.Seconds />
          </>
        )}
      </Timer>
      <Button color="inherit" onClick={stopImpersonating}>
        Exit
      </Button>
    </Toolbar>
  )
}
