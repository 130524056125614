import React from 'react'
import { Dialog, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { formatTenDigitPhoneNumber } from '../../util/formatUtils'

const NO_VALUE_TEXT = 'Not provided'

const useStyles = makeStyles(theme => ({
  container: {
    alignContent: 'center',
    padding: theme.spacing(2),
  },
  dialogPaper: {
    position: 'absolute',
    top: '15%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    padding: theme.spacing(3),
    width: '100%',
    maxWidth: '600px',
    borderRadius: '0px',
  },
  title: {
    fontSize: '32px',
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  message: {
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  confirmForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  phoneInputContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    width: '100%',
  },
  phoneInput: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
    minWidth: '80px',
    textAlign: 'center',
    border: `1px solid gray`,
    fontSize: '16px',
  },
  phoneNumberInput: {
    padding: theme.spacing(2),
    width: '65%',
    border: `1px solid gray`,
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  editButton: {
    marginLeft: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  buttonSaveAndSubscribe: {
    fontSize: '16px',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2),
    width: '50%',
    alignSelf: 'center',
    borderRadius: '1px',
    boxShadow: 'none',
  },
  disclaimer: {
    fontSize: '12px',
    color: 'gray',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
}))

export const ModalConfirmPhone = ({
  showConfirmPhoneModal,
  setSmsToggleStatus,
  closeModal,
  phone,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={showConfirmPhoneModal}
      onClose={closeModal}
      PaperProps={{
        className: classes.dialogPaper,
      }}
    >
      <div className={classes.container}>
        <Typography className={classes.title}>Sign Up for SMS Alerts</Typography>
        <Typography className={classes.message}>
          Enter your phone number to receive important updates and reminders.
        </Typography>
        <div className={classes.confirmForm}>
          <div className={classes.phoneInputContainer}>
            <div className={classes.phoneInput}>US +1</div>
            <div className={classes.phoneNumberInput}>
              {phone ? formatTenDigitPhoneNumber(phone) : NO_VALUE_TEXT}
            </div>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.buttonSaveAndSubscribe}
            onClick={setSmsToggleStatus}
            size="large"
            variant="contained"
            color="primary"
          >
            Save & Subscribe to SMS
          </Button>
        </div>
        <div>
          <p className={classes.disclaimer}>
            By submitting this form and signing up via text, you consent to receive transactional
            text messages from Foodsby such as order reminders, order and delivery updates at the
            number provided. Consent is not a condition of any purchase. Message and data rates may
            apply. Message frequency varies. You can unsubscribe at any time by replying STOP. Terms
            of service
          </p>
        </div>
      </div>
    </Dialog>
  )
}
export default ModalConfirmPhone
