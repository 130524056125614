import { Link, Typography } from '@material-ui/core'
import currency from 'currency.js'
import React from 'react'

import Favorite from '../community/Favorite'
import { Component as ComponentStyles } from './Menu'

/**
 *  popularItems doesn't return an object structure that allows us to leverage the Menu component.
 *  This means we have to copy the whole component. To avoid copying the CSS we are importing the styles in from Menu
 */
export const MenuPopularItems = ({ onSelectMenuItem, popularItems }) => (
  <ComponentStyles>
    <section>
      <Typography component="h2" gutterBottom variant="h5">
        Popular Items
      </Typography>
      <Typography className="-categoryDescription" variant="body1">
        Choose from the top items for this restaurant.
      </Typography>
      <ul>
        {popularItems.map(item => (
          <li className="-categoryItem" key={item.id}>
            <Favorite className="-categoryFavorite" id={item.id} parentComponentName="Menu" />
            <button
              className="-categoryPrimaryContents"
              onClick={evt => {
                onSelectMenuItem(item.id)
              }}
            >
              <div className="-categoryPrimaryContent">
                <Link className="-categoryItemTitle" variant="h6" color="textPrimary">
                  {item.name}
                </Link>
                <Typography variant="h6" className="-categoryItemPrice">
                  {currency(item.price).format()}
                </Typography>
              </div>
              <Typography className="-itemDescription" variant="body1">
                {item.description}
              </Typography>
            </button>
          </li>
        ))}
      </ul>
    </section>
  </ComponentStyles>
)

export default MenuPopularItems
