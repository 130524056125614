import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Business } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import React from 'react'
import { connect } from 'react-redux'

import { clearLocationErrors, createUserSavedLocationStart } from '../../redux/modules/location'
import { clearUpdateLocationError, updateUserLocationTemporarily } from '../../redux/modules/user'

import {
  selectCurrentLocationWithAddress,
  selectUserLocationId,
} from '../../redux/modules/selectors'

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 8,
    },
  },
  locationName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}))

export const DialogUpdateUserLocation = ({
  clearLocationErrors,
  clearUpdateLocationError,
  createSavedLocationError,
  createUserSavedLocationStart,
  isLocationLoading,
  isSavedLocationsLoading,
  location,
  locationId,
  locationError,
  savedLocationError,
  userLocationId,
  updateUserLocationError,
  updateUserLocationTemporarily,
}) => {
  const classes = useStyles()

  const errorInLocationUpdateModal = !!createSavedLocationError || !!updateUserLocationError
  const successSavedLocationsLoading = !isSavedLocationsLoading && !savedLocationError
  const successLocationLoading = !isLocationLoading && !locationError
  const currentLocationId = location ? parseInt(location.deliveryLocationId) : 0

  const showLocationUpdateModal =
    (location &&
      successSavedLocationsLoading &&
      successLocationLoading &&
      userLocationId &&
      userLocationId !== currentLocationId) ||
    errorInLocationUpdateModal

  return (
    <Dialog className={classes.dialog} fullWidth open={showLocationUpdateModal}>
      <DialogTitle>Would you like to save this location?</DialogTitle>
      <DialogContent>
        <Box alignItems="center" display="flex" flexDirection="row">
          <Business color="action" style={{ fontSize: 64, marginRight: 16 }} />
          <span>
            <Typography className={classes.locationName}>
              {location?.deliveryLocationName}
            </Typography>
            <Typography>{location?.street}</Typography>
            <Typography>
              {location?.city}, {location?.state}
            </Typography>
          </span>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            updateUserLocationTemporarily(locationId)
            clearLocationErrors()
            clearUpdateLocationError()
          }}
        >
          No, just browsing
        </Button>
        <Button
          color="primary"
          onClick={() => {
            createUserSavedLocationStart(location)
          }}
          variant="contained"
        >
          Yes, save to profile
        </Button>
      </DialogActions>
      {(updateUserLocationError || createSavedLocationError) && (
        <Alert severity="error">
          {updateUserLocationError?.message ?? createSavedLocationError ?? (
            <span>
              Oops! Something went wrong.
              <br /> If this continues to happen, please contact support.
            </span>
          )}
        </Alert>
      )}
    </Dialog>
  )
}

const mapStateToProps = state => {
  const { updateUserLocationError } = state.user
  const userLocationId = selectUserLocationId(state)
  const location = selectCurrentLocationWithAddress(state)

  const {
    createSavedLocationError,
    isLocationLoading,
    isSavedLocationsLoading,
    locationError,
    savedLocationError,
  } = state.location

  return {
    createSavedLocationError,
    isLocationLoading,
    isSavedLocationsLoading,
    location,
    locationError,
    savedLocationError,
    userLocationId,
    updateUserLocationError,
  }
}

const mapDispatchToProps = {
  clearLocationErrors,
  clearUpdateLocationError,
  createUserSavedLocationStart,
  updateUserLocationTemporarily,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogUpdateUserLocation)
