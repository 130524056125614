import React from 'react'

export const ReferralsTermsOfUse = () => (
  <>
    <h5>FOODSBY PROMOTIONAL Terms and Conditions (specific to Referral Link)</h5>
    <br />
    <p>
      Limited time offer, subject to change. Referrer must have a Foodsby account to send a referral
      link and to receive credits. Referrer can send out multiple links. Referrer will receive
      credits once a referee creates a Foodsby account and places an order. For referee to receive
      credits they must create a Foodsby account and place an order. Referee can only receive
      credits for 1 Foodsby account creation and order. If referee receives multiple links from
      multiple referrers, credits will be given to the referrer’s link that the referee last clicked
      on to create a Foodsby account and to place an order. Credits earned can not be used to place
      a FOODSBY MEETING order or to pay for a FOODSBY UNLIMITED subscription.
    </p>
  </>
)

export default ReferralsTermsOfUse
