import React from 'react'
import {
  Box,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { isNil, noop } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import cn from 'classnames'

const useStyles = makeStyles({
  chip: {
    marginLeft: '16px',
  },
})

const ListItemPerk = ({
  primaryText,
  primaryTypographyClass,
  secondaryText,
  secondaryTypographyProps,
  secondaryTypographyClass,
  ChipComponent,
  BottomComponent,
  ActionComponent,
  onClick,
  bottomComponentMarginTop,
}) => {
  const classes = useStyles()
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('xs'))

  return (
    <ListItem button={!isNil(onClick)} onClick={!isNil(onClick) ? onClick : noop}>
      <ListItemText
        disableTypography
        primary={
          <Box
            display="flex"
            flexDirection={mobileView ? 'column' : 'row'}
            alignItems={mobileView ? 'flex-start' : 'center'}
          >
            <Typography variant="h6" className={primaryTypographyClass}>
              {primaryText}
            </Typography>
            <span className={cn({ [classes.chip]: !mobileView })}>{ChipComponent}</span>
          </Box>
        }
        secondary={
          <Box mt={2}>
            <Typography
              variant="body1"
              className={secondaryTypographyClass}
              {...secondaryTypographyProps}
            >
              {secondaryText}
            </Typography>
            {BottomComponent && <Box mt={bottomComponentMarginTop ?? 3}>{BottomComponent}</Box>}
          </Box>
        }
      />
      {ActionComponent && <ListItemSecondaryAction>{ActionComponent}</ListItemSecondaryAction>}
    </ListItem>
  )
}

export default ListItemPerk
