import { Box, Card, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { cancelEditingOrder, loadEditOrder } from '../../redux/modules/cart'
import { clearMenuItem } from '../../redux/modules/menu'
import {
  selectCurrentLocationWithAddress,
  selectMenuItemForEdit,
} from '../../redux/modules/selectors'
import { OrderCart } from '../cart/Cart'
import ButtonSubmit from '../common/ButtonSubmit'
import StickyContainer from '../common/StickyContainer'
import PersonalizeOrder from '../personalize-order/PersonalizeOrder'
import { isOrderMinimumHit, isOrderUnderMaxWeight } from '../../util/cateringUtils'
import RestaurantDetailsSidebar from '../common/RestaurantDetailsSidebar'

const useStyles = makeStyles({
  card: {
    padding: '0',
  },
  orderInfoText: {
    fontWeight: 'bold',
  },
  clickableDisabledButton: {
    boxShadow: 'none',
    '&:hover': {
      cursor: 'auto',
      boxShadow: 'none',
    },
  },
})

export const CateringOrderSidebar = ({
  shouldDisplayNextButton,
  isAuthenticated,
  isEditingOrder,
  isEditingPastOrder,
  isCartSaving,
  isLoadingOrderForEdit,
  isLocationLoading,
  isOrderCartLoading,
  isCateringStoreLoading,
  loadEditOrder,
  cateringStore,
  location,
  menuItemForEdit,
  normalizedMenuItem,
  orderCart,
  orderCartError,
  handleClose,
  handleNext,
  handleRemoveItemFromCart,
  handleAddItemsToCart,
  handleEditOrder,
  nextButtonDisplayText,
  personalizeOrderAddMoreIsPrimary,
  isPickingDateAndTime,
  deliveryDateAndTimeNotFilledOut,
  personalizeOrderNextButtonIsHidden,
  handleNextButtonClick,
  handleAddItemButtonClick,
  showRestaurantLogo,
}) => {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState(null)

  const minimumErrorMessage = 'Please add more items to your cart to meet the minimum order amount.'
  const overMaxErrorMessage =
    "This restaurant can't cater this many items. Please remove some items from your cart."

  const disableCheckout =
    !isAuthenticated ||
    orderCart.orderItems.length === 0 ||
    cateringStore === undefined ||
    (isPickingDateAndTime && deliveryDateAndTimeNotFilledOut) ||
    !isOrderMinimumHit(orderCart.itemSubTotal, cateringStore?.moneyMinimumInCents) ||
    !isOrderUnderMaxWeight(orderCart.weight, cateringStore?.maxWeight)
  const isLoading = isLocationLoading || isOrderCartLoading || isCateringStoreLoading

  useEffect(() => {
    if (orderCartError) {
      setErrorMessage(orderCartError.message)
    } else if (
      isOrderMinimumHit(orderCart.itemSubTotal, cateringStore?.moneyMinimumInCents) &&
      isOrderUnderMaxWeight(orderCart.weight, cateringStore?.maxWeight)
    ) {
      setErrorMessage(null)
    }
  }, [orderCartError, orderCart, cateringStore])

  const onSubmit = () => {
    if (!isOrderMinimumHit(orderCart.itemSubTotal, cateringStore?.moneyMinimumInCents)) {
      setErrorMessage(minimumErrorMessage)
    } else if (!isOrderUnderMaxWeight(orderCart.weight, cateringStore?.maxWeight)) {
      setErrorMessage(overMaxErrorMessage)
    } else {
      setErrorMessage(null)
      handleNext()
    }
  }

  return (
    <StickyContainer bottomOffset={18} stickyStyle={{ paddingTop: '100px' }} topOffset={-100}>
      <Card className={classes.card}>
        {isLoading && <SkeletonLoading />}

        {!isLoading &&
          showRestaurantLogo &&
          cateringStore?.restaurantName &&
          cateringStore?.logoUrl && (
            <>
              <Box paddingLeft={3} paddingTop={3}>
                <Typography color="textSecondary" gutterBottom variant="overline">
                  Restaurant
                </Typography>
              </Box>
              <Box paddingLeft={3} paddingTop={3}>
                <RestaurantDetailsSidebar
                  logoLink={cateringStore.logoUrl}
                  name={cateringStore.restaurantName}
                />
              </Box>
              <Box paddingLeft={3} paddingRight={3} paddingTop={3}>
                <Divider />
              </Box>
            </>
          )}

        {!isLoading && (
          <Box padding={3} margin={3}>
            {orderCart && (
              <OrderCart
                cart={orderCart}
                isCatering={true}
                deleteMenuItem={handleRemoveItemFromCart}
                isCartSaving={isCartSaving}
                editable="true"
                editMenuItem={loadEditOrder}
                isLoadingOrderForEdit={isLoadingOrderForEdit}
                isEditingPastOrder={false}
                showEditLinks="true"
                orderMinimumInCents={cateringStore?.moneyMinimumInCents}
                showQuantity="true"
              />
            )}
          </Box>
        )}
      </Card>

      {shouldDisplayNextButton && (
        <ButtonSubmit
          className={disableCheckout ? classes.clickableDisabledButton : null}
          color="primary"
          fullWidth
          onClick={onSubmit}
          errorMessage={errorMessage}
          variant="contained"
        >
          {nextButtonDisplayText}
        </ButtonSubmit>
      )}

      {!isEditingPastOrder && menuItemForEdit && isEditingOrder && (
        <PersonalizeOrder
          handleClose={handleClose}
          handleAddItemsToCart={handleAddItemsToCart}
          handleEditOrder={handleEditOrder}
          isEditingFromCheckout={false}
          isEditingFromMenu="true"
          isEditingOrder={isEditingOrder}
          isEditingPastOrder={false}
          locationId={location.deliveryLocationId}
          menuItemForEdit={menuItemForEdit}
          menuItemId={menuItemForEdit.menuItemId}
          normalizedMenuItem={normalizedMenuItem}
          nextButtonDisplayText={nextButtonDisplayText}
          addMoreIsPrimary={personalizeOrderAddMoreIsPrimary}
          handleNextButtonClick={handleNextButtonClick}
          nextButtonIsHidden={personalizeOrderNextButtonIsHidden}
          handleAddItemButtonClick={handleAddItemButtonClick}
          nextButtonIsDisabled={
            !isOrderMinimumHit(orderCart.itemSubTotal, cateringStore?.moneyMinimumInCents)
          }
          enableQuantity
          addItemDisplayText={'Add to Cart'}
        />
      )}
    </StickyContainer>
  )
}

const SkeletonLoading = () => (
  <>
    <Skeleton height={150} variant="rect" />
    <Box padding={3}>
      <Skeleton height={30} width="80%" />
      <Skeleton height={30} width="80%" />
      <Box marginY={2}>
        <Skeleton height={40} width="90%" />
      </Box>
      <Skeleton height={50} />
    </Box>
  </>
)

const mapStateToProps = state => {
  const { isAuthenticated } = state.user
  const { normalizedMenuItem } = state.personalize
  const location = selectCurrentLocationWithAddress(state)
  const menuItemForEdit = selectMenuItemForEdit(state)
  const { isLocationLoading } = state.location
  const {
    isEditingOrder,
    isEditingPastOrder,
    isCartSaving,
    isLoadingOrderForEdit,
    isOrderCartLoading,
    orderCart,
    orderCartError,
  } = state.cart
  const { isCateringStoreLoading, cateringStore } = state.catering
  return {
    isAuthenticated,
    isEditingOrder,
    isEditingPastOrder,
    isCartSaving,
    isLoadingOrderForEdit,
    isLocationLoading,
    isOrderCartLoading,
    isCateringStoreLoading,
    location,
    cateringStore,
    menuItemForEdit,
    normalizedMenuItem,
    orderCart,
    orderCartError,
  }
}

const mapDispatchToProps = {
  cancelEditingOrder,
  clearMenuItem,
  loadEditOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(CateringOrderSidebar)
