import React from 'react'
import { Typography, Box, Tabs, Tab } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'
import { formatUrl } from '../../util/formatUtils'

import {
  locationManagerRoute,
  locationManagerOfficesRoute,
  locationManagerDeliveryRoute,
} from '../../routes/routes'

import BackButton from '../teams/admin/BackButton'

const LinkTab = props => {
  return <Tab component={Link} {...props} />
}

const LocationManagerHeader = ({ locationId, name, Action }) => {
  const formatPath = path => formatUrl(path, { locationId: locationId })

  const location = useLocation()
  return (
    <>
      <Box mb={3}>
        <>
          <BackButton />
          {name && <Typography variant="h5">{name}</Typography>}
        </>
      </Box>

      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
        <Tabs
          value={location.pathname}
          indicatorColor="primary"
          textColor="primary"
          aria-label="location manager pages"
        >
          <LinkTab
            label="Location"
            value={formatPath(locationManagerRoute.path)}
            to={formatPath(locationManagerRoute.path)}
          />
          <LinkTab
            label="Offices"
            value={formatPath(locationManagerOfficesRoute.path)}
            to={formatPath(locationManagerOfficesRoute.path)}
          />
          <LinkTab
            label="Delivery"
            value={formatPath(locationManagerDeliveryRoute.path)}
            to={formatPath(locationManagerDeliveryRoute.path)}
          />
        </Tabs>
        {Action}
      </Box>
    </>
  )
}

export default LocationManagerHeader
