import React from 'react'

const LoadingDeliverySectionError = () => (
  <div className="center-xs">
    <h5 className="error center-xs">
      <i className="icon-warning" /> Oops! Something went wrong.
    </h5>
    <p>
      Try refreshing the page or switching between days. If this problem persists, please{' '}
      <a href={`/contact`}>contact support</a>
    </p>
  </div>
)

export default LoadingDeliverySectionError
