import React, { useEffect, useState } from 'react'
import { Skeleton } from '@material-ui/lab'
import { connect } from 'react-redux'

import { RadioPaymentMethod } from '../../common/PaymentMethod'
import { loadTeamPaymentMethodsStart } from '../../../redux/modules/team'
import { getDefaultPaymentOrUndefined } from '../../../util/paymentMethods'
import CardTeam, { CardTeamContent, CardTeamTitle } from '../common/CardTeam'
import CardActionButton from './CardActionButton'
import DialogPaymentSelect from './DialogPaymentSelect'

const CardPaymentDetails = ({ team, loading, paymentMethods, loadTeamPaymentMethodsStart }) => {
  const [showEditDialog, setShowEditDialog] = useState(false)

  useEffect(() => {
    loadTeamPaymentMethodsStart(team.id)
  }, [loadTeamPaymentMethodsStart, team.id])

  if (loading) {
    return <LoadingState />
  }
  const defaultPaymentMethod = getDefaultPaymentOrUndefined(paymentMethods)
  const actionButtonText = paymentMethods?.length > 0 ? 'Edit' : 'Add'
  return (
    <>
      <CardTeam>
        <CardActionButton name={actionButtonText} onClick={() => setShowEditDialog(true)} />
        <CardTeamTitle>Default Payment Method</CardTeamTitle>
        <CardTeamContent>
          {defaultPaymentMethod && (
            <RadioPaymentMethod
              key={defaultPaymentMethod.providerId}
              paymentMethod={defaultPaymentMethod}
              value={defaultPaymentMethod.providerId}
              readOnly
              condensed
              flat
            />
          )}
        </CardTeamContent>
      </CardTeam>
      <DialogPaymentSelect
        open={showEditDialog}
        team={team}
        paymentMethods={paymentMethods}
        defaultPaymentMethod={defaultPaymentMethod}
        onClose={() => setShowEditDialog(false)}
      />
    </>
  )
}

const LoadingState = () => (
  <CardTeam>
    <CardTeamTitle>Payment Methods</CardTeamTitle>
    <Skeleton height={80} />
  </CardTeam>
)

const mapStateToProps = state => {
  const { paymentMethods } = state.team
  return {
    paymentMethods,
  }
}

const mapDispatchToProps = {
  loadTeamPaymentMethodsStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(CardPaymentDetails)
