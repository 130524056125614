import React from 'react'
import { CircularProgress, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { themeConWeb } from '@app/util/modernThemeConweb'

const useStyles = makeStyles({
  root: {
    display: ({ hasIcon }) => (hasIcon ? 'flex' : 'inline'),
    flexDirection: 'row',
    alignItems: 'center',
    color: ({ disabled, color }) => (disabled ? themeConWeb.color.greyShade3 : color),
    fontSize: '14px',
    fontWeight: ({ fontWeight }) => fontWeight,
    pointerEvents: ({ disabled }) => disabled && 'none',
    '&:hover': {
      textDecoration: ({ underline }) => (underline ? 'underline' : 'none'),
    },
  },
  icon: {
    color: ({ disabled, iconColor }) => (disabled ? themeConWeb.color.greyShade3 : iconColor),
    marginRight: '8px',
  },
  circularProgress: {
    color: props => props.color,
    marginLeft: '4px',
  },
})

/**
 * A button component that looks like a Link
 */
const LinkButton = ({
  children,
  color = themeConWeb.color.black,
  fontWeight = 'bold',
  underline = true,
  submitting,
  Icon,
  iconColor,
  disabled,
  type = 'button',
  ...rest
}) => {
  const hasIcon = Boolean(Icon)
  const classes = useStyles({ color, fontWeight, underline, hasIcon, disabled, iconColor })
  return (
    <Link
      className={classes.root}
      component="button"
      type={type}
      disabled={disabled || submitting}
      {...rest}
    >
      {hasIcon && <span className={classes.icon}>{Icon}</span>}
      {children}
      {submitting && <CircularProgress size={12} className={classes.circularProgress} />}
    </Link>
  )
}

export default LinkButton
