import { themeConWeb } from '@app/util/modernThemeConweb'
import { InputError } from '@foodsby/nutrient'
import { Avatar } from '@material-ui/core'
import cn from 'classnames'
import React, { Fragment, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

import { getInitialsOrEmoji } from '../../util/formatUtils'
import { validProfileImage } from '../../util/validators'

export const ProfileAvatar = ({ className, name, size, src }) => {
  const avatarClassName = cn('', {
    'MuiAvatar-circular--xlarge': size === 'xlarge',
    'MuiAvatar-circular--xsmall': size === 'xsmall',
  })
  return (
    <AvatarComponent className={className}>
      <Avatar className={avatarClassName} name={name} src={src} variant="circular">
        {name && getInitialsOrEmoji(name)}
      </Avatar>
    </AvatarComponent>
  )
}

export const ProfileImage = ({
  avatarId,
  isWebpSupported,
  name,
  updateProfilePictureLoading,
  updateProfilePictureStart,
}) => {
  const [validationError, setValidationError] = useState(undefined)

  const onDrop = useCallback(
    acceptedFiles => {
      const img = acceptedFiles[0]
      if (img) {
        const errors = validProfileImage(img)
        if (errors) {
          setValidationError(errors)
        } else if (img) {
          setValidationError(undefined)
          updateProfilePictureStart(img)
        }
      }
    },
    [updateProfilePictureStart],
  )

  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop })

  const imgSrc =
    avatarId && isWebpSupported !== undefined
      ? `${process.env.REACT_APP_IMAGE_LAMBDA}/profiles/${avatarId}.${
          isWebpSupported ? 'webp' : 'jpg'
        }?height=150&width=150`
      : ''

  return (
    <Fragment>
      <div className="edit_profile_img_action" {...getRootProps()}>
        <input {...getInputProps()} data-cy="dropzone" disabled={updateProfilePictureLoading} />
        <ProfileAvatar
          className={cn('profile-image', {
            'drag-active': isDragActive,
            loading: updateProfilePictureLoading,
          })}
          name={name}
          size="xlarge"
          src={imgSrc}
        />
      </div>
      {validationError && <InputError>{validationError}</InputError>}
    </Fragment>
  )
}

const AvatarComponent = styled.div`
  & .MuiAvatar-circular {
    &--xsmall {
      height: calc(${themeConWeb.baseUnit} * 8);
      width: calc(${themeConWeb.baseUnit} * 8);
      font-size: ${themeConWeb.fontSizes.basePlus1};
    }
    &--xlarge {
      height: calc(${themeConWeb.baseUnit} * 30);
      width: calc(${themeConWeb.baseUnit} * 30);
      font-size: ${themeConWeb.fontSizes.basePlus6};
    }
  }
`
