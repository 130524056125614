import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import offices from './offices'
import browser from './browser'
import cart from './cart'
import checkHealth from './checkHealth'
import checkout from './checkout'
import paymentMethods from './paymentMethods'
import comingSoon from './comingSoon'
import creditHistory from './creditHistory'
import credits from './credits'
import delivery from './delivery'
import cateringStores from './cateringStores'
import favorite from './favorite'
import forgotPassword from './forgotPassword'
import { contactUsReducerPlugin } from './formPlugins'
import friends from './friends'
import join from './join'
import location from './location'
import locationSearch from './locationSearch'
import notification from './notification'
import menu from './menu'
import orderDetail from './orderDetail'
import deliveryDropoffRewards from './deliveryDropoffRewards'
import locationManager from './locationManager'
import orderHistory from './orderHistory'
import pastorder from './pastorder'
import personalize from './personalize'
import profile from './profile'
import slackbot from './slackbot'
import subscriptions from './subscriptions'
import ticket from './ticket'
import token from './token'
import user from './user'
import onboarding from './onboarding'
import userTeam from './userTeam'
import team from './team'
import account from './account'
import teamMembers from './teamMembers'
import teamOnboarding from './teamOnboarding'
import teamGiftCards from './teamGiftCards'
import teamPrograms from './teamPrograms'
import teamPerkDetails from './teamPerkDetails'
import teamAddPerkMembers from './teamAddPerkMembers'
import teamSubscription from './teamSubscription'
import teamBilling from './teamBilling'
import snackbar from './snackbar'
import catering from './catering'
import reserve from './reserve'
import reserveStores from './reserveStores'

const reducers = history =>
  combineReducers({
    offices,
    browser,
    cart,
    checkHealth,
    checkout,
    paymentMethods,
    comingSoon,
    creditHistory,
    credits,
    delivery,
    cateringStores,
    reserveStores,
    favorite,
    forgotPassword,
    form: formReducer.plugin({
      contactUs: contactUsReducerPlugin,
    }),
    friends,
    join,
    location,
    locationSearch,
    notification,
    menu,
    orderDetail,
    locationManager,
    deliveryDropoffRewards,
    orderHistory,
    pastorder,
    personalize,
    profile,
    router: connectRouter(history),
    slackbot,
    subscriptions,
    ticket,
    token,
    user,
    onboarding,
    userTeam,
    team,
    account,
    teamMembers,
    teamOnboarding,
    teamGiftCards,
    teamPrograms,
    teamPerkDetails,
    teamAddPerkMembers,
    teamSubscription,
    teamBilling,
    snackbar,
    catering,
    reserve,
  })

export default reducers
