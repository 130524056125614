import { refreshToken as refreshTokenApi } from '@foodsby/webapp-jwt'
import { push } from 'connected-react-router'
import { call, put, select, take } from 'redux-saga/effects'

import { FOODSBY_BASE_URL } from '../../api/api'
import { logoutRoute } from '../../routes/routes'
import { logException } from '../../util/errorUtils'
import {
  getDecodedAccessToken,
  isAccessTokenExpired,
  storeUserInfoCookie,
} from '../../util/webStorageUtils'
import { selectCurrentLocationWithAddress, selectCurrentUser } from './selectors'
import { currentUserSuccess } from './user'

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET

// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const REFRESH = 'foodsby/token/REFRESH'
export const REFRESH_SUCCESS = 'foodsby/token/REFRESH_SUCCESS'
export const REFRESH_FAILURE = 'foodsby/token/REFRESH_FAILURE'

let isRefreshing = false
export const refreshStart = args => {
  isRefreshing = true

  return {
    payload: { args },
    refreshing: isRefreshing,
    type: REFRESH,
  }
}

export const refreshSuccess = (response, args) => {
  isRefreshing = false
  return {
    payload: { args, response },
    refreshing: isRefreshing,
    type: REFRESH_SUCCESS,
  }
}

export const refreshFailure = error => {
  isRefreshing = false
  return {
    error,
    refreshing: isRefreshing,
    type: REFRESH_FAILURE,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [REFRESH]: (state, action) => {
    const { args } = action.payload
    return {
      ...state,
      args,
      refreshing: true,
    }
  },
  [REFRESH_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.error,
      refreshing: undefined,
    }
  },
  [REFRESH_SUCCESS]: state => {
    return {
      ...state,
      error: undefined,
      refreshing: undefined,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  refreshing: undefined,
}

export default function token(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

/**
 *  Checks if the access token needs to be refreshed by comparing the expiration
 *  date with the current date.
 *  @return  {Bool}
 */
export function needRefresh() {
  return isAccessTokenExpired()
}

export function* refreshTokenSaga() {
  // If the application is already waiting for a new set of tokens, wait for the
  // completion of that request instead of creating a new one.
  if (isRefreshing) {
    const { error } = yield take([REFRESH_SUCCESS, REFRESH_FAILURE])

    if (error) {
      yield put(push(logoutRoute.path))
    }

    return error
  }

  // dispatch an action indicating that the application is 'refreshing' the tokens
  yield put(refreshStart())

  try {
    // refresh token and store:
    yield call(refreshToken)

    const token = yield call(getDecodedAccessToken)

    if (token) {
      yield put(
        currentUserSuccess({
          deliveryLocationId: token.location_id,
          email: token.user_name,
          userId: token.user_id,
          userName: token.user_name,
        }),
      )

      // dispatch success message so the 'refreshing' flag is reset
      yield put(refreshSuccess())
      return null
    } else {
      yield put(refreshFailure(new Error('Token was undefined')))
    }
  } catch (err) {
    yield put(refreshFailure(err))
    return err
  } finally {
    // Update user info cookie
    try {
      const location = yield select(selectCurrentLocationWithAddress)
      const currentUser = yield select(selectCurrentUser)
      if (currentUser && location) {
        storeUserInfoCookie({
          userBuildingCity: location.city,
          userBuildingEPP: location.isEpp,
          userBuildingId: location.deliveryLocationId,
          userBuildingName: location.deliveryLocationName,
          userBuildingState: location.state,
          userBuildingStreet: location.street,
          userBuildingZip: location.zip,
          userId: currentUser.userId,
        })
      }
    } catch (ex) {
      logException(ex)
    }
  }
}

export const refreshToken = () =>
  refreshTokenApi(
    {
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET,
    },
    {
      baseURL: FOODSBY_BASE_URL,
    },
  )
