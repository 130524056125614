import { P } from '@foodsby/nutrient'
import { themeConWeb } from '@app/util/modernThemeConweb'
import { Button } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'

import { contactRoute } from '../../routes/routes'

const FeedbackLink = () => {
  return (
    <div>
      <P
        color={themeConWeb.color.brandSecondaryShade2}
        fontSize={themeConWeb.fontSizes.basePlus1}
        mb={3}
      >
        Didn’t find what you’re looking for? Let us know
      </P>
      <Button component={Link} to={contactRoute.path} color="secondary" variant="outlined">
        Give Feedback
      </Button>
    </div>
  )
}
export default FeedbackLink
