import { DialogContent as MuiDialogContent } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

const DialogContent = withStyles({
  root: {
    height: ({ height = '340px' }) => height,
  },
})(MuiDialogContent)

export default DialogContent
