import { isInteger } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  getReferralsEnabled,
  referralGetDetails,
  referralGetWithEnabledDetails,
  referralRedeem,
} from '../redux/modules/friends'
import { normalizeErrorResponse } from '../util/errorUtils'
import { pushAnalyticsEvent } from '../util/gtmUtils'
/**
 * Hook to redeem a dual use referral.
 * @param {*} code the referral code
 */
export const useReferralRedemption = code => {
  const [referralCode, setReferralCode] = useState(code)
  const [redemptionResult, setRedemptionResult] = useState(null)
  const [redemptionError, setError] = useState(null)
  const [redemptionLoading, setLoading] = useState(null)
  const user = useSelector(state => state.user)

  useEffect(() => {
    setReferralCode(code)
  }, [code])

  useEffect(() => {
    let isMounted = true

    if (referralCode && typeof referralCode === 'string' && user?.isAuthenticated) {
      setLoading(true)
      referralRedeem(referralCode)
        .then(r => {
          if (r?.type === 'NEW_USER') {
            pushAnalyticsEvent('Referral', 'Redeemed')
          } else if (r?.type === 'DAILY') {
            pushAnalyticsEvent('Referral-Daily', 'Redeemed')
          }
          if (isMounted) {
            setRedemptionResult(r)
          }
        })
        .catch(err => {
          pushAnalyticsEvent('Referral', 'Referral Error')
          if (isMounted) {
            setError(normalizeErrorResponse(err))
          }
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false)
          }
        })
    }

    return () => {
      isMounted = false
    }
  }, [referralCode, user])

  return {
    redemptionError,
    redemptionLoading,
    redemptionResult,
    setReferralCode,
  }
}

/**
 * Get details about a referral code
 * @param {*} code the referral code
 */
export const useReferralDetails = code => {
  const [referralCode, setReferralCode] = useState(code)
  const [referralDetails, setReferralDetails] = useState(null)
  const [referralError, setError] = useState(null)
  const [referralLoading, setLoading] = useState(null)

  const [locationId, setLocationId] = useState()
  const [enabledDetails, setEnabledDetails] = useState()

  useEffect(() => {
    setReferralCode(code)
  }, [code])

  useEffect(() => {
    let isMounted = true
    if (referralCode && typeof referralCode === 'string') {
      setLoading(true)
      referralGetDetails(referralCode)
        .then(r => {
          if (isMounted) {
            setError(null)
            setLocationId(r?.locationId)
            setReferralDetails(r)
          }
        })
        .catch(err => {
          if (isMounted) {
            setError(normalizeErrorResponse(err))
          }
        })
        .finally(() => setLoading(false))
    }
    return () => {
      isMounted = false
    }
  }, [locationId, referralCode, setLocationId])

  useEffect(() => {
    let isMounted = true
    if (isInteger(locationId)) {
      setLoading(true)
      getReferralsEnabled(locationId)
        .then(r => isMounted && setEnabledDetails(r))
        .catch(e => setError(normalizeErrorResponse(e)))
        .finally(() => setLoading(false))
    }

    return () => {
      isMounted = false
    }
  }, [locationId])

  return { enabledDetails, referralDetails, referralError, referralLoading, setReferralCode }
}

/**
 * Get the current users referral code, alongside enabled details.
 */
export const useMyReferralWithEnabledDetails = () => {
  const [referralAndEnabledDetails, setReferralAndEnabledDetails] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  useEffect(() => {
    let isMounted = true
    setLoading(true)
    referralGetWithEnabledDetails()
      .then(result => {
        if (isMounted) {
          setReferralAndEnabledDetails(result)
        }
      })
      .catch(error => {
        if (isMounted) {
          setError(normalizeErrorResponse(error))
        }
      })
      .finally(() => {
        if (isMounted) {
          setLoading(false)
        }
      })

    return () => {
      isMounted = false
    }
  }, [])

  return {
    error,
    loading,
    referralAndEnabledDetails,
  }
}
