import React from 'react'
import { Dialog, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    alignContent: 'center',
    padding: '100px',
  },
  dialogPaper: {
    position: 'absolute',
    top: '15%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    padding: theme.spacing(4),
    maxWidth: '100%',
    borderRadius: '0px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  button: {
    flexGrow: 1,
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1.5),
    maxWidth: '230px',
    width: '100%',
    borderRadius: '1px',
  },
  buttonClose: {
    backgroundColor: 'gray',
    color: 'white',
  },
}))

export const ModalUnsubscribeSms = ({ showUnsubscribeModal, setSmsToggleStatus, closeModal }) => {
  const classes = useStyles()

  return (
    <Dialog
      open={showUnsubscribeModal}
      onClose={closeModal}
      PaperProps={{
        className: classes.dialogPaper,
      }}
    >
      <div>
        <Typography className={classes.title}>
          Are you sure you want to turn off SMS updates?
        </Typography>

        <div className={classes.buttonContainer}>
          <Button
            size="large"
            onClick={closeModal}
            className={`${classes.button} ${classes.buttonClose}`}
          >
            Close
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={setSmsToggleStatus}
            className={classes.button}
          >
            Turn off SMS
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
export default ModalUnsubscribeSms
