import { Box, Grid, Typography } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Paper from '@material-ui/core/Paper'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Favorite from '@material-ui/icons/Favorite'
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import React from 'react'

export const DocsCheckbox = () => {
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  })

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked })
  }
  return (
    <>
      {/* Checkboxes */}
      <Grid container justifyContent="center" spacing={2}>
        <Grid xs={12}>
          <Typography component="h2" gutterBottom variant="h2">
            Checkboxes
          </Typography>
        </Grid>
        <Grid xs={10}>
          <Paper>
            <Box padding={4}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedA}
                      onChange={handleChange('checkedA')}
                      value="checkedA"
                    />
                  }
                  label="Secondary"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedB}
                      color="primary"
                      onChange={handleChange('checkedB')}
                      value="checkedB"
                    />
                  }
                  label="Primary"
                />
                <FormControlLabel control={<Checkbox value="checkedC" />} label="Uncontrolled" />
                <FormControlLabel
                  control={<Checkbox value="checkedD" />}
                  disabled
                  label="Disabled"
                />
                <FormControlLabel
                  control={<Checkbox checked value="checkedE" />}
                  disabled
                  label="Disabled"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedF}
                      indeterminate
                      onChange={handleChange('checkedF')}
                      value="checkedF"
                    />
                  }
                  label="Indeterminate"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={<Favorite />}
                      icon={<FavoriteBorder />}
                      value="checkedH"
                    />
                  }
                  label="Custom icon"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      value="checkedI"
                    />
                  }
                  label="Custom size"
                />
              </FormGroup>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default DocsCheckbox
