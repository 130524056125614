import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'

import { api, FOODSBY_BASE_URL } from '../../src/api/api'
import { normalizeErrorResponse } from '../../src/util/errorUtils'
import { SimpleSpinner } from '../components/common/SimpleSpinner'
import { notificationStart } from '../redux/modules/notification'
import { homeRoute, loginRoute } from '../routes/routes'

export const PageGiftRedemption = ({
  isAuthenticated,
  isCurrentUserLoading,
  match,
  notificationStart,
}) => {
  const [redemptionResult, setRedemptionResult] = useState(null)
  const [loading, setLoading] = useState(false)

  const redeemGiftCode = async giftCardCode => {
    setLoading(true)
    try {
      await api.put(`${FOODSBY_BASE_URL}/api/v1/gift-cards`, {
        giftCardCode: giftCardCode,
      })
      setRedemptionResult({
        message: 'Your Gift is now available at checkout!',
        succeeded: true,
      })
    } catch (e) {
      setRedemptionResult({
        message: normalizeErrorResponse(e),
        succeeded: false,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    const giftCode = match?.params.giftCode
    if (isAuthenticated && !loading) {
      redeemGiftCode(giftCode)
    }
  }, [match, isAuthenticated, loading])

  useEffect(() => {
    redemptionResult !== null &&
      notificationStart(redemptionResult.message, !redemptionResult.succeeded)
  }, [notificationStart, redemptionResult])

  if (!isAuthenticated && !isCurrentUserLoading) {
    return (
      <Redirect
        to={{
          pathname: loginRoute.path,
          refer: encodeURI(`/gift/${match?.params.giftCode}`),
        }}
      />
    )
  }

  return redemptionResult?.message ? <Redirect to={homeRoute.path} /> : <SimpleSpinner />
}

const mapStateToProps = state => {
  const { isAuthenticated, isCurrentUserLoading } = state.user
  return { isAuthenticated, isCurrentUserLoading }
}

const mapDispatchToProps = {
  notificationStart,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageGiftRedemption))
