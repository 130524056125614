import React from 'react'
import { Box, Divider } from '@material-ui/core'
import { themeConWeb } from '@app/util/modernThemeConweb'
import { SpinnerCard } from '../common/Layout'
import { LocationLogo } from '../common/Location'
import LinkButton from '../common/LinkButton'
import { OrderConfirmationDetails, OrderSummary } from '../common/OrderSummary'
import { closeAutoFavorite } from '../../redux/modules/checkout'
import { AutoFavoriteModal } from './AutoFavorite'
import { DateAndRestaurant } from './DateAndRestaurant'
import { OrderStatusAndTimeText } from './OrderStatusAndTimeText'
import { PickupInstructions } from './PickupInstructions'

export const OrderSummaryCard = ({
  order,
  loading,
  isLoadingFavorites,
  submitting,
  showOrderSummary,
  toggleShowOrderSummary,
  deliveryDropoff,
  location,
  autoFavorites,
}) => {
  if (!order) {
    return <></>
  }
  return (
    <SpinnerCard spinnerActive={!order || loading || isLoadingFavorites || submitting}>
      <Box display={'flex'} flexDirection={'row'} marginBottom={'2em'}>
        <Box marginRight={'2em'} style={{ width: 75, height: 75 }}>
          <LocationLogo
            logoAlt={order.storeName + ' Logo'}
            logoLink={order.logoUrl}
            logoOptions="?height=100&width=100&fit=contain&background=white"
          />
        </Box>
        <DateAndRestaurant
          dateTime={order.originalDeliveryDateTime}
          restaurant={order.storeName}
          orderId={order.orderId}
        />
        <div style={{ flexGrow: 1 }} />
        <OrderStatusAndTimeText orderStatus={order.statusText} order={order} />
      </Box>
      <Box marginBottom={'2em'}>
        <Divider />
      </Box>
      <PickupInstructions instructions={order.pickupInstructions} />
      <Box marginTop={'1em'} display={'flex'}>
        <div style={{ flexGrow: 1 }} />
        <LinkButton
          onClick={toggleShowOrderSummary}
          color={themeConWeb.color.brandPrimary}
          style={{
            fontSize: themeConWeb.fontSizes.basePlus1,
            fontWeight: 700,
            textDecoration: 'underline',
            textTransform: 'none',
          }}
        >
          {showOrderSummary ? 'Hide' : 'Show'} Order Details
        </LinkButton>
      </Box>
      {showOrderSummary && (
        <OrderSummary>
          {deliveryDropoff && (
            <OrderConfirmationDetails
              order={order}
              location={location}
              deliveryType={deliveryDropoff.type}
            />
          )}
        </OrderSummary>
      )}
      {autoFavorites.length >= 1 && (
        <AutoFavoriteModal closeAutoFavorite={closeAutoFavorite} items={autoFavorites} />
      )}
    </SpinnerCard>
  )
}
