import React from 'react'
import { Link, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Star } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    border: ({ viewed }) =>
      `1px solid ${viewed ? theme.palette.action.disabledBackground : theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  listItemAvatar: {
    marginRight: theme.spacing(3),
  },
  title: {
    ...theme.typography.h6,
    lineHeight: theme.typography.body1.lineHeight,
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  iconPinned: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
  },
}))

const ListItemContentCard = ({ title, description, imageUrl, link, viewed, pinned }) => {
  const classes = useStyles({ viewed })
  const hasImage = Boolean(imageUrl)
  return (
    <ListItem className={classes.root}>
      {pinned && <Star className={classes.iconPinned} color="primary" />}
      {hasImage && (
        <ListItemAvatar className={classes.listItemAvatar}>
          <img src={imageUrl} alt="" height="100px" width="100px" />
        </ListItemAvatar>
      )}
      <ListItemText disableTypography>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
        {link.url && link.text && <Link href={link.url}>{link.text}</Link>}
      </ListItemText>
    </ListItem>
  )
}

export default ListItemContentCard
