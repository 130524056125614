import { InputError } from '@foodsby/nutrient'
import React, { Component } from 'react'
import scriptLoader from 'react-async-script-loader'
import { connect } from 'react-redux'
import Action from '../common/Action'

import { search, update } from '../../redux/modules/locationSearch'
import { revertTemporaryLocation, updateAndSaveUserLocationStart } from '../../redux/modules/user'
import { Location, LocationBuilding, LocationContainer } from '../common/Location'
import ModalPopup from '../common/ModalPopup'
import LocationSearch, { MAPS_SCRIPT_URL } from './LocationSearchBox'

const LocationSearchBox = scriptLoader(MAPS_SCRIPT_URL)(LocationSearch)

const OriginalLocation = ({ currentUser, previousLocation, revertTemporaryLocation }) => (
  <div className="col-xs-6" style={{ paddingLeft: '8%' }}>
    <Location>
      <LocationContainer>
        <LocationBuilding
          address={previousLocation.address}
          name={previousLocation.deliveryLocationName}
          showLink={false}
        />
      </LocationContainer>
    </Location>
    <Action onClick={() => revertTemporaryLocation(currentUser.originalLocationId)}>
      Back to my original location
    </Action>
  </div>
)

const TemporaryLocation = ({ currentUser, location, onCancel, updateAndSaveUserLocationStart }) => (
  <div className="col-xs-6" style={{ paddingLeft: '8%' }}>
    <Location>
      <LocationContainer>
        <LocationBuilding
          address={location.address}
          name={location.deliveryLocationName}
          showLink={false}
        />
      </LocationContainer>
    </Location>
    <Action
      onClick={() => {
        updateAndSaveUserLocationStart(currentUser.deliveryLocationId)
        onCancel()
      }}
      style={{ paddingLeft: '20px' }}
    >
      Make this my location
    </Action>
  </div>
)

export class LocationPopup extends Component {
  constructor(props) {
    super(props)
    this.state = { showModal: false }
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }

  componentDidMount() {
    if (this.props.currentUser && !this.props.previousLocation) {
      this.props.loadPreviousLocationStart(this.props.currentUser.originalLocationId)
    }
  }

  showModal(evt) {
    evt.preventDefault()
    this.setState({ showModal: true })
  }

  hideModal() {
    this.setState({ showModal: false })
  }

  render() {
    const {
      address,
      currentUser,
      error,
      isLocationLoading,
      location,
      previousLocation,
      revertTemporaryLocation,
      search,
      showError,
      submitting,
      update,
      updateAndSaveUserLocationStart,
    } = this.props
    return (
      <span>
        <Action onClick={this.showModal} style={this.props.styles}>
          {this.props.linkIconClassName ? <i className={this.props.linkIconClassName} /> : null}{' '}
          {this.props.linkText}
        </Action>
        {this.state.showModal && (
          <ModalPopup onCancel={this.hideModal}>
            <div>
              <LocationSearchBox
                address={address}
                label={'Search for an address'}
                search={search}
                showError={showError}
                submitting={submitting}
                updateAddress={update}
              />{' '}
              {!isLocationLoading && previousLocation && location && (
                <div className="row" style={{ marginTop: '8px' }}>
                  <OriginalLocation
                    currentUser={currentUser}
                    isLocationLoading={isLocationLoading}
                    previousLocation={previousLocation}
                    revertTemporaryLocation={revertTemporaryLocation}
                  />
                  <TemporaryLocation
                    currentUser={currentUser}
                    isLocationLoading={isLocationLoading}
                    location={location}
                    onCancel={this.hideModal}
                    updateAndSaveUserLocationStart={updateAndSaveUserLocationStart}
                  />
                </div>
              )}
              {error && (
                <div className="center-xs pt-1">
                  <InputError visible={error && error !== null}>
                    {error.message
                      ? error.message
                      : 'Address not found. Please enter a valid address.'}
                  </InputError>
                </div>
              )}
            </div>
          </ModalPopup>
        )}
      </span>
    )
  }
}
function mapStateToProps(state) {
  const { address = '', error, submitting } = state.locationSearch
  const { currentUser } = state.user
  const { isLocationLoading, location, previousLocation } = state.location

  return {
    address,
    currentUser,
    error,
    isLocationLoading,
    location,
    previousLocation,
    submitting,
  }
}

const mapDispatchToProps = {
  revertTemporaryLocation,
  search,
  update,
  updateAndSaveUserLocationStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationPopup)
