import { call, take } from 'redux-saga/effects'

import { deliveringRestaurants } from '../../api/api'
import { apiSaga } from './sagas'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const LOAD_DELIVERING_RESTAURANTS = 'foodsby/home/LOAD_DELIVERING_RESTAURANTS'
export const LOAD_DELIVERING_RESTAURANTS_SUCCESS =
  'foodsby/home/LOAD_DELIVERING_RESTAURANTS_SUCCESS'
export const LOAD_DELIVERING_RESTAURANTS_FAILURE =
  'foodsby/home/LOAD_DELIVERING_RESTAURANTS_FAILURE'

export const loadDeliveringRestaurants = locationId => {
  return {
    payload: { locationId },
    type: LOAD_DELIVERING_RESTAURANTS,
  }
}

export const loadDeliveringRestaurantsSuccess = res => {
  return {
    payload: res,
    type: LOAD_DELIVERING_RESTAURANTS_SUCCESS,
  }
}

export const loadDeliveringRestaurantsFailure = error => {
  return {
    error,
    type: LOAD_DELIVERING_RESTAURANTS_FAILURE,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOAD_DELIVERING_RESTAURANTS]: state => {
    return {
      ...state,
      loadingDeliveringRestaurants: true,
    }
  },
  [LOAD_DELIVERING_RESTAURANTS_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.error,
      loadDeliveriesForLocation: undefined,
      loadingDeliveringRestaurants: false,
    }
  },
  [LOAD_DELIVERING_RESTAURANTS_SUCCESS]: (state, action) => {
    return {
      ...state,
      loadDeliveriesForLocation: action.payload.restaurants,
      loadingDeliveringRestaurants: false,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loadDeliveriesForLocation: {},
  loadingDeliveringRestaurants: false,
}

export default function comingSoon(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------
export function* watchDeliveringRestaurants() {
  while (true) {
    const {
      payload: { locationId },
    } = yield take(LOAD_DELIVERING_RESTAURANTS)
    yield call(
      apiSaga,
      deliveringRestaurants,
      [locationId],
      loadDeliveringRestaurantsSuccess,
      loadDeliveringRestaurantsFailure,
    )
  }
}
