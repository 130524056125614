import React from 'react'
import { Typography, Box, Tabs, Tab } from '@material-ui/core'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Skeleton } from '@material-ui/lab'
import { formatUrl } from '../../../util/formatUtils'

import {
  teamAccountRoute,
  teamMembersRoute,
  teamPaymentsRoute,
  teamPerksRoute,
} from '../../../routes/routes'
import BackButton from './BackButton'

const LinkTab = props => {
  return <Tab component={Link} {...props} />
}

const Header = ({ account, name, Action }) => {
  const {
    location: { pathname },
  } = useHistory()

  const formatPath = path => formatUrl(path, { accountId: account?.id || 0 })
  return (
    <>
      <Box mb={3}>
        <>
          <BackButton />
          {name ? <Typography variant="h5">{name}</Typography> : <SkeletonLoading />}
        </>
      </Box>

      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
        <Tabs
          value={pathname}
          indicatorColor="primary"
          textColor="primary"
          aria-label="team administration pages"
        >
          <LinkTab
            label="account"
            value={formatPath(teamAccountRoute.path)}
            to={formatPath(teamAccountRoute.path)}
          />
          <LinkTab
            label="members"
            value={formatPath(teamMembersRoute.path)}
            to={formatPath(teamMembersRoute.path)}
          />
          <LinkTab
            label="perks"
            value={formatPath(teamPerksRoute.path)}
            to={formatPath(teamPerksRoute.path)}
          />
          <LinkTab
            label="payment"
            value={formatPath(teamPaymentsRoute.path)}
            to={formatPath(teamPaymentsRoute.path)}
          />
        </Tabs>
        {Action}
      </Box>
    </>
  )
}

const SkeletonLoading = () => <Skeleton variant="rect" width="320px" height="42px" />

export default Header
