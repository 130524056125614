import { TextField, Grid } from '@material-ui/core'
import { get } from 'lodash'
import React, { useCallback } from 'react'

import { TextMaskPhone } from '../common/InputMasks'
import AutoEnrollment from './AutoEnrollment'

const UserInfo = ({
  email,
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
  showAutoEnrollment,
  handleToggleSeeMoreSms,
  toggleSeeMoreSms,
}) => {
  const getError = useCallback(name => get(touched, name) && get(errors, name), [errors, touched])

  return (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <TextField
          size="small"
          autoComplete="given-name"
          variant="outlined"
          fullWidth
          id="User.firstName"
          label="First Name"
          maxLength="35"
          name="User.firstName"
          onBlur={handleBlur}
          onChange={handleChange}
          error={!!getError('User.firstName')}
          helperText={getError('User.firstName')}
          color="secondary"
          value={values.User.firstName}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          size="small"
          autoComplete="family-name"
          id="User.lastName"
          variant="outlined"
          fullWidth
          label="Last Name"
          maxLength="35"
          name="User.lastName"
          onBlur={handleBlur}
          onChange={handleChange}
          error={!!getError('User.lastName')}
          helperText={getError('User.lastName')}
          color="secondary"
          value={values.User.lastName}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          size="small"
          autoComplete="phone"
          value={values.User.phone}
          onChange={handleChange}
          name="User.phone"
          id="User.phone"
          onBlur={handleBlur}
          variant="outlined"
          fullWidth
          label="Phone Number"
          error={!!getError('User.phone')}
          helperText={getError('User.phone')}
          color="secondary"
          InputProps={{
            inputComponent: TextMaskPhone,
          }}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          size="small"
          disabled
          label="Email"
          value={email}
          fullWidth
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid>
        {showAutoEnrollment && (
          <AutoEnrollment
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            values={values}
            handleToggleSeeMoreSms={handleToggleSeeMoreSms}
            toggleSeeMoreSms={toggleSeeMoreSms}
          />
        )}
      </Grid>
    </Grid>
  )
}
export default UserInfo
