import { call, take } from 'redux-saga/effects'

import { loadPaymentMethods } from '../../api/api'

import { apiSaga } from './sagas'

// ------------------------------------
// CONSTs
// ------------------------------------
export const CREDIT_CARDS_ERROR = 'There was an error loading your saved credit cards.'

// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const LOAD_CREDIT_CARDS = 'foodsby/paymentMethods/LOAD_CREDIT_CARDS'
export const LOAD_CREDIT_CARDS_SUCCESS = 'foodsby/paymentMethods/LOAD_CREDIT_CARDS_SUCCESS'
export const LOAD_CREDIT_CARDS_FAILURE = 'foodsby/checkout/LOAD_CREDIT_CARDS_FAILURE'

export const loadPaymentMethodsStart = () => {
  return {
    type: LOAD_CREDIT_CARDS,
  }
}

export const loadPaymentMethodsSuccess = paymentMethods => {
  return {
    payload: { paymentMethods },
    type: LOAD_CREDIT_CARDS_SUCCESS,
  }
}

export const loadPaymentMethodsFailure = error => {
  return {
    error,
    type: LOAD_CREDIT_CARDS_FAILURE,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [LOAD_CREDIT_CARDS]: state => {
    return {
      ...state,
      isPaymentMethodsLoading: true,
    }
  },
  [LOAD_CREDIT_CARDS_FAILURE]: state => {
    return {
      ...state,
      paymentMethods: [],
      paymentMethodsError: CREDIT_CARDS_ERROR,
      isPaymentMethodsLoading: false,
    }
  },
  [LOAD_CREDIT_CARDS_SUCCESS]: (state, action) => {
    const { paymentMethods } = action.payload
    return {
      ...state,
      paymentMethods,
      isPaymentMethodsLoading: false,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  paymentMethods: [],
}

export default function paymentMethods(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* watchLoadPaymentMethods() {
  while (true) {
    yield take(LOAD_CREDIT_CARDS)
    yield call(
      apiSaga,
      loadPaymentMethods,
      [],
      loadPaymentMethodsSuccess,
      loadPaymentMethodsFailure,
    )
  }
}
