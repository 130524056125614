import { themeConWeb } from '@app/util/modernThemeConweb'
import { Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const PanelHeader = ({ headerRight, title }) => (
  <Header>
    <Title variant="subtitle1">{title}</Title>
    {headerRight}
  </Header>
)

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${themeConWeb.color.greyShade2};
  margin-bottom: calc(${themeConWeb.baseUnit} * 4);
`

const Title = styled(Typography)`
  align-self: center;
  text-transform: uppercase;
  flex-grow: 1;
`

export default PanelHeader
