import React, { useEffect } from 'react'
import { format, parse } from 'date-fns'
import { Skeleton } from '@material-ui/lab'
import { isEmpty, isNil } from 'lodash'

import TableSimple from '../../common/TableSimple'
import { formatCentsToPrettyDollars } from '../perks/giftCardUtils'
import { ROWS_PER_PAGE } from '../../../util/constants'
import DialogGiftTransactionDetails from './DialogGiftTransactionDetails'

const TableGiftTransactions = ({
  teamId,
  giftTransactions: { content, number: page, totalElements },
  giftTransactionDetails,
  loadGiftTransactionsStart,
  loadGiftTransactionDetailsStart,
  resetGiftTransactionDetailsState,
}) => {
  const handlePageChange = (e, page) => {
    loadGiftTransactionsStart(teamId, page, ROWS_PER_PAGE)
  }

  const handleRowClick = rowCell => {
    loadGiftTransactionDetailsStart(teamId, rowCell.id)
  }

  const handleCloseDialog = () => {
    resetGiftTransactionDetailsState()
  }

  const columns = [
    {
      id: 'purchaseDate',
      label: 'Date',
    },
    {
      id: 'purchaserName',
      label: 'Purchaser',
    },
    {
      id: 'numberOfGifts',
      label: 'Quantity',
    },
    {
      id: 'totalGiftAmount',
      label: 'Total',
    },
  ]

  const rowCells = content?.map(giftTransaction => ({
    id: giftTransaction.id,
    cells: [
      {
        labelId: 'purchaseDate',
        Component: format(parse(giftTransaction.purchaseDate), 'M/D/YYYY'),
      },
      {
        labelId: 'purchaserName',
        Component: giftTransaction.purchaserName,
      },
      {
        labelId: 'numberOfGifts',
        Component: giftTransaction.numberOfGifts,
      },
      {
        labelId: 'totalGiftAmount',
        Component: formatCentsToPrettyDollars(giftTransaction.totalGiftAmount),
      },
    ],
  }))

  return (
    <>
      <TableSimple
        columns={columns}
        rowCells={rowCells}
        rowsPerPage={ROWS_PER_PAGE}
        totalElements={totalElements}
        page={page}
        ariaLabel="billing history gift transactions table"
        noDataText="No gift transactions found"
        onChangePage={handlePageChange}
        onRowClick={handleRowClick}
      />
      {!isNil(giftTransactionDetails) && (
        <DialogGiftTransactionDetails
          giftTransactionDetails={giftTransactionDetails}
          onClose={handleCloseDialog}
        />
      )}
    </>
  )
}

const LoadableTableGiftTransactions = props => {
  const { teamId, loading, giftTransactions, loadGiftTransactionsStart } = props

  useEffect(() => {
    if (teamId) {
      loadGiftTransactionsStart(teamId, 0, ROWS_PER_PAGE)
    }
  }, [loadGiftTransactionsStart, teamId])

  if (loading) {
    return <Skeleton variant="rect" height="200px" />
  }

  return !isEmpty(giftTransactions) && <TableGiftTransactions {...props} />
}

export default LoadableTableGiftTransactions
