import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import { selectDefaultTeamPaymentMethod, selectTeam } from '../../../redux/selectors/teamSelectors'
import { loadTeamMembersStart } from '../../../redux/modules/teamMembers'
import {
  loadSubscriptionMembersStart,
  setPage,
  setSearch,
} from '../../../redux/modules/teamPerkDetails'
import { selectPerkMembersFilteredBySearch } from '../../../redux/selectors/teamProgramDetailsSelectors'
import { resetState, setMissingMembersStart } from '../../../redux/modules/teamAddPerkMembers'
import { selectMissingMembers } from '../../../redux/selectors/teamAddProgramMembersSelectors'
import { updateSubscriptionStart } from '../../../redux/modules/teamSubscription'
import DialogPerkDetails from './DialogPerkDetails'
import CardTeamSubscriptionSummary from './CardTeamSubscriptionSummary'
import CardPerkMembers from './CardPerkMembers'
import DialogAddPerkMembers from './DialogAddPerkMembers'
import CardPerkAutoEnrollMembers from './CardPerkAutoEnrollMembers'

const DialogTeamSubscriptionDetails = ({
  defaultPaymentMethod,
  adminTeam,
  teamSubscription,
  perkMembers,
  missingMembers,
  errorSettingMissingMembers,
  isUpdatingSubscription,
  onClose,
  loadTeamMembersStart,
  loadSubscriptionMembersStart,
  setPage,
  setSearch,
  setMissingMembersStart,
  resetState: resetAddMembersState,
  updateSubscriptionStart,
  pricePerHeadInCents,
}) => {
  const handleClickAddMembers = () => {
    setMissingMembersStart()
  }

  const handleCloseAddMembers = () => {
    resetAddMembersState()
  }

  const handleChangeAutoEnrollMembers = ({ autoEnrollNewMembers }) => {
    updateSubscriptionStart({ autoEnrollNewMembers })
  }

  const handleUpdateTeamSubscription = ({ endDate }) => {
    updateSubscriptionStart({ endDate })
  }

  useEffect(() => {
    if (adminTeam) {
      loadTeamMembersStart(adminTeam.id)
      loadSubscriptionMembersStart()
    }
  }, [adminTeam, loadTeamMembersStart, loadSubscriptionMembersStart])

  useEffect(() => {
    return () => {
      resetAddMembersState()
    }
  }, [resetAddMembersState])

  return (
    <>
      <DialogPerkDetails onClose={onClose}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography display="inline" variant="h5" component="h1">
              Foodsby Unlimited
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <CardTeamSubscriptionSummary
              teamSubscription={teamSubscription}
              defaultPaymentMethod={defaultPaymentMethod}
              teamPaymentCollectionMethod={adminTeam.paymentCollectionMethod}
              isUpdatingSubscription={isUpdatingSubscription}
              onUpdate={handleUpdateTeamSubscription}
              onClose={onClose}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid item xs={12}>
              <CardPerkAutoEnrollMembers
                perk={teamSubscription}
                isUpdatingPerk={isUpdatingSubscription}
                onUpdatePerk={handleChangeAutoEnrollMembers}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CardPerkMembers
              members={perkMembers}
              errorSettingMissingMembers={errorSettingMissingMembers}
              emailFieldId="email"
              setPage={setPage}
              setSearch={setSearch}
              onClickAddMembers={handleClickAddMembers}
            />
          </Grid>
        </Grid>
      </DialogPerkDetails>
      {!isEmpty(missingMembers) && (
        <DialogAddPerkMembers
          onClose={handleCloseAddMembers}
          pricePerHeadInCents={pricePerHeadInCents}
        />
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    teamSubscription: state.teamSubscription.teamSubscription,
    defaultPaymentMethod: selectDefaultTeamPaymentMethod(state),
    adminTeam: selectTeam(state),
    perkMembers: selectPerkMembersFilteredBySearch(state),
    missingMembers: selectMissingMembers(state),
    errorSettingMissingMembers: state.teamAddPerkMembers.errorSettingMissingMembers,
    isUpdatingSubscription: state.teamSubscription.isUpdatingSubscription,
  }
}

const mapDispatchToProps = {
  loadTeamMembersStart,
  loadSubscriptionMembersStart,
  setPage,
  setSearch,
  setMissingMembersStart,
  resetState,
  updateSubscriptionStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogTeamSubscriptionDetails)
