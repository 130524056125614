import { MenuItem, Select } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  selectQuantity: {
    width: '100px',
    marginBottom: '16px',
  },
}))

const SelectQuantity = ({ input, label, meta: { error, touched }, options, ...custom }) => {
  const classes = useStyles({ error, touched })
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 260,
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  }

  return (
    <Select
      label={label}
      {...input}
      {...custom}
      className={classes.selectQuantity}
      MenuProps={menuProps}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectQuantity
