import { themeConWeb } from '@app/util/modernThemeConweb'
import { Box, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import currency from 'currency.js'
import React, { useState } from 'react'
import styled from 'styled-components'

/**
 *  This is a one off component and should only be used after vetting with UX.
 *  It is not recommend this variant be used outside for the customization modal
 */
const FormHelperTextCompact = withStyles({
  root: {
    marginLeft: 32,
    marginTop: -8,
  },
})(FormHelperText)

const MenuRadioAnswers = ({
  answers,
  input,
  input: { value: prevValue },

  meta: { error },
  onAnswerChange,
  questionId,
}) => {
  const { onChange } = input
  const [value, setValue] = useState('')

  const handleChange = event => {
    onChange(event)
    setValue(event.target.value)
    onAnswerChange({
      answerId: event.target.value,
      questionId: questionId,
      selected: true,
    })
  }

  return (
    <ul style={{ width: '100%' }}>
      <RadioGroup onChange={handleChange} value={value}>
        <Box display="flex" flexWrap="wrap" paddingRight="16px" mt={4}>
          {answers.map(answer => (
            <MenuInput key={answer.id}>
              <FormControlLabel
                checked={`${answer.id}` === `${input.value}`}
                control={<Radio color="primary" />}
                data-testid={`answer-${answer.id}`}
                label={answer.text}
                name={answer.text}
                value={answer.id}
              />
              {answer.price !== 0 && (
                <FormHelperTextCompact margin="dense">
                  +{' '}
                  {currency(answer.price, {
                    formatWithSymbol: false,
                    precision: 2,
                  }).format()}
                </FormHelperTextCompact>
              )}
            </MenuInput>
          ))}
        </Box>
      </RadioGroup>
    </ul>
  )
}
const MenuInput = styled.li`
  @media ${themeConWeb.small} {
    width: 100%;
  }
  @media ${themeConWeb.medium} {
    width: 50%;
  }

  margin-bottom: calc(${themeConWeb.baseUnit} * 4);
  width: 33%;
`

MenuRadioAnswers.defaultProps = {
  meta: {
    error: false,
    touched: false,
  },
}

export default MenuRadioAnswers
