const parseMenuQuestionErrors = (errors, questions) => {
  const result = []
  Object.keys(errors).forEach(errorKey => {
    Object.keys(questions).forEach(questionKey => {
      if (`q_${questions[questionKey].id}` === errorKey) {
        result.push(`${questions[questionKey].text}${errors[errorKey]}`)
      }
    })
  })
  return result
}

export default parseMenuQuestionErrors
