import 'react-day-picker/lib/style.css'
import React, { useState } from 'react'
import { Box, Typography, Grid } from '@material-ui/core'
import { format, addDays } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { getDayName, getShortDayName } from '../../util/dateUtils'
import { themeConWeb } from '../../util/modernThemeConweb'

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 700,
    fontSize: themeConWeb.fontSizes.basePlus3,
    lineHeight: themeConWeb.fontSizes.basePlus2,
    color: themeConWeb.color.primaryType,
    letterSpacing: '1px',
    textTransform: 'capitalize',
    paddingBottom: '10px',
  },
  selectedButton: {
    borderBottom: `4px solid ${themeConWeb.color.twoShade2}`,
    color: themeConWeb.color.twoShade2,
    '&:not(:last-child)': {
      borderBottom: `4px solid ${themeConWeb.color.twoShade2}`,
    },
    '&:hover': {
      backgroundColor: themeConWeb.color.greyShade1,
    },
  },
  unselectedButton: {
    color: 'black',
    '&:hover': {
      backgroundColor: themeConWeb.color.greyShade1,
    },
  },
  buttonGroup: {
    width: '100%',
    hover: themeConWeb.color.twoShade2,
    '& .MuiButtonGroup-grouped': {
      borderRight: 'none',
      borderRadius: 0,
      flex: 1,
    },
  },
}))

const DayOfWeekPickerWidget = ({ selectedDate, selectDateStart, locationId }) => {
  const classes = useStyles()
  const [newDayOfWeek, setNewDayOfWeek] = useState(selectedDate)

  const handleDayClick = day => {
    setNewDayOfWeek(day)
    return selectDateStart(format(day, 'YYYYMMDD'), locationId)
  }

  const days = Array.from({ length: 7 }, (_, i) => addDays(new Date(), i))

  return (
    <Box style={{ position: 'relative', width: '100%' }}>
      <Box display="flex" flexDirection="column" alignItems="start">
        <Typography className={classes.title}>Delivering {getDayName(newDayOfWeek)}</Typography>
      </Box>
      <Grid width="100%">
        <ButtonGroup variant="text" color="primary" className={classes.buttonGroup}>
          {days.map(day => (
            <Button
              key={day}
              onClick={() => handleDayClick(day)}
              className={
                format(day, 'yyyyMMdd') === format(newDayOfWeek, 'yyyyMMdd')
                  ? classes.selectedButton
                  : classes.unselectedButton
              }
            >
              {window.innerWidth <= 600 ? getShortDayName(day) : getDayName(day)}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
    </Box>
  )
}

export default DayOfWeekPickerWidget
