import React from 'react'
import { Box } from '@material-ui/core'
import SectionHeaderWrapper from './SectionHeaderWrapper'
import DayOfWeekPickerWidget from './DayOfWeekPickerWidget'

export const DailyHeader = ({ selectedDate, selectDateStart, locationId }) => {
  return (
    <SectionHeaderWrapper>
      <Box style={{ paddingBottom: '4px' }}>
        <DayOfWeekPickerWidget
          selectedDate={selectedDate}
          selectDateStart={selectDateStart}
          locationId={locationId}
        />
      </Box>
    </SectionHeaderWrapper>
  )
}

export default DailyHeader
