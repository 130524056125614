import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loadTeamPaymentMethodsStart, updateDefaultPaymentMethodStart } from '../redux/modules/team'
import { isDefaultPaymentMethod, isNewPaymentMethod } from '../util/paymentMethods'
import { waitPlz } from '../util/promiseUtils'
import useAddPaymentMethod from './useAddPaymentMethod'

const useTeamPaymentMethod = ({ teamId, onSuccess = () => {} }) => {
  const [waiting, setWaiting] = useState(false)
  const paymentMethods = useSelector(state => state.team.paymentMethods)
  const errorLoadingPaymentMethods = useSelector(state => state.team.errorLoadingPaymentMethods)
  const errorUpdatingDefaultPaymentMethod = useSelector(
    state => state.team.errorUpdatingDefaultPaymentMethod,
  )
  const isUpdatingDefaultPaymentMethod = useSelector(
    state => state.team.isUpdatingDefaultPaymentMethod,
  )
  const dispatch = useDispatch()

  const { isCreatingPaymentMethod, handleAddPaymentMethod } = useAddPaymentMethod({
    ownerType: 'ENTERPRISE',
    referenceId: teamId,
    onSuccess: () => handleSuccess(),
  })

  const handleSuccess = async () => {
    setWaiting(true)
    await waitPlz(3000)
    dispatch(loadTeamPaymentMethodsStart(teamId))
    setWaiting(false)
    onSuccess()
  }

  const handleSubmitTeamPaymentMethod = selectedPaymentMethodId => {
    if (isNewPaymentMethod(selectedPaymentMethodId)) {
      handleAddPaymentMethod()
    } else if (!isDefaultPaymentMethod(paymentMethods, selectedPaymentMethodId)) {
      dispatch(updateDefaultPaymentMethodStart(teamId, selectedPaymentMethodId, handleSuccess))
    } else {
      onSuccess()
    }
  }

  const submitting = isCreatingPaymentMethod || isUpdatingDefaultPaymentMethod || waiting
  const error = errorLoadingPaymentMethods || errorUpdatingDefaultPaymentMethod
  return {
    submitting,
    error,
    handleSubmitTeamPaymentMethod,
  }
}

export default useTeamPaymentMethod
