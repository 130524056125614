import React from 'react'
import { Card } from '@material-ui/core'
import { connect } from 'react-redux'

import { selectTeamsSupported, selectUserTeam } from '../../../redux/selectors/userTeamSelectors'
import PanelHeader from '../../common/PanelHeader'
import MyTeam from './MyTeam'

const PanelTeams = ({ userTeam, isTeamsSupported }) => {
  if (!isTeamsSupported || !userTeam.team) {
    return null
  }

  return (
    <Card>
      <PanelHeader title="My Team" />
      {userTeam.team && <MyTeam />}
    </Card>
  )
}

const mapStateToProps = state => {
  const userTeam = selectUserTeam(state)
  const isTeamsSupported = selectTeamsSupported(state)

  return {
    userTeam,
    isTeamsSupported,
  }
}

export default connect(mapStateToProps)(PanelTeams)
