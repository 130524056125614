import {
  getTeamApi,
  getTeamPaymentMethodsApi,
  updateTeamDefaultPaymentMethodApi,
  updateTeamSettingsApi,
} from '../../services/team'
import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import { enqueueSnackbar } from './snackbar'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const TEAM_SET = 'foodsby/team/TEAM_SET'
export const TEAM_UPDATE_SETTINGS = 'foodsby/team/UPDATE_SETTINGS'
export const PAYMENT_METHODS_SET = 'foodsby/team/PAYMENT_METHODS_SET'
export const CAN_LOAD_TEAM_SET = 'foodsby/team/CAN_LOAD_TEAM_SET'
export const DEFAULT_PAYMENT_METHOD_UPDATE = 'foodsby/team/DEFAULT_PAYMENT_METHOD_UPDATE'

export const setTeam = createAsyncAction(TEAM_SET)
export const setPaymentMethods = createAsyncAction(PAYMENT_METHODS_SET)
export const updateTeamSettings = createAsyncAction(TEAM_UPDATE_SETTINGS)
export const setCanLoadTeam = createAction(CAN_LOAD_TEAM_SET)
export const updateDefaultPaymentMethod = createAsyncAction(DEFAULT_PAYMENT_METHOD_UPDATE)

// ------------------------------------
// Thunks
// ------------------------------------
export const loadTeamStart = teamId => {
  return dispatch => {
    return dispatch(setTeam(getTeamApi(teamId)))
  }
}

export const loadTeamPaymentMethodsStart = teamId => {
  return dispatch => {
    dispatch(setPaymentMethods(getTeamPaymentMethodsApi(teamId)))
  }
}

export const updateTeamSettingsStart = (accountId, team) => {
  return async dispatch => {
    try {
      await dispatch(updateTeamSettings(updateTeamSettingsApi(accountId, team)))
    } catch (err) {
      dispatch(enqueueSnackbar({ message: err.message }))
    }
  }
}

export const updateDefaultPaymentMethodStart = (teamId, paymentMethodId, onSuccess) => {
  return async dispatch => {
    await dispatch(
      updateDefaultPaymentMethod(updateTeamDefaultPaymentMethodApi(teamId, paymentMethodId)),
    )
    onSuccess && onSuccess()
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PENDING(TEAM_SET)]: state => {
    return {
      ...state,
      isLoadingTeam: true,
      canLoadTeam: true,
    }
  },
  [FULFILLED(TEAM_SET)]: (state, action) => {
    return {
      ...state,
      team: action.payload,
      isLoadingTeam: false,
      teamInitialized: true,
      errorLoadingTeam: undefined,
      canLoadTeam: true,
    }
  },
  [REJECTED(TEAM_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingTeam: false,
      teamInitialized: true,
      errorLoadingTeam: action.error,
      canLoadTeam: true,
    }
  },
  [PENDING(TEAM_UPDATE_SETTINGS)]: state => {
    return {
      ...state,
      isLoadingTeam: true,
      errorUpdatingTeam: false,
    }
  },
  [REJECTED(TEAM_UPDATE_SETTINGS)]: (state, action) => {
    return {
      ...state,
      isLoadingTeam: false,
      errorUpdatingTeam: action.error,
    }
  },
  [FULFILLED(TEAM_UPDATE_SETTINGS)]: (state, action) => {
    return {
      ...state,
      isLoadingTeam: false,
      errorUpdatingTeam: null,
      team: action.payload,
    }
  },
  [PENDING(PAYMENT_METHODS_SET)]: state => {
    return {
      ...state,
      isLoadingPaymentMethods: true,
    }
  },
  [FULFILLED(PAYMENT_METHODS_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingPaymentMethods: false,
      paymentMethods: action.payload,
    }
  },
  [REJECTED(PAYMENT_METHODS_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingPaymentMethods: false,
      errorLoadingPaymentMethods: action.error,
    }
  },
  [CAN_LOAD_TEAM_SET]: (state, action) => {
    return {
      ...state,
      canLoadTeam: action.payload,
    }
  },
  [PENDING(DEFAULT_PAYMENT_METHOD_UPDATE)]: state => {
    return {
      ...state,
      isUpdatingDefaultPaymentMethod: true,
    }
  },
  [FULFILLED(DEFAULT_PAYMENT_METHOD_UPDATE)]: state => {
    return {
      ...state,
      isUpdatingDefaultPaymentMethod: false,
    }
  },
  [REJECTED(DEFAULT_PAYMENT_METHOD_UPDATE)]: (state, action) => {
    return {
      ...state,
      isUpdatingDefaultPaymentMethod: false,
      errorUpdatingDefaultPaymentMethod: action.error,
    }
  },
}

export const initialState = {
  team: undefined,
  canLoadTeam: false,
  teamInitialized: false,
  paymentMethods: [],
  // Loading states
  isLoadingTeam: true,
  isLoadingTeamsEnabled: false,
  isLoadingPaymentMethods: true,
  isUpdatingDefaultPaymentMethod: false,
  // Success states
  // Error states
  errorLoadingTeam: undefined,
  errorLoadingPaymentMethods: undefined,
  errorUpdatingTeam: undefined,
  errorUpdatingDefaultPaymentMethod: undefined,
}

export default function team(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
