import { logout } from '@foodsby/webapp-jwt'
import { push } from 'connected-react-router'
import { first, isEmpty, isString } from 'lodash'
import { all, call, put, race, select, take } from 'redux-saga/effects'

import { resetCaches } from '../../api/api'
import { friendsRoute, locationRoute, loginRoute } from '../../routes/routes'
import { loginUser, logoutAdmin, oneShotRegister, updateUserLocation } from '../../services/user'
import {
  logException,
  normalizeErrorResponse,
  normalizeLoginUserError,
  normalizeUserCreationError,
} from '../../util/errorUtils'
import { formatUrl } from '../../util/formatUtils'
import { pushGTMDataLayer } from '../../util/gtmUtils'
import { getDecodedAccessToken, storeUserInfoCookie } from '../../util/webStorageUtils'
import { FULFILLED } from '../utils'
import { confirmReferralSuccess, referralRedeem } from './friends'
import { JOIN_USER_SUCCESS, joinUser } from './join'
import {
  clearPreviousLocation,
  createUserSavedLocationStart,
  LOAD_LOCATION_SUCCESS,
  loadLocationStart,
  loadUserSavedLocationsStart,
} from './location'
import { SUCCESS } from './locationSearch'
import { PROFILE_SET } from './profile'
import { apiSaga, formSaga } from './sagas'
import {
  getLocationIdParam,
  selectConfirmUserCredentials,
  selectCurrentLocationWithAddress,
  selectCurrentUser,
  selectEntryPoint,
  selectIsAuthenticated,
  selectRef,
} from './selectors'
import { refreshTokenSaga } from './token'
import { enqueueSnackbar } from './snackbar'

export const ROLE_FOODSBY_ADMIN = 'ROLE_FOODSBY_ADMIN'

// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const LOGIN = 'foodsby/user/LOGIN'
export const LOGIN_SUCCESS = 'foodsby/user/LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'foodsby/user/LOGIN_FAILURE'

export const LOGOUT = 'foodsby/user/LOGOUT'
export const LOGOUT_SUCCESS = 'foodsby/user/LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'foodsby/user/LOGOUT_FAILURE'

export const GET_CURRENT_USER = 'foodsby/user/GET_CURRENT_USER'
export const GET_CURRENT_USER_SUCCESS = 'foodsby/user/GET_CURRENT_USER_SUCCESS'
export const GET_CURRENT_USER_FAILURE = 'foodsby/user/GET_CURRENT_USER_FAILURE'

export const CONFIRM_USER = 'foodsby/user/CONFIRM_USER'
export const CONFIRM_USER_SUCCESS = 'foodsby/user/CONFIRM_USER_SUCCESS'
export const CONFIRM_USER_FAILURE = 'foodsby/user/CONFIRM_USER_FAILURE'

export const REGISTER_LIGHT = 'foodsby/ticket/register/REGISTER_LIGHT'
export const REGISTER_LIGHT_SUCCESS = 'foodsby/ticket/register/REGISTER_LIGHT_SUCCESS'
export const REGISTER_LIGHT_FAILURE = 'foodsby/ticket/register/REGISTER_LIGHT_FAILURE'

export const UPDATE_AND_SAVE_USER_LOCATION = 'foodsby/user/UPDATE_AND_SAVE_USER_LOCATION'
export const UPDATE_AND_SAVE_USER_LOCATION_SUCCESS =
  'foodsby/user/UPDATE_AND_SAVE_USER_LOCATION_SUCCESS'
export const UPDATE_AND_SAVE_USER_LOCATION_FAILURE =
  'foodsby/user/UPDATE_AND_SAVE_USER_LOCATION_FAILURE'

export const UPDATE_USER_LOCATION_TEMPORARILY = 'foodsby/user/UPDATE_USER_LOCATION_TEMPORARILY'
export const REVERT_TEMPORARY_LOCATION = 'foodsby/user/REVERT_TEMPORARY_LOCATION'

export const CLEAR_LOGIN_ERRORS = 'foodsby/user/CLEAR_LOGIN_ERRORS'
export const CLEAR_UPDATE_LOCATION_ERROR = '/foodsby/user/CLEAR_UPDATE_LOCATION_ERROR'

export const SET_ENTRY_POINT = '/foodsby/user/SET_ENTRY_POINT'

export const loginStart = (credentials, ref) => {
  return {
    payload: { credentials, ref },
    type: LOGIN,
  }
}

export const loginSuccess = response => {
  return {
    payload: response,
    type: LOGIN_SUCCESS,
  }
}

export const loginFailure = error => {
  const message = normalizeErrorResponse(error.response, error.message)
  return {
    error: { message },
    type: LOGIN_FAILURE,
  }
}

export const logoutStart = (ref, currentUser) => {
  return {
    payload: { currentUser, ref },
    type: LOGOUT,
  }
}

export const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
  }
}

export const logoutFailure = error => {
  return {
    error,
    type: LOGOUT_FAILURE,
  }
}

export const currentUserStart = () => {
  return {
    type: GET_CURRENT_USER,
  }
}

export const registerLightStart = userData => {
  return {
    payload: { userData },
    type: REGISTER_LIGHT,
  }
}

export const registerLightSuccess = ({ username }) => {
  return {
    payload: { username },
    type: REGISTER_LIGHT_SUCCESS,
  }
}

export const registerLightFailure = error => {
  return {
    error,
    type: REGISTER_LIGHT_FAILURE,
  }
}

export const confirmRegisterStart = (formData, path, entryPoint, referralCode) => {
  return {
    payload: { entryPoint, formData, path, referralCode },
    type: CONFIRM_USER,
  }
}

export const confirmRegisterSuccess = (currentUser, referralCode) => {
  return {
    payload: { currentUser, referralCode },
    type: CONFIRM_USER_SUCCESS,
  }
}

export const confirmRegisterFailure = error => {
  return {
    error,
    type: CONFIRM_USER_FAILURE,
  }
}

export const currentUserSuccess = currentUser => {
  return {
    payload: { currentUser },
    type: GET_CURRENT_USER_SUCCESS,
  }
}

export const currentUserFailure = error => {
  return {
    error,
    type: GET_CURRENT_USER_FAILURE,
  }
}

export const updateUserLocationTemporarily = locationId => {
  return {
    payload: { locationId },
    type: UPDATE_USER_LOCATION_TEMPORARILY,
  }
}

export const updateAndSaveUserLocationStart = locationId => {
  return {
    payload: { locationId },
    type: UPDATE_AND_SAVE_USER_LOCATION,
  }
}

export const updateAndSaveUserLocationSuccess = currentUser => {
  return {
    payload: { currentUser },
    type: UPDATE_AND_SAVE_USER_LOCATION_SUCCESS,
  }
}

export const updateAndSaveUserLocationFailure = error => {
  return {
    error,
    type: UPDATE_AND_SAVE_USER_LOCATION_FAILURE,
  }
}

export const revertTemporaryLocation = originalLocationId => {
  return {
    payload: { originalLocationId },
    type: REVERT_TEMPORARY_LOCATION,
  }
}

export const clearLoginErrors = () => {
  return {
    payload: {},
    type: CLEAR_LOGIN_ERRORS,
  }
}

export const clearUpdateLocationError = () => {
  return {
    type: CLEAR_UPDATE_LOCATION_ERROR,
  }
}

export const setEntryPoint = entryPoint => {
  return {
    payload: { entryPoint },
    type: SET_ENTRY_POINT,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [CLEAR_LOGIN_ERRORS]: state => {
    return {
      ...state,
      error: undefined,
    }
  },
  [CLEAR_UPDATE_LOCATION_ERROR]: state => {
    return {
      ...state,
      updateUserLocationError: undefined,
    }
  },
  [CONFIRM_USER]: (state, action) => {
    const confirmCredentials = action.payload
    return {
      ...state,
      confirmCredentials,
      submitting: true,
    }
  },
  [CONFIRM_USER_FAILURE]: (state, action) => {
    const error = normalizeUserCreationError(action.error)
    return {
      ...state,
      confirmUserError: error,
      currentUser: undefined,
      submitting: undefined,
    }
  },
  [CONFIRM_USER_SUCCESS]: state => {
    return {
      ...state,
      confirmUserError: undefined,
      submitting: undefined,
    }
  },
  [GET_CURRENT_USER]: (state = { currentUser: undefined }) => {
    return {
      ...state,
      isCurrentUserLoading: true,
    }
  },
  [GET_CURRENT_USER_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      currentUser: undefined,
      error,
      isAuthenticated: false,
      isCurrentUserLoading: false,
      userInitialized: true,
    }
  },
  [GET_CURRENT_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      currentUser: action.payload.currentUser,
      userInitialized: true,
      error: undefined,
      isAuthenticated: true,
      isCurrentUserLoading: false,
    }
  },
  [JOIN_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      currentUser: action.payload.currentUser,
      isAuthenticated: true,
    }
  },
  [LOGIN]: (state, action) => {
    const { accessToken } = action.payload
    return {
      ...state,
      accessToken,
      currentUser: undefined,
      isAuthenticated: false,
      submitting: true,
    }
  },
  [LOGIN_FAILURE]: (state, action) => {
    const { error } = action
    const { numberOfFailedLoginAttempts } = state
    return {
      ...state,
      currentUser: undefined,
      error,
      isAuthenticated: false,
      submitting: undefined,
      numberOfFailedLoginAttempts: numberOfFailedLoginAttempts + 1,
    }
  },
  [LOGIN_SUCCESS]: state => {
    try {
      const { authorities, location_id, user_id, user_name } = getDecodedAccessToken()

      let currentUser = {
        authorities,
        deliveryLocationId: location_id,
        email: user_name,
        userId: user_id,
        userName: user_name,
      }

      return {
        ...state,
        confirmCredentials: undefined,
        currentUser,
        error: undefined,
        isAuthenticated: true,
        isCurrentUserLoading: false,
        ref: undefined,
        submitting: undefined,
        userInitialized: true,
      }
    } catch (ex) {
      logException(ex)
      return {
        ...state,
        error: normalizeLoginUserError(ex),
        submitting: undefined,
      }
    }
  },
  [LOGOUT]: (state, action) => {
    return {
      ...state,
      currentUser: undefined,
      isAuthenticated: false,
      ref: action.payload.ref,
    }
  },
  // Assuming the user is returned when registration finishes
  // Assuming user can register full or partial (w/password or w/o-password both are same call.
  [LOGOUT_FAILURE]: state => {
    return {
      ...state,
      currentUser: undefined,
      isAuthenticated: false,
    }
  },
  [LOGOUT_SUCCESS]: state => {
    return {
      ...state,
      currentUser: undefined,
      isAuthenticated: false,
    }
  },
  [REGISTER_LIGHT]: state => {
    return {
      ...state,
      registerLightError: undefined,
      submitting: true,
    }
  },
  [REGISTER_LIGHT_FAILURE]: (state, action) => {
    const error = normalizeUserCreationError(action.error.response)
    return {
      ...state,
      currentUser: undefined,
      isAuthenticated: false,
      registerLightError: error,
      submitting: undefined,
    }
  },
  [REGISTER_LIGHT_SUCCESS]: state => {
    return {
      ...state,
    }
  },
  [REVERT_TEMPORARY_LOCATION]: (state, action) => {
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        deliveryLocationId: action.payload.originalLocationId,
        originalLocationId: undefined,
      },
      hasChangedLocation: false,
    }
  },
  [SET_ENTRY_POINT]: (state, action) => {
    return {
      ...state,
      entryPoint: action.payload.entryPoint,
    }
  },
  [SUCCESS]: state => {
    return {
      ...state,
      hasChangedLocation: false,
    }
  },
  [UPDATE_AND_SAVE_USER_LOCATION]: (state, action) => {
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        deliveryLocationId: action.payload.locationId,
        originalLocationId: state.currentUser.deliveryLocationId,
      },
      hasChangedLocation: true,
      updateUserLocationError: undefined,
    }
  },
  [UPDATE_AND_SAVE_USER_LOCATION_FAILURE]: (state, action) => {
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        deliveryLocationId: state.currentUser.originalLocationId,
        originalLocationId: undefined,
      },
      updateUserLocationError: action.error,
    }
  },
  [UPDATE_AND_SAVE_USER_LOCATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      currentUser: {
        ...action.payload.currentUser,
        originalLocationId: undefined,
      },
      hasChangedLocation: true,
    }
  },
  [UPDATE_USER_LOCATION_TEMPORARILY]: (state, action) => {
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        deliveryLocationId: action.payload.locationId,
        originalLocationId:
          state.currentUser.originalLocationId || state.currentUser.deliveryLocationId,
      },
      hasChangedLocation: true,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  isAuthenticated: false,
  isCurrentUserLoading: true,
  submitting: undefined,
  userInitialized: false,
  numberOfFailedLoginAttempts: 0,
}

export default function user(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------
export function* watchLogin() {
  while (true) {
    const {
      payload: { credentials, ref },
    } = yield take(LOGIN)
    try {
      const selector = yield select(selectRef)
      const connectionId = credentials.connectionId
      yield call(formSaga, 'login', loginUser, [credentials], loginSuccess, loginFailure)
      const isAuthenticated = yield select(selectIsAuthenticated)

      let url
      // Do any redirects
      if (ref) {
        url = ref
      } else if (selector?.ref) {
        url = selector.ref
      } else if (isAuthenticated && connectionId) {
        url = friendsRoute.path + `?connectionId=${connectionId}`
      }

      if (url) {
        yield put(push(url))
      }
    } catch (ex) {
      logException(ex)
      yield put(loginFailure(ex))
    }
  }
}

export function* watchUserInfoLoaded() {
  while (true) {
    yield race([take(LOGIN_SUCCESS), take(GET_CURRENT_USER_SUCCESS)])
    const currentUser = yield select(selectCurrentUser)
    if (currentUser) {
      yield put(loadUserSavedLocationsStart())
    }

    yield take(LOAD_LOCATION_SUCCESS)
    try {
      const location = yield select(selectCurrentLocationWithAddress)
      if (currentUser) {
        if (location) {
          storeUserInfoCookie({
            userBuildingCity: location.city,
            userBuildingEPP: location.isEpp,
            userBuildingId: location.deliveryLocationId,
            userBuildingName: location.deliveryLocationName,
            userBuildingState: location.state,
            userBuildingStreet: location.street,
            userBuildingZip: location.zip,
            userId: currentUser.userId,
          })
        } else {
          storeUserInfoCookie({
            userId: currentUser.userId,
          })
        }
      }
    } catch (ex) {
      logException(ex)
    }
  }
}

export function* watchLogout() {
  while (true) {
    const {
      payload: { currentUser },
    } = yield take(LOGOUT)

    resetCaches()

    try {
      const id = yield select(getLocationIdParam)
      const route = id ? null : loginRoute.path

      try {
        if (currentUser && (currentUser.authorities || []).includes(ROLE_FOODSBY_ADMIN)) {
          try {
            yield call(logoutAdmin)
          } catch (ex) {
            logException(ex)
            // don't throw a fail state, just continue
          }
        }
        yield call(logout, process.env.REACT_APP_COOKIE_NAME, process.env.REACT_APP_COOKIE_DOMAIN)
        yield put(logoutSuccess())

        if (route) {
          // refreshes the whole app so the state gets cleared
          window.location.href = route
        }
      } catch (ex) {
        logException(ex)
        yield put(logoutFailure(ex))
      }
    } catch (ex) {
      logException(ex)
      yield put(logoutFailure(ex))
      window.location.href = loginRoute.path
    }
  }
}

export function* watchLoadCurrentUser() {
  while (true) {
    yield take(GET_CURRENT_USER)
    try {
      const token = yield call(getDecodedAccessToken)
      if (token) {
        yield put(
          currentUserSuccess({
            authorities: token.authorities,
            deliveryLocationId: token.location_id,
            email: token.user_name,
            userId: token.user_id,
            userName: token.user_name,
          }),
        )
        if (window.braze) {
          window.braze.changeUser(token.user_id) // start tracking in braze
        }
      } else {
        yield put(currentUserFailure())
      }
    } catch (ex) {
      yield put(currentUserFailure(ex))
    }
  }
}

export function* watchConfirmUser() {
  while (true) {
    const {
      payload: { entryPoint, formData, referralCode },
    } = yield take(CONFIRM_USER)
    const reduxEntryPoint = yield select(selectEntryPoint)
    formData.locationId = Number(formData.locationId)
    formData.platform = 'WEB'
    formData.entryPoint = entryPoint || reduxEntryPoint || 'SELF_SIGN_UP'

    yield call(
      formSaga,
      'confirmregister',
      oneShotRegister,
      [formData],
      payload => confirmRegisterSuccess(payload, referralCode),
      confirmRegisterFailure,
    )
  }
}

export function* watchSubmitRegisterLight() {
  while (true) {
    const {
      payload: {
        userData: { locationId, username },
      },
    } = yield take(REGISTER_LIGHT)
    try {
      yield call(
        formSaga,
        'registrationLight',
        joinUser,
        [username, locationId],
        registerLightSuccess,
        registerLightFailure,
      )
    } catch (ex) {
      yield put(registerLightFailure(ex))
    }
  }
}

export function* watchRegisterLightSuccess() {
  while (true) {
    const {
      payload: { username },
    } = yield take(REGISTER_LIGHT_SUCCESS)
    yield put(loginStart({ password: '', userName: username }))
  }
}

export function* watchConfirmUserSuccess() {
  while (true) {
    const {
      payload: { path },
    } = yield take(CONFIRM_USER)
    yield take(CONFIRM_USER_SUCCESS)
    const credentials = yield select(selectConfirmUserCredentials)

    yield put(
      loginStart(
        {
          password: credentials.password,
          userName: credentials.email,
        },
        path,
      ),
    )
  }
}

export function* watchRegisterSuccess() {
  while (true) {
    const results = yield all([
      take(CONFIRM_USER_SUCCESS),
      take(FULFILLED(PROFILE_SET)),
      take(LOGIN_SUCCESS),
      take(LOAD_LOCATION_SUCCESS),
    ])
    const currentUser = yield select(selectCurrentUser)
    const location = yield select(selectCurrentLocationWithAddress)
    const referralCode = first(results)?.payload?.referralCode

    if (currentUser && location) {
      yield put(createUserSavedLocationStart(location))
    }
    if (isString(referralCode) && !isEmpty(referralCode)) {
      try {
        const result = yield call(referralRedeem, referralCode)
        yield put(confirmReferralSuccess(result))
      } catch (ex) {
        yield put(
          enqueueSnackbar({
            message: `An error occurred. ${ex?.message}`,
          }),
        )
      }
    }
    if (currentUser) {
      pushGTMDataLayer({
        event: 'completedRegistration',
        signupSource: 'webApp',
        userId: currentUser.userId,
      })
      if (window.braze) {
        window.braze.changeUser(currentUser.userId)
      }
    }
  }
}

export function* watchUpdateAndSaveUserLocation() {
  while (true) {
    const {
      payload: { locationId },
    } = yield take(UPDATE_AND_SAVE_USER_LOCATION)
    const user = yield select(selectCurrentUser)

    try {
      // update the user's location via API
      yield call(apiSaga, updateUserLocation, [user.userId, locationId])

      user.deliveryLocationId = locationId
      // update user token
      yield call(refreshTokenSaga)
      yield put(updateAndSaveUserLocationSuccess(user))
      yield put(clearPreviousLocation())
    } catch (ex) {
      yield put(updateAndSaveUserLocationFailure(ex))
    }
  }
}

export function* watchUpdateAndSaveUserLocationSuccess() {
  while (true) {
    const {
      payload: {
        currentUser: { deliveryLocationId },
      },
    } = yield take(UPDATE_AND_SAVE_USER_LOCATION_SUCCESS)
    yield put(loadLocationStart(deliveryLocationId))
  }
}

export function* watchRevertTemporaryLocation() {
  while (true) {
    const {
      payload: { originalLocationId },
    } = yield take(REVERT_TEMPORARY_LOCATION)
    yield put(push(formatUrl(locationRoute.path, { locationId: originalLocationId })))
  }
}
