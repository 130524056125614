export const COUPON_NOT_APPLIED = ' - coupon not applied'

export const normalizeValidationErrors = validationErrors => {
  if (validationErrors) {
    return validationErrors.reduce((acc, { field, message }) => {
      acc[field] = message
      return acc
    }, {})
  }
}

const defaultErrorMessage =
  'Oops! Something went wrong. If this continues to happen, please contact support.'

export const normalizeDeliveryErrorMessage = serverMessage => {
  switch (serverMessage) {
    case 'PAST_CUTOFF':
      return {
        message: `You have passed the order cutoff time for this delivery.`,
        title: 'Sorry, it’s too late.',
      }
    case 'SOLD_OUT':
      return {
        message: 'This restaurant accepts a limited number of orders to ensure quality.',
        title: 'Sold out.',
      }
    case 'NOT_CANCELABLE':
      return {
        message: '',
        title: 'This order has been processed so it cannot be cancelled',
      }
    default:
      return {
        message: 'We’re experiencing an internal error.',
        title: 'Sorry, something went wrong.',
      }
  }
}

function parseHtmlEntities(str) {
  return str.replace(/&#([0-9]{1,3});/gi, (match, numStr) => {
    const num = parseInt(numStr, 10) // read num as normal number
    return String.fromCharCode(num)
  })
}

export const normalizeLocationErrorResponse = response => {
  const message = 'Invalid location, please enter a new address.'
  switch (response.status) {
    case 400:
      return message
    case 404:
      return message
    default:
      return defaultErrorMessage
  }
}

/**
 * A generic error handler that produces a user friendly error message.
 * All error messages can be overridden via the defaults parameter.
 * @param {*} response The error response. This must contain a `status` property.
 * @param {*} defaults An object containing overriding messages for the various error scenarios.
 */
export const normalizeErrorResponse = (response = {}, defaults = {}) => {
  let message
  if (typeof defaults === 'string') {
    message = defaults
  } else {
    const { defaultMessage } = defaults
    message = defaultMessage
  }

  if (typeof response === 'string') {
    message = response
  } else if (typeof response.data === 'string') {
    message = response.data
  } else if (typeof response.message === 'string') {
    message = response.message
  } else {
    switch (response.status) {
      case 404: {
        const { notFound } = defaults
        message = notFound
        return message || 'We were unable to find the item you were looking for.'
      }
      default:
        if (response && response.data) {
          // server supplied a prettier error message
          if (response.data.exceptionMessage) {
            message = response.data.exceptionMessage
          } else if (response.data.errorMessage) {
            message = response.data.errorMessage
          } else if (response.data.errorDescription) {
            message = response.data.errorDescription
          } else if (response.data.message) {
            message = response.data.message
          } else if (typeof response.data === 'string') {
            message = response.data.replace('101:', '')
          }
        }
    }
  }
  if (message === 'Bad credentials') {
    message = 'Invalid email address or password.'
  }
  return parseHtmlEntities(message || defaultErrorMessage)
}

export const normalizeInvalidTokenError = response => {
  let message = defaultErrorMessage
  if (response.status === 401 && response.data.error === 'invalid_token') {
    message = 'Invalid Token: your token has either expired or it is invalid.'
  }
  return message
}

export const normalizeUserCreationError = response => {
  let message = defaultErrorMessage
  // server supplied a prettier error message
  if (
    response &&
    response.data &&
    response.data.validationErrors &&
    response.data.validationErrors.length &&
    response.data.validationErrors[0].message
  ) {
    message = response.data.validationErrors[0].message
  }
  if (/user with the username of (.*) has already been confirmed/.test(message)) {
    message = 'This email address has already been used. Please login.'
  }
  if (/User with id of (.*) does not exist/.test(message)) {
    message = 'This email address does not exist.'
  }
  if (/Illegal request to friend yourself/.test(message)) {
    message = 'You cannot send yourself a friend request'
  }
  return parseHtmlEntities(message || defaultErrorMessage)
}

export const normalizeLoginUserError = ex => {
  let msg = ex.message
  if (
    (msg && msg.includes("'user_id' of undefined")) ||
    (msg && msg.includes("'user_id' of null"))
  ) {
    ex.message =
      'We were unable to log you in. Please ensure cookies are enabled and try again. If the error persists, try using different browser, download our mobile app, or contact support.'
  }
  return ex
}

export const logException = ex => {
  // TODO: PE-4220: Add exception logging
}

export const normalizeProfileImageErrors = errors => {
  let normalizedErrors = []

  errors.includes('IMAGE_WIDTH_TOO_SMALL') &&
    normalizedErrors.push('The minimum image width is 180px.')

  errors.includes('IMAGE_WIDTH_TOO_LARGE') &&
    normalizedErrors.push('The max image width is 5000px.')

  errors.includes('IMAGE_HEIGHT_TOO_SMALL') &&
    normalizedErrors.push('The minimum image height is 180px.')

  errors.includes('IMAGE_HEIGHT_TOO_LARGE') &&
    normalizedErrors.push('The max image height is 5000px.')

  errors.includes('IMAGE_TOO_LARGE') && normalizedErrors.push('The maximum file size is 4MB.')

  errors.includes('IMAGE_TOO_SMALL') && normalizedErrors.push('The minimum image size is 1KB.')

  errors.includes('FILETYPE_NOT_SUPPORTED') &&
    normalizedErrors.push(
      `The image's filetype is not supported. We currently support JPG and PNG.`,
    )

  if (errors.includes('EMPTY_FILE') || errors.includes('INVALID_FILE')) {
    normalizedErrors.push(
      `We're experiencing an internal error. Please try again or with a new image.`,
    )
  }

  if (errors && normalizedErrors.length === 0) {
    normalizedErrors.push(errors)
  }

  return normalizedErrors
}

export const normalizeReferralRedemptionErr = err => {
  switch (err) {
    case 'REFERRAL_NOT_FOUND':
      return 'Sorry, that referral code is not valid.'
    case 'CANNOT_REDEEM_OWN_CODE':
      return `Sorry, you can't redeem from your code`
    case 'HAS_PREVIOUS_ORDERS':
      return 'Sorry, but the referral incentive is only for new users.'
    case 'HAS_PREVIOUS_REDEMPTION':
      return `Sorry, you can't redeem more than one`
    case 'INVALID':
      return 'An internal server error occurred processing the referral.'
    default:
      return err
  }
}

export const normalizeApplyCouponErrorResponse = errors => {
  let error = normalizeErrorResponse(errors)

  if (error === 'Invalid coupon, not valid for this product') {
    error = 'Oops! This coupon only works for Meetings'
  }
  if (error && error.includes('Coupon cannot be used by user')) {
    error = '🤨 This is your coupon from when you shared your order link. This coupon won’t work.'
  }

  return error + COUPON_NOT_APPLIED
}
