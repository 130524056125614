import { isNil } from 'lodash'

const isTrial = subscription =>
  subscription && !subscription?.previousPaymentDate && !subscription?.lastUnsuccessfulPaymentDate
const isFirstSignup = offerDetails => !isNil(offerDetails?.offer?.trialInformation)

export const isEligibleToSubscribe = offerDetails =>
  offerDetails && isNil(offerDetails.ineligabilityReason)
export const isEligibleForFreeTrial = offerDetails => isFirstSignup(offerDetails)

//this will evaluate to false if they are ineligable, but we don't use this for anything
//outside of when they do qualify, and this this is effectively correct
export const hasSubscribedBefore = offerDetails => !isFirstSignup(offerDetails)
export const hasRequestedSubscriptionCancelation = subscription =>
  subscription?.status === 'CANCEL_REQUESTED'
export const hasActiveSubscription = subscription => !!subscription
export const hasActiveSubscriptionTrial = subscription => isTrial(subscription)
