import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import { selectTeam } from '../selectors/teamSelectors'
import { getPlanApi, updateAccountPlanApi, updateTeamPlanApi } from '../../services/team'
import { enqueueSnackbar } from './snackbar'

const DEFAULT_UPDATE_PLAN_ERROR_MESSAGE = 'Something went wrong while updating the perk.'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const STATE_RESET = 'foodsby/teamSubscription/STATE_RESET'
export const TEAM_SUBSCRIPTION_SET = 'foodsby/teamSubscription/TEAM_SUBSCRIPTION_SET'
export const TEAM_SUBSCRIPTION_UPDATE = 'foodsby/teamSubscription/TEAM_SUBSCRIPTION_UPDATE'

export const resetState = createAction(STATE_RESET)
export const setTeamSubscription = createAsyncAction(TEAM_SUBSCRIPTION_SET)
export const updateTeamSubscription = createAsyncAction(TEAM_SUBSCRIPTION_UPDATE)
export const updateTeamSubscriptionPending = createAction(PENDING(TEAM_SUBSCRIPTION_UPDATE))
export const updateTeamSubscriptionFulfilled = createAction(FULFILLED(TEAM_SUBSCRIPTION_UPDATE))
export const updateTeamSubscriptionRejected = createAction(REJECTED(TEAM_SUBSCRIPTION_UPDATE))

// ------------------------------------
// Thunks
// ------------------------------------
export const loadTeamSubscriptionStart = () => {
  return (dispatch, getState) => {
    const state = getState()
    const team = selectTeam(state)
    return dispatch(setTeamSubscription(getPlanApi(team.id)))
  }
}

export const subscribeStart = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const team = selectTeam(state)

    try {
      await dispatch(updateTeamSubscription(updateTeamPlanApi(team.id, { planId: 2 })))
    } catch (ex) {
      return dispatch(enqueueSnackbar({ message: DEFAULT_UPDATE_PLAN_ERROR_MESSAGE }))
    }
  }
}

export const unsubscribeStart = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const team = selectTeam(state)
    try {
      await dispatch(updateTeamSubscription(updateTeamPlanApi(team.id, { planId: 1 })))
    } catch (ex) {
      return dispatch(enqueueSnackbar({ message: DEFAULT_UPDATE_PLAN_ERROR_MESSAGE }))
    }
  }
}

export const updateSubscriptionStart = ({ autoEnrollNewMembers, endDate }) => {
  return async (dispatch, getState) => {
    const state = getState()
    const team = selectTeam(state)

    try {
      dispatch(updateTeamSubscriptionPending())
      await updateAccountPlanApi(team.id, { autoEnrollNewMembers, endDate })
      await dispatch(loadTeamSubscriptionStart())
      dispatch(updateTeamSubscriptionFulfilled())
    } catch (ex) {
      dispatch(updateTeamSubscriptionRejected())
      dispatch(
        enqueueSnackbar({
          message: ex.message || DEFAULT_UPDATE_PLAN_ERROR_MESSAGE,
        }),
      )
    }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [STATE_RESET]: () => {
    return initialState
  },
  [FULFILLED(TEAM_SUBSCRIPTION_SET)]: (state, action) => {
    return {
      ...state,
      teamSubscription: action.payload,
      isLoadingSubscription: false,
    }
  },
  [PENDING(TEAM_SUBSCRIPTION_UPDATE)]: state => {
    return {
      ...state,
      isUpdatingSubscription: true,
    }
  },
  [FULFILLED(TEAM_SUBSCRIPTION_UPDATE)]: state => {
    return {
      ...state,
      isUpdatingSubscription: false,
    }
  },
  [REJECTED(TEAM_SUBSCRIPTION_UPDATE)]: state => {
    return {
      ...state,
      isUpdatingSubscription: false,
    }
  },
}

export const initialState = {
  teamSubscription: undefined,
  // Loading states
  isLoadingSubscription: true,
  isUpdatingSubscription: false,
}

export default function teamSubscription(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
