import React from 'react'
import {
  Box,
  DialogActions,
  DialogContentText,
  FormControl,
  FormLabel,
  RadioGroup,
  Typography,
} from '@material-ui/core'

import { Form, Formik } from 'formik'
import { Skeleton } from '@material-ui/lab'
import { themeConWeb } from '../../../util/modernThemeConweb'
import ButtonSubmit from '../../common/ButtonSubmit'

import DialogContent from './DialogContent'
import RadioCardOption from './RadioCardOption'
import { getProgramUsageTypeDescription, PROGRAM_DIALOG_HEIGHT_BIG } from './programUtils'

const StepProgramUsageType = ({
  validationSchema,
  values,
  programUsageTypes,
  loading,
  setFieldValue,
  handleClose,
  handleGetTotal,
  offerUsageType,
  isRedeeming100PercentOffer,
}) => {
  const handleSubmit = newValues => {
    setFieldValue('usageType', newValues.usageType)
    handleGetTotal({ usageType: newValues.usageType })
    handleClose()
  }

  return (
    <Formik
      initialValues={{
        usageType: values.usageType,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {props => {
        const { setFieldValue, values: subValues, errors, touched } = props
        return (
          <Form>
            <DialogContent height={PROGRAM_DIALOG_HEIGHT_BIG}>
              {!isRedeeming100PercentOffer && (
                <DialogContentText>
                  You will only be charged for the funds used by your members within the selected
                  time period. Funds do not roll over to the next period.
                </DialogContentText>
              )}
              {loading && <SkeletonLoading />}
              {!loading && (
                <FormControl
                  name="usageType"
                  component="fieldset"
                  error={!!touched.usageType && !!errors.usageType}
                >
                  <RadioGroup
                    aria-label="subsidy cadence"
                    name="usageType"
                    value={subValues.usageType}
                    onChange={event => setFieldValue('usageType', event.target.value)}
                  >
                    {programUsageTypes.map(usageType => {
                      const disabled =
                        offerUsageType &&
                        offerUsageType !== '' &&
                        offerUsageType !== usageType.value
                      const disabledColorStyle = {
                        color: disabled
                          ? themeConWeb.color.greyShade2
                          : themeConWeb.color.primaryType,
                      }
                      return (
                        <RadioCardOption
                          key={usageType.value}
                          value={usageType.value}
                          checked={subValues.usageType === usageType.value}
                          disabled={!!disabled}
                          disabledReason={
                            disabled ? 'This setting is unavailable with this offer.' : ''
                          }
                        >
                          <Typography variant="h6" style={disabledColorStyle}>
                            {usageType.label}
                          </Typography>
                          <Typography variant="body1" style={disabledColorStyle}>
                            {getProgramUsageTypeDescription(usageType.value)}
                          </Typography>
                        </RadioCardOption>
                      )
                    })}
                  </RadioGroup>
                  <FormLabel>{touched.usageType && errors.usageType}</FormLabel>
                </FormControl>
              )}
            </DialogContent>
            {/* Need this to maintain the dialog height */}
            <DialogActions>
              <Box>
                <ButtonSubmit color="primary" variant="contained">
                  Save
                </ButtonSubmit>
              </Box>
            </DialogActions>
          </Form>
        )
      }}
    </Formik>
  )
}

const SkeletonLoading = () => {
  return (
    <>
      <Skeleton height={125} />
      <Skeleton height={125} />
      <Skeleton height={125} />
    </>
  )
}

export default StepProgramUsageType
