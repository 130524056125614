export const convertTo12HourFormat = time24h => {
  if (!time24h) {
    return
  }
  let [hours, minutes] = time24h.split(':')
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes.substring(0, 2)
  return `${hours}:${minutes} ${ampm}`
}
