import { Button, Typography } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React from 'react'
import { Link } from 'react-router-dom'
import CardTeam, { CardTeamContent, CardTeamTitle } from '../teams/common/CardTeam'
import { formatUrl } from '../../util/formatUtils'
import { locationManagerDeliveryRoute, locationManagerOfficesRoute } from '../../routes/routes'

const actions = [
  {
    title: 'View Offices',
    link: locationManagerOfficesRoute.path,
  },
  {
    title: 'View Delivery & Parking',
    link: locationManagerDeliveryRoute.path,
  },
]

const CardCommonActions = ({ locationId }) => {
  const formatPath = path => formatUrl(path, { locationId })
  return (
    <CardTeam>
      <CardTeamTitle>Common Actions</CardTeamTitle>
      {actions.map(action => (
        <CardTeamContent key={action.title}>
          <Button
            component={Link}
            to={formatPath(action.link)}
            startIcon={<InfoOutlinedIcon />}
            style={{ textTransform: 'none' }}
          >
            <Typography>{action.title}</Typography>
          </Button>
        </CardTeamContent>
      ))}
    </CardTeam>
  )
}

export default CardCommonActions
