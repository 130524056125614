import { Box, Button, ButtonGroup, Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'

export const DocsButton = () => (
  <>
    <Grid container justifyContent="center" spacing={2}>
      <Grid xs={12}>
        <Typography component="h2" gutterBottom variant="h2">
          Buttons
        </Typography>
        <Typography component="p" gutterBottom variant="body1">
          For a full list of buttons and their usage reference the material ui docs
        </Typography>
      </Grid>
      <Grid xs={10}>
        <Paper>
          <Box padding={4}>
            <Typography component="h2" gutterBottom variant="2">
              Contained Buttons
            </Typography>
            <Button variant="contained">Default</Button>
            <Button color="primary" variant="contained">
              Primary
            </Button>
            <Button color="secondary" variant="contained">
              Secondary
            </Button>
            <Button disabled variant="contained">
              Disabled
            </Button>
            <Button color="primary" href="#contained-buttons" variant="contained">
              Link
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
    <Grid container justifyContent="center" spacing={2}>
      <Grid xs={10}>
        <Paper>
          <Box padding={4}>
            <Typography component="h2" gutterBottom variant="2">
              Text Buttons
            </Typography>
            <Button>Default</Button>
            <Button color="primary">Primary</Button>
            <Button color="secondary">Secondary</Button>
            <Button disabled>Disabled</Button>
            <Button href="#text-buttons">Link</Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
    <Grid container justifyContent="center" spacing={2}>
      <Grid xs={10}>
        <Paper>
          <Box padding={4}>
            <Typography component="h2" gutterBottom variant="2">
              Grouped Buttons
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <ButtonGroup aria-label="outlined primary button group" color="primary">
                <Button>One</Button>
                <Button>Two</Button>
                <Button>Three</Button>
              </ButtonGroup>
              <ButtonGroup
                aria-label="contained primary button group"
                color="primary"
                variant="contained"
              >
                <Button>One</Button>
                <Button>Two</Button>
                <Button>Three</Button>
              </ButtonGroup>
              <ButtonGroup aria-label="text primary button group" color="primary" variant="text">
                <Button>One</Button>
                <Button>Two</Button>
                <Button>Three</Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
    <Grid container justifyContent="center" spacing={2}>
      <Grid xs={10}>
        <Paper>
          <Box padding={4}>
            <Typography component="h2" gutterBottom variant="2">
              Group sizes and colors
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <ButtonGroup aria-label="small outlined button group" size="small">
                <Button>One</Button>
                <Button>Two</Button>
                <Button>Three</Button>
              </ButtonGroup>
              <ButtonGroup aria-label="outlined secondary button group" color="secondary">
                <Button>One</Button>
                <Button>Two</Button>
                <Button>Three</Button>
              </ButtonGroup>
              <ButtonGroup
                aria-label="large outlined primary button group"
                color="primary"
                size="large"
              >
                <Button>One</Button>
                <Button>Two</Button>
                <Button>Three</Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  </>
)

export default DocsButton
