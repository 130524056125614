import { call, put, select, take } from 'redux-saga/effects'

import {
  loadFutureOrders,
  loadOrderByDate,
  loadOrderById,
  getOrderRewardsByOrderIdApi,
} from '../../api/api'
import { apiSaga } from './sagas'
import { selectIsAuthenticated } from './selectors'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const LOAD_ORDER_DETAIL = 'foodsby/orderDetail/LOAD_ORDER_DETAIL'
export const LOAD_ORDER_DETAIL_SUCCESS = 'foodsby/orderDetail/LOAD_ORDER_DETAIL_SUCCESS'
export const LOAD_ORDER_DETAIL_FAILURE = 'foodsby/orderDetail/LOAD_ORDER_DETAIL_FAILURE'
export const LOAD_FUTURE_ORDER_DETAILS = 'foodsby/orderDetail/LOAD_FUTURE_ORDER_DETAILS'
export const LOAD_FUTURE_ORDER_DETAILS_SUCCESS =
  'foodsby/orderDetail/LOAD_FUTURE_ORDER_DETAILS_SUCCESS'
export const LOAD_FUTURE_ORDER_DETAILS_FAILURE =
  'foodsby/orderDetail/LOAD_FUTURE_ORDER_DETAILS_FAILURE'
export const LOAD_ORDER_BY_ID = 'foodsby/orderDetail/LOAD_ORDER_BY_ID'
export const LOAD_ORDER_BY_ID_SUCCESS = 'foodsby/orderDetail/LOAD_ORDER_BY_ID_SUCCESS'
export const LOAD_ORDER_BY_ID_FAILURE = 'foodsby/orderDetail/LOAD_ORDER_BY_ID_FAILURE'

export const LOAD_ORDER_REWARDS_BY_ID = 'foodsby/orderDetail/LOAD_ORDER_REWARDS_BY_ID'
export const LOAD_ORDER_REWARDS_BY_ID_SUCCESS =
  'foodsby/orderDetail/LOAD_ORDER_REWARDS_BY_ID_SUCCESS'
export const LOAD_ORDER_REWARDS_BY_ID_FAILURE =
  'foodsby/orderDetail/LOAD_ORDER_REWARDS_BY_ID_FAILURE'

export const loadOrderStart = (date, locationId) => {
  return {
    payload: { date, locationId },
    type: LOAD_ORDER_DETAIL,
  }
}

export const loadOrderSuccess = response => {
  return {
    payload: { response },
    type: LOAD_ORDER_DETAIL_SUCCESS,
  }
}

export const loadOrderFailure = error => {
  return {
    error,
    type: LOAD_ORDER_DETAIL_FAILURE,
  }
}

export const loadFutureOrdersStart = (date, locationId, numberOfDaysInFuture) => {
  return {
    payload: { date, locationId, numberOfDaysInFuture },
    type: LOAD_FUTURE_ORDER_DETAILS,
  }
}

export const loadFutureOrdersSuccess = response => {
  return {
    payload: { response },
    type: LOAD_FUTURE_ORDER_DETAILS_SUCCESS,
  }
}

export const loadFutureOrdersFailure = error => {
  return {
    error,
    type: LOAD_FUTURE_ORDER_DETAILS_FAILURE,
  }
}

export const loadOrderByIdStart = orderId => {
  return {
    payload: { orderId },
    type: LOAD_ORDER_BY_ID,
  }
}

export const loadOrderByIdSuccess = response => {
  return {
    payload: { response },
    type: LOAD_ORDER_BY_ID_SUCCESS,
  }
}

export const loadOrderByIdFailure = error => {
  return {
    error,
    type: LOAD_ORDER_BY_ID_FAILURE,
  }
}

export const loadOrderRewardsByIdStart = orderId => {
  return {
    payload: { orderId },
    type: LOAD_ORDER_REWARDS_BY_ID,
  }
}

export const loadOrderRewardsByIdSuccess = response => {
  return {
    payload: { response },
    type: LOAD_ORDER_REWARDS_BY_ID_SUCCESS,
  }
}

export const loadOrderRewardsByIdFailure = error => {
  return {
    error,
    type: LOAD_ORDER_REWARDS_BY_ID_FAILURE,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [LOAD_ORDER_BY_ID]: state => {
    return {
      ...state,
      loading: true,
    }
  },
  [LOAD_ORDER_BY_ID_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    }
  },
  [LOAD_ORDER_BY_ID_SUCCESS]: (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: false,
      order: action.payload.response,
    }
  },
  [LOAD_ORDER_REWARDS_BY_ID]: state => {
    return {
      ...state,
      loadingOrderRewards: true,
    }
  },
  [LOAD_ORDER_REWARDS_BY_ID_FAILURE]: (state, action) => {
    return {
      ...state,
      errorLoadingOrderRewards: action.error,
      loadingOrderRewards: false,
      currentRewardsInCents: undefined,
      currentRewardsPercentage: undefined,
      nextRewardsInCents: undefined,
      nextRewardsPercentage: undefined,
      rewardsDistributed: undefined,
      rewardsEligibleSubtotalInCents: undefined,
    }
  },
  [LOAD_ORDER_REWARDS_BY_ID_SUCCESS]: (state, action) => {
    return {
      ...state,
      errorLoadingOrderRewards: undefined,
      loadingOrderRewards: false,
      currentRewardsInCents: action.payload.response.currentRewardsInCents,
      currentRewardsPercentage: action.payload.response.currentRewardsPercentage,
      nextRewardsInCents: action.payload.response.nextRewardsInCents,
      nextRewardsPercentage: action.payload.response.nextRewardsPercentage,
      rewardsDistributed: action.payload.response.rewardsDistributed,
      rewardsEligibleSubtotalInCents: action.payload.response.rewardsEligibleSubtotalInCents,
    }
  },
  [LOAD_ORDER_DETAIL]: state => {
    return {
      ...state,
      loading: true,
    }
  },
  [LOAD_ORDER_DETAIL_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    }
  },
  [LOAD_ORDER_DETAIL_SUCCESS]: (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: false,
      orders: action.payload.response.orders,
    }
  },
  [LOAD_FUTURE_ORDER_DETAILS]: state => {
    return {
      ...state,
      loadingFutureOrders: true,
    }
  },
  [LOAD_FUTURE_ORDER_DETAILS_SUCCESS]: (state, action) => {
    return {
      ...state,
      errorLoadingFutureOrders: undefined,
      loadingFutureOrders: false,
      futureOrders: action.payload.response.orders,
    }
  },
  [LOAD_FUTURE_ORDER_DETAILS_FAILURE]: (state, action) => {
    return {
      ...state,
      errorLoadingFutureOrders: action.error,
      loadingFutureOrders: false,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  error: undefined,
  loading: false,
  orders: [],
  errorLoadingFutureOrders: undefined,
  loadingFutureOrders: false,
  futureOrders: [],
  currentRewardsInCents: undefined,
  currentRewardsPercentage: undefined,
  nextRewardsInCents: undefined,
  nextRewardsPercentage: undefined,
  rewardsDistributed: undefined,
  rewardsEligibleSubtotalInCents: undefined,
  errorLoadingOrderRewards: undefined,
  loadingOrderRewards: false,
}

export default function orderDetail(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// -----------------------------------

export function* watchLoadOrderDetails() {
  while (true) {
    const {
      payload: { date, locationId },
    } = yield take(LOAD_ORDER_DETAIL)
    const isAuthenticated = yield select(selectIsAuthenticated)
    if (isAuthenticated) {
      yield call(apiSaga, loadOrderByDate, [date, locationId], loadOrderSuccess, loadOrderFailure)
    } else {
      yield put(loadOrderFailure)
    }
  }
}

export function* watchLoadFutureOrderDetails() {
  while (true) {
    const {
      payload: { date, locationId, numberOfDaysInFuture },
    } = yield take(LOAD_FUTURE_ORDER_DETAILS)
    const isAuthenticated = yield select(selectIsAuthenticated)
    if (isAuthenticated) {
      yield call(
        apiSaga,
        loadFutureOrders,
        [date, locationId, numberOfDaysInFuture],
        loadFutureOrdersSuccess,
        loadFutureOrdersFailure,
      )
    } else {
      yield put(loadFutureOrdersFailure)
    }
  }
}

export function* watchLoadOrderById() {
  while (true) {
    const {
      payload: { orderId },
    } = yield take(LOAD_ORDER_BY_ID)
    yield call(apiSaga, loadOrderById, [orderId], loadOrderByIdSuccess, loadOrderByIdFailure)
  }
}

export function* watchLoadOrderRewardsById() {
  while (true) {
    const {
      payload: { orderId },
    } = yield take(LOAD_ORDER_REWARDS_BY_ID)
    yield call(
      apiSaga,
      getOrderRewardsByOrderIdApi,
      [orderId],
      loadOrderRewardsByIdSuccess,
      loadFutureOrdersFailure,
    )
  }
}
