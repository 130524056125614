import React from 'react'

import DialogLoginOrJoin from '../login/DialogLoginOrJoin'
import { DialogSelectLocation } from '../register/DialogSelectLocation'
import DialogInviteMembers from '../teams/admin/DialogInviteMembers'
import DialogAcceptDecline from '../teams/invitation/DialogAcceptDecline'
import DialogCreateTeam from '../teams/onboarding/DialogCreateTeam'
import DialogJoinTeam from '../teams/onboarding/DialogJoinTeam'
import DialogTeamValueProps from '../teams/onboarding/DialogTeamValueProps'
import DialogClaimTeam from '../teams/onboarding/DialogClaimTeam'
import DialogUpdateUserLocation from '../location-search/DialogUpdateUserLocation'
import DialogRequestPreferred from './DialogRequestPreferred'
import DialogPreferredValueProps from './DialogPreferredValueProps'

const HomeDialogs = ({ locationId, isScriptLoaded, isScriptLoadSucceed }) => {
  return (
    <>
      <DialogLoginOrJoin locationId={locationId} />
      <DialogSelectLocation
        isScriptLoaded={isScriptLoaded}
        isScriptLoadSucceed={isScriptLoadSucceed}
      />
      <DialogUpdateUserLocation locationId={locationId} />
      <DialogAcceptDecline />
      <DialogInviteMembers />
      <DialogCreateTeam />
      <DialogJoinTeam />
      <DialogRequestPreferred />
      <DialogTeamValueProps />
      <DialogPreferredValueProps />
      <DialogClaimTeam />
    </>
  )
}

export default HomeDialogs
