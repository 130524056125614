import { noop } from 'lodash'

import pluralize from 'pluralize'
import { formatCentsToPrettyDollars, STEPS } from '../../components/teams/perks/giftCardUtils'
import { cache, createGiftCardsApi, validateGiftCardDiscountAmountApi } from '../../services/team'
import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import { selectTeam } from '../selectors/teamSelectors'
import { enqueueSnackbar } from './snackbar'
import { loadGiftTransactionsStart } from './teamBilling'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const CURRENT_STEP_SET = 'foodsby/teamGiftCards/CURRENT_STEP_SET'
export const DISCOUNT_CODE_SET = 'foodsby/teamGiftCards/DISCOUNT_CODE_SET'
export const TOTAL_CHARGE_AMOUNT_SET = 'foodsby/teamGiftCards/TOTAL_CHARGE_AMOUNT_SET'
export const GIFT_CARDS_CREATE = 'foodsby/teamGiftCards/GIFT_CARDS_CREATE'

export const setCurrentStep = createAction(CURRENT_STEP_SET)
export const setDiscountCodePending = createAction(PENDING(DISCOUNT_CODE_SET))
export const setDiscountCodeFulfilled = createAction(FULFILLED(DISCOUNT_CODE_SET))
export const setDiscountCodeRejected = createAction(REJECTED(DISCOUNT_CODE_SET))
export const setTotalChargeAmount = createAction(TOTAL_CHARGE_AMOUNT_SET)
export const createGiftCards = createAsyncAction(GIFT_CARDS_CREATE)

// ------------------------------------
// Thunks
// ------------------------------------
export const setCurrentStepStart = (currentStep, payload, onSuccess = noop) => {
  return async (dispatch, getState) => {
    if (currentStep === STEPS.COMPLETE && payload) {
      const state = getState()
      const team = selectTeam(state)

      try {
        await dispatch(createGiftCards(createGiftCardsApi(team.id, payload)))

        cache.reset()
        dispatch(loadGiftTransactionsStart(team.id))

        const numOfRecipients = payload.userIds.length
        dispatch(
          enqueueSnackbar({
            message: `You sent a ${formatCentsToPrettyDollars(
              payload.amountPer,
            )} one-time perk to ${numOfRecipients} ${pluralize('recipient', numOfRecipients)}.`,
          }),
        )
        onSuccess()
      } catch (ex) {
        dispatch(
          enqueueSnackbar({
            message: `An error occurred`,
          }),
        )
        return
      }
    } else if ([STEPS.GIFT_PAYMENT, STEPS.GIFT_ADD_PAYMENT].includes(currentStep) && payload) {
      await dispatch(validateDiscountCodeStart(payload))
    }

    return dispatch(setCurrentStep(currentStep))
  }
}

export const createGiftStart = (payload, onSuccess = noop) => {
  return async (dispatch, getState) => {
    const state = getState()
    const team = selectTeam(state)

    try {
      await dispatch(createGiftCards(createGiftCardsApi(team.id, payload)))

      cache.reset()
      dispatch(loadGiftTransactionsStart(team.id))

      const numOfRecipients = payload.userIds.length
      dispatch(
        enqueueSnackbar({
          message: `You sent a ${formatCentsToPrettyDollars(
            payload.amountPer,
          )} one-time perk to ${numOfRecipients} ${pluralize('recipient', numOfRecipients)}.`,
        }),
      )
      onSuccess()
    } catch (ex) {
      dispatch(
        enqueueSnackbar({
          message: `An error occurred. ${ex?.message}`,
        }),
      )
    }
  }
}

export const validateDiscountCodeStart = payload => {
  return async dispatch => {
    try {
      const discountInformation = await getDiscountInformation(dispatch, payload)
      dispatch(setDiscountCodeFulfilled(discountInformation))
      if (discountInformation) {
        return dispatch(setTotalChargeAmount(discountInformation.postDiscountTotalInPennies))
      }
    } catch (ex) {
      dispatch(setDiscountCodeRejected(ex))
    }
    return dispatch(setTotalChargeAmount(payload?.userIds?.length * payload?.amountPer || 0))
  }
}

const getDiscountInformation = async (dispatch, payload) => {
  if (payload?.discountCode && payload.discountCode.trim().length > 0) {
    dispatch(setDiscountCodePending())
    return await validateGiftCardDiscountAmountApi(payload.discountCode, payload)
  }
  return undefined
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CURRENT_STEP_SET]: (state, action) => {
    return {
      ...state,
      currentStep: action.payload,
    }
  },
  [TOTAL_CHARGE_AMOUNT_SET]: (state, action) => {
    return {
      ...state,
      totalChargeAmount: action.payload,
    }
  },
  [PENDING(DISCOUNT_CODE_SET)]: state => {
    return {
      ...state,
      isValidatingDiscount: true,
      errorValidatingDiscount: undefined,
    }
  },
  [FULFILLED(DISCOUNT_CODE_SET)]: (state, action) => {
    return {
      ...state,
      isValidatingDiscount: false,
      discountCode: action?.payload?.discountCode,
      discountDescription: action?.payload?.discountDescription,
      discountAmountInPennies: action?.payload?.discountAmountInPennies,
      errorValidatingDiscount: undefined,
    }
  },
  [REJECTED(DISCOUNT_CODE_SET)]: (state, action) => {
    return {
      ...state,
      isValidatingDiscount: false,
      discountCode: undefined,
      discountDescription: undefined,
      discountAmountInPennies: undefined,
      errorValidatingDiscount: 'Invalid Offer',
    }
  },
  [PENDING(GIFT_CARDS_CREATE)]: state => {
    return {
      ...state,
      isCreatingGiftCards: true,
      errorCreatingGiftCards: undefined,
    }
  },
  [FULFILLED(GIFT_CARDS_CREATE)]: state => {
    return {
      ...state,
      isCreatingGiftCards: false,
      errorCreatingGiftCards: undefined,
    }
  },
  [REJECTED(GIFT_CARDS_CREATE)]: state => {
    return {
      ...state,
      isCreatingGiftCards: false,
      errorCreatingGiftCards:
        'Something went wrong purchasing the perk. Please try again later or contact support.',
    }
  },
}

export const initialState = {
  currentStep: undefined,
  discountCode: undefined,
  discountAmountInPennies: undefined,
  totalChargeAmount: undefined,
  discountDescription: undefined,
  // Loading states
  isCreatingGiftCards: false,
  isValidatingDiscount: false,
  // Success states
  // Error states
  errorCreatingGiftCards: undefined,
  errorValidatingDiscount: undefined,
}

export default function teamGiftCards(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
