import React from 'react'
import { Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    opacity: ({ disabled }) => disabled && 0.4,
  },
  switch: {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: theme.palette.success.main,
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.success.main,
          opacity: 0.4,
        },
      },
    },
  },
}))

const SwitchOnOff = props => {
  const classes = useStyles({ disabled: props.disabled })
  return (
    <span className={classes.root}>
      <Switch className={classes.switch} {...props} />
    </span>
  )
}

export default SwitchOnOff
