import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

import { Button, CardActions, CardContent, CardHeader } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import {
  loadTeamSubscriptionStart,
  subscribeStart,
  unsubscribeStart,
} from '../../../redux/modules/teamSubscription'

import { loadAccountSubscriptionPriceStart } from '../../../redux/modules/subscriptions'

import ChipPerkStatus from './ChipPerkStatus'
import CardPerk, { StretchedCard } from './CardPerk'
import { isSubscriptionCanceled } from './teamSubscriptionUtils'
import DialogTeamSubscriptionDetails from './DialogTeamSubscriptionDetails'
import { formatCentsToPrettyDollars } from './giftCardUtils'

const CardTeamSubscription = ({
  teamSubscription,
  isLoadingSubscription,
  loadTeamSubscriptionStart,
  loadAccountSubscriptionPriceStart,
  accountSubscriptionPrice,
}) => {
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false)
  const subscribed = Boolean(teamSubscription)
  const canceled = isSubscriptionCanceled(teamSubscription)

  const history = useHistory()

  useEffect(() => {
    loadTeamSubscriptionStart()
    loadAccountSubscriptionPriceStart()
  }, [loadTeamSubscriptionStart, loadAccountSubscriptionPriceStart])

  const handleOpenSubscriptionDialog = () => {
    setShowSubscriptionDialog(true)
  }

  const handleGoToSubscriptionCheckoutPage = () => {
    history.push('perks/unlimited-delivery')
  }

  const handleCloseSubscriptionDialog = () => {
    setShowSubscriptionDialog(false)
  }

  if (isLoadingSubscription) {
    return <Skeleton variant="rect" height="calc(100% - 16px)" width="100%" />
  }

  const title = 'Foodsby Unlimited'
  const description = `${formatCentsToPrettyDollars(
    accountSubscriptionPrice,
  )} per member / per month for $0 service fees on every order.`
  return (
    <>
      {!subscribed && (
        <CardPerk
          title={title}
          description={description}
          buttonComponentType="button"
          buttonText="Turn on perk"
          onClick={handleGoToSubscriptionCheckoutPage}
        />
      )}
      {subscribed && (
        <StretchedCard>
          <CardHeader title={title} />
          <CardContent>{description}</CardContent>
          <CardActions>
            {!canceled && <ChipPerkStatus status="active" />}
            <Button color="primary" onClick={handleOpenSubscriptionDialog}>
              Manage
            </Button>
          </CardActions>
        </StretchedCard>
      )}
      {showSubscriptionDialog && (
        <DialogTeamSubscriptionDetails
          onClose={handleCloseSubscriptionDialog}
          pricePerHeadInCents={accountSubscriptionPrice}
        />
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    teamSubscription: state.teamSubscription.teamSubscription,
    isLoadingSubscription: state.teamSubscription.isLoadingSubscription,
    accountSubscriptionPrice: state.subscriptions.accountSubscriptionPrice,
  }
}

const mapDispatchToProps = {
  loadTeamSubscriptionStart,
  loadAccountSubscriptionPriceStart,
  subscribeStart,
  unsubscribeStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(CardTeamSubscription)
