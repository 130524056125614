import { InputError } from '@foodsby/nutrient'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import scriptLoader from 'react-async-script-loader'
import { connect } from 'react-redux'

import { search, update } from '../../redux/modules/locationSearch'
import LocationSearch, { MAPS_SCRIPT_URL } from './LocationSearchBox'

const LocationSearchBox = scriptLoader(MAPS_SCRIPT_URL)(LocationSearch)

class LocationSearchContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectDisabled: true,
      selectedLocation: '',
    }

    this.updateLocation = this.updateLocation.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  updateLocation() {
    let currentUrl = window.location.href

    if (currentUrl.includes('?newLocationId')) {
      let newUrl = currentUrl.split('=')[0] + '=' + this.state.selectedLocation
      window.location.href = newUrl
    } else {
      window.location.href = `${currentUrl}?newLocationId=${this.state.selectedLocation}`
    }
  }

  handleChange(e) {
    this.setState({ selectedLocation: e.target.value })
    if (e.target.value === 'search-location') {
      this.setState({ selectDisabled: true })
    } else {
      this.setState({ selectDisabled: false })
    }
  }

  render() {
    const {
      address,
      completeAccountToken,
      error,
      label = 'Search for an address',
      locationError,
      redirectOverride,
      search,
      showError,
      submitting,
      update,
    } = this.props
    return (
      <div>
        <LocationSearchBox
          address={address}
          completeAccountToken={completeAccountToken}
          label={label}
          redirectOverride={redirectOverride}
          search={search}
          showError={showError}
          submitting={submitting}
          updateAddress={update}
        />{' '}
        {error && !locationError ? (
          <div className="center-xs pt-1">
            <InputError visible={error || locationError !== null}>
              {error.message ? error.message : 'Address not found. Please enter a valid address.'}
            </InputError>
          </div>
        ) : locationError && !error ? (
          <div className="center-xs pt-1">
            <InputError visible={locationError && locationError !== null}>
              {locationError || 'Address not found. Please enter a valid address.'}
            </InputError>
          </div>
        ) : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { address = '', error, submitting } = state.locationSearch
  const locationSearch = state.locationSearch

  return {
    address,
    error,
    locationSearch,
    submitting,
  }
}

const mapDispatchToProps = {
  search,
  update,
}

LocationSearchContainer.propTypes = {
  loading: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSearchContainer)
