import { Box, InputError } from '@foodsby/nutrient'
import { TextField } from '@material-ui/core'
import { Formik } from 'formik'
import React from 'react'
import { object, string } from 'yup'
import ButtonSubmit from '../common/ButtonSubmit'
import Action from '../common/Action'

import { Submitting } from '../common/Submitting'

const schema = object().shape({
  password: string() // NOTE: Legacy passwords are 6, new ones are 8
    .required('Password is required.'),
  userName: string()
    .email('Enter a valid email address.')
    .required('Email is required.'),
})

const LoginForm = ({
  handleSubmit,
  isPasswordVisible,
  submitting,
  toggleVisibility,
  disablePasswordField,
}) => {
  return (
    <Formik
      gtm-data-elm-id="LoginForm"
      initialValues={{
        password: '',
        userName: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="row start-xs">
            <Box className="col-xs-12" mb={2}>
              <TextField
                error={Boolean(touched.userName && errors.userName)}
                fullWidth
                id="userName"
                label="Email"
                margin="dense"
                name="userName"
                onBlur={handleBlur}
                onChange={handleChange}
                tabIndex="1"
                type="email"
                value={values.userName}
                variant="outlined"
                color="secondary"
              />
              <InputError>{touched.userName && errors.userName}</InputError>
            </Box>
            <Box className="col-xs-12 _lr-hide" mb={2}>
              <TextField
                className="confirmPassword"
                error={Boolean(touched.password && errors.password)}
                fullWidth
                id="loginPassword"
                label="Password"
                margin="dense"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                tabIndex="2"
                type={isPasswordVisible ? 'text' : 'password'}
                value={values.password}
                variant="outlined"
                color="secondary"
                disabled={disablePasswordField}
              />
              <InputError>{touched.password && errors.password}</InputError>
              <div className="col end-xs showPassword">
                <Action
                  onClick={toggleVisibility}
                  style={{ marginBottom: '4px' }}
                  tabIndex="3"
                  type="button"
                >
                  {isPasswordVisible ? 'Hide' : 'Show'} Password
                </Action>
              </div>
            </Box>
            <Box className="col-xs-12 center-xs login-button-container" mb={2} mt={4}>
              {submitting ? (
                <Submitting />
              ) : (
                <ButtonSubmit
                  tabIndex="4"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={disablePasswordField}
                >
                  Log in
                </ButtonSubmit>
              )}
            </Box>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default LoginForm
