import { createSelector } from 'reselect'
import { selectHasTeam } from './userTeamSelectors'

// TODO Use this selector to show create team error
export const selectErrorCreatingTeam = createSelector(
  [state => state.teamOnboarding.errorCreatingTeam],
  errorPayload => {
    if (!errorPayload) {
      return undefined
    }

    if (errorPayload.status === 400 && errorPayload.message.includes('already exists')) {
      return 'This team name has already been taken. Please try another one.'
    }

    if (errorPayload.status === 409 && errorPayload.message.includes('already a member')) {
      return errorPayload.message
    }

    return 'Sorry, there was a problem creating a team. Please try again or contact support.'
  },
)

export const selectShouldLoadPotentialTeams = createSelector(
  [
    selectHasTeam,
    state => state.teamOnboarding.potentialTeams,
    state => state.teamOnboarding.isLoadingPotentialTeams,
    state => state.teamOnboarding.errorLoadingPotentialTeams,
  ],
  (hasTeam, potentialTeams, isLoadingPotentialTeams, errorLoadingPotentialTeams) =>
    !hasTeam && !potentialTeams && !isLoadingPotentialTeams && !errorLoadingPotentialTeams,
)
