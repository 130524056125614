import { cookies, jwt } from '@foodsby/webapp-jwt'

const AUTH_COOKIE_NAME = process.env.REACT_APP_AUTH_COOKIE_NAME
const AUTH_COOKIE_DOMAIN = process.env.REACT_APP_AUTH_COOKIE_DOMAIN

const USER_INFO_COOKIE_NAME = process.env.REACT_APP_COOKIE_NAME
const USER_INFO_COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN

const jwtDomains = process.env.REACT_APP_JWT_DOMAIN

export const LOCAL_STORAGE_ERROR =
  'Access to browser local storage was denied. This may be caused by overly strict browser security settings. Please ensure content settings are not blocked, and contact support if the issue persists.'

/**
 * Token Methods
 **/
export const initTokens = () => {
  jwt.initialize({
    cookieDomain: AUTH_COOKIE_DOMAIN,
    cookieName: AUTH_COOKIE_NAME,
    domains: jwtDomains,
    onStopImpersonation: () => {
      alert('Your session has ended.')
      window.close()
      window.location.reload()
    },
    secureCookie: process.env.NODE_ENV !== 'development',
  })
}

export const storeUserInfoCookie = user =>
  cookies.addUserInfoCookie({
    cookieName: USER_INFO_COOKIE_NAME,
    domain: USER_INFO_COOKIE_DOMAIN,
    user,
  })

export const getAccessToken = () => jwt.getAccessToken()
export const getRefreshToken = () => jwt.getRefreshToken()
export const getDecodedAccessToken = () => jwt.getDecodedAccessToken()
export const isAccessTokenExpired = () => jwt.isAccessTokenExpired()
export const storeTokens = tokens => jwt.storeTokens(tokens)
export const removeTokens = () => jwt.removeTokens()
export const isWithoutAccessToken = () => jwt.isWithoutAccessToken()
export const isImpersonatingUser = () => jwt.isImpersonatingUser()
