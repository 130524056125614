import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Box, Button, Typography } from '@material-ui/core'
import { format, parse } from 'date-fns'
import { themeConWeb } from '../../../util/modernThemeConweb'

const useStyles = makeStyles({
  offersBox: {
    background: `${themeConWeb.color.one}35`,
    display: 'flex',
    padding: '20px 50px',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default function CardAvailableOffers({ availableOffer, onOfferRedeemClicked }) {
  const classes = useStyles()
  const [expirationDate, setExpirationDate] = useState('')

  useEffect(() => {
    const expDate = parse(availableOffer.expiration)
    setExpirationDate(format(expDate, 'M/D/YYYY'))
  }, [availableOffer.expiration])

  const getOfferDescription = offer => {
    if (offer.totalLimit) {
      return `${
        offer.description && offer.description.slice(-1) === '.'
          ? offer.description.slice(0, -1)
          : offer.description
      }, up to $${offer.totalLimit / 100} in total.`
    }
    return offer.description
  }

  return (
    <Box className={classes.offersBox} marginY={3}>
      <Box flexGrow={1}>
        <Box mb={1}>
          <Typography variant="h6">You have an offer available!</Typography>
        </Box>
        <Typography variant="body2">{getOfferDescription(availableOffer)}</Typography>
        <Typography variant="body2">Redeem by {expirationDate}</Typography>
      </Box>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => onOfferRedeemClicked(availableOffer)}
      >
        Redeem
      </Button>
    </Box>
  )
}
