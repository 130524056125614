import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ButtonDialogClose from '../common/ButtonDialogClose'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      padding: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
      },
    },
  },
  listItem: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  listItemAvatar: {
    width: '140px',
    textAlign: 'center',
  },
  listItemText: {
    marginLeft: theme.spacing(2),
  },
  listItemPrimaryText: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  dialogAction: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
}))

// TODO Move this to common folder
const DialogValueProps = ({ title, description, valueProps, action, onClose }) => {
  const classes = useStyles()
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('xs'))

  return (
    <Dialog open maxWidth="sm" className={classes.root} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h5" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" align="center">
          {description}
        </Typography>
        <ButtonDialogClose onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        {valueProps.map((valueProp, index) => (
          <ListItem
            key={valueProp.id}
            className={classes.listItem}
            divider={index !== valueProps.length - 1}
          >
            {!mobileView && (
              <ListItemAvatar className={classes.listItemAvatar}>
                <img alt="" src={valueProp.image} height="100px" />
              </ListItemAvatar>
            )}
            <ListItemText
              className={classes.listItemText}
              primary={valueProp.primaryText}
              primaryTypographyProps={{
                className: classes.listItemPrimaryText,
                gutterBottom: true,
              }}
              secondary={valueProp.secondaryText}
            />
          </ListItem>
        ))}
      </DialogContent>
      <DialogActions className={classes.dialogAction}>{action}</DialogActions>
    </Dialog>
  )
}

export default DialogValueProps
