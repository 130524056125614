const LOCAL_STORAGE_KEY = 'hideRequestPreferred'

export const setHideRequestPreferred = userId => {
  const value = JSON.stringify({ userId, hide: true })
  localStorage.setItem(LOCAL_STORAGE_KEY, value)
}

const getHideRequestPreferred = () => {
  const stringValue = localStorage.getItem(LOCAL_STORAGE_KEY)
  return JSON.parse(stringValue)
}

export const userHidRequestPreferred = userId => {
  const hideOnboardingObj = getHideRequestPreferred()
  return hideOnboardingObj?.userId === userId && hideOnboardingObj?.hide
}
