import { format } from 'date-fns'

/*
 * Formats the birthday string that consists of month and day
 * to ISO 8601 date time format with 1900 as the default constant year.
 * @param birthdayStr (e.g '06/23')
 */
export const formatBirthdayToDateTime = birthdayStr => {
  if (!birthdayStr) {
    return undefined
  }

  return format(`${birthdayStr}/1900`, 'YYYY-MM-DDTHH:SS:SS')
}

/*
 * Formats the date time birthday to MM/DD string.
 * Returns an empty string if the input is not defined.
 * @param birthDateTime (e.g '1900-06-23T00:00:00')
 */
export const formatBirthdayToFormValue = birthDateTime => {
  if (!birthDateTime) {
    return ''
  }

  return format(birthDateTime, 'MM/DD')
}

/*
 * Validates if the birthday string is valid
 * by checking if the month and day portions of the string are valid.
 * @param birthdayStr (e.g '06/23')
 */
export const validateBirthday = birthdayStr => {
  if (!birthdayStr) {
    return true
  }

  const values = birthdayStr.split('/')
  const valueMonth = parseInt(values[0])
  const valueDay = parseInt(values[1])

  return valueMonth <= 12 && valueDay <= 31
}
