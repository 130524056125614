import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import { confirmation, length } from 'redux-form-validators/lib'

import { forgotPasswordRoute } from '../../routes/routes'
import { noSpacesAllowed } from '../../util/validators'
import Input from '../common/reduxForms/Input'

const ChangePasswordForm = ({ completeResetError, handleSubmit, showSuccess, submitting }) => {
  const showDirections = !(showSuccess || completeResetError)
  return (
    <form onSubmit={handleSubmit}>
      <Typography component="h1" variant="h4">
        Reset Password
      </Typography>
      {showDirections && (
        <Typography variant="body1">
          To complete your password change please enter your new password and press the Create New
          Password button.
        </Typography>
      )}
      {completeResetError && (
        <Typography variant="body1">
          Change Password token is invalid or expired. To try again click this link:{' '}
          <Link to={forgotPasswordRoute.path}>Reset Password</Link>.
        </Typography>
      )}
      <Box textAlign="left">
        {showSuccess ? (
          <Box textAlign="center">
            <Typography variant="body1">
              Your Password has been successfully changed. Use below link to log in.
            </Typography>
          </Box>
        ) : (
          <>
            <Field
              component={Input}
              id="password"
              label="Password *"
              name="password"
              type="password"
              validate={[noSpacesAllowed, length({ minimum: 8 })]}
            />
            <Field
              component={Input}
              id="confirmPassword"
              label="Verify Password *"
              name="confirmPassword"
              type="password"
              validate={[
                noSpacesAllowed,
                confirmation({
                  field: 'password',
                  fieldLabel: 'Password',
                }),
              ]}
            />
          </>
        )}
        <Box marginTop={3} textAlign="center">
          {!showSuccess && (
            <Button
              color="primary"
              disabled={showSuccess || submitting}
              disableElevation
              startIcon={submitting && <CircularProgress size={20} />}
              type="submit"
              variant="contained"
            >
              Create New Password
            </Button>
          )}
        </Box>
      </Box>
    </form>
  )
}
export default ChangePasswordForm
