import { api } from '../api/api'
import { pascalcaseKeys } from '../util/keys'

export const API_MONO = '/api-monolith'

export const getCheckoutCartApi = (dropoffId, skipDeliveryCalc = false) => {
  return api.get(`${API_MONO}/cart/dropoff/${dropoffId}/full?skipDeliveryCalc=${skipDeliveryCalc}`)
}

export const getOrderForEditApi = (hash, userId) =>
  api.get(`${API_MONO}/v2/order-items/latest?hash=${hash}${userId ? `&userId=${userId}` : ''}`)

export const getOrderFromCartForEditApi = orderItemId =>
  api.get(`${API_MONO}/v2/orderItems/${orderItemId}`)

export const updateOrderItemApi = (orderItemId, item) =>
  api.put(`${API_MONO}/order/items/${orderItemId}`, pascalcaseKeys(item))
