import { InputError } from '@foodsby/nutrient'
import { themeConWeb } from '@app/util/modernThemeConweb'
import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import { confirmation, email, required } from 'redux-form-validators/lib'

import { loginRoute } from '../../routes/routes'
import { noSpacesAllowed } from '../../util/validators'
import Input from '../common/reduxForms/Input'

const ForgotPasswordForm = ({ error, handleSubmit, showSuccess, submitting }) => (
  <form onSubmit={handleSubmit}>
    <Box marginBottom={3} textAlign="center">
      <Typography component="h1" variant="h4">
        Reset Password
      </Typography>
      {!showSuccess && (
        <Typography variant="body1">
          Please enter your email address to receive instructions to reset your password.
        </Typography>
      )}
      <InputError>{error}</InputError>
    </Box>
    {showSuccess ? (
      <Box textAlign="center">
        <Typography variant="body1">
          An email has been sent. Please check your email and follow the link provided there.
        </Typography>
      </Box>
    ) : (
      <>
        <Box marginBottom={3}>
          <Field
            component={Input}
            label="Email"
            name="email"
            type="email"
            validate={[noSpacesAllowed, required({ msg: `Email is required` }), email()]}
          />
        </Box>
        <Box marginBottom={3}>
          <Field
            component={Input}
            label="Confirm Email"
            name="confirmEmail"
            type="email"
            validate={[noSpacesAllowed, confirmation({ field: 'email' })]}
          />
        </Box>
      </>
    )}
    {!showSuccess && (
      <Box textAlign="center">
        <Button
          color="primary"
          disabled={showSuccess || submitting}
          disableElevation
          startIcon={submitting && <CircularProgress size={20} />}
          type="submit"
          variant="contained"
        >
          Reset Password
        </Button>
      </Box>
    )}
    <Box marginTop={3} textAlign="center">
      <Link to={loginRoute.path}>
        <span className="icon-user" style={{ color: themeConWeb.color.greyShade3 }} /> Log In
      </Link>
    </Box>
  </form>
)
export default ForgotPasswordForm
