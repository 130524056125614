import { createSelector } from 'reselect'
import { isEmpty, isNil } from 'lodash'

import { isTeamAdmin, getFullName } from '../../components/teams/admin/adminUtils'
import { ROWS_PER_PAGE } from '../../util/constants'
import { selectTeam } from './teamSelectors'

export const selectTeamMembers = createSelector(
  [state => state.teamMembers.teamMembers, selectTeam],
  (teamMembers, team) =>
    team
      ? teamMembers.map(member => ({
          ...member,
          isAdmin: isTeamAdmin(team.externalManagers, member.userId),
        }))
      : [],
)

export const selectActionableMembers = createSelector(
  [state => state.teamMembers.actionableMembers],
  actionableMembers => actionableMembers,
)

export const selectRemovableMembers = createSelector(
  [selectActionableMembers],
  actionableMembers => ({
    members: actionableMembers.filter(m => !m.isAdmin),
    containsAdmin: Boolean(actionableMembers.find(m => m.isAdmin)),
  }),
)

export const selectFilteredMembers = createSelector(
  [selectTeamMembers, state => state.teamMembers.search],
  (teamMembers, search) => teamMembers.filter(value => filterByNameAndEmail(value, search)),
)

export const selectMembersRows = createSelector(
  [selectFilteredMembers, state => state.teamMembers.sort, state => state.teamMembers.page],
  (filteredMembers, sort, page) => {
    const sortedMembers = stableSort(filteredMembers, getComparator(sort.field, sort.direction))
    const pagedMembers = sortedMembers.slice(
      page * ROWS_PER_PAGE,
      page * ROWS_PER_PAGE + ROWS_PER_PAGE,
    )

    return pagedMembers
  },
)

export const selectMemberInviteMessages = createSelector(
  [memberInviteResults => memberInviteResults],
  memberInviteResults => {
    let result = []

    if (!memberInviteResults) {
      return result
    }

    let successfulEmails = []
    let existingEmails = []
    let otherErroredEmails = []

    memberInviteResults.forEach(result => {
      if (result.status < 300) {
        successfulEmails.push(result.username)
      } else if (result.status === 400) {
        existingEmails.push(result.username)
      } else {
        otherErroredEmails.push(result.username)
      }
    })

    if (!isEmpty(successfulEmails)) {
      result.push(`Successfully invited ${successfulEmails.length} user(s).`)
    }

    if (!isEmpty(existingEmails)) {
      const plural = existingEmails.length > 1
      result.push(
        `${existingEmails.join(', ')} ${plural ? 'are' : 'is'} already a member of a team.`,
      )
    }

    if (!isEmpty(otherErroredEmails)) {
      result.push(
        `Something went wrong when inviting ${otherErroredEmails.join(
          ', ',
        )}. Please try again or contact support.`,
      )
    }

    return result
  },
)

// ------------------------------------
// Private methods
// ------------------------------------
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const descendingComparator = (a, b, field) => {
  // TODO Handle date comparison here
  if (b[field] < a[field]) {
    return -1
  }
  if (b[field] > a[field]) {
    return 1
  }

  return 0
}

const getComparator = (field, direction) => {
  return direction === 'desc'
    ? (a, b) => descendingComparator(a, b, field)
    : (a, b) => -descendingComparator(a, b, field)
}

const filterByNameAndEmail = (member, search) => {
  if (isNil(search)) {
    return true
  }

  const stringToCompare = `${getFullName(member)} ${member.email}`.toLowerCase()
  return stringToCompare.includes(search.toLowerCase())
}
