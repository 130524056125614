import { Box, Card, Fab, Modal, Button } from '@material-ui/core'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import { Skeleton } from '@material-ui/lab'
import format from 'date-fns/format'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { push } from 'connected-react-router'
import PersonalizeOrder from '../components/personalize-order/PersonalizeOrder'
import {
  Menu,
  MenuPastOrders,
  MenuPopularItems,
  NavRestaurantCategories,
  OrderSidebar,
  ValidationModal,
} from '../components/place-order'
import RestaurantDetails from '../components/common/RestaurantDetails'
import {
  addItemToCartStart,
  cancelEditingOrder,
  editOrderInCart,
  loadEditOrder,
  loadOrderCartStart,
  removeItemFromOrderCartStart,
} from '../redux/modules/cart'
import { loadDeliveryDropoffStart, loadDeliveryRewardsStart } from '../redux/modules/delivery'
import { loadFavoriteStart, loadPopularMenuItems } from '../redux/modules/favorite'
import { getFriendsOrdersStart } from '../redux/modules/friends'
import { clearMenuItem, loadMenuStart, selectMenuItem } from '../redux/modules/menu'
import { addPastOrderToCart, loadPastOrdersStart } from '../redux/modules/pastorder'
import {
  getMenuInfoForNewPopularItems,
  getMenuInfoForPopularItems,
} from '../redux/modules/selectors'
import { pushAnalyticsEvent } from '../util/gtmUtils'
import AuthedComponent from '../components/common/AuthedComponent'
import LayoutInnerPage, { HeaderInnerPage } from '../layouts/LayoutInnerPage'
import { formatUrl, formatRewardsPercentage } from '../util/formatUtils'
import { loadDeliveryDropoffRewardsStart } from '../redux/modules/deliveryDropoffRewards'
import { checkoutRoute, locationRoute } from '../routes/routes'
import { loadOfficesStart } from '../redux/modules/offices'
import { selectOfficeAccounts } from '../redux/selectors/accountSelectors'
import { resetCheckoutError } from '../redux/modules/checkout'

export class PagePlaceOrder extends Component {
  constructor(props) {
    super(props)
    const { clearMenuItem } = this.props
    this.state = {
      openMobileOrderCart: false,
      isMobile: window.innerWidth <= 960,
    }
    clearMenuItem()
    this.handleClose = this.handleClose.bind(this)
    this.handleOpenMobileOrderCart = this.handleOpenMobileOrderCart.bind(this)
    this.handleCloseMobileOrderCart = this.handleCloseMobileOrderCart.bind(this)
  }

  updateWindowDimensions = () => {
    this.setState({ isMobile: window.innerWidth <= 960 })
  }

  handleOpenMobileOrderCart() {
    this.setState({ openMobileOrderCart: true })
  }

  handleCloseMobileOrderCart() {
    this.setState({ openMobileOrderCart: false })
  }

  handleClose() {
    this.props.clearMenuItem()
    this.props.cancelEditingOrder()
  }

  getDropoffIdAndLocationId = () => {
    let {
      match: {
        params: { dropoffId = 0, locationId = 0 },
      },
    } = this.props
    dropoffId = Number(dropoffId)
    locationId = Number(locationId)
    return { dropoffId, locationId }
  }

  handleAddItemsToCart = (menuItemId, itemValues, checkout) => {
    const { dropoffId, locationId } = this.getDropoffIdAndLocationId()
    const { addItemToCartStart } = this.props
    addItemToCartStart(locationId, menuItemId, dropoffId, itemValues, checkout)
  }

  handleEditOrder = (orderItemId, menuItemId, itemValues, checkout) => {
    const { dropoffId, locationId } = this.getDropoffIdAndLocationId()
    const { resetCheckoutError, editOrderInCart } = this.props

    checkout && resetCheckoutError()

    editOrderInCart(locationId, menuItemId, dropoffId, itemValues, orderItemId, true, checkout)
  }

  handleNext = () => {
    const { push } = this.props
    const { dropoffId, locationId } = this.getDropoffIdAndLocationId()

    push(
      formatUrl(checkoutRoute.path, {
        dropoffId: dropoffId,
        locationId: locationId,
      }),
    )
  }

  handleRemoveItemFromCart = orderItemId => {
    const { dropoffId } = this.getDropoffIdAndLocationId()
    const { removeItemFromOrderCartStart } = this.props

    removeItemFromOrderCartStart(orderItemId, dropoffId)
  }

  componentDidMount() {
    window && window.scrollTo(0, 0)
    const {
      loadDeliveryDropoffStart,
      loadFavoriteStart,
      loadMenuStart,
      loadOfficesStart,
      loadOrderCartStart,
      loadPastOrdersStart,
      loadPopularMenuItems,
      loadDeliveryRewardsStart,
      loadDeliveryDropoffRewardsStart,
      selectedDate,
    } = this.props

    let {
      match: {
        params: { dropoffId = 0, locationId = 0 },
      },
    } = this.props
    dropoffId = Number(dropoffId)
    locationId = Number(locationId)
    window.addEventListener('resize', this.updateWindowDimensions)

    if (locationId && dropoffId) {
      loadMenuStart(dropoffId)
      loadDeliveryDropoffStart(dropoffId)
      loadOrderCartStart(dropoffId)
      loadPopularMenuItems()
      loadPastOrdersStart(dropoffId)
      loadFavoriteStart()
      loadOfficesStart(locationId)
      loadDeliveryRewardsStart(locationId, selectedDate)
      loadDeliveryDropoffRewardsStart(dropoffId)
    }
    pushAnalyticsEvent('Ordering', 'View Menu', undefined, dropoffId)
  }

  componentDidUpdate(prevProps) {
    const {
      deliveryDropoff,
      getFriendsOrdersStart,
      isAuthenticated,
      loadDeliveryDropoffStart,
      loadFavoriteStart,
      loadMenuStart,
      loadOrderCartStart,
      loadPastOrdersStart,
      loadDeliveryDropoffRewardsStart,
      nextRewardsPercentage,
    } = this.props
    let {
      match: {
        params: { dropoffId = 0, locationId = 0 },
      },
    } = this.props
    dropoffId = Number(dropoffId)
    locationId = Number(locationId)

    if (dropoffId !== Number(prevProps.match.params.dropoffId)) {
      loadMenuStart(dropoffId)
      loadDeliveryDropoffStart(dropoffId)
      loadOrderCartStart(dropoffId)
    }
    if (isAuthenticated !== prevProps.isAuthenticated) {
      loadPastOrdersStart(dropoffId)
      loadFavoriteStart()
      loadMenuStart(dropoffId)
      loadDeliveryDropoffStart(dropoffId)
      loadOrderCartStart(dropoffId)
    }
    if (
      isAuthenticated &&
      deliveryDropoff &&
      prevProps.deliveryDropoff &&
      (deliveryDropoff.deliveryDateTime !== prevProps.deliveryDropoff.deliveryDateTime ||
        locationId !== Number(prevProps.match.params.locationId))
    ) {
      getFriendsOrdersStart(locationId, format(deliveryDropoff.deliveryDateTime, 'YYYY-MM-DD'))
    }
    if (nextRewardsPercentage === undefined) {
      loadDeliveryDropoffRewardsStart(dropoffId)
    }
  }

  componentWillUnmount() {
    this.props.cancelEditingOrder()
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const {
      isAuthenticated,
      addPastOrderToCart,
      deliveryDropoff,
      deliveryError,
      friendHasOrdered,
      isDeliveryDropoffLoading,
      isLoadingRewards,
      isEditingFromMenu,
      isEditingOrder,
      isEditingPastOrder,
      isMenuLoading,
      isOrderCartLoading,
      isPastOrderLoading,
      isWebpSupported,
      loadEditOrder,
      menu,
      menuItemForEdit,
      menuItemId,
      newPopularItems,
      pastOrderError,
      pastOrders,
      selectMenuItem,
      nextRewardsPercentage,
      isLoadingDeliveryDropoffRewards,
      orderCart,
    } = this.props

    let {
      match: {
        params: { locationId = 0 },
      },
    } = this.props

    locationId = Number(locationId)

    const { openMobileOrderCart, isMobile } = this.state

    let orderItemCount = orderCart?.orderItems?.length

    const rewardsPercentage = formatRewardsPercentage(nextRewardsPercentage)

    return (
      <AuthedComponent isAuthenticated={isAuthenticated} locationId={locationId}>
        <LayoutInnerPage
          HeaderComponent={
            <>
              {isMobile && (
                <>
                  <Fab
                    style={{
                      position: 'fixed',
                      zIndex: 1000,
                      backgroundColor: '#bb2f1c',
                      color: 'white',
                      margin: 0,
                      top: 75,
                      right: 20,
                      bottom: 'auto',
                      left: 'auto',
                    }}
                    onClick={this.handleOpenMobileOrderCart}
                  >
                    <ShoppingCartOutlinedIcon />
                    <span style={{ marginLeft: 5 }}>{orderItemCount}</span>
                  </Fab>
                  <Modal open={openMobileOrderCart} onClose={this.handleCloseMobileOrderCart}>
                    <div style={{ maxHeight: '80vh', paddingTop: '80px' }}>
                      <Button
                        style={{
                          position: 'fixed',
                          color: 'black',
                          margin: 10,
                          right: 10,
                          top: 95,
                          zIndex: 1001,
                        }}
                        onClick={this.handleCloseMobileOrderCart}
                      >
                        <ClearOutlinedIcon />
                      </Button>
                      <OrderSidebar
                        nextButtonDisplayText={'Continue to checkout'}
                        handleClose={this.handleClose}
                        handleNext={this.handleNext}
                        handleEditOrder={this.handleEditOrder}
                        handleAddItemsToCart={this.handleAddItemsToCart}
                        handleRemoveItemFromCart={this.handleRemoveItemFromCart}
                        rewardsPercentage={rewardsPercentage}
                        isLoadingDeliveryDropoffRewards={isLoadingDeliveryDropoffRewards}
                        isLoadingRewards={isLoadingRewards}
                      />
                    </div>
                  </Modal>
                </>
              )}
              <HeaderInnerPage
                showBackButton
                backButtonRoute={formatUrl(locationRoute.path, { locationId })}
              >
                Back
              </HeaderInnerPage>
            </>
          }
          MainComponent={
            <Card>
              <Box marginBottom={4}>
                <Box marginY={3}>
                  <RestaurantDetails
                    logoLink={deliveryDropoff.logo}
                    name={deliveryDropoff.storeName}
                    isCatering={false}
                    isReserve={false}
                    loading={isDeliveryDropoffLoading}
                  />
                </Box>
                <NavRestaurantCategories
                  deliveryDropoff={deliveryDropoff}
                  isMenuLoading={isMenuLoading}
                />
              </Box>
              {isMenuLoading ||
              isDeliveryDropoffLoading ||
              isMenuLoading ||
              isOrderCartLoading ||
              isLoadingRewards ? (
                <>
                  <LoadingState />
                  <LoadingState />
                </>
              ) : (
                <>
                  {/* Show past orders if you have them */}
                  {pastOrders && pastOrders.length > 0 && (
                    <MenuPastOrders
                      {...{
                        addPastOrderToCart,
                        editPastOrder: (
                          hash,
                          orderItemId,
                          isEditingPastOrder,
                          userId,
                          menuItemId,
                        ) => {
                          loadEditOrder(hash, orderItemId, isEditingPastOrder, userId, menuItemId)
                        },
                        isPastOrderLoading,
                        pastOrderError,
                        pastOrders,
                      }}
                    />
                  )}
                  {/* Show popular items if your building has */}
                  {newPopularItems && newPopularItems.length >= 1 && (
                    <MenuPopularItems
                      onSelectMenuItem={selectMenuItem}
                      popularItems={newPopularItems}
                    />
                  )}
                  <Menu
                    friendsHasOrdered={
                      friendHasOrdered &&
                      friendHasOrdered.filter(
                        order => order.dropoffId === Number(this.props.match.params.dropoffId),
                      )[0]?.friendsOrders
                    }
                    isWebpSupported={isWebpSupported}
                    menuData={menu}
                    onSelectMenuItem={selectMenuItem}
                  />
                </>
              )}
            </Card>
          }
          SidebarComponent={
            !isMobile && (
              <OrderSidebar
                nextButtonDisplayText={'Continue to checkout'}
                handleClose={this.handleClose}
                handleNext={this.handleNext}
                handleEditOrder={this.handleEditOrder}
                handleAddItemsToCart={this.handleAddItemsToCart}
                handleRemoveItemFromCart={this.handleRemoveItemFromCart}
                rewardsPercentage={rewardsPercentage}
                isLoadingDeliveryDropoffRewards={isLoadingDeliveryDropoffRewards}
                isLoadingRewards={isLoadingRewards}
              />
            )
          }
        />
        {/* Global Page Components */}
        {menuItemId && !isEditingPastOrder && !isEditingOrder && (
          <PersonalizeOrder
            handleClose={this.handleClose}
            handleAddItemsToCart={this.handleAddItemsToCart}
            handleEditOrder={this.handleEditOrder}
            menuId={menu && menu.menuId}
            menuItemForEdit={menuItemForEdit}
            menuItemId={menuItemId}
          />
        )}
        {isEditingPastOrder && menuItemForEdit && isEditingOrder && (
          <PersonalizeOrder
            handleClose={this.handleClose}
            handleAddItemsToCart={this.handleAddItemsToCart}
            handleEditOrder={this.handleEditOrder}
            isEditingFromMenu={isEditingFromMenu}
            isEditingOrder={isEditingOrder}
            menuId={menu.menuId}
            menuItemForEdit={menuItemForEdit}
            menuItemId={menuItemForEdit.menuItemId}
          />
        )}
        {deliveryError && (
          <ValidationModal locationId={locationId} serverMessage={deliveryError?.data} />
        )}
      </AuthedComponent>
    )
  }
}

// should these live in the Page components or in the child?
const LoadingState = () => {
  return (
    <Box>
      <Box display="flex">
        <Skeleton height={80} width={250} />
      </Box>
      <Skeleton height={3} />
      <Box display="flex" justifyContent="space-between">
        <Skeleton height={50} width={150} />
        <Skeleton height={40} width={120} />
      </Box>
      <Skeleton />
      <Skeleton />
      <Skeleton width={200} />
      <Skeleton height={3} />
      <Box display="flex" justifyContent="space-between">
        <Skeleton height={50} width={150} />
        <Skeleton height={40} width={120} />
      </Box>
      <Skeleton />
      <Skeleton />
      <Skeleton width={200} />
      <Skeleton height={3} />
      <Box display="flex" justifyContent="space-between">
        <Skeleton height={50} width={150} />
        <Skeleton height={40} width={120} />
      </Box>
      <Skeleton />
      <Skeleton />
      <Skeleton width={200} />
      <Skeleton height={3} />
      <Box display="flex" justifyContent="space-between">
        <Skeleton height={50} width={150} />
        <Skeleton height={40} width={120} />
      </Box>
      <Skeleton />
      <Skeleton />
      <Skeleton width={200} />
    </Box>
  )
}

const mapStateToProps = state => {
  const { isMenuLoading, isOrderCartLoading, menu, menuItemId } = state.menu
  const {
    deliveryDropoff,
    isDeliveryDropoffLoading,
    selectedDate,
    rewards,
    isLoadingRewards,
  } = state.delivery
  const { nextRewardsPercentage, isLoadingDeliveryDropoffRewards } = state.deliveryDropoffRewards
  const { errorMessage: pastOrderError, isPastOrderLoading, pastOrders } = state.pastorder
  const {
    isEditingFromMenu,
    isEditingOrder,
    isEditingPastOrder,
    menuItemForEdit,
    orderCart,
  } = state.cart
  const { friendHasOrdered, isLoadingFriendsOrders } = state.friends
  const { isLoadingFavorites, isLoadingNewPopularMenuItems, isLoadingPopularItems } = state.favorite
  const { currentUser, isAuthenticated, isCurrentUserLoading } = state.user
  const { location } = state.location
  const popularItems = getMenuInfoForPopularItems(state)
  const newPopularItems = getMenuInfoForNewPopularItems(state)
  const deliveryError = state.delivery.errorLoading
  const orderError = state.menu.error
  const { isWebpSupported } = state.browser
  const offices = selectOfficeAccounts(state)

  return {
    currentUser,
    deliveryDropoff,
    deliveryError,
    friendHasOrdered,
    isAuthenticated,
    isCurrentUserLoading,
    isDeliveryDropoffLoading,
    isEditingFromMenu,
    isEditingOrder,
    isEditingPastOrder,
    isLoadingFavorites,
    isLoadingFriendsOrders,
    isLoadingNewPopularMenuItems,
    isLoadingPopularItems,
    isMenuLoading,
    isOrderCartLoading,
    isPastOrderLoading,
    isWebpSupported,
    menu,
    menuItemForEdit,
    menuItemId,
    newPopularItems,
    orderError,
    pastOrderError,
    pastOrders,
    popularItems,
    offices,
    location,
    selectedDate,
    rewards,
    isLoadingRewards,
    nextRewardsPercentage,
    isLoadingDeliveryDropoffRewards,
    orderCart,
  }
}

const mapDispatchToProps = {
  addPastOrderToCart,
  cancelEditingOrder,
  clearMenuItem,
  getFriendsOrdersStart,
  loadDeliveryDropoffStart,
  loadEditOrder,
  loadFavoriteStart,
  loadMenuStart,
  loadOrderCartStart,
  loadPastOrdersStart,
  loadPopularMenuItems,
  selectMenuItem,
  loadOfficesStart,
  addItemToCartStart,
  removeItemFromOrderCartStart,
  editOrderInCart,
  loadDeliveryRewardsStart,
  loadDeliveryDropoffRewardsStart,
  push,
  resetCheckoutError,
}

export default connect(mapStateToProps, mapDispatchToProps)(PagePlaceOrder)
