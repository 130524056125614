import pluralize from 'pluralize'
import React from 'react'
import { addValidator } from 'redux-form-validators'

export const valueRequired = value => {
  if (!value) {
    return ' is required.'
  }
}

export const noSpacesAllowed = value => {
  if (value && value.trim() !== value) {
    return 'Value cannot begin or end with a space.'
  }
}

export const arrayMinLength = addValidator({
  defaultMessage: '',
  validator: function(options, value) {
    if (options.length > 0 && value.length < options.length) {
      return {
        defaultMessage: `: Minimum ${pluralize('selection', options.length, true)} required.`,
        id: 'form.errors.arrayMinLength',
      }
    }
  },
})

export const arrayMaxLength = addValidator({
  defaultMessage: '',
  validator: function(options, value) {
    if (options.length > 0 && value.length > options.length) {
      return {
        defaultMessage: `: Maximum ${pluralize('selection', options.length, true)} allowed.`,
        id: 'form.errors.arrayMaxLength',
      }
    }
  },
})

export const validAutocomplete = value => {
  if (value) {
    if (value.toString().trim() === '') {
      return 'Required'
    }
    if (!isNaN(Number(value))) {
      return undefined
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) && isNaN(Number(value))) {
      return 'Please enter a valid email address'
    }
  }
  return undefined
}

// To use properly you must create validator outside of render, these are helpers for that
export const minSelect = value => arrayMinLength({ length: value })
export const maxSelect = value => arrayMaxLength({ length: value })

// Image Validator - returns a string for any failed validation, otherwise returns null
export const validProfileImage = image => {
  const supportedTypes = ['image/png', 'image/jpg', 'image/jpeg']
  if (!image) {
    return (
      <div>
        Our bad! <br />
        Thanks for &quot;pudding&quot; up with us. We&apos;re experiencing an internal error. Please
        try again or with a new image.
      </div>
    )
  }
  if (image.size < 1000) {
    return (
      <div>
        Son of a biscuit! <br />
        That file is too small. Upload a larger photo no smaller than 1 KB.
      </div>
    )
  }
  if (image.size > 4000000) {
    return (
      <div>
        Son of a biscuit! <br />
        That file is too big. Upload a smaller photo no larger than 4 MB.
      </div>
    )
  }
  if (!supportedTypes.includes(image.type)) {
    return (
      <div>
        Shucks{' '}
        <span aria-label="Corn" role="img">
          🌽
        </span>
        ! <br />
        We only support JPGs and PNGs.
      </div>
    )
  }

  let img = new Image()
  let err
  img.onload = () => {
    const height = img.height
    const width = img.width

    if (height < 180 || width < 180) {
      err = (
        <div>
          Son of a biscuit! <br />
          That file is too small. Please upload a larger photo, at least 180px for both dimensions.
        </div>
      )
    }
    if (height > 5000 || width > 5000) {
      err = (
        <div>
          Son of a biscuit! <br />
          That file is too large. Please upload a smaller photo, at most 5000px for both dimensions.
        </div>
      )
    }
  }
  img.url = 'test.jpg'
  if (err) {
    return err
  }

  return null
}

export const validateCcExpiration = value => {
  if (!value) {
    return false
  }

  const now = new Date()
  const nowMonth = now.getMonth() + 1 // getMonth() returns months from 0 through 11
  const nowYear = parseInt(
    // get the last two digits of the full year
    now
      .getFullYear()
      .toString()
      .substr(-2),
  )

  const [expMonth, expYear] = value.split('/')
  const valueMonth = parseInt(expMonth)
  const valueYear = parseInt(expYear)

  return (
    valueMonth <= 12 && (valueYear > nowYear || (valueYear === nowYear && valueMonth >= nowMonth))
  )
}
