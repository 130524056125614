import React from 'react'
import { Grid, useMediaQuery } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router'

import CardPerk from './CardPerk'
import CardTeamSubscription from './CardTeamSubscription'

const StretchedGrid = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}))(Grid)

const GridCardContainer = ({ children }) => {
  return (
    <StretchedGrid item xs={12} md={4} component="li">
      {children}
    </StretchedGrid>
  )
}

const PerkCards = ({ subscribed }) => {
  const history = useHistory()
  const isMediumView = useMediaQuery(theme => theme.breakpoints.down('md'))

  return (
    <Grid container spacing={isMediumView ? 1 : 4} component="ul">
      {subscribed && (
        <GridCardContainer>
          <CardTeamSubscription />
        </GridCardContainer>
      )}
      <GridCardContainer>
        <CardPerk
          title="Recurring"
          description="Set a daily, weekly, or monthly budget for members to use on Foodsby."
          buttonText="Add perk"
          onClick={() => history.push('perks/recurring-perk')}
        />
      </GridCardContainer>
      <GridCardContainer>
        <CardPerk
          title="One-Time"
          description="Perfect for celebrating birthdays, wins, recognizing hard work, or making someone's day. "
          buttonText="Send perk"
          onClick={() => history.push('perks/one-time-perk')}
        />
      </GridCardContainer>
    </Grid>
  )
}

export default PerkCards
