import React from 'react'
import { Box } from '@material-ui/core'
import { RemoveCircleOutline } from '@material-ui/icons'

import ToolboxAction from '../common/ToolboxAction'

const ToolboxMemberActions = ({ onShowRemoveDialog }) => {
  return (
    <>
      <Box display="flex" flexDirection="row">
        <Box marginLeft={4}>
          <ToolboxAction
            Icon={<RemoveCircleOutline style={{ color: 'white' }} />}
            label="Remove from team"
            onClick={onShowRemoveDialog}
          />
        </Box>
      </Box>
    </>
  )
}

export default ToolboxMemberActions
