import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { InfoOutlined } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  listItem: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
}))

const ListActionableDetails = ({ listItems, handleClick, activeId }) => {
  const classes = useStyles()
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('md'))

  return (
    <List>
      {listItems.map(item => {
        return (
          <ListItem key={item.id} className={classes.listItem}>
            {item.loading && <Skeleton variant="rect" height="50px" width="100%" />}
            {!item.loading && (
              <>
                {item.image && <ListItemAvatar>{item.image}</ListItemAvatar>}
                <Box marginRight={2}>
                  <Box display="flex">
                    <Typography display="block">{item.title}</Typography>
                    {item.infoText && (
                      <Box display="flex" alignItems="center" marginLeft={2}>
                        <Tooltip title={item.infoText} arrow>
                          <InfoOutlined fontSize="small" />
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                  <Typography display="block" variant="body2" color="textSecondary">
                    {item.secondaryText}
                  </Typography>
                  {mobileView && (
                    <ButtonListItemAction
                      item={item}
                      activeId={activeId}
                      handleClick={handleClick}
                    />
                  )}
                </Box>
                {!mobileView && (
                  <ListItemSecondaryAction>
                    <ButtonListItemAction
                      item={item}
                      activeId={activeId}
                      handleClick={handleClick}
                    />
                  </ListItemSecondaryAction>
                )}
              </>
            )}
          </ListItem>
        )
      })}
    </List>
  )
}

const ButtonListItemAction = ({ item, activeId, handleClick }) => {
  if (!handleClick || !item.buttonText) {
    return null
  }

  return (
    <Button
      variant="contained"
      color={activeId === item.id ? 'primary' : 'default'}
      disabled={!!item.disabled}
      onClick={() => handleClick(item)}
    >
      {item.buttonText}
    </Button>
  )
}

ListActionableDetails.defaultProps = {
  listItems: [],
}

ListActionableDetails.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      step: PropTypes.number,
      title: PropTypes.string.isRequired,
      secondaryText: PropTypes.string,
      image: PropTypes.element,
      buttonText: PropTypes.string,
      infoText: PropTypes.string,
    }),
  ),
  activeId: PropTypes.string,
  handleClick: PropTypes.func,
}

export default ListActionableDetails
