import React from 'react'
import { Box, Card, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  cancelEditingOrder,
  editCateringOrderInCart,
  editOrderInCart,
  loadEditOrder,
  removeItemFromCheckoutCartStart,
} from '../../redux/modules/cart'
import { clearMenuItem, selectMenuItem } from '../../redux/modules/menu'
import {
  selectCurrentLocationWithAddress,
  selectMenuItemForEdit,
} from '../../redux/modules/selectors'
import { placeCateringOrderRoute, placeOrderRoute } from '../../routes/routes'
import { formatUrl } from '../../util/formatUtils'
import { CheckoutCart } from '../cart/Cart'
import Captcha from '../common/Captcha'
import PersonalizeOrder from '../personalize-order/PersonalizeOrder'
import ButtonSubmit from '../common/ButtonSubmit'
import { resetCheckoutError } from '../../redux/modules/checkout'
import RestaurantDetailsSidebar from '../common/RestaurantDetailsSidebar'
import CheckoutError from './CheckoutError'

const useStyles = makeStyles(theme => ({
  card: {
    padding: '0',
  },
}))

const CheckoutSidebar = ({
  //props
  deliveryType,
  showRestaurantLogo,
  enableQuantity,
  rewardsPercentage,
  isLoadingDeliveryDropoffRewards,

  //redux
  checkoutCart,
  checkoutCartError,
  errorCheckingOut,
  couponUsage,
  creditUsage,
  deliveryDropoff,
  isAvailableCreditsLoading,
  isCheckoutCartLoading,
  isDeliveryDropoffLoading,
  isCartSaving,
  isCheckoutProcessing,
  isEditingOrder,
  isLoadingOrderForEdit,
  location,
  menuItemForEdit,
  normalizedMenuItem,
  programUsage,
  storedCurrencyUsage,
  removeItemFromCheckoutCartStart,
  selectMenuItem,
  clearMenuItem,
  loadEditOrder,
  editOrderInCart,
  editCateringOrderInCart,
  cancelEditingOrder,
  resetCheckoutError,
}) => {
  const classes = useStyles()

  const handleClose = () => {
    clearMenuItem()
    cancelEditingOrder()
  }

  const handleEditOrder = (orderItemId, menuItemId, itemValues, checkout) => {
    const isEditingFromMenu = false
    checkout && resetCheckoutError()

    editOrderInCart(
      location.deliveryLocationId,
      menuItemId,
      deliveryDropoff.dropoffId,
      itemValues,
      orderItemId,
      isEditingFromMenu,
      checkout,
    )
  }

  const handleDeleteMenuItem = orderItemId => {
    removeItemFromCheckoutCartStart(orderItemId, deliveryDropoff.dropoffId)
  }

  const hasNoItem = checkoutCartError?.message?.indexOf('No items') > -1
  const isLoading =
    isCheckoutCartLoading ||
    isAvailableCreditsLoading ||
    isDeliveryDropoffLoading ||
    isLoadingDeliveryDropoffRewards

  const isCatering = deliveryType === 'Catering'

  const changeInvalidError = error => {
    if (error === 'Invalid account.') {
      error = 'There was a problem checking out with your credit card. Please contact support.'
    }
    return error
  }

  return (
    <>
      <Card className={classes.card}>
        {isLoading && <SkeletonLoading />}

        {!isLoading && deliveryDropoff?.logo && deliveryDropoff?.storeName && (
          <>
            <Box paddingLeft={3} paddingTop={3}>
              <Typography color="textSecondary" gutterBottom variant="overline">
                Restaurant
              </Typography>
            </Box>
            <Box paddingLeft={3} paddingTop={3}>
              <RestaurantDetailsSidebar
                logoLink={deliveryDropoff.logo}
                name={deliveryDropoff.storeName}
              />
            </Box>
            <Box paddingLeft={3} paddingRight={3} paddingTop={3}>
              <Divider />
            </Box>
          </>
        )}

        {!isLoading && (
          <>
            <Box paddingLeft={3} paddingTop={1} />
            <Box padding={3}>
              {checkoutCart && deliveryDropoff && (
                <CheckoutCart
                  cart={checkoutCart}
                  couponUsage={couponUsage}
                  creditUsage={creditUsage}
                  deleteMenuItem={handleDeleteMenuItem}
                  isCartSaving={isCartSaving}
                  editMenuItem={loadEditOrder}
                  isLoadingOrderForEdit={isLoadingOrderForEdit}
                  isEditingPastOrder={false}
                  programUsage={programUsage}
                  selectMenuItem={selectMenuItem}
                  storedCurrencyUsage={storedCurrencyUsage}
                  showQuantity={enableQuantity}
                  isCatering={isCatering}
                  rewardsPercentage={rewardsPercentage}
                  isLoadingDeliveryDropoffRewards={isLoadingDeliveryDropoffRewards}
                />
              )}
            </Box>
          </>
        )}
      </Card>

      <Box marginY={2}>
        <Captcha />
      </Box>

      <ButtonSubmit
        color="primary"
        disabled={checkoutCart?.orderItems?.length === 0}
        fullWidth
        onClick={() => {
          // manually trigger a form submission from far away
          document.getElementById('checkoutButton').click()
        }}
        submitting={isCheckoutProcessing}
        errorMessage={changeInvalidError(errorCheckingOut)}
        variant="contained"
      >
        Place order
      </ButtonSubmit>

      {checkoutCartError && (
        <CheckoutError serviceMessage={checkoutCartError.message} hasNoItem={hasNoItem} />
      )}

      {deliveryDropoff && location && (
        <Box marginY={3} textAlign="center">
          <Link
            to={
              deliveryType === 'Catering'
                ? formatUrl(placeCateringOrderRoute.path, {
                    locationId: location.deliveryLocationId,
                    storeId: deliveryDropoff.storeId,
                  })
                : formatUrl(placeOrderRoute.path, {
                    dropoffId: deliveryDropoff.dropoffId,
                    locationId: location.deliveryLocationId,
                  })
            }
          >
            + Add More Items
          </Link>
        </Box>
      )}

      {normalizedMenuItem && menuItemForEdit && isEditingOrder && (
        <PersonalizeOrder
          addMoreIsHidden="true"
          handleClose={handleClose}
          handleEditOrder={handleEditOrder}
          isEditingFromCheckout="true"
          isEditingFromMenu="false"
          isEditingOrder={isEditingOrder}
          locationId={location.deliveryLocationId}
          menuItemForEdit={menuItemForEdit}
          menuItemId={menuItemForEdit.menuItemId}
          normalizedMenuItem={normalizedMenuItem}
          enableQuantity={enableQuantity}
        />
      )}
    </>
  )
}

const SkeletonLoading = () => (
  <>
    <Skeleton height={150} variant="rect" />
    <Box padding={3}>
      <Skeleton height={30} width="80%" />
      <Skeleton height={30} width="80%" />
      <Box marginY={2}>
        <Skeleton height={40} width="90%" />
      </Box>
      <Skeleton height={50} />
    </Box>
  </>
)

const mapStateToProps = state => {
  const { errorCheckingOut, isCheckoutProcessing, isDeliveryLoading } = state.checkout
  const { normalizedMenuItem } = state.personalize
  const { deliveryDropoff, isDeliveryDropoffLoading } = state.delivery
  const { availableCredits, isAvailableCreditsLoading } = state.creditHistory
  const location = selectCurrentLocationWithAddress(state)
  const {
    checkoutCart,
    checkoutCartError,
    couponUsage,
    creditUsage,
    isCheckoutCartLoading,
    isCartSaving,
    isEditingOrder,
    isLoadingOrderForEdit,
    programUsage,
    storedCurrencyUsage,
  } = state.cart
  const menuItemForEdit = selectMenuItemForEdit(state)

  return {
    availableCredits,
    checkoutCart,
    checkoutCartError,
    errorCheckingOut,
    couponUsage,
    creditUsage,
    deliveryDropoff,
    isAvailableCreditsLoading,
    isCheckoutCartLoading,
    isCartSaving,
    isCheckoutProcessing,
    isDeliveryDropoffLoading,
    isDeliveryLoading,
    isEditingOrder,
    isLoadingOrderForEdit,
    location,
    menuItemForEdit,
    normalizedMenuItem,
    programUsage,
    storedCurrencyUsage,
  }
}

const mapDispatchToProps = {
  cancelEditingOrder,
  clearMenuItem,
  loadEditOrder,
  editOrderInCart,
  editCateringOrderInCart,
  removeItemFromCheckoutCartStart,
  selectMenuItem,
  resetCheckoutError,
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSidebar)
