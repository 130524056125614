import React from 'react'
import { Form, Formik } from 'formik'

import useTeamPaymentMethod from '../../../hooks/useTeamPaymentMethod'
import PaymentMethodTeam from '../common/PaymentMethodTeam'
import { NEW_PAYMENT_METHOD_ID } from '../../../util/paymentMethods'
import DialogAction from '../../common/DialogAction'

const DialogPaymentSelect = ({ open, team, paymentMethods, defaultPaymentMethod, onClose }) => {
  const { submitting, error, handleSubmitTeamPaymentMethod } = useTeamPaymentMethod({
    teamId: team.id,
    onSuccess: () => onClose(),
  })

  const handleSubmit = values => {
    handleSubmitTeamPaymentMethod(values.SelectedCard)
  }

  const title = paymentMethods?.length > 0 ? 'Default Payment Method' : 'Add Payment Method'
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ SelectedCard: defaultPaymentMethod?.id ?? NEW_PAYMENT_METHOD_ID }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => {
        return (
          <DialogAction
            dialogTitle={title}
            handleDialogOnClose={onClose}
            handleSubmit={handleSubmit}
            isSubmitting={submitting}
            show={open}
            submitText="Save"
          >
            <Form>
              <PaymentMethodTeam
                paymentMethods={paymentMethods}
                paymentMethodsError={error}
                errors={errors}
                touched={touched}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
            </Form>
          </DialogAction>
        )
      }}
    </Formik>
  )
}

export default DialogPaymentSelect
