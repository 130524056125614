import React from 'react'
import { Box, Card, Grid, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { themeConWeb } from '@app/util/modernThemeConweb'
import { Skeleton } from '@material-ui/lab'
import CircularProgress from '../../common/CircularProgress'
import ChipLarge from '../../common/ChipLarge'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  list: {
    marginTop: theme.spacing(2),
  },
  listItem: {
    marginTop: theme.spacing(4),
  },
}))

const CardProgramStats = ({ programStats, loading }) => {
  const classes = useStyles()

  if (loading) {
    return <Skeleton />
  }

  const engagementPercentage = programStats.engagementPercentage * 100
  return (
    <Card className={classes.root}>
      <Box textAlign="center">
        <Typography variant="overline" color="textSecondary">
          Engagement
        </Typography>
      </Box>
      <ul className={classes.list}>
        <Tooltip arrow title="In the last 30 days" aria-label="in the last 30 days">
          <Grid container component="li" className={classes.listItem} alignItems="center">
            <Grid item xs={6} container justifyContent="center">
              <CircularProgress
                percentage={engagementPercentage}
                thickness={7}
                size={58}
                color={themeConWeb.color.success}
                hideLabel
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{engagementPercentage.toFixed(2)}% engagement</Typography>
            </Grid>
          </Grid>
        </Tooltip>
        <Tooltip arrow title="In the last 30 days" aria-label="in the last 30 days">
          <Grid container component="li" className={classes.listItem} alignItems="center">
            <Grid item xs={6} container justifyContent="center">
              <ChipLarge
                label={`${programStats.activeMemberCount} / ${programStats.memberCount}`}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>Member usage</Typography>
            </Grid>
          </Grid>
        </Tooltip>
      </ul>
    </Card>
  )
}

export default CardProgramStats
