import React, { useCallback } from 'react'
import { get } from 'lodash'
import { Grid, TextField } from '@material-ui/core'

const CateringDeliveryInstructions = ({ values, handleChange, errors, handleBlur, touched }) => {
  const getError = useCallback(name => get(touched, name) && get(errors, name), [errors, touched])

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} xs={12}>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          id="User.cateringDeliveryInstructions"
          placeholder="Please provide instructions to assist the driver with your delivery including parking directions, access instructions, and contact information, including suite number if applicable."
          label="Delivery Instructions"
          maxLength="35"
          name="User.cateringDeliveryInstructions"
          onBlur={handleBlur}
          onChange={handleChange}
          error={!!getError('User.cateringDeliveryInstructions')}
          helperText={getError('User.cateringDeliveryInstructions')}
          color="secondary"
          value={values.User.cateringDeliveryInstructions}
          multiline
          minRows={4}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  )
}

export default CateringDeliveryInstructions
