import { Box, Grid, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Check } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { api, FOODSBY_BASE_URL } from '../../api/api'
import { contactRoute } from '../../routes/routes'
import DialogError from '../common/DialogError'
import ButtonSubmit from '../common/ButtonSubmit'

const useStyles = makeStyles({
  primaryText: {
    fontWeight: 'bold',
  },
})

const EmailVerificationBanner = ({ profile }) => {
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const handleClick = async () => {
    try {
      setSubmitting(true)
      await api.post(`${FOODSBY_BASE_URL}/api/v1/uaa/confirmation/resend`)
      setSuccess(true)
      setError(false)
    } catch (err) {
      setError(true)
      setSuccess(false)
    } finally {
      setSubmitting(false)
    }
  }

  const handleCloseSnackbar = () => {
    setSuccess(false)
    setError(false)
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }, [success])

  return (
    <Component style={{ zIndex: 999999 }}>
      <Grid component={Box} container maxWidth={780} padding={3}>
        <Grid item sm={9} xs={12}>
          <Typography className={classes.primaryText}>
            Please verify your email address
            {profile?.verifiedBefore ? '.' : ' to get $0 service fees on your next order.'}
          </Typography>
          <Typography variant="body2">
            An email containing verification instructions was sent to {profile?.username}
          </Typography>
        </Grid>
        <Grid alignSelf="center" component={Box} display="flex" item sm={3} xs={12}>
          <ButtonSubmit
            color="secondary"
            onClick={handleClick}
            size="small"
            submitting={submitting}
            success={success}
            variant="outlined"
            width="120px"
          >
            {success ? <Check /> : 'Resend email'}
          </ButtonSubmit>
        </Grid>
      </Grid>
      <DialogError
        message={
          <>
            Please try again later. <a href={contactRoute.path}>Contact Support</a> if the issue
            persists.
          </>
        }
        onClose={handleCloseSnackbar}
        title="There was an issue resending the email"
        visible={Boolean(error)}
      />
    </Component>
  )
}

const Component = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d8d8d8;
  width: 100%;
`

export default EmailVerificationBanner
