import { Chip } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

const ChipLarge = withStyles({
  root: {
    height: '46px',
    width: '104px',
    fontSize: '20px',
    fontWeight: 700,
    fontRadius: '50px',
  },
})(Chip)

export default ChipLarge
