import { push } from 'connected-react-router'
import { call, put, select, take } from 'redux-saga/effects'

import { api } from '../../api/api'
import { locationRoute } from '../../routes/routes'
import { logException } from '../../util/errorUtils'
import { formatUrl } from '../../util/formatUtils'
import { loginUser } from '../../services/user'
import { apiSaga } from './sagas'
import { selectUserJoinData } from './selectors'
import { loginFailure, loginSuccess } from './user'

export const JOIN_USER_AND_CONFIRM_ADDRESS = 'foodsby/join/JOIN_USER_AND_CONFIRM_ADDRESS'
export const JOIN_USER_AND_CHANGE_ADDRESS = 'foodsby/join/JOIN_USER_AND_CHANGE_ADDRESS'

export const JOIN_USER_SUCCESS = 'foodsby/join/JOIN_USER_SUCCESS'
export const JOIN_USER_FAILURE = 'foodsby/join/JOIN_USER_FAILURE'

// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const joinUserAndConfirmAddress = () => {
  return {
    type: JOIN_USER_AND_CONFIRM_ADDRESS,
  }
}

export const joinUserSuccess = currentUser => {
  return {
    payload: { currentUser },
    type: JOIN_USER_SUCCESS,
  }
}

export const joinUserFailure = error => {
  return {
    error,
    type: JOIN_USER_FAILURE,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [JOIN_USER_AND_CONFIRM_ADDRESS]: state => {
    return {
      ...state,
      isJoinSaving: true,
      joinSaveError: undefined,
    }
  },
  [JOIN_USER_FAILURE]: (state, action) => {
    return {
      ...state,
      isJoinSaving: false,
      joinSaveError: action.error,
    }
  },
  [JOIN_USER_SUCCESS]: state => {
    return {
      ...state,
      isJoinSaving: false,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

export default function join(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------
export function* watchSubmitJoinInfo() {
  while (true) {
    yield take(JOIN_USER_AND_CONFIRM_ADDRESS)
    const userData = yield select(selectUserJoinData)
    try {
      yield call(
        apiSaga,
        joinUser,
        [userData.username, userData.locationId],
        joinUserSuccess,
        joinUserFailure,
      )
      yield call(
        apiSaga,
        loginUser,
        [{ password: '', userName: userData.username }],
        loginSuccess,
        loginFailure,
      )
      yield put(push(formatUrl(locationRoute.path, { locationId: userData.locationId })))
    } catch (ex) {
      logException(ex)
    }
  }
}

// ------------------------------------
// APIs
// ------------------------------------
export const joinUser = (username, locationId) =>
  api.post(`/api/v1/uaa/users`, { locationId, username }).then(data => {
    // make sure username is passed to success\
    return { ...data, username }
  })
