import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Sticky from 'react-sticky-el'

const useStyles = makeStyles({
  root: {
    height: '100%',
    position: 'relative',
  },
})

const StickyContainer = ({ children, ...rest }) => {
  const classes = useStyles()
  return (
    <div className={classes.root} id="stickyContainer">
      <Sticky boundaryElement="#stickyContainer" hideOnBoundaryHit={false} {...rest}>
        {children}
      </Sticky>
    </div>
  )
}

export default StickyContainer
