import { Card } from '@material-ui/core'
import React from 'react'

import ReferralsTermsOfUse from '../components/legal/ReferralsTermsOfUse'

const PageReferralTermsAndConditions = () => {
  return (
    <article className="article">
      <Card>
        <ReferralsTermsOfUse />
      </Card>
    </article>
  )
}

export default PageReferralTermsAndConditions
