import React from 'react'
import { format, parse } from 'date-fns'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { themeConWeb } from '@app/util/modernThemeConweb'
import { Box, Link } from '@material-ui/core'
import { formatUrl } from '../../util/formatUtils'
import { confirmationRoute } from '../../routes/routes'
import RestaurantLogo from './RestaurantLogo'

const SelectedDayOrderCard = ({ selectedDateOrder, locationId }) => {
  const orderId = selectedDateOrder.orderId
  return (
    <Box marginBottom={3}>
      <CurrentOrderCard>
        <div className="order-card-content">
          <div className="-leftSide">
            <div className="-logo">
              <RestaurantLogo
                restaurantName={selectedDateOrder.Box}
                logoLink={selectedDateOrder.logoUrl}
              />
            </div>
            <div className="-nameAndManage">
              <div className="-title">{selectedDateOrder.restaurantName}</div>

              <div className="-manageLink">
                <Link
                  component={RouterLink}
                  to={formatUrl(confirmationRoute.path, {
                    locationId,
                    orderId,
                  })}
                >
                  View your order
                </Link>
              </div>
            </div>
          </div>
          <div className="-rightSide">
            {selectedDateOrder.orderState === 'DELIVERED' ? (
              <>
                <span>
                  <strong className="-delivered">Delivered</strong>
                </span>
                <span>at {format(parse(selectedDateOrder.deliveredDateTime), 'h:mm A')}</span>
              </>
            ) : selectedDateOrder.orderState === 'CANCELED' ? (
              <span className="-canceled">
                <strong>Canceled</strong>
              </span>
            ) : (
              <>
                <span>
                  <strong>Estimated delivery</strong>
                </span>
                <span>
                  at{' '}
                  {selectedDateOrder.expectedDeliveryTimeDisplay ??
                    format(parse(selectedDateOrder.orderDateTime), 'h:mm A')}
                </span>
              </>
            )}
          </div>
        </div>
      </CurrentOrderCard>
    </Box>
  )
}

const CurrentOrderCard = styled.div`
  display: block;
  background-color: ${themeConWeb.color.white};
  flex: auto;
  max-width: 100%;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-top: 0;

  & .order-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    padding-left: 45px;
    padding-right: 45px;
  }

  & .-leftSide {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .-nameAndManage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15px;
  }

  & .-rightSide {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-family: 'Roboto';
    font-size: 16px;
    text-align: right;
  }

  & .-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
  }

  & .-logo {
    max-width: 60px;
    margin-bottom: 8px;
  }

  & .-delivered {
    color: ${themeConWeb.color.success};
    font-size: 18px;
  }

  & .-canceled {
    color: ${themeConWeb.color.dangerLight};
    font-size: 18px;
  }

  & .-manageLink {
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    color: ${themeConWeb.color.twoShade2};
  }
`

export default SelectedDayOrderCard
