import { Box, Divider, Typography } from '@material-ui/core'
import currency from 'currency.js'
import React from 'react'
import styled from 'styled-components'

import PriceBreakdown, { DeliveryFeeContribution, PriceRow } from '../checkout/PriceBreakdown'
import { formatCurrencyInCents, setUndefinedItemSubtotalToZero } from '../../util/formatUtils'
import CartMenuItemList from './CartMenuItemList'

const CartContainer = styled.div`
  padding-top: 0px;
`

const YourOrder = styled.div`
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: 500;
  line-height: 23px;
  padding-top: 10px;
`

export const OrderCart = ({
  cart: { itemSubTotal, orderItems },
  deleteMenuItem,
  isCartSaving,
  editMenuItem,
  isLoadingOrderForEdit,
  isEditingPastOrder,
  orderMinimumInCents,
  showQuantity,
  deliveryFee,
  isConfirmedReserveDropoff,
  isCatering,
  rewardsPercentage,
}) => {
  const deliveryFeeInDollars = isConfirmedReserveDropoff ? deliveryFee : deliveryFee / 100

  return (
    <CartContainer>
      <YourOrder>Your Order</YourOrder>
      <CartMenuItemList
        deleteMenuItem={deleteMenuItem}
        isDeletingMenuItem={isCartSaving}
        editable
        editMenuItem={editMenuItem}
        isEditingMenuItem={isLoadingOrderForEdit}
        isEditingPastOrder={isEditingPastOrder}
        menuItems={orderItems}
        showEditLinks
        useFullSubTotal
        showQuantity={showQuantity}
      />
      <Box marginBottom={2} marginTop={3}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body1" fontWeight="bold">
          Subtotal
        </Typography>

        <Typography fontSize={30} fontWeight={700}>
          {currency(setUndefinedItemSubtotalToZero(itemSubTotal), {
            formatWithSymbol: true,
          }).format()}
        </Typography>
      </Box>
      {deliveryFee > 0 && (
        <>
          <DeliveryFeeContribution
            deliveryFeeContribution={deliveryFeeInDollars}
            isCatering={isCatering}
            isReserveConfirmedDropoff={isConfirmedReserveDropoff}
          />
          <Box marginBottom={2} marginTop={3}>
            <Divider />
          </Box>
          <PriceRow
            primary
            label="Total"
            value={currency(deliveryFeeInDollars + setUndefinedItemSubtotalToZero(itemSubTotal), {
              formatWithSymbol: true,
            }).format()}
          />
        </>
      )}
      {orderMinimumInCents && (
        <Box display="flex" justifyContent="right">
          <Typography variant="body2" color="textSecondary">
            {`${formatCurrencyInCents(orderMinimumInCents)} minimum`}
          </Typography>
        </Box>
      )}
      {rewardsPercentage && (
        <Box display="flex" justifyContent="right">
          <p style={{ marginTop: '10px', fontSize: '10px', fontWeight: 500, color: '#8A8B8D' }}>
            {`Earn ${rewardsPercentage}%`}
          </p>
        </Box>
      )}
    </CartContainer>
  )
}

export const CheckoutCart = ({
  cart: {
    foodsbyFee,
    orderItems,
    orderTotal,
    taxSubTotal,
    taxSubtotal,
    itemSubTotal,
    deliveryFeeContribution,
    rewardsTotal,
  },
  couponUsage,
  creditUsage,
  deleteMenuItem,
  isCartSaving,
  editMenuItem,
  isLoadingOrderForEdit,
  isEditingPastOrder,
  showQuantity,
  programUsage = {},
  storedCurrencyUsage = {},
  isCatering,
  rewardsPercentage,
  isLoadingDeliveryDropoffRewards,
}) => {
  return (
    <CartContainer>
      <Typography gutterBottom variant="overline">
        Your order
      </Typography>
      <CartMenuItemList
        deleteMenuItem={deleteMenuItem}
        isDeletingMenuItem={isCartSaving}
        editable
        editMenuItem={editMenuItem}
        isEditingMenuItem={isLoadingOrderForEdit}
        isEditingPastOrder={isEditingPastOrder}
        menuItems={orderItems}
        showEditLinks
        useFullSubTotal
        showQuantity={showQuantity}
      />
      <PriceBreakdown
        couponAmount={couponUsage.couponAmount}
        couponApplied={!couponUsage.isEpp && couponUsage.couponApplied}
        couponCode={couponUsage.couponCode}
        creditsApplied={creditUsage.creditsApplied}
        creditsUsed={creditUsage.creditBalanceUsed}
        orderFee={foodsbyFee}
        subtotal={itemSubTotal}
        programAmount={programUsage?.programAmount || couponUsage.couponAmount}
        programApplied={
          programUsage?.programApplied || (couponUsage.couponApplied && couponUsage.isEpp)
        }
        programCode={programUsage?.programName || couponUsage.couponCode}
        storedCurrencyAmount={storedCurrencyUsage?.storedCurrencyBalanceUsed}
        storedCurrencyApplied={storedCurrencyUsage?.storedCurrencyApplied}
        taxes={taxSubTotal || taxSubtotal}
        orderTotal={orderTotal}
        deliveryFeeContribution={deliveryFeeContribution}
        isCatering={isCatering}
      />
      {rewardsPercentage != null && !isLoadingDeliveryDropoffRewards && (
        <Box display="flex" justifyContent="right">
          <p style={{ marginTop: '10px', fontSize: '10px', fontWeight: 500, color: '#8A8B8D' }}>
            {`Earn ${rewardsPercentage}%`}
          </p>
        </Box>
      )}
    </CartContainer>
  )
}

export const ReserveCheckoutCart = ({
  cart: {
    foodsbyFee,
    orderItems,
    orderTotal,
    preTaxSubTotal,
    taxSubTotal,
    taxSubtotal,
    deliveryFeeContribution,
    itemSubTotal,
    rewardsTotal,
  },
  couponUsage,
  creditUsage,
  deleteMenuItem,
  isCartSaving,
  editMenuItem,
  isLoadingOrderForEdit,
  isEditingPastOrder,
  showQuantity,
  programUsage = {},
  storedCurrencyUsage = {},
  isReserveConfirmedDropoff,
}) => {
  return (
    <CartContainer>
      <Typography gutterBottom variant="overline">
        Your order
      </Typography>
      <CartMenuItemList
        deleteMenuItem={deleteMenuItem}
        isDeletingMenuItem={isCartSaving}
        editable
        editMenuItem={editMenuItem}
        isEditingMenuItem={isLoadingOrderForEdit}
        isEditingPastOrder={isEditingPastOrder}
        menuItems={orderItems}
        showEditLinks
        useFullSubTotal
        showQuantity={showQuantity}
      />
      <PriceBreakdown
        couponAmount={couponUsage.couponAmount}
        couponApplied={!couponUsage.isEpp && couponUsage.couponApplied}
        couponCode={couponUsage.couponCode}
        creditsApplied={creditUsage.creditsApplied}
        creditsUsed={creditUsage.creditBalanceUsed}
        orderFee={foodsbyFee}
        subtotal={itemSubTotal}
        programAmount={programUsage?.programAmount || couponUsage.couponAmount}
        programApplied={
          programUsage?.programApplied || (couponUsage.couponApplied && couponUsage.isEpp)
        }
        programCode={programUsage?.programName || couponUsage.couponCode}
        storedCurrencyAmount={storedCurrencyUsage?.storedCurrencyBalanceUsed}
        storedCurrencyApplied={storedCurrencyUsage?.storedCurrencyApplied}
        taxes={taxSubTotal || taxSubtotal}
        orderTotal={orderTotal}
        deliveryFeeContribution={deliveryFeeContribution}
        isReserveConfirmedDropoff={isReserveConfirmedDropoff}
      />
      {rewardsTotal != null && (
        <Box display="flex" justifyContent="right">
          <p style={{ marginTop: '10px', fontSize: '10px', fontWeight: 500, color: '#8A8B8D' }}>
            {`Earn $${currency(setUndefinedItemSubtotalToZero(rewardsTotal))}`}
          </p>
        </Box>
      )}
    </CartContainer>
  )
}
