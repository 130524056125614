import { Box, Card } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React, { Component } from 'react'
import scriptLoader from 'react-async-script-loader'
import { connect } from 'react-redux'

import { Article } from '../components/common/Layout'
import { MAPS_SCRIPT_URL } from '../components/location-search/LocationSearchBox'
import PanelPaymentOptions from '../components/profile/PanelPaymentOptions'
import PanelPersonalInformation from '../components/profile/PanelPersonalInformation'
import PanelSubscriptions from '../components/profile/subscriptions/PanelSubscriptions'
import PanelTeams from '../components/profile/teams/PanelTeams'
import { loadPaymentMethodsStart } from '../redux/modules/paymentMethods'
import {
  selectCurrentLocationWithAddress,
  selectAllPaymentMethods,
} from '../redux/modules/selectors'

export class PageProfile extends Component {
  componentDidMount() {
    this.props.loadPaymentMethodsStart()
  }

  render() {
    const {
      isLoadingPaymentOptions,
      location,
      loadPaymentMethodsStart,
      paymentMethods,
      paymentMethodsError,
    } = this.props
    return (
      <Article>
        <Card>
          <PanelPersonalInformation />
        </Card>
        <Card>
          {isLoadingPaymentOptions ? (
            <SkeletonPanelPaymentOptionsLoading />
          ) : (
            <PanelPaymentOptions
              paymentMethods={paymentMethods}
              paymentMethodsError={paymentMethodsError}
              loadPaymentMethodsStart={loadPaymentMethodsStart}
            />
          )}
        </Card>
        <PanelTeams />
        <PanelSubscriptions location={location} />
      </Article>
    )
  }
}

const SkeletonHeader = () => (
  <Box display="flex" flexDirection="row" justifyContent="space-between" marginBottom={3}>
    <Skeleton height={40} width={160} />
    <Skeleton height={40} width={160} />
  </Box>
)

const SkeletonPanelPaymentOptionsLoading = () => (
  <>
    <SkeletonHeader />
    <Skeleton height={40} width="100%" />
    <Skeleton height={40} width="100%" />
    <Skeleton height={40} width="100%" />
  </>
)

const mapStateToProps = state => {
  const { isLoadingPaymentOptions } = state.profile
  const location = selectCurrentLocationWithAddress(state)
  const { currentUser } = state.user
  const { paymentMethodsError } = state.paymentMethods
  const paymentMethods = selectAllPaymentMethods(state)

  return {
    currentUser,
    isLoadingPaymentOptions,
    location,
    paymentMethods,
    paymentMethodsError,
  }
}

const mapDispatchToProps = {
  loadPaymentMethodsStart,
}

export default scriptLoader(MAPS_SCRIPT_URL)(
  connect(mapStateToProps, mapDispatchToProps)(PageProfile),
)
