import { Box, Grid, Card } from '@material-ui/core'
import React from 'react'

import { Skeleton } from '@material-ui/lab'

const MobileWidgetSkeleton = ({ isDesktop, loading, WidgetComponent }) => {
  if (isDesktop) {
    return null
  } else if (!loading) {
    return <WidgetComponent />
  }

  return (
    <Card data-testid="MobileWidgetSkeleton">
      <Grid container spacing={1} style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item>
          <Skeleton height={60} width={200} />
        </Grid>
      </Grid>
      <Box display={'flex'} justifyContent={'center'} mt={2}>
        <Skeleton height={60} width={120} />
      </Box>
    </Card>
  )
}

export default MobileWidgetSkeleton
