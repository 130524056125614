import React, { useState } from 'react'
import { Box, Card, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { addDays, format } from 'date-fns'
import { capitalize } from 'lodash'
import {
  AccessTimeOutlined,
  AttachMoney,
  CreditCardOutlined,
  InfoOutlined,
} from '@material-ui/icons'

import ListActionableDetails from '../../common/ListActionableDetails'
import { formatCentsToPrettyDollars } from './giftCardUtils'
import { getProgramDays } from './utils'
import DialogEditEndDate from './DialogEditEndDate'
import {
  formatDefaultPaymentMethodInfoText,
  formatDefaultPaymentMethodText,
  offerAmountContributionDescription,
  PROGRAM_USAGE_TYPES,
} from './programUtils'

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
})

const CardProgramSummary = ({
  program,
  teamPaymentCollectionMethod,
  defaultPaymentMethod,
  isUpdatingProgram,
  onUpdateProgram,
  isEndDateEditable,
  status,
}) => {
  const classes = useStyles()
  const [showEditEndDateDialog, setShowEditEndDateDialog] = useState(false)
  const days = getProgramDays(program.schedule)
  const startDate = format(program.startDate, 'MM/DD/YYYY')
  // WARNING This is a TEMPORARY fix as the end date is exclusive in the backend
  // We need to subtract a day here because we add a day when creating/updating
  // just so users can use the program on the day they select
  const defaultEndDate = formatString =>
    program.endDate ? format(addDays(program.endDate, -1), formatString) : 'Not set'

  const summaryItems = [
    {
      id: 'FREQUENCY',
      title: `${PROGRAM_USAGE_TYPES[program.usageType].label} | ${days}`,
      secondaryText: 'Perk Frequency',
      image: <AccessTimeOutlined />,
    },
    {
      id: 'AMOUNT',
      title: `${formatCentsToPrettyDollars(program.discountAmountInPennies)} per member`,
      secondaryText: 'Perk Amount',
      image: <AttachMoney />,
    },
    {
      id: 'TIME',
      title: `${capitalize(status)} Perk`,
      secondaryText: `Start date: ${startDate} | End date: ${defaultEndDate('MM/DD/YYYY') ??
        'Not set'}`,
      image: <InfoOutlined />,
      buttonText: isEndDateEditable && `${program.endDate ? 'Edit' : 'Set'} expiration`,
      loading: isUpdatingProgram,
    },
    {
      id: 'PAYMENT_METHOD',
      title: formatDefaultPaymentMethodText(defaultPaymentMethod, teamPaymentCollectionMethod),
      secondaryText: 'Default Payment Method',
      image: <CreditCardOutlined />,
      infoText: formatDefaultPaymentMethodInfoText(teamPaymentCollectionMethod),
    },
  ]

  const handleSubmitEndDate = endDate => {
    onUpdateProgram(endDate)
    setShowEditEndDateDialog(false)
  }

  return (
    <Card className={classes.root}>
      <Typography variant="h6">Details</Typography>
      <ListActionableDetails
        listItems={summaryItems}
        handleClick={() => setShowEditEndDateDialog(true)}
        activeId="TIME"
      />
      {program.activeDiscount && (
        <Box mt={3}>
          <Typography variant="body2" color="error" style={{ fontStyle: 'italic' }}>
            {offerAmountContributionDescription(
              program.activeDiscount.discountType === 'PERCENT',
              program.activeDiscount.amountPerUse,
              program.activeDiscount.totalLimit / 100,
              program.usageType,
            )}{' '}
            This discount expires on {format(program.activeDiscount.endDate, 'M/D/YYYY')}.
          </Typography>
        </Box>
      )}
      <DialogEditEndDate
        open={showEditEndDateDialog}
        defaultValue={defaultEndDate('YYYY-MM-DD')}
        submitting={isUpdatingProgram}
        startDate={format(program.startDate, 'YYYY-MM-DD')}
        onSubmit={handleSubmitEndDate}
        onClose={() => setShowEditEndDateDialog(false)}
      />
    </Card>
  )
}

export default CardProgramSummary
