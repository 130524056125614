import { Card } from '@material-ui/core'
import React from 'react'

export const SuccessModal = ({ isFailure = false, message, onClose, title }) => (
  <Card className={isFailure ? 'failure-notification' : 'success-notification'} pb={4}>
    <p className="notification-message">
      {isFailure ? (
        <i className="icon-times-circle failure-notification-icon" />
      ) : (
        <i className="icon-checkmark success-notification-icon" />
      )}{' '}
      <strong>{title}</strong>
      {message}
    </p>
    {onClose && (
      <i
        aria-label="Close notification"
        className="notification-close"
        onClick={onClose}
        role="button"
      />
    )}
  </Card>
)
