import { isNil } from 'lodash'
import { SubscriptionStatus } from '../../util/constants'

const DELIVERY_FEE = 1.99

/**
 * The foodsby fee can be zero due to location having a default delivery fee of $0.00
 * or due to user checking the Subscribe checkbox.
 * That is why we have a parameter called willSubscribe that makes that distinction.
 *
 * A couple restrictions:
 *  1. We must have the user's payment method in order to subscribe them. So we must hide the Subscription:
 *      a. If the order is free.
 *      b. If the order is less than the delivery fee because user might attempt to subscribe which would
 *         make the order free.
 *
 *  2. If the delivery has no delivery fee, we will hide the Subscription, but only if it is not due to user attempting to subscribe.
 *
 * @param {object} checkoutCart (with foodsbyFee and orderTotal fields)
 * @param {boolean} isSubscribed
 * @param {boolean} willSubscribe
 * @returns {boolean}
 */
export const shouldHideSubscription = (checkoutCart, isSubscribed, willSubscribe) => {
  if (isSubscribed) {
    return false
  }

  if (isFreeOrder(checkoutCart)) {
    return true
  }

  if (willSubscribe) {
    return false
  }

  return (
    isNil(checkoutCart.foodsbyFee) ||
    totalIsLessThanFoodsbyFee(checkoutCart) ||
    hasNoFoodsbyFee(checkoutCart)
  )
}

export const shouldShowSmsAutoEnrollment = (smsSubscriptionStatus, smsEnrollmentAsked) => {
  if (!smsEnrollmentAsked) {
    return smsSubscriptionStatus !== SubscriptionStatus.SUBSCRIBED
  }

  return smsSubscriptionStatus === SubscriptionStatus.UNSUBSCRIBED
}

// ------------------------------------
// Private methods
// ------------------------------------
const isFreeOrder = checkoutCart => checkoutCart.orderTotal === 0

const totalIsLessThanFoodsbyFee = checkoutCart => checkoutCart.orderTotal < DELIVERY_FEE

const hasNoFoodsbyFee = checkoutCart => checkoutCart.foodsbyFee === 0
