import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Link } from '@material-ui/core'
import { Lock } from '@material-ui/icons'

import { privacyPolicyRoute } from '../../../routes/routes'

const useStyles = makeStyles(theme => ({
  infoAlert: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[300],
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.common.black,
  },
  lockIcon: {
    color: theme.palette.common.black,
    marginRight: theme.spacing(2),
  },
}))

const AlertMemberInvitePrivacy = () => {
  const classes = useStyles()

  return (
    <Box className={classes.infoAlert}>
      <Lock className={classes.lockIcon} fontSize="small" />
      Uploaded emails will be used for sending invites only in accordance with our&nbsp;
      <Link href={privacyPolicyRoute.path} target="_blank">
        Privacy Policy.
      </Link>
    </Box>
  )
}

export default AlertMemberInvitePrivacy
