import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadSubscriptionDetailsStart,
  loadSubscriptionOfferStart,
} from '../redux/modules/subscriptions'

import {
  hasActiveSubscription,
  hasSubscribedBefore,
  isEligibleToSubscribe,
} from '../util/subscriptions'

/**
 * Hook to load, select and infer subscription details
 */
const useSubscriptions = () => {
  const dispatch = useDispatch()
  const subscriptionDetails = useSelector(state => state.subscriptions.subscriptionDetails)
  const subscriptionOffer = useSelector(state => state.subscriptions.subscriptionOffer)
  const errorLoadingSubscriptionDetails = useSelector(
    state => state.subscriptions.errorLoadingSubscriptionDetails,
  )
  const isLoadingSubscriptionDetails = useSelector(
    state => state.subscriptions.isLoadingSubscriptionDetails,
  )
  const isLoadingSubscriptionOffer = useSelector(
    state => state.subscriptions.isLoadingSubscriptionOffer,
  )
  const isSubmittingSubscription = useSelector(
    state => state.subscriptions.isSubmittingSubscription,
  )
  const successSubmittingSubscription = useSelector(
    state => state.subscriptions.successSubmittingSubscription,
  )
  const errorSubmittingSubscription = useSelector(
    state => state.subscriptions.errorSubmittingSubscription,
  )

  useEffect(() => {
    if (subscriptionOffer === undefined) {
      dispatch(loadSubscriptionOfferStart())
    }
  }, [dispatch, subscriptionOffer])

  useEffect(() => {
    if (subscriptionDetails === undefined && isEligibleToSubscribe(subscriptionOffer)) {
      dispatch(loadSubscriptionDetailsStart())
    }
  }, [dispatch, subscriptionDetails, subscriptionOffer])

  const isSubscriptionEligible =
    Boolean(subscriptionOffer?.offer) ||
    subscriptionOffer?.ineligibilityReason === 'ALREADY_SUBSCRIBED'
  const isSubscriptionBeneficiary =
    isEmpty(subscriptionDetails) && subscriptionOffer?.ineligibilityReason === 'ALREADY_SUBSCRIBED'
  const isSubscribedBefore = hasSubscribedBefore(subscriptionOffer)
  const isSubscribed = hasActiveSubscription(subscriptionDetails)
  return {
    subscriptionDetails,
    isSubscriptionEligible,
    isSubscriptionBeneficiary,
    subscriptionOffer,
    isLoadingSubscriptionDetails,
    isLoadingSubscriptionOffer,
    errorLoadingSubscriptionDetails,
    isSubscribedBefore,
    isSubscribed,
    isSubmittingSubscription,
    successSubmittingSubscription,
    errorSubmittingSubscription,
  }
}

export default useSubscriptions
