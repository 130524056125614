import { Pagination } from '@foodsby/nutrient'
import { Card } from '@material-ui/core'
import currency from 'currency.js'
import { format, parse } from 'date-fns'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import React from 'react'

import { SimpleSpinner } from '../common/SimpleSpinner'
import { formatUrl } from '../../util/formatUtils'
import { confirmationRoute } from '../../routes/routes'

export const NoOrderHistory = () => (
  <p className="order-history_description">You have no previous orders.</p>
)

export const OrderHistoryTable = ({
  loading,
  orders,
  pagination: { currentPage, handleChange, totalPages },
}) => {
  const items = orders.map(order => <OrderHistoryItem key={order.orderId} order={order} />)

  return (
    <Card className="order-history-table">
      <div className="row center-xs">
        <div className="col-xs-12 center-xs">
          <h1 className="title">Order History</h1>
          <p className="order-history_description">
            Please select an order below to view the details.
          </p>
        </div>
      </div>
      {items && items.length && !loading ? (
        [
          <div className="table-wrapper table-hover" key="order-history-table">
            <table>
              <thead>
                <tr>
                  <th>Order Date</th>
                  <th>Order #</th>
                  <th>Restaurant</th>
                  <th>Total</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>{items}</tbody>
            </table>
          </div>,
          <Pagination
            currentPage={currentPage}
            key="order-history-table-pagination"
            onChange={handleChange}
            totalPages={totalPages}
          />,
        ]
      ) : loading ? (
        <SimpleSpinner />
      ) : (
        <NoOrderHistory />
      )}
    </Card>
  )
}

export const OrderHistoryItem = ({ order }) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    return dispatch(
      push(
        formatUrl(confirmationRoute.path, {
          locationId: order.locationId,
          orderId: order.orderId,
        }),
      ),
    )
  }
  return (
    <tr onClick={handleClick}>
      <td>{format(parse(order.orderDate), 'MM/DD/YYYY')}</td>
      <td>{order.orderId}</td>
      <td>{order.storeName}</td>
      <td>{currency(order.orderTotal, { formatWithSymbol: true }).format()}</td>
      <td>{order.orderStatusText}</td>
    </tr>
  )
}

OrderHistoryTable.defaultProps = {
  orders: [],
}

export default OrderHistoryTable
