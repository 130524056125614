import { themeConWeb } from '@app/util/modernThemeConweb'
import { parse } from 'date-fns'
import React from 'react'

import { Box, Button, Typography } from '@material-ui/core'
import { convertOrderDetailItemsToCartMenuItems } from '../../util/formatUtils'
import CartMenuItemList from '../cart/CartMenuItemList'
import PriceBreakdown from '../checkout/PriceBreakdown'
import {
  Location,
  LocationBuilding,
  LocationContainer,
  LocationLogo,
  LocationOrderStatusOld,
  LocationTime,
} from './Location'
import LinkButton from './LinkButton'
import CheckoutDeliveryDetails from './CheckoutDeliveryDetails'

export const OrderConfirmationDetails = ({ order, location, deliveryType }) => {
  const menuItems = convertOrderDetailItemsToCartMenuItems(order.orderItems)
  const isCatering = deliveryType === 'Catering'
  return (
    <Box style={{ marginTop: '2em' }}>
      <Box display={'flex'} flexDirection={'row'} marginBottom={'1em'}>
        <Typography
          variant="h3"
          style={{ fontSize: themeConWeb.fontSizes.basePlus3, fontWeight: 500 }}
        >
          Order Details
        </Typography>
        <Box marginLeft={'1em'} marginTop={'0.3em'}>
          <LinkButton
            onClick={() => window.print()}
            color={themeConWeb.color.brandPrimary}
            style={{
              fontSize: themeConWeb.fontSizes.baseMinus1,
              fontWeight: 700,
              textDecoration: 'underline',
              textTransform: 'none',
            }}
          >
            Print Receipt
          </LinkButton>
        </Box>
      </Box>
      <Box marginLeft={'2em'}>
        <CheckoutDeliveryDetails
          address={location.address}
          deliveryDate={order.orderDate}
          deliveryTime={order.originalTime}
        />
        <CartMenuItemList
          editable={false}
          menuId={order.menuId}
          menuItems={menuItems}
          showEditLinks={false}
          showQuantity={true}
          useFullSubTotal
        />
        <PriceBreakdown
          couponAmount={order.couponSubtotal}
          couponApplied={order.couponId != null && !order.isCouponEpp}
          couponCode={order.couponCode}
          creditsApplied={order.creditSubtotal > 0}
          creditsUsed={order.creditSubtotal}
          orderFee={order.foodsbyFee}
          subtotal={order.itemSubtotal}
          programAmount={order.programSubtotal || order.couponSubtotal}
          programApplied={order.programId || (order.couponId != null && order.isCouponEpp)}
          programCode={order.programName || order.couponCode}
          storedCurrencyAmount={order?.storedCurrencySubtotal}
          storedCurrencyApplied={order?.storedCurrencySubtotal > 0}
          deliveryFeeContribution={order.deliveryFeeContribution}
          taxes={order.taxTotal}
          orderTotal={order.orderTotal}
          isReserveConfirmedDropoff={true}
          isCatering={isCatering}
        />
      </Box>
    </Box>
  )
}

export const OrderDetails = ({ order }) => {
  const menuItems = convertOrderDetailItemsToCartMenuItems(order.orderItems)
  return (
    <div className="cart">
      <div className="location">
        <div className="location-content_row">
          <div className="location-order">
            <span className="location-order_icon">
              <i className="icon-cart" style={{ color: themeConWeb.color.greyShade3 }} />
            </span>
            <span className="location-order_number">Order: {order.orderId}</span>
          </div>
          <div className="location-order">
            <span className="location-order_icon">
              <i className="icon-user" style={{ color: themeConWeb.color.greyShade3 }} />
            </span>
            <span className="location-order_number">Name: {order.username}</span>
          </div>
          {order.ccLastFour ? (
            <div className="location-order">
              <span className="location-order_icon">
                <i className="icon-credit-card" style={{ color: themeConWeb.color.greyShade3 }} />
              </span>
              <span className="location-order_number">CC Used: {order.ccLastFour}</span>
            </div>
          ) : null}
        </div>
      </div>
      <CartMenuItemList
        editable={false}
        menuId={order.menuId}
        menuItems={menuItems}
        showEditLinks={false}
        showQuantity={true}
        useFullSubTotal
      />
      <hr />
      <div className="row middle-xs OrderSummary-printReceipt">
        <div className="col-xs-12 col-md-6">
          <Box className="col-xs-12 center-xs" mb={3}>
            <Button
              className="cart-checkout"
              color="primary"
              media="print"
              onClick={() => window.print()}
              startIcon={<i className="icon-printer" />}
              variant="contained"
            >
              Print Receipt
            </Button>
          </Box>
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="cart-subtotal-container">
            <PriceBreakdown
              couponAmount={order.couponSubtotal}
              couponApplied={order.couponId != null && !order.isCouponEpp}
              couponCode={order.couponCode}
              creditsApplied={order.creditSubtotal > 0}
              creditsUsed={order.creditSubtotal}
              orderFee={order.foodsbyFee}
              subtotal={order.itemSubtotal}
              programAmount={order.programSubtotal || order.couponSubtotal}
              programApplied={order.programId || (order.couponId != null && order.isCouponEpp)}
              programCode={order.programName || order.couponCode}
              storedCurrencyAmount={order?.storedCurrencySubtotal}
              storedCurrencyApplied={order?.storedCurrencySubtotal > 0}
              deliveryFeeContribution={order.deliveryFeeContribution}
              taxes={order.taxTotal}
              orderTotal={order.orderTotal}
              isOrderSummary={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export const OrderSummaryLocation = ({ order, orderHistorySize }) => {
  return (
    <Location>
      <LocationContainer>
        <div className="row middle-xs">
          <div className="col-xs-12 col-md-4">
            <LocationLogo logoAlt={order.storeName + ' Logo'} logoLink={order.logoUrl} />
          </div>
          <div className="col-xs-12 col-md-8">
            <h1>
              <LocationOrderStatusOld
                extraClasses={!orderHistorySize ? { confirm: true } : { orderHistoryStatus: true }}
                locationStatusClasses="loc-three-row"
                order={order}
                statusText={order.statusText}
              />
            </h1>
            <LocationBuilding address={order.address} name={order.locationName} />
            <LocationTime
              dateTime={parse(order.deliveryDateTime)}
              deliveryTimeDisplay={order.expectedDeliveryTimeDisplay}
              multiLine={false}
            />
          </div>
        </div>
      </LocationContainer>
    </Location>
  )
}

export const OrderSummary = ({ children }) => <div>{children}</div>

export default OrderSummary
