import { Modal } from '@foodsby/nutrient'
import { ConnectedRouter } from 'connected-react-router'
import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import { Provider } from 'react-redux'

import store, { history } from '../../redux/store'
import Action from './Action'
class LinkPopup extends Component {
  constructor(props) {
    super(props)
    this.state = { showModal: false }
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }

  showModal(evt) {
    evt.preventDefault()
    document.body.classList.add('noscroll')
    this.setState({ showModal: true })
  }

  hideModal() {
    document.body.classList.remove('noscroll')
    this.setState({ showModal: false })
    // TODO: this will need to be handled better later on - added check for quick fix on prod issue that was happening story CONWEB-3108 was created for this issue
    if (this.props.clearError) {
      this.props.clearError()
    }
  }

  render() {
    const { children, linkIconClassName, linkText, showCancel = true, styles, ...rest } = this.props
    return (
      <span>
        <Action onClick={this.showModal} style={styles}>
          {linkIconClassName ? <i className={linkIconClassName} /> : null}
          {linkText}
        </Action>
        {this.state.showModal &&
          createPortal(
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <Modal
                  onCancel={showCancel ? this.hideModal : null}
                  showCancel={showCancel}
                  {...rest}
                >
                  {children}
                </Modal>
              </ConnectedRouter>
            </Provider>,
            document.getElementById('modal'),
          )}
      </span>
    )
  }
}

export default LinkPopup
