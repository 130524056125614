import { Button, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import styled from 'styled-components'

const TEXTAREA_HEIGHT = '100px'

const Root = styled.div`
  background-color: #ffffff;
  border: 1px dashed rgb(224, 224, 224);
  padding: 16px;
  & + & {
    margin-top: 16px;
  }
`
const Row = styled.div`
  display: flex;
  margin-left: -16px;
`
const Col = styled.div`
  margin-left: 16px;
`
const IndexContainer = styled(Col)`
  align-items: center;
  display: flex;
  min-width: 40px;
`
const InputContainer = styled(Col)`
  flex-grow: 1;
`
const PreviewImage = styled.div`
  background-image: url(${({ backgroundSrc }) => backgroundSrc});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 2px;
  cursor: pointer;
  height: ${TEXTAREA_HEIGHT};
  width: ${parseInt(TEXTAREA_HEIGHT, 10) * 1.45}px;
`
const DialogImage = styled.img`
  max-width: 100%;
`

const LocationImage = ({ imageSrc, description, position }) => {
  const [showImageModal, setShowImageModal] = useState(false)
  return (
    <Root>
      <Row>
        <IndexContainer>{position}</IndexContainer>
        <Col>
          <PreviewImage
            role="button"
            tabIndex="0"
            onClick={() => setShowImageModal(true)}
            backgroundSrc={imageSrc}
          />
          <Dialog onClose={() => setShowImageModal(false)} open={showImageModal}>
            <DialogContent>
              <DialogImage src={imageSrc} />
            </DialogContent>
            <DialogActions>
              <Button type="button" color="secondary" onClick={() => setShowImageModal(false)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Col>
        <InputContainer>
          <TextField
            id={`${position}`}
            name="description"
            disabled
            multiline
            minRows={3}
            variant="outlined"
            fullWidth
            margin="dense"
            value={description}
          />
        </InputContainer>
      </Row>
    </Root>
  )
}

const LocationImages = ({ images }) => {
  return images.map(({ id, imageUrl, name }, index) => (
    <LocationImage
      key={id}
      imageSrc={encodeURI(imageUrl)}
      description={name}
      position={index + 1}
    />
  ))
}

export default LocationImages
