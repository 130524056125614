import React from 'react'
import { Box, Divider, Typography, Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import currency from 'currency.js'
import { makeStyles } from '@material-ui/core/styles'
import { ReserveFeeNotice } from '../../util/reserveUtils'
import { setUndefinedItemSubtotalToZero } from '../../util/formatUtils'

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: 'black',
    fontWeight: 400,
    padding: '8px',
    '& p, & a': {
      color: '#fff !important',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
}))

export const PriceRow = ({
  label,
  value,
  primary = false,
  negativeValue = false,
  hide = false,
}) => (
  <Box
    display={hide ? 'none' : 'flex'}
    justifyContent="space-between"
    style={{ paddingBottom: '6px' }}
  >
    <Box display={hide ? 'none' : 'flex'} justifyContent="start">
      <Typography variant={primary ? 'h5' : 'body1'} style={{ marginRight: '5px' }}>
        {label}
      </Typography>
    </Box>
    <Typography variant={primary ? 'h5' : 'body1'}>
      {negativeValue && '- '}
      {typeof value === 'number' ? currency(value, { formatWithSymbol: true }).format() : value}
    </Typography>
  </Box>
)

const FeesAndTaxes = ({ taxes = 0, orderFee }) => {
  const classes = useStyles()

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ paddingBottom: '6px' }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <p>Fees & Taxes</p>
          <Tooltip
            interactive
            disableFocusListener
            disableTouchListener
            title={<FeesAndTaxesNotice taxes={taxes} orderFee={orderFee} />}
            classes={{ tooltip: classes.customTooltip }}
          >
            <InfoIcon
              fontSize="small"
              style={{ color: 'ABADB2', lineHeight: '14px', marginLeft: '5px' }}
            />
          </Tooltip>
        </Box>
        <PriceRow label="" value={setUndefinedItemSubtotalToZero(taxes + orderFee)} />
      </Box>
    </>
  )
}

export const FeesAndTaxesNotice = ({ taxes, orderFee }) => {
  return (
    <div>
      <p>Service Fee: {currency(orderFee, { formatWithSymbol: true }).format()}</p>
      <p>This fee helps us operate Foodsby.</p>
      <br />
      <p>Taxes: {currency(taxes, { formatWithSymbol: true }).format()}</p>
      <p>Tax is calculated based on your local and state municipalities.</p>
    </div>
  )
}

const SubtotalPreTax = ({
  couponAmount,
  couponApplied,
  couponCode,
  creditsApplied,
  creditsUsed,
  subtotal,
}) => {
  return (
    <>
      <PriceRow label="Subtotal" value={subtotal} />
      <PriceRow
        label={`Coupon ${couponCode}`}
        value={couponAmount}
        negativeValue
        hide={!couponApplied}
      />
      <PriceRow label="Credit" value={creditsUsed} negativeValue hide={!creditsApplied} />
    </>
  )
}

const ProgramsAndGifts = ({
  programAmount,
  programApplied,
  programCode,
  storedCurrencyAmount,
  storedCurrencyApplied,
}) => {
  return (
    <>
      <PriceRow label={programCode} value={programAmount} negativeValue hide={!programApplied} />
      <PriceRow
        label="Gift"
        value={storedCurrencyAmount / 100}
        negativeValue
        hide={!storedCurrencyApplied}
      />
    </>
  )
}

export const DeliveryFeeContribution = ({
  deliveryFeeContribution,
  isCatering,
  isReserveConfirmedDropoff,
}) => {
  const classes = useStyles()
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {!isCatering ? (
            <Tooltip
              interactive
              disableFocusListener
              disableTouchListener
              title={<ReserveFeeNotice isReserveConfirmedDropoff={isReserveConfirmedDropoff} />}
              classes={{ tooltip: classes.customTooltip }}
            >
              <Typography>Delivery Fee</Typography>
            </Tooltip>
          ) : (
            <Box>
              <Typography>Delivery Fee</Typography>
            </Box>
          )}
        </Box>
        <PriceRow label="" value={deliveryFeeContribution} />
      </Box>
    </>
  )
}

const PriceBreakdown = props => {
  const {
    orderTotal,
    deliveryFeeContribution,
    isReserveConfirmedDropoff,
    isOrderSummary,
    isCatering,
    orderFee,
    taxes = 0,
  } = props
  return (
    <>
      <Box marginY={3}>
        <Divider />
      </Box>
      <SubtotalPreTax {...props} />
      {deliveryFeeContribution > 0 ? (
        <DeliveryFeeContribution
          deliveryFeeContribution={deliveryFeeContribution}
          isReserveConfirmedDropoff={isReserveConfirmedDropoff}
          isOrderSummary={isOrderSummary}
          isCatering={isCatering}
        />
      ) : (
        <></>
      )}
      <FeesAndTaxes orderFee={orderFee} taxes={taxes} />
      <ProgramsAndGifts {...props} />
      <Box marginY={3}>
        <Divider />
      </Box>

      <PriceRow primary label="Order Total" value={orderTotal} />
    </>
  )
}

export default PriceBreakdown
