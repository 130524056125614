import { isFuture, isPast } from 'date-fns'

export const getProgramDays = schedule => {
  if (isAllWeekDays(schedule)) {
    return 'All weekdays'
  }

  const {
    mondayUsage,
    tuesdayUsage,
    wednesdayUsage,
    thursdayUsage,
    fridayUsage,
    saturdayUsage,
    sundayUsage,
  } = schedule

  let result = []
  if (mondayUsage) {
    result.push('M')
  }
  if (tuesdayUsage) {
    result.push('T')
  }
  if (wednesdayUsage) {
    result.push('W')
  }
  if (thursdayUsage) {
    result.push('Th')
  }
  if (fridayUsage) {
    result.push('F')
  }
  if (saturdayUsage) {
    result.push('S')
  }
  if (sundayUsage) {
    result.push('Su')
  }

  return result
    .map((day, index) => {
      if (index !== 0) {
        return `- ${day}`
      }

      return day
    })
    .join(' ')
}

export const getProgramStatus = program => {
  if (!program.startDate) {
    console.error('Invalid program: startDate is required.')
    return
  }

  if (isProgramUpcoming(program)) {
    return 'upcoming'
  }
  if (isProgramInactive(program)) {
    return 'inactive'
  }
  if (isProgramActive(program)) {
    return 'active'
  }
}

export const isProgramActive = program => {
  const pastStartDate = isPast(program.startDate)
  const beforeEndDate = !program.endDate || isFuture(program.endDate)

  return pastStartDate && beforeEndDate
}
export const isProgramInactive = program => program.endDate && !isFuture(program.endDate)
export const isProgramUpcoming = program => !isPast(program.startDate)

const isAllWeekDays = ({
  mondayUsage,
  tuesdayUsage,
  wednesdayUsage,
  thursdayUsage,
  fridayUsage,
  saturdayUsage,
  sundayUsage,
}) =>
  mondayUsage &&
  tuesdayUsage &&
  wednesdayUsage &&
  thursdayUsage &&
  fridayUsage &&
  !saturdayUsage &&
  !sundayUsage
