import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { object, string } from 'yup'

import ButtonSubmit from '../../common/ButtonSubmit'

const schema = object().shape({
  password: string().required('Password is required'),
})

const DialogChangeEmail = ({ onClose, onSubmit, submitting, errorUpdatingPersonalInfo }) => {
  const [error, setError] = useState('')

  useEffect(() => {
    if (errorUpdatingPersonalInfo) {
      // Service returns an error saying username and password are invalid
      // But we are only asking for the password in this dialog
      if (errorUpdatingPersonalInfo?.message?.includes('Invalid username or password')) {
        setError('Invalid password')
      } else {
        setError(errorUpdatingPersonalInfo.message)
      }
    }
  }, [errorUpdatingPersonalInfo])

  return (
    <Formik
      initialValues={{
        password: '',
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ errors, handleBlur, handleChange, resetForm, touched, values }) => (
        <Dialog
          open
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="sm"
          onClose={onClose}
          onEntering={() => {
            setError('')
            resetForm()
          }}
        >
          <Form>
            <DialogTitle>Confirmation</DialogTitle>

            <DialogContent>
              <DialogContentText>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                You've edited your email address. Please enter your password to confirm.
              </DialogContentText>
              <TextField
                autoFocus
                error={touched.password && Boolean(errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                id="loginPassword"
                label="Password"
                margin="dense"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
                color="secondary"
              />
            </DialogContent>

            <DialogActions>
              <Grid component={Box} container justifyContent="flex-end" px={2} py={3} spacing={3}>
                <Grid item>
                  <Button onClick={onClose}>Cancel</Button>
                </Grid>
                <Grid item>
                  <ButtonSubmit color="primary" submitting={submitting} variant="contained">
                    Confirm
                  </ButtonSubmit>
                </Grid>
              </Grid>
            </DialogActions>

            {error && <Alert severity="error">{error}</Alert>}
          </Form>
        </Dialog>
      )}
    </Formik>
  )
}

export default DialogChangeEmail
