import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Typography, CircularProgress as MuiCircularProgress } from '@material-ui/core'

import { themeConWeb } from '@app/util/modernThemeConweb'

const useStyles = makeStyles({
  top: {
    color: ({ color }) => color,
    position: 'absolute',
    left: 0,
    zIndex: 2,
  },
  bottom: {
    color: themeConWeb.color.greyShade1,
  },
  label: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    fontSize: ({ labelSize }) => labelSize,
    fontWeight: 'bold',
  },
})

const CircularProgress = ({ percentage, color, thickness, size, hideLabel, labelSize }) => {
  const classes = useStyles({ color, labelSize })
  return (
    <Box position="relative" display="inline-flex">
      <MuiCircularProgress
        className={classes.top}
        variant="static"
        value={percentage}
        thickness={thickness}
        size={size}
      />
      <MuiCircularProgress
        className={classes.bottom}
        variant="determinate"
        value={100}
        thickness={thickness}
        size={size}
      />
      {!hideLabel && <Typography className={classes.label}>{percentage}%</Typography>}
    </Box>
  )
}

export default CircularProgress
