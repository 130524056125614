import { Card } from '@material-ui/core'
import cn from 'classnames'
import React from 'react'

import { SimpleSpinner } from './SimpleSpinner'

export const SpinnerCard = ({ children, className, size, spinnerActive, ...rest }) => (
  <Card className={className} {...rest}>
    {spinnerActive ? <SimpleSpinner size={size} /> : children}
  </Card>
)

export const Article = ({ children, classnames }) => (
  <article className={cn('article', classnames)}>{children}</article>
)

export const ArticleLarge = ({ children }) => <article className="article-lg">{children}</article>

export const Segment = ({ children }) => <section className="segment">{children}</section>

export const Panel = ({ children }) => <section className="panel">{children}</section>

export const Sidebar = ({ children }) => <aside className="aside">{children}</aside>
