import { Divider, List, ListItem, ListItemText } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import CardTeam, { CardTeamContent, CardTeamTitle } from '../common/CardTeam'

const CardAccountAdmins = ({ admins }) => {
  return (
    <CardTeam>
      <CardTeamTitle>Account Admins</CardTeamTitle>
      <CardTeamContent>
        {admins ? (
          <List>
            {admins.map((admin, index) => (
              <>
                <ListItem key={admin.id}>
                  <ListItemText
                    primary={
                      admin.firstName ? `${admin.firstName} ${admin.lastName}` : admin.username
                    }
                    secondary={admin.firstName ? admin.username : null}
                  />
                </ListItem>
                {index !== admins.length - 1 ? <Divider /> : null}
              </>
            ))}
          </List>
        ) : (
          <Skeleton />
        )}
      </CardTeamContent>
    </CardTeam>
  )
}

export default CardAccountAdmins
