import { Box, Typography, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { themeConWeb } from '@app/util/modernThemeConweb'
import React from 'react'
import { format, isToday, parse } from 'date-fns'
import styled from 'styled-components'
import { Skeleton } from '@material-ui/lab'
import { formatUrl } from '../../util/formatUtils'
import { confirmationRoute } from '../../routes/routes'
import {
  excludeReplacedCanceledOrders,
  orderStateText,
  sortOrdersFromEarliestToLatest,
} from './utils/orderUtils'

const UpcomingOrdersContainer = styled.div`
  max-height: 55vh;
  overflow-y: auto;
  width: 240px;
`

const HomeFutureOrders = ({ futureOrders, locationId, loadingFutureOrders }) => {
  if (loadingFutureOrders || !futureOrders) {
    return <SkeletonLoadingFutureOrders />
  }
  const sortedOrders = sortOrdersFromEarliestToLatest(futureOrders)
  const filteredOrders = excludeReplacedCanceledOrders(sortedOrders)
  return (
    <UpcomingOrdersContainer>
      <FutureOrdersTitle />
      {futureOrders.length === 0 && <NoFutureOrdersMessage />}
      <FutureOrdersList orders={filteredOrders} locationId={locationId} />
    </UpcomingOrdersContainer>
  )
}

const NoFutureOrdersMessage = () => {
  return (
    <Box marginTop={'1em'}>
      <Typography
        style={{
          fontSize: themeConWeb.fontSizes.basePlus1,
        }}
      >
        No orders.
      </Typography>
    </Box>
  )
}

const FutureOrdersList = ({ orders, locationId }) => {
  return (
    <Box marginTop={'1em'}>
      {orders.map(order => {
        const orderId = order.orderId
        const groupOrderId = order.groupOrderId
        const isExternal = Boolean(groupOrderId)
        const manageOrderLink = groupOrderId
          ? `${process.env.REACT_APP_GROUP_ORDER_URL}/group-order/${order.groupOrderId}`
          : formatUrl(confirmationRoute.path, {
              locationId,
              orderId,
            })

        return (
          <Box key={orderId} marginBottom={'1em'}>
            <FutureOrderRestaurantName restaurantName={order.restaurantName} />
            <OrderStatusAndTimeText orderStatus={order.statusText} order={order} />
            <Box>
              <FutureOrderManageLink link={manageOrderLink} isExternal={isExternal} />
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

const FutureOrderRestaurantName = ({ restaurantName }) => {
  return (
    <Typography
      style={{
        fontWeight: 700,
        fontSize: themeConWeb.fontSizes.basePlus1,
      }}
    >
      {restaurantName}
    </Typography>
  )
}

const FutureOrderManageLink = ({ link, isExternal }) => {
  if (isExternal) {
    return (
      <Link
        component="a"
        href={link}
        style={{
          color: themeConWeb.color.dangerLight,
          fontSize: `${themeConWeb.fontSizes.baseMinus1}`,
          fontWeight: '400',
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        Manage
      </Link>
    )
  }

  return (
    <Link
      component={RouterLink}
      to={link}
      style={{
        color: themeConWeb.color.dangerLight,
        fontSize: `${themeConWeb.fontSizes.baseMinus1}`,
        fontWeight: '400',
      }}
    >
      Manage
    </Link>
  )
}

const FutureOrdersTitle = () => {
  return (
    <Typography
      style={{
        textTransform: 'capitalize',
        color: themeConWeb.color.greyShade3,
        fontWeight: 500,
        fontSize: themeConWeb.fontSizes.base,
        lineHeight: '16px',
        letterSpacing: '1.5px',
      }}
      color="textSecondary"
    >
      Upcoming Orders
    </Typography>
  )
}

const OrderStatusAndTimeText = ({ orderStatus, order }) => {
  const parsedOrderDate = parse(order.orderDateTime)
  const parsedDeliveredDate = parse(order.deliveredDateTime)
  if (!orderStatus) {
    return <OrderTimeText orderTime={convertOrderDateToString(parsedOrderDate)} prependWith={''} />
  }

  if (
    orderStatus.includes(orderStateText.CREATED) ||
    orderStatus.includes(orderStateText.ON_TIME)
  ) {
    return (
      <Box display={'flex'} flexDirection={'row'}>
        {isToday(parsedOrderDate) && (
          <OrderStatusText text={'Estimated'} textColor={themeConWeb.color.success} />
        )}
        <OrderTimeText orderTime={convertOrderDateToString(parsedOrderDate)} prependWith={''} />
      </Box>
    )
  }

  if (orderStatus.includes(orderStateText.DELIVERED)) {
    return (
      <Box display={'flex'} flexDirection={'row'}>
        <OrderStatusText text={'Delivered'} textColor={themeConWeb.color.success} />
        <OrderTimeText orderTime={format(parsedDeliveredDate, 'h:mm A')} prependWith={'at '} />
      </Box>
    )
  }

  if (orderStatus.includes(orderStateText.CANCELED)) {
    return <OrderStatusText text={'Canceled'} textColor={themeConWeb.color.dangerLight} />
  }

  if (orderStatus.includes(orderStateText.LATE)) {
    return (
      <Box display={'flex'} flexDirection={'row'}>
        <OrderStatusText text={'Late'} textColor={themeConWeb.color.dangerLight} />
        <OrderTimeText orderTime={format(parsedOrderDate, 'h:mm A')} prependWith={'Now at '} />
      </Box>
    )
  }

  return null
}

const OrderStatusText = ({ text, textColor }) => {
  return (
    <Typography
      style={{
        fontSize: themeConWeb.fontSizes.base,
        fontWeight: 700,
        color: textColor,
        marginRight: '5px',
      }}
    >
      {text}
    </Typography>
  )
}

const OrderTimeText = ({ orderTime, prependWith }) => {
  return (
    <Typography
      style={{
        fontSize: themeConWeb.fontSizes.base,
        fontWeight: 400,
        color: themeConWeb.color.greyShade7,
      }}
    >
      {prependWith}
      {orderTime}
    </Typography>
  )
}

const convertOrderDateToString = orderDate => {
  if (isToday(orderDate)) {
    return `Today at ${format(orderDate, 'h:mm A')}`
  } else {
    return format(orderDate, 'ddd, MMMM DD [at] h:mm A')
  }
}

const SkeletonLoadingFutureOrders = () => {
  return (
    <Box width={'240px'}>
      <FutureOrdersTitle />
      <Box marginTop={'1em'}>
        <Box marginBottom={'1em'}>
          <Skeleton height={30} />
          <Skeleton height={30} width={250} style={{ marginTop: '-0.5em' }} />
          <Skeleton height={30} width={50} style={{ marginTop: '-0.5em' }} />
        </Box>
        <Box marginBottom={'1em'}>
          <Skeleton height={30} />
          <Skeleton height={30} width={250} style={{ marginTop: '-0.5em' }} />
          <Skeleton height={30} width={50} style={{ marginTop: '-0.5em' }} />
        </Box>
        <Box marginBottom={'1em'}>
          <Skeleton height={30} />
          <Skeleton height={30} width={250} style={{ marginTop: '-0.5em' }} />
          <Skeleton height={30} width={50} style={{ marginTop: '-0.5em' }} />
        </Box>
      </Box>
    </Box>
  )
}

export default HomeFutureOrders
