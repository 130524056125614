import { getAccountApi, updateAccountApi, upgradeToPreferredApi } from '../../services/account'
import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import { enqueueSnackbar } from './snackbar'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const ACCOUNT_SET = 'foodsby/account/ACCOUNT_SET'
export const ACCOUNT_UPDATE = 'foodsby/account/ACCOUNT_UPDATE'
export const ACCOUNT_UPGRADE_TO_PREFERRED = 'foodsby/account/ACCOUNT_UPGRADE_TO_PREFERRED'
export const RESET_DONE_UPGRADING = 'foodsby/account/RESET_DONE_UPGRADING'

export const setAccount = createAsyncAction(ACCOUNT_SET)
export const updateAccount = createAsyncAction(ACCOUNT_UPDATE)
export const resetDoneUpgrading = createAction(RESET_DONE_UPGRADING)
export const upgradeToPreferredPending = createAction(PENDING(ACCOUNT_UPGRADE_TO_PREFERRED))
export const upgradeToPreferredFulfilled = createAction(FULFILLED(ACCOUNT_UPGRADE_TO_PREFERRED))
export const upgradeToPreferredRejected = createAction(REJECTED(ACCOUNT_UPGRADE_TO_PREFERRED))

// ------------------------------------
// Thunks
// ------------------------------------
export const loadAccountStart = accountId => {
  return dispatch => {
    return dispatch(setAccount(getAccountApi(accountId)))
  }
}

export const updateAccountStart = (accountId, changes) => {
  return async dispatch => {
    try {
      await dispatch(updateAccount(updateAccountApi(accountId, changes)))
    } catch (err) {
      dispatch(enqueueSnackbar({ message: err.message }))
    }
  }
}

export const upgradeToPreferredStart = (accountId, payload) => {
  return async dispatch => {
    try {
      dispatch(upgradeToPreferredPending())
      await upgradeToPreferredApi(accountId, payload)
      dispatch(upgradeToPreferredFulfilled())
    } catch (ex) {
      dispatch(upgradeToPreferredRejected(ex))
    }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PENDING(ACCOUNT_SET)]: state => {
    return {
      ...state,
      isLoadingAccount: true,
    }
  },
  [FULFILLED(ACCOUNT_SET)]: (state, action) => {
    return {
      ...state,
      account: action.payload,
      isLoadingAccount: false,
      accountInitialized: true,
      errorLoadingAccount: undefined,
    }
  },
  [REJECTED(ACCOUNT_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingAccount: false,
      accountInitialized: true,
      errorLoadingAccount: action.error,
    }
  },
  [PENDING(ACCOUNT_UPDATE)]: state => {
    return {
      ...state,
      isLoadingAccount: true,
      errorUpdatingAccount: false,
    }
  },
  [FULFILLED(ACCOUNT_UPDATE)]: (state, action) => {
    return {
      ...state,
      account: action.payload,
      errorUpdatingAccount: null,
      isLoadingAccount: false,
    }
  },
  [REJECTED(ACCOUNT_UPDATE)]: (state, action) => {
    return {
      ...state,
      isLoadingAccount: false,
      errorUpdatingAccount: action.error,
    }
  },
  [PENDING(ACCOUNT_UPGRADE_TO_PREFERRED)]: state => {
    return {
      ...state,
      isUpgradingAccountToPreferred: true,
    }
  },
  [FULFILLED(ACCOUNT_UPGRADE_TO_PREFERRED)]: state => {
    return {
      ...state,
      isUpgradingAccountToPreferred: false,
      isDoneUpgrading: true,
    }
  },
  [REJECTED(ACCOUNT_UPGRADE_TO_PREFERRED)]: (state, action) => {
    return {
      ...state,
      isUpgradingAccountToPreferred: false,
      errorUpgradingAccountToPreferred: action.payload.data.message,
    }
  },
  [RESET_DONE_UPGRADING]: state => {
    return { ...state, isDoneUpgrading: false }
  },
}

const initialState = {
  account: undefined,
  accountInitialized: false,
  isLoadingAccount: true,
  isUpgradingAccountToPreferred: false,
  errorLoadingAccount: undefined,
  errorUpdatingAccount: undefined,
  errorUpgradingAccountToPreferred: undefined,
  isDoneUpgrading: false,
}

export default function account(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
