import { api } from '../api/api'

// Constants
const ACCOUNTS_V1 = '/api/v1/accounts'

export const getAccountApi = accountId => api.get(`${ACCOUNTS_V1}/${accountId}`)
export const updateAccountApi = (accountId, changes) =>
  api.put(`${ACCOUNTS_V1}/${accountId}`, changes)
export const upgradeToPreferredApi = (accountId, payload) =>
  api.post(`${ACCOUNTS_V1}/${accountId}/request/preferred`, payload)
