import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { isEmpty, isNil } from 'lodash'

const StyledTableCell = withStyles(theme => ({
  root: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    maxWidth: '80px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(TableCell)

const TableRowData = withStyles({
  root: {
    '&:hover': {
      cursor: ({ hover }) => (hover ? 'pointer' : 'auto'),
    },
  },
})(TableRow)

const TableRowNoData = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(TableRow)

const TableSimple = ({
  columns,
  rowCells,
  page,
  totalElements,
  rowsPerPage,
  ariaLabel,
  noDataText = 'No data found',
  onChangePage,
  onRowClick,
}) => {
  return (
    <>
      <TableContainer>
        <Table size="small" aria-label={ariaLabel}>
          <TableHead>
            <TableRow>
              {columns.map(({ id, label, align }) => (
                <StyledTableCell key={id} align={align}>
                  {label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isEmpty(rowCells) && (
              <TableRowNoData>
                <StyledTableCell colSpan={columns.length} align="center">
                  {noDataText}
                </StyledTableCell>
              </TableRowNoData>
            )}

            {!isEmpty(rowCells) &&
              rowCells.map(rowCell => (
                <TableRowData
                  key={rowCell.id}
                  hover={!isNil(onRowClick)}
                  onClick={!isNil(onRowClick) ? () => onRowClick(rowCell) : undefined}
                >
                  {rowCell.cells.map(({ labelId, Component, align }, index) => (
                    <StyledTableCell key={index} id={labelId} align={align}>
                      {Component}
                    </StyledTableCell>
                  ))}
                </TableRowData>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalElements > rowsPerPage && (
        <TablePagination
          component="div"
          count={totalElements}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          onChangePage={onChangePage}
        />
      )}
    </>
  )
}

export default TableSimple
