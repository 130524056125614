import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { themeConWeb } from '@app/util/modernThemeConweb'
import { convertTo12HourFormat } from '../../redux/selectors/utils/time'
import { orderStateText } from '../home/utils/orderUtils'

export const OrderStatusAndTimeText = ({ orderStatus, order }) => {
  if (
    orderStatus.includes(orderStateText.CREATED) ||
    orderStatus.includes(orderStateText.ON_TIME)
  ) {
    return (
      <Box display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h3"
          style={{ fontSize: themeConWeb.fontSizes.basePlus2, fontWeight: 700 }}
        >
          Est. Delivery
        </Typography>
        <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus2 }}>
          at {order.expectedDeliveryTimeDisplay}
        </Typography>
      </Box>
    )
  }

  if (orderStatus.includes(orderStateText.DELIVERED)) {
    return (
      <Box display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h3"
          style={{ fontSize: themeConWeb.fontSizes.basePlus2, fontWeight: 700 }}
        >
          Delivered
        </Typography>
        <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus2 }}>
          at {convertTo12HourFormat(order.deliveryTime.split('.')[0])}
        </Typography>
      </Box>
    )
  }

  if (orderStatus.includes(orderStateText.CANCELED)) {
    return (
      <Box display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h3"
          style={{
            fontSize: themeConWeb.fontSizes.basePlus2,
            fontWeight: 700,
            color: themeConWeb.color.dangerLight,
          }}
        >
          Canceled
        </Typography>
      </Box>
    )
  }

  if (orderStatus.includes(orderStateText.LATE)) {
    return (
      <Box display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h3"
          style={{
            fontSize: themeConWeb.fontSizes.basePlus2,
            fontWeight: 700,
          }}
        >
          Late
        </Typography>
        <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus2 }}>
          Now at {order.expectedDeliveryTimeDisplay}
        </Typography>
      </Box>
    )
  }
}
