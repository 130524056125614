import { flatten } from 'lodash'
import { call, take } from 'redux-saga/effects'

import { api, connectionsCache, shareablesCache } from '../../api/api'
import { normalizeUserCreationError } from '../../util/errorUtils'
import { apiSaga } from './sagas'

// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const GET_FRIENDS_ORDERS = 'foodsby/friends/GET_FRIENDS_ORDERS'
export const GET_FRIENDS_ORDERS_SUCCESS = 'foodsby/friends/GET_FRIENDS_ORDERS_SUCCESS'
export const GET_FRIENDS_ORDERS_FAILURE = 'foodsby/friends/GET_FRIENDS_ORDERS_FAILURE'

export const REFERRAL_SUCCESS = 'foodsby/friends/REFERRAL_SUCCESS'
export const REFERRAL_ACKNOWLEDGE = 'foodsby/friends/REFERRAL_ACKNOWLEDGE'

export const getFriendsOrdersStart = (locationId, date = '', options = {}) => {
  return {
    payload: { locationId, date, options },
    type: GET_FRIENDS_ORDERS,
  }
}

export const getFriendsOrdersSuccess = response => {
  return {
    payload: { response },
    type: GET_FRIENDS_ORDERS_SUCCESS,
  }
}

export const getFriendsOrdersFailure = error => {
  return {
    error,
    type: GET_FRIENDS_ORDERS_FAILURE,
  }
}
export const confirmReferralSuccess = redemptionResult => {
  return {
    payload: { redemptionResult },
    type: REFERRAL_SUCCESS,
  }
}

export const acknowledgeReferral = () => {
  return {
    type: REFERRAL_ACKNOWLEDGE,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [GET_FRIENDS_ORDERS]: state => {
    return {
      ...state,
      isLoadingFriendsOrders: true,
    }
  },
  [GET_FRIENDS_ORDERS_FAILURE]: (state, action) => {
    const error = normalizeUserCreationError(action.error.response)
    return {
      ...state,
      friendsOrdersError: error,
      isLoadingFriendsOrders: false,
    }
  },
  [GET_FRIENDS_ORDERS_SUCCESS]: (state, action) => {
    let friendNotOrdered
    let friendHasOrdered = []
    let friendsOrderCount = []

    let friendsOrdered = action.payload.response

    if (action.payload.response) {
      action.payload.response.forEach(dropoff => {
        if (dropoff.dropoffId === null) {
          friendNotOrdered = dropoff.friendsOrders
        } else if (dropoff.dropoffId !== null) {
          friendHasOrdered.push(dropoff)
          friendsOrderCount.push(dropoff.friendsOrders)
        }
      })
    }
    friendsOrderCount = flatten(friendsOrderCount)
    return {
      ...state,
      friendsOrdered,
      friendHasOrdered,
      friendNotOrdered,
      friendsOrderCount,
      friendsOrdersError: undefined,
      isLoadingFriendsOrders: false,
    }
  },
  [REFERRAL_ACKNOWLEDGE]: state => {
    return {
      ...state,
      referralSuccess: undefined,
    }
  },
  [REFERRAL_SUCCESS]: (state, action) => {
    const { redemptionResult } = action.payload
    return {
      ...state,
      redemptionResult,
      referralSuccess: true,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  favorites: [],
  suggestions: [],
  friendsOrdered: [],
}

export default function friends(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* watchGetFriendsOrders() {
  while (true) {
    const {
      payload: { locationId, date },
    } = yield take(GET_FRIENDS_ORDERS)
    yield call(
      apiSaga,
      getFriendsOrders,
      [locationId, date],
      getFriendsOrdersSuccess,
      getFriendsOrdersFailure,
    )
  }
}

// ------------------------------------
// APIs
// ------------------------------------

export const getFriendsOrders = (locationId, date, options = {}) =>
  api.get(`/api/v1/accounts/order-activity/${locationId}/${date}`, null, {
    cache: connectionsCache,
    ...options,
  })

export const referralGet = () =>
  api.get(`/api/v1/shareables/referrals`, null, { cache: shareablesCache })

export const referralGetWithEnabledDetails = () =>
  api.get(`/api/v1/shareables/referrals/enabledDetails`, null, {
    cache: shareablesCache,
  })

export const referralGetDetails = referralCode =>
  api.get(`/api/v1/shareables/referrals/${referralCode}`, null, {
    cache: shareablesCache,
  })

export const getReferralsEnabled = locationId =>
  api.get(`/api/v1/shareables/referrals-enabled?locationId=${locationId}`, null, {
    cache: shareablesCache,
  })

export const referralsGetPreviouslyRedeemed = () =>
  api.get('/api/v1/shareables/referrals/history/redemptions')

export const referralRedeem = referralCode =>
  api.post(`/api/v2/shareables/referrals/${referralCode}/redemptions`)
