import { createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import {
  getOfficesListApi,
  getAllPreferredOfficesForWhichUserIsAnAdmin,
  getEmailDomains,
} from '../../services/offices'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const OFFICES_SET = 'foodsby/offices/OFFICES_SET'
export const PREFERRED_OFFICES_SET = 'foodsby/offices/PREFERRED_OFFICES_SET'
export const EMAIL_DOMAINS_SET = 'foodsby/offices/EMAIL_DOMAINS_SET'

export const setOffices = createAsyncAction(OFFICES_SET)
export const setAllPreferredOffices = createAsyncAction(PREFERRED_OFFICES_SET)
export const setEmailDomains = createAsyncAction(EMAIL_DOMAINS_SET)

// ------------------------------------
// Thunks
// ------------------------------------
export const loadOfficesStart = locationId => {
  return async dispatch => {
    return await dispatch(setOffices(getOfficesListApi(locationId)))
  }
}

export const loadPreferredOfficesStart = () => {
  return async dispatch => {
    return await dispatch(setAllPreferredOffices(getAllPreferredOfficesForWhichUserIsAnAdmin()))
  }
}

export const loadEmailDomainsStart = accountId => {
  return async dispatch => {
    return await dispatch(setEmailDomains(getEmailDomains(accountId)))
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PENDING(OFFICES_SET)]: state => {
    return {
      ...state,
      isLoadingOffices: true,
    }
  },
  [FULFILLED(OFFICES_SET)]: (state, action) => {
    return {
      ...state,
      offices: action.payload,
      isLoadingOffices: false,
      errorLoadingOffices: undefined,
    }
  },
  [FULFILLED(PREFERRED_OFFICES_SET)]: (state, action) => {
    const innerState = {
      ...state,
      preferredOffices: action.payload,
    }
    return innerState
  },
  [REJECTED(PREFERRED_OFFICES_SET)]: (state, action) => {
    return {
      ...state,
      preferredOffices: [],
    }
  },
  [PENDING(EMAIL_DOMAINS_SET)]: state => {
    return {
      ...state,
      isLoadingDomains: true,
    }
  },
  [FULFILLED(EMAIL_DOMAINS_SET)]: (state, action) => {
    return {
      ...state,
      emailDomains: action.payload.emailDomains,
      isLoadingDomains: false,
      errorLoadingDomains: undefined,
    }
  },
  [REJECTED(EMAIL_DOMAINS_SET)]: (state, action) => {
    return {
      ...state,
      domains: undefined,
      isLoadingDomains: false,
      errorLoadingDomains: action.error,
    }
  },
}

export const initialState = {
  offices: [],
  isLoadingOffices: true,
  errorLoadingOffices: undefined,
  preferredOffices: [],
  emailDomains: undefined,
  isLoadingDomains: true,
  errorLoadingDomains: undefined,
}

export default function offices(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
