import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CardTeam from '../teams/common/CardTeam'
import { locationStatusDisplayText, locationStatusOptions } from './utils'

const useStyles = makeStyles({
  status: { fontWeight: 500, fontSize: '23px', paddingBottom: '20px' },
  preferred: { color: 'green' },
  nonPreferred: { color: 'red' },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '20px',
    marginTop: '20px',
  },
})

const LocationStatus = ({ locationStatus }) => {
  const classes = useStyles()
  const locationStatusDisplay = locationStatusDisplayText(locationStatus)
  return (
    <CardTeam>
      <Box className={classes.box}>
        <Typography className={classes.status}>
          Location Status:{' '}
          <span
            className={
              locationStatusDisplay === locationStatusOptions.Preferred
                ? classes.preferred
                : classes.nonPreferred
            }
          >
            {locationStatusDisplay}{' '}
          </span>
        </Typography>
      </Box>
    </CardTeam>
  )
}
export default LocationStatus
