import { Input as NutrientInput, InputError } from '@foodsby/nutrient'
import React from 'react'

/* This is a wrapper specifically for redux-forms. DO NOT use this with other libraries, just use nutrient. */
export const Input = ({ input = {}, id, className, meta: { error, touched } = {}, ...other }) => (
  <div className={className}>
    <NutrientInput id={id} name={input.name} {...input} {...other} />
    <InputError>{touched && error}</InputError>
  </div>
)

export default Input
