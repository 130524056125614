import React from 'react'

import { Box, Typography } from '@material-ui/core'
import { themeConWeb } from '../../util/modernThemeConweb'
import { useMyReferralWithEnabledDetails } from '../../hooks/referral'
import { ConfirmationCopyReferral } from '../community/Referral'
import { SpinnerCard } from './Layout'

function createConfirmationMessage(friendsOrders, maximumOrders, maximumPercentage) {
  const friendsOrdersCount = friendsOrders.length
  const currentOrderCount = friendsOrdersCount + 1
  if (currentOrderCount >= maximumOrders) {
    return (
      <>
        Congrats - everyone earns <b>{maximumPercentage * 100}%</b>!
      </>
    )
  } else {
    return (
      <>
        Everyone earns up to <b>{maximumPercentage * 100}%</b> when{' '}
        <b>{maximumOrders - currentOrderCount}</b> more customers order!
      </>
    )
  }
}

const RewardsAndReferralsLinkCard = ({
  maximumPercentage,
  maximumOrders,
  storeName,
  loading,
  friendsOrders,
}) => {
  const { referralAndEnabledDetails } = useMyReferralWithEnabledDetails()
  let confirmationMessage = ''
  if (!loading) {
    confirmationMessage = createConfirmationMessage(friendsOrders, maximumOrders, maximumPercentage)
  }

  return (
    <SpinnerCard spinnerActive={loading} style={{ border: '6px solid #6E8D28' }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus4, fontWeight: 700 }}>
          Order Together, Earn Together
        </Typography>
        <Box marginBottom={'1.5em'}>
          <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus2 }}>
            {confirmationMessage}
          </Typography>
        </Box>

        <ConfirmationCopyReferral
          referralAndEnabledDetails={referralAndEnabledDetails}
          storeName={storeName}
        />
      </Box>
    </SpinnerCard>
  )
}

export default RewardsAndReferralsLinkCard
