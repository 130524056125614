import { Box, Card, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { cancelEditingOrder, loadEditOrder } from '../../redux/modules/cart'
import { clearMenuItem } from '../../redux/modules/menu'
import {
  selectCurrentLocationWithAddress,
  selectMenuItemForEdit,
} from '../../redux/modules/selectors'
import { OrderCart } from '../cart/Cart'
import ButtonSubmit from '../common/ButtonSubmit'
import StickyContainer from '../common/StickyContainer'
import PersonalizeOrder from '../personalize-order/PersonalizeOrder'
import RestaurantDetailsSidebar from '../common/RestaurantDetailsSidebar'

const useStyles = makeStyles({
  card: {
    padding: '0',
  },
  orderInfoText: {
    fontWeight: 'bold',
  },
})

export const ReserveOrderSidebar = ({
  shouldDisplayNextButton,
  isEditingOrder,
  isEditingPastOrder,
  isCartSaving,
  isLoadingOrderForEdit,
  isLocationLoading,
  isOrderCartLoading,
  isReserveStoreLoading,
  loadEditOrder,
  reserveStore,
  location,
  menuItemForEdit,
  normalizedMenuItem,
  orderCart,
  orderCartError,
  handleClose,
  handleNext,
  handleRemoveItemFromCart,
  handleAddItemsToCart,
  handleEditOrder,
  nextButtonDisplayText,
  personalizeOrderAddMoreIsPrimary,
  personalizeOrderNextButtonIsHidden,
  handleNextButtonClick,
  handleAddItemButtonClick,
  showRestaurantLogo,
}) => {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState(null)

  const isLoading = isLocationLoading || isOrderCartLoading || isReserveStoreLoading

  const onSubmit = () => {
    if (orderCart.orderItems.length === 0) {
      setErrorMessage('Please add items to your cart.')
    } else {
      setErrorMessage(null)
      handleNext()
    }
  }

  useEffect(() => {
    if (orderCartError) {
      setErrorMessage(orderCartError.message)
    } else {
      setErrorMessage(null)
    }
  }, [orderCartError, orderCart, reserveStore])

  return (
    <StickyContainer bottomOffset={18} stickyStyle={{ paddingTop: '100px' }} topOffset={-100}>
      <Card className={classes.card}>
        {isLoading && <SkeletonLoading />}

        {!isLoading && showRestaurantLogo && reserveStore?.restaurantName && reserveStore?.logoUrl && (
          <>
            <Box paddingLeft={3} paddingTop={3}>
              <Typography color="textSecondary" gutterBottom variant="overline">
                Restaurant
              </Typography>
            </Box>
            <Box paddingLeft={3} paddingTop={3}>
              <RestaurantDetailsSidebar
                logoLink={reserveStore.logoUrl}
                name={reserveStore.restaurantName}
              />
            </Box>
            <Box paddingLeft={3} paddingRight={3} paddingTop={3}>
              <Divider />
            </Box>
          </>
        )}

        {!isLoading && (
          <Box padding={3} margin={3}>
            {orderCart && (
              <OrderCart
                cart={orderCart}
                deleteMenuItem={handleRemoveItemFromCart}
                isCartSaving={isCartSaving}
                editable="true"
                editMenuItem={loadEditOrder}
                isLoadingOrderForEdit={isLoadingOrderForEdit}
                isEditingPastOrder={false}
                showEditLinks="true"
                orderMinimumInCents={reserveStore?.moneyMinimumInCents}
                showQuantity="true"
                deliveryFee={reserveStore?.deliveryFeeInCents}
                isConfirmedReserveDropoff={false}
                isCatering={false}
              />
            )}
          </Box>
        )}
      </Card>

      {shouldDisplayNextButton && (
        <ButtonSubmit
          color="primary"
          fullWidth
          onClick={onSubmit}
          errorMessage={errorMessage}
          variant="contained"
        >
          {nextButtonDisplayText}
        </ButtonSubmit>
      )}

      {!isEditingPastOrder && menuItemForEdit && isEditingOrder && (
        <PersonalizeOrder
          handleClose={handleClose}
          handleAddItemsToCart={handleAddItemsToCart}
          handleEditOrder={handleEditOrder}
          isEditingFromCheckout={false}
          isEditingFromMenu="true"
          isEditingOrder={isEditingOrder}
          isEditingPastOrder={false}
          locationId={location.deliveryLocationId}
          menuItemForEdit={menuItemForEdit}
          menuItemId={menuItemForEdit.menuItemId}
          normalizedMenuItem={normalizedMenuItem}
          nextButtonDisplayText={nextButtonDisplayText}
          addMoreIsPrimary={personalizeOrderAddMoreIsPrimary}
          handleNextButtonClick={handleNextButtonClick}
          nextButtonIsHidden={personalizeOrderNextButtonIsHidden}
          handleAddItemButtonClick={handleAddItemButtonClick}
          nextButtonIsDisabled={false}
          enableQuantity={false}
          addItemDisplayText={'Add to Cart'}
        />
      )}
    </StickyContainer>
  )
}

const SkeletonLoading = () => (
  <>
    <Skeleton height={150} variant="rect" />
    <Box padding={3}>
      <Skeleton height={30} width="80%" />
      <Skeleton height={30} width="80%" />
      <Box marginY={2}>
        <Skeleton height={40} width="90%" />
      </Box>
      <Skeleton height={50} />
    </Box>
  </>
)

const mapStateToProps = state => {
  const { normalizedMenuItem } = state.personalize
  const location = selectCurrentLocationWithAddress(state)
  const menuItemForEdit = selectMenuItemForEdit(state)
  const { isLocationLoading } = state.location
  const {
    isEditingOrder,
    isEditingPastOrder,
    isCartSaving,
    isLoadingOrderForEdit,
    isOrderCartLoading,
    orderCart,
    orderCartError,
  } = state.cart
  const { isReserveStoreLoading, reserveStore } = state.reserve
  return {
    isEditingOrder,
    isEditingPastOrder,
    isCartSaving,
    isLoadingOrderForEdit,
    isLocationLoading,
    isOrderCartLoading,
    isReserveStoreLoading,
    location,
    reserveStore,
    menuItemForEdit,
    normalizedMenuItem,
    orderCart,
    orderCartError,
  }
}

const mapDispatchToProps = {
  cancelEditingOrder,
  clearMenuItem,
  loadEditOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReserveOrderSidebar)
