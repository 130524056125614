import { flatten } from 'lodash'
import { call, put, select, take } from 'redux-saga/effects'

import { api } from '../../api/api'
import { normalizeDeliveryErrorMessage } from '../../util/errorUtils'
import { loadOrderByIdStart } from './orderDetail'
import { apiSaga, formSaga } from './sagas'
import { getCancelTicketDetails, getCurrentOrderDetails, selectReasons } from './selectors'

// see https://github.com/erikras/ducks-modular-redux
// for background on why these are grouped into 1 file

// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const LOAD = 'foodsby/ticket/reason/LOAD'
export const LOAD_BY_ORDER = 'foodsby/ticket/reason/LOAD_BY_ORDER'
export const LOAD_SUCCESS = 'foodsby/ticket/reason/LOAD_SUCCESS'
export const LOAD_ERROR = 'foodsby/ticket/reason/LOAD_ERROR'

export const SUBMIT = 'foodsby/ticket/SUBMIT'
export const SUBMIT_SUCCESS = 'foodsby/ticket/SUBMIT_SUCCESS'
export const SUBMIT_ERROR = 'foodsby/ticket/SUBMIT_ERROR'

export const CONFIRM_CANCEL = 'foodsby/ticket/CONFIRM_CANCEL'
export const CANCEL_CONFIRM_REQUEST = 'foodsby/ticket/CANCEL_CONFIRM_REQUEST'

export const SUBMIT_SELF_CANCEL = 'foodsby/ticket/SUBMIT_SELF_CANCEL'
export const SUBMIT_SELF_CANCEL_SUCCESS = 'foodsby/ticket/SUBMIT_SELF_CANCEL_SUCCESS'
export const SUBMIT_SELF_CANCEL_FAILURE = 'foodsby/ticket/SUBMIT_SELF_CANCEL_FAILURE'

export function loadReasons() {
  return { type: LOAD }
}

export function loadReasonsByOrder(orderId) {
  return {
    payload: orderId,
    type: LOAD_BY_ORDER,
  }
}

export function loadReasonsSucceeded(reasons) {
  const reasonOptions = flatten(
    reasons.map(reason => {
      return reason.options.map(option => {
        const { id, label } = option
        return {
          id,
          label,
          reasonTypeId: reason.id,
          reasonTypeLabel: reason.label,
        }
      })
    }),
  )
  return {
    payload: reasonOptions,
    type: LOAD_SUCCESS,
  }
}

export function loadReasonsFailed(error) {
  return {
    error,
    type: LOAD_ERROR,
  }
}

export function submitTicket(ticket) {
  return {
    payload: { ticket },
    type: SUBMIT,
  }
}

export function submitTicketSucceeded(ticketId) {
  return {
    payload: { ticketId },
    type: SUBMIT_SUCCESS,
  }
}

export function submitTicketFailed(error) {
  return {
    error,
    type: SUBMIT_ERROR,
  }
}

export function confirmCancelRequest(cancelTicket) {
  return {
    payload: { cancelTicket },
    type: CONFIRM_CANCEL,
  }
}

export function cancelConfirmRequest() {
  return {
    type: CANCEL_CONFIRM_REQUEST,
  }
}

export function submitSelfCancel(cancelTicket) {
  return {
    payload: { cancelTicket },
    type: SUBMIT_SELF_CANCEL,
  }
}

export function submitSelfCancelSuccess() {
  return {
    type: SUBMIT_SELF_CANCEL_SUCCESS,
  }
}

export function submitSelfCancelFailure(error) {
  return {
    error,
    type: SUBMIT_SELF_CANCEL_FAILURE,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [CANCEL_CONFIRM_REQUEST]: state => {
    return {
      ...state,
      confirming: false,
    }
  },
  [CONFIRM_CANCEL]: (state, action) => {
    const ticket = action.payload.cancelTicket
    return {
      ...state,
      cancelTicket: ticket,
      confirming: true,
      loading: undefined,
      submitting: undefined,
    }
  },
  [LOAD]: state => {
    return {
      ...state,
      error: undefined,
      loading: true,
    }
  },

  [LOAD_BY_ORDER]: state => {
    return {
      ...state,
      confirming: undefined,
      error: undefined,
      loading: true,
      selfCancelError: undefined,
      success: undefined,
    }
  },

  [LOAD_ERROR]: (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    }
  },
  [LOAD_SUCCESS]: (state, action) => {
    const reasons = action.payload
    return {
      ...state,
      loading: false,
      reasons,
    }
  },
  [SUBMIT]: (state, action) => {
    return {
      ...state,
      error: undefined,
      loading: true,
      submitting: true,
      success: undefined,
      ticket: action.payload.ticket,
    }
  },
  [SUBMIT_ERROR]: (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
      submitting: undefined,
      success: undefined,
    }
  },
  [SUBMIT_SELF_CANCEL]: state => {
    return {
      ...state,
      confirming: undefined,
      loading: true,
      selfCancelError: undefined,
      submitting: true,
    }
  },
  [SUBMIT_SELF_CANCEL_FAILURE]: (state, action) => {
    const error = normalizeDeliveryErrorMessage(action.error.response.data)?.title
    return {
      ...state,
      loading: false,
      selfCancelError: error,
      submitting: undefined,
    }
  },
  [SUBMIT_SELF_CANCEL_SUCCESS]: state => {
    return {
      ...state,
      loading: undefined,
      submitting: undefined,
    }
  },
  [SUBMIT_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      submitting: undefined,
      success: true,
      ticketId: action.payload.ticketId,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = { reasons: [] }

export default function ticket(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* watchLoadReasonsByOrder() {
  while (true) {
    const { payload: orderId } = yield take(LOAD_BY_ORDER)
    yield call(
      apiSaga,
      loadTicketReasonsByOrder,
      [orderId],
      loadReasonsSucceeded,
      loadReasonsFailed,
    )
  }
}

export function* watchLoadReasons() {
  while (true) {
    yield take(LOAD)
    yield call(apiSaga, loadTicketReasons, [], loadReasonsSucceeded, loadReasonsFailed)
  }
}

export const convertTicketToPayload = (ticket, reasons, order) => {
  const reason = reasons.find(reason => reason.id === ticket.reasonOption)
  return {
    comments: ticket.comment,
    order: {
      date: order.orderDate,
      id: order.orderId,
      location: order.locationName,
      store: order.storeName,
    },
    reasonOption: {
      id: ticket.reasonOption,
      label: reason.label,
    },
    reasonType: reason.reasonTypeId,
    ticketType: 'regular',
  }
}

export function* watchSubmitTicket() {
  while (true) {
    const {
      payload: { ticket },
    } = yield take(SUBMIT)
    const reasons = yield select(selectReasons)
    const order = yield select(getCurrentOrderDetails)
    const formattedTicket = convertTicketToPayload(ticket, reasons, order)
    try {
      if (formattedTicket.reasonOption.id === 8) {
        const cancelPayload = {
          comments: formattedTicket.comments,
          orderId: formattedTicket.order.id,
        }
        yield put(confirmCancelRequest(cancelPayload))
      } else {
        yield call(
          formSaga,
          'contactUsLight',
          createTicket,
          [formattedTicket],
          submitTicketSucceeded,
          submitTicketFailed,
        )
      }
    } catch (error) {
      yield put(submitTicketFailed(error))
    }
  }
}

export function* watchSubmitSelfCancel() {
  while (true) {
    const {
      payload: { cancelTicket },
    } = yield take(SUBMIT_SELF_CANCEL)
    yield call(
      apiSaga,
      selfCancel,
      [cancelTicket],
      submitSelfCancelSuccess,
      submitSelfCancelFailure,
    )
  }
}

export function* watchSubmitSelfCancelSuccess() {
  while (true) {
    yield take(SUBMIT_SELF_CANCEL_SUCCESS)
    const cancelTicket = yield select(getCancelTicketDetails)
    yield put(loadReasonsByOrder(cancelTicket.orderId))
    yield put(loadOrderByIdStart(cancelTicket.orderId))
  }
}

// ------------------------------------
// APIs
// ------------------------------------
export const loadTicketReasons = () => api.get(`/api/v1/tickets/reasons`)
export const loadTicketReasonsByOrder = orderId =>
  api.get(`/api-monolith/tickets/reasons/${orderId}`)
export const createTicket = formattedTicket => api.post(`/api/v2/tickets`, formattedTicket)

export const selfCancel = payload => api.post(`/api-monolith/tickets/cancel-order`, payload)
