import React from 'react'
import { Box, Divider, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { format, isAfter, isBefore, subDays } from 'date-fns'
import { themeConWeb } from '../../util/modernThemeConweb'

import CardLocation from './CardLocation'

const useStyles = makeStyles({
  itemName: { marginTop: '10px' },
  itemLabel: { color: themeConWeb.color.greyShade4, fontSize: '14px' },
  listItemBox: {
    marginLeft: '20px',
  },
  divider: { marginTop: '20px' },
})

const ListItem = ({ classes, title, label, showDivider }) => {
  return (
    <>
      <Box className={classes.listItemBox}>
        <Typography className={classes.itemName}>{title}</Typography>
        <Typography className={classes.itemLabel} variant="body2">
          {label}
        </Typography>
      </Box>
      {showDivider && <Divider className={classes.divider} />}
    </>
  )
}

const CardServiceInformation = ({ locationUserManages }) => {
  const classes = useStyles()

  const showPromotionDate = promotionDate => {
    if (!promotionDate) {
      return false
    }

    const date = new Date(promotionDate)
    const today = new Date()
    const sixtyDaysAgo = subDays(today, 60)

    return (
      isAfter(date, sixtyDaysAgo) && (isBefore(date, today) || date.getTime() === today.getTime())
    )
  }

  const isShowingPromotionDate = showPromotionDate(locationUserManages.promotionDate)

  return (
    <CardLocation>
      <Typography variant="h6">Service Information</Typography>
      <Box marginTop={3}>
        <Box>
          <ListItem
            classes={classes}
            title={'$' + locationUserManages.orderFee}
            label={'Order Fee (Paid per order)'}
            iconType={true}
            showDivider={true}
          />
          <ListItem
            classes={classes}
            title={'$' + locationUserManages.subscriptionFee}
            label={'Monthly Fee (Paid per month)'}
            iconType={true}
            showDivider={true}
          />
          <ListItem
            classes={classes}
            title={format(locationUserManages.activationDate, 'MMMM Do, YYYY')}
            label={'Service Start Date'}
            iconType={true}
            showDivider={isShowingPromotionDate}
          />
          {isShowingPromotionDate && (
            <ListItem
              classes={classes}
              title={format(locationUserManages.promotionDate, 'MMMM Do, YYYY')}
              label={'Promotion Start Date'}
              iconType={true}
              showDivider={false}
            />
          )}
        </Box>
      </Box>
    </CardLocation>
  )
}

export default CardServiceInformation
