import { Button, H5, P } from '@foodsby/nutrient'
import { themeConWeb } from '@app/util/modernThemeConweb'
import React from 'react'
import styled from 'styled-components'

import { locationRoute } from '../../routes/routes'
import { normalizeDeliveryErrorMessage } from '../../util/errorUtils'
import { formatUrl } from '../../util/formatUtils'
import ModalPopup from '../common/ModalPopup'

const navigate = linkRoute => {
  window.location.href = linkRoute
}

export const ValidationModal = ({ locationId, serverMessage }) => {
  const error = normalizeDeliveryErrorMessage(serverMessage)
  const linkRoute = formatUrl(locationRoute.path, {
    locationId,
  })

  return (
    <ModalPopup maxWidth={450} showCancel={false}>
      <Component>
        <div className="-title">
          <i className="-titleIcon icon-warning primary" />
          <H5 className="-titleText">{error?.title}</H5>
        </div>
        <P className="-body">{error?.message}</P>
        <Button className="-cta" onClick={() => navigate(linkRoute)}>
          VIEW OTHER RESTAURANTS
        </Button>
      </Component>
    </ModalPopup>
  )
}

const Component = styled.div`
  display: flex;
  flex-direction: column;

  & .-title {
    margin-bottom: 16px;
  }

  & .-titleIcon {
    padding-right: 8px;
  }

  & .-titleText {
    display: inline-block;
  }

  & .-body {
    font-size: ${themeConWeb.fontSizes.basePlus1}
    margin-bottom: 16px;
  }

  & .-cta {
    font-size: ${themeConWeb.fontSizes.base}
    align-self: flex-end;
    letter-spacing: normal;
  }
`

export default ValidationModal
