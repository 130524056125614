import { Box, Container, Grid, Link } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import { DocsButton } from '../documentation/DocsButton'
import { DocsCheckbox } from '../documentation/DocsCheckbox'
import { DocsSelect } from '../documentation/DocsSelect'
import { DocsSwitch } from '../documentation/DocsSwitch'
import { DocsTextField } from '../documentation/DocsTextField'
import DocsTypography from '../documentation/DocsTypography'

const PageUI = () => {
  return (
    <Container maxWidth="md">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography component="h1" gutterBottom variant="h1">
            Foodsby UI
          </Typography>
          <Typography component="p" gutterBottom variant="p">
            {`Foodsby's UI library leverages Material UI for more detailed documentation please check
            their docs ${(<Link href="https://material-ui.com/">Documentation</Link>)}`}
          </Typography>
        </Grid>
      </Grid>
      <Box marginBottom={4}>
        <DocsButton />
      </Box>
      <Box marginBottom={4}>
        <DocsCheckbox />
      </Box>
      <Box marginBottom={4}>
        <DocsSwitch />
      </Box>
      <Box marginBottom={4}>
        <DocsSelect />
      </Box>
      <Box marginBottom={4}>
        <DocsTextField />
      </Box>
      <Box marginBottom={4}>
        <DocsTypography />
      </Box>
    </Container>
  )
}

export default PageUI
