import { call, take } from 'redux-saga/effects'

import { loadAvailableCredits } from '../../api/api'
import { apiSaga } from './sagas'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const LOAD_CREDIT_HISTORY = 'foodsby/credit/LOAD_CREDIT_HISTORY'
export const LOAD_CREDIT_HISTORY_SUCCESS = 'foodsby/credit/LOAD_CREDIT_HISTORY_SUCCESS'
export const LOAD_CREDIT_HISTORY_FAILURE = 'foodsby/credit/LOAD_CREDIT_HISTORY_FAILURE'

export const UPDATE_CREDIT_PAGE = 'foodsby/credit/UPDATE_CREDIT_PAGE'
export const UPDATE_REFERRAL_PAGE = 'foodsby/credit/UPDATE_REFERRAL_PAGE'

export const LOAD_AVAILABLE_CREDITS = 'foodsby/credit/LOAD_AVAILABLE_CREDITS'
export const LOAD_AVAILABLE_CREDITS_SUCCESS = 'foodsby/credit/LOAD_AVAILABLE_CREDITS_SUCCESS'
export const LOAD_AVAILABLE_CREDITS_FAILURE = 'foodsby/credit/LOAD_AVAILABLE_CREDITS_FAILURE'

export const updateCreditPage = page => {
  return {
    page,
    type: UPDATE_CREDIT_PAGE,
  }
}

export const updateReferralPage = page => {
  return {
    page,
    type: UPDATE_REFERRAL_PAGE,
  }
}

export const loadAvailableCreditsStart = () => {
  return {
    type: LOAD_AVAILABLE_CREDITS,
  }
}

export const loadAvailableCreditsSuccess = response => {
  return {
    payload: { response },
    type: LOAD_AVAILABLE_CREDITS_SUCCESS,
  }
}

export const loadAvailableCreditsFailure = error => {
  return {
    error,
    type: LOAD_AVAILABLE_CREDITS_FAILURE,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [LOAD_AVAILABLE_CREDITS]: state => {
    return {
      ...state,
      isAvailableCreditsLoading: true,
    }
  },
  [LOAD_AVAILABLE_CREDITS_FAILURE]: (state, action) => {
    return {
      ...state,
      availableCredits: 0,
      error: action.error,
      isAvailableCreditsLoading: false,
    }
  },
  [LOAD_AVAILABLE_CREDITS_SUCCESS]: (state, action) => {
    const availableCredits = action.payload.response.balance
    return {
      ...state,
      availableCredits,
      error: undefined,
      isAvailableCreditsLoading: false,
    }
  },
  [LOAD_CREDIT_HISTORY]: state => {
    return {
      ...state,
      loading: true,
    }
  },
  [LOAD_CREDIT_HISTORY_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: false,
    }
  },
  [LOAD_CREDIT_HISTORY_SUCCESS]: (state, action) => {
    const { credits, referrals } = action.payload.response

    return {
      ...state,
      credits: credits.reverse(),
      error: undefined,
      loading: false,
      referrals,
    }
  },
  [UPDATE_CREDIT_PAGE]: (state, action) => {
    return {
      ...state,
      creditPage: {
        page: action.page,
        size: state.creditPage.size,
      },
    }
  },
  [UPDATE_REFERRAL_PAGE]: (state, action) => {
    return {
      ...state,
      referralPage: {
        page: action.page,
        size: state.referralPage.size,
      },
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  availableCredits: 0,
  creditPage: {
    page: 1,
    size: 5,
  },
  credits: [],
  error: undefined,
  loading: false,
  referralPage: {
    page: 1,
    size: 5,
  },
  referrals: [],
}

export default function creditHistory(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// -----------------------------------
export function* watchLoadAvailableCredits() {
  while (true) {
    yield take(LOAD_AVAILABLE_CREDITS)
    yield call(
      apiSaga,
      loadAvailableCredits,
      [],
      loadAvailableCreditsSuccess,
      loadAvailableCreditsFailure,
    )
  }
}
