import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Divider, Box } from '@material-ui/core'
import RestaurantIcon from '@material-ui/icons/Restaurant'
import PersonIcon from '@material-ui/icons/Person'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'

import { Panel } from '../common/Layout'
import { useMyReferralWithEnabledDetails } from '../../hooks/referral'
import { ReferralLink } from '../community/Referral'

const useStyles = makeStyles({
  panelTitle: {
    fontWeight: 500,
    fontSize: '32px',
  },
  titleBox: {
    paddingBottom: '30px',
  },
  title: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '16px',
    paddingBottom: '5px',
  },
  sectionContainer: {
    display: 'flex',
    justifyContent: 'row',
  },
  content: {
    fontSize: '14px',
  },
  section: {
    fontSize: 'large',
  },
  paddedDivider: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  icon: {
    margin: '10px',
    marginRight: '25px',
  },
  referralLinkContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
    alignItems: 'start',
  },
})

export const PanelRewardsAndReferral = () => {
  const { referralAndEnabledDetails } = useMyReferralWithEnabledDetails()
  const classes = useStyles()

  return (
    <Panel>
      <Box className={classes.titleBox}>
        <Typography className={classes.panelTitle}>Foodsby Rewards</Typography>
        <p>
          Earn more than just a great meal—get rewards for referrals, ordering with others, catering
          orders and more!
        </p>
      </Box>
      <Box className={classes.sectionContainer}>
        <Box>
          <SupervisorAccountIcon className={classes.icon} fontSize="large" />
        </Box>
        <Box>
          <Typography className={classes.title}>Delivery</Typography>
          <Typography className={classes.content}>
            Earn rewards collectively when more people order together on the same delivery. Everyone
            receives a percentage back based on their order total less fees and taxes. The more
            people order, the higher the percentage everyone earns. Start ordering together, earn
            together, and enjoy your meals together!
          </Typography>
        </Box>
      </Box>
      <Divider className={classes.paddedDivider} />

      <Box className={classes.sectionContainer}>
        <Box>
          <RestaurantIcon className={classes.icon} fontSize="large" />
        </Box>
        <Box>
          <Typography className={classes.title}>Catering</Typography>
          <Typography className={classes.content}>
            Earn up to 2% back in credits on each catering order you place!
          </Typography>
        </Box>
      </Box>
      <Divider className={classes.paddedDivider} />

      <Box className={classes.sectionContainer}>
        <Box>
          <PersonIcon className={classes.icon} fontSize="large" />
        </Box>
        <Box>
          <Typography className={classes.title}>Referrals</Typography>
          <Typography className={classes.content}>
            Invite your friends with the link below, <b>and you both earn $5 </b> when they make
            their first order!
          </Typography>
          <Box className={classes.referralLinkContainer}>
            <ReferralLink referralAndEnabledDetails={referralAndEnabledDetails} />
          </Box>
        </Box>
      </Box>
    </Panel>
  )
}

export default PanelRewardsAndReferral
