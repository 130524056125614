import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { themeConWeb } from '@app/util/modernThemeConweb'
import LinkButton from '../../common/LinkButton'

const useStyles = makeStyles({
  buttonGroup: {
    position: 'absolute',
    top: -14,
    right: 5,
    backgroundColor: themeConWeb.color.white,
    padding: '2px',
  },
  input: {
    position: 'relative',
    '& .MuiInputBase-root': {
      backgroundColor: themeConWeb.color.white,
    },
  },
})

const ButtonSaveCancel = ({ onSave, onCancel }) => {
  const classes = useStyles()
  return (
    <Box className={classes.buttonGroup}>
      <LinkButton color={themeConWeb.color.brandPrimary} onClick={onCancel}>
        Cancel
      </LinkButton>
      <Box marginX={2} display="inline">
        |
      </Box>
      <LinkButton color={themeConWeb.color.brandPrimary} onClick={onSave}>
        Save
      </LinkButton>
    </Box>
  )
}

export default ButtonSaveCancel
