import React from 'react'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { themeConWeb } from '@app/util/modernThemeConweb'
import { Clear } from '@material-ui/icons'
import { isEmpty } from 'lodash'
import { getFullName } from '../admin/adminUtils'

const useStyles = makeStyles({
  root: {
    maxHeight: ({ maxHeight }) => maxHeight,
  },
  headerRow: {
    backgroundColor: themeConWeb.color.greyShade1,
    color: themeConWeb.color.black,
    height: '20px',
  },
})

const EmptyState = () => {
  return (
    <TableRow>
      <TableCell colSpan={3} align="center">
        No members selected
      </TableCell>
    </TableRow>
  )
}

const TableActionableMembers = ({ maxHeight, members, onRemove }) => {
  const classes = useStyles({ maxHeight })
  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmpty(members) && <EmptyState />}
          {members.map(member => (
            <TableRow key={member.userId}>
              <TableCell>{getFullName(member)}</TableCell>
              <TableCell>{member.email ?? member.username}</TableCell>
              <TableCell>
                <IconButton
                  size="small"
                  onClick={() => onRemove(member)}
                  data-testid={`remove-member-${member.userId}`}
                >
                  <Clear fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableActionableMembers
