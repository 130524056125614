import { Card } from '@material-ui/core'
import React from 'react'

import { Article } from '../common/Layout'
import TermsOfUse from './TermsOfUse'

const TermsOfUsePage = () => (
  <Article>
    <Card>
      <TermsOfUse />
    </Card>
  </Article>
)

export default TermsOfUsePage
