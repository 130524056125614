import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles({
  btnContainer: {
    float: 'right',
    height: ({ fullHeight }) => (fullHeight ? 20 : 0),
    display: 'block',
  },
})

export default function CardActionButton({
  name,
  onClick = () => {},
  fullHeight = false,
  ...rest
}) {
  const { variant, color, ...otherProps } = rest
  const styles = useStyles({ fullHeight })
  return (
    <div className={styles.btnContainer}>
      <Button
        onClick={onClick}
        variant={rest.variant || 'text'}
        color={rest.color || 'primary'}
        {...otherProps}
      >
        {name}
      </Button>
    </div>
  )
}
