import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Box, Typography, Chip } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { isEmpty, isNil } from 'lodash'
import styled from 'styled-components'

import { themeConWeb } from '@app/util/modernThemeConweb'
import { loadPaymentMethodsStart } from '../../redux/modules/paymentMethods'
import {
  selectAllPaymentMethods,
  selectPaymentMethodsIsLoading,
} from '../../redux/modules/selectors'
import { loadTeamPaymentMethodsStart } from '../../redux/modules/team'
import {
  selectTeamPaymentMethods,
  selectIsLoadingTeamPaymentMethods,
} from '../../redux/selectors/teamSelectors'
import StripePaymentMethodElement from '../checkout/StripePaymentMethodElement'
import PaymentMethodItem from './PaymentMethodItem'

// This component is exported separately to provide a button that looks like a credit card radio list item (RadioPaymentMethod)
// It is used in PanelSubscriptions
export const ButtonPaymentMethod = ({ active, children, disabled, primary, onClick }) => (
  <RootButtonPaymentMethod active={active} disabled={disabled} primary={primary} onClick={onClick}>
    {children}
  </RootButtonPaymentMethod>
)

export const RadioPaymentMethod = ({
  onChangePayment,
  selected,
  value,
  primary,
  readOnly,
  paymentMethod,
  condensed,
  flat,
}) => (
  <RootRadioPaymentMethod
    onClick={() => {
      !readOnly && onChangePayment(value)
    }}
    selected={selected}
    disabled={readOnly}
    flat={flat}
    condensed={condensed}
  >
    <InputRadioPaymentMethod
      checked={selected}
      name="paymentMethod"
      onChange={() => onChangePayment(value)}
      type="radio"
      value={value}
    />
    <PaymentMethodItem paymentMethod={paymentMethod} condensed={condensed} />
    {primary && (
      <Box position="absolute" top={15} right={10}>
        <Chip color="primary" label="Primary" size="small" />
      </Box>
    )}
  </RootRadioPaymentMethod>
)

const PaymentMethod = ({
  isTeamContext,
  teamId,
  noPreselectIfEmpty,
  condensed,
  flat,
  paymentMethodId,
  primaryMethodId,
  onError,
  setPaymentMethodId,
  onlyExistingCards,
  readOnly,
  CardElement,
}) => {
  const dispatchRedux = useDispatch()
  const paymentMethods = useSelector(
    isTeamContext ? selectTeamPaymentMethods : selectAllPaymentMethods,
    shallowEqual,
  )
  const loading = useSelector(
    isTeamContext ? selectIsLoadingTeamPaymentMethods : selectPaymentMethodsIsLoading,
  )

  // Effects
  useEffect(() => {
    if (paymentMethods.length === 0) {
      dispatchRedux(isTeamContext ? loadTeamPaymentMethodsStart(teamId) : loadPaymentMethodsStart())
    }
  }, [dispatchRedux, paymentMethods.length, isTeamContext, teamId])

  useEffect(() => {
    if (!isEmpty(paymentMethods) && !paymentMethodId && !noPreselectIfEmpty && !readOnly) {
      setPaymentMethodId(paymentMethods[0].providerId)
    }
    //eslint-disable-next-line
  }, [paymentMethods, setPaymentMethodId])

  useEffect(() => {
    if (paymentMethodId) {
      onError(null)
    }
  }, [paymentMethodId, onError])

  return loading ? (
    <SkeletonPaymentMethod />
  ) : (
    <>
      {paymentMethods?.map(pm => (
        <RadioPaymentMethod
          key={pm.providerId}
          onChangePayment={setPaymentMethodId}
          selected={pm.providerId === paymentMethodId}
          primary={pm.providerId === primaryMethodId}
          paymentMethod={pm}
          value={pm.providerId}
          readOnly={readOnly}
          condensed={condensed}
          flat={flat}
        />
      ))}
      {!onlyExistingCards && !readOnly && (
        <>
          <ButtonPaymentMethod active={!paymentMethodId} onClick={() => setPaymentMethodId(null)}>
            <Typography color="secondary" variant="body2">
              + Add new card
            </Typography>
          </ButtonPaymentMethod>
          {isNil(paymentMethodId) && (
            <Box mt={3}>
              <StripePaymentMethodElement CardElement={CardElement} />
            </Box>
          )}
        </>
      )}
      {readOnly && paymentMethods.length === 0 && !loading && (
        <Box
          bgcolor="#f5f5f5"
          border="1px solid #e5e5e5"
          padding={2}
          textAlign="center"
          marginTop="15px"
        >
          <Typography variant="overline">None Available</Typography>
        </Box>
      )}
    </>
  )
}

const SkeletonPaymentMethod = () => (
  <Box marginBottom={2}>
    <Skeleton height={80} />
    <Skeleton height={80} />
  </Box>
)

const RootRadioPaymentMethod = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  margin-bottom: ${({ condensed }) =>
    condensed ? `calc(${themeConWeb.baseUnit} * 2)` : `calc(${themeConWeb.baseUnit} * 4)`};
  padding: ${({ condensed }) =>
    condensed ? `calc(${themeConWeb.baseUnit} * 3)` : `calc(${themeConWeb.baseUnit} * 4)`};
  border-radius: ${themeConWeb.baseUnit};
  box-shadow: ${({ flat }) =>
    flat ? 'inherit' : `0 0 ${themeConWeb.baseUnit} 0 rgba(0, 0, 0, 0.25)`};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  border: ${({ selected, flat }) =>
    selected
      ? `solid 2px ${themeConWeb.color.brandPrimary}`
      : flat
      ? `solid 1px #e5e5e5`
      : 'inherit'};

  &:hover {
    border: ${({ disabled, selected }) =>
      !disabled && !selected && `solid 1px ${themeConWeb.color.brandPrimary}`};
  }

  & .-creditCardIcon {
    margin-right: calc(${themeConWeb.baseUnit} * 4);
  }

  & .-text {
    flex-grow: 1;
  }

  & .-checkCircle {
    color: ${themeConWeb.color.success};
  }
`

const InputRadioPaymentMethod = styled.input`
  display: none;
`

const RootButtonPaymentMethod = styled.button`
  display: flex;
  align-content: center;
  width: 100%;
  padding: calc(${themeConWeb.baseUnit} * 4);
  border-radius: ${({ active }) => (active ? '4px 4px 0 0' : '4px')};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  cursor: ${({ disabled }) => !disabled && 'pointer'};

  &:hover {
    border: ${({ active, disabled }) =>
      !disabled && !active && `solid 1px ${themeConWeb.color.brandPrimary}`};
  }
`

export default PaymentMethod
