import { call, take } from 'redux-saga/effects'

import { loadOrderHistory } from '../../api/api'
import { normalizeErrorResponse } from '../../util/errorUtils'
import { apiSaga } from './sagas'

// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const LOAD_ORDER_HISTORY = 'foodsby/orderHistory/LOAD_ORDER_HISTORY'
export const LOAD_ORDER_HISTORY_SUCCESS = 'foodsby/orderHistory/LOAD_ORDER_HISTORY_SUCCESS'
export const LOAD_ORDER_HISTORY_FAILURE = 'foodsby/orderHistory/LOAD_ORDER_HISTORY_FAILURE'

export const loadOrderHistoryStart = (page, pageSize) => {
  return {
    payload: { page, pageSize },
    type: LOAD_ORDER_HISTORY,
  }
}

export const loadOrderHistorySuccess = history => {
  return {
    payload: { history },
    type: LOAD_ORDER_HISTORY_SUCCESS,
  }
}

export const loadOrderHistoryFailure = error => {
  return {
    error,
    type: LOAD_ORDER_HISTORY_FAILURE,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [LOAD_ORDER_HISTORY]: state => {
    return {
      ...state,
      loading: true,
    }
  },
  [LOAD_ORDER_HISTORY_FAILURE]: (state, action) => {
    const message = normalizeErrorResponse(action.error.response, action.error.message)
    return {
      ...state,
      errorMessage: message,
      loading: false,
    }
  },
  [LOAD_ORDER_HISTORY_SUCCESS]: (state, action) => {
    const { history } = action.payload
    return {
      ...state,
      errorMessage: undefined,
      history,
      loading: false,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  errorMessage: undefined,
  history: {
    orders: [],
  },
  loading: false,
}

export default function orderHistory(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* watchLoadOrderHistory() {
  while (true) {
    const {
      payload: { page, pageSize },
    } = yield take(LOAD_ORDER_HISTORY)
    yield call(
      apiSaga,
      loadOrderHistory,
      [page, pageSize],
      loadOrderHistorySuccess,
      loadOrderHistoryFailure,
    )
  }
}
