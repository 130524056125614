import React from 'react'
import { Box, Typography, Avatar } from '@material-ui/core'
import { themeConWeb } from '@app/util/modernThemeConweb'
import { isEmpty } from 'lodash'
import { UNKNOWN_CUSTOMER } from '../../util/constants'

export const CustomersOrdered = ({
  friendsOrders,
  avatarId,
  firstName,
  lastName,
  creditsEarnedSectionShown,
}) => {
  const userFullName = `${firstName} ${lastName} (You)`
  const friendImageUrlBase = process.env.REACT_APP_IMAGE_LAMBDA
  const customersOutsideOffice = []
  const friendsInsideOffice = []

  if (!isEmpty(friendsOrders)) {
    for (const friendOrder of friendsOrders) {
      const friend = friendOrder.friend
      if (friend.email === '') {
        customersOutsideOffice.push(friend)
      } else {
        friendsInsideOffice.push(friend)
      }
    }
  }
  return (
    <Box>
      <Box marginBottom={'1em'}>
        <Typography
          style={{
            fontSize: themeConWeb.fontSizes.basePlus2,
            fontWeight: creditsEarnedSectionShown ? null : 500,
          }}
        >
          Customers who&apos;ve ordered {`(${friendsOrders.length + 1})`}
        </Typography>
      </Box>
      <Box minHeight={creditsEarnedSectionShown ? '11em' : '23em'}>
        <Customer name={userFullName} friendImageUrlBase={friendImageUrlBase} avatarId={avatarId} />
        {!isEmpty(friendsInsideOffice) &&
          friendsInsideOffice.map(friend => {
            const name = `${friend.firstName} ${friend.lastName}`
            return (
              <Customer
                key={friend.userId}
                avatarId={friend.avatarId}
                name={name}
                friendImageUrlBase={friendImageUrlBase}
              />
            )
          })}
        {!isEmpty(customersOutsideOffice) &&
          customersOutsideOffice.map((_, index) => {
            return (
              <Customer
                key={index}
                name={UNKNOWN_CUSTOMER}
                friendImageUrlBase={friendImageUrlBase}
                avatarId={null}
              />
            )
          })}
      </Box>
    </Box>
  )
}

const Customer = ({ name, avatarId, friendImageUrlBase }) => {
  return (
    <Box display={'flex'} flexDirection={'row'} marginBottom={'0.5em'}>
      <Avatar
        alt={name}
        src={
          avatarId && encodeURI(`${friendImageUrlBase}/profiles/${avatarId}.jpg?height=50&width=50`)
        }
        variant="circular"
        style={{ width: '30px', height: '30px' }}
      />
      <Box marginLeft={'0.5em'} marginTop={'0.2em'}>
        <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus1, fontWeight: 500 }}>
          {name}
        </Typography>
      </Box>
    </Box>
  )
}
