import React from 'react'
import { connect } from 'react-redux'
import { Box, Card, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { deleteCateringOrderCartStart } from '../redux/modules/cart'
import LayoutInnerPage, { HeaderInnerPage } from '../layouts/LayoutInnerPage'
import { formatUrl } from '../util/formatUtils'
import { placeOrderRoute, setDeliveryReserveFeeRoute } from '../routes/routes'
import ReserveCheckoutSidebar from '../components/checkout/ReserveCheckoutSidebar'
import ReserveCheckoutForm from '../components/checkout/ReserveCheckoutForm'

const useStyles = makeStyles({
  formTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '38px',
  },
})

const PageReserveCheckout = ({ deliveryDropoff }) => {
  const classes = useStyles()
  const storeId = deliveryDropoff?.storeId
  const { dropoffId, locationId } = useParams()

  return (
    <LayoutInnerPage
      HeaderComponent={
        <HeaderInnerPage
          showBackButton
          backButtonRoute={
            !deliveryDropoff.isConfirmedReserveDropoff
              ? formatUrl(setDeliveryReserveFeeRoute.path, {
                  locationId,
                  storeId,
                })
              : formatUrl(placeOrderRoute.path, { locationId, dropoffId })
          }
        >
          Back
        </HeaderInnerPage>
      }
      MainComponent={
        <Card>
          <Box marginBottom={4}>
            <Typography className={classes.formTitle}>Review & Checkout</Typography>
          </Box>
          <ReserveCheckoutForm dropoffId={dropoffId} />
        </Card>
      }
      SidebarComponent={<ReserveCheckoutSidebar deliveryType={deliveryDropoff?.type} />}
    />
  )
}

const mapStateToProps = state => {
  const { deliveryDropoff } = state.delivery
  const { checkoutCart } = state.cart

  return {
    checkoutCart,
    deliveryDropoff,
  }
}

const mapDispatchToProps = {
  deleteCateringOrderCartStart,
}
export default connect(mapStateToProps, mapDispatchToProps)(PageReserveCheckout)
