import { Button } from '@material-ui/core'
import { Card } from '@material-ui/core'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Skeleton from '@material-ui/lab/Skeleton'
import Action from '../components/common/Action'

import { Article } from '../components/common/Layout'
import SimpleSpinner from '../components/common/SimpleSpinner'
import ContactUsLightForm, {
  ErrorMessage,
  SuccessMessage,
} from '../components/contact-us/ContactUsLightForm'
import { createTicket } from '../redux/modules/ticket'
import { historyRoute, loginRoute, contactRoute } from '../routes/routes'
import { FOODSBY_ADDRESS } from '../util/constants'

const SkeletonLoading = () => (
  <>
    <Skeleton height={100} width="100%" />
    <Skeleton height={100} width="100%" />
    <Skeleton height={100} width="100%" />
  </>
)

class PageContact extends Component {
  state = {
    payload: undefined,
    showContactForm: false,
    submitError: undefined,
    submitSuccess: false,
    submitting: false,
    ticket: {},
  }

  handleShowContactForm = () => {
    this.setState({ showContactForm: true })
  }

  handleChange = e => {
    const { ticket } = this.state
    this.setState({ ticket: { ...ticket, [e.target.name]: e.target.value } })
  }

  submitTicket = async e => {
    e.preventDefault()
    const { ticket } = this.state
    const payload = {
      comments: ticket.comment.trim(),
      reasonOption: {
        id: 23,
        label: 'General Inquiry',
      },
      reasonType: 'GENERAL_OTHER',
      ticketType: 'regular',
    }
    this.setState({ payload })
    if (Number(payload.reasonOption.id) === 8) {
      this.setState({ confirmCancel: true })
    } else {
      this.setState({ submitting: true })
      try {
        await createTicket(payload)
        this.setState({ submitSuccess: true, submitting: false })
      } catch (error) {
        this.setState({ submitError: error, submitting: false })
      }
    }
  }

  render() {
    const { submitError, submitSuccess, submitting } = this.state
    const { isAuthenticated, isCurrentUserLoading } = this.props
    if (isCurrentUserLoading) {
      return <SkeletonLoading />
    }
    if (!isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: loginRoute.path,
            refer: contactRoute.path,
          }}
        />
      )
    }

    return (
      <Article>
        <Card>
          <div className="row center-xs">
            <div className="col-xs-12 center-xs">
              <h1 className="title" style={{ marginBottom: '16px' }}>
                Contact Us!
              </h1>
              <h3 className="center-xs" style={{ marginBottom: '16px' }}>
                Questions about an order?
              </h3>
            </div>
          </div>
          <p className="row center-xs" style={{ marginBottom: '16px' }}>
            First, click below to find your order. Then request support using the information on the
            order screen.
          </p>
          <div className="row center-xs">
            <Button component={Link} to={historyRoute.path} color="primary" variant="outlined">
              View Your Orders
            </Button>
          </div>
          <hr />
          <br />
          <div className="row center-xs">
            {submitting ? (
              <SimpleSpinner />
            ) : submitSuccess ? (
              <SuccessMessage />
            ) : (
              <div className="panel">
                <h3 className="panel-title">For general questions, comments, or feedback</h3>
                <ContactUsLightForm
                  handleChange={this.handleChange}
                  handleSubmit={this.submitTicket}
                />
              </div>
            )}
            {!submitting && submitSuccess && (
              <div className="row center-xs">
                <Action mb={3} onClick={() => this.setState({ submitSuccess: false })}>
                  Submit another ticket
                </Action>
              </div>
            )}
            {submitError && <ErrorMessage />}
            <div className="col-xs-12">
              Foodsby
              <br />
              {FOODSBY_ADDRESS.STREET}
              <br />
              {FOODSBY_ADDRESS.CITY}, {FOODSBY_ADDRESS.STATE} {FOODSBY_ADDRESS.ZIP}
            </div>
          </div>
        </Card>
      </Article>
    )
  }
}

const mapStateToProps = state => {
  const { isAuthenticated, isCurrentUserLoading } = state.user
  return { isAuthenticated, isCurrentUserLoading }
}

export default connect(mapStateToProps)(PageContact)
