import React, { useEffect } from 'react'
import { DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import queryString from 'query-string'
import { makeStyles } from '@material-ui/styles'

import ButtonSubmit from '../../common/ButtonSubmit'
import { selectQuery } from '../../../redux/selectors/routerSelectors'
import { selectUserTeam } from '../../../redux/selectors/userTeamSelectors'
import {
  acceptInviteStart,
  declineInviteStart,
  loadInviterTeamNameStart,
  setPendingAcceptedInvite,
} from '../../../redux/modules/userTeam'
import { selectIsAuthenticated } from '../../../redux/modules/selectors'
import DialogMarketing from '../../common/DialogMarketing'
import RadioCardOption from '../perks/RadioCardOption'
import { enqueueSnackbar } from '../../../redux/modules/snackbar'
import { hasTeam } from '../teamUtils'

const useStyles = makeStyles(theme => ({
  radioOptionLabel: {
    fontWeight: 500,
    fontSize: '20px',
  },
  declineButton: {
    marginRight: theme.spacing(2),
  },
}))

const DialogAcceptDecline = ({
  query,
  isAuthenticated,
  userTeam,
  inviterTeamName,
  isAcceptingInvite,
  isDecliningInvite,
  setPendingAcceptedInvite,
  acceptInviteStart,
  declineInviteStart,
  push,
}) => {
  const classes = useStyles()

  const handleAccept = () => {
    if (isAuthenticated) {
      acceptInviteStart()
    } else {
      setPendingAcceptedInvite(true)
      push({ search: queryString.stringify({ ...query, authenticate: 'true' }) })
    }
  }

  const handleDecline = () => {
    declineInviteStart()
  }

  const teamName = userTeam?.invitation?.teamName || inviterTeamName
  return (
    <DialogMarketing open>
      <DialogTitle disableTypography>
        <Typography variant="h5" align="center" gutterBottom>
          {"You've been invited 🎉"}
        </Typography>
        <Typography variant="body1" align="center">
          Join your office to get instant access to exclusive perks, special offers, and more!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <RadioCardOption dense checked>
          <span className={classes.radioOptionLabel}>{teamName}</span>
        </RadioCardOption>
      </DialogContent>
      <DialogActions>
        <ButtonSubmit
          submitting={isDecliningInvite}
          disabled={isAcceptingInvite}
          onClick={handleDecline}
          className={classes.declineButton}
          variant="text"
        >
          Decline
        </ButtonSubmit>
        <ButtonSubmit
          submitting={isAcceptingInvite}
          disabled={isDecliningInvite}
          variant="contained"
          color="primary"
          onClick={handleAccept}
        >
          Join
        </ButtonSubmit>
      </DialogActions>
    </DialogMarketing>
  )
}

const DialogAcceptDeclineWrapper = props => {
  const {
    query,
    isAuthenticated,
    teamInviteCode,
    inviterTeamName,
    userTeam,
    pendingAcceptedInvite,
    acceptInviteStart,
    loadInviterTeamNameStart,
    enqueueSnackbar,
    push,
  } = props

  useEffect(() => {
    const handleAlreadyTeamMember = () => {
      enqueueSnackbar({
        message: 'You cannot join this team because you are already part of another team.',
      })
      push({ search: queryString.stringify({ ...query, teamInviteCode: undefined }) })
    }

    if (Boolean(teamInviteCode) && hasTeam(userTeam)) {
      handleAlreadyTeamMember()
    }
  }, [enqueueSnackbar, push, query, teamInviteCode, userTeam])

  useEffect(() => {
    if (teamInviteCode && !hasTeam(userTeam)) {
      loadInviterTeamNameStart(teamInviteCode)
    }
  }, [loadInviterTeamNameStart, teamInviteCode, userTeam])

  useEffect(() => {
    if (isAuthenticated && pendingAcceptedInvite) {
      acceptInviteStart()
    }
  }, [acceptInviteStart, isAuthenticated, pendingAcceptedInvite])

  const open =
    ((inviterTeamName && Boolean(teamInviteCode)) || Boolean(userTeam?.invitation)) &&
    !hasTeam(userTeam) &&
    !pendingAcceptedInvite
  return open ? <DialogAcceptDecline {...props} /> : null
}

const mapStateToProps = state => {
  const isAuthenticated = selectIsAuthenticated(state)
  const query = selectQuery(state)
  const { teamInviteCode } = query
  const userTeam = selectUserTeam(state)
  const inviterTeamName = state.userTeam.inviterTeamName
  const isAcceptingInvite = state.userTeam.isAcceptingInvite
  const isDecliningInvite = state.userTeam.isDecliningInvite
  const pendingAcceptedInvite = state.userTeam.pendingAcceptedInvite

  return {
    query,
    isAuthenticated,
    teamInviteCode,
    userTeam,
    inviterTeamName,
    isAcceptingInvite,
    isDecliningInvite,
    pendingAcceptedInvite,
  }
}

const mapDispatchToProps = {
  acceptInviteStart,
  declineInviteStart,
  loadInviterTeamNameStart,
  setPendingAcceptedInvite,
  push,
  enqueueSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogAcceptDeclineWrapper)
