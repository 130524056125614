import React, { useEffect, useState } from 'react'

import { SuccessModal } from './SuccessModal'

export const GenericSuccessModal = ({
  ackOnUnmount = true,
  isError,
  message,
  notificationAck,
  title,
}) => {
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    return () => {
      ackOnUnmount && notificationAck()
    }
  }, [ackOnUnmount, notificationAck])

  return (
    <div className={hidden || (!title && !message) ? 'hidden' : ''}>
      <SuccessModal
        isFailure={isError}
        message={message}
        onClose={() => notificationAck() && setHidden(true)}
        title={title}
      />
    </div>
  )
}

export default GenericSuccessModal
