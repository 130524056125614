import React, { Component } from 'react'
import { connect } from 'react-redux'

import Footer from '../components/common/Footer'
import Header from '../components/common/header/Header'
import RestaurantLogin from '../components/common/RestuarantLogin'
import { SimpleSpinner } from '../components/common/SimpleSpinner'
import { logoutStart } from '../redux/modules/user'
import { pushGTMUserInfo } from '../util/gtmUtils'

export const HeaderComponent = ({ currentUser, match, profile }) => {
  if (currentUser) {
    return <Header currentUser={currentUser} profileStatus={profile && profile.status} />
  } else {
    return <Header match={match} />
  }
}

const Article = ({ children }) => (
  <article className="content" id="content">
    {children}
  </article>
)

export class LayoutPublic extends Component {
  componentDidMount() {
    pushGTMUserInfo()
  }

  render() {
    const {
      children,
      childrenContainer: ChildrenContainer = Article,
      currentUser,
      flex,
      styles = {},
      hideFooter,
      hideHeader,
      isAuthenticated,
      isCurrentUserLoading,
      match,
      profile,
    } = this.props
    return (
      <div
        className="main"
        style={
          flex
            ? {
                ...styles,
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
              }
            : styles
        }
      >
        {isCurrentUserLoading ? (
          <SimpleSpinner />
        ) : (
          !hideHeader && (
            <HeaderComponent
              {...{
                currentUser,
                match,
                profile,
              }}
            />
          )
        )}

        <ChildrenContainer>{children}</ChildrenContainer>
        {!hideFooter && (
          <>
            <RestaurantLogin />
            <Footer isAuthenticated={isAuthenticated} showLogo="true" />
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { currentUser, isAuthenticated, isCurrentUserLoading } = state.user
  const { profile } = state.profile

  return {
    currentUser,
    isAuthenticated,
    isCurrentUserLoading,
    profile,
  }
}

const mapDispatchToProps = {
  logoutStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPublic)
