import 'react-day-picker/lib/style.css'
import { Card, Typography, Box, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { withStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { pushAnalyticsEvent } from '../util/gtmUtils'
import AuthedComponent from '../components/common/AuthedComponent'
import LayoutInnerPage, { HeaderInnerPage } from '../layouts/LayoutInnerPage'
import { formatUrl } from '../util/formatUtils'
import { checkoutRoute, pickReserveDeliveryDateAndTimeRoute } from '../routes/routes'
import { setReserveFeeApi } from '../services/delivery'
import { loadReserveStoreStart } from '../redux/modules/reserve'
import ButtonSubmit from '../components/common/ButtonSubmit'
import { selectDateStart, loadDeliveryReserveFeeStart } from '../redux/modules/delivery'
import {
  getMenuInfoForNewPopularItems,
  getMenuInfoForPopularItems,
} from '../redux/modules/selectors'
import { addPastOrderToReserveCart, loadPastOrdersReserveStart } from '../redux/modules/pastorder'
import {
  addItemToReserveCartStart,
  cancelEditingOrder,
  editReserveOrderInCart,
  loadEditOrder,
  loadReserveOrderCartStart,
  removeItemFromReserveOrderCartStart,
} from '../redux/modules/cart'
import { clearMenuItem, loadReserveMenuStart, selectMenuItem } from '../redux/modules/menu'
import { loadFavoriteStart, loadPopularMenuItems } from '../redux/modules/favorite'
import { resetCheckoutError } from '../redux/modules/checkout'
import { ReserveOrderSidebar } from '../components/place-order'
import { TextMaskDollarBills } from '../components/common/InputMasks'

const styles = () => ({
  menuPaper: {
    maxHeight: 400,
    marginTop: 8,
  },
  inputRoot: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  header: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '38px',
  },
})

export class PageSetDeliveryReserveFee extends Component {
  constructor(props) {
    super(props)
    this.props.clearMenuItem()
    this.handleClose = this.handleClose.bind(this)
    this.state = { reserveFee: this.props.deliveryReserveFee, errorSettingReserveFee: '' }
  }

  handleClose() {
    this.props.clearMenuItem()
    this.props.cancelEditingOrder()
  }

  getStoreIdAndLocationId = () => {
    let {
      match: {
        params: { storeId = 0, locationId = 0 },
      },
    } = this.props
    storeId = Number(storeId)
    locationId = Number(locationId)
    return { storeId, locationId }
  }

  handleAddItemsToCart = (menuItemId, itemValues, checkout) => {
    const { addItemToReserveCartStart } = this.props
    const { storeId, locationId } = this.getStoreIdAndLocationId()
    addItemToReserveCartStart(locationId, menuItemId, storeId, itemValues, checkout)
  }

  handleEditOrder = (orderItemId, menuItemId, itemValues, checkout) => {
    const { resetCheckoutError, editReserveOrderInCart } = this.props

    const { storeId, locationId } = this.getStoreIdAndLocationId()

    checkout && resetCheckoutError()

    editReserveOrderInCart(locationId, menuItemId, storeId, itemValues, orderItemId, true, checkout)
  }

  handleNext = async () => {
    const { locationId } = this.getStoreIdAndLocationId()
    const { push, orderCart } = this.props
    const { reserveFee } = this.state

    if (reserveFee === '') {
      return
    }

    const deliveryDropoffId = orderCart.deliveryDropoffId
    try {
      await setReserveFeeApi({ reserveFee }, deliveryDropoffId)
      push(
        formatUrl(checkoutRoute.path, {
          locationId: locationId,
          dropoffId: deliveryDropoffId,
        }),
      )
    } catch (error) {
      this.setState({ errorSettingReserveFee: error.message })
    }
  }

  handleRemoveItemFromCart = orderItemId => {
    const { storeId, locationId } = this.getStoreIdAndLocationId()
    const { removeItemFromReserveOrderCartStart } = this.props

    removeItemFromReserveOrderCartStart(orderItemId, storeId, locationId)
  }

  handleAddPastOrderToCart = order => {
    const { addPastOrderToReserveCart } = this.props
    const { storeId, locationId } = this.getStoreIdAndLocationId()
    addPastOrderToReserveCart(order, storeId, locationId)
  }

  async componentDidMount() {
    window && window.scrollTo(0, 0)
    const {
      loadFavoriteStart,
      loadReserveMenuStart,
      loadReserveOrderCartStart,
      loadPastOrdersReserveStart,
      loadPopularMenuItems,
      loadReserveStoreStart,
      loadDeliveryReserveFeeStart,
      orderCart,
      deliveryReserveFee,
    } = this.props

    const { storeId, locationId } = this.getStoreIdAndLocationId()
    const deliveryDropoffId = orderCart.deliveryDropoffId

    if (storeId && locationId) {
      loadReserveMenuStart(storeId, locationId)
      loadReserveOrderCartStart(storeId, locationId)
      loadPopularMenuItems()
      loadPastOrdersReserveStart(storeId)
      loadFavoriteStart()
      loadReserveStoreStart(storeId, locationId)
    }

    if (deliveryDropoffId) {
      loadDeliveryReserveFeeStart(deliveryDropoffId)
    }

    this.setState({ reserveFee: deliveryReserveFee })

    pushAnalyticsEvent('Reserve Ordering', 'Set Delivery Fee', undefined, storeId)
  }

  componentDidUpdate(prevProps) {
    const {
      isAuthenticated,
      loadFavoriteStart,
      loadReserveMenuStart,
      loadReserveOrderCartStart,
      loadPastOrdersReserveStart,
      loadReserveStoreStart,
      deliveryReserveFee,
      orderCart,
      loadDeliveryReserveFeeStart,
    } = this.props

    const { storeId, locationId } = this.getStoreIdAndLocationId()
    if (
      storeId !==
      Number(
        prevProps.match.params.storeId || locationId !== Number(prevProps.match.params.locationId),
      )
    ) {
      loadReserveMenuStart(storeId, locationId)
      loadReserveOrderCartStart(storeId, locationId)
      loadReserveStoreStart(storeId, locationId)
    }
    if (isAuthenticated !== prevProps.isAuthenticated) {
      loadPastOrdersReserveStart(storeId)
      loadFavoriteStart()
      loadReserveMenuStart(storeId, locationId)
      loadReserveOrderCartStart(storeId, locationId)
      loadReserveStoreStart(storeId, locationId)
    }
    if (prevProps.orderCart !== orderCart) {
      loadDeliveryReserveFeeStart(orderCart.deliveryDropoffId)
    }
    if (prevProps.deliveryReserveFee !== deliveryReserveFee) {
      this.setState({ reserveFee: deliveryReserveFee })
    }
  }

  handleReserveFeeChange = event => {
    const { errorSettingReserveFee } = this.state
    this.setState({ reserveFee: event })
    if (errorSettingReserveFee !== '') {
      this.setState({ errorSettingReserveFee: '' })
    }
  }

  render() {
    const {
      isAuthenticated,
      classes,
      isReserveStoreLoading,
      isLoadingDeliveryReserveFee,
      reserveStore,
      orderCart,
    } = this.props
    const { reserveFee, errorSettingReserveFee } = this.state
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const { locationId, storeId } = this.getStoreIdAndLocationId()

    if (isReserveStoreLoading || isLoadingDeliveryReserveFee) {
      return <LayoutInnerPageSkeleton locationId={locationId} storeId={storeId} />
    }

    const disableCheckout =
      !isAuthenticated ||
      reserveStore === undefined ||
      orderCart.orderItems.length === 0 ||
      reserveFee === ''

    return (
      <AuthedComponent isAuthenticated={isAuthenticated} locationId={locationId}>
        <LayoutInnerPage
          HeaderComponent={
            <HeaderInnerPage
              showBackButton
              backButtonRoute={formatUrl(pickReserveDeliveryDateAndTimeRoute.path, {
                locationId,
                storeId,
              })}
            >
              Back
            </HeaderInnerPage>
          }
          MainComponent={
            <Card>
              <Box marginBottom={4}>
                <Typography className={classes.header}>Set the delivery fee</Typography>
                <Box marginTop={3}>
                  <Typography>
                    Pay the initial delivery fee and set the rate for others. Choose $0.00 to cover
                    everyone&apos;s cost, or charge more to share it and earn credits back.{' '}
                    <b>All delivery fees from others are credited directly to you.</b>
                  </Typography>
                </Box>
              </Box>
              <Box component="section" marginBottom={4}>
                <Box>
                  <TextField
                    type="text"
                    size="small"
                    variant="outlined"
                    label="Delivery Fee (paid by others)"
                    value={reserveFee}
                    onChange={this.handleReserveFeeChange}
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                      },
                      inputComponent: TextMaskDollarBills,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <ButtonSubmit
                  color="primary"
                  fullWidth
                  disabled={disableCheckout}
                  onClick={this.handleNext}
                  variant="contained"
                  width={300}
                >
                  Continue to Checkout
                </ButtonSubmit>
              </Box>
              {errorSettingReserveFee !== '' && (
                <Box display="flex" justifyContent="center" marginTop={'2em'}>
                  <Typography color="error" align="center">
                    {errorSettingReserveFee}
                  </Typography>
                </Box>
              )}
            </Card>
          }
          SidebarComponent={
            <ReserveOrderSidebar
              shouldDisplayNextButton={false}
              handleClose={this.handleClose}
              handleNext={this.handleNext}
              handleEditOrder={this.handleEditOrder}
              handleAddItemsToCart={this.handleAddItemsToCart}
              handleRemoveItemFromCart={this.handleRemoveItemFromCart}
              personalizeOrderAddMoreIsPrimary
              personalizeOrderNextButtonIsHidden={true}
              showRestaurantLogo={true}
            />
          }
        />
      </AuthedComponent>
    )
  }
}

const LayoutInnerPageSkeleton = ({ locationId, storeId }) => {
  const headerComponent = (
    <HeaderInnerPage
      showBackButton
      backButtonRoute={formatUrl(pickReserveDeliveryDateAndTimeRoute.path, { locationId, storeId })}
    >
      Back
    </HeaderInnerPage>
  )

  const mainComponentSkeleton = (
    <Card>
      <Box padding={2}>
        <Box display="flex" flexDirection="column" gap="1em">
          <Skeleton variant="rect" height={56} />
        </Box>
      </Box>
    </Card>
  )

  const sidebarComponentSkeleton = (
    <Card>
      <Box padding={2}>
        <Skeleton variant="rect" height={150} />
        <Skeleton variant="rect" height={150} style={{ marginTop: 16 }} />
      </Box>
    </Card>
  )

  return (
    <LayoutInnerPage
      HeaderComponent={headerComponent}
      MainComponent={mainComponentSkeleton}
      SidebarComponent={sidebarComponentSkeleton}
    />
  )
}

const mapStateToProps = state => {
  const { isMenuLoading, isOrderCartLoading, menu, menuItemId } = state.menu
  const { errorMessage: pastOrderError, isPastOrderLoading, pastOrders } = state.pastorder
  const {
    isEditingFromMenu,
    isEditingOrder,
    isEditingPastOrder,
    menuItemForEdit,
    orderCart,
    orderCartError,
  } = state.cart
  const { isLoadingFavorites, isLoadingNewPopularMenuItems, isLoadingPopularItems } = state.favorite
  const popularItems = getMenuInfoForPopularItems(state)
  const newPopularItems = getMenuInfoForNewPopularItems(state)
  const deliveryError = state.delivery.errorLoading
  const { isWebpSupported } = state.browser
  const { currentUser, isAuthenticated, isCurrentUserLoading } = state.user
  const { isReserveStoreLoading, reserveStore } = state.reserve
  const { deliveryReserveFee, isLoadingDeliveryReserveFee } = state.delivery

  return {
    currentUser,
    deliveryError,
    orderCartError,
    isAuthenticated,
    isCurrentUserLoading,
    isEditingFromMenu,
    isEditingOrder,
    isEditingPastOrder,
    isLoadingFavorites,
    isLoadingNewPopularMenuItems,
    isLoadingPopularItems,
    isMenuLoading,
    isOrderCartLoading,
    isPastOrderLoading,
    isWebpSupported,
    menu,
    menuItemForEdit,
    menuItemId,
    newPopularItems,
    pastOrderError,
    pastOrders,
    popularItems,
    orderCart,
    reserveStore,
    isReserveStoreLoading,
    deliveryReserveFee,
    isLoadingDeliveryReserveFee,
  }
}

const mapDispatchToProps = {
  addPastOrderToReserveCart,
  addItemToReserveCartStart,
  cancelEditingOrder,
  clearMenuItem,
  editReserveOrderInCart,
  loadEditOrder,
  loadFavoriteStart,
  loadReserveMenuStart,
  loadReserveOrderCartStart,
  loadPastOrdersReserveStart,
  loadPopularMenuItems,
  selectMenuItem,
  removeItemFromReserveOrderCartStart,
  loadReserveStoreStart,
  push,
  resetCheckoutError,
  selectDateStart,
  loadDeliveryReserveFeeStart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(PageSetDeliveryReserveFee))
