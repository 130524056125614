import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { acknowledgeSlackbotRegistration } from '../../redux/modules/slackbot'
import { SuccessModal } from '../success-modal/SuccessModal'

const SlackBotSuccessModal = props => {
  const { acknowledgeSlackbotRegistration, error, slackbotRegistered } = props
  useEffect(() => acknowledgeSlackbotRegistration)

  if (slackbotRegistered === undefined) {
    return null
  }

  const title = slackbotRegistered ? 'Thanks a brunch!' : error.title
  const message = slackbotRegistered ? 'Foodsbot has been added to your Slack!' : error.text
  return (
    <SuccessModal
      isFailure={!slackbotRegistered}
      message={message}
      onClose={acknowledgeSlackbotRegistration}
      title={title}
    />
  )
}

const mapStateToProps = state => {
  const { error, slackbotRegistered } = state.slackbot
  return { error, slackbotRegistered }
}

const mapDispatchToProps = { acknowledgeSlackbotRegistration }

export default connect(mapStateToProps, mapDispatchToProps)(SlackBotSuccessModal)
