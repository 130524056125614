import React, { useEffect, useState } from 'react'
import { first, mapValues } from 'lodash'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import scriptLoader from 'react-async-script-loader'

import { Backdrop, CircularProgress } from '@material-ui/core'
import { locationRoute, registerRoute } from '../routes/routes'
import { MAPS_SCRIPT_URL } from '../components/location-search/LocationSearchBox'
import { search } from '../redux/modules/locationSearch'
import { formatAddress, formatUrl } from '../util/formatUtils'
import DialogSelectLocation from '../components/register/DialogSelectLocation'
import { getAddressByPlaceId } from '../services/location'

const PageLocationSearch = ({
  locations,
  loading,
  error,
  query,
  isScriptLoadSucceed,
  isScriptLoaded,
  search,
}) => {
  const [foundLocation, setFoundLocation] = useState(undefined)
  const [isSearchingAddress, setSearchingAddress] = useState(true)
  const [errorSearchingAddress, setErrorSearchingAddress] = useState(false)
  const isGoogleScriptLoaded = isScriptLoaded && isScriptLoadSucceed
  const placeId = query.placeid

  useEffect(() => {
    const searchAddress = async id => {
      try {
        const result = await getAddressByPlaceId(id)
        const formattedAddress = formatAddress(result.address)
        search(formattedAddress, false)
      } catch (ex) {
        setErrorSearchingAddress(true)
      } finally {
        setSearchingAddress(false)
      }
    }

    if (isGoogleScriptLoaded && placeId) {
      searchAddress(placeId)
    }
  }, [isGoogleScriptLoaded, placeId, search])

  useEffect(() => {
    if (locations?.length === 1) {
      setFoundLocation(first(locations))
    }
  }, [locations])

  if (isSearchingAddress || loading) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    )
  }

  if (errorSearchingAddress || error) {
    return <Redirect to={registerRoute.path} />
  }

  if (foundLocation) {
    return (
      <Redirect
        to={formatUrl(locationRoute.path, { locationId: foundLocation.deliveryLocationId })}
      />
    )
  }

  return (
    <DialogSelectLocation
      closeable={false}
      handleSelect={setFoundLocation}
      isScriptLoaded={isScriptLoaded}
      isScriptLoadSucceed={isScriptLoadSucceed}
    />
  )
}

const mapStateToProps = state => {
  const {
    location: { query },
  } = state.router
  const { error, loading, locations } = state.locationSearch

  return {
    error,
    loading,
    locations,
    query: mapValues(query, value => decodeURI(value)),
  }
}

const mapDispatchToProps = {
  search,
}

export const PageLocationSearchWithoutScripts = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageLocationSearch)

export default scriptLoader(MAPS_SCRIPT_URL)(PageLocationSearchWithoutScripts)
