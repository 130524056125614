import { themeConWeb } from '@app/util/modernThemeConweb'
import { Checkbox, Icon } from '@material-ui/core'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { addFavoriteStart, deleteFavoriteStart } from '../../redux/modules/favorite'
import { pushAnalyticsEvent } from '../../util/gtmUtils'

const Favorite = ({ classNames, id, menuId, parentComponentName, ...rest }) => {
  const dispatchRedux = useDispatch()
  const [loading, setLoading] = useState()
  const favorites = useSelector(state => state?.favorite?.favorites)
  const favorite = favorites ? favorites[id] : null
  const isDeletingFavorite = useSelector(state => state?.favorite?.isDeletingFavorite)
  const isAddingFavorite = useSelector(state => state?.favorite?.isAddingFavorite)
  const isFavorited = favorite?.isFavorite === true

  useEffect(() => {
    if (loading && !isDeletingFavorite && !isAddingFavorite) {
      setLoading(false)
    }
  }, [isAddingFavorite, isDeletingFavorite, loading])

  const handleClick = evt => {
    evt.preventDefault()
    if (!(isDeletingFavorite || isAddingFavorite)) {
      isFavorited
        ? dispatchRedux(deleteFavoriteStart(id)) &&
          pushAnalyticsEvent('Favorite', 'Deleted Favorite', parentComponentName ?? null)
        : dispatchRedux(addFavoriteStart(id, menuId)) &&
          pushAnalyticsEvent('Favorite', 'Added Favorite', parentComponentName ?? null)
      setLoading(true)
    }
  }

  const FavoriteIcon = (
    <Icon
      className={cn('-favoriteIcon', {
        '-is-Favorited': isFavorited,
        '-is-Loading': loading,
      })}
    >
      favorite_border
    </Icon>
  )

  return (
    <Component className={cn(classNames)} {...rest}>
      <Checkbox
        checked={isFavorited}
        checkedIcon={FavoriteIcon}
        className="-checkbox"
        icon={FavoriteIcon}
        onClick={handleClick}
        title={isFavorited ? 'Unfavorite this item' : 'Favorite this item'}
      />
    </Component>
  )
}

const Component = styled.div`
  & .-favoriteIcon {
    color: ${themeConWeb.color.greyShade1};
    font-size: ${themeConWeb.fontSizes.basePlus2};
  }

  & .-is-Favorited {
    color: ${themeConWeb.color.twoShade2};
    &::before {
      content: 'favorite';
    }
  }

  & .-checkbox {
    padding: 4px;
  }

  @media (hover: hover) {
    & .-checkbox:hover {
      & .-favoriteIcon::before {
        font-family: 'Material Icons';
        content: 'favorite_border';
        color: ${themeConWeb.color.twoShade2};
      }

      & .-is-Favorited::before {
        font-family: 'Material Icons';
        content: 'favorite';
      }
    }
  }
`

export default Favorite
