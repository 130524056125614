import { CheckboxOption, InputError } from '@foodsby/nutrient'
import { Box, Button, CircularProgress, TextField } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { boolean, object, string } from 'yup'

import Action from '../common/Action'
import { confirmRegisterStart } from '../../redux/modules/user'
import { termsAndConditionsRoute } from '../../routes/routes'
import { pushAnalyticsEvent } from '../../util/gtmUtils'

const schema = object().shape({
  agreeToTOS: boolean().test(
    'agreeToTOS',
    'You must accept the Terms and Conditions Agreement',
    value => value === true,
  ),
  email: string()
    .email('Enter a valid email address.')
    .required('Email is required.'),
  password: string() // NOTE: Legacy passwords are 6, new ones are 8
    .strict()
    .trim('Password cannot begin or end with a space')
    .required('Password is required.')
    .min(8, 'Fudge 🍫! Your password must be at least 8 characters.'),
})

const CreateAccountForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  hideTOS,
  isPasswordVisible,
  setFieldValue,
  setIsPasswordVisible,
  submitting,
  touched,
  values,
}) => {
  const handleChangeEmail = (event, handleChange) => {
    handleChange(event)
  }
  return (
    <form className="createAccountForm" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12">
          <Box mb={2}>
            <TextField
              autoComplete="new-password"
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              fullWidth
              id="email"
              label="Email"
              margin="dense"
              maxLength="254"
              name="email"
              onBlur={handleBlur}
              onChange={event => handleChangeEmail(event, handleChange)}
              type="email"
              value={values.email}
              variant="outlined"
              color="secondary"
            />
          </Box>
        </div>
        <div className="col-xs-12">
          <TextField
            autoComplete="new-password"
            className="confirmPassword"
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
            fullWidth
            id="confirmPassword"
            label="Password"
            margin="dense"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type={isPasswordVisible ? 'text' : 'password'}
            value={values.password}
            variant="outlined"
            color="secondary"
          />
          <div className="col end-xs showPassword" style={{ marginBottom: '16px' }}>
            <Action onClick={() => setIsPasswordVisible(!isPasswordVisible)} type="button">
              {isPasswordVisible ? 'Hide' : 'Show'} Password
            </Action>
          </div>
        </div>
        {!hideTOS && (
          <div className="col-xs-12 Checkbox">
            <CheckboxOption
              id="agreeToTOS"
              label={
                <small>
                  By signing up, you agree to our{' '}
                  <a
                    href={termsAndConditionsRoute.path}
                    rel="noopener noreferrer"
                    style={{ display: 'inline' }}
                    target="_blank"
                  >
                    Terms and Conditions Agreement
                  </a>
                </small>
              }
              name="agreeToTOS"
              onChange={handleChange}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  e.preventDefault()
                  setFieldValue('agreeToTOS', !values.agreeToTOS)
                  document.getElementById('agreeToTOS').checked = !document.getElementById(
                    'agreeToTOS',
                  ).checked
                }
              }}
              value={values.agreeToTOS}
            />
            <InputError>{touched.agreeToTOS && errors.agreeToTOS}</InputError>
          </div>
        )}
        <Box mt={3} width="100%">
          <Button
            color="primary"
            endIcon={submitting ? <CircularProgress size={15} /> : <ChevronRight />}
            fullWidth
            id="submit"
            type="submit"
            variant="contained"
          >
            Sign Up
          </Button>
        </Box>
      </div>
    </form>
  )
}

const CollectEmailPanel = ({
  confirmRegisterStart,
  confirmUserError,
  email,
  entryPoint,
  hideTOS, // Only use this prop if the TOS is presented otherwhere in the page.
  locationId,
  redirectPath,
  referralCode,
  submitting,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  return (
    <>
      <Formik
        data-gtm-elm-id="CreateAccountForm"
        enableReinitialize={true}
        initialValues={{
          agreeToTOS: hideTOS ? true : false,
          email: email,
          locationId,
          password: '',
        }}
        onSubmit={creds => {
          confirmRegisterStart(creds, redirectPath, entryPoint, referralCode)
          pushAnalyticsEvent('Registration Flow', 'Signed Up')
        }}
        validationSchema={schema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
          <CreateAccountForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            hideTOS={hideTOS}
            isPasswordVisible={isPasswordVisible}
            setFieldValue={setFieldValue}
            setIsPasswordVisible={setIsPasswordVisible}
            submitting={submitting}
            touched={touched}
            values={values}
          />
        )}
      </Formik>
      {confirmUserError && (
        <InputError visible={confirmUserError && confirmUserError !== null}>
          {confirmUserError}
        </InputError>
      )}
    </>
  )
}

const mapStateToProps = state => {
  const { confirmRegisterStart, confirmUserError, currentUser, submitting } = state.user
  return {
    confirmRegisterStart,
    confirmUserError,
    currentUser,
    submitting,
  }
}

const mapDispatchToProps = {
  confirmRegisterStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectEmailPanel)
