import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import Header from '../components/teams/admin/Header'
import CardPrograms from '../components/teams/perks/CardPrograms'
import { selectTeam } from '../redux/selectors/teamSelectors'
import { selectTeamPrograms } from '../redux/selectors/teamProgramsSelectors'
import {
  loadAvailableOffersStart,
  loadProgramsStart,
  setOfferRedeeming,
} from '../redux/modules/teamPrograms'
import { setSelectedProgram, resetPerkDetailsState } from '../redux/modules/teamPerkDetails'
import { loadTeamPaymentMethodsStart } from '../redux/modules/team'
import PerkCards from '../components/teams/perks/PerkCards'
import { loadAccountStart } from '../redux/modules/account'
import { selectAccount } from '../redux/selectors/accountSelectors'
import { loadTeamSubscriptionStart } from '../redux/modules/teamSubscription'

function PageTeamPerks({
  account,
  loadAccountStart,
  isLoadingTeam,
  isLoadingPrograms,
  loadProgramsStart,
  loadTeamPaymentMethodsStart,
  team,
  programs,
  paymentMethods,
  selectedProgram,
  setSelectedProgram,
  offerRedeeming,
  setOfferRedeeming,
  resetPerkDetailsState,
  availableOffers,
  loadAvailableOffersStart,
  match,
  teamSubscription,
  isLoadingSubscription,
  loadTeamSubscriptionStart,
}) {
  let {
    params: { accountId },
  } = match
  const defaultPaymentMethodOrUndefined = paymentMethods.find(pm => pm.default === true)

  useEffect(() => {
    if (account === undefined) {
      loadAccountStart(accountId)
    }
  }, [account, accountId, loadAccountStart])

  useEffect(() => {
    accountId && loadAvailableOffersStart(accountId)
  }, [loadAvailableOffersStart, accountId])

  useEffect(() => {
    accountId && loadProgramsStart(accountId)
  }, [loadProgramsStart, accountId])

  useEffect(() => {
    accountId && loadTeamPaymentMethodsStart(accountId)
  }, [loadTeamPaymentMethodsStart, accountId])

  useEffect(() => {
    team && loadTeamSubscriptionStart()
  }, [loadTeamSubscriptionStart])

  const subscribed = Boolean(teamSubscription)

  return (
    <>
      <Header account={account} name={account?.name} />
      {isLoadingTeam || isLoadingSubscription ? (
        <SkeletonLoading />
      ) : (
        <Box mt={4}>
          <PerkCards subscribed={subscribed} />
          <Box paddingTop={3} paddingBottom={3}>
            <CardPrograms
              team={team}
              programs={programs}
              defaultPaymentMethod={defaultPaymentMethodOrUndefined}
              loading={isLoadingPrograms}
              selectedProgram={selectedProgram}
              setSelectedProgram={setSelectedProgram}
              setOfferRedeeming={setOfferRedeeming}
              offerRedeeming={offerRedeeming}
              resetPerkDetailsState={resetPerkDetailsState}
              availableOffers={availableOffers}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

const SkeletonLoading = () => (
  <>
    <Box marginY={3}>
      <Skeleton variant="rect" width="100%" height="42px" />
    </Box>
    <Skeleton variant="rect" width="100%" height="400px" />
  </>
)

const mapStateToProps = state => {
  return {
    isLoadingTeam: state.team.isLoadingTeam,
    isLoadingPrograms: state.teamPrograms.isLoadingPrograms,
    account: selectAccount(state),
    team: selectTeam(state),
    programs: selectTeamPrograms(state),
    paymentMethods: state.team.paymentMethods,
    selectedProgram: state.teamPerkDetails.selectedProgram,
    availableOffers: state.teamPrograms.availableOffers,
    offerRedeeming: state.teamPrograms.offerRedeeming,
    teamSubscription: state.teamSubscription.teamSubscription,
    isLoadingSubscription: state.teamSubscription.isLoadingSubscription,
  }
}

const mapDispatchToProps = {
  loadAccountStart,
  loadProgramsStart,
  loadTeamPaymentMethodsStart,
  setSelectedProgram,
  setOfferRedeeming,
  resetPerkDetailsState,
  loadAvailableOffersStart,
  loadTeamSubscriptionStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTeamPerks)
