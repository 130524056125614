import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ArticleLarge } from '../components/common/Layout'
import OrderHistoryTable from '../components/history/OrderHistoryTable'
import { loadFavoriteStart } from '../redux/modules/favorite'
import { loadOrderByIdStart } from '../redux/modules/orderDetail'
import { loadOrderHistoryStart } from '../redux/modules/orderHistory'
import { loadReasonsByOrder } from '../redux/modules/ticket'

const page = 1
const pageSize = 15
export class PageOrderHistory extends Component {
  state = { didLoadDefault: false }

  UNSAFE_componentWillMount() {
    const { loadOrderHistoryStart } = this.props
    loadOrderHistoryStart(page, pageSize)
  }

  componentDidUpdate() {
    // Selects latest order by default
    if (
      !this.props.isLoadingOrderHistory &&
      !this.state.didLoadDefault &&
      this.props.history.totalPages > 0
    ) {
      this.loadOrderDetails(this.props.history.orders[0].orderId)
      this.setState({ didLoadDefault: true })
    }
  }

  loadOrderDetails = id => {
    const { loadOrderByIdStart, loadReasonsByOrder } = this.props
    loadOrderByIdStart(id)
    loadReasonsByOrder(id)
  }

  render() {
    const { history, isLoadingOrderHistory, loadOrderHistoryStart } = this.props

    const paginationConfig = {
      currentPage: history.currentPage || 1,
      handleChange: page => loadOrderHistoryStart(page, pageSize),
      totalPages: history.totalPages || 1,
    }

    return (
      <ArticleLarge>
        <OrderHistoryTable
          loading={isLoadingOrderHistory}
          orders={history.orders}
          pagination={paginationConfig}
        />
      </ArticleLarge>
    )
  }
}

const mapStateToProps = state => {
  const {
    favorite: { favorites },
    orderHistory: { history },
  } = state

  const isLoadingOrderHistory = state.orderHistory.loading

  return {
    favorites,
    history,
    isLoadingOrderHistory,
  }
}

const mapDispatchToProps = {
  loadFavoriteStart,
  loadOrderByIdStart,
  loadOrderHistoryStart,
  loadReasonsByOrder,
}

PageOrderHistory.defaultProps = {
  history: {},
}

export default connect(mapStateToProps, mapDispatchToProps)(PageOrderHistory)
