import { LocationStatusType } from './constants'

// Places the default location at the beginning of the list
export const formatSavedLocations = (savedLocations, defaultLocationId) => {
  if (savedLocations && defaultLocationId) {
    const defaultLocation = savedLocations?.find(
      savedLocation => savedLocation.deliveryLocationId === defaultLocationId,
    )
    if (defaultLocation) {
      savedLocations.splice(savedLocations.indexOf(defaultLocation), 1)
      savedLocations.unshift(defaultLocation)
    }

    return savedLocations
  }
}

export const isLocationActive = activationDate => {
  if (!activationDate) {
    return false
  }

  const date = new Date(activationDate)
  if (isNaN(date.getTime())) {
    return false
  }
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  date.setHours(0, 0, 0, 0)

  return now >= date
}

export const isLocationProspectOrUserCreated = locationStatus => {
  if (
    locationStatus === LocationStatusType.PROSPECT ||
    locationStatus === LocationStatusType.USERCREATED
  ) {
    return true
  }
  return false
}

export const isLocationUpcomingLaunch = (locationStatus, activationDate) => {
  const date = new Date(activationDate)
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  date.setHours(0, 0, 0, 0)
  if (
    (locationStatus === LocationStatusType.PREFERRED ||
      locationStatus === LocationStatusType.NONPREFERRED) &&
    date > now
  ) {
    return true
  }
  return false
}
