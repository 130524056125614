import React from 'react'
import { Box, Paper, Typography } from '@material-ui/core'

const CardTeam = ({ children }) => {
  return (
    <Box mb={2}>
      <Paper variant="outlined" elevation={0}>
        <Box padding={4}>{children}</Box>
      </Paper>
    </Box>
  )
}

export const CardTeamContent = ({ children }) => {
  return <Box marginTop={3}>{children}</Box>
}

export const CardTeamTitle = ({ children, ...rest }) => {
  return (
    <Typography variant="h6" {...rest}>
      {children}
    </Typography>
  )
}

export default CardTeam
