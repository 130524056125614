import React, { isValidElement, useEffect, useState } from 'react'
import { Box, Button, Card, Snackbar, Typography } from '@material-ui/core'
import { Alert, Skeleton } from '@material-ui/lab'
import { connect, useDispatch, useSelector } from 'react-redux'
import { find } from 'lodash'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'

import { createSetupIntent, getCaptcha } from '../../../api/api'
import useSubscriptions from '../../../hooks/useSubscriptions'
import { selectAllPaymentMethods } from '../../../redux/modules/selectors'
import { loadPaymentMethodsStart } from '../../../redux/modules/paymentMethods'
import PaymentMethod, { ButtonPaymentMethod } from '../../common/PaymentMethod'
import PanelHeader from '../../common/PanelHeader'
import PaymentMethodItem from '../../common/PaymentMethodItem'
import {
  cancelSubscriptionStart,
  createSubscriptionStart,
  loadSubscriptionDetailsStart,
  loadSubscriptionOfferStart,
  updateSubscriptionStart,
} from '../../../redux/modules/subscriptions'
import {
  hasActiveSubscription,
  hasRequestedSubscriptionCancelation,
} from '../../../util/subscriptions'
import DialogUnsubscribe from './DialogUnsubscribe'
import SubscriptionButton from './SubscriptionButton'
import SubscriptionMessage from './SubscriptionMessage'

const ERROR_MESSAGE_NO_CARD_SELECTED = 'Please select a payment method.'

const PanelSubscriptions = ({
  header: Header,
  component: Component = Card,
  message: Message,
  onSubscribeSuccess,
  loadSubscriptionDetailsStart,
  loadSubscriptionOfferStart,
  loadPaymentMethodsStart,
  createSubscriptionStart,
  updateSubscriptionStart,
  cancelSubscriptionStart,
}) => {
  const dispatch = useDispatch()
  const paymentMethods = useSelector(selectAllPaymentMethods)
  const {
    subscriptionDetails,
    isSubscriptionEligible,
    subscriptionOffer,
    isSubscriptionBeneficiary,
    isLoadingSubscriptionDetails,
    isLoadingSubscriptionOffer,
    errorLoadingSubscriptionDetails,
    isSubscribed,
    isSubmittingSubscription,
    successSubmittingSubscription,
    errorSubmittingSubscription,
  } = useSubscriptions()

  const elements = useElements()
  const stripe = useStripe()

  // States
  const [isSubmittingPaymentMethod, setSubmittingPaymentMethod] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState(null)
  const [showPaymentMethod, setShowPaymentMethod] = useState(false)
  const [showDialogUnsubscribe, setShowDialogUnsubscribe] = useState(false)
  const [errorPaymentMethod, setErrorPaymentMethod] = useState(null)

  const onCreateSubscription = () => {
    if (!showPaymentMethod) {
      setErrorPaymentMethod(ERROR_MESSAGE_NO_CARD_SELECTED)
      return
    }
    if (paymentMethodId) {
      createSubscriptionStart(paymentMethodId)
    } else {
      createNewPaymentMethod(true)
    }
  }

  const onUpdateSubscription = () => {
    if (paymentMethodId) {
      updateSubscriptionStart(subscriptionDetails.subscriptionId, paymentMethodId)
    } else {
      createNewPaymentMethod(false)
    }
  }

  const handleCancelSubscription = () => {
    cancelSubscriptionStart(subscriptionDetails.subscriptionId)
  }

  const createNewPaymentMethod = async createMode => {
    setSubmittingPaymentMethod(true)
    if (!elements || !stripe) {
      setErrorPaymentMethod('Payment System not initialized. Please contact support.')
      setSubmittingPaymentMethod(false)
      return
    }

    const captchaToken = await getCaptcha()
    const setupIntentRes = await createSetupIntent({ captchaToken })
    if (setupIntentRes.error) {
      setErrorPaymentMethod(setupIntentRes.message)
      setSubmittingPaymentMethod(false)
      return
    }

    const reqBody = {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    }

    const { setupIntent, error } = await stripe.confirmCardSetup(
      setupIntentRes.clientSecret,
      reqBody,
    )
    if (error) {
      setErrorPaymentMethod(error.message)
      setSubmittingPaymentMethod(false)
      return
    }

    const pm = setupIntent.payment_method
    createMode
      ? createSubscriptionStart(pm)
      : updateSubscriptionStart(subscriptionDetails.subscriptionId, pm)

    setSubmittingPaymentMethod(false)
  }

  useEffect(() => {
    loadSubscriptionDetailsStart()
    loadSubscriptionOfferStart()
  }, [loadSubscriptionDetailsStart, loadSubscriptionOfferStart])

  useEffect(() => {
    if (paymentMethods.length === 0) {
      dispatch(loadPaymentMethodsStart())
    }
  }, [dispatch, loadPaymentMethodsStart, paymentMethods.length])

  useEffect(() => {
    if (paymentMethodId) {
      setErrorPaymentMethod(null)
    }
  }, [paymentMethodId])

  useEffect(() => {
    if (successSubmittingSubscription) {
      loadPaymentMethodsStart()
      loadSubscriptionDetailsStart()
      setShowPaymentMethod(false)
      setShowDialogUnsubscribe(false)

      if (onSubscribeSuccess) {
        onSubscribeSuccess()
      }
    }
  }, [
    loadPaymentMethodsStart,
    loadSubscriptionDetailsStart,
    onSubscribeSuccess,
    successSubmittingSubscription,
  ])

  const pendingCancelation = hasRequestedSubscriptionCancelation(subscriptionDetails)
  const selectedPaymentMethod = find(
    paymentMethods,
    pm => subscriptionDetails?.paymentMethodId === pm.providerId,
  )

  if (isLoadingSubscriptionDetails || isLoadingSubscriptionOffer) {
    return <SkeletonLoading />
  }

  if (!isSubscriptionEligible || isSubscriptionBeneficiary) {
    return null
  }

  return (
    <Component>
      {isValidElement(Header) ? (
        Header
      ) : (
        <PanelHeader
          headerRight={
            isSubscribed &&
            !errorLoadingSubscriptionDetails &&
            !pendingCancelation && (
              <Button
                className="-button"
                color="primary"
                onClick={() => setShowDialogUnsubscribe(true)}
                size="small"
              >
                Unsubscribe
              </Button>
            )
          }
          title="Foodsby Unlimited"
        />
      )}

      {!errorLoadingSubscriptionDetails && (
        <>
          {isValidElement(Message) ? (
            Message
          ) : (
            <SubscriptionMessage subscription={subscriptionDetails} offer={subscriptionOffer} />
          )}
          <Box marginBottom={4}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" mb={1}>
              <Typography className="-title" variant="subtitle2">
                {isSubscribed && !pendingCancelation ? 'Applied' : 'Apply'} to
              </Typography>
              {isSubscribed && !showPaymentMethod && !pendingCancelation && (
                <Button
                  className="-button"
                  color="primary"
                  onClick={() => setShowPaymentMethod(true)}
                  size="small"
                >
                  Change
                </Button>
              )}
            </Box>
            {isSubscribed && !showPaymentMethod && !pendingCancelation && (
              <ButtonPaymentMethod disabled>
                <PaymentMethodItem paymentMethod={selectedPaymentMethod} />
              </ButtonPaymentMethod>
            )}
            {(!isSubscribed || pendingCancelation) && !showPaymentMethod && (
              <ButtonPaymentMethod onClick={() => setShowPaymentMethod(true)}>
                <Typography color="primary" variant="body2">
                  Select payment method
                </Typography>
              </ButtonPaymentMethod>
            )}
            {showPaymentMethod && (
              <PaymentMethod
                paymentMethodId={paymentMethodId}
                onError={setErrorPaymentMethod}
                setPaymentMethodId={setPaymentMethodId}
                CardElement={CardElement}
              />
            )}
          </Box>
          <SubscriptionButton
            handleClick={
              hasActiveSubscription(subscriptionDetails) && !pendingCancelation
                ? onUpdateSubscription
                : onCreateSubscription
            }
            submitting={isSubmittingPaymentMethod || isSubmittingSubscription}
            subscription={subscriptionDetails}
            offer={subscriptionOffer}
            updatingCard={showPaymentMethod}
          />
          {(errorPaymentMethod || errorSubmittingSubscription) && (
            <Typography color="error" variant="body2">
              {errorPaymentMethod || errorSubmittingSubscription}
            </Typography>
          )}
          <DialogUnsubscribe
            handleClose={() => setShowDialogUnsubscribe(false)}
            handleSubmit={handleCancelSubscription}
            submitting={isSubmittingSubscription}
            subscription={subscriptionDetails}
            visible={showDialogUnsubscribe}
          />
        </>
      )}
      <Snackbar open={!!errorLoadingSubscriptionDetails}>
        <Alert severity="error">{errorLoadingSubscriptionDetails}</Alert>
      </Snackbar>
    </Component>
  )
}

const SkeletonLoading = () => (
  <Box>
    <Box display="flex" flexDirection="row" justifyContent="space-between" marginBottom={3}>
      <Skeleton height={40} width={160} />
      <Skeleton height={40} width={160} />
    </Box>
    <Skeleton />
    <Skeleton width={200} />
    <Skeleton height={80} />
    <Skeleton height={60} width={140} />
  </Box>
)

const mapStateToProps = state => {
  return {}
}
const mapDispatchToProps = {
  loadSubscriptionDetailsStart,
  loadSubscriptionOfferStart,
  loadPaymentMethodsStart,
  createSubscriptionStart,
  updateSubscriptionStart,
  cancelSubscriptionStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelSubscriptions)
