import { Box, Typography } from '@material-ui/core'
import React from 'react'

export const SubscriptionPromoHeader = () => (
  <Box marginBottom={4} textAlign="center">
    <Typography align="center" component="p" variant="subtitle1">
      SAY GOODBYE TO SERVICE FEES WITH
    </Typography>
    <Typography variant="h4">Foodsby Unlimited</Typography>
  </Box>
)

export default SubscriptionPromoHeader
