import React from 'react'
import { useParams } from 'react-router-dom'
import { find } from 'lodash'

import SurveyContainer from '../components/surveys/SurveyContainer'
import firstOrderSurvey from '../components/surveys/surveyDefinitions/firstOrderSurvey'

const surveyList = [
  {
    name: 'Welcome Survey',
    id: 'first-order',
    json: firstOrderSurvey,
    version: '1.0.0',
  },
]

function SurveyPage() {
  const { id } = useParams()

  const survey = find(surveyList, sur => sur.id === id)
  if (!survey) {
    return 'No Survey Found'
  }

  return <SurveyContainer survey={survey} />
}

export default SurveyPage
