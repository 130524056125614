import React from 'react'
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import { themeConWeb } from '@app/util/modernThemeConweb'
import { noop } from 'lodash'
import { getSelectionDetails } from '../admin/adminUtils'
import AltHeaderTableMembers from './AltHeaderTableMembers'

const useStyles = makeStyles({
  tableCell: {
    padding: ({ checkbox }) => (checkbox ? '0px 16px' : '8px 16px'),
    color: 'white',
  },
  row: {
    backgroundColor: themeConWeb.color.greyShade7,
    color: themeConWeb.color.black,
  },
  altHeaderRow: {
    backgroundColor: themeConWeb.color.greyShade2,
    color: themeConWeb.color.greyShade6,
  },
  checkboxCell: {
    width: '20px',
  },
  root: {
    color: 'white',
    '&:hover': {
      color: 'white',
    },
    '&$active': {
      color: 'white',
    },
  },
  active: {},
  icon: {
    color: 'inherit !important',
  },
})

const SortableHeaderCell = ({ id, label, active, sortDirection, onSort }) => {
  const classes = useStyles()

  const createSortHandler = property => event => {
    onSort(event, property)
  }

  return (
    <TableCell className={classes.tableCell}>
      <TableSortLabel
        active={active}
        classes={{
          root: classes.root,
          active: classes.active,
          icon: classes.icon,
        }}
        direction={active ? sortDirection : 'asc'}
        onClick={createSortHandler(id)}
        aria-label={sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
        role="button"
      >
        {label}
      </TableSortLabel>
    </TableCell>
  )
}

const HeaderTableMembers = ({
  checkbox,
  teamMembers,
  columns,
  rows,
  selectedMembers = [],
  sort,
  onSelectOnePage = noop,
  onSelectAll = noop,
  onSort,
  Toolbox,
}) => {
  const classes = useStyles({ checkbox })

  const totalRows = teamMembers.length
  const numSelected = selectedMembers.length

  const { allSelected, allPageSelected, someSelected, someSelectedOnPage } = getSelectionDetails(
    teamMembers,
    rows,
    selectedMembers,
  )
  return (
    <>
      <TableHead>
        <TableRow className={classes.row}>
          {checkbox && (
            <TableCell className={cn([classes.tableCell, classes.checkboxCell])}>
              <Checkbox
                indeterminate={someSelectedOnPage && !allPageSelected}
                checked={allPageSelected}
                style={{
                  color: 'white',
                }}
                onChange={e => onSelectOnePage(e.target.checked)}
                inputProps={{ 'aria-label': 'select all members' }}
              />
            </TableCell>
          )}

          {someSelected && Toolbox && (
            <TableCell colSpan={columns.length} className={classes.tableCell}>
              {Toolbox}
            </TableCell>
          )}

          {(!someSelected || !Toolbox) &&
            columns.map(cell => {
              if (cell.sortable) {
                return (
                  <SortableHeaderCell
                    key={cell.id}
                    id={cell.id}
                    label={cell.label}
                    active={cell.id === sort.field}
                    sortDirection={sort.direction}
                    onSort={onSort}
                  >
                    {cell.label}
                  </SortableHeaderCell>
                )
              }

              return (
                <TableCell key={cell.id} className={classes.tableCell}>
                  {cell.label}
                </TableCell>
              )
            })}
        </TableRow>
        {numSelected > 0 && (
          <TableRow className={classes.altHeaderRow}>
            <TableCell colSpan={columns.length + 1} className={classes.tableCell}>
              <AltHeaderTableMembers
                allSelected={allSelected}
                totalRows={totalRows}
                numSelected={numSelected}
                onSelectAll={onSelectAll}
              />
            </TableCell>
          </TableRow>
        )}
      </TableHead>
    </>
  )
}

export default HeaderTableMembers
