import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import promise from 'redux-promise-middleware'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'

import reducers from './modules/reducers'
import root from './modules/sagas'

export const history = createBrowserHistory()
const initialState = {}
const enhancers = []
const reduxRouterMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()

let middleware = [reduxRouterMiddleware, sagaMiddleware, thunk, promise]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
  if (!process.env.REACT_APP_DISABLE_LOGGING) {
    // Conditionally import logger here so Webpack can optimize it away if not needed
    const logger = require('redux-logger').createLogger({
      // Ignore all the redux-form actions - they clutter the logs
      predicate: (getState, action) => !action.type.startsWith('@@redux-form'),
    })

    middleware = [...middleware, logger]
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

// This function allows us to create a store with a state for testing purposes
export const createStoreWithState = state =>
  createStore(reducers(history), state, composedEnhancers)

const store = createStoreWithState(initialState)

sagaMiddleware.run(root)

export default store
