import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import currency from 'currency.js'

const programTypes = Object.freeze({
  DAILY: 'today',
  MONTHLY: 'this month',
  WEEKLY: 'this week',
})

const Program = ({ program, loading = true }) => {
  if (loading) {
    return <SkeletonLoading />
  }

  return (
    <>
      <Box marginBottom={1}>
        <Typography variant="subtitle1">{program.programName}</Typography>
      </Box>
      <Typography>
        {currency(program.discountAmountInPennies / 100, { formatWithSymbol: true }).format()}
        &nbsp;of&nbsp;
        {currency(program.originalDiscountAmountInPennies / 100, {
          formatWithSymbol: true,
        }).format()}
        &nbsp;remaining {programTypes[program.programUsageType]}
      </Typography>
    </>
  )
}

const SkeletonLoading = () => (
  <>
    <Skeleton height={30} width="40%" />
    <Skeleton height={40} width="60%" />
  </>
)

export default Program
