import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { rootRoute } from '../../routes/routes'

const ButtonBackToHome = ({ buttonProps }) => {
  return (
    <Link to={rootRoute.path} style={{ textDecoration: 'none' }}>
      <Button {...buttonProps}>Back to home</Button>
    </Link>
  )
}

ButtonBackToHome.propTypes = {
  buttonProps: PropTypes.shape({
    variant: PropTypes.oneOf(['contained', 'text']),
    color: PropTypes.oneOf(['primary', 'secondary']),
  }),
}

export default ButtonBackToHome
