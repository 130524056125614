import { Dialog, DialogContent } from '@material-ui/core'
import { first, isEmpty, isFunction, noop } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { clearSearch } from '../../redux/modules/locationSearch'
import { locationRoute } from '../../routes/routes'
import { LocationType } from '../../util/constants'
import { formatUrl } from '../../util/formatUtils'
import SelectLocation from '../location-search/SelectLocation'

export const DialogSelectLocation = ({
  closeable = true,
  handleSelect,
  isScriptLoadSucceed,
  isScriptLoaded,
  locationListButtonText,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { address, error, lat, lng, loading, locations, noResults } = useSelector(
    state => state.locationSearch,
  )

  // By default, push the location route
  const defaultHandleSelect = useCallback(
    location =>
      history.push(formatUrl(locationRoute.path, { locationId: location.deliveryLocationId })),
    [history],
  )

  // Auto select single result
  useEffect(() => {
    if (locations?.length === 1 && first(locations)?.type === LocationType.RESIDENTIAL_HOME) {
      isFunction(handleSelect)
        ? handleSelect(first(locations))
        : defaultHandleSelect(first(locations))
      dispatch(clearSearch())
    }
  }, [defaultHandleSelect, dispatch, handleSelect, locations])

  // Clear search on dialog close
  useEffect(() => {
    return () => dispatch(clearSearch())
  }, [dispatch])

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={closeable ? () => dispatch(clearSearch()) : noop}
      open={!isEmpty(locations) || Boolean(noResults)}
    >
      <DialogContent style={{ padding: 48 }}>
        {isScriptLoaded && isScriptLoadSucceed && (
          <SelectLocation
            address={address}
            lat={address ? lat : undefined}
            lng={address ? lng : undefined}
            locations={locations}
            locationListButtonText={locationListButtonText}
            onClickOverride={location => {
              isFunction(handleSelect) ? handleSelect(location) : defaultHandleSelect(location)
              dispatch(clearSearch())
            }}
            showError={error}
            submitting={loading}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default DialogSelectLocation
