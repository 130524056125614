import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container, Grid, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'
import { goBack, push } from 'connected-react-router'
import { ArrowBackIos } from '@material-ui/icons'
import { themeConWeb } from '../util/modernThemeConweb'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    minHeight: 'calc(100vh - 217px)',
    paddingLeft: 0,
  },
  container: {
    marginTop: -1,
    marginLeft: 0,
  },
  sidebarContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: '100%',
    width: '300px',
    borderRight: `1px solid ${themeConWeb.color.greyShade2}`,
  },
  sidebarInner: {
    position: 'fixed',
    paddingTop: '30px',
    top: theme.spacing(6),
    height: '100%',
    paddingRight: '0px',
    width: '300px',
  },
  buttonRoot: {
    position: 'absolute',
    left: '-50px',
    backgroundColor: theme.palette.common.white,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    height: '50px',
    width: '50px',
    '@media only screen and (max-width: 1400px)': {
      display: 'none',
    },
  },
  buttonIcon: {
    color: theme.palette.common.black,
    transform: 'translateX(3px)',
    fontSize: '14px',
  },
  titleRoot: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  titleRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: ({ showBackButton }) => ({
    ...theme.typography.h5,
    fontWeight: 'normal',
    marginLeft: showBackButton ? theme.spacing(2) : 0,
    '@media only screen and (max-width: 1400px)': {
      marginLeft: 0,
    },
  }),
  rightSidebar: {
    paddingTop: '32px',
    paddingLeft: '15px',
    paddingRight: '15px',
    '@media only screen and (max-width: 1280px)': {
      display: 'none',
    },
  },
  [theme.breakpoints.down('sm')]: {
    rightSidebar: { display: 'none' },
  },
  [theme.breakpoints.down('sm')]: {
    sidebarContainer: { display: 'none' },
    rightSidebar: { display: 'none' },
    titleRow: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    sideElementRow: {
      marginTop: theme.spacing(2),
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}))

const LayoutInnerHomePage = ({
  HeaderComponent,
  MainComponent,
  SidebarComponent,
  containerMaxWidth,
  RightSidebarComponents,
}) => {
  const headerExists = Boolean(HeaderComponent)

  const classes = useStyles({ headerExists })
  return (
    <Grid className={classes.root} container spacing={2}>
      <Grid item md={2} sm={0}>
        <Grid className={classes.sidebarContainer}>
          <div className={classes.sidebarInner}>{SidebarComponent}</div>
        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
        {headerExists && HeaderComponent}
        <Container maxWidth={containerMaxWidth} className={classes.container}>
          <Grid container>
            <Grid item md={2} lg={1} />
            <Grid item sm={12} md={10} lg={8} xs={12} className={classes.main}>
              {MainComponent}
            </Grid>
            <Grid md={2} xl={3} item className={classes.rightSidebar}>
              {RightSidebarComponents.map((Component, index) => (
                <Box id={index} key={index} style={{ paddingBottom: '3em' }}>
                  <Component />
                </Box>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  )
}

LayoutInnerHomePage.propTypes = {
  HeaderComponent: PropTypes.element,
  MainComponent: PropTypes.element.isRequired,
  SidebarComponent: PropTypes.element,
  containerMaxWidth: PropTypes.oneOf([false, 'xs', 'sm', 'md', 'lg', 'xl']),
  hideSidebar: PropTypes.oneOf([false, 'xs', 'sm']),
}

LayoutInnerHomePage.defaultProps = {
  HeaderComponent: undefined,
  SidebarComponent: undefined,
  containerMaxWidth: 'lg',
  hideSidebar: false,
}

export const HeaderInnerPage = ({
  children,
  showBackButton,
  backButtonRoute,
  maxWidth,
  sideElement,
}) => {
  const classes = useStyles({ showBackButton })
  return (
    <Container className={classes.titleRoot} maxWidth={maxWidth}>
      {showBackButton && <ButtonBack backRoute={backButtonRoute} />}
      <Box className={classes.titleRow}>
        <Typography component="h1" className={classes.title}>
          {children}
        </Typography>
        {sideElement && <Box className={classes.sideElementRow}>{sideElement}</Box>}
      </Box>
    </Container>
  )
}

HeaderInnerPage.propTypes = {
  children: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool,
  backButtonRoute: PropTypes.string,
  maxWidth: PropTypes.oneOf([false, 'xs', 'sm', 'md', 'lg', 'xl']),
}

HeaderInnerPage.defaultProps = {
  showBackButton: false,
  backButtonRoute: undefined,
  maxWidth: 'lg',
}

const ButtonBack = ({ backRoute }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClick = () => {
    if (backRoute) {
      dispatch(push(backRoute))
    } else {
      dispatch(goBack())
    }
  }

  return (
    <IconButton className={classes.buttonRoot} onClick={handleClick} data-testid="back-button">
      <ArrowBackIos className={classes.buttonIcon} />
    </IconButton>
  )
}

export default LayoutInnerHomePage
