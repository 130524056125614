export const sortContentCards = cards => cards?.sort(compareCards)

const compareCards = (cardA, cardB) => {
  if (areCardsEqual(cardA, cardB)) {
    return 0
  }
  if (isPinnedUnviewed(cardA)) {
    return -1
  }
  if (isPinnedUnviewed(cardB)) {
    return 1
  }
  if (isPinned(cardA)) {
    return -1
  }
  if (isPinned(cardB)) {
    return 1
  }

  return isUnviewed(cardA) ? -1 : 1
}

const areCardsEqual = (cardA, cardB) =>
  cardA.viewed === cardB.viewed && cardA.pinned === cardB.pinned
const isPinnedUnviewed = card => card.pinned && !card.viewed
const isPinned = card => card.pinned
const isUnviewed = card => !card.viewed
