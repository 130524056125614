import React from 'react'
import { Box, DialogActions, DialogContentText, TextField, MenuItem } from '@material-ui/core'

import { Form, Formik } from 'formik'

import ButtonSubmit from '../../common/ButtonSubmit'
import DialogContent from './DialogContent'
import { PROGRAM_DIALOG_HEIGHT } from './programUtils'

const StepProgramGiftDetails = ({
  values,
  handleClose,
  handleGetTotal,
  setValues,
  validationSchema,
}) => {
  const handleSubmit = newValues => {
    setValues({
      ...values,
      reason: newValues.reason,
      message: newValues.message,
    })
    handleGetTotal({
      reason: newValues.reason,
      message: newValues.message,
    })
    handleClose()
  }

  return (
    <Formik
      initialValues={{
        reason: values.reason,
        message: values.message,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {props => {
        const { values: subValues, errors, touched, handleChange } = props
        return (
          <Form>
            <DialogContent height={PROGRAM_DIALOG_HEIGHT}>
              <DialogContentText>What do you want to name your perk?</DialogContentText>
              <Box marginTop={4} marginBottom={4}>
                <TextField
                  select
                  autoFocus
                  name="reason"
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="What's the occasion?"
                  value={subValues.reason}
                  onChange={handleChange}
                  error={touched.reason && Boolean(errors.reason)}
                  helperText={touched.reason && errors.reason}
                >
                  <MenuItem value="Birthday">Birthday</MenuItem>
                  <MenuItem value="Work Anniversary">Work Anniversary</MenuItem>
                  <MenuItem value="Employee of the Month">Employee of the Month</MenuItem>
                  <MenuItem value="Performance Reward">Performance Reward</MenuItem>
                  <MenuItem value="Team Reward">Team Reward</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </TextField>
              </Box>
              <Box marginY={4}>
                <TextField
                  multiline
                  name="message"
                  fullWidth
                  size="small"
                  rowsMax={3}
                  rows={3}
                  variant="outlined"
                  label="Add a personal message (optional)"
                  value={subValues.message}
                  onChange={handleChange}
                  error={touched.message && Boolean(errors.message)}
                  helperText={touched.message && errors.message}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <ButtonSubmit color="primary" variant="contained">
                Save
              </ButtonSubmit>
            </DialogActions>
          </Form>
        )
      }}
    </Formik>
  )
}

export default StepProgramGiftDetails
