import React from 'react'

import { Typography, Box } from '@material-ui/core'
import {
  hasActiveSubscription,
  hasRequestedSubscriptionCancelation,
  isEligibleForFreeTrial,
} from '../../../util/subscriptions'
import ButtonSubmit from '../../common/ButtonSubmit'

const SubscriptionButton = ({
  handleClick,
  subscription,
  offer,
  submitting,
  updatingCard,
  ...rest
}) => {
  let text = 'Subscribe'
  if (isEligibleForFreeTrial(offer)) {
    text = 'Try For Free'
  }
  if (hasRequestedSubscriptionCancelation(subscription)) {
    text = 'Reactivate'
  }

  if (hasActiveSubscription(subscription) && !hasRequestedSubscriptionCancelation(subscription)) {
    if (updatingCard) {
      text = 'Select Card'
    } else {
      return null
    }
  }

  return (
    <>
      <ButtonSubmit
        color="primary"
        onClick={handleClick}
        submitting={submitting}
        variant="contained"
        {...rest}
      >
        {text}
      </ButtonSubmit>
      <Box mt={3}>
        <Typography variant="caption" color="textSecondary">
          This site is protected by reCAPTCHA and the Google{' '}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </Typography>
      </Box>
    </>
  )
}

export default SubscriptionButton
