import { InputError } from '@foodsby/nutrient'
import { Card } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { reduxForm } from 'redux-form'

import { Article } from '../components/common/Layout'
import { SimpleSpinner } from '../components/common/SimpleSpinner'
import ForgotPasswordForm from '../components/login/ForgotPasswordForm'
import { resetPasswordStart } from '../redux/modules/forgotPassword'
import { homeRoute } from '../routes/routes'

const ForgotPasswordFormContainer = reduxForm({ form: 'forgotPassword' })(ForgotPasswordForm)

class PageForgotPassword extends Component {
  render() {
    const {
      isAuthenticated,
      isCurrentUserLoading,
      resetPasswordError,
      resetPasswordStart,
      showResetPasswordFailure,
      showResetPasswordSuccess,
      submittingReset,
    } = this.props
    if (isCurrentUserLoading && !isAuthenticated) {
      return <SimpleSpinner />
    } else {
      return isAuthenticated ? (
        <Redirect to={homeRoute.path} />
      ) : (
        <Article>
          <Card>
            <div>
              <ForgotPasswordFormContainer
                onSubmit={resetPasswordStart}
                showFailure={showResetPasswordFailure}
                showSuccess={showResetPasswordSuccess}
                submitting={submittingReset}
              />
              <InputError>{resetPasswordError}</InputError>
            </div>
          </Card>
        </Article>
      )
    }
  }
}

const mapStateToProps = state => {
  const {
    resetPasswordError,
    showResetPasswordFailure,
    showResetPasswordSuccess,
    submittingReset,
  } = state.forgotPassword
  const { isAuthenticated, isCurrentUserLoading } = state.user
  return {
    isAuthenticated,
    isCurrentUserLoading,
    resetPasswordError,
    showResetPasswordFailure,
    showResetPasswordSuccess,
    submittingReset,
  }
}

const mapDispatchToProps = {
  resetPasswordStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageForgotPassword)
