import React from 'react'
import { CircularProgress, MenuItem, Select, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { getMemberRole, MEMBER_ROLES } from './adminUtils'

const useStyles = makeStyles({
  roleSelector: {
    fontSize: '0.875rem',
  },
  circularProgress: {
    marginLeft: '4px',
  },
})

const SelectMemberRole = ({ currentUser, member, submitting, onChange }) => {
  const classes = useStyles()
  const memberRole = getMemberRole(member.isAdmin)

  if (currentUser.userId === member.userId) {
    return <Typography variant="body2">{memberRole}</Typography>
  }

  return (
    <>
      <Select
        value={memberRole}
        onChange={onChange}
        disableUnderline
        className={classes.roleSelector}
      >
        <MenuItem value={MEMBER_ROLES.ADMIN}>{MEMBER_ROLES.ADMIN}</MenuItem>
        <MenuItem value={MEMBER_ROLES.MEMBER}>{MEMBER_ROLES.MEMBER}</MenuItem>
      </Select>
      {submitting && <CircularProgress size={12} className={classes.circularProgress} />}
    </>
  )
}

export default SelectMemberRole
