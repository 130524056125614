import React from 'react'
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import { enqueueSnackbar } from '../../../redux/modules/snackbar'
import ButtonCopy from '../../common/ButtonCopy'
import ButtonDialogClose from '../common/ButtonDialogClose'

export const TITLE = 'Invite using a Share Link'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))

const InviteByLink = ({ onClose, link }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClickCopy = () => {
    dispatch(
      enqueueSnackbar({
        message: 'Link copied. Now, go share via email, text, message, Slack, Teams, and more.',
      }),
    )
    onClose()
  }

  return (
    <Paper className={classes.root}>
      <DialogTitle>
        {TITLE} <ButtonDialogClose onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Box marginBottom={3}>
          <Typography>
            Easily copy and send your invite link via email, text message, Slack, Teams, and more.
          </Typography>
        </Box>
        <TextField variant="outlined" value={link} size="small" fullWidth />
      </DialogContent>
      <DialogActions>
        <ButtonCopy
          clipboardText={`Join my team on Foodsby to save on lunch delivery at the office. Just click this link to get set up: ${link}`}
          text="Copy share link"
          color="primary"
          variant="contained"
          width="160px"
          onSuccess={handleClickCopy}
        />
      </DialogActions>
    </Paper>
  )
}

export default InviteByLink
