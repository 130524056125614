import React, { useEffect } from 'react'

import { Form, Formik } from 'formik'
import { Box, DialogActions, TextField } from '@material-ui/core'

import ButtonSubmit from '../../common/ButtonSubmit'
import DialogContent from './DialogContent'
import { PROGRAM_DIALOG_HEIGHT_SMALL } from './programUtils'

const StepProgramDiscountCode = ({
  values,
  setFieldValue,
  handleGetTotal,
  handleClose,
  validationSchema,
  isValidatingDiscount,
  errorValidatingDiscount,
  updateDiscountCode,
  discountCode,
}) => {
  const handleSubmit = () => {
    if (discountCode) {
      setFieldValue('discountCode', discountCode)
      handleGetTotal({
        discountCode: discountCode,
      })
      handleClose()
    }
  }

  const validateDiscountCodeFromEvent = values => {
    updateDiscountCode(values.discountCode)
  }

  useEffect(() => {
    if (discountCode) {
      handleSubmit()
    }
  }, [discountCode])

  return (
    <Formik
      initialValues={{
        discountCode: values.discountCode,
      }}
      onSubmit={validateDiscountCodeFromEvent}
      validationSchema={validationSchema}
    >
      {props => {
        const { values: subValues, setFieldValue, errors, touched } = props
        return (
          <Form>
            <DialogContent height={PROGRAM_DIALOG_HEIGHT_SMALL}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <TextField
                  fullWidth
                  label="Offer Code"
                  variant="outlined"
                  size="medium"
                  name="discountCode"
                  onChange={e => setFieldValue('discountCode', e.target.value)}
                  value={subValues.discountCode}
                  helperText={
                    touched.discountCode && errors.discountCode
                      ? errors.discountCode
                      : errorValidatingDiscount
                      ? 'Offer code is not valid'
                      : ''
                  }
                  error={Boolean(errorValidatingDiscount) || Boolean(errors.discountCode)}
                  disabled={isValidatingDiscount}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <ButtonSubmit color="primary" variant="contained" disabled={isValidatingDiscount}>
                Add
              </ButtonSubmit>
            </DialogActions>
          </Form>
        )
      }}
    </Formik>
  )
}

export default StepProgramDiscountCode
