import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router'
import { Box, Button, Divider, Grid, List, Typography, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { compareAsc, isAfter, parse } from 'date-fns'
import { isEmpty, isNil } from 'lodash'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { format } from 'date-fns'
import CardTeam, { CardTeamTitle } from '../common/CardTeam'
import { formatProgramOverview } from '../admin/adminUtils'
import DialogProgramDetails from './DialogProgramDetails'
import ListItemPerk from './ListItemPerk'
import ChipPerkStatus from './ChipPerkStatus'
import CardAvailableOffers from './CardAvailableOffers'

const useStyles = makeStyles({
  emptyState: {
    fontWeight: 'normal',
    fontStyle: 'italic',
  },
  hiddenTabs: {
    visibility: 'hidden',
  },
  visibleTabs: {
    visibility: 'visible',
  },
  activeInactiveMenuButton: {
    textTransform: 'none',
  },
})

export default function CardPrograms({
  programs,
  defaultPaymentMethod,
  loading,
  selectedProgram,
  setSelectedProgram,
  resetPerkDetailsState,
  availableOffers,
  setOfferRedeeming,
}) {
  const classes = useStyles()
  const history = useHistory()

  const today = new Date()

  const [menuAnchor, setMenuAnchor] = useState(null)
  // programs that have no end date or whose end date has not passed
  const activePrograms =
    programs?.filter(program => program.endDate === null || isAfter(program.endDate, today)) ?? []

  // programs whose end date has passed
  const inactivePrograms =
    programs?.filter(
      program => program.endDate !== null && compareAsc(program.endDate, today) <= 0,
    ) ?? []

  //set this state AFTER setting active and inactive programs so that initial state can be set cleanly
  const [showActivePerks, setShowActivePerks] = useState(
    activePrograms.length > 0 || inactivePrograms.length === 0,
  )

  const navigateToPageRecurringPerk = offer => {
    setOfferRedeeming(offer)
    history.push('/team/perks/recurring-perk')
  }

  const handleTabMenuButtonClick = event => {
    setMenuAnchor(event.currentTarget)
  }

  const handleCloseProgramDetails = () => {
    resetPerkDetailsState()
  }

  const closeMenu = () => {
    setMenuAnchor(null)
  }

  const onChange = showActive => {
    setShowActivePerks(showActive)
    closeMenu()
  }

  if (loading) {
    return <LoadingState />
  }

  return (
    <>
      <CardTeam>
        <Button
          className={classes.activeInactiveMenuButton}
          type="button"
          aria-controls="tabMenu"
          aria-haspopup="true"
          onClick={handleTabMenuButtonClick}
        >
          <CardTeamTitle>{showActivePerks ? 'Active' : 'Inactive'} Recurring Perks</CardTeamTitle>
          {menuAnchor ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Menu
          id="tabMenu"
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={closeMenu}
        >
          <MenuItem onClick={() => onChange(true)}>Active</MenuItem>
          <MenuItem onClick={() => onChange(false)}>Inactive</MenuItem>
        </Menu>
        <Box paddingTop={2}>
          <Box paddingLeft={2}>
            {availableOffers?.map((o, i) => (
              <CardAvailableOffers
                key={i}
                availableOffer={o}
                onOfferRedeemClicked={navigateToPageRecurringPerk}
              />
            ))}
          </Box>
          {showActivePerks ? (
            <ActivePrograms
              classes={classes}
              today={today}
              onClick={setSelectedProgram}
              hasOffers={!isEmpty(availableOffers)}
            >
              {activePrograms}
            </ActivePrograms>
          ) : (
            <InactivePrograms
              classes={classes}
              onClick={setSelectedProgram}
              hasOffers={!isEmpty(availableOffers)}
            >
              {inactivePrograms}
            </InactivePrograms>
          )}
        </Box>
      </CardTeam>
      {!isNil(selectedProgram) && (
        <DialogProgramDetails
          program={selectedProgram}
          defaultPaymentMethod={defaultPaymentMethod}
          onClose={handleCloseProgramDetails}
        />
      )}
    </>
  )
}

const LoadingState = () => {
  return (
    <CardTeam>
      <Box padding={3}>
        <Grid container>
          <Grid item xs={10}>
            <CardTeamTitle>Employee Perks</CardTeamTitle>
          </Grid>
        </Grid>
        <Skeleton height={100} />
      </Box>
    </CardTeam>
  )
}

const EmptyState = ({ active }) => {
  const classes = useStyles()
  return (
    <Box padding={3}>
      <Typography variant="h6" data-testid="empty-info-text" className={classes.emptyState}>
        No {active ? 'Current' : 'Inactive'} Recurring Perks
      </Typography>
      {active && (
        <Typography variant="body1">Add perks to make lunch for your team even better</Typography>
      )}
    </Box>
  )
}

const ActivePrograms = ({ children, classes, today, onClick, hasOffers }) => {
  return (
    <>
      {children.length > 0 || hasOffers ? (
        <List data-testid="tab-active">
          {children.map((program, index) => (
            <Fragment key={program.programId}>
              <ListItemPerk
                primaryText={program.name}
                secondaryText={formatProgramOverview(program)}
                bottomComponentMarginTop={2}
                BottomComponent={
                  program.activeDiscount && (
                    <Typography variant="body2" color="error" style={{ fontStyle: 'italic' }}>
                      Foodsby contribution expires{' '}
                      {format(program.activeDiscount.endDate, 'M/D/YYYY')}
                    </Typography>
                  )
                }
                onClick={() => onClick(program)}
                ActionComponent={
                  parse(program.startDate) <= today ? (
                    <ChipPerkStatus status="active" />
                  ) : (
                    <ChipPerkStatus status="upcoming" />
                  )
                }
              />
              {index < children.length - 1 && <Divider />}
            </Fragment>
          ))}
        </List>
      ) : (
        <EmptyState active className={classes.emptyState} />
      )}
    </>
  )
}

const InactivePrograms = ({ children, classes, onClick, hasOffers }) => {
  return (
    <>
      {children.length > 0 || hasOffers ? (
        <List data-testid="tab-inactive">
          {children.map((program, index) => (
            <Fragment key={program.programId}>
              <ListItemPerk
                primaryText={program.name}
                secondaryText={formatProgramOverview(program)}
                onClick={() => onClick(program)}
                ActionComponent={<ChipPerkStatus status="inactive" />}
              />
              {index < children.length - 1 && (
                <Box paddingTop={2} paddingBottom={2}>
                  <Divider />
                </Box>
              )}
            </Fragment>
          ))}
        </List>
      ) : (
        <EmptyState active={false} className={classes.emptyState} />
      )}
    </>
  )
}
