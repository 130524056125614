import * as braze from '@braze/web-sdk'

export const BRAZE_CUSTOM_EVENTS = Object.freeze({
  ORDER_COMPLETED: 'order-completed',
  REFERRAL_COPIED_LINK: 'referral-copied-link',
  ADDED_TO_CART: 'added-to-cart',
  GO_ELIGIBLE: 'go-eligible',
})

export const BRAZE_MESSAGE_IDS = Object.freeze({
  PUSH_PRIMER: 'push-primer',
})

export const publishBrazeEvent = (event, properties) => {
  braze.logCustomEvent(event, properties)
}

export const interceptInAppMessageToAskToShowWebPushNotifications = inAppMessage => {
  let shouldDisplay = true

  if (inAppMessage instanceof braze.InAppMessage) {
    let messageId = inAppMessage.extras['msg-id']

    if (messageId === BRAZE_MESSAGE_IDS.PUSH_PRIMER) {
      if (braze.isPushPermissionGranted()) {
        braze.requestPushPermission()
        shouldDisplay = false
      }
      if (!braze.isPushSupported() || braze.isPushBlocked()) {
        shouldDisplay = false
      }
      if (inAppMessage.buttons[0] != null) {
        inAppMessage.buttons[0].subscribeToClickedEvent(() => {
          braze.requestPushPermission()
        })
      }
    }
  }

  if (shouldDisplay) {
    braze.showInAppMessage(inAppMessage)
  }
}
