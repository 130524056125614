import { Spinner } from '@foodsby/nutrient'
import classNames from 'classnames'
import React from 'react'

export const SimpleSpinner = ({ size = 'md', spinnerClass }) => (
  <div className="row center-xs">
    <div className="col-xs">
      <Spinner className={classNames('primary', spinnerClass)} size={size} />
    </div>
  </div>
)

export default SimpleSpinner
