import React from 'react'
import { connect } from 'react-redux'
import { Box, Card, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { deleteCateringOrderCartStart } from '../redux/modules/cart'
import CheckoutSidebar from '../components/checkout/CheckoutSidebar'
import LayoutInnerPage, { HeaderInnerPage } from '../layouts/LayoutInnerPage'
import { formatUrl } from '../util/formatUtils'
import { pickCateringDeliveryDateAndTimeRoute } from '../routes/routes'
import { isOrderValid } from '../util/cateringUtils'
import CateringOrderValidationModal from '../components/place-order/CateringOrderValidationModal'
import {
  selectFormattedCateringDeliveryDate,
  selectFormattedCateringDeliveryTime,
} from '../redux/selectors/deliverySelectors'
import CateringCheckoutForm from '../components/checkout/CateringCheckoutForm'

const useStyles = makeStyles({
  formTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '38px',
  },
})

const PageCateringCheckout = ({
  checkoutCart,
  cateringDeliveryDate,
  cateringDeliveryTime,
  isLoadingCateringDeliveryDateTime,
  cateringStore,
  deliveryDropoff,
  deleteCateringOrderCartStart,
}) => {
  const classes = useStyles()
  const storeId = deliveryDropoff?.storeId
  const { dropoffId, locationId } = useParams()

  if (
    !isLoadingCateringDeliveryDateTime &&
    checkoutCart &&
    cateringStore &&
    !isOrderValid(checkoutCart, cateringStore, cateringDeliveryTime, cateringDeliveryDate)
  ) {
    return (
      <CateringOrderValidationModal
        locationId={locationId}
        storeId={storeId}
        handleRestartOrder={() => deleteCateringOrderCartStart(storeId, locationId)}
      />
    )
  }

  const isCatering = deliveryDropoff?.type === 'Catering'

  return (
    <LayoutInnerPage
      HeaderComponent={
        <HeaderInnerPage
          showBackButton
          backButtonRoute={formatUrl(pickCateringDeliveryDateAndTimeRoute.path, {
            locationId,
            storeId,
          })}
        >
          Back
        </HeaderInnerPage>
      }
      MainComponent={
        <Card>
          <Box marginBottom={4}>
            <Typography className={classes.formTitle}>Review & Checkout</Typography>
          </Box>
          <CateringCheckoutForm
            dropoffId={dropoffId}
            cateringDeliveryDate={cateringDeliveryDate}
            cateringDeliveryTime={cateringDeliveryTime}
          />
        </Card>
      }
      SidebarComponent={
        <CheckoutSidebar
          deliveryType={deliveryDropoff?.type}
          showRestaurantLogo={isCatering}
          enableQuantity={isCatering}
        />
      }
    />
  )
}

const mapStateToProps = state => {
  const { deliveryDropoff, isLoadingCateringDeliveryDateTime } = state.delivery
  const { checkoutCart } = state.cart
  const cateringDeliveryDate = selectFormattedCateringDeliveryDate(state)
  const cateringDeliveryTime = selectFormattedCateringDeliveryTime(state)
  const { cateringStore } = state.catering

  return {
    checkoutCart,
    cateringStore,
    cateringDeliveryDate,
    cateringDeliveryTime,
    isLoadingCateringDeliveryDateTime,
    deliveryDropoff,
  }
}

const mapDispatchToProps = {
  deleteCateringOrderCartStart,
}
export default connect(mapStateToProps, mapDispatchToProps)(PageCateringCheckout)
