import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { LocationLogo } from './Location'

const useStyles = makeStyles(theme => ({
  restaurantName: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  logo: {
    width: '75px',
    height: '75px',
  },
}))

const RestaurantDetailsSidebar = ({ logoLink, name }) => {
  const classes = useStyles()
  const logoOptions = '?height=100&width=100&fit=contain&background=white'

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box className={classes.logo}>
          <LocationLogo logoAlt={name} logoLink={logoLink} logoOptions={logoOptions} />
        </Box>
        <Box paddingLeft={1}>
          <Typography variant="p" className={classes.restaurantName}>
            {name}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default RestaurantDetailsSidebar
