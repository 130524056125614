import React from 'react'
import { Box, Divider, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { themeConWeb } from '../../util/modernThemeConweb'

import { locationTypeDisplayText } from './utils'
import CardLocation from './CardLocation'

const useStyles = makeStyles({
  itemName: { marginTop: '10px' },
  itemLabel: { color: themeConWeb.color.greyShade4, fontSize: '14px' },
  listItemBox: {
    marginLeft: '20px',
  },
  divider: { marginTop: '20px' },
})

const ListItem = ({ classes, title, label, showDivider }) => {
  return (
    <>
      <Box className={classes.listItemBox}>
        <Typography className={classes.itemName}>{title}</Typography>
        <Typography className={classes.itemLabel} variant="body2">
          {label}
        </Typography>
      </Box>
      {showDivider && <Divider className={classes.divider} />}
    </>
  )
}

const CardLocationInformation = ({ name, address, locationType, populationCount }) => {
  const classes = useStyles()

  return (
    <CardLocation>
      <Typography variant="h6">Location Information</Typography>
      <Box marginTop={3}>
        <Box>
          <ListItem
            classes={classes}
            title={name}
            label={'Location/Building Name'}
            iconType={true}
            showDivider={true}
          />
          <ListItem
            classes={classes}
            title={address}
            label={'Address'}
            iconType={true}
            showDivider={true}
          />
          <ListItem
            classes={classes}
            title={locationTypeDisplayText(locationType)}
            label={'Building Type'}
            iconType={true}
            showDivider={true}
          />
          <ListItem
            classes={classes}
            title={populationCount}
            label={'Estimated # Occupancy'}
            iconType={false}
            showDivider={false}
          />
        </Box>
      </Box>
    </CardLocation>
  )
}

export default CardLocationInformation
