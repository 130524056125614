import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles({
  box: {
    backgroundColor: 'white',
    width: '240px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
})

const HomeAd = ({ title, image, actions }) => {
  const classes = useStyles()
  return (
    <Box paddingX={3} className={classes.box}>
      <Box width="100%" textAlign="center" marginBottom={3}>
        {image}
      </Box>

      <Typography variant="h6">{title}</Typography>

      <Typography>
        Upgrade to a Preferred location and enjoy more restaurant options with{' '}
        <b>50% reduced fees.</b>
      </Typography>

      <Box marginTop={3} marginBottom={3}>
        {actions}
      </Box>
    </Box>
  )
}

export default HomeAd
