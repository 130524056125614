import React, { Component } from 'react'
import { connect } from 'react-redux'

import Footer from '../components/common/Footer'
import Header from '../components/common/header/Header'
import ReferralSuccessNotification from '../components/community/ReferralSuccessNotification'
import SlackBotSuccessModal from '../components/slack/SlackBotSuccessModal'
import GenericSuccessModal from '../components/success-modal/GenericSuccessModal'
import { loadUserSavedLocationsStart } from '../redux/modules/location'
import { notificationAck } from '../redux/modules/notification'
import { selectCurrentLocationWithAddress } from '../redux/modules/selectors'
import { logoutStart, updateAndSaveUserLocationStart } from '../redux/modules/user'
import EmailVerificationBanner from '../components/email-verification/EmailVerificationBanner'
import ErrorBoundary from '../components/common/ErrorBoundary'

export class LayoutPrivate extends Component {
  componentDidUpdate(prevProps) {
    if (
      (!prevProps.currentUser && this.props.currentUser) ||
      (prevProps.location !== this.props.location && this.props.currentUser)
    ) {
      this.props.loadUserSavedLocationsStart()
    }
  }

  render() {
    const {
      children,
      error,
      flex,
      hideHeader,
      isAuthenticated,
      isCurrentUserLoading,
      message,
      notificationAck,
      profile,
      publicMenuLinkMode,
      showHeaderLocation,
      showHeaderDeliveryDate,
    } = this.props

    /*
      Show the update user location dialog
      If the location is not user's profile location but is in user's saved locations list
          Or if there is an error in the modal
    */

    return (
      <ErrorBoundary>
        <div
          style={
            flex
              ? {
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '100vh',
                }
              : {}
          }
        >
          {!isCurrentUserLoading && !hideHeader && (
            <Header
              publicMenuLinkMode={publicMenuLinkMode}
              showHeaderLocation={showHeaderLocation}
              showHeaderDeliveryDate={showHeaderDeliveryDate}
            />
          )}
          <SlackBotSuccessModal />
          <ReferralSuccessNotification />
          {profile?.verifiedEmail === false && <EmailVerificationBanner profile={profile} />}
          <GenericSuccessModal
            isError={error}
            message={message}
            notificationAck={notificationAck}
          />
          {children}
          <Footer isAuthenticated={isAuthenticated} showLogo="true" />
        </div>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => {
  const location = selectCurrentLocationWithAddress(state)
  const { error, message } = state.notification
  const { profile } = state.profile
  const { currentUser, isAuthenticated, isCurrentUserLoading, updateUserLocationError } = state.user

  return {
    currentUser,
    error,
    isAuthenticated,
    isCurrentUserLoading,
    location,
    message,
    profile,
    updateUserLocationError,
  }
}

const mapDispatchToProps = {
  loadUserSavedLocationsStart,
  logoutStart,
  notificationAck,
  updateAndSaveUserLocationStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPrivate)
