import currency from 'currency.js'
import { parse } from 'date-fns'
import queryString from 'query-string'
import { formValueSelector } from 'redux-form'
import { createSelector } from 'reselect'
import us from 'us'

// ------------------------------------
// Common
// ------------------------------------
export const getQueryParams = (props = {}) =>
  queryString.parse(props.location ? props.location.search : window.location.search)

/**
 * @param {*} data entire dataset
 * @param {*} pageRequest
 * {
 *    page: page number
 *    size: page size
 * }
 * @returns
 * {
 *    // calculated by selector
 *    content: current page's content
 *    totalElements: total number of elements
 *    totalPages: total number of pages
 *
 *    // provided by reducer
 *    page: page number
 *    size: page size
 * }
 */
const getPageContent = (data, pageRequest) => {
  const pageNumber = pageRequest.page ? pageRequest.page : 1
  const offset = (pageNumber - 1) * pageRequest.size

  return {
    ...pageRequest,
    content: data.slice(offset, offset + pageRequest.size),
    totalElements: data.length,
    totalPages: Math.ceil(data.length / pageRequest.size),
  }
}

export const selectProfile = createSelector([state => state.profile.profile], profile => profile)

export const selectCurrentUser = createSelector(
  [state => state.user.currentUser],
  currentUser => currentUser,
)

export const selectIsAuthenticated = createSelector(
  [state => state.user.isAuthenticated],
  isAuthenticated => isAuthenticated,
)
export const selectRef = createSelector([state => state.user.ref], ref => ref)

export const selectConfirmUserCredentials = createSelector(
  [state => state.user.confirmCredentials.formData],
  confirmCredentials => confirmCredentials,
)

export const selectCurrentOrderId = createSelector(
  [state => state.orderDetail.order.orderId],
  orderId => orderId,
)

// ------------------------------------
// Location
// ------------------------------------

export const getLocationActivationDate = createSelector(
  [state => state.location.location],
  location => {
    if (location) {
      return location.activationDate
    } else {
      return undefined
    }
  },
)

export const selectCurrentLocationWithAddress = createSelector(
  [state => state.location.location],
  location => {
    if (location) {
      location.address = {
        city: location.city,
        state: location.state,
        street: location.street,
        zip: location.zip,
      }
      if (location.activationDate) {
        location.isActive = parse(location.activationDate).getTime() < Date.now()
      }
      if (us.lookup(location.state || '')) {
        location.StateAbbr = us.lookup(location.state || '').abbr
      }
    }
    return location
  },
)

export const selectUserLocationId = createSelector(
  [state => state.user.currentUser, state => state.location.location],
  (currentUser, location) => {
    let locationId = 0
    if (currentUser) {
      if (currentUser.deliveryLocationId) {
        locationId = currentUser.deliveryLocationId
      } else {
        if (currentUser.originalLocationId) {
          locationId = currentUser.originalLocationId
        }
      }
    } else if (location) {
      locationId = location.deliveryLocationId
    }
    return locationId
  },
)

export const selectLocationDeliveryId = createSelector(
  [state => state.location.location],
  location => {
    let locationId = 0
    if (location) {
      locationId = location.deliveryLocationId
    }
    return Number(locationId)
  },
)

export const selectLocationError = createSelector(
  [state => state.location.locationError],
  locationError => locationError,
)

export const selectCurrentBuildingNameAndAddress = createSelector(
  [state => state.location.location],
  location => {
    if (location) {
      return {
        buildingAddress: location.street,
        buildingName: location.deliveryLocationName,
      }
    }
  },
)

export const getLocationIdParam = createSelector([getQueryParams], params => {
  const { id } = params
  return id || 0
})

export const getAddressQueryStringParams = createSelector([getQueryParams], params => {
  const { city, lat, lng, state, street, zip } = params
  let StateAbbr
  if (us.lookup(state || '')) {
    StateAbbr = us.lookup(state || '').abbr
  }
  return { city, lat, lng, state: StateAbbr || state, street, zip }
})

export const selectCurrentSavedLocation = createSelector([state => state], state => {
  const locationId = selectLocationDeliveryId(state)
  return state.location.savedLocations?.find?.(sL => sL.deliveryLocationId === locationId)
})

// ------------------------------------
// Credits / Referrals
// ------------------------------------

const getAllCredits = state => state.creditHistory.credits
const getCreditPageRequest = state => state.creditHistory.creditPage
const reduceCreditTotal = credits =>
  credits.reduce((accumulator, value) => accumulator + value.creditAmount, 0)

export const getCreditPage = createSelector([getAllCredits, getCreditPageRequest], getPageContent)
export const getTotalCreditAmount = createSelector([getAllCredits], reduceCreditTotal)

const getAllReferrals = state => state.creditHistory.referrals
const getReferralPageRequest = state => state.creditHistory.referralPage
const reduceReferralTotal = refs =>
  refs.reduce((accumulator, value) => accumulator + (value.earned ? value.earnedAmount : 0), 0)

export const getReferralPage = createSelector(
  [getAllReferrals, getReferralPageRequest],
  getPageContent,
)
export const getTotalReferralAmount = createSelector([getAllReferrals], reduceReferralTotal)

// ------------------------------------
// Dropoffs
// ------------------------------------
const getLocations = state => state.locationSearch.locations

export const getAllDropoffs = createSelector([getLocations], locations => locations.foundLocations)

export const selectCurrentDeliveryDropoff = createSelector(
  [state => state.delivery.deliveryDropoff],
  deliveryDropoff => deliveryDropoff,
)

export const selectCurrentMenu = createSelector([state => state.menu.menu], menu => menu)

// ------------------------------------
// Contact Us
// ------------------------------------
const contactUsSelector = formValueSelector('contactUs')

const getReasons = state => state.ticket.reasons
const getReasonType = state => contactUsSelector(state, 'reasonType')

export const selectReasons = createSelector([getReasons], reasons => reasons)

export const findSelectedReason = createSelector(
  [getReasons, getReasonType],
  (reasons, reasonTypeId) => reasons.find(reason => reason.id === reasonTypeId),
)

// ------------------------------------
// Community
// ------------------------------------

export const getFriendRequests = createSelector(
  [state => state.friends.incomingFriendRequests],
  incomingFriendRequests => incomingFriendRequests,
)

export const getNewFavorites = createSelector(
  [state => state.favorite.newFavorites],
  newFavorites => newFavorites,
)

const getPopularItemInfo = state => state.favorite.popularMenuItems
const getNewPopularItemInfo = state => state.favorite.newPopularMenuItems
const getMenu = state => state.menu.menu

export const getMenuInfoForPopularItems = createSelector(
  [getPopularItemInfo, getMenu],
  (getPopularItemInfo, getMenu) => {
    let popularItems = []
    if (getPopularItemInfo && getMenu && getMenu.subMenus.length > 0) {
      getMenu.subMenus.forEach(menu => {
        popularItems = popularItems.concat(
          menu.menuItems.filter(menuItem =>
            getPopularItemInfo.some(info => info.menuItemId === menuItem.id),
          ),
        )
      })
    }
    return popularItems
  },
)

export const getMenuInfoForNewPopularItems = createSelector(
  [getNewPopularItemInfo, getMenu],
  (getNewPopularItemInfo, getMenu) => {
    let popularItems = []
    if (getNewPopularItemInfo && getMenu && getMenu.subMenus.length > 0) {
      getMenu.subMenus.forEach(menu => {
        popularItems = popularItems.concat(
          menu.menuItems.filter(menuItem =>
            getNewPopularItemInfo.some(info => info.menuItemId === menuItem.id),
          ),
        )
      })
    }
    return popularItems
  },
)
// ------------------------------------
// Personalize
// ------------------------------------

export const getSelectedPastOrder = createSelector(
  [state => state.pastorder.selectedOrderItemId],
  selectedOrderItemId => selectedOrderItemId,
)

export const getDetailsForEdit = createSelector(
  [state => state.cart.editDetails],
  editDetails => editDetails,
)

export const selectMenuItemForEdit = createSelector(
  [state => state.cart.menuItemForEdit],
  menuItemForEdit => menuItemForEdit,
)

export const selectCouponUsage = createSelector(
  [state => state.cart.couponUsage],
  couponUsage => couponUsage,
)

export const selectNormalizedMenuItem = createSelector(
  [state => state.personalize.normalizedMenuItem],
  normalizedMenuItem => normalizedMenuItem,
)

export const selectDefaultAnswers = createSelector(
  [state => state.personalize.defaultAnswers],
  defaultAnswers => defaultAnswers,
)

const questionDictionary = state =>
  state.personalize.normalizedMenuItem
    ? state.personalize.normalizedMenuItem.entities.questions
    : []
const answerDictionary = state =>
  state.personalize.normalizedMenuItem ? state.personalize.normalizedMenuItem.entities.answers : []
const displayedQuestions = state => state.personalize.displayedQuestions
const menuItems = state =>
  state.personalize.normalizedMenuItem
    ? state.personalize.normalizedMenuItem.entities.menuItems
    : []

const treeWalkForQuestions = (result, questionIds, questions, answers, displayedQuestionIds) => {
  questionIds.forEach(questionId => {
    if (displayedQuestionIds.includes(questionId) && !result.includes(questions[questionId])) {
      result.push(questions[questionId])
      questions[questionId].answers.forEach(answerId => {
        treeWalkForQuestions(
          result,
          answers[answerId].questions,
          questions,
          answers,
          displayedQuestionIds,
        )
      })
    }
  })
  return result
}

export const selectOrderCart = createSelector(
  [state => state.cart.orderCart],
  orderCart => orderCart,
)

export const selectCardPaymentDetail = createSelector(
  [state => state.cart.cardPaymentDetail],
  cardPaymentDetail => cardPaymentDetail,
)

export const getDisplayedQuestions = createSelector(
  [questionDictionary, answerDictionary, displayedQuestions, menuItems],
  (questionDictionary, answerDictionary, displayedQuestions, menuItems) => {
    let questions = []
    if (Object.keys(menuItems).length > 0) {
      let menuItemId = Object.keys(menuItems)[0]
      treeWalkForQuestions(
        questions,
        menuItems[menuItemId].questions,
        questionDictionary,
        answerDictionary,
        displayedQuestions,
      )
    }
    return questions
  },
)
const personalizeForm = state => state.form.personalize
export const personalizeFormErrors = createSelector([state => state.form], form => {
  if (form && form.personalize && form.personalize.syncErrors) {
    return form.personalize.syncErrors
  } else {
    return undefined
  }
})

export const personalizeFormTouched = createSelector([state => state.form], form => {
  if (form && form.personalize && form.personalize.anyTouched) {
    return form.personalize.anyTouched
  } else {
    return undefined
  }
})

export const orderSubtotal = createSelector(
  [answerDictionary, menuItems, personalizeForm],
  (answerDictionary, menuItems, personalizeForm) => {
    let menuItemId = Object.keys(menuItems)[0]
    let subTotal = currency(0.0)
    let quantity = 1
    if (Object.keys(menuItems).length > 0) {
      subTotal = currency(subTotal).add(menuItems[menuItemId].price)
    }
    if (personalizeForm && personalizeForm.values) {
      subTotal = Object.keys(personalizeForm.values).reduce((subTotal, question) => {
        if (question !== 'specialInstructions' && question !== 'quantity') {
          let answer = personalizeForm.values[question]
          let answerArray = answer instanceof Array ? answer : [answer]
          answerArray.forEach(answer => {
            if (answerDictionary && answerDictionary[answer] && answerDictionary[answer].price) {
              subTotal = currency(subTotal).add(answerDictionary[answer].price)
            }
          })
        }
        return subTotal
      }, subTotal)
      quantity = personalizeForm.values.quantity ? personalizeForm.values.quantity : 1
    }
    return currency(subTotal).multiply(quantity)
  },
)

export const selectOrderTotal = createSelector(
  [state => state.cart.checkoutCart],
  checkoutCart => checkoutCart,
)

// ------------------------------------
// Checkout
// ------------------------------------

export const selectSelectedCard = createSelector(
  [state => state.checkout.selectedCard],
  card => card,
)

export const selectSaveCard = createSelector(
  [state => state.checkout.saveCard],
  saveCard => saveCard,
)

export const selectAllPaymentMethods = createSelector(
  [state => state.paymentMethods.paymentMethods],
  paymentMethods => paymentMethods,
)
export const selectPaymentMethodsIsLoading = createSelector(
  [state => state.paymentMethods.isPaymentMethodsLoading],
  loading => loading,
)
export const selectSuggestedCredits = createSelector(
  [
    state => {
      const availableCredits =
        state.cart.creditUsage && state.cart.creditUsage.creditBalance
          ? state.cart.creditUsage.creditBalance
          : 0
      const orderTotal =
        state.cart.checkoutCart && state.cart.checkoutCart.preTaxSubTotal
          ? state.cart.checkoutCart.preTaxSubTotal
          : 0
      return Math.min(availableCredits, orderTotal)
    },
  ],
  suggestedCredits => suggestedCredits,
)
export const selectSuggestedGifts = createSelector(
  [
    state => {
      const { storedCurrencyUsage, checkoutCart } = state.cart
      const availableGifts = storedCurrencyUsage?.storedCurrencyBalance / 100
      const { orderTotal } = checkoutCart
      return Math.min(availableGifts, orderTotal)
    },
  ],
  suggestedGifts => suggestedGifts,
)

// ------------------------------------
// Join
// ------------------------------------
export const selectUserJoinData = createSelector(
  [
    state => {
      const { locationId, username } = state.join
      return { locationId, username }
    },
  ],
  userJoinData => userJoinData,
)

export const selectEntryPoint = createSelector(
  [
    state => {
      const entryPoint = state.user.entryPoint
      return entryPoint
    },
  ],
  entryPoint => entryPoint,
)

// ------------------------------------
// Order Detail
// ------------------------------------
export const getCurrentOrderDetails = createSelector(
  [state => state.orderDetail.order],
  order => order,
)

export const getCurrentOrderDetailsCcLastFour = createSelector(
  [state => state.orderDetail.order?.ccLastFour],
  ccLastFour => ccLastFour,
)

// ------------------------------------
// Ticket
// ------------------------------------
export const getCancelTicketDetails = createSelector(
  [state => state.ticket.cancelTicket],
  cancelTicket => cancelTicket,
)

// ------------------------------------
// Router
// ------------------------------------
export const getCurrentRoutePath = createSelector(
  [state => state.router?.location?.pathname],
  pathname => pathname,
)
