import { login } from '@foodsby/webapp-jwt'
import { api, FOODSBY_BASE_URL } from '../api/api'

// ------------------------------------
// APIs
// ------------------------------------
export const oneShotRegister = formData => api.post(`/api/v1/uaa/users/createUser`, formData)

export const loginUser = ({ password, userName }) =>
  login(
    {
      clientId: process.env.REACT_APP_CLIENT_ID,
      clientSecret: process.env.REACT_APP_CLIENT_SECRET,
      cookieName: process.env.REACT_APP_COOKIE_NAME,
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      password,
      scope: 'consumer',
      username: userName,
    },
    {
      baseURL: FOODSBY_BASE_URL,
    },
  )

export const logoutAdmin = () => api.get(`/admin/logout`)

export const updateUserLocation = (id, locationId) =>
  api.put(`/api/v1/uaa/users/${id}/profiles`, { locationId })
