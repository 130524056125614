import React from 'react'
import { LocationLogo } from '../common/Location'

const RestaurantLogo = ({ restaurantName, logoLink }) => {
  return (
    <div className="-restaurantLogoContainer">
      <LocationLogo
        className="-restaurantLogo"
        logoAlt={`${restaurantName} logo`}
        logoLink={logoLink}
      />
    </div>
  )
}

export default RestaurantLogo
