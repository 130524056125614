import { Backdrop, CircularProgress } from '@material-ui/core'
import { isBoolean, isEmpty, isString } from 'lodash'
import React, { useEffect } from 'react'
import scriptLoader from 'react-async-script-loader'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { MAPS_SCRIPT_URL } from '../components/location-search/LocationSearchBox'
import { search } from '../redux/modules/locationSearch'
import { getAddressQueryStringParams } from '../redux/modules/selectors'
import { homeRoute, registerRoute } from '../routes/routes'
import { formatAddress } from '../util/formatUtils'

// NOTE: This page no longer provides an inline component for selecting a location.
// It simply fires off the search if it sees an address param and redirects to home/register page after completion.
const PageSelectLocation = ({ isScriptLoaded }) => {
  const dispatchRedux = useDispatch()
  const history = useHistory()
  const { isAuthenticated } = useSelector(state => state.user)
  const addressQueryParams = useSelector(state => getAddressQueryStringParams(state.router))
  const searchCompleted = useSelector(state => state?.locationSearch.noResults)
  const formattedAddress = formatAddress(addressQueryParams)

  useEffect(() => {
    if (!isEmpty(formattedAddress) && isString(formattedAddress) && isScriptLoaded) {
      dispatchRedux(search(formattedAddress, false))
    }
  }, [dispatchRedux, formattedAddress, isScriptLoaded])

  useEffect(() => {
    if (isBoolean(searchCompleted) && isAuthenticated) {
      history.push(homeRoute.path)
    } else if (isBoolean(searchCompleted) && !isAuthenticated) {
      history.push(registerRoute.path)
    }
  }, [history, isAuthenticated, searchCompleted])

  return (
    <Backdrop open style={{ zIndex: '1101' }}>
      <CircularProgress color="primary" />
    </Backdrop>
  )
}

export default scriptLoader(MAPS_SCRIPT_URL)(PageSelectLocation)
