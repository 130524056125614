import React, { useCallback, useState } from 'react'
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import { object, array, string } from 'yup'
import PapaParse from 'papaparse'
import { isArray, first, last } from 'lodash'

import templateFile from '../../../assets/member_upload_template.csv'
import ButtonSubmit from '../../common/ButtonSubmit'
import ButtonDialogClose from '../common/ButtonDialogClose'
import AlertMemberInvitePrivacy from './AlertMemberInvitePrivacy'
import FileDropzone from './FileDropzone'

export const TITLE = 'Invite by uploading a CSV file'

const MESSAGE_NO_FILE = 'Please upload a file.'
const MESSAGE_INVALID_FILE_TYPE = 'Incorrect file type uploaded. The file type must be .csv.'
const MESSAGE_INVALID_FORMAT =
  'The uploaded file is incorrectly formatted. Email addresses must be in a single column.'
const MESSAGE_INVALID_EMAIL_ADDRESS = 'One or more of the uploaded email addresses are invalid.'
const MESSAGE_MULTI_FILES = 'Multiple files selected.'
const MESSAGE_PARSER_ERROR =
  'Something went wrong uploading the file. Please make sure you have a valid .csv file.'

const validationSchema = object().shape({
  memberEmails: array()
    .required(MESSAGE_NO_FILE)
    .of(string().email(MESSAGE_INVALID_EMAIL_ADDRESS)),
})

const useStyles = makeStyles(theme => ({
  csvTemplateText: {
    marginRight: theme.spacing(1),
  },
}))

const InviteByCsv = ({ onSubmit, onClose, submitting }) => {
  const classes = useStyles()
  const [file, setFile] = useState(null)
  const [uploadError, setUploadError] = useState(null)

  const handleDrop = useCallback((acceptedFiles, rejectedFiles, setFieldValue) => {
    if (rejectedFiles?.length > 1) {
      setUploadError(MESSAGE_MULTI_FILES)
      setFile(null)
      return
    } else if (rejectedFiles?.length > 0) {
      setUploadError(MESSAGE_INVALID_FILE_TYPE)
      setFile(null)
      return
    }

    const csvFile = first(acceptedFiles)
    if (csvFile) {
      PapaParse.parse(csvFile, {
        skipEmptyLines: true,
        error: () => {
          setUploadError(MESSAGE_PARSER_ERROR)
        },
        complete: ({ data }) => {
          if (first(data)?.length > 1) {
            setUploadError(MESSAGE_INVALID_FORMAT)
            return
          }

          setFieldValue(
            'memberEmails',
            data.map(result => first(result)),
          )
          setFile(csvFile)
          setUploadError(null)
        },
      })
    }
  }, [])

  const handleRemove = resetForm => {
    setFile(null)
    setUploadError(null)
    resetForm()
  }

  return (
    <Formik
      initialValues={{ memberEmails: [] }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, setFieldValue, resetForm }) => {
        const validationError = isArray(errors.memberEmails)
          ? last(errors.memberEmails)
          : errors.memberEmails
        return (
          <Form>
            <Paper>
              <Box paddingBottom={2}>
                <DialogTitle>
                  {TITLE}
                  <ButtonDialogClose onClick={onClose} />
                </DialogTitle>
                <DialogContent>
                  <Typography gutterBottom>
                    Add email addresses to a single-column CSV file.
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography display="inline" className={classes.csvTemplateText}>
                      Need a CSV template?
                    </Typography>
                    <Link color="primary" href={templateFile}>
                      Download here.
                    </Link>
                  </Box>
                  <Box marginTop={3} marginBottom={2}>
                    <FileDropzone
                      success={Boolean(file) && !errors.memberEmails}
                      fileName={file?.name}
                      error={uploadError || validationError}
                      onDrop={(acceptedFiles, rejectedFiles) =>
                        handleDrop(acceptedFiles, rejectedFiles, setFieldValue)
                      }
                      onRemove={() => handleRemove(resetForm)}
                    />
                  </Box>
                  {(uploadError || validationError) && (
                    <Typography variant="body2" color="error" align="center">
                      {uploadError || validationError}
                    </Typography>
                  )}
                </DialogContent>
                <DialogActions>
                  <ButtonSubmit submitting={submitting} variant="contained" color="primary">
                    Invite members
                  </ButtonSubmit>
                </DialogActions>
              </Box>
              <AlertMemberInvitePrivacy />
            </Paper>
          </Form>
        )
      }}
    </Formik>
  )
}

export default InviteByCsv
