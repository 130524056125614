import React from 'react'
import styled from 'styled-components'
import { cateringScheduleDescription, parseLeadTimeInHours } from '../../util/cateringUtils'

const StyledBox = styled.div`
  padding-top: 10px;
  margin-bottom: 30px;
  p {
    font-style: normal;
    font-size: 14px;
    margin-bottom: 5px;

    b {
      /* Styles for <b> tags within <p> */
    }
  }
`

const RestaurantHoursAndNotice = ({
  operationalHoursStart,
  operationalHoursEnd,
  operationalDaysOfWeek,
  deliveryLeadTime,
}) => {
  const operationalHours = operationalHoursStart
    ? cateringScheduleDescription(operationalDaysOfWeek, operationalHoursStart, operationalHoursEnd)
    : '10-2pm'

  const parseLeadTime = deliveryLeadTime ? parseLeadTimeInHours(deliveryLeadTime) : 18
  return (
    <StyledBox>
      <p>
        <b>Delivery Notice:</b> This restaurant requires <b>{parseLeadTime} hours </b> advance
        notice for all orders.
      </p>
      <p>
        <b>Hours:</b> {operationalHours}
      </p>
    </StyledBox>
  )
}

export default RestaurantHoursAndNotice
