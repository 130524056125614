import React from 'react'
import { themeConWeb } from '@app/util/modernThemeConweb'
import { Box, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useSelector } from 'react-redux'
import { Link as LinkReactScroll } from 'react-scroll'
import styled from 'styled-components'
import { space } from 'styled-system'

const NavRestaurantCategories = ({ isMenuLoading }) => {
  // get subMenus from state
  const subMenus = useSelector(state => state.menu?.menu?.subMenus)

  // categories titles and ids from the restaurant
  const categories = subMenus?.map(sub => ({ id: sub.id.toString(), name: sub.name })) ?? []

  if (isMenuLoading) {
    return <SkeletonLoading />
  }

  return (
    <Component>
      <div className="-categoryActions">
        {categories.map(({ id, name }) => (
          <LinkReactScroll key={id} smooth spy to={id}>
            <Typography className="-categoryAction" variant="body1">
              {name}
            </Typography>
          </LinkReactScroll>
        ))}
      </div>
    </Component>
  )
}

const Component = styled.nav`
  ${space}

  & .-categoryActions {
    flex-wrap: wrap;
  }

  & .-categoryAction {
    color: ${themeConWeb.color.greyShade4};
    display: inline-block;
    margin-bottom: calc(${themeConWeb.baseUnit} * 2);
    margin-right: calc(${themeConWeb.baseUnit} * 6);
    white-space: nowrap;

    &:hover {
      color: ${themeConWeb.color.brandPrimaryShade2};
    }
  }
`

const SkeletonLoading = () => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Skeleton height={30} width={150} />
      </Box>
      <Box display="flex">
        <Skeleton style={{ marginRight: `calc(${themeConWeb.baseUnit} * 6)` }} width={150} />
        <Skeleton style={{ marginRight: `calc(${themeConWeb.baseUnit} * 6)` }} width={150} />
        <Skeleton style={{ marginRight: `calc(${themeConWeb.baseUnit} * 6)` }} width={150} />
        <Skeleton style={{ marginRight: `calc(${themeConWeb.baseUnit} * 6)` }} width={150} />
        <Skeleton style={{ marginRight: `calc(${themeConWeb.baseUnit} * 6)` }} width={150} />
        <Skeleton style={{ marginRight: `calc(${themeConWeb.baseUnit} * 6)` }} width={150} />
        <Skeleton style={{ marginRight: `calc(${themeConWeb.baseUnit} * 6)` }} width={150} />
      </Box>
    </Box>
  )
}

export default NavRestaurantCategories
