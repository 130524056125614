import React from 'react'
import { Box, Typography, Link, useMediaQuery } from '@material-ui/core'
import { locationManagerRoute } from '../../routes/routes'
import { formatUrl } from '../../util/formatUtils'
import { makeStyles } from '@material-ui/core/styles'
import { DIALOG_OPTIONS } from './savedLocationsUtils'
import cn from 'classnames'
import { themeConWeb } from '../../util/modernThemeConweb'

const useStyles = makeStyles(theme => ({
  addressLine: {
    fontSize: theme.typography.caption.fontSize,
    color: 'grey',
  },
  firstAddressLine: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: 'black',
  },
  editLink: {
    fontSize: themeConWeb.fontSizes.baseMinus1,
  },
  default: {
    color: theme.palette.error.main,
    fontWeight: 500,
    fontSize: themeConWeb.color.brandPrimary,
    paddingLeft: '15px',
  },
  addressContainer: {
    display: 'flex',
    alignItems: 'start',
  },
}))

const AddressCell = ({
  location,
  shouldShowLocationManagerLink,
  id,
  setDialogToShow,
  handleSelectLocation,
  deliveryLocationId,
}) => {
  const classes = useStyles()

  const fillAddressLines = address => {
    const { name, deliveryLocationName, street, city, state, zip } = address
    return [name || deliveryLocationName, street, `${city}, ${state} ${zip}`].filter(Boolean)
  }

  const handleEditNickname = () => {
    handleSelectLocation(id)
    setDialogToShow(DIALOG_OPTIONS.EDIT_NICKNAME.id)
  }

  const goToLocationManager = locationId => {
    window.location.href = formatUrl(locationManagerRoute.path, { locationId: locationId })
  }
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('xs'))

  return (
    <Box
      width="100%"
      marginLeft={3}
      marginRight={4}
      justifyContent="flex-end"
      flexDirection={mobileView ? 'column-reverse' : 'row'}
    >
      <Box className={classes.addressContainer}>
        <Box>
          {fillAddressLines(location).map((line, i) => (
            <Typography
              key={`${line}-${i}`}
              className={cn(classes.addressLine, { [classes.firstAddressLine]: i === 0 })}
            >
              {line}
            </Typography>
          ))}
          <Typography className={classes.editLink}>
            {shouldShowLocationManagerLink && (
              <Link onClick={() => goToLocationManager(id)}>Manage | {''}</Link>
            )}
            <Link onClick={() => handleEditNickname()}>Edit Nickname</Link>
          </Typography>
        </Box>
        {deliveryLocationId === id && <Typography className={classes.default}>Default</Typography>}
      </Box>
    </Box>
  )
}

export default AddressCell
