import { Box, Button, CircularProgress, Container, Typography } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { api, FOODSBY_BASE_URL } from '../api/api'
import { contactRoute } from '../routes/routes'

const ORDER_URL = process.env.REACT_APP_BASE_ORDER_URL

const useStyles = makeStyles({
  checkIcon: {
    color: green[500],
    fontSize: '100px',
  },
  errorIcon: {
    color: red[500],
    fontSize: '100px',
  },
})

const PageConfirmEmailAddress = ({ location }) => {
  const classes = useStyles()
  const { confirmationToken, email, firstTimeVerifying } = queryString.parse(location.search)
  const [success, setSuccess] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    const verifyConfirmationToken = async () => {
      try {
        setSubmitting(true)
        await api.post(`${FOODSBY_BASE_URL}/api/v1/uaa/confirmation/verify`, { confirmationToken })
        setSuccess(true)
      } catch (err) {
        setSuccess(false)
      } finally {
        setSubmitting(false)
      }
    }

    verifyConfirmationToken()
  }, [confirmationToken, email])

  const sentFreeDelivery = firstTimeVerifying === 'true'
  const successMessage = `Thank you for verifying your email address. ${
    sentFreeDelivery ? `We just sent an email with your $0 service fee coupon code to ${email}` : ''
  }`
  const errorMessage =
    'Something went wrong verifying your email address. If this continues to happen, please contact support.'
  return (
    <Component disableGutters maxWidth={false}>
      <Box margin="auto" maxWidth="800px" paddingY={4} position="relative" textAlign="center">
        {submitting ? (
          <CircularProgress size={100} />
        ) : (
          <>
            {success ? (
              <CheckCircleOutline className={classes.checkIcon} />
            ) : (
              <ErrorOutline className={classes.errorIcon} />
            )}
            <Typography gutterBottom variant="h4">
              {success ? 'Email verified!' : 'Oops!'}
            </Typography>
            <Typography>{success ? successMessage : errorMessage}</Typography>
            <Box marginTop={4}>
              <Button color="primary" href={success ? ORDER_URL : contactRoute.path}>
                {success ? 'View restaurants' : 'Contact support'}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Component>
  )
}

const Component = styled(Container)`
  min-height: calc(100vh - 287px);
`

export default PageConfirmEmailAddress
