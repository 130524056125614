import { Typography } from '@material-ui/core'
import currency from 'currency.js'
import pluralize from 'pluralize'
import React, { useEffect, useRef, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import CartMenuItem from './CartMenuItem'

const CartPreviousItems = ({ numberOfItems, subtotal }) => (
  <li className="cart-item">
    <div className="cart-item_summary">
      <div className="cart-item_title">{`${pluralize(
        'other item',
        numberOfItems,
        true,
      )} (In Cart)`}</div>
      <div className="cart-item_price">
        {currency(subtotal, { formatWithSymbol: true }).format()}
      </div>
    </div>
  </li>
)

const useStyles = makeStyles({
  cartList: {
    display: 'block',
    maxHeight: 'calc(100vh - 800px)',
    overflowY: 'auto',
    '@media print': {
      display: 'initial',
      maxHeight: 'none',
      overflowY: 'visible',
    },
  },
})

const CartMenuItemList = ({
  deleteMenuItem,
  isDeletingMenuItem,
  editMenuItem,
  isEditingMenuItem,
  editable,
  isEditingPastOrder,
  menuId,
  menuItems,
  previousItems,
  previousSubtotal,
  showEditLinks,
  useFullSubTotal,
  showQuantity,
}) => {
  const classes = useStyles()
  const cartListRef = useRef(null)
  const [minHeight, setMinHeight] = useState('0px')

  useEffect(() => {
    if (cartListRef.current) {
      const firstItem = cartListRef.current.querySelector('li')
      if (firstItem) {
        var minHeight = firstItem.offsetHeight
        if (menuItems.length > 1) {
          minHeight += 30 // Add a little extra padding
        }
        setMinHeight(`${minHeight}px`)
      }
    }
  }, [menuItems, previousItems])
  return (
    <ul className={classes.cartList} ref={cartListRef} style={{ minHeight, maxHeight: '45vh' }}>
      {menuItems.length > 0 ? (
        menuItems.map((menuItem, index) => (
          <CartMenuItem
            deleteMenuItem={deleteMenuItem}
            isDeletingMenuItem={isDeletingMenuItem}
            editable={editable}
            editMenuItem={editMenuItem}
            isEditingMenuItem={isEditingMenuItem}
            isEditingPastOrder={isEditingPastOrder}
            key={index}
            menuId={menuId}
            menuItem={menuItem}
            showEditLinks={showEditLinks}
            useFullSubTotal={useFullSubTotal}
            showQuantity={showQuantity}
          />
        ))
      ) : (
        <Typography>No items</Typography>
      )}
      {previousItems && previousItems.length > 0 && (
        <CartPreviousItems numberOfItems={previousItems.length} subtotal={previousSubtotal} />
      )}
    </ul>
  )
}

CartMenuItemList.defaultProps = {
  menuItems: [],
}

export default CartMenuItemList
