import React from 'react'
import { FOODSBY_ADDRESS } from '../../util/constants'

const PrivacyPolicy = () => (
  <div>
    <section>
      <div className="row center-xs">
        <div className="col-xs-12 center-xs">
          <h1 className="title">Privacy Policy</h1>
        </div>
      </div>
    </section>
    <section>
      <div className="row">
        <div className="col-xs-12 start-xs">
          <p>
            <strong>FOODSBY LLC</strong>
          </p>
          <p>
            <strong>Privacy Policy</strong>
          </p>
          <br />
          <p>Effective Date: November 1, 2017</p>
          <br />
          <p>
            This Web site, https://www.foodsby.com, (the “Site”) is owned and operated by Foodsby
            Inc.
          </p>
          <br />
          <p>
            We know that your privacy is important to you, and we work hard to earn and keep your
            trust. We have created this Privacy Policy to let you know what Personal Information we
            collect when you use this Site, how we use and share the Personal Information we
            collect, and how we protect your privacy.
          </p>
          <br />
          <p>
            If you have any questions about this Privacy Policy or our privacy practices, please
            contact us by e-mail at&nbsp;
            <a href="mailto:inquiries@foodsby.com">inquiries@foodsby.com</a> or by mail at
            Foodsby&nbsp;
            {FOODSBY_ADDRESS.STREET}, {FOODSBY_ADDRESS.CITY}, {FOODSBY_ADDRESS.STATE}&nbsp;
            {FOODSBY_ADDRESS.ZIP}.
          </p>
          <p>
            This Privacy Policy applies to the online collection of Personal Information via
            software and Internet services and is incorporated into any applicable user agreement
            and service terms and any general terms of use for the Site (collectively, “Services”).
            This Privacy Policy does not apply to information collected in any other way. The
            Services may contain links to sites maintained by others. This Privacy Policy does not
            reflect the privacy practices of those sites. By using the above Services, you consent
            to the terms of this Privacy Policy and to Foodsby’s processing of Personal Information
            and non-personal information for the purposes provided in the Privacy Policy. You
            further consent to reviewing the Privacy Policy from time to time to view any updates.
          </p>
          <p>
            <strong>This Privacy Policy May Change</strong>
          </p>
          <p>
            This Privacy Policy describes Foodsby’s current policies and practices with regard to
            the Personal Information we collect through this Site.
          </p>
          <br />
          <p>
            We are continually improving and adding to the features and functionality of this Site
            and the services we offer through this Site. As a result of these changes (or changes in
            the law), we may need to update or revise this Privacy Policy. Accordingly, we reserve
            the right to update or modify this Privacy Policy at any time, without prior notice, by
            posting the revised version of this Privacy Policy behind the link marked “Privacy
            Policy” at the bottom of each page of this Site. Your continued use of this Site after
            we have posted the revised Privacy Policy constitutes your agreement to be bound by the
            revised Privacy Policy. However, we will honor the terms that were in effect when we
            gathered data from you.
          </p>
          <br />
          <p>
            For your convenience, whenever this Privacy Policy is changed, we will alert you by
            posting a notice on our home page for sixty days. We will also update the “effective
            date” at the top of this page. If more than sixty days goes by between your visits to
            this Site, be sure you check the effective date to see if this Privacy Policy has been
            revised since your last visit.
          </p>
          <br />
          <p>
            You may access the current version of this Privacy Policy at any time by clicking the
            link marked “Privacy Policy” at the bottom of each page of this Site.
          </p>
          <p>
            <strong>WHAT PERSONAL INFORMATION DOES FOODSBY COLLECT AND HOW DO WE USE IT?</strong>
          </p>
          <p>
            We collect information about our users in three ways: directly from the user, from our
            web server logs, and through cookies. We use the information primarily to provide you
            with a personalized Internet experience that delivers the information, resources, and
            services that are most relevant and helpful to you. We don’t share any of the
            information you provide with others, unless we say so in this Privacy Policy, or when we
            believe in good faith that the law requires it or when you authorize us to (for example,
            to our restaurant partners from whom you may order and receive food delivery).
          </p>
          <p>
            <u>User-Supplied Information</u>
          </p>
          <p>
            When you register for our services or order products via our site, we ask you to provide
            some Personal Information, for example, your name, address, phone number, email address,
            payment card information, and/or certain additional categories of information resulting
            from use of our websites and services. Other than payment card information, we keep this
            information in a contact database for future reference, as needed. We do not store any
            payment card information. Only our payment card processor has access to that
            information. We may use your email address you provide to offer you products and
            services that we believe may be of interest to you, via our newsletter. If you don’t
            wish to receive such offers, you may opt out (unsubscribe) via the link provided in
            these newsletters.
          </p>
          <p>
            If you contact us for customer support, we may ask you to provide information about your
            computer or about the issues you are trying to resolve. This information is necessary to
            help us answer your questions. We may record your requests and our responses for quality
            control purposes.
          </p>
          <p>
            Foodsby may make websites available to you for chat rooms, forums, message boards, or
            news groups. Please remember that any information disclosed in these areas is public.
            You should exercise caution when disclosing Personal Information in these areas. Don’t
            disclose information in these public forums that might be considered confidential.
          </p>
          <p>
            We may track information about your visit to the Site and store that information in web
            or mobile server logs, which are records of the activities on the Site. Our servers
            automatically capture and save the information electronically. Examples of the
            information we may collect include:
          </p>
          <ul>
            <li>Your unique Internet protocol address</li>
            <li>The name of your unique Internet service provider</li>
            <li>The city, state, and country from which you access the Services</li>
            <li>The kind of browser or computer you use</li>
            <li>The number of links you click within the Site</li>
            <li>The date and time of your visit</li>
            <li>The web page from which you arrived to our site</li>
            <li>The pages you viewed on the Site</li>
            <li>Certain searches/queries that you conducted via our website(s)</li>
          </ul>
          <p>
            The information we collect in web server logs helps us administer the Site, analyze its
            usage, protect the website and its content from inappropriate use, and improve the
            user’s experience.
          </p>
          <p id="ad-choices">
            <u>Information About Third-Party Cookies and Interest-Based Advertising</u>
          </p>
          <p>
            In order to offer and provide a customized and personal service, we may use cookies to
            store and help track information about you. Cookies are simply small pieces of data that
            are sent to your browser from a web server and stored on your computer’s hard drive.
          </p>
          <p>
            The use of cookies is relatively standard. Most browsers are initially set up to accept
            cookies. However, if you prefer, you can set your browser to either notify you when you
            receive a cookie or to refuse to accept cookies. You should understand that some
            features of many sites may not function properly if you don’t accept cookies. In
            general, we use cookies and other web technologies to:
          </p>
          <ul>
            <li>
              collect information about the ways visitors use our Site—which pages they visit, which
              links they use, and how long they stay on each page;{' '}
            </li>
            <li>
              support the features and functionality of our Site—for example, to save you the
              trouble of reentering information already in our database or to prompt the settings
              you established on previous visits; and
            </li>
            <li>
              personalize your experience when you use our Site, including by serving targeted
              advertisements.
            </li>
          </ul>
          <p>
            Generally, the information we collect using these web technologies does not identify you
            personally. <span>If, however, you have created a user account</span>, we may link the
            information we collect using these web technologies to other information that identifies
            you personally.
          </p>
          <p>
            <u>Third Party Cookies</u>
          </p>
          <p>
            In addition to the cookies we deliver to your computer or mobile or tablet device
            through our Site, certain third parties may deliver cookies to you for a variety of
            reasons. For example, we use Google Analytics, a web analytics tool that helps us
            understand how visitors engage with our Site. To learn more about Google Analytics,{' '}
            <a
              href="https://marketingplatform.google.com/about/analytics/"
              rel="noopener noreferrer"
              target="_blank"
            >
              click here
            </a>
            .
          </p>
          <p>
            Other third parties may deliver cookies to your computer or mobile or tablet device for
            the purpose of tracking your online behaviors across nonaffiliated websites and
            delivering targeted advertisements either on our Site or on other websites.
          </p>
          <p>
            You have choices about the collection of information by third parties on our Site. For
            example, if you don’t want information about your visit to our Site sent to Google
            Analytics, you may download an Opt-out Browser Add-on by{' '}
            <a
              href="http://tools.google.com/dlpage/gaoptout?hl=en"
              rel="noopener noreferrer"
              target="_blank"
            >
              clicking here
            </a>
            . Please note that the Add-on does not prevent information from being sent to us.
          </p>
          <p>
            In addition, if you would like to opt-out of having interest-based information collected
            by certain entities during your visits to our Site or other websites, please click here.
            You will be directed to an industry-developed website that contains mechanisms for
            choosing whether each listed entity may collect and use data for online behavioral
            advertising purposes. It may be that some of the third parties that collect
            interest-based information on our Site do not participate in the industry-developed
            opt-out website, in which case the best way to avoid third-party tracking of your online
            behaviors may be through your browser settings and deletion of cookies.
          </p>
          <p>
            <u>Children’s Privacy</u>
          </p>
          <p>
            Services are not structured nor intended for users under 18 years of age. Accordingly,
            Foodsby will not knowingly collect or use any Personal Information from persons that
            Foodsby knows to be under the age of 18.
          </p>
          <p>
            <strong>HOW DOES FOODSBY PROTECT THE PERSONAL INFORMATION THAT IT COLLECTS?</strong>
          </p>
          <p>
            We have implemented certain appropriate security measures to help protect your Personal
            Information from accidental loss and from unauthorized access, use, or disclosure. For
            example, some of our websites are protected with Secure Sockets Layer (SSL) technology.
            Also, we store the information about you in a data center with restricted access and
            appropriate monitoring, and we use a variety of technical security measures to secure
            your data. In addition, we use intrusion detection and virus protection software.
            However, please note that we cannot guarantee that unauthorized persons will always be
            unable to defeat our security measures.
          </p>
          <p>
            Also, please note that we may store and process your Personal Information in systems
            located outside of your home country. However, regardless of where storage and
            processing may occur, we take appropriate steps to ensure that your information is
            protected, consistent with the principles set forth under this Privacy Policy.
          </p>
          <p>
            <strong>WHO HAS ACCESS TO THE PERSONAL INFORMATION?</strong>
          </p>
          <p>
            We will not sell, rent, or lease mailing lists or other customer data to others, and we
            will not make your Personal Information available to any unaffiliated parties, except as
            follows:
          </p>
          <ul>
            <li>
              To restaurant partners, agents and/or contractors who may use it on in connection with
              their relationship with us (for example, restaurants will use your Personal
              Information to deliver food orders you placed on the Site).
            </li>
            <li>
              As required by law, in a matter of public safety or Privacy Policy, or if we believe
              in good faith that sharing the data is necessary to protect our rights or property.
            </li>
            <li>
              We may rely on affiliates for support of the products and services we offer. If so,
              our affiliates would be required to preserve the confidentiality of any Personal
              Information they may access.
            </li>
            <li>
              We will not disclose any information about your unique usage to unaffiliated third
              parties, except as necessary to service the account, to enforce the terms of use, to
              meet our obligations to content and technology providers, including their ability to
              offer products and services, or as required by law.
            </li>
            <li>
              As noted above, we may use statistics or other data regarding your usage of the
              Services for product development or other business analytic purposes, but only in
              anonymized aggregate form. We may make such data and statistics available to third
              parties, such as potential or existing customers, for business development, marketing
              or sales purposes.
            </li>
            <li>
              Your information may be transferred to successor organization if, for example, we
              transfer the ownership or operation of this Site to another organization or if we
              merge with another organization. If such a transfer occurs, the successor
              organization’s use of your information will still be subject to this Privacy Policy
              and the privacy preferences you have expressed to us.
            </li>
          </ul>
          <p>
            Your access to some of Services and content may be password protected. We recommend that
            you refrain from disclosing your username(s) and password(s) to anyone. We also
            recommend that you sign out of your account or service at the end of each session. You
            may also wish to close your browser window when you have finished your work, especially
            if you share a computer with someone else or if you are using a computer in a public
            place like a library or Internet cafe.
          </p>
          <p>
            <strong>USE OF SLACK BOT</strong>
          </p>
          <p>
            If the Foodsby Slackbot is deployed in your Slack environment and Foodsby is available
            at your physical location you could have access to the Daily Schedule in Slack. You need
            a Foodsby Account to download the Foodsby Slackbot into one of your Teams channels.
            Foodsby will check your email address against Foodsby’s account database to check for
            the Daily Schedule at your physical location. If an Existing User clicks the bot, they
            will be redirected to Foodsby to place an order. If a New User clicks the bot, they will
            be redirected to Foodsby to the sign up page to create a account.”
          </p>
          <p>
            <strong>
              HOW MAY I CORRECT, AMEND, OR DELETE MY PERSONAL INFORMATION AND/OR UPDATE MY
              PREFERENCES?
            </strong>
          </p>
          <p>
            You may cancel your registration or update your preferences at any time. If you don’t
            want to receive information about our products or services, please update your account
            preferences (where available), check the appropriate box when registering, and/or
            utilize the “unsubscribe” mechanism within the communications that you receive from us.
          </p>
          <p>
            <strong>QUESTIONS?</strong>
          </p>
          <p>
            If you have any additional questions or concerns related to this Privacy Policy and/or
            our practices, please email us at&nbsp;
            <a href="mailto:inquiries@foodsby.com">inquiries@foodsby.com</a>.
          </p>
          <p>
            <strong>Definitions</strong>
          </p>
          <br />
          <p>    The terms “Foodsby,” “we,” “us,” and “our” refer to Foodsby Corp.</p>
          <br />
          <p>
            The term “Personal Information” refers to information that identifies you personally,
            alone or in combination with other information available to us. Examples of Personal
            Information include your name, address, and e-mail address.
          </p>
          <br />
          <p>
            The term “User-Generated Content” refers to all of the text, photographs, images,
            illustrations, graphics, sounds, video, audio-video clips, and other content you post on
            or through this Site using the social networking tools we make available to registered
            members of this Site. One way that User-Generated Content differs from other information
            you provide to us is that, once submitted, User-Generated Content is made available
            instantaneously to others who visit this Site. Examples of User-Generated Content
            include comments posted to discussion boards and reviews of products available on this
            Site.
          </p>
        </div>
      </div>
    </section>
  </div>
)

export default PrivacyPolicy
