import { Box, Grid, Paper } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'

const SkeletonLoadingDeliverySection = () => (
  <>
    <SkeletonLoadingCard />
    <SkeletonLoadingCard />
    <SkeletonLoadingCard />
  </>
)

const SkeletonLoadingCard = ({ logoShape }) => {
  return (
    <Box marginBottom={3}>
      <Paper color="secondary" variant="outlined">
        <Box alignItems="center" display="flex" minHeight="140px">
          <Grid container>
            <Grid item md={4} xs={12}>
              <Box display="flex" justifyContent="center">
                <Skeleton height={100} variant={logoShape} width={100} />
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton height={60} width={150} />
              <Grid container justifyContent="flex-start">
                <Grid item xs={3}>
                  <Skeleton height={10} width={80} />
                  <Skeleton height={30} width={100} />
                </Grid>
                <Grid container item justifyContent="center" xs={1}>
                  <Skeleton height="100%" width={3} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton height={10} width={80} />
                  <Skeleton height={30} width={100} />
                </Grid>
                <Grid container item justifyContent="center" xs={1}>
                  <Skeleton height="100%" width={3} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton height={10} width={80} />
                  <Skeleton height={30} width={100} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  )
}

export default SkeletonLoadingDeliverySection
