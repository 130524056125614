import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { themeConWeb } from '@app/util/modernThemeConweb'
import pluralize from 'pluralize'

import LinkButton from '../../common/LinkButton'

const AltHeaderTableMembers = ({ allSelected, totalRows, numSelected, onSelectAll }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      paddingY={2}
    >
      <Box marginRight={1}>
        <Typography variant="body2">
          {allSelected && 'All '}
          {numSelected} {pluralize('member', numSelected)} {pluralize('is', numSelected)} selected.
        </Typography>
      </Box>
      <LinkButton onClick={() => onSelectAll(allSelected)} color={themeConWeb.color.brandPrimary}>
        {allSelected ? 'Clear selection' : `Select all ${totalRows} members on team`}
      </LinkButton>
    </Box>
  )
}

export default AltHeaderTableMembers
