import {
  getGiftTransactionDetailsApi,
  getGiftTransactionsApi,
  getInvoiceReportApi,
  getInvoicesApi,
} from '../../services/team'
import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import downloadFile from '../../util/downloadFile'
import { enqueueSnackbar } from './snackbar'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const INVOICES_SET = 'foodsby/teamBilling/INVOICES_SET'
export const USAGE_REPORT_DOWNLOAD = 'foodsby/teamBilling/USAGE_REPORT_DOWNLOAD'
export const GIFT_TRANSACTIONS_SET = 'foodsby/teamBilling/GIFT_TRANSACTIONS_SET'
export const GIFT_TRANSACTION_DETAILS_SET = 'foodsby/teamBilling/GIFT_TRANSACTION_DETAILS_SET'
export const STATE_RESET_GIFT_TRANSACTION_DETAILS =
  'foodsby/teamBilling/RESET_STATE_GIFT_TRANSACTION_DETAILS'
export const PAGE_GIFT_RECIPIENTS_SET = 'foodsby/teamBilling/PAGE_GIFT_RECIPIENTS_SET'
export const SORT_GIFT_RECIPIENTS_SET = 'foodsby/teamBilling/SORT_GIFT_RECIPIENTS_SET'

export const setInvoices = createAsyncAction(INVOICES_SET)
export const downloadUsageReport = createAsyncAction(USAGE_REPORT_DOWNLOAD)
export const setGiftTransactions = createAsyncAction(GIFT_TRANSACTIONS_SET)
export const setGiftTransactionDetails = createAsyncAction(GIFT_TRANSACTION_DETAILS_SET)
export const resetGiftTransactionDetailsState = createAction(STATE_RESET_GIFT_TRANSACTION_DETAILS)
export const setPageGiftRecipients = createAction(PAGE_GIFT_RECIPIENTS_SET)
export const setSortGiftRecipients = createAction(SORT_GIFT_RECIPIENTS_SET)

// ------------------------------------
// Thunks
// ------------------------------------
export const loadInvoicesStart = (accountId, page, pageSize) => {
  return async dispatch => {
    try {
      await dispatch(setInvoices(getInvoicesApi(accountId, page, pageSize)))
    } catch (ex) {
      dispatch(
        enqueueSnackbar({
          message:
            'Something went wrong while loading the invoices. Please try again later or contact support.',
        }),
      )
    }
  }
}

export const downloadUsageReportStart = (invoiceId, fileName) => {
  return async () => {
    const file = await getInvoiceReportApi(invoiceId)
    downloadFile(file, fileName, 'text/csv')
  }
}

export const loadGiftTransactionsStart = (accountId, page, pageSize) => {
  return async dispatch => {
    try {
      await dispatch(setGiftTransactions(getGiftTransactionsApi(accountId, page, pageSize)))
    } catch (ex) {
      dispatch(
        enqueueSnackbar({
          message:
            'Something went wrong while loading the gift transactions. Please try again later or contact support.',
        }),
      )
    }
  }
}

export const loadGiftTransactionDetailsStart = (accountId, giftTransactionId) => {
  return dispatch => {
    return dispatch(
      setGiftTransactionDetails(getGiftTransactionDetailsApi(accountId, giftTransactionId)),
    )
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PENDING(INVOICES_SET)]: state => {
    return {
      ...state,
      isLoadingInvoices: true,
    }
  },
  [FULFILLED(INVOICES_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingInvoices: false,
      invoices: action.payload,
    }
  },
  [REJECTED(INVOICES_SET)]: state => {
    return {
      ...state,
      isLoadingInvoices: false,
    }
  },
  [PENDING(GIFT_TRANSACTIONS_SET)]: state => {
    return {
      ...state,
      isLoadingGiftTransactions: true,
    }
  },
  [FULFILLED(GIFT_TRANSACTIONS_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingGiftTransactions: false,
      giftTransactions: action.payload,
    }
  },
  [REJECTED(GIFT_TRANSACTIONS_SET)]: state => {
    return {
      ...state,
      isLoadingGiftTransactions: false,
    }
  },
  [PENDING(GIFT_TRANSACTION_DETAILS_SET)]: state => {
    return {
      ...state,
      isLoadingGiftTransactionDetails: true,
    }
  },
  [FULFILLED(GIFT_TRANSACTION_DETAILS_SET)]: (state, action) => {
    const { giftCardDetails, ...rest } = action.payload
    return {
      ...state,
      isLoadingGiftTransactionDetails: false,
      giftTransactionDetails: {
        details: rest,
        recipients: giftCardDetails.map(g =>
          g.actualRecipient
            ? { ...g.actualRecipient, redeemed: true }
            : { ...g.intendedRecipient, redeemed: false },
        ),
      },
    }
  },
  [REJECTED(GIFT_TRANSACTION_DETAILS_SET)]: state => {
    return {
      ...state,
      isLoadingGiftTransactionDetails: false,
    }
  },
  [STATE_RESET_GIFT_TRANSACTION_DETAILS]: state => {
    return {
      ...state,
      isLoadingGiftTransactionDetails: false,
      giftTransactionDetails: undefined,
    }
  },
  [PAGE_GIFT_RECIPIENTS_SET]: (state, action) => {
    return {
      ...state,
      pageGiftRecipients: action.payload,
    }
  },
  [SORT_GIFT_RECIPIENTS_SET]: (state, action) => {
    return {
      ...state,
      sortGiftRecipients: action.payload,
    }
  },
}

export const initialState = {
  invoices: undefined,
  giftTransactions: undefined,
  giftTransactionDetails: undefined,
  pageGiftRecipients: 0,
  sortGiftRecipients: { field: undefined, direction: undefined },
  // Loading states
  isLoadingInvoices: true,
  isLoadingGiftTransactions: true,
  isLoadingGiftTransactionDetails: false,
}

export default function teamBilling(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
