import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import TableLocationOffices from './TableLocationOffices'
import LocationManagerHeader from './LocationManagerHeader'
import SearchOfficesAndManagersInput from './SearchOfficesAndMangers'

const LocationOfficesTab = ({
  loadingTable,
  locationOffices,
  pageOffices,
  setPageOffices,
  name,
  locationId,
  handleEditOffice,
  isEditingOffice,
  errorEditingOffice,
  handleRemoveOffice,
  isRemovingOffice,
  errorRemovingOffice,
}) => {
  const [filteredOffices, setFilteredOffices] = useState(locationOffices)

  const handleSearch = value => {
    const lowercasedValue = value.toLowerCase()
    const newFilteredOffices = locationOffices.filter(office => {
      const officeNameMatches = office.name.toLowerCase().includes(lowercasedValue)
      const managerUsernameMatches = office.externalManagers?.some(manager =>
        manager.username.toLowerCase().includes(lowercasedValue),
      )

      return officeNameMatches || managerUsernameMatches
    })

    setFilteredOffices(newFilteredOffices)
  }

  useEffect(() => {
    setFilteredOffices(locationOffices)
  }, [locationOffices])

  if (loadingTable) {
    return <SkeletonLocationOfficesTab locationId={locationId} name={name} />
  }

  return (
    <Grid>
      <LocationManagerHeader locationId={locationId} name={name} />
      <Box mt={3} bgcolor="white">
        <SearchOfficesAndManagersInput onChange={handleSearch} />
      </Box>
      <Box mt={3}>
        <TableLocationOffices
          locationOffices={locationOffices}
          filteredOffices={filteredOffices}
          loadingTable={loadingTable}
          pageOffices={pageOffices}
          setPageOffices={setPageOffices}
          handleEditOffice={handleEditOffice}
          isEditingOffice={isEditingOffice}
          errorEditingOffice={errorEditingOffice}
          handleRemoveOffice={handleRemoveOffice}
          isRemovingOffice={isRemovingOffice}
          errorRemovingOffice={errorRemovingOffice}
        />
      </Box>
    </Grid>
  )
}

const SkeletonLocationOfficesTab = ({ locationId, name }) => {
  return (
    <>
      <LocationManagerHeader locationId={locationId} name={name} />
      <Box mt={3}>
        <Box height={800} overflow="hidden">
          <Skeleton variant="rect" height="100%" />
        </Box>
      </Box>
    </>
  )
}

export default LocationOfficesTab
