import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

const RESTAURANT_LOGIN_URL = 'https://www.foodsby.com/main/restaurants-login'
const BASE_URL = 'https://home.foodsby.com'

const OrderFooter = styled.div`
  & .-footer {
    width: 100%;
    overflow-x: hidden;
    min-height: 300px;
    padding: 3px 52px 5px 200px;
    background-color: #13171a;
    position: relative;
    flex: 1 0 100%;

    @media screen and (max-width: 1200px) {
      padding: 3px 20px 5px 20px;
      text-align: center;
    }

    & .-footer-content {
      display: flex;
      width: 100%;
      max-width: 850px;
      margin: auto;
      margin-top: 40px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }

      & .-footer-section {
        flex: 1 0 auto;

        & h6 {
          color: white;
          margin-bottom: 20px;
          text-transform: uppercase;
          font-size: 18px;
        }

        & a {
          font-family: 'Roboto';
          font-weight: 300;
          font-size: 16px;
          color: white;
          display: block;
          margin-top: 10px;
          margin-bottom: 20px;
          line-height: 24px;
          font-weight: 400;
        }

        @media screen and (max-width: 768px) {
          & h6 {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          & ul {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          & ul li {
            display: block;
          }
        }
      }
    }
  }
`

const Footer = ({ isAuthenticated }) => (
  <OrderFooter>
    <footer className="-footer">
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="flex-start"
        className="-footer-content"
      >
        <Grid item className="-footer-section" xs={2}>
          <h6>Partner</h6>
          <ul className="-list">
            <li>
              <a href={`${BASE_URL}/offices`}>For Offices</a>
            </li>
            <li>
              <a href={`${BASE_URL}/restaurants`}>For Restaurants</a>
            </li>
            <li>
              <a href={RESTAURANT_LOGIN_URL}>Restaurant Login</a>
            </li>
          </ul>
        </Grid>

        <Grid item className="-footer-section" xs={2}>
          <h6>About</h6>
          <ul>
            <li>
              <a href={`${BASE_URL}/about-us`}>About Us</a>
            </li>
            <li>
              <a href={`${BASE_URL}/careers`}>Careers</a>
            </li>
          </ul>
        </Grid>
        <Grid item className="-footer-section" xs={2}>
          <h6>Support</h6>
          <ul>
            <li>
              <a href={`${BASE_URL}/faq`}>FAQ</a>
            </li>
            <li>
              <a href={`${BASE_URL}/terms-privacy`}>Terms & Privacy</a>
            </li>
            <li>
              <a href={`${BASE_URL}/contact`}>Contact Us</a>
            </li>
          </ul>
        </Grid>
      </Grid>{' '}
    </footer>
  </OrderFooter>
)

function mapStateToProps(state) {
  const { currentUser } = state.user
  return {
    currentUser,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
