import React, { Component } from 'react'
import { Modal } from '@foodsby/nutrient'
import { ConnectedRouter } from 'connected-react-router'
import { noop } from 'lodash'
import { createPortal } from 'react-dom'
import { Provider } from 'react-redux'

import store, { history } from '../../redux/store'

class ModalPopup extends Component {
  constructor(props) {
    super(props)
    this.state = { showModal: true }
    document.body.classList.add('noscroll')
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }

  showModal(evt) {
    evt.preventDefault()
    document.body.classList.add('noscroll')
    this.setState({ showModal: true })
  }

  hideModal() {
    document.body.classList.remove('noscroll')
    this.setState({ showModal: false })
  }

  render() {
    const { children, showCancel = true, ...rest } = this.props
    const onCancel = showCancel ? this.hideModal : noop
    return (
      this.state.showModal &&
      createPortal(
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Modal onCancel={onCancel} showCancel={showCancel} {...rest}>
              {children}
            </Modal>
          </ConnectedRouter>
        </Provider>,
        document.getElementById('modal'),
      )
    )
  }
}

export default ModalPopup
