import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { themeConWeb } from '../../util/modernThemeConweb'
import DynamicLink from '../common/DynamicLink'
import { reserveRoute } from '../../routes/routes'
import { formatUrl } from '../../util/formatUtils'
import SectionHeaderWrapper from './SectionHeaderWrapper'
import HomeButton from './HomeButton'

const ReserveHeader = ({ loading, locationId, showSeeAll }) => {
  if (loading) {
    return (
      <Box style={{ paddingBottom: 4, paddingTop: 4 }}>
        <Skeleton height={70} width={250} />
      </Box>
    )
  }
  return (
    <SectionHeaderWrapper>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography
            variant="h6"
            style={{
              color: themeConWeb.color.primaryType,
              fontWeight: 700,
              fontSize: themeConWeb.fontSizes.basePlus3,
              lineHeight: themeConWeb.fontSizes.basePlus2,
            }}
          >
            {showSeeAll ? 'Available to Reserve' : <HomeButton />}
          </Typography>
        </Box>
        <Box>
          {showSeeAll && (
            <DynamicLink
              style={{
                color: themeConWeb.color.primaryType,
                fontWeight: 600,
                fontSize: themeConWeb.fontSizes.basePlus1,
                textDecoration: 'none',
                paddingRight: '10px',
              }}
              to={formatUrl(reserveRoute.path, {
                locationId: locationId,
              })}
            >
              See All
            </DynamicLink>
          )}
        </Box>
      </Box>
    </SectionHeaderWrapper>
  )
}

export default ReserveHeader
