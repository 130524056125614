import React from 'react'
import { Box, Chip } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { locationStatusOptions } from '../location-manager/utils'
import { themeConWeb } from '../../util/modernThemeConweb'

const LocationStatusCell = ({ status }) => {
  if (status === locationStatusOptions.Preferred) {
    return (
      <Box width="100%" marginLeft={2} marginRight={2}>
        <Chip
          style={{ color: 'green' }}
          color="blue"
          icon={<CheckCircle style={{ color: 'green' }} />}
          label="Preferred"
          variant="outlined"
          size="small"
        />
      </Box>
    )
  }

  return (
    <Box width="100%" marginLeft={2} marginRight={2}>
      <Chip
        style={{ color: themeConWeb.color.greyShade4 }}
        color="default"
        icon={<CheckCircle style={{ color: themeConWeb.color.greyShade1 }} />}
        label="Standard"
        variant="outlined"
        size="small"
      />
    </Box>
  )
}

export default LocationStatusCell
