import { InputError } from '@foodsby/nutrient'
import { Box, MenuItem, TextField } from '@material-ui/core'
import { get } from 'lodash'
import React, { useCallback, useEffect } from 'react'

import PaymentMethodItem from '../common/PaymentMethodItem'
import { NEW_PAYMENT_METHOD_ID } from '../../util/paymentMethods'
import PaymentMethodDetail from './PaymentMethodDetail'
import StripePaymentMethodElement from './StripePaymentMethodElement'

const BillingInfo = ({
  clearError,
  paymentMethods,
  paymentMethodsError,
  errors,
  handleBlur,
  handleChange,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
  CardElement,
  showSaveCard,
}) => {
  const options = [
    ...paymentMethods.map(method => ({
      label: `${method?.card?.lastFour}`,
      value: method?.id,
      paymentMethod: method,
    })),
    { label: 'Add New Card', value: -1 },
  ]

  useEffect(() => {
    const defaultCard = paymentMethods.find(method => method.defaultAtCheckout === true)

    if (paymentMethods.length === 1) {
      setFieldValue('SelectedCard', paymentMethods[0].id)
    }

    if (paymentMethods.length > 1 && defaultCard !== undefined) {
      setFieldValue('SelectedCard', defaultCard.id)
    }

    if (paymentMethods > 1) {
      setFieldValue('SelectedCard', NEW_PAYMENT_METHOD_ID)
    }
  }, [paymentMethods, handleBlur, setFieldTouched, setFieldValue])

  const getError = useCallback(name => get(touched, name) && get(errors, name), [errors, touched])

  return (
    <>
      {paymentMethods && paymentMethods.length > 0 ? (
        <>
          <Box mb={3}>
            <TextField
              id="SelectedCard"
              color="secondary"
              error={!!getError('SelectedCard')}
              fullWidth
              helperText={getError('SelectedCard')}
              label="Select a Card"
              name="SelectedCard"
              onBlur={handleBlur}
              onChange={evt => {
                setFieldValue('SelectedCard', evt.target.value)
                clearError && clearError()
              }}
              select
              size="small"
              value={
                options.filter(opt => opt.value === values.SelectedCard)[0]?.value ||
                (paymentMethods.length > 1 ? '' : -1)
              }
              variant="outlined"
            >
              {options.map(opt => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.paymentMethod && <PaymentMethodItem paymentMethod={opt.paymentMethod} />}

                  {!opt.paymentMethod && (
                    <>
                      <Box maxWidth="35px" marginRight={3} clone>
                        <img
                          src={`${process.env.REACT_APP_BASE_ORDER_URL}/images/card_etc.svg`}
                          alt="cc"
                        />
                      </Box>
                      {opt.label}
                    </>
                  )}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          {values.SelectedCard === -1 && (
            <>
              <StripePaymentMethodElement CardElement={CardElement} />
              {showSaveCard && (
                <PaymentMethodDetail
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          <StripePaymentMethodElement CardElement={CardElement} />
          {showSaveCard && (
            <PaymentMethodDetail
              handleBlur={handleBlur}
              handleChange={handleChange}
              values={values}
            />
          )}
        </>
      )}
      <InputError>{paymentMethodsError}</InputError>
    </>
  )
}

export default BillingInfo
