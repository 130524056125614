export const themeConWeb = {
  color: {
    brandPrimaryTint5: '#ecc0ba',
    brandPrimaryTint4: '#e5aca4',
    brandPrimaryTint3: '#df978d',
    brandPrimaryTint2: '#d98276',
    brandPrimaryTint1: '#c85849',
    brandPrimary: '#bb2f1c',
    brandPrimaryShade1: '#822013',
    brandPrimaryShade2: '#992616',
    brandPrimaryShade3: '#862113',
    brandPrimaryShade4: '#731c10',
    brandSecondaryTint8: '#e5e5e5',
    brandSecondaryTint7: '#cccccc',
    brandSecondaryTint6: '#b2b2b2',
    brandSecondaryTint5: '#999999',
    brandSecondaryTint4: '#7f7f7f',
    brandSecondaryTint3: '#666666',
    brandSecondaryTint2: '#4c4c4c',
    brandSecondaryTint1: '#333333',
    brandSecondary: '#000000',
    brandSecondaryShade1: '#000000',
    brandSecondaryShade2: '#000000',
    oneTint7: '#fef8f1',
    oneTint6: '#fef1e2',
    oneTint5: '#fdead4',
    oneTint4: '#fde3c6',
    oneTint3: '#fddcb8',
    oneTint2: '#fcd5aa',
    oneTint1: '#fbc78e',
    one: '#fbba72',
    oneShade1: '#e1a766',
    oneShade2: '#af814f',
    twoTint5: '#faebe9',
    twoTint4: '#f6d6d2',
    twoTint3: '#eeaea5',
    twoTint2: '#e68679',
    twoTint1: '#de5e4c',
    two: '#ee3c24',
    twoShade1: '#d63620',
    twoShade2: '#c0301c',
    twoShade3: '#ab2b19',
    twoShade4: '#802013',
    twoShade5: '#55150c',
    three: '#008d99',
    grey: '#f5f5f5',
    greyShade1: '#e6e7e8',
    greyShade2: '#d5d6d8',
    greyShade3: '#abadb2',
    greyShade4: '#8a8b8d',
    greyShade5: '#626366',
    greyShade6: '#4c4b4d',
    greyShade7: '#35393d',
    greyShade8: '#13171a',
    white: '#ffffff',
    black: '#000000',
    // Functional colors
    primaryType: '#30332c',
    danger: '#ab2b19',
    dangerLight: '#d7522f',
    success: '#6e8d28',
    warning: '#feb700',
    warningLight: '#fbc560',
    boxShadow: '0 0 6px 0 rgba(89, 150, 155, 0.7)',
  },

  // Spacing
  baseUnit: '4px',
  borderRadius: '4px',

  // Layout
  elevation1: '0 1px 1px rgba(0,0,0,0.12), 0 1px 1px rgba(0,0,0,0.24)',
  elevation2: '0 3px 3px rgba(0,0,0,0.16), 0 3px 3px rgba(0,0,0,0.23)',
  elevation3: '0 10px 10px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  elevation4: '0 14px 14px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
  elevation5: '0 19px 19px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',

  // Spacing
  small: '(min-width:320px) and (max-width:640px)',
  medium: '(min-width:640px) and (max-width:960px)',
  large: '(min-width:960px)',

  // Typography
  fonts: {
    primary: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
    secondary: 'futura-pt, sans-serif',
    monospace: 'Menlo, monospace',
  },

  fontSizes: {
    baseMinus2: `10px`,
    baseMinus1: `12px`,
    base: `14px`,
    basePlus1: `16px`,
    basePlus2: `20px`,
    basePlus3: `24px`,
    basePlus4: `32px`,
    basePlus5: `40px`,
    basePlus6: `50px`,
    basePlus7: `60px`,
    basePlus8: `72px`,
  },

  lineHeights: {
    primary: 1.2,
    heading: 1.2,
  },

  transition: '0.3s ease',
}
