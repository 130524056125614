import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, DialogActions, DialogContent, Divider, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import pluralize from 'pluralize'

import ButtonSubmit from '../../common/ButtonSubmit'
import { formatCentsToPrettyDollars } from './giftCardUtils'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    height: ({ height }) => height,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

const StepPerkMemberConfirmation = ({
  height,
  addMode,
  perkMembers,
  selectedMembers,
  errorSubmitting,
  validationError,
  submitting,
  onBack,
  onSubmit,
  pricePerHeadInCents,
}) => {
  const classes = useStyles({ height })

  const updatedTotalMembers = addMode
    ? perkMembers.length + selectedMembers.length
    : perkMembers.length - selectedMembers.length
  const totalChange = formatCentsToPrettyDollars(selectedMembers.length * pricePerHeadInCents)
  const monthlyTotal = formatCentsToPrettyDollars(updatedTotalMembers * pricePerHeadInCents)
  return (
    <>
      <DialogContent className={classes.dialogContent}>
        {validationError && (
          <Box marginBottom={3}>
            <Typography color="error">{validationError}</Typography>
          </Box>
        )}
        <RowSpaced marginBottom={3}>
          <Typography>
            {selectedMembers.length} {addMode ? 'added' : 'removed'}{' '}
            {pluralize('member', selectedMembers.length)}
          </Typography>
          <Typography>
            {formatCentsToPrettyDollars(pricePerHeadInCents)} per member/month
          </Typography>
        </RowSpaced>
        <Divider />
        <RowSpaced marginTop={3}>
          <Typography className={classes.boldText}>Adjustment to Monthly Total</Typography>
          <Typography className={classes.boldText}>
            {addMode ? '+' : '-'} {totalChange}
          </Typography>
        </RowSpaced>
        <RowSpaced marginTop={5} marginBottom={3}>
          <Typography>{updatedTotalMembers} total members</Typography>
          <Typography>
            {formatCentsToPrettyDollars(pricePerHeadInCents)} per member / month
          </Typography>
        </RowSpaced>
        <Divider />
        <RowSpaced marginTop={3}>
          <Typography className={classes.boldText}>Monthly Total</Typography>
          <Typography className={classes.boldText}>{monthlyTotal}</Typography>
        </RowSpaced>
        <Box marginY={3}>
          <Typography variant="caption" color="textSecondary">
            Your scheduled monthly payment date will not change. Added/removed member subscriptions
            are prorated to the first of the month.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onBack}>
          Back
        </Button>
        <ButtonSubmit
          variant="contained"
          color="primary"
          submitting={submitting}
          onClick={onSubmit}
        >
          Confirm
        </ButtonSubmit>
      </DialogActions>
      {errorSubmitting && (
        <Alert severity="error">
          Something went wrong when {addMode ? 'adding' : 'removing'} members to the perk. Please
          try again or contact support.
        </Alert>
      )}
    </>
  )
}

const RowSpaced = ({ children, ...props }) => {
  return (
    <Box display="flex" justifyContent="space-between" {...props}>
      {children}
    </Box>
  )
}

StepPerkMemberConfirmation.propTypes = {
  height: PropTypes.number,
  addMode: PropTypes.bool.isRequired,
  perkMembers: PropTypes.array.isRequired,
  selectedMembers: PropTypes.array.isRequired,
  errorSubmitting: PropTypes.bool,
  validationError: PropTypes.string,
  submitting: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pricePerHeadInCents: PropTypes.number.isRequired,
}

StepPerkMemberConfirmation.defaultProps = {
  submitting: false,
}

export default StepPerkMemberConfirmation
