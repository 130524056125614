import { isNumber } from 'lodash'
import { all, call, put, race, select, take } from 'redux-saga/effects'

import { api, favoritesCache, monolithCache } from '../../api/api'
import { LOAD_MENU_FAILURE, LOAD_MENU_SUCCESS } from './menu'
import { apiSaga } from './sagas'
import { selectCurrentMenu, selectCurrentUser } from './selectors'
// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const ADD_FAVORITE = 'foodsby/favorite/ADD_FAVORITE'
export const ADD_FAVORITE_SUCCESS = 'foodsby/favorite/ADD_FAVORITE_SUCCESS'
export const ADD_FAVORITE_FAILURE = 'foodsby/favorite/ADD_FAVORITE_FAILURE'

export const LOAD_FAVORITE = 'foodsby/favorite/LOAD_FAVORITE'
export const LOAD_FAVORITE_SUCCESS = 'foodsby/favorite/LOAD_FAVORITE_SUCCESS'
export const LOAD_FAVORITE_FAILURE = 'foodsby/favorite/LOAD_FAVORITE_FAILURE'

export const LOAD_HASHES = 'foodsby/favorite/LOAD_HASHES'
export const LOAD_HASHES_SUCCESS = 'foodsby/favorite/LOAD_HASHES_SUCCESS'
export const LOAD_HASHES_FAILURE = 'foodsby/favorite/LOAD_HASHES_FAILURE'

export const DELETE_FAVORITE = 'foodsby/favorite/DELETE_FAVORITE'
export const DELETE_FAVORITE_SUCCESS = 'foodsby/favorite/DELETE_FAVORITE_SUCCESS'
export const DELETE_FAVORITE_FAILURE = 'foodsby/favorite/DELETE_FAVORITE_FAILURE'

export const LOAD_POPULAR_ITEMS = 'foodsby/favorite/LOAD_POPULAR_ITEMS'
export const LOAD_POPULAR_ITEMS_SUCCESS = 'foodsby/favorite/LOAD_POPULAR_ITEMS_SUCCESS'
export const LOAD_POPULAR_ITEMS_FAILURE = 'foodsby/favorite/LOAD_POPULAR_ITEMS_FAILURE'

export const LOAD_POPULAR_MENU_ITEMS = 'foodsby/favorite/LOAD_POPULAR_MENU_ITEMS'
export const LOAD_POPULAR_MENU_ITEMS_SUCCESS = 'foodsby/favorite/LOAD_POPULAR_MENU_ITEMS_SUCCESS'
export const LOAD_POPULAR_MENU_ITEMS_FAILURE = 'foodsby/favorite/LOAD_POPULAR_MENU_ITEMS_FAILURE'

export const ADD_FAVORITES_FOR_MENUS_COUNT = 'foodsby/favorite/ADD_FAVORITES_FOR_MENUS_COUNT'

export const addFavoriteStart = (menuItemId, menuId) => {
  return {
    payload: { menuId, menuItemId },
    type: ADD_FAVORITE,
  }
}

export const addFavoriteSuccess = favorite => {
  return {
    payload: { favorite },
    type: ADD_FAVORITE_SUCCESS,
  }
}

export const addFavoriteFailure = error => {
  return {
    error,
    type: ADD_FAVORITE_FAILURE,
  }
}

export const loadFavoriteStart = menuId => {
  return {
    payload: { menuId },
    type: LOAD_FAVORITE,
  }
}

export const loadFavoriteSuccess = favorite => {
  return {
    payload: favorite,
    type: LOAD_FAVORITE_SUCCESS,
  }
}

export const loadFavoriteFailure = error => {
  return {
    error,
    type: LOAD_FAVORITE_FAILURE,
  }
}

export const deleteFavoriteStart = menuItemId => {
  return {
    payload: { menuItemId },
    type: DELETE_FAVORITE,
  }
}

export const deleteFavoriteSuccess = response => {
  return {
    payload: { menuItemId: response.data },
    type: DELETE_FAVORITE_SUCCESS,
  }
}

export const deleteFavoriteFailure = error => {
  return {
    error,
    type: DELETE_FAVORITE_FAILURE,
  }
}

export const loadPopularItems = () => ({
  type: LOAD_POPULAR_ITEMS,
})

export const loadPopularItemsSuccess = response => ({
  payload: { response },
  type: LOAD_POPULAR_ITEMS_SUCCESS,
})

export const loadPopularItemsFailure = error => ({
  error,
  type: LOAD_POPULAR_ITEMS_FAILURE,
})

export const loadPopularMenuItems = () => ({
  type: LOAD_POPULAR_MENU_ITEMS,
})

export const loadPopularMenuItemsSuccess = response => ({
  payload: { response },
  type: LOAD_POPULAR_MENU_ITEMS_SUCCESS,
})

export const loadPopularMenuItemsFailure = error => ({
  error,
  type: LOAD_POPULAR_MENU_ITEMS_FAILURE,
})

export const addFavoritesForMenusStart = favoriteMenuIdsCount => ({
  payload: { favoriteMenuIdsCount },
  type: ADD_FAVORITES_FOR_MENUS_COUNT,
})

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [ADD_FAVORITE]: state => {
    return {
      ...state,
      isAddingFavorite: true,
    }
  },
  [ADD_FAVORITES_FOR_MENUS_COUNT]: (state, action) => {
    let favoriteMenus = action.payload.favoriteMenuIdsCount
    favoriteMenus = favoriteMenus.reduce((result, item) => {
      result[item.menuId] = item.count
      return result
    }, {})
    return {
      ...state,
      favoritesCountByMenuId: favoriteMenus,
    }
  },
  [ADD_FAVORITE_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      error,
      isAddingFavorite: false,
    }
  },
  [ADD_FAVORITE_SUCCESS]: (state, action) => {
    const { favorite } = action.payload
    const { favorites } = state
    return {
      ...state,
      favorites: { ...favorites, [favorite.menuItemId]: favorite },
      isAddingFavorite: false,
      newFavorites: action.payload.favorites,
    }
  },
  [DELETE_FAVORITE]: state => {
    return {
      ...state,
      isDeletingFavorite: true,
    }
  },
  [DELETE_FAVORITE_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      error,
      isDeletingFavorite: false,
    }
  },
  [DELETE_FAVORITE_SUCCESS]: (state, action) => {
    const { favorites } = state
    const {
      payload: { menuItemId },
    } = action
    return {
      ...state,
      favorites: { ...favorites, [menuItemId]: undefined },
      isDeletingFavorite: false,
    }
  },
  [LOAD_FAVORITE]: state => {
    return {
      ...state,
      isLoadingFavorites: true,
    }
  },
  [LOAD_FAVORITE_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      error,
      isLoadingFavorites: false,
    }
  },
  [LOAD_FAVORITE_SUCCESS]: (state, action) => {
    let favorites = action.payload
    favorites = favorites.reduce((result, item) => {
      result[item.menuItemId] = item
      return result
    }, {})
    return {
      ...state,
      favorites,
      isLoadingFavorites: false,
    }
  },
  [LOAD_HASHES]: state => {
    return {
      ...state,
      isLoadingHashes: true,
    }
  },
  [LOAD_POPULAR_ITEMS]: state => ({
    ...state,
    isLoadingPopularItems: true,
    popularItemsError: undefined,
  }),
  [LOAD_POPULAR_ITEMS_FAILURE]: (state, action) => ({
    ...state,
    isLoadingPopularItems: false,
    popularItemsError: action.error,
  }),
  [LOAD_POPULAR_ITEMS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingPopularItems: false,
    popularItemsError: undefined,
    popularMenuItems: action.payload.response,
  }),
  [LOAD_POPULAR_MENU_ITEMS]: state => ({
    ...state,
    isLoadingNewPopularMenuItems: true,
    popularItemsError: undefined,
  }),
  [LOAD_POPULAR_MENU_ITEMS_FAILURE]: (state, action) => ({
    ...state,
    error: action.error,
    newPopularMenuItems: undefined,
  }),
  [LOAD_POPULAR_MENU_ITEMS_FAILURE]: (state, action) => ({
    ...state,
    error: action.error,
    newPopularMenuItems: undefined,
  }),
  [LOAD_POPULAR_MENU_ITEMS_SUCCESS]: (state, action) => {
    return {
      ...state,
      isLoadingNewPopularMenuItems: false,
      newPopularMenuItems: action.payload.response.popularItems,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { favorites: {} }

export default function favorite(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* watchAddFavorite() {
  while (true) {
    try {
      const {
        payload: { menuId, menuItemId },
      } = yield take(ADD_FAVORITE)
      let menu
      if (!menuId) {
        menu = yield select(selectCurrentMenu)
      }
      const user = yield select(selectCurrentUser)
      const payloadInfo = {
        menuId: menuId || menu.menuId,
        menuItemId: menuItemId,
        userId: user.userId,
      }
      favoritesCache.reset()
      yield call(apiSaga, addFavorite, [payloadInfo], addFavoriteSuccess, addFavoriteFailure)
    } catch (error) {
      yield put(addFavoriteFailure(error))
    }
  }
}

export function* watchLoadFavorites() {
  while (true) {
    try {
      let menuId
      const { payload } = yield take(LOAD_FAVORITE)
      menuId = payload.menuId
      if (!menuId) {
        yield race([take(LOAD_MENU_SUCCESS), take(LOAD_MENU_FAILURE)])
        const menu = yield select(selectCurrentMenu)
        menuId = menu.menuId
      }

      const user = yield select(selectCurrentUser)
      if (menuId && user) {
        yield call(
          apiSaga,
          loadFavoritesForMenu,
          [menuId, user.userId],
          loadFavoriteSuccess,
          loadFavoriteFailure,
        )
      } else {
        yield put(loadFavoriteFailure())
      }
    } catch (error) {
      yield put(loadFavoriteFailure(error))
    }
  }
}

export function* watchDeleteFavorite() {
  while (true) {
    const {
      payload: { menuItemId },
    } = yield take(DELETE_FAVORITE)
    favoritesCache.reset()
    yield call(apiSaga, deleteFavorite, [menuItemId], deleteFavoriteSuccess, deleteFavoriteFailure)
  }
}

export function* watchLoadPopularItems() {
  while (true) {
    try {
      yield all([
        take(LOAD_POPULAR_ITEMS),
        race([take(LOAD_MENU_SUCCESS), take(LOAD_MENU_FAILURE)]),
      ])

      yield call(apiSaga, loadPopularItemsSuccess, loadPopularItemsFailure)
    } catch (ex) {
      yield put(loadPopularItemsFailure(ex))
    }
  }
}

export function* watchLoadPopularMenuItems() {
  while (true) {
    try {
      yield all([
        take(LOAD_POPULAR_MENU_ITEMS),
        race([take(LOAD_MENU_SUCCESS), take(LOAD_MENU_FAILURE)]),
      ])

      const menu = yield select(selectCurrentMenu)
      if (isNumber(menu?.menuId)) {
        yield call(
          apiSaga,
          popularMenuItems,
          [menu.menuId],
          loadPopularMenuItemsSuccess,
          loadPopularMenuItemsFailure,
        )
      } else {
        yield put(loadPopularMenuItemsFailure('LocationId or MenuId is undefined'))
      }
    } catch (ex) {
      yield put(loadPopularMenuItemsFailure(ex))
    }
  }
}

export const popularMenuItems = menuId =>
  api.get(`/api-monolith/menu/popular/${menuId}`, null, {
    cache: monolithCache,
  })

export const addFavorite = payloadInfo => api.post(`/api/v2/favorites`, payloadInfo)

export const loadFavoritesForMenu = menuId =>
  api.get(`/api/v2/favorites?menu=${menuId}`, null, { cache: favoritesCache })

export const getFavoritesForMenusCount = menuIds =>
  api.get(`/api/v2/favorites/count?${menuIds.map(m => `${`menus=${m}`}`).join('&')}`, null, {
    cache: favoritesCache,
  })

export const deleteFavorite = menuItemId =>
  api
    .delete(`/api/v2/favorites/${menuItemId}`)
    .then(response => ({ ...response, data: menuItemId }))

export const loadHashedOrders = menuItemId =>
  api.get(`/api-monolith/menu/item/${menuItemId}/configurations`, null, { cache: monolithCache })
