import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useHistory } from 'react-router'

import { Box, Dialog, DialogTitle, Divider, Tooltip, Typography } from '@material-ui/core'
import currency from 'currency.js'

import { CreditCardOutlined, InfoOutlined, People } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import { array, number, object } from 'yup'
import { isEmpty } from 'lodash'
import LayoutInnerPage, { HeaderInnerPage } from '../layouts/LayoutInnerPage'
import { formatUrl } from '../util/formatUtils'
import ListActionableDetails from '../components/common/ListActionableDetails'
import Captcha from '../components/common/Captcha'
import { teamPerksRoute, loginRoute } from '../routes/routes'

import { selectTeam, selectDefaultTeamPaymentMethod } from '../redux/selectors/teamSelectors'

import {
  formatMembersText,
  formatPaymentMethodText,
  getCheckoutStepDetails,
  getStepIconColor,
} from '../components/teams/perks/teamSubscriptionUtils'

import { NEW_PAYMENT_METHOD_ID } from '../util/paymentMethods'

import ButtonDialogClose from '../components/teams/common/ButtonDialogClose'
import StepProgramMembers from '../components/teams/perks/StepProgramMembers'
import StepAddPaymentMethod from '../components/teams/perks/StepAddPaymentMethod'

import { selectTeamMembers } from '../redux/selectors/teamMembersSelectors'

import { loadSubscriptionPriceStart, subscribeStart } from '../redux/modules/subscriptions'
import { loadTeamMembersStart } from '../redux/modules/teamMembers'
import { enqueueSnackbar } from '../redux/modules/snackbar'
import { loadTeamPaymentMethodsStart } from '../redux/modules/team'
import { formatCentsToPrettyDollars } from '../components/teams/perks/giftCardUtils'
import useTeamPaymentMethod from '../hooks/useTeamPaymentMethod'
import ButtonSubmit from '../components/common/ButtonSubmit'

const useStyles = makeStyles(theme => ({
  perkSummary: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.common.white,
    minWidth: '400px',
    height: '100%',
    marginRight: '32px',
    marginTop: '32px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: '250px',
      marginRight: '0px',
      marginTop: '32px',
    },
  },
  perkSteps: {
    minWidth: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 'auto',
    },
  },
  recurringPerkPageContainer: {
    flexFlow: 'row wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: theme.spacing(3),
    },
  },
}))

const PageTeamUnlimitedDelivery = ({
  isAuthenticated,
  team,
  teamMembers,
  isLoadingTeamMembers,
  loadTeamMembersStart,
  paymentMethods,
  isUpdatingDefaultPaymentMethod,
  loadTeamPaymentMethodsStart,
  isLoadingPaymentMethods,
  defaultPaymentMethod,
  loadSubscriptionPriceStart,
  newSubscriptionPrice,
  subscribeStart,
  isStartingSubscription,
  errorStartingSubscription,
  accountSubscriptionPrice,
}) => {
  const [dialogToShow, setDialogToShow] = useState(null)
  const confirmedTeamMembers = teamMembers.filter(member => member.status === 'CONFIRMED')
  const {
    submitting: isSubmittingPaymentMethod,
    error: errorPaymentMethods,
    handleSubmitTeamPaymentMethod,
  } = useTeamPaymentMethod({
    teamId: team.id,
    onSuccess: () => {},
  })

  const classes = useStyles()
  const history = useHistory()

  const handleItemClicked = perkOption => {
    setDialogToShow(perkOption)
  }

  const handleSubmit = values => {
    const path = formatUrl(teamPerksRoute.path, { accountId: team?.id })
    subscribeStart(
      team.id,
      { userIds: values.members, autoEnrollNewMembers: values.autoEnrollNewMembers },
      () => history.push(path),
    )
  }

  const handleClose = () => {
    setDialogToShow(null)
  }

  useEffect(() => {
    if (team) {
      loadTeamMembersStart(team.id)
    }
  }, [loadTeamMembersStart, team])

  useEffect(() => {
    if (paymentMethods?.length === 0 && team && isLoadingPaymentMethods) {
      loadTeamPaymentMethodsStart(team.id)
    }
  }, [paymentMethods, loadTeamPaymentMethodsStart, isLoadingPaymentMethods, team])

  if (!isAuthenticated) {
    return <Redirect to={loginRoute.path} />
  }

  const getNextStep = (values, ONE_TIME_PERK_OPTIONS) => {
    if (!values.members) {
      return ONE_TIME_PERK_OPTIONS[0].id
    } else if (!values.SelectedCard || values.SelectedCard < 0) {
      return ONE_TIME_PERK_OPTIONS[1].id
    }
  }

  const handleGetTotal = (newValues, previousValues) => {
    const params = { ...previousValues, ...newValues }
    if (params.members) {
      loadSubscriptionPriceStart(team.id, params.members?.length)
    }
  }

  const defaultSelectedCardForSubscriptionPrice = (
    defaultPaymentMethodId,
    accountSubscriptionPrice,
  ) => {
    if (
      (defaultPaymentMethodId !== undefined && accountSubscriptionPrice === 0) ||
      defaultPaymentMethodId === undefined
    ) {
      return NEW_PAYMENT_METHOD_ID
    }
    return defaultPaymentMethodId
  }

  const formatDetailRow = (title, value) => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      margin={3}
      marginLeft={0}
    >
      <Typography variant="body2">{title}</Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  )

  const mainSchema = object().shape({
    members: array().test('members', ' ', function() {
      return !!this.options.parent.members
    }),
    pricePerHeadInCentsYupValidation: number(),
    SelectedCard: number().when('pricePerHeadInCentsYupValidation', {
      is: pricePerHeadInCentsYupValidation => pricePerHeadInCentsYupValidation > 0,
      then: schema => schema.moreThan(0),
      otherwise: schema =>
        schema.test(
          'SelectedCard',
          'No card is needed due to $0 account subscription price',
          function(value) {
            return this.options.parent.pricePerHeadInCentsYupValidation === 0 && value === -1
          },
        ),
    }),
  })
  return (
    <LayoutInnerPage
      HeaderComponent={
        <HeaderInnerPage
          showBackButton
          backButtonRoute={formatUrl(teamPerksRoute.path, { accountId: team?.id })}
        >
          Add Unlimited Delivery
        </HeaderInnerPage>
      }
      MainComponent={
        <>
          {paymentMethods &&
            accountSubscriptionPrice !== undefined &&
            (defaultPaymentMethod || paymentMethods.length === 0) && (
              <Formik
                initialValues={{
                  members: null,
                  autoEnrollNewMembers: false,
                  SelectedCard: defaultSelectedCardForSubscriptionPrice(
                    defaultPaymentMethod?.id,
                    accountSubscriptionPrice,
                  ),
                  pricePerHeadInCentsYupValidation: accountSubscriptionPrice,
                }}
                onSubmit={handleSubmit}
                validationSchema={mainSchema}
              >
                {props => {
                  const { values, errors, submitCount } = props
                  const hasTriedSubmitting = submitCount > 0
                  const { title, validationSchema } = getCheckoutStepDetails(dialogToShow?.step)

                  const ONE_TIME_PERK_OPTIONS = [
                    {
                      id: 'MEMBERS',
                      step: 0,
                      title:
                        formatMembersText(values.members) ||
                        'Who would you like to give the perk to?',
                      secondaryText: 'Perk Members',
                      image: (
                        <People color={getStepIconColor(errors.members, hasTriedSubmitting)} />
                      ),
                      buttonText: 'EDIT',
                    },
                    accountSubscriptionPrice > 0 && {
                      id: 'PAYMENT_METHOD',
                      step: 1,
                      title:
                        formatPaymentMethodText(defaultPaymentMethod) || 'Default Payment Method',
                      secondaryText: !defaultPaymentMethod ? '' : 'Default Payment Method',

                      image: (
                        <CreditCardOutlined
                          color={getStepIconColor(errors.SelectedCard, hasTriedSubmitting)}
                        />
                      ),
                      buttonText: 'EDIT',
                    },
                  ]

                  return (
                    <Form>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        className={classes.recurringPerkPageContainer}
                      >
                        <Box marginRight={4} width={'50%'} className={classes.perkSteps}>
                          <Box marginTop={4}>
                            <ListActionableDetails
                              listItems={ONE_TIME_PERK_OPTIONS}
                              handleClick={handleItemClicked}
                              activeId={getNextStep(values, ONE_TIME_PERK_OPTIONS)}
                            />
                          </Box>
                          <Dialog open={!!dialogToShow} fullWidth maxWidth="sm">
                            <>
                              <DialogTitle>
                                {title}
                                <ButtonDialogClose onClick={() => handleClose()} />
                              </DialogTitle>
                              {dialogToShow?.id === ONE_TIME_PERK_OPTIONS[0].id && (
                                <StepProgramMembers
                                  teamMembers={confirmedTeamMembers}
                                  loading={isLoadingTeamMembers}
                                  handleClose={handleClose}
                                  handleGetTotal={newValues => handleGetTotal(newValues, values)}
                                  isUnlimitedDelivery
                                  {...props}
                                />
                              )}
                              {accountSubscriptionPrice > 0
                                ? dialogToShow?.id === ONE_TIME_PERK_OPTIONS[1].id && (
                                    <StepAddPaymentMethod
                                      team={team}
                                      paymentMethods={paymentMethods}
                                      paymentMethodsError={errorPaymentMethods}
                                      submitting={
                                        isSubmittingPaymentMethod || isUpdatingDefaultPaymentMethod
                                      }
                                      defaultPaymentMethod={defaultPaymentMethod}
                                      validationSchema={validationSchema}
                                      handleGetTotal={newValues =>
                                        handleGetTotal(newValues, values)
                                      }
                                      handleConfirm={values =>
                                        handleSubmitTeamPaymentMethod(values.SelectedCard)
                                      }
                                      handleClose={handleClose}
                                      {...props}
                                    />
                                  )
                                : null}
                            </>
                          </Dialog>
                        </Box>
                        <Box
                          width={'35%'}
                          padding={4}
                          minWidth="400px"
                          className={classes.perkSummary}
                        >
                          <Box marginBottom={4}>
                            <Typography variant="h6">Details</Typography>
                          </Box>
                          {formatDetailRow('Type', 'Unlimited Delivery')}
                          {newSubscriptionPrice &&
                            formatDetailRow(
                              'Amount',
                              `${formatCentsToPrettyDollars(
                                newSubscriptionPrice?.pricePerHeadInCents,
                              )} per member`,
                            )}

                          {(!!values.members?.length || values.members?.length === 0) &&
                            formatDetailRow('Members', values.members?.length)}
                          <Divider />
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            margin={3}
                            marginLeft={0}
                            marginBottom={0}
                          >
                            <Box display="flex">
                              <Typography variant="body1">Monthly Total</Typography>
                              <Box marginLeft={1}>
                                <Tooltip
                                  title={
                                    'Your credit card will be charged when the perk is purchased and at the beginning of each billing cycle.'
                                  }
                                >
                                  <InfoOutlined fontSize="small" />
                                </Tooltip>
                              </Box>
                            </Box>
                            <Typography>
                              {newSubscriptionPrice?.monthlyTotalInCents ? (
                                formatCentsToPrettyDollars(newSubscriptionPrice.monthlyTotalInCents)
                              ) : (
                                <Typography>
                                  {currency(Number(0), { formatWithSymbol: true }).format()}
                                </Typography>
                              )}
                            </Typography>
                          </Box>
                          <Box marginTop={3}>
                            <Captcha />
                          </Box>
                          <Box marginTop={3}>
                            <ButtonSubmit
                              color="primary"
                              width="100%"
                              variant="contained"
                              submitting={isStartingSubscription}
                            >
                              Purchase Perk
                            </ButtonSubmit>
                          </Box>
                          {errorStartingSubscription && (
                            <Box margin={2} marginTop={3} marginBottom={3}>
                              <Typography color="error" variant="caption">
                                {errorStartingSubscription}
                              </Typography>
                            </Box>
                          )}
                          {!isEmpty(errors) && hasTriedSubmitting && (
                            <Box margin={2} marginTop={3} marginBottom={3}>
                              <Typography color="error" variant="caption">
                                Please complete all steps before purchasing.
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Form>
                  )
                }}
              </Formik>
            )}
        </>
      }
    />
  )
}

const mapStateToProps = state => {
  const {
    isCreatingGiftCards,
    isValidatingDiscount,
    errorCreatingGiftCards,
    errorValidatingDiscount,
  } = state.teamGiftCards

  const {
    newSubscriptionPrice,
    isStartingSubscription,
    errorStartingSubscription,
    accountSubscriptionPrice,
  } = state.subscriptions

  const { paymentMethods, isUpdatingDefaultPaymentMethod, isLoadingPaymentMethods } = state.team

  return {
    isAuthenticated: state.user?.isAuthenticated,
    team: selectTeam(state),
    isLoadingTeamMembers: state.teamMembers.isLoadingTeamMembers,
    teamMembers: selectTeamMembers(state),
    isCreatingGiftCards,
    isValidatingDiscount,
    errorCreatingGiftCards,
    errorValidatingDiscount,
    paymentMethods,
    isUpdatingDefaultPaymentMethod,
    isLoadingPaymentMethods,
    defaultPaymentMethod: selectDefaultTeamPaymentMethod(state),
    newSubscriptionPrice,
    isStartingSubscription,
    errorStartingSubscription,
    accountSubscriptionPrice,
  }
}

const mapDispatchToProps = {
  loadTeamMembersStart,
  enqueueSnackbar,
  loadTeamPaymentMethodsStart,
  loadSubscriptionPriceStart,
  subscribeStart,
}

PageTeamUnlimitedDelivery.defaultProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PageTeamUnlimitedDelivery)
