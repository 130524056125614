import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { Form, Formik } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'
import { states } from 'us'
import { object, string } from 'yup'
import { Alert } from '@material-ui/lab'
import { useHubspotSubmitLead } from '../../hooks/useHubspotSubmitLead'
import ButtonSubmit from '../common/ButtonSubmit'
import { getAddressQueryStringParams } from '../../redux/modules/selectors'

const HUBSPOT_URL = process.env.REACT_APP_HUBSPOT_REQUEST_FOODSBY_FORM_WEBHOOK || ''

const STATES = Object.keys(states).map(state => ({
  label: state,
  value: state,
}))

const schema = object().shape({
  city: string().required(),
  country_code: string().required(),
  email: string()
    .email('Enter a valid email address.')
    .required('Email is required.'),
  first_name: string().required(),
  last_name: string().required(),
  lead_source: string().required(),
  phone: string().required(),
  state_code: string().required(),
  street: string().required(),
  zip: string().required(),
})

const RequestFoodsbyForm = () => {
  const { loading, submitForm, success, error } = useHubspotSubmitLead(HUBSPOT_URL)
  const addressParams = useSelector(state => getAddressQueryStringParams(state))

  if (success) {
    return (
      <Box alignItems="center" mt={3} display="flex" flexDirection="column" textAlign="center">
        <CheckCircle style={{ color: '#6e8d28', fontSize: '60px', marginBottom: '8px' }} />
        <Typography variant="h6">Your request has been received!</Typography>
      </Box>
    )
  }

  return (
    <Formik
      gtm-data-elm-id="LoginForm"
      initialValues={{
        city: addressParams?.city || '',
        country_code: 'US',
        email: '',
        first_name: '',
        last_name: '',
        lead_source: 'Foodsby Website',
        phone: '',
        state_code: addressParams?.state || '',
        street: addressParams?.street || '',
        zip: addressParams?.zip || '',
      }}
      onSubmit={submitForm}
      validationSchema={schema}
    >
      {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
        <Form>
          <TextField
            autoComplete="address-line1"
            error={touched.street && Boolean(errors.street)}
            fullWidth
            id="street"
            label="Street"
            margin="dense"
            name="street"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.street}
            variant="outlined"
            color="secondary"
          />
          <TextField
            autoComplete="address-level2"
            error={touched.city && Boolean(errors.city)}
            fullWidth
            id="city"
            label="City"
            margin="dense"
            name="city"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.city}
            variant="outlined"
            color="secondary"
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoComplete="address-level1"
                error={touched.state_code && Boolean(errors.state_code)}
                fullWidth
                id="state_code"
                label="State"
                margin="dense"
                name="state_code"
                onBlur={handleBlur}
                onChange={handleChange}
                select
                value={values.state_code}
                variant="outlined"
                color="secondary"
              >
                {STATES.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="postal-code"
                error={touched.zip && Boolean(errors.zip)}
                fullWidth
                id="zip"
                label="Zip"
                margin="dense"
                name="zip"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zip}
                variant="outlined"
                color="secondary"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoComplete="given-name"
                error={touched.first_name && Boolean(errors.first_name)}
                fullWidth
                id="first_name"
                label="First Name"
                margin="dense"
                name="first_name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.first_name}
                variant="outlined"
                color="secondary"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="family-name"
                error={touched.last_name && Boolean(errors.last_name)}
                fullWidth
                id="last_name"
                label="Last Name"
                margin="dense"
                name="last_name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.last_name}
                variant="outlined"
                color="secondary"
              />
            </Grid>
          </Grid>
          <TextField
            autoComplete="email"
            error={touched.email && Boolean(errors.email)}
            fullWidth
            id="email"
            label="Work email"
            margin="dense"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            color="secondary"
          />
          <TextField
            autoComplete="tel"
            error={touched.phone && Boolean(errors.phone)}
            fullWidth
            id="phone"
            label="Phone"
            margin="dense"
            name="phone"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.phone}
            variant="outlined"
            color="secondary"
          />
          <Box pt="12px">
            <ButtonSubmit
              color="primary"
              submitting={isSubmitting && loading}
              fullWidth
              variant="contained"
            >
              Request Foodsby
            </ButtonSubmit>
          </Box>
          {error && (
            <Alert style={{ marginTop: '4px' }} severity="error">
              {error}
            </Alert>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default RequestFoodsbyForm
