import { api } from '../api/api'

const SWITCHBOARD = '/api/v1/switchboard'
const ACHIEVEMENTS = '/api/v1/achievements'
const API_MONO = '/api-monolith'

export const getUserAttributes = () => api.get(`${SWITCHBOARD}/user-profiles`)

export const updateUserAttributes = userAttributes =>
  api.patch(`${SWITCHBOARD}/user-profiles`, userAttributes)

export const updateUserSmsEnrollmentAsked = userId =>
  api.post(`${SWITCHBOARD}/braze/sms-enrollment-asked/${userId}`)

export const getStoreTypeOptions = () => api.get(`${API_MONO}/v3/stores/store-type-options`)

export const sendDownloadMobileSms = phone =>
  api.post(`${ACHIEVEMENTS}/sms/send-mobile-app-message`, { phone })
