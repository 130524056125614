import { Box, Grid, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}))

export const DocsTextField = () => {
  const classes = useStyles()

  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid xs={12}>
          <Typography component="h2" gutterBottom variant="h2">
            Text Fields
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Paper>
            <Box padding={4}>
              <form autoComplete="off" className={classes.root} noValidate>
                <div>
                  <TextField
                    defaultValue="Hello World"
                    id="standard-required"
                    label="Required"
                    required
                  />
                  <TextField
                    defaultValue="Hello World"
                    disabled
                    id="standard-disabled"
                    label="Disabled"
                  />
                  <TextField
                    autoComplete="current-password"
                    id="standard-password-input"
                    label="Password"
                    type="password"
                  />
                  <TextField
                    defaultValue="Hello World"
                    id="standard-read-only-input"
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Read Only"
                  />
                  <TextField
                    id="standard-number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Number"
                    type="number"
                  />
                  <TextField id="standard-search" label="Search field" type="search" />
                  <TextField
                    defaultValue="Default Value"
                    helperText="Some important text"
                    id="standard-helperText"
                    label="Helper text"
                  />
                </div>
                <div>
                  <TextField
                    defaultValue="Hello World"
                    id="filled-required"
                    label="Required"
                    required
                    variant="filled"
                  />
                  <TextField
                    defaultValue="Hello World"
                    disabled
                    id="filled-disabled"
                    label="Disabled"
                    variant="filled"
                  />
                  <TextField
                    autoComplete="current-password"
                    id="filled-password-input"
                    label="Password"
                    type="password"
                    variant="filled"
                  />
                  <TextField
                    defaultValue="Hello World"
                    id="filled-read-only-input"
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Read Only"
                    variant="filled"
                  />
                  <TextField
                    id="filled-number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Number"
                    type="number"
                    variant="filled"
                  />
                  <TextField
                    id="filled-search"
                    label="Search field"
                    type="search"
                    variant="filled"
                  />
                  <TextField
                    defaultValue="Default Value"
                    helperText="Some important text"
                    id="filled-helperText"
                    label="Helper text"
                    variant="filled"
                  />
                </div>
                <div>
                  <TextField
                    defaultValue="Hello World"
                    id="outlined-required"
                    label="Required"
                    required
                    variant="outlined"
                    color="secondary"
                  />
                  <TextField
                    defaultValue="Hello World"
                    disabled
                    id="outlined-disabled"
                    label="Disabled"
                    variant="outlined"
                    color="secondary"
                  />
                  <TextField
                    autoComplete="current-password"
                    id="outlined-password-input"
                    label="Password"
                    type="password"
                    variant="outlined"
                    color="secondary"
                  />
                  <TextField
                    defaultValue="Hello World"
                    id="outlined-read-only-input"
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Read Only"
                    variant="outlined"
                    color="secondary"
                  />
                  <TextField
                    id="outlined-number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Number"
                    type="number"
                    variant="outlined"
                    color="secondary"
                  />
                  <TextField
                    id="outlined-search"
                    label="Search field"
                    type="search"
                    variant="outlined"
                    color="secondary"
                  />
                  <TextField
                    defaultValue="Default Value"
                    helperText="Some important text"
                    id="outlined-helperText"
                    label="Helper text"
                    variant="outlined"
                    color="secondary"
                  />
                </div>
              </form>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default DocsTextField
