import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import queryString from 'query-string'

import { enqueueSnackbar } from '../../../redux/modules/snackbar'
import { selectQuery } from '../../../redux/selectors/routerSelectors'
import RadioCardOption from '../perks/RadioCardOption'
import { selectUserTeam } from '../../../redux/selectors/userTeamSelectors'
import ButtonSubmit from '../../common/ButtonSubmit'
import ButtonDialogClose from '../common/ButtonDialogClose'
import { createTeamStart, loadClaimableTeamStart } from '../../../redux/modules/teamOnboarding'
import { hasTeam } from '../teamUtils'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      padding: theme.spacing(4),
    },
    '& .MuiDialogContent-root': {
      maxHeight: '500px',
    },
  },
  dialogAction: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  radioOptionLabel: {
    fontWeight: 500,
    fontSize: '20px',
  },
}))

const ErrorState = ({ title, description, onDismiss, onJoinOtherTeams }) => {
  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss}>Dismiss</Button>
        <Button variant="contained" color="primary" onClick={onJoinOtherTeams}>
          See teams
        </Button>
      </DialogActions>
    </>
  )
}

const LoadingState = () => {
  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Skeleton height={80} width="40%" />
      <Skeleton height={120} width="80%" />
    </Box>
  )
}

const DialogClaimTeam = ({
  companyId,
  claimableTeam,
  isLoadingClaimableTeam,
  errorLoadingClaimableTeam,
  isCreatingTeam,
  createTeamStart,
  loadClaimableTeamStart,
  push,
}) => {
  const classes = useStyles()

  const handleSubmit = () => {
    createTeamStart(claimableTeam.name, claimableTeam.companyId)
  }

  const handleClose = () => {
    push({ search: undefined })
  }

  const handleJoinOtherTeams = () => {
    push({ search: queryString.stringify({ joinTeam: 'true' }) })
  }

  useEffect(() => {
    loadClaimableTeamStart(companyId)
  }, [companyId, loadClaimableTeamStart])

  const isNotClaimed = claimableTeam?.claimed === false
  return (
    <Dialog open maxWidth="sm" fullWidth className={classes.root}>
      {!errorLoadingClaimableTeam && isNotClaimed && (
        <>
          <DialogTitle disableTypography>
            <Typography variant="h5" align="center" gutterBottom>
              {'Claim your team 🎉'}
            </Typography>
            <Typography variant="body1" align="center">
              As an admin of your team, you get instant access to unique perks and special offers!
            </Typography>
            <ButtonDialogClose onClick={handleClose} />
          </DialogTitle>
          <DialogContent>
            <RadioCardOption
              dense
              key={claimableTeam.companyId}
              value={claimableTeam.companyId}
              checked
            >
              <span className={classes.radioOptionLabel}>{claimableTeam.name}</span>
            </RadioCardOption>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <ButtonSubmit
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              submitting={isCreatingTeam}
            >
              Claim team
            </ButtonSubmit>
          </DialogActions>
        </>
      )}
      {!errorLoadingClaimableTeam && !isNotClaimed && !isLoadingClaimableTeam && (
        <ErrorState
          title={`${claimableTeam?.name} is already claimed.`}
          description="You can still either join a different team or create a new one."
          onDismiss={handleClose}
          onJoinOtherTeams={handleJoinOtherTeams}
        />
      )}
      {isLoadingClaimableTeam && <LoadingState />}
      {errorLoadingClaimableTeam && (
        <ErrorState
          title="Error occurred!"
          description="Something went wrong loading the team. Please try again later or contact support."
          onDismiss={handleClose}
          onJoinOtherTeams={handleJoinOtherTeams}
        />
      )}
    </Dialog>
  )
}

const DialogClaimTeamWrapper = ({
  isAuthenticated,
  isCurrentUserLoading,
  query,
  userTeam,
  push,
  enqueueSnackbar,
  ...rest
}) => {
  useEffect(() => {
    if (Boolean(query?.claimTeam) && !isAuthenticated && !isCurrentUserLoading) {
      push({ search: queryString.stringify({ ...query, authenticate: 'true' }) })
    }

    if (Boolean(query?.claimTeam) && hasTeam(userTeam)) {
      enqueueSnackbar({ message: 'Cannot claim team because you are already part of a team.' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, isAuthenticated, isCurrentUserLoading, push, query?.claimTeam, userTeam])

  const open = isAuthenticated && Boolean(query?.claimTeam) && !hasTeam(userTeam)
  return open ? <DialogClaimTeam companyId={query.claimTeam} push={push} {...rest} /> : null
}

const mapStateToProps = state => {
  const { isAuthenticated, isCurrentUserLoading } = state.user
  const query = selectQuery(state)
  const userTeam = selectUserTeam(state)
  const {
    claimableTeam,
    isLoadingClaimableTeam,
    errorLoadingClaimableTeam,
    isCreatingTeam,
  } = state.teamOnboarding

  return {
    isAuthenticated,
    isCurrentUserLoading,
    query,
    userTeam,
    claimableTeam,
    isLoadingClaimableTeam,
    errorLoadingClaimableTeam,
    isCreatingTeam,
  }
}

const mapDispatchToProps = {
  createTeamStart,
  loadClaimableTeamStart,
  push,
  enqueueSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogClaimTeamWrapper)
