import React from 'react'
import { Divider, ListItem, ListItemText, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { Link } from 'react-router-dom'
import getMenuSections from './getMenuSections'

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    paddingLeft: theme.spacing(4),
    color: theme.palette.grey[500],
  },
  listItemGutters: {
    paddingLeft: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const Title = ({ children }) => {
  const classes = useStyles()
  return (
    <Typography className={classes.sectionTitle} variant="caption" color="textSecondary">
      {children}
    </Typography>
  )
}

const ListItems = ({ items, onClose }) => {
  const classes = useStyles()
  return items.map((item, i) => (
    <ListItem
      key={i}
      dense
      button
      component={item.external ? 'a' : Link}
      to={!item.external ? item.href : undefined}
      href={item.external ? item.href : undefined}
      onClick={onClose}
      classes={{ gutters: classes.listItemGutters }}
      target={item.newTab ? '_blank' : undefined}
    >
      <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{item.copy}</ListItemText>&nbsp;
      {item.endAdornment && item.endAdornment}
    </ListItem>
  ))
}

const FlyoutLinks = ({
  currentUser,
  userTeam,
  isTeamsSupported,
  onClose,
  preferredOffices,
  isPreferredLocation,
}) => {
  const classes = useStyles()

  const menuSections = getMenuSections(
    currentUser,
    userTeam,
    isTeamsSupported,
    preferredOffices,
    isPreferredLocation,
  )
  return (
    <>
      {/* Ordering section */}
      <Title>{menuSections.orderingSection.title}</Title>
      <ListItems items={menuSections.orderingSection.items} onClose={onClose} />
      <Divider className={classes.divider} />

      {/* Account section */}
      <Title>{menuSections.accountSection.title}</Title>
      <ListItems items={menuSections.accountSection.items} onClose={onClose} />
      <Divider className={classes.divider} />

      {/* Offices section */}
      {menuSections.preferredOfficesSection && (
        <>
          <Title>{menuSections.preferredOfficesSection.title}</Title>
          <ListItems items={menuSections.preferredOfficesSection.items} onClose={onClose} />
          <Divider className={classes.divider} />
        </>
      )}

      {/* Team section */}
      {menuSections.teamSection && (
        <>
          <Title>{menuSections.teamSection.title}</Title>
          <ListItems items={menuSections.teamSection.items} onClose={onClose} />
          <Divider className={classes.divider} />
        </>
      )}

      {/* Admin section */}
      {menuSections.adminSection && (
        <>
          <Title>{menuSections.adminSection.title}</Title>
          <ListItems items={menuSections.adminSection.items} onClose={onClose} />
          <Divider className={classes.divider} />
        </>
      )}

      {/* Bottom section */}
      {menuSections.bottomSection && (
        <ListItems items={menuSections.bottomSection.items} onClose={onClose} />
      )}
    </>
  )
}

export default FlyoutLinks
