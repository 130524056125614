import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container, Grid, Hidden, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'
import { goBack, push } from 'connected-react-router'
import { ArrowBackIos } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    minHeight: 'calc(100vh - 217px)',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(5),
  },
  container: {
    marginTop: ({ headerExists }) => (headerExists ? theme.spacing(3) : 0),
  },
  buttonRoot: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    height: '50px',
    width: '50px',
    left: '0px',
    '@media only screen and (min-width: 1400px)': {
      left: '-70px',
    },
    '@media only screen and (max-width: 960px)': {
      display: 'none',
    },
  },
  buttonIcon: {
    color: theme.palette.common.black,
    transform: 'translateX(3px)',
    fontSize: '14px',
  },
  titleRoot: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  titleRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '0.5em',
    '@media only screen and (min-width: 1400px)': {
      marginLeft: '-3.5em',
    },
    '@media only screen and (max-width: 960px)': {
      display: 'none',
    },
  },
  title: ({ showBackButton }) => ({
    ...theme.typography.h5,
    fontWeight: 'normal',
    marginLeft: showBackButton ? theme.spacing(2) : 0,
    '@media only screen and (max-width: 960px)': {
      marginLeft: 0,
    },
  }),
  [theme.breakpoints.down('xs')]: {
    titleRow: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    sideElementRow: {
      marginTop: theme.spacing(2),
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}))

/**
 * This component is not a replacement for other layout components (e.g. LayoutPrivate) as it does not include the navbar and footer.
 * Rather it is to be used as child of those layout components.
 * Ideally we should consider consolidating these, but for now this is a good way of reusing the inner layout logic across our pages.
 */
const LayoutInnerPage = ({
  HeaderComponent,
  MainComponent,
  SidebarComponent,
  containerMaxWidth,
  hideSidebar,
}) => {
  const headerExists = Boolean(HeaderComponent)
  const sidebarExists = Boolean(SidebarComponent)

  const classes = useStyles({ headerExists })
  return (
    <div className={classes.root}>
      {headerExists && HeaderComponent}
      <Container maxWidth={containerMaxWidth} className={classes.container}>
        <Grid container justifyContent="space-between" spacing={4}>
          <Grid item md={sidebarExists ? 8 : 12} xs={12}>
            {MainComponent}
          </Grid>
          {sidebarExists && (
            <Hidden xsDown={hideSidebar === 'xs'} smDown={hideSidebar === 'sm'}>
              <Grid item md={4} xs={12}>
                {SidebarComponent}
              </Grid>
            </Hidden>
          )}
        </Grid>
      </Container>
    </div>
  )
}

LayoutInnerPage.propTypes = {
  HeaderComponent: PropTypes.element,
  MainComponent: PropTypes.element.isRequired,
  SidebarComponent: PropTypes.element,
  containerMaxWidth: PropTypes.oneOf([false, 'xs', 'sm', 'md', 'lg', 'xl']),
  hideSidebar: PropTypes.oneOf([false, 'xs', 'sm']),
}

LayoutInnerPage.defaultProps = {
  HeaderComponent: undefined,
  SidebarComponent: undefined,
  containerMaxWidth: 'lg',
  hideSidebar: false,
}

export const HeaderInnerPage = ({
  children,
  showBackButton,
  backButtonRoute,
  maxWidth,
  sideElement,
}) => {
  const classes = useStyles({ showBackButton })
  return (
    <Container className={classes.titleRoot} maxWidth={maxWidth}>
      {showBackButton && <ButtonBack backRoute={backButtonRoute} />}
      <Box className={classes.titleRow}>
        <Typography component="h1" className={classes.title}>
          {children}
        </Typography>
        {sideElement && <Box className={classes.sideElementRow}>{sideElement}</Box>}
      </Box>
    </Container>
  )
}

HeaderInnerPage.propTypes = {
  children: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool,
  backButtonRoute: PropTypes.string,
  maxWidth: PropTypes.oneOf([false, 'xs', 'sm', 'md', 'lg', 'xl']),
}

HeaderInnerPage.defaultProps = {
  showBackButton: false,
  backButtonRoute: undefined,
  maxWidth: 'lg',
}

const ButtonBack = ({ backRoute }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClick = () => {
    if (backRoute) {
      dispatch(push(backRoute))
    } else {
      dispatch(goBack())
    }
  }

  return (
    <IconButton className={classes.buttonRoot} onClick={handleClick} data-testid="back-button">
      <ArrowBackIos className={classes.buttonIcon} />
    </IconButton>
  )
}

export default LayoutInnerPage
