import { ROLE_FOODSBY_ADMIN } from '../../../redux/modules/user'
import { teamRoute } from '../../../routes/routes'
import { formatUrl } from '../../../util/formatUtils'
import menuItems from './menuItems'

const isAdmin = (authorities = []) => authorities.includes(ROLE_FOODSBY_ADMIN)
const isTeamManager = userTeam => userTeam?.team?.manager
const hasTeam = userTeam => Boolean(userTeam?.team)
const isOfficeManager = office => office.isManager

const createPreferredOfficeItems = preferredOffices => {
  return preferredOffices.map(preferredOffice => {
    return {
      copy: preferredOffice.name,
      href: formatUrl(teamRoute.path, { accountId: `${preferredOffice.id}` }),
    }
  })
}

const getMenuSections = (
  currentUser,
  userTeam,
  isTeamsSupported,
  preferredOffices,
  isPreferredLocation,
) => {
  const formatPath = path => {
    return formatUrl(path, {
      accountId: `${userTeam?.team?.accountId}`,
    })
  }

  let menuSections = {
    orderingSection: {
      title: 'Ordering',
      items: menuItems.orderingMenu,
    },
    accountSection: {
      title: 'Account',
      items: menuItems.accountMenu,
    },
  }
  if (
    preferredOffices.length > 0 &&
    isPreferredLocation &&
    (preferredOffices.some(office => isOfficeManager(office)) || isAdmin(currentUser.authorities))
  ) {
    const officesManagedByUser = preferredOffices.filter(office => isOfficeManager(office))
    menuSections = {
      ...menuSections,
      preferredOfficesSection: {
        title: 'Offices',
        items: createPreferredOfficeItems(
          isAdmin(currentUser.authorities) ? preferredOffices : officesManagedByUser,
        ),
      },
    }
  }

  const showTeam = hasTeam(userTeam) && isTeamManager(userTeam)
  if (isTeamsSupported && showTeam) {
    menuSections = {
      ...menuSections,
      teamSection: {
        title: 'Teams',
        items: [{ copy: userTeam.team.name, href: formatPath(teamRoute.path) }],
      },
    }
  }

  if (isAdmin(currentUser.authorities)) {
    menuSections = {
      ...menuSections,
      adminSection: {
        title: 'Admin',
        items: menuItems.adminMenu,
      },
    }
  }

  menuSections = { ...menuSections, bottomSection: { title: '', items: menuItems.logout } }

  return menuSections
}

export default getMenuSections
