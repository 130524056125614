import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import queryString from 'query-string'
import { Box, Button, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { SpinnerCard } from '../common/Layout'
import { SIGNUP_LOGIN_TABS } from '../login/DialogLoginOrJoin'

const useStyles = makeStyles({
  containerGreenBorder: {
    border: '6px solid #6E8D28',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingBottom: '10px',
  },
  container: {
    marginTop: '50px',
    paddingBottom: '20px',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    fontWeight: 700,
    fontSize: '24px',
  },
  button: {
    marginTop: '15px',
  },
  unqualified: {
    color: 'blue',
  },
})

export const UnlockPreferredMessage = ({ isAuthenticated }) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const handleClick = () => {
    if (isAuthenticated) {
      dispatch(push({ search: queryString.stringify({ requestPreferred: 'true' }) }))
    } else {
      dispatch(
        push({
          search: queryString.stringify({
            authenticate: 'true',
            authenticateTab: SIGNUP_LOGIN_TABS.SIGNUP,
          }),
        }),
      )
    }
  }

  return (
    <SpinnerCard className={classes.containerGreenBorder}>
      <Box className={classes.box}>
        <Typography className={classes.title}>Unlock Foodsby Preferred!</Typography>
        <Typography>
          Upgrade to a Preferred location and enjoy more restaurant options with no delivery fees or
          order minimums.
        </Typography>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          Request Access
        </Button>
      </Box>
    </SpinnerCard>
  )
}

export const UnqualifiedLocationMessage = () => {
  const classes = useStyles()
  return (
    <SpinnerCard className={classes.container}>
      <Box className={classes.box}>
        <Typography className={classes.title}>Service Currently Unavailable</Typography>
        <Typography style={{ maxWidth: '70%' }}>
          <span>
            Sorry, we don&apos;t offer service at this location. Feel free to email
            support@foodsby.com with any questions.
          </span>
        </Typography>
      </Box>
    </SpinnerCard>
  )
}

export const UpcomingLaunchMessage = ({ isAuthenticated, activationDate }) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(
      push({
        search: queryString.stringify({
          authenticate: 'true',
          authenticateTab: SIGNUP_LOGIN_TABS.SIGNUP,
        }),
      }),
    )
  }

  const formattedDate = format(activationDate, 'dddd, MMMM D')
  return (
    <SpinnerCard className={classes.containerGreenBorder}>
      <Box className={classes.box}>
        <Typography className={classes.title}>Service Coming Soon!</Typography>
        <Typography style={{ maxWidth: '70%', marginBottom: '10px' }}>
          <span>
            Great news! You&apos;ll be able to start ordering from Foodsby on {formattedDate}. Sign
            up now to get delivery reminders.
          </span>
        </Typography>
        {!isAuthenticated && (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Get Delivery Reminders
          </Button>
        )}
      </Box>
    </SpinnerCard>
  )
}
