import { themeConWeb } from '@app/util/modernThemeConweb'
import { Box, Checkbox, FormControlLabel, FormGroup, FormHelperText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import currency from 'currency.js'
import React from 'react'
import styled from 'styled-components'

/**
 *  This is a one off component and should be used only after vetting with UX
 *  it is not recommend this variant be used outside for the customization modal
 */
const FormHelperTextCompact = withStyles({
  root: {
    marginLeft: 32,
    marginTop: -8,
  },
})(FormHelperText)

const MenuCheckboxAnswers = ({ answers, input, meta: { error }, onAnswerChange }) => {
  const { onBlur, onChange } = input

  const handleChange = (event, onAnswerChange) => {
    const valueArr = [...input.value]
    onAnswerChange({
      answerId: event.target.value,
      selected: event.target.checked,
    })
    if (event.target.checked) {
      valueArr.push(event.target.value)
    } else {
      valueArr.splice(valueArr.indexOf(event.target.value), 1)
    }
    onBlur(valueArr)
    return onChange(valueArr)
  }

  return (
    <>
      <ul style={{ width: '100%' }}>
        <FormGroup>
          <Box display="flex" flexWrap="wrap" paddingRight="16px">
            {answers.map(answer => (
              <MenuInput key={answer.id}>
                <FormControlLabel
                  checked={input.value.includes(`${answer.id}`)}
                  control={<Checkbox color="primary" />}
                  data-testid={`answer-${answer.id}`}
                  label={answer.text}
                  onChange={event => handleChange(event, onAnswerChange)}
                  value={answer.id}
                />
                {answer.price > 0 && (
                  <FormHelperTextCompact>
                    +{' '}
                    {currency(answer.price, {
                      formatWithSymbol: true,
                      precision: 2,
                    }).format()}
                  </FormHelperTextCompact>
                )}
              </MenuInput>
            ))}
          </Box>
        </FormGroup>
      </ul>
    </>
  )
}

MenuCheckboxAnswers.defaultProps = {
  meta: {
    error: false,
    touched: false,
  },
}

const MenuInput = styled.li`
  @media ${themeConWeb.small} {
    width: 100%;
  }
  @media ${themeConWeb.medium} {
    width: 50%;
  }
  margin-bottom: calc(${themeConWeb.baseUnit} * 4);
  width: 33%;
`
export default MenuCheckboxAnswers
