export const SET_WEBP_SUPPORTED = 'foodsby/browser/SET_WEBP_SUPPORTED'

export const setWebpSupported = supported => {
  return {
    payload: supported,
    type: SET_WEBP_SUPPORTED,
  }
}

const ACTION_HANDLERS = {
  [SET_WEBP_SUPPORTED]: (state, action) => {
    const isWebpSupported = action.payload
    return {
      ...state,
      isWebpSupported,
    }
  },
}

const initialState = {
  isWebpSupported: false,
}

export default function browser(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
