import React from 'react'
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  TextField,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { object, array, string } from 'yup'

import LinkButton from '../../common/LinkButton'
import ButtonSubmit from '../../common/ButtonSubmit'
import ButtonDialogClose from '../common/ButtonDialogClose'
import InputMemberEmails from './InputMemberEmails'
import AlertMemberInvitePrivacy from './AlertMemberInvitePrivacy'

export const TITLE = 'Invite via email'

const validationSchema = object().shape({
  memberEmails: array()
    .required('Please enter at least one email address.')
    .of(string().email("At least one of the emails you've entered is invalid.")),
})

// TODO Should we limit the number of members that can be added via email at once?
// We probably should, but check with the team.
const InviteByEmail = ({ onSubmit, onClose, submitting }) => {
  const showFeature = false // Flag to hide features that are not included in the MVP
  return (
    <Formik
      initialValues={{ memberEmails: [] }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, errors, handleChange, setFieldValue }) => (
        <Form>
          <Paper>
            <Box paddingBottom={2}>
              <DialogTitle>
                {TITLE}
                <ButtonDialogClose onClick={onClose} />
              </DialogTitle>
              <DialogContent>
                {showFeature && (
                  <Box display="flex" justifyContent="flex-end" marginBottom={2}>
                    <LinkButton color="primary">Preview Email</LinkButton>
                  </Box>
                )}
                <Box marginBottom={4}>
                  <InputMemberEmails
                    name="memberEmails"
                    fieldValue={values.memberEmails}
                    setFieldValue={setFieldValue}
                    error={errors.memberEmails}
                  />
                </Box>
                {showFeature && (
                  <TextField
                    name="memberRole"
                    fullWidth
                    select
                    variant="outlined"
                    value={values.memberRole}
                    onChange={handleChange}
                  >
                    <MenuItem value="member">Member</MenuItem>
                    <MenuItem value="admin">Administrator</MenuItem>
                  </TextField>
                )}
              </DialogContent>
              <DialogActions>
                <ButtonSubmit submitting={submitting} variant="contained" color="primary">
                  Invite members
                </ButtonSubmit>
              </DialogActions>
            </Box>
            <AlertMemberInvitePrivacy />
          </Paper>
        </Form>
      )}
    </Formik>
  )
}

export default InviteByEmail
