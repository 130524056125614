import React, { useEffect } from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Form, Formik } from 'formik'
import { string, object } from 'yup'
import { Alert } from '@material-ui/lab'
import queryString from 'query-string'
import { push } from 'connected-react-router'

import { selectQuery } from '../../../redux/selectors/routerSelectors'
import { selectUserTeam } from '../../../redux/selectors/userTeamSelectors'
import { hasTeam } from '../teamUtils'
import ButtonSubmit from '../../common/ButtonSubmit'
import { createTeamStart } from '../../../redux/modules/teamOnboarding'
import ButtonDialogClose from '../common/ButtonDialogClose'

const schema = object().shape({
  teamName: string().required('Please enter a team name.'),
})

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      padding: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
      },
      textAlign: 'center',
    },

    '& .MuiDialogContent-root': {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
    },
  },
  dialogAction: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
}))

const DialogCreateTeam = ({ isCreatingTeam, errorCreatingTeam, createTeamStart, push }) => {
  const classes = useStyles()

  const handleSubmit = values => {
    createTeamStart(values.teamName)
  }

  const handleBlurTeamName = (event, error, handleBlurField) => {
    handleBlurField(event)
  }

  const handleClose = () => {
    push({ search: queryString.stringify({ submitTeam: undefined }) })
  }

  return (
    <Dialog open maxWidth="sm" fullWidth className={classes.root}>
      <DialogTitle disableTypography>
        <Typography variant="h5" gutterBottom>
          Set up your team
        </Typography>
        <Typography variant="body1">
          This will be the name of your Foodsby team account. Choose something that your team will
          recognize (e.g., ACME Marketing).
        </Typography>
        <ButtonDialogClose onClick={handleClose} />
      </DialogTitle>
      <Formik initialValues={{ teamName: '' }} onSubmit={handleSubmit} validationSchema={schema}>
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <DialogContent>
              <TextField
                autoFocus
                fullWidth
                margin="dense"
                name="teamName"
                label="Team name"
                placeholder="Pedro’s Pals"
                variant="outlined"
                value={values.teamName}
                onChange={handleChange}
                onBlur={e => handleBlurTeamName(e, errors.teamName, handleBlur)}
                error={touched.teamName && Boolean(errors.teamName)}
                helperText={touched.teamName && errors.teamName}
              />
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
              <ButtonSubmit variant="contained" color="primary" submitting={isCreatingTeam}>
                Create team
              </ButtonSubmit>
            </DialogActions>
            {errors.teamId && (
              <Typography align="center" color="error">
                {errors.teamId}
              </Typography>
            )}
          </Form>
        )}
      </Formik>
      {errorCreatingTeam && (
        <Box mt={3}>
          <Alert severity="error">{errorCreatingTeam}</Alert>
        </Box>
      )}
    </Dialog>
  )
}

const DialogCreateTeamWrapper = ({
  isAuthenticated,
  isCurrentUserLoading,
  query,
  userTeam,
  push,
  ...rest
}) => {
  useEffect(() => {
    if (Boolean(query?.submitTeam) && !isAuthenticated && !isCurrentUserLoading) {
      push({ search: queryString.stringify({ ...query, authenticate: 'true' }) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isCurrentUserLoading, push, query?.submitTeam])

  const open = isAuthenticated && Boolean(query?.submitTeam) && !hasTeam(userTeam)
  return open ? <DialogCreateTeam push={push} {...rest} /> : null
}

const mapStateToProps = state => {
  const { isAuthenticated, isCurrentUserLoading } = state.user
  const query = selectQuery(state)
  const userTeam = selectUserTeam(state)
  const { isCreatingTeam, errorCreatingTeam } = state.teamOnboarding

  return {
    isAuthenticated,
    isCurrentUserLoading,
    query,
    userTeam,
    isCreatingTeam,
    errorCreatingTeam,
  }
}

const mapDispatchToProps = {
  createTeamStart,
  push,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogCreateTeamWrapper)
