import { SUBMIT_SUCCESS } from './ticket'

export const contactUsReducerPlugin = (state, action) => {
  switch (action.type) {
    case SUBMIT_SUCCESS:
      return undefined // wipe out the form data when a ticket is successfully submitted
    default:
      return state
  }
}
