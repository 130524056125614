import currency from 'currency.js'
import React from 'react'
import { connect } from 'react-redux'

import { acknowledgeReferral } from '../../redux/modules/friends'
import { SuccessModal } from '../success-modal/SuccessModal'

const ReferralSuccessNotification = props => {
  const { acknowledgeReferral, redemptionResult, referralSuccess } = props
  const value = redemptionResult?.reward
    ? currency(redemptionResult?.reward / 100, {
        formatWithSymbol: true,
      }).format()
    : null
  const isDailyReward = redemptionResult?.type === 'DAILY'

  const message =
    isDailyReward && value
      ? `🎉 Your ${value} credit will be awarded when your order is delivered.`
      : '🎉 Your credit will be available at checkout!'
  const title = ''

  return referralSuccess ? (
    <SuccessModal message={message} onClose={acknowledgeReferral} title={title} />
  ) : null
}

const mapStateToProps = state => {
  const { redemptionResult, referralSuccess } = state.friends
  return { redemptionResult, referralSuccess }
}

const mapDispatchToProps = { acknowledgeReferral }

export default connect(mapStateToProps, mapDispatchToProps)(ReferralSuccessNotification)
