import { Box, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { Field } from 'redux-form'

import { maxSelect, minSelect, valueRequired } from '../../util/validators'
import { LOCAL_STORAGE_ERROR } from '../../util/webStorageUtils'
import MenuCheckboxAnswers from './MenuCheckboxAnswers'
import MenuRadioAnswers from './MenuRadioAnswers'
import PersonalizeOrderQuestionRow from './PersonalizeOrderQuestionRow'
import SelectQuantity from './SelectQuantity'

export const formName = 'personalize'
export const questionPrefix = 'q_'

const makeQuestionText = question => {
  const questionText = question.text
  if (question.minimumSelection === 1 && question.maximumSelection === 1) {
    return `${questionText} (Required)`
  } else if (question.maximumSelection > 1 && !question.minimumSelection >= 1) {
    return `${questionText} (Choose up to ${question.maximumSelection})`
  } else if (question.maximumSelection > 1 && question.minimumSelection >= 1) {
    return `${questionText} (Required Choose up to ${question.maximumSelection})`
  }

  return questionText
}

const quantityOptionLabel = quantity => {
  return quantity === 1 ? '1 item' : `${quantity} items`
}

const makeQuantityOptions = amount => {
  var quantityOptions = []
  for (var i = 1; i <= amount; i++) {
    quantityOptions.push({
      value: i,
      label: quantityOptionLabel(i),
    })
  }

  return quantityOptions
}

const PersonalizeOrderForm = ({
  answers,
  displayedQuestions, // The post personalized price of the current in-checkout cart
  handleSubmit,
  onCheckboxChange,
  onRadioChange,
  onSubmit,
  personalizeFormError,
  enableQuantity,
  onQuantityChange,
}) => {
  const quantityOptions = makeQuantityOptions(99)
  return (
    <form>
      {enableQuantity && (
        <PersonalizeOrderQuestionRow key={'quantity'} questionName={'Quantity'} isExpanded={true}>
          <Field
            label={'Quantity'}
            component={SelectQuantity}
            name={`quantity`}
            options={quantityOptions}
            onChange={onQuantityChange}
          />
        </PersonalizeOrderQuestionRow>
      )}
      {displayedQuestions.map(function(question) {
        const firstLevelAnswers = question.answers.map(a => answers[a])

        let isRadio = question.minimumSelection === 1 && question.maximumSelection === 1
        // is expanded the selection is required has an optional amount up to or is sub menu
        let isExpanded =
          question.minimumSelection > 0 || question.maximumSelection > 1 || question.depth >= 2

        return (
          <PersonalizeOrderQuestionRow
            key={question.id}
            questionName={makeQuestionText(question)}
            isExpanded={isExpanded}
          >
            {question.description && <p>{question.description}</p>}
            {isRadio ? (
              <Field
                answers={firstLevelAnswers}
                component={MenuRadioAnswers}
                name={`${questionPrefix}${question.id}`}
                normalize={value => Number(value)}
                onAnswerChange={onRadioChange}
                question={question}
                questionId={question.id}
                validate={question.minimumSelection === 1 ? valueRequired : null}
              />
            ) : (
              <Field
                answers={firstLevelAnswers}
                component={MenuCheckboxAnswers}
                name={`${questionPrefix}${question.id}`}
                onAnswerChange={onCheckboxChange}
                validate={[
                  maxSelect(question.maximumSelection),
                  minSelect(question.minimumSelection),
                ]}
              />
            )}
          </PersonalizeOrderQuestionRow>
        )
      })}
      <Box marginTop={4}>
        <Field
          aria-label="special instructions"
          color="secondary"
          component={renderTextField}
          fullWidth={true}
          id="specialInstructions"
          label="Special Instructions"
          multiline
          name="specialInstructions"
          placeholder="e.g. Dressing on the side"
          rows="5"
          rowsMax="5"
          variant="outlined"
        />
      </Box>
      <button
        id="add-to-checkout"
        onClick={handleSubmit(values => onSubmit(values, false))}
        style={{ visibility: 'hidden' }}
        type="submit"
      ></button>
      <button
        id="move-to-checkout"
        onClick={handleSubmit(values => onSubmit(values, true))}
        style={{ visibility: 'hidden' }}
        type="submit"
      ></button>
      <div className="row middle-xs">
        <div className="col-xs-12 center-xs">
          {/* @jacob Looks like you touched this last. I don't believe this is doing anything can you take a look? */}
          {personalizeFormError && (
            <Typography color="error" variant="body1">
              {personalizeFormError.message.includes('QuotaExceeded')
                ? LOCAL_STORAGE_ERROR
                : personalizeFormError.message}
            </Typography>
          )}
        </div>
      </div>
    </form>
  )
}

/**
 * https://redux-form.com/7.3.0/examples/material-ui/
 * Needed to create a wrapper component for redux forms check docs for details
 */
const renderTextField = ({ input, label, meta: { error, touched }, ...custom }) => (
  <TextField label={label} {...input} {...custom} />
)

export default PersonalizeOrderForm
