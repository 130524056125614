import React from 'react'
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { themeConWeb } from '@app/util/modernThemeConweb'
import { formatCentsToPrettyDollars } from '../perks/giftCardUtils'
import ButtonDialogClose from '../common/ButtonDialogClose'
import LinkButton from '../../common/LinkButton'
import { getGiftTransactionTotal } from './adminUtils'
import TableGiftRecipients from './TableGiftRecipients'

const useStyles = makeStyles({
  label: {
    fontWeight: 500,
    fontSize: '16px',
  },
  value: {
    fontSize: '16px',
  },
})

const Row = ({ label, value }) => {
  const classes = useStyles()
  return (
    <Box display="flex" justifyContent="space-between" marginBottom={3}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </Box>
  )
}

const DialogGiftTransactionDetails = ({ giftTransactionDetails: { details }, onClose }) => {
  const classes = useStyles()
  const showReceiptFeature = false
  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>
        Gift details <ButtonDialogClose onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Row label="Occasion" value={details.reason} />
        <Row label="Amount" value={formatCentsToPrettyDollars(details.singleGiftAmount)} />
        <Row label="Quantity" value={details.numberOfGifts} />
        {details.discountAmountInPennies > 0 && (
          <Row
            label="Discount"
            value={formatCentsToPrettyDollars(details.discountAmountInPennies)}
          />
        )}
        <Row label="Total" value={getGiftTransactionTotal(details)} />

        <Box marginY={4}>
          <Typography className={classes.label} gutterBottom>
            Personalized message
          </Typography>
          <Typography className={classes.value}>{details.message}</Typography>
        </Box>

        <Typography className={classes.label} gutterBottom>
          Recipients
        </Typography>
        <TableGiftRecipients />

        <Box marginY={4} textAlign="center">
          {showReceiptFeature && (
            <LinkButton color={themeConWeb.color.brandPrimary}>Print Receipt</LinkButton>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DialogGiftTransactionDetails
