import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { isNil } from 'lodash'

import { LocationLogo } from './Location'
import RestaurantHoursAndNotice from './RestaurantHoursAndNotice'

const RestaurantDetails = ({
  logoLink,
  name,
  loading,
  isCatering,
  isReserve,
  operationalHoursStart,
  operationalHoursEnd,
  operationalDaysOfWeek,
  cateringLeadTime,
}) => {
  if (isNil(name) || loading) {
    return <SkeletonLoading />
  }

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <LocationLogo logoAlt={name} logoLink={logoLink} />
        <Typography
          component="h2"
          variant="h6"
          style={{ fontSize: '32px', marginLeft: '10px', fontWeight: 500 }}
        >
          {name}
        </Typography>
      </Box>
      {(isCatering || isReserve) && (
        <RestaurantHoursAndNotice
          operationalHoursStart={operationalHoursStart}
          operationalHoursEnd={operationalHoursEnd}
          operationalDaysOfWeek={operationalDaysOfWeek}
          deliveryLeadTime={cateringLeadTime}
          isCatering={isCatering}
        />
      )}
    </>
  )
}

const SkeletonLoading = () => {
  return (
    <Box alignItems="center" display="flex" marginBottom={3}>
      <Skeleton height={150} width={150} />
      <Skeleton height={50} width={300} style={{ fontSize: '40px', marginLeft: '10px' }} />
    </Box>
  )
}

export default RestaurantDetails
