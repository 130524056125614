import { Box, Divider, Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import { themeConWeb } from '@app/util/modernThemeConweb'
import { makeStyles } from '@material-ui/core/styles'
import { formatCentsToDollars } from '../teams/perks/giftCardUtils'
import { parseLeadTimeInHours } from '../../util/cateringUtils'
import { createReserveRewardsHoverMessage } from '../../util/formatUtils'

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: 'black',
    fontWeight: 400,
    padding: '8px',
    '& p, & a': {
      color: '#fff !important',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  expectedAverageOrders: {
    color: themeConWeb.color.greyShade8,
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '20px',
    alignContent: 'center',
    paddingLeft: '2px',
    paddingTop: '5px',
  },
}))

const ReserveRestaurantDeliveryDetail = ({
  leadTime,
  deliveryFeeInCents,
  averageOrders,
  deliveryRewardsTiers,
}) => {
  const classes = useStyles()
  const leadTimeInHours = parseLeadTimeInHours(leadTime)

  return (
    <>
      <div className="-leadTime">
        <div>
          <Typography className="-cardDetailTitle" variant="body2">
            Delivery notice
          </Typography>
          <p className="-cardDetail">
            <span style={{ fontWeight: 500 }}>{leadTimeInHours}</span>
            <span style={{ fontSize: themeConWeb.fontSizes.base }}>
              {' '}
              hour{leadTimeInHours > 1 && 's'}
            </span>
          </p>
        </div>
      </div>
      <Divider className="-divider" flexItem light orientation="vertical" />
      <div className="-moneyMinimum">
        <div>
          <Box display={'flex'} alignItems={'center'}>
            <Typography className="-cardDetailTitle" variant="body2">
              Minimum
            </Typography>
          </Box>
          <p className="-cardDetail">
            <span style={{ fontWeight: 500 }}>{formatCentsToDollars(0)}</span>
          </p>
        </div>
      </div>
      <Divider className="-divider" flexItem light orientation="vertical" />
      <div className="-deliveryFee">
        <div>
          <Box display={'flex'} alignItems={'center'}>
            <Typography className="-cardDetailTitle" variant="body2">
              Delivery fee
            </Typography>
          </Box>
          <p className="-cardDetail">
            <span style={{ fontWeight: 500 }}>{formatCentsToDollars(deliveryFeeInCents)}</span>
          </p>
        </div>
      </div>
      <Divider className="-divider" flexItem light orientation="vertical" />
      <div className="-deliveryFee">
        <div>
          <Box display={'flex'} alignItems={'center'}>
            <Typography className="-cardDetailTitle" variant="body2">
              Est. Orders
            </Typography>
          </Box>
          <Tooltip
            title={createReserveRewardsHoverMessage(averageOrders, deliveryRewardsTiers)}
            classes={{ tooltip: classes.customTooltip }}
          >
            <p className={classes.expectedAverageOrders}>
              <b>{averageOrders}</b> avg
            </p>
          </Tooltip>
        </div>
      </div>
    </>
  )
}

export default ReserveRestaurantDeliveryDetail
