import React from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { withStyles } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles'

import { themeConWeb } from '../../util/modernThemeConweb'
import ButtonSubmit from './ButtonSubmit'
import LinkButton from './LinkButton'

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      overflowY: 'visible',
    },
  },
  dialogTitleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  dialogText: {
    fontWeight: 700,
  },
}))

const StyledDialog = withStyles(theme => ({
  root: {
    margin: '0 auto',
  },
}))(Dialog)

const StyledDialogTitle = withStyles(theme => ({
  root: {
    paddingRight: 0,
    paddingLeft: theme.spacing(2),
  },
}))(DialogTitle)

const StyledDialogContent = withStyles(theme => ({
  root: {
    overflowY: 'visible',
    padding: theme.spacing(0),
    margin: theme.spacing(2),
  },
}))(DialogContent)

const DialogAction = ({
  aditionalMessage,
  cancelText,
  children,
  dialogTitle,
  handleDialogOnClose,
  handleSubmit,
  isSubmitting,
  show,
  submitText,
  maxWidth = 'xs',
}) => {
  const classes = useStyles()
  return (
    <StyledDialog
      className={classes.dialog}
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
      fullWidth
      maxWidth={maxWidth}
      onClose={handleDialogOnClose}
      open={show}
    >
      <StyledDialogTitle disableTypography>
        <Box className={classes.dialogTitleRow}>
          <Typography className={classes.dialogText} fontWeight={800} title={dialogTitle}>
            {dialogTitle}
          </Typography>
          <IconButton color="secondary" onClick={handleDialogOnClose}>
            <Close />
          </IconButton>
        </Box>
      </StyledDialogTitle>
      <StyledDialogContent>{children}</StyledDialogContent>
      {(submitText || cancelText) && (
        <DialogActions>
          <Box width="100%" display="flex" flexDirection="column">
            {submitText && (
              <ButtonSubmit
                data-testid="dialog-submit-button"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                submitting={isSubmitting}
              >
                {submitText}
              </ButtonSubmit>
            )}

            {cancelText && (
              <Box margin={3} display="flex" justifyContent="center">
                <LinkButton
                  className={classes.dialogText}
                  color={themeConWeb.color.dangerLight}
                  onClick={handleDialogOnClose}
                >
                  {cancelText}
                </LinkButton>
              </Box>
            )}
          </Box>
        </DialogActions>
      )}
      {aditionalMessage}
    </StyledDialog>
  )
}

export default DialogAction
