import { formatTimeSpanStr } from './dateUtils'

export const daysOfWeekAvailabilityDescription = operationalDaysOfWeek => {
  if (isWeekdays(operationalDaysOfWeek)) {
    return 'Monday - Friday'
  }
  const days = ['S', 'M', 'T', 'W', 'Th', 'F', 'Sa']
  const daysOfWeek = operationalDaysOfWeek.map(day => days[day]).join(', ')
  return `${daysOfWeek}`
}

export const isWeekdays = operationalDaysOfWeek => {
  return (
    operationalDaysOfWeek.length === 5 &&
    operationalDaysOfWeek[0] === 1 &&
    operationalDaysOfWeek[1] === 2 &&
    operationalDaysOfWeek[2] === 3 &&
    operationalDaysOfWeek[3] === 4 &&
    operationalDaysOfWeek[4] === 5
  )
}

export const timeOfDayAvailabilityDescription = (operationalHoursStart, operationalHoursEnd) => {
  const formattedStartTime = formatTimeSpanStr(operationalHoursStart, 'h:mm A')
  const formattedEndTime = formatTimeSpanStr(operationalHoursEnd, 'h:mm A')
  return `${formattedStartTime} - ${formattedEndTime}`
}

export const cateringScheduleDescription = (
  operationalDaysOfWeek,
  operationalHoursStart,
  operationalHoursEnd,
) => {
  return `${daysOfWeekAvailabilityDescription(
    operationalDaysOfWeek,
  )} | ${timeOfDayAvailabilityDescription(operationalHoursStart, operationalHoursEnd)}`
}

export const parseLeadTimeInHours = leadTime => {
  var days = 0
  var hoursPart = leadTime

  // Check if the leadTime string contains a dot ('.').
  if (leadTime.includes('.')) {
    let parts = leadTime.split('.')
    days = parseInt(parts[0], 10)
    hoursPart = parts[1] // Assign the hours part correctly after splitting.
  }

  let timeParts = hoursPart.split(':')
  // The first part of timeParts is hours, so add days converted to hours.
  return parseInt(timeParts[0], 10) + days * 24
}

export const isOrderMinimumHit = (cartItemSubTotal, cateringStoreOrderMinimumInCents) => {
  return (
    cateringStoreOrderMinimumInCents === 0 ||
    Math.round(cartItemSubTotal * 100) >= cateringStoreOrderMinimumInCents
  )
}

export const isOrderUnderMaxWeight = (orderCartWeight, cateringStoreMaxWeight) => {
  if (cateringStoreMaxWeight === undefined) {
    return true
  }
  return orderCartWeight <= cateringStoreMaxWeight
}

export function isOrderValid(orderCart, cateringStore, cateringDeliveryTime, cateringDeliveryDate) {
  const orderDate = new Date(cateringDeliveryDate)
  const orderTime = cateringDeliveryTime

  if (cateringStore == null || cateringDeliveryDate == null || cateringDeliveryTime == null) {
    return true
  }

  if (orderCart.orderItems.length === 0) {
    return true
  }

  const inPast = isOrderInPast(orderDate)
  const daySupported = isDaySupported(orderDate, cateringStore.operationalDaysOfWeek)
  const timeInOperatingHours = isTimeWithinOperatingHours(
    orderTime,
    cateringStore.operationalHoursStart,
    cateringStore.operationalHoursEnd,
  )
  const isNotViolatingLeadTime = isOrderNotViolatingLeadTime(
    orderTime,
    orderDate,
    cateringStore.cateringLeadTime,
  )

  return !inPast && isNotViolatingLeadTime && daySupported && timeInOperatingHours
}

function isOrderInPast(orderDate) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const orderDateTime = new Date(orderDate)
  orderDateTime.setHours(0, 0, 0, 0)

  return orderDate < today
}

function isDaySupported(orderDate, operationalDays) {
  return operationalDays.includes(orderDate.getDay())
}

function isTimeWithinOperatingHours(orderTime, startTime, endTime) {
  if (!orderTime) {
    return false
  }
  const orderTimeString = orderTime.toString()

  const orderTime24HourFormat = convertTo24HourFormat(orderTimeString.trim())

  return orderTime24HourFormat >= startTime && orderTime24HourFormat <= endTime
}

const convertTo24HourFormat = time12h => {
  const [time, modifier] = time12h.split(' ')
  let [hours, minutes] = time.split(':')

  if (hours === '12') {
    hours = '00'
  }
  if (modifier === 'PM' && hours !== '12') {
    hours = parseInt(hours, 10) + 12
  }

  hours = hours.toString().padStart(2, '0')
  minutes = minutes.padStart(2, '0')

  return `${hours}:${minutes}:00`
}

function isOrderNotViolatingLeadTime(orderTime, orderDate, leadTime) {
  const currentDateTime = new Date()
  const orderDateTime = new Date(orderDate)
  const [orderHours, orderMinutes] = orderTime.split(' ')[0].split(':')
  const pmModifier = orderTime.split(' ')[1] === 'PM'

  let hours = parseInt(orderHours, 10) + (pmModifier && orderHours !== '12' ? 12 : 0)
  hours = hours === 12 && !pmModifier ? 0 : hours

  orderDateTime.setHours(hours, parseInt(orderMinutes, 10))

  const [leadHours, leadMinutes, leadSeconds] = leadTime.split(':')
  const leadTimeInMilliseconds =
    (parseInt(leadHours, 10) * 3600 + parseInt(leadMinutes, 10) * 60 + parseInt(leadSeconds, 10)) *
    1000

  const deadline = new Date(orderDateTime.getTime() - leadTimeInMilliseconds)

  return currentDateTime <= deadline
}

function deliveryInstructionsBreak() {
  return `\n---\n`
}

function makeCateringDeliveryInstructions(instructions) {
  if (instructions === undefined) {
    return ''
  }
  return instructions
}

function makeCateringUtensilsInstructions(cateringHeadCount) {
  if (cateringHeadCount === undefined || cateringHeadCount === 0) {
    return ''
  }
  return `Please provide utensils & napkins for ${cateringHeadCount} people`
}

export const combineCateringDeliveryInstructionsWithUtensilsHeadcount = (
  cateringDeliveryInstructions,
  cateringUtensilsRequested,
  cateringHeadCount,
) => {
  let instructions = makeCateringDeliveryInstructions(cateringDeliveryInstructions)
  const utensilsInstructions = makeCateringUtensilsInstructions(cateringHeadCount)
  if (cateringUtensilsRequested && utensilsInstructions.length > 0) {
    if (instructions.length > 0) {
      instructions = `${instructions}${deliveryInstructionsBreak()}`
    }
    instructions = `${instructions}${utensilsInstructions}`
  }
  return instructions
}
