import { Box, Grid, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export const DocsSelect = () => {
  const classes = useStyles()
  const [age, setAge] = React.useState('')

  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  const handleChange = event => {
    setAge(event.target.value)
  }
  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid xs={12}>
          <Typography component="h2" gutterBottom variant="h2">
            Selects
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Paper>
            <Box padding={4}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                <Select
                  id="demo-simple-select"
                  labelId="demo-simple-select-label"
                  onChange={handleChange}
                  value={age}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-helper-label">Age</InputLabel>
                <Select
                  id="demo-simple-select-helper"
                  labelId="demo-simple-select-helper-label"
                  onChange={handleChange}
                  value={age}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
                <FormHelperText>Some important helper text</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Select
                  className={classes.selectEmpty}
                  displayEmpty
                  onChange={handleChange}
                  value={age}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
                <FormHelperText>Without label</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-placeholder-label-label" shrink>
                  Age
                </InputLabel>
                <Select
                  className={classes.selectEmpty}
                  displayEmpty
                  id="demo-simple-select-placeholder-label"
                  labelId="demo-simple-select-placeholder-label-label"
                  onChange={handleChange}
                  value={age}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
                <FormHelperText>Label + placeholder</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl} disabled>
                <InputLabel id="demo-simple-select-disabled-label">Name</InputLabel>
                <Select
                  id="demo-simple-select-disabled"
                  labelId="demo-simple-select-disabled-label"
                  onChange={handleChange}
                  value={age}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
                <FormHelperText>Disabled</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl} error>
                <InputLabel id="demo-simple-select-error-label">Name</InputLabel>
                <Select
                  id="demo-simple-select-error"
                  labelId="demo-simple-select-error-label"
                  onChange={handleChange}
                  renderValue={value => `⚠️  - ${value}`}
                  value={age}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
                <FormHelperText>Error</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-readonly-label">Name</InputLabel>
                <Select
                  id="demo-simple-select-readonly"
                  inputProps={{ readOnly: true }}
                  labelId="demo-simple-select-readonly-label"
                  onChange={handleChange}
                  value={age}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
                <FormHelperText>Read only</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-autowidth-label">Age</InputLabel>
                <Select
                  autoWidth
                  id="demo-simple-select-autowidth"
                  labelId="demo-simple-select-autowidth-label"
                  onChange={handleChange}
                  value={age}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
                <FormHelperText>Auto width</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Select
                  className={classes.selectEmpty}
                  displayEmpty
                  onChange={handleChange}
                  value={age}
                >
                  <MenuItem disabled value="">
                    Placeholder
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
                <FormHelperText>Placeholder</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl} required>
                <InputLabel id="demo-simple-select-required-label">Age</InputLabel>
                <Select
                  className={classes.selectEmpty}
                  id="demo-simple-select-required"
                  labelId="demo-simple-select-required-label"
                  onChange={handleChange}
                  value={age}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl} variant="outlined" color="secondary">
                <InputLabel id="demo-simple-select-outlined-label" ref={inputLabel}>
                  Age
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  labelId="demo-simple-select-outlined-label"
                  labelWidth={labelWidth}
                  onChange={handleChange}
                  value={age}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} variant="filled">
                <InputLabel id="demo-simple-select-filled-label">Age</InputLabel>
                <Select
                  id="demo-simple-select-filled"
                  labelId="demo-simple-select-filled-label"
                  onChange={handleChange}
                  value={age}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default DocsSelect
