import React from 'react'
import { Redirect } from 'react-router'

import { errorRoute } from '../../routes/routes'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }
  render() {
    if (this.state.hasError) {
      return <Redirect to={errorRoute.path} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
