import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Container, Dialog, IconButton, Slide } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    paddingTop: theme.spacing(5),
  },
}))

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogPerkDetails = ({ children, onClose }) => {
  const classes = useStyles()

  return (
    <Dialog open fullScreen classes={{ paper: classes.root }} TransitionComponent={Transition}>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <Close />
      </IconButton>
      <Container className={classes.container}>{children}</Container>
    </Dialog>
  )
}

DialogPerkDetails.propTypes = {
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default DialogPerkDetails
