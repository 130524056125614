import React from 'react'
import { Form, Formik } from 'formik'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { object, ref, string } from 'yup'

import ButtonSubmit from '../../common/ButtonSubmit'

const schema = object().shape({
  oldPassword: string().required('Current Password is required.'),
  password: string().required('New Password is required'),
  confirmPassword: string()
    .oneOf([ref('password')], "Passwords don't match")
    .required('Confirm New Password is required'),
})

const DialogChangePassword = ({ onClose, onSubmit, submitting, error }) => {
  return (
    <Formik
      initialValues={{
        oldPassword: '',
        password: '',
        confirmPassword: '',
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ errors, handleBlur, handleChange, resetForm, touched, values }) => (
        <Dialog
          open
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="sm"
          onClose={onClose}
          onEntering={() => {
            resetForm()
          }}
        >
          <Form>
            <DialogTitle>Change password</DialogTitle>

            <DialogContent>
              <TextField
                autoFocus
                error={touched.oldPassword && Boolean(errors.oldPassword)}
                fullWidth
                helperText={touched.oldPassword && errors.oldPassword}
                id="oldPassword"
                label="Current Password"
                margin="dense"
                name="oldPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.oldPassword}
                variant="outlined"
                color="secondary"
              />
              <TextField
                error={touched.password && Boolean(errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                id="loginPassword"
                label="New Password"
                margin="dense"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
                color="secondary"
              />
              <TextField
                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                id="confirmPassword"
                label="Confirm New Password"
                margin="dense"
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.confirmPassword}
                variant="outlined"
                color="secondary"
              />
            </DialogContent>

            <DialogActions>
              <Grid component={Box} container justifyContent="flex-end" px={2} py={3} spacing={3}>
                <Grid item>
                  <Button onClick={onClose}>Cancel</Button>
                </Grid>
                <Grid item>
                  <ButtonSubmit color="primary" submitting={submitting} variant="contained">
                    Save Password
                  </ButtonSubmit>
                </Grid>
              </Grid>
            </DialogActions>

            {error && <Alert severity="error">{error}</Alert>}
          </Form>
        </Dialog>
      )}
    </Formik>
  )
}

export default DialogChangePassword
