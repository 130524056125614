import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { createSelector } from 'reselect'

import { removeSnackbar } from '../../redux/modules/snackbar'

const selectSnackbars = createSelector([state => state.snackbar.snackbars], snackbars => snackbars)

let activeSnackbars = [] // Keep track of the snackbars we displayed

/*
 * Handles showing/hiding snackbars globally using notistack
 * Use enqueueSnackbar and closeSnackbar from the snackbar Redux module
 * See https://iamhosseindhv.com/notistack/api documentation for what can be passed in as options
 */
const Snackbars = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const snackbars = useSelector(selectSnackbars)
  const dispatch = useDispatch()

  useEffect(() => {
    const addActiveSnackbar = key => {
      activeSnackbars = [...activeSnackbars, key]
    }

    const removeActiveSnackbar = key => {
      activeSnackbars = activeSnackbars.filter(d => d !== key)
    }

    if (!isEmpty(snackbars)) {
      snackbars.forEach(({ key, message, options = {}, dismissed = false }) => {
        if (dismissed) {
          closeSnackbar(key)
          return
        }

        if (activeSnackbars.includes(key)) {
          return
        }

        enqueueSnackbar(message, {
          key,
          ...options,
          autoHideDuration: options.autoHideDuration || 7000,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey)
            }
          },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(myKey))
            removeActiveSnackbar(myKey)
          },
        })

        addActiveSnackbar(key)
      })
    }
  }, [enqueueSnackbar, snackbars, dispatch, closeSnackbar])

  return null
}

export default Snackbars
