import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import copy from 'copy-to-clipboard'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  button: {
    width: ({ width }) => width,
  },
  buttonSuccess: {
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
    backgroundColor: theme.palette.success.main,
  },
}))

const ButtonCopy = ({
  clipboardText,
  text,
  successText = 'Copied!',
  width,
  onSuccess,
  ...rest
}) => {
  const classes = useStyles({ width })
  const [copied, setCopied] = useState(false)

  const handleClick = () => {
    copy(clipboardText)
    if (onSuccess) {
      onSuccess()
    } else {
      setCopied(true)
    }
  }

  useEffect(() => {
    if (copied) {
      const clearCopied = setTimeout(() => setCopied(false), 5000)

      return () => {
        clearTimeout(clearCopied)
      }
    }
  }, [copied])

  return (
    <Button
      {...rest}
      className={cn(classes.button, { [classes.buttonSuccess]: copied })}
      onClick={handleClick}
    >
      {copied ? successText : text}
    </Button>
  )
}

export default ButtonCopy
