import React from 'react'
import { Grid, Typography } from '@material-ui/core'

const variants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'caption',
  'button',
  'overline',
  'srOnly',
  'inherit',
]

export const DocsTypography = () => (
  <Grid container justify="center" spacing={2}>
    {variants.map(variant => (
      <Grid item xs={12} key={variant}>
        <Typography variant={variant}>This is {variant} variant.</Typography>
      </Grid>
    ))}
  </Grid>
)

export default DocsTypography
