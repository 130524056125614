import { H5, P } from '@foodsby/nutrient'
import { themeConWeb } from '@app/util/modernThemeConweb'
import { Button } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { deleteFavoriteStart } from '../../redux/modules/favorite'
import { pushAnalyticsEvent } from '../../util/gtmUtils'
import ModalPopup from '../common/ModalPopup'

export const AutoFavoriteModal = ({ closeAutoFavorite, items }) => {
  const dispatchRedux = useDispatch()

  useEffect(() => {
    pushAnalyticsEvent('Favorite', 'View Auto Favorite')
  }, [])
  return (
    <ModalPopup maxWidth={550} showCancel={false}>
      <Component>
        <img
          alt=""
          className="-autoFavoriteIcon"
          src="https://images.foodsby.com/conweb/favorite-icon-1.svg"
        />
        <div>
          <H5 className="-titleText">We’ve favorited this item for you!</H5>
          <P className="-bodyText">
            {`You've ordered ${items.map(item => item.displayText).join(', ')} two times. Would you
            like to keep this as a favorite?`}
          </P>
          <div className="-ctaGroup">
            <Button
              color="secondary"
              onClick={() => {
                pushAnalyticsEvent('Favorite', 'Auto Favorite Deleted Favorite')
                items.forEach(item => dispatchRedux(deleteFavoriteStart(item.menuItemId)))
                closeAutoFavorite()
              }}
            >
              No, unfavorite
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                pushAnalyticsEvent('Favorite', 'Auto Favorite Added Favorite')
                closeAutoFavorite()
              }}
            >
              Yes, Favorite
            </Button>
          </div>
        </div>
      </Component>
    </ModalPopup>
  )
}

const Component = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & .-autoFavoriteIcon {
    padding-right: 16px;
  }

  & .-titleText {
    font-weight: 500;
    margin-bottom: 16px;
  }

  & .-bodyText {
    font-size: ${themeConWeb.fontSizes.basePlus1}
    margin-bottom: 20px;
  }

  & .-ctaGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  @media screen and ${themeConWeb.small} {
    & .-ctaGroup {
      flex-wrap: wrap;
    }
  }
`

export default AutoFavoriteModal
