import React from 'react'
import scriptLoader from 'react-async-script-loader'
import { Redirect } from 'react-router-dom'

import { MAPS_SCRIPT_URL } from '../components/location-search/LocationSearchBox'
import { rootRoute } from '../routes/routes'

export const PageRegister = () => {
  return <Redirect to={rootRoute.path} />
}

export default scriptLoader(MAPS_SCRIPT_URL)(PageRegister)
