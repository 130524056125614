import * as Survey from 'survey-react'
import 'survey-react/survey.css'
import 'github-markdown-css/github-markdown.css'
import showdown from 'showdown'

var defaultThemeColors = Survey.StylesManager.ThemeColors['default']

defaultThemeColors['$main-color'] = '#4a4a4a'
defaultThemeColors['$main-hover-color'] = '#EE3C25'

Survey.StylesManager.applyTheme()

//basically just gets rid of the paneling
var myCss = {
  row: 'sv_other',
  page: {
    root: 'sv_p_root markdown-body',
  },
}

//Create showdown markdown converter
var converter = new showdown.Converter()

const createSurvey = json => {
  const survey = new Survey.Model(json)
  survey.onTextMarkdown.add(function(_, options) {
    //convert the markdown text to html
    var str = converter.makeHtml(options.text)
    //remove root paragraphs <p></p>
    if (str.slice(0, 3) === '<p>') {
      str = str.substring(3)
      str = str.substring(0, str.length - 4)
    }

    //set html
    options.html = str
  })
  survey.css = myCss
  return survey
}

export default createSurvey
