import React, { useEffect } from 'react'
import { Grid, Box, Typography, Link } from '@material-ui/core'
import { connect } from 'react-redux'

import { selectTeam } from '../redux/selectors/teamSelectors'
import { updateTeamSettingsStart } from '../redux/modules/team'
import Header from '../components/teams/admin/Header'
import CardTeamDetails from '../components/teams/admin/CardTeamDetails'
import CardAccountSettings from '../components/teams/admin/CardAccountSettings'
import CardCommonActions from '../components/teams/admin/CardCommonActions'
import { loadAccountStart } from '../redux/modules/account'
import { selectAccount, selectEmailDomains } from '../redux/selectors/accountSelectors'
import CardAccountAdmins from '../components/teams/admin/CardAccountAdmins'
import CardPreferredStatus from '../components/teams/admin/CardPreferredStatus'
import DialogUpgradeOfficeSuccess from '../components/home/DialogUpgradeOfficeSuccess'
import { loadEmailDomainsStart } from '../redux/modules/offices'

const PageTeamAccount = ({
  updateTeamSettingsStart,
  isLoadingTeam,
  team,
  match,
  account,
  emailDomains,
  isDoneUpgrading,
  loadAccountStart,
  loadEmailDomainsStart,
}) => {
  const accountId = match.params.accountId
  const onSettingsSubmit = changes => {
    updateTeamSettingsStart(team.id, { ...team, ...changes })
  }

  useEffect(() => {
    loadAccountStart(accountId)
  }, [accountId, team, loadAccountStart])

  useEffect(() => {
    if (account?.type === 'OFFICE') {
      loadEmailDomainsStart(accountId)
    }
  }, [account?.type, accountId, loadEmailDomainsStart])

  const DisplayForOfficeType = ({ accountType, children }) => {
    if (accountType !== 'OFFICE') {
      return null
    }
    return children
  }

  const DisplayForTeamType = ({ accountType, children }) => {
    if (accountType !== 'TEAM') {
      return null
    }
    return children
  }

  return (
    <>
      <Header account={account} name={account?.name} />
      <Box mt={3}>
        <Grid container spacing={3} justifyContent="flex-start">
          <DisplayForOfficeType accountType={account?.type}>
            <Grid item xs={12}>
              <CardPreferredStatus preferredType={account?.preferredType} />
            </Grid>
          </DisplayForOfficeType>
          <Grid item xs={12} lg={8}>
            <CardTeamDetails
              team={team}
              emailDomains={emailDomains}
              loading={isLoadingTeam}
              onSubmit={onSettingsSubmit}
            />
            <DisplayForTeamType>
              <CardAccountSettings
                team={team}
                loading={isLoadingTeam}
                onSubmit={onSettingsSubmit}
              />
            </DisplayForTeamType>
            <CardAccountAdmins admins={account?.externalManagers} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CardCommonActions accountId={accountId} />
            <Box m={'2rem'}>
              <Typography gutterBottom>
                <strong>Need Help?</strong>
              </Typography>
              <Typography display="block">
                Visit{' '}
                <Link href="https://home.foodsby.com/faq" target="_blank" underline={'always'}>
                  FAQs
                </Link>{' '}
                or contact{' '}
                <Link href="mailto:support@foodsby.com" underline={'always'}>
                  support@foodsby.com
                </Link>
                .
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isDoneUpgrading ? <DialogUpgradeOfficeSuccess accountId={accountId} /> : null}
    </>
  )
}

const mapStateToProps = state => {
  return {
    isLoadingTeam: state.team.isLoadingTeam,
    team: selectTeam(state),
    account: selectAccount(state),
    emailDomains: selectEmailDomains(state),
    isDoneUpgrading: state.account.isDoneUpgrading,
  }
}

const mapDispatchToProps = {
  updateTeamSettingsStart,
  loadAccountStart,
  loadEmailDomainsStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTeamAccount)
