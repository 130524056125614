import React, { useState } from 'react'
import { List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import DialogInviteMembers from '../../teams/admin/DialogInviteMembers'
import { teamMembersRoute } from '../../../routes/routes'
import { leaveTeamStart, resetLeaveState } from '../../../redux/modules/userTeam'
import { selectUserTeam } from '../../../redux/selectors/userTeamSelectors'
import MenuTeamOptions from './MenuTeamOptions'
import DialogLeaveConfirmation from './DialogLeaveConfirmation'

const MyTeam = ({ userTeam, isLeaving, errorLeaving, leaveTeamStart, resetLeaveState, push }) => {
  const [showDialogConfirmation, setShowDialogConfirmation] = useState(false)

  const handleManage = () => push(teamMembersRoute.path)

  const handleInvite = () => push({ search: queryString.stringify({ inviteMembers: true }) })

  const handleLeave = () => setShowDialogConfirmation(true)

  const handleCloseLeaveConfirmation = () => setShowDialogConfirmation(false)

  const isAdmin = userTeam.team.manager
  return (
    <>
      <List>
        <ListItem>
          <ListItemText primary={userTeam.team.name} />
          <ListItemSecondaryAction>
            <MenuTeamOptions
              isAdmin={isAdmin}
              canInvite={false}
              onManage={handleManage}
              onInvite={handleInvite}
              onLeave={handleLeave}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      {showDialogConfirmation && (
        <DialogLeaveConfirmation
          team={userTeam.team}
          submitting={isLeaving}
          error={errorLeaving}
          onClose={handleCloseLeaveConfirmation}
          onConfirm={leaveTeamStart}
          onUnmount={resetLeaveState}
        />
      )}
      <DialogInviteMembers />
    </>
  )
}

const mapStateToProps = state => {
  const userTeam = selectUserTeam(state)
  const { isLeaving, errorLeaving } = state.userTeam

  return {
    userTeam,
    isLeaving,
    errorLeaving,
  }
}

const mapDispatchToProps = {
  leaveTeamStart,
  resetLeaveState,
  push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyTeam)
