import { createTheme } from '@material-ui/core/styles'
import { themeConWeb } from '@app/util/modernThemeConweb'

// A custom theme for this app
const theme = createTheme({
  overrides: {
    MuiAvatarGroup: {
      root: {
        avatar: {
          marginLeft: '-4px',
        },
        marginLeft: '4px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 4,
      },
    },
    MuiButtonGroup: {
      root: {
        borderRadius: 4,
      },
    },
    MuiCard: {
      root: {
        marginBottom: '16px',
        overflow: 'intial',
        padding: '32px',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: themeConWeb.color.white,
      },
    },
    MuiSwitch: {
      root: {
        height: 43,
        padding: 9,
      },
      thumb: {
        height: 24,
        width: 24,
      },
      track: {
        borderRadius: 25,
      },
    },
    MuiTab: {
      root: {
        '&$selected': {
          outline: 'none',
        },
      },
    },
    MuiSnackbar: {
      root: {
        '& .MuiAlert-root': {
          textAlign: 'left',
          backgroundColor: '#000000',
          color: '#eeeeee',

          '& .MuiAlert-icon': {
            alignItems: 'center',
            color: 'white',
          },
        },
      },
    },
    MuiDialog: {
      paper: {
        padding: 8,
      },
      paperFullScreen: {
        padding: 0,
      },
    },
  },
  palette: {
    background: {
      default: themeConWeb.color.white,
      secondary: themeConWeb.color.grey,
    },
    destructive: {
      main: themeConWeb.color.dangerLight,
    },
    error: {
      main: themeConWeb.color.danger,
    },
    primary: {
      main: themeConWeb.color.brandPrimary,
    },
    secondary: {
      main: themeConWeb.color.brandSecondary,
    },
    success: {
      main: themeConWeb.color.success,
    },
    text: {
      primary: themeConWeb.color.primaryType,
    },
    warning: {
      main: themeConWeb.color.warning,
    },
  },

  props: {
    MuiCard: {
      variant: 'outlined',
    },
  },
  shape: {
    borderRadius: 4,
  },

  spacing: factor => [0, 4, 8, 16, 32, 64][factor],
  typography: {
    h1: {
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    },
    h6: {
      fontWeight: 'bold',
    },
    subtitle1: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontWeight: 500,
    },
  },
})

export default theme
