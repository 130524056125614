import React from 'react'
import PropTypes from 'prop-types'
import { Badge, IconButton } from '@material-ui/core'
import { NotificationsNone } from '@material-ui/icons'

const ButtonContentCards = ({ unviewed, onClick }) => {
  return (
    <IconButton onClick={onClick} data-testid="button-content-cards">
      <Badge color="primary" invisible={!unviewed} variant="dot" overlap="circular">
        <NotificationsNone />
      </Badge>
    </IconButton>
  )
}

ButtonContentCards.propTypes = {
  unviewed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ButtonContentCards
