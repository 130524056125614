import React, { useEffect, useState } from 'react'
import { Box, Button, DialogActions, FormHelperText } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { Alert } from '@material-ui/lab'

import ButtonSubmit from '../../common/ButtonSubmit'
import PaymentMethodTeam from '../common/PaymentMethodTeam'
import { NEW_PAYMENT_METHOD_ID } from '../../../util/paymentMethods'
import DialogContent from './DialogContent'
import { PROGRAM_DIALOG_HEIGHT } from './programUtils'

const StepAddPaymentMethod = ({
  helperText = undefined,
  submitting,
  error = undefined,
  values,
  paymentMethods,
  paymentMethodsError,
  setFieldValue,
  handleGetTotal,
  validationSchema,
  defaultPaymentMethod,
  handleConfirm,
  handleClose,
  onBack,
}) => {
  const [onLoadPaymentMethodId] = useState(defaultPaymentMethod?.id)

  const saveValueGetTotalAndClose = () => {
    setFieldValue('SelectedCard', defaultPaymentMethod.id)
    handleGetTotal &&
      handleGetTotal({
        SelectedCard: defaultPaymentMethod.id,
      })
    handleClose && handleClose()
  }

  useEffect(() => {
    if (defaultPaymentMethod && onLoadPaymentMethodId !== defaultPaymentMethod.id) {
      saveValueGetTotalAndClose()
    }
  }, [defaultPaymentMethod])

  const handleSave = newValues => {
    if (newValues.SelectedCard === defaultPaymentMethod?.id) {
      saveValueGetTotalAndClose()
    }
    handleConfirm && handleConfirm(newValues)
  }

  return (
    <Formik
      initialValues={{
        SelectedCard:
          values.SelectedCard > 0
            ? values.SelectedCard
            : defaultPaymentMethod
            ? defaultPaymentMethod.id
            : NEW_PAYMENT_METHOD_ID,
      }}
      onSubmit={handleSave}
      validationSchema={validationSchema}
    >
      {props => {
        const {
          setFieldValue,
          values: subValues,
          errors,
          touched,
          setFieldTouched,
          handleChange,
          handleBlur,
        } = props
        return (
          <Form>
            <DialogContent height={PROGRAM_DIALOG_HEIGHT}>
              <PaymentMethodTeam
                paymentMethods={paymentMethods}
                paymentMethodsError={paymentMethodsError}
                errors={errors}
                touched={touched}
                values={subValues}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
              {helperText && (
                <Box paddingTop={2}>
                  <FormHelperText>{helperText}</FormHelperText>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              {onBack && <Button onClick={onBack}>Back</Button>}
              <ButtonSubmit color="primary" variant="contained" submitting={submitting}>
                Confirm
              </ButtonSubmit>
            </DialogActions>
            {error && <Alert color="error">{error}</Alert>}
          </Form>
        )
      }}
    </Formik>
  )
}
export default StepAddPaymentMethod
