import { TableCell, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { themeConWeb } from '../../util/modernThemeConweb'

const useStyles = makeStyles({
  tableCell: {
    padding: ({ checkbox }) => (checkbox ? '0px 16px' : '8px 16px'),
    color: 'white',
  },
  row: {
    backgroundColor: themeConWeb.color.greyShade7,
    color: themeConWeb.color.black,
  },
  root: {
    color: 'white',
    '&:hover': {
      color: 'white',
    },
    '&$active': {
      color: 'white',
    },
  },
  active: {},
  icon: {
    color: 'inherit !important',
  },
})

const HeaderTableLocationOffices = ({ columns }) => {
  const classes = useStyles()

  return (
    <TableHead>
      <TableRow className={classes.row}>
        {columns.map(cell => {
          return (
            <TableCell key={cell.id} className={classes.tableCell}>
              {cell.label}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default HeaderTableLocationOffices
