import 'react-day-picker/lib/style.css'
import { Card, Typography, Box, InputAdornment, TextField, Grid, MenuItem } from '@material-ui/core'
import React, { Component } from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import DayPicker from 'react-day-picker'
import { format, parse } from 'date-fns'
import { withStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { pushAnalyticsEvent } from '../util/gtmUtils'
import AuthedComponent from '../components/common/AuthedComponent'
import LayoutInnerPage, { HeaderInnerPage } from '../layouts/LayoutInnerPage'
import { formatUrl } from '../util/formatUtils'
import { placeReserveOrderRoute, setDeliveryReserveFeeRoute } from '../routes/routes'
import { updateReserveDeliveryApi } from '../services/delivery'
import { loadReserveStoreStart } from '../redux/modules/reserve'
import RestaurantHoursAndNotice from '../components/common/RestaurantHoursAndNotice'
import ButtonSubmit from '../components/common/ButtonSubmit'
import { loadReserveDeliveryDateAndTimeStart, selectDateStart } from '../redux/modules/delivery'
import {
  getMenuInfoForNewPopularItems,
  getMenuInfoForPopularItems,
} from '../redux/modules/selectors'
import { addPastOrderToReserveCart, loadPastOrdersReserveStart } from '../redux/modules/pastorder'
import {
  addItemToReserveCartStart,
  cancelEditingOrder,
  editReserveOrderInCart,
  loadEditOrder,
  loadReserveOrderCartStart,
  removeItemFromReserveOrderCartStart,
} from '../redux/modules/cart'
import { clearMenuItem, loadReserveMenuStart, selectMenuItem } from '../redux/modules/menu'
import { loadFavoriteStart, loadPopularMenuItems } from '../redux/modules/favorite'
import { resetCheckoutError } from '../redux/modules/checkout'
import { ReserveOrderSidebar } from '../components/place-order'
import {
  selectFormattedReserveDeliveryDate,
  selectFormattedReserveDeliveryTime,
} from '../redux/selectors/deliverySelectors'

const styles = () => ({
  dayPicker: {
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: 'white',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    marginTop: '3em',
    '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
      backgroundColor: '#db4c34',
      color: 'white',
    },
  },
  enterDelivery: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '38px',
  },
  timeField: {
    '& .MuiSelect-select': { minWidth: '11.3em' },
  },
})

export class PagePickReserveDeliveryDateTime extends Component {
  constructor(props) {
    super(props)
    this.props.clearMenuItem()
    this.handleClose = this.handleClose.bind(this)
    this.state = {
      selectedDate: '',
      showDatePicker: false,
      time: '',
      errorUpdatingReserveDelivery: '',
      checkoutErrorMessage: false,
      displayedMonth: new Date(),
    }
  }

  handleClose() {
    this.props.clearMenuItem()
    this.props.cancelEditingOrder()
  }

  getStoreIdAndLocationId = () => {
    let {
      match: {
        params: { storeId = 0, locationId = 0 },
      },
    } = this.props
    storeId = Number(storeId)
    locationId = Number(locationId)
    return { storeId, locationId }
  }

  handleAddItemsToCart = (menuItemId, itemValues, checkout) => {
    const { addItemToReserveCartStart } = this.props
    const { storeId, locationId } = this.getStoreIdAndLocationId()
    addItemToReserveCartStart(locationId, menuItemId, storeId, itemValues, checkout)
  }

  handleEditOrder = (orderItemId, menuItemId, itemValues, checkout) => {
    const { resetCheckoutError, editReserveOrderInCart } = this.props

    const { storeId, locationId } = this.getStoreIdAndLocationId()

    checkout && resetCheckoutError()

    editReserveOrderInCart(locationId, menuItemId, storeId, itemValues, orderItemId, true, checkout)
  }

  convertTo24HourFormat = time12h => {
    const [timePart, modifier] = time12h.split(' ')
    let [hours, minutes] = timePart.split(':')
    if (hours === '12') {
      hours = '00'
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12
    }
    return `${hours}:${minutes}:00`
  }

  handleNext = async () => {
    const { locationId, storeId } = this.getStoreIdAndLocationId()
    const { push, selectDateStart } = this.props
    const { selectedDate, time } = this.state

    if (!selectedDate || time === '') {
      return
    }
    const formattedDate = format(selectedDate, 'YYYY-MM-DD')
    const formattedTime = this.convertTo24HourFormat(time)

    try {
      const requestData = {
        date: formattedDate,
        time: formattedTime,
        locationId,
        storeId,
      }

      await updateReserveDeliveryApi(requestData)
      push(
        formatUrl(setDeliveryReserveFeeRoute.path, {
          locationId: locationId,
          storeId: storeId,
        }),
      )
      selectDateStart(format(selectedDate, 'YYYYMMDD'), locationId)
    } catch (error) {
      this.setState({ errorUpdatingReserveDelivery: error.message })
    }
  }

  handleRemoveItemFromCart = orderItemId => {
    const { storeId, locationId } = this.getStoreIdAndLocationId()
    const { removeItemFromReserveOrderCartStart } = this.props

    removeItemFromReserveOrderCartStart(orderItemId, storeId, locationId)
  }

  handleAddPastOrderToCart = order => {
    const { addPastOrderToReserveCart } = this.props
    const { storeId, locationId } = this.getStoreIdAndLocationId()
    addPastOrderToReserveCart(order, storeId, locationId)
  }

  handleDateChange = day => {
    const { errorUpdatingReserveDelivery } = this.state
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (day >= today) {
      this.setState({ selectedDate: day })
    }

    if (errorUpdatingReserveDelivery !== '') {
      this.setState({ errorUpdatingReserveDelivery: '' })
    }

    this.setState({ showDatePicker: false })
  }

  handleDateInputChange = event => {
    const inputDate = event.target.value
    if (inputDate.length === 10) {
      const parsedDate = parse(inputDate, 'MM/DD/YYYY', new Date())
      if (!isNaN(parsedDate)) {
        this.setState({ selectedDate: parsedDate })
      }
    }
  }

  formatDate = date => {
    return format(date, 'dddd, MMMM D')
  }

  toggleDatePicker = () => {
    this.setState(prevState => ({ showDatePicker: !prevState.showDatePicker }))
  }

  async componentDidMount() {
    window && window.scrollTo(0, 0)
    const {
      loadFavoriteStart,
      loadReserveMenuStart,
      loadReserveOrderCartStart,
      loadPastOrdersReserveStart,
      loadPopularMenuItems,
      loadReserveStoreStart,
      reserveStore,
      loadReserveDeliveryDateAndTimeStart,
      reserveDeliveryDate,
      reserveDeliveryTime,
    } = this.props

    const { selectedDate } = this.state

    const { storeId, locationId } = this.getStoreIdAndLocationId()

    if (storeId && locationId) {
      loadReserveMenuStart(storeId, locationId)
      loadReserveOrderCartStart(storeId, locationId)
      loadPopularMenuItems()
      loadPastOrdersReserveStart(storeId)
      loadFavoriteStart()
      loadReserveStoreStart(storeId, locationId)
      loadReserveDeliveryDateAndTimeStart(storeId, locationId)
    }
    pushAnalyticsEvent('Reserve Ordering', 'Pick Date & Time', undefined, storeId)

    const isDeliveryInThePast =
      reserveDeliveryDate &&
      parse(reserveDeliveryDate, 'MM/DD/YYYY', new Date()) < new Date().setHours(0, 0, 0, 0)
    const deliveryHasNotBeenUpdatedAfterCreation = reserveDeliveryTime === '12:00 AM'

    if (
      reserveDeliveryDate &&
      reserveDeliveryTime &&
      !isDeliveryInThePast &&
      !deliveryHasNotBeenUpdatedAfterCreation
    ) {
      this.setState({
        selectedDate: parse(reserveDeliveryDate, 'MM/DD/YYYY', new Date()),
        time: reserveDeliveryTime,
      })
    } else if (reserveStore) {
      this.setState({
        selectedDate: '',
        time: '',
      })
    }

    if (selectedDate !== '') {
      this.setState({
        displayedMonth: new Date(selectedDate.getFullYear(), selectedDate.getMonth()),
      })
    }

    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps) {
    const {
      isAuthenticated,
      loadFavoriteStart,
      loadReserveMenuStart,
      loadReserveOrderCartStart,
      loadPastOrdersReserveStart,
      loadReserveStoreStart,
      loadReserveDeliveryDateAndTimeStart,
      reserveDeliveryDate,
      reserveDeliveryTime,
    } = this.props

    const { storeId, locationId } = this.getStoreIdAndLocationId()

    if (
      storeId !==
      Number(
        prevProps.match.params.storeId || locationId !== Number(prevProps.match.params.locationId),
      )
    ) {
      loadReserveMenuStart(storeId, locationId)
      loadReserveOrderCartStart(storeId, locationId)
      loadReserveStoreStart(storeId, locationId)
      loadReserveDeliveryDateAndTimeStart(storeId, locationId)
    }
    if (isAuthenticated !== prevProps.isAuthenticated) {
      loadPastOrdersReserveStart(storeId)
      loadFavoriteStart()
      loadReserveMenuStart(storeId, locationId)
      loadReserveOrderCartStart(storeId, locationId)
      loadReserveStoreStart(storeId, locationId)
      loadReserveDeliveryDateAndTimeStart(storeId, locationId)
    }

    const isDeliveryInThePast =
      reserveDeliveryDate &&
      parse(reserveDeliveryDate, 'MM/DD/YYYY', new Date()) < new Date().setHours(0, 0, 0, 0)

    const deliveryHasNotBeenUpdatedAfterCreation = reserveDeliveryTime === '12:00 AM'

    if (
      (prevProps.reserveDeliveryDate !== reserveDeliveryDate ||
        prevProps.reserveDeliveryTime !== reserveDeliveryTime) &&
      !isDeliveryInThePast &&
      !deliveryHasNotBeenUpdatedAfterCreation
    ) {
      this.setState({
        selectedDate: reserveDeliveryDate
          ? parse(reserveDeliveryDate, 'MM/DD/YYYY', new Date())
          : '',
        time: reserveDeliveryTime || '',
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setDayPickerRef = node => {
    this.dayPickerRef = node
  }

  handleClickOutside = event => {
    if (this.dayPickerRef && !this.dayPickerRef.contains(event.target)) {
      this.setState({ showDatePicker: false })
    }
  }

  handleTimeChange = event => {
    const { errorUpdatingReserveDelivery } = this.state
    this.setState({ time: event.target.value })
    if (errorUpdatingReserveDelivery !== '') {
      this.setState({ errorUpdatingReserveDelivery: '' })
    }
  }

  createTimeOptions = () => {
    const timeOptions = []

    for (let hour = 8; hour <= 20; hour++) {
      const hourString = hour <= 12 ? hour : hour - 12
      const amPm = hour < 12 ? 'AM' : 'PM'

      timeOptions.push({
        label: `${hourString}:00 ${amPm}`,
        value: `${hourString}:00 ${amPm}`,
      })

      if (hour !== 20) {
        timeOptions.push({
          label: `${hourString}:30 ${amPm}`,
          value: `${hourString}:30 ${amPm}`,
        })
      }
    }

    return timeOptions
  }

  handleMonthChange = newMonth => {
    this.setState({ displayedMonth: newMonth })
  }

  render() {
    const {
      isAuthenticated,
      classes,
      isReserveStoreLoading,
      reserveStore,
      orderCart,
      isLoadingReserveDeliveryDateTime,
    } = this.props
    const {
      selectedDate,
      showDatePicker,
      time,
      errorUpdatingReserveDelivery,
      displayedMonth,
    } = this.state
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const deliveryDateAndTimeNotFilledOut = !selectedDate || time === ''

    const { locationId, storeId } = this.getStoreIdAndLocationId()

    const timeOptions = this.createTimeOptions(selectedDate)

    if (isLoadingReserveDeliveryDateTime || isReserveStoreLoading) {
      return <LayoutInnerPageSkeleton locationId={locationId} storeId={storeId} />
    }

    const disableContinue =
      !isAuthenticated ||
      reserveStore === undefined ||
      deliveryDateAndTimeNotFilledOut ||
      orderCart.orderItems.length === 0

    return (
      <AuthedComponent isAuthenticated={isAuthenticated} locationId={locationId}>
        <LayoutInnerPage
          HeaderComponent={
            <HeaderInnerPage
              showBackButton
              backButtonRoute={formatUrl(placeReserveOrderRoute.path, { locationId, storeId })}
            >
              Back
            </HeaderInnerPage>
          }
          MainComponent={
            <Card>
              <Box marginBottom={4}>
                <Typography className={classes.enterDelivery}>
                  Enter delivery date & time
                </Typography>
                <Box marginTop={2}>
                  <Typography>
                    Select the date and time for your delivery, and it will automatically be
                    available for everyone at your location to order from as well!
                  </Typography>
                </Box>
                {!isReserveStoreLoading && (
                  <RestaurantHoursAndNotice
                    operationalHoursStart={reserveStore?.operationalHoursStart}
                    operationalHoursEnd={reserveStore?.operationalHoursEnd}
                    operationalDaysOfWeek={reserveStore?.operationalDaysOfWeek}
                    deliveryLeadTime={reserveStore?.deliveryLeadTime}
                  />
                )}
              </Box>
              <Box component="section" marginBottom={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      label="Delivery Date"
                      value={selectedDate !== '' ? this.formatDate(selectedDate) : ''}
                      onChange={this.handleDateInputChange}
                      onClick={this.toggleDatePicker}
                      readOnly
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <ArrowDropDownIcon />
                          </InputAdornment>
                        ),
                        style: { cursor: 'pointer' },
                        inputProps: { style: { pointerEvents: 'none' } },
                      }}
                    />
                  </Grid>
                  {showDatePicker && (
                    <div ref={this.setDayPickerRef} className={classes.dayPicker}>
                      <DayPicker
                        onDayClick={this.handleDateChange}
                        selectedDays={parse(this.state.selectedDate, 'MM/DD/YYYY', new Date())}
                        disabledDays={{ before: today }}
                        fromMonth={new Date(today.getFullYear(), today.getMonth())}
                        month={displayedMonth}
                        onMonthChange={this.handleMonthChange}
                      />
                    </div>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      className={classes.timeField}
                      select
                      value={time}
                      label="Delivery Time"
                      variant="outlined"
                      size="small"
                      onChange={this.handleTimeChange}
                      SelectProps={{
                        MenuProps: {
                          style: { maxHeight: 370, marginTop: 8 },
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        },
                      }}
                    >
                      {timeOptions.map((option, index) => (
                        <MenuItem
                          key={index}
                          value={option.value}
                          autoFocus={
                            time !== '' ? option.label === time : option.label === '12:00 PM'
                          }
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <ButtonSubmit
                  color="primary"
                  fullWidth
                  disabled={disableContinue}
                  onClick={this.handleNext}
                  variant="contained"
                  width={300}
                >
                  Continue to Checkout
                </ButtonSubmit>
              </Box>
              {errorUpdatingReserveDelivery !== '' && (
                <Box display="flex" justifyContent="center" marginTop={'2em'}>
                  <Typography color="error" align="center">
                    {errorUpdatingReserveDelivery}
                  </Typography>
                </Box>
              )}
            </Card>
          }
          SidebarComponent={
            <ReserveOrderSidebar
              shouldDisplayNextButton={false}
              handleClose={this.handleClose}
              handleNext={this.handleNext}
              handleEditOrder={this.handleEditOrder}
              handleAddItemsToCart={this.handleAddItemsToCart}
              handleRemoveItemFromCart={this.handleRemoveItemFromCart}
              personalizeOrderAddMoreIsPrimary
              personalizeOrderNextButtonIsHidden={true}
              showRestaurantLogo={true}
            />
          }
        />
      </AuthedComponent>
    )
  }
}

const LayoutInnerPageSkeleton = ({ locationId, storeId }) => {
  const headerComponent = (
    <HeaderInnerPage
      showBackButton
      backButtonRoute={formatUrl(placeReserveOrderRoute.path, { locationId, storeId })}
    >
      Back
    </HeaderInnerPage>
  )

  const mainComponentSkeleton = (
    <Card>
      <Box padding={2}>
        <Box display="flex" flexDirection="column" gap="1em">
          <Skeleton variant="rect" height={56} />
        </Box>
      </Box>
    </Card>
  )

  const sidebarComponentSkeleton = (
    <Card>
      <Box padding={2}>
        <Skeleton variant="rect" height={150} />
        <Skeleton variant="rect" height={150} style={{ marginTop: 16 }} />
      </Box>
    </Card>
  )

  return (
    <LayoutInnerPage
      HeaderComponent={headerComponent}
      MainComponent={mainComponentSkeleton}
      SidebarComponent={sidebarComponentSkeleton}
    />
  )
}

const mapStateToProps = state => {
  const { isMenuLoading, isOrderCartLoading, menu, menuItemId } = state.menu
  const { errorMessage: pastOrderError, isPastOrderLoading, pastOrders } = state.pastorder
  const {
    isEditingFromMenu,
    isEditingOrder,
    isEditingPastOrder,
    menuItemForEdit,
    orderCart,
    orderCartError,
  } = state.cart
  const { isLoadingFavorites, isLoadingNewPopularMenuItems, isLoadingPopularItems } = state.favorite
  const popularItems = getMenuInfoForPopularItems(state)
  const newPopularItems = getMenuInfoForNewPopularItems(state)
  const deliveryError = state.delivery.errorLoading
  const { isWebpSupported } = state.browser
  const reserveDeliveryDate = selectFormattedReserveDeliveryDate(state)
  const reserveDeliveryTime = selectFormattedReserveDeliveryTime(state)
  const isLoadingReserveDeliveryDateTime = state.delivery.isLoadingReserveDeliveryDateTime
  const { currentUser, isAuthenticated, isCurrentUserLoading } = state.user
  const { isReserveStoreLoading, reserveStore } = state.reserve

  return {
    currentUser,
    deliveryError,
    orderCartError,
    isAuthenticated,
    isCurrentUserLoading,
    isEditingFromMenu,
    isEditingOrder,
    isEditingPastOrder,
    isLoadingFavorites,
    isLoadingNewPopularMenuItems,
    isLoadingPopularItems,
    isMenuLoading,
    isOrderCartLoading,
    isPastOrderLoading,
    isWebpSupported,
    menu,
    menuItemForEdit,
    menuItemId,
    newPopularItems,
    pastOrderError,
    pastOrders,
    popularItems,
    orderCart,
    reserveStore,
    isReserveStoreLoading,
    reserveDeliveryDate,
    reserveDeliveryTime,
    isLoadingReserveDeliveryDateTime,
  }
}

const mapDispatchToProps = {
  addPastOrderToReserveCart,
  addItemToReserveCartStart,
  cancelEditingOrder,
  clearMenuItem,
  editReserveOrderInCart,
  loadEditOrder,
  loadFavoriteStart,
  loadReserveMenuStart,
  loadReserveOrderCartStart,
  loadPastOrdersReserveStart,
  loadPopularMenuItems,
  selectMenuItem,
  removeItemFromReserveOrderCartStart,
  loadReserveStoreStart,
  loadReserveDeliveryDateAndTimeStart,
  push,
  resetCheckoutError,
  selectDateStart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(PagePickReserveDeliveryDateTime))
