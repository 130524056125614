import { Box, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Form, Formik } from 'formik'
import { isNil } from 'lodash'
import React from 'react'
import { object, string } from 'yup'

import ButtonSubmit from '../../common/ButtonSubmit'
import { phoneRegExp } from '../../common/InputMasks'
import { validateBirthday } from './birthday'
import EditPersonalInformationFields from './EditPersonalInformationFields'

const validationSchema = object().shape({
  email: string()
    .required('Email address is required.')
    .email('Enter a valid email address.'),
  firstName: string()
    .required('First name is required.')
    .max(35, 'First name cannot exceed 35 characters.'),
  lastName: string()
    .required('Last name is required.')
    .max(35, 'Last name cannot exceed 35 characters.'),
  birthday: string().test(
    'is-valid-birthday',
    'Please enter a valid (MM/DD) birthday.',
    validateBirthday,
  ),
  phone: string()
    .required('Phone number is required.')
    .matches(phoneRegExp, 'Please enter a valid phone number.'),
})

const EditPersonalInformationForm = ({ onSubmit, onCancel, initialValues, submitting, error }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {props => (
        <Form>
          <EditPersonalInformationFields {...props} />
          <Box display="flex" justifyContent="flex-end" marginY={2}>
            <Button size="small" onClick={onCancel} style={{ marginRight: '8px' }}>
              Cancel
            </Button>
            <ButtonSubmit submitting={submitting} size="small" variant="contained" color="primary">
              Update
            </ButtonSubmit>
          </Box>
          {!isNil(error) && (
            <Alert severity="error">Something went wrong updating your profile.</Alert>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default EditPersonalInformationForm
