import React, { useEffect } from 'react'
import { Box, Button } from '@material-ui/core'
import { Alert, Skeleton } from '@material-ui/lab'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import queryString from 'query-string'

import Header from '../components/teams/admin/Header'
import DialogInviteMembers from '../components/teams/admin/DialogInviteMembers'
import { loadTeamMembersStart, resetState, setPage, setSearch } from '../redux/modules/teamMembers'
import InputSearchMembers from '../components/teams/admin/InputSearchMembers'
import TableAdminMembers from '../components/teams/admin/TableAdminMembers'
import { selectTeamMembers } from '../redux/selectors/teamMembersSelectors'
import { selectAccount } from '../redux/selectors/accountSelectors'
import { loadAccountStart } from '../redux/modules/account'

function PageTeamMembers({
  account,
  loadAccountStart,
  teamMembers,
  isLoadingTeam,
  errorLoadingTeam,
  errorLoadingTeamMembers,
  push,
  loadTeamMembersStart,
  resetState,
  setSearch,
  setPage,
  match,
}) {
  const accountId = match.params.accountId
  const handleOpenInviteDialog = () => {
    push({ search: queryString.stringify({ inviteMembers: true }) })
  }

  const handleSearch = value => {
    setPage(0)
    setSearch(value)
  }

  useEffect(() => {
    if (accountId) {
      loadTeamMembersStart(accountId)
    }
  }, [loadTeamMembersStart, accountId])

  useEffect(() => {
    if (account === undefined) {
      loadAccountStart(accountId)
    }
  }, [account, accountId, loadAccountStart])

  useEffect(() => {
    return () => {
      resetState()
    }
  }, [resetState])

  if (errorLoadingTeam) {
    return (
      <Alert severity="error">
        Sorry, something went wrong loading your team. Please try again or contact support.
      </Alert>
    )
  }

  return (
    <>
      <Header
        account={account}
        name={account?.name}
        Action={
          <Button variant="contained" color="primary" onClick={handleOpenInviteDialog}>
            Invite People
          </Button>
        }
      />
      <Box mt={3} bgcolor="white">
        <InputSearchMembers onChange={handleSearch} />
      </Box>
      <Box mt={3}>
        {errorLoadingTeamMembers && (
          <Alert severity="error">
            Sorry, something went wrong loading your team members. Please try again or contact
            support.
          </Alert>
        )}
        {isLoadingTeam ? <SkeletonLoading /> : teamMembers && <TableAdminMembers />}
      </Box>

      <DialogInviteMembers />
    </>
  )
}

const SkeletonLoading = () => (
  <>
    <Box marginY={3}>
      <Skeleton variant="rect" width="100%" height="42px" />
    </Box>
    <Skeleton variant="rect" width="100%" height="400px" />
  </>
)

const mapStateToProps = state => {
  const teamMembers = selectTeamMembers(state)
  const account = selectAccount(state)
  const { isLoadingTeam, errorLoadingTeam } = state.team
  const { errorLoadingTeamMembers } = state.teamMembers

  return {
    account,
    teamMembers,
    isLoadingTeam,
    errorLoadingTeam,
    errorLoadingTeamMembers,
  }
}

const mapDispatchToProps = {
  push,
  loadTeamMembersStart,
  loadAccountStart,
  resetState,
  setSearch,
  setPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTeamMembers)
