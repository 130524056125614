import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Formik } from 'formik'
import { isEmpty } from 'lodash'
import { object, string } from 'yup'

import ButtonSubmit from '../common/ButtonSubmit'
import DeliveryInstructionsField from './DeliveryInstructionsField'

const schema = object().shape({
  deliveryInstructions: string().required(),
})

export const DialogDeliveryInstructions = ({
  curSavedLocation,
  isUpdatingSavedLocation,
  showDeliveryInstructionsDialog,
  showOnEmptyDeliveryInstructions = false,
  toggleDeliveryInstructionsDialog,
  updateSavedLocationError,
  updateUserSavedLocationStart,
}) => {
  const [dismissed, setDismissed] = useState(false)

  const dialogOpen = Boolean(
    (showOnEmptyDeliveryInstructions &&
      isEmpty(curSavedLocation?.deliveryInstructions) &&
      curSavedLocation?.deliveryInstructionsSupported &&
      !dismissed) ||
      showDeliveryInstructionsDialog,
  )

  const handleClose = () => {
    setDismissed(true)
    showDeliveryInstructionsDialog && toggleDeliveryInstructionsDialog()
  }
  const handleSubmit = values => {
    updateUserSavedLocationStart(
      curSavedLocation.savedLocationId,
      null,
      values.deliveryInstructions,
    )
  }

  return (
    <Formik
      initialValues={{ deliveryInstructions: curSavedLocation?.deliveryInstructions ?? '' }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ errors, handleBlur, handleChange, handleSubmit }) => (
        <Dialog
          disableBackdropClick={isUpdatingSavedLocation}
          disableEscapeKeyDown={isUpdatingSavedLocation}
          fullWidth
          onClose={handleClose}
          open={dialogOpen}
        >
          <DialogTitle disableTypography>
            <Typography variant="h5">Provide delivery instructions</Typography>
            <Typography component="p" variant="body1">
              It looks like this is a new address, provide any additional information drivers may
              need to deliver to your location.
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DeliveryInstructionsField
              autoFocus
              defaultValue={curSavedLocation?.deliveryInstructions}
              error={errors.deliveryInstructions}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Dismiss</Button>
            <ButtonSubmit
              color="primary"
              variant="contained"
              disabled={isUpdatingSavedLocation}
              onClick={handleSubmit}
              submitting={isUpdatingSavedLocation}
            >
              Submit instructions
            </ButtonSubmit>
          </DialogActions>
          {updateSavedLocationError && <Alert severity="error">{updateSavedLocationError}</Alert>}
        </Dialog>
      )}
    </Formik>
  )
}

export default DialogDeliveryInstructions
