import { first, noop } from 'lodash'
import pluralize from 'pluralize'

import { subscriptionsCache } from '../../api/api'
import { selectTeam } from '../selectors/teamSelectors'
import {
  createSubscriptionApi,
  cancelSubscriptionApi,
  getSubscriptionDetailsApi,
  getSubscriptionOfferApi,
  updateSubscriptionApi,
  getIsSubscribed,
  getSubscriptionPriceApi,
  deleteSubscribeApi,
  subscribeApi,
  getAccountSubscriptionPricePerHeadInCentsApi,
} from '../../services/subscriptions'
import { createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'

import { enqueueSnackbar } from './snackbar'

const ERROR_MESSAGE_LOAD_SUBSCRIPTIONS =
  'There was a problem loading your Unlimited Delivery information. Please refresh the page and try again.'
const ERROR_MESSAGE_LOAD_NEW_SUBSCRIPTION_PRICE =
  'There was a problem calculating your Unlimited Delivery price. Please refresh the page and try again.'
const ERROR_MESSAGE_CANCEL_SUBSCRIPTION =
  'Something went wrong when canceling subscription. Please try again or contact support.'
const ERROR_MESSAGE_START_SUBSCRIPTION =
  'Something went wrong when starting your subscription. Please try again or contact support.'
const ERROR_MESSAGE_DELETE_SUBSCRIPTION =
  'Something went wrong when canceling your subscription. Please try again or contact support.'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const IS_SUBSCRIBED_SET = 'foodsby/subscriptions/IS_SUBSCRIBED_SET'
export const SUBSCRIPTION_DETAILS_SET = 'foodsby/subscriptions/SUBSCRIPTION_DETAILS_SET'
export const SUBSCRIPTION_PRICE_SET = 'foodsby/subscriptions/SUBSCRIPTION_PRICE_SET'
export const ACCOUNT_SUBSCRIPTION_PRICE_SET = 'foodsby/subscriptions/ACCOUNT_SUBSCRIPTION_PRICE_SET'
export const SUBSCRIPTION_OFFER_SET = 'foodsby/subscriptions/SUBSCRIPTION_OFFER_SET'
export const SUBSCRIPTION_SUBMIT = 'foodsby/subscriptions/SUBSCRIPTION_SUBMIT'
export const SUBSCRIPTION_START = 'foodsby/subscriptions/SUBSCRIPTION_START'
export const SUBSCRIPTION_DELETE = 'foodsby/subscriptions/SUBSCRIPTION_DELETE'

export const setIsSubscribed = createAsyncAction(IS_SUBSCRIBED_SET)
export const setSubscriptionDetails = createAsyncAction(SUBSCRIPTION_DETAILS_SET)
export const setSubscriptionPrice = createAsyncAction(SUBSCRIPTION_PRICE_SET)
export const setAccountSubscriptionPrice = createAsyncAction(ACCOUNT_SUBSCRIPTION_PRICE_SET)
export const setSubscriptionOffer = createAsyncAction(SUBSCRIPTION_OFFER_SET)
export const submitSubscription = createAsyncAction(SUBSCRIPTION_SUBMIT)
export const startSubscription = createAsyncAction(SUBSCRIPTION_START)
export const deleteSubscription = createAsyncAction(SUBSCRIPTION_DELETE)

// ------------------------------------
// Thunks
// ------------------------------------
export const loadIsSubscribedStart = () => dispatch => {
  return dispatch(setIsSubscribed(getIsSubscribed()))
}

export const loadSubscriptionDetailsStart = () => dispatch => {
  return dispatch(setSubscriptionDetails(getSubscriptionDetailsApi()))
}

export const loadSubscriptionOfferStart = () => dispatch => {
  return dispatch(setSubscriptionOffer(getSubscriptionOfferApi()))
}

export const createSubscriptionStart = paymentMethodId => dispatch => {
  return dispatch(submitSubscription(createSubscriptionApi(paymentMethodId))).then(() => {
    subscriptionsCache.reset()
  })
}

export const updateSubscriptionStart = (subscriptionId, paymentMethodId) => dispatch => {
  return dispatch(submitSubscription(updateSubscriptionApi(subscriptionId, paymentMethodId))).then(
    () => {
      subscriptionsCache.reset()
    },
  )
}

export const cancelSubscriptionStart = subscriptionId => dispatch => {
  return dispatch(submitSubscription(cancelSubscriptionApi(subscriptionId))).then(() => {
    subscriptionsCache.reset()
  })
}

export const loadSubscriptionPriceStart = (teamId, memberCount) => dispatch => {
  return dispatch(setSubscriptionPrice(getSubscriptionPriceApi(teamId, memberCount)))
}

export const loadAccountSubscriptionPriceStart = () => {
  return (dispatch, getState) => {
    const state = getState()
    const team = selectTeam(state)
    return dispatch(
      setAccountSubscriptionPrice(getAccountSubscriptionPricePerHeadInCentsApi(team.id)),
    )
  }
}

export const subscribeStart = (teamId, payload, onSuccess = noop) => {
  return async dispatch => {
    try {
      await dispatch(startSubscription(subscribeApi(teamId, payload)))

      const numOfRecipients = payload.userIds.length
      dispatch(
        enqueueSnackbar({
          message: `You have successfully subscribed ${numOfRecipients} ${pluralize(
            'recipient',
            numOfRecipients,
          )}.`,
        }),
      )
      onSuccess()
    } catch (ex) {
      dispatch(
        enqueueSnackbar({
          message: `There was a problem when completing your subscription. Please try again or contact support..`,
        }),
      )
    }
  }
}

export const deleteSubscribeStart = (teamId, onSuccess = noop) => {
  return async dispatch => {
    try {
      await dispatch(deleteSubscription(deleteSubscribeApi(teamId)))

      dispatch(
        enqueueSnackbar({
          message: `You have successfully canceled your subscription.`,
        }),
      )
      onSuccess()
    } catch (ex) {
      dispatch(
        enqueueSnackbar({
          message: `There was a problem when canceling your subscription. Please try again or contact support..`,
        }),
      )
    }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [FULFILLED(IS_SUBSCRIBED_SET)]: (state, action) => ({
    ...state,
    isSubscribed: action.payload,
  }),
  [PENDING(SUBSCRIPTION_DETAILS_SET)]: state => ({
    ...state,
    isLoadingSubscriptionDetails: true,
  }),
  [FULFILLED(SUBSCRIPTION_DETAILS_SET)]: (state, action) => ({
    ...state,
    subscriptionDetails: first(action.payload) ?? null,
    isLoadingSubscriptionDetails: false,
    errorLoadingSubscriptionDetails: undefined,
  }),
  [REJECTED(SUBSCRIPTION_DETAILS_SET)]: state => ({
    ...state,
    isLoadingSubscriptionDetails: false,
    errorLoadingSubscriptionDetails: ERROR_MESSAGE_LOAD_SUBSCRIPTIONS,
  }),
  [PENDING(SUBSCRIPTION_PRICE_SET)]: state => ({
    ...state,
    isLoadingNewSubscriptionPrice: true,
    errorLoadingNewSubscriptionPrice: undefined,
  }),
  [FULFILLED(SUBSCRIPTION_PRICE_SET)]: (state, action) => ({
    ...state,
    newSubscriptionPrice: action.payload ?? null,
    isLoadingNewSubscriptionPrice: false,
    errorLoadingNewSubscriptionPrice: undefined,
  }),
  [REJECTED(SUBSCRIPTION_PRICE_SET)]: state => ({
    ...state,
    isLoadingNewSubscriptionPrice: false,
    errorLoadingNewSubscriptionPrice: ERROR_MESSAGE_LOAD_NEW_SUBSCRIPTION_PRICE,
  }),
  [PENDING(ACCOUNT_SUBSCRIPTION_PRICE_SET)]: state => ({
    ...state,
    isLoadingNewSubscriptionPrice: true,
    errorLoadingNewSubscriptionPrice: undefined,
  }),
  [FULFILLED(ACCOUNT_SUBSCRIPTION_PRICE_SET)]: (state, action) => ({
    ...state,
    accountSubscriptionPrice: action.payload.pricePerHeadInCents,
    isLoadingNewSubscriptionPrice: false,
    errorLoadingNewSubscriptionPrice: undefined,
  }),
  [REJECTED(ACCOUNT_SUBSCRIPTION_PRICE_SET)]: state => ({
    ...state,
    isLoadingNewSubscriptionPrice: false,
    errorLoadingNewSubscriptionPrice: ERROR_MESSAGE_LOAD_NEW_SUBSCRIPTION_PRICE,
  }),
  [PENDING(SUBSCRIPTION_OFFER_SET)]: state => ({
    ...state,
    isLoadingSubscriptionOffer: true,
  }),
  [FULFILLED(SUBSCRIPTION_OFFER_SET)]: (state, action) => ({
    ...state,
    subscriptionOffer: action.payload,
    isLoadingSubscriptionOffer: false,
  }),
  [REJECTED(SUBSCRIPTION_OFFER_SET)]: state => ({
    ...state,
    subscriptionOffer: undefined,
    isLoadingSubscriptionOffer: false,
  }),
  [PENDING(SUBSCRIPTION_SUBMIT)]: state => ({
    ...state,
    isSubmittingSubscription: true,
    successSubmittingSubscription: false,
    errorSubmittingSubscription: undefined,
  }),
  [FULFILLED(SUBSCRIPTION_SUBMIT)]: state => ({
    ...state,
    isSubmittingSubscription: false,
    successSubmittingSubscription: true,
    errorSubmittingSubscription: undefined,
  }),
  [REJECTED(SUBSCRIPTION_SUBMIT)]: state => ({
    ...state,
    isSubmittingSubscription: false,
    successSubmittingSubscription: false,
    errorSubmittingSubscription: ERROR_MESSAGE_CANCEL_SUBSCRIPTION,
  }),
  [PENDING(SUBSCRIPTION_START)]: state => ({
    ...state,
    isStartingSubscription: true,
    successStartedSubscription: false,
    errorStartingSubscription: undefined,
  }),
  [FULFILLED(SUBSCRIPTION_START)]: state => ({
    ...state,
    isStartingSubscription: false,
    successStartedSubscription: true,
    errorStartingSubscription: undefined,
  }),
  [REJECTED(SUBSCRIPTION_START)]: state => ({
    ...state,
    isStartingSubscription: false,
    successStartedSubscription: false,
    errorStartingSubscription: ERROR_MESSAGE_START_SUBSCRIPTION,
  }),
  [PENDING(SUBSCRIPTION_DELETE)]: state => ({
    ...state,
    isDeletingSubscription: true,
    successDeletedSubscription: false,
    errorDeletingSubscription: undefined,
  }),
  [FULFILLED(SUBSCRIPTION_DELETE)]: state => ({
    ...state,
    isDeletingSubscription: false,
    successDeletedSubscription: true,
    errorDeletingSubscription: undefined,
  }),
  [REJECTED(SUBSCRIPTION_DELETE)]: state => ({
    ...state,
    isDeletingSubscription: false,
    successDeletedSubscription: false,
    errorDeletingSubscription: ERROR_MESSAGE_DELETE_SUBSCRIPTION,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  isSubscribed: undefined,
  subscriptionDetails: undefined,
  subscriptionOffer: undefined,
  // Loading states
  isLoadingSubscriptionDetails: false,
  isLoadingSubscriptionOffer: false,
  isSubmittingSubscription: false,
  // Success states
  successSubmittingSubscription: false,
  // Error states
  errorLoadingSubscriptionDetails: undefined,
  errorSubmittingSubscription: undefined,
}

export default function subscriptions(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
