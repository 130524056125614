import React, { useEffect } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import ButtonDialogClose from '../../teams/common/ButtonDialogClose'
import ButtonSubmit from '../../common/ButtonSubmit'

const DialogLeaveConfirmation = ({ team, submitting, error, onClose, onConfirm, onUnmount }) => {
  useEffect(() => {
    return () => {
      onUnmount()
    }
  }, [onUnmount])

  return (
    <Dialog open>
      <DialogTitle>
        {/* TODO Move this button dialog close to shared component */}
        Are you sure you want to leave? <ButtonDialogClose onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          By leaving {team.name}, you will lose access to all of your team benefits.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonSubmit
          color="primary"
          variant="contained"
          onClick={onConfirm}
          submitting={submitting}
        >
          Confirm
        </ButtonSubmit>
      </DialogActions>
      {error && <Alert severity="error">{error}</Alert>}
    </Dialog>
  )
}

export default DialogLeaveConfirmation
