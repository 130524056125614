import { createAction } from '../utils'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const SNACKBAR_ENQUEUE = 'foodsby/snackbar/ENQUEUE_SNACKBAR'
export const SNACKBAR_DISMISS = 'foodsby/snackbar/SNACKBAR_DISMISS'
export const SNACKBAR_REMOVE = 'foodsby/snackbar/SNACKBAR_REMOVE'

export const enqueueSnackbar = createAction(SNACKBAR_ENQUEUE)
export const dismissSnackbar = createAction(SNACKBAR_DISMISS)
export const removeSnackbar = createAction(SNACKBAR_REMOVE)

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SNACKBAR_ENQUEUE]: (state, action) => {
    return {
      ...state,
      snackbars: [
        ...state.snackbars,
        { key: new Date().getTime() + Math.random(), ...action.payload },
      ],
    }
  },
  [SNACKBAR_DISMISS]: (state, action) => {
    return {
      ...state,
      snackbars: state.snackbars.map(snackbar =>
        snackbar.key === action.payload ? { ...snackbar, dismissed: true } : { ...snackbar },
      ),
    }
  },
  [SNACKBAR_REMOVE]: (state, action) => {
    return {
      ...state,
      snackbars: state.snackbars.filter(snackbar => snackbar.key !== action.payload),
    }
  },
}

export const initialState = {
  snackbars: [],
}

export default function snackbar(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
