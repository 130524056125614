import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { isArray, last } from 'lodash'

const useStyles = makeStyles(theme => ({
  textField: {
    maxHeight: '500px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '320px',
    },
    '& .MuiInputBase-root': {
      alignItems: 'flex-start',
    },
  },
}))

const InputMemberEmails = ({ name, fieldValue, setFieldValue, error }) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState()
  const validationError = isArray(error) ? last(error) : error

  const handleInputChange = (event, newInputValue) => {
    const options = newInputValue.split(',')

    if (options.length > 1) {
      setFieldValue(
        'memberEmails',
        fieldValue
          .concat(options)
          .map(x => x.trim())
          .filter(x => x),
      )
    } else {
      setInputValue(newInputValue)
    }
  }

  const handleBlur = () => {
    const options = inputValue?.split(',')

    if (options) {
      const value = fieldValue
        .concat(options)
        .map(x => x.trim())
        .filter(x => x)

      setFieldValue('memberEmails', value)
    }
  }

  return (
    <Autocomplete
      multiple
      freeSolo
      value={fieldValue}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        setFieldValue('memberEmails', newValue)
      }}
      onBlur={handleBlur}
      onInputChange={handleInputChange}
      options={[]}
      renderInput={params => (
        <TextField
          {...params}
          name={name}
          multiline
          minRows={7}
          className={classes.textField}
          fullWidth
          variant="outlined"
          label="To:"
          placeholder="Add email addresses here, separated by a comma."
          error={Boolean(error)}
          helperText={validationError}
        />
      )}
    />
  )
}

export default InputMemberEmails
