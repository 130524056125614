import { themeConWeb } from '@app/util/modernThemeConweb'
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Add, ArrowForwardIos, Close, Warning } from '@material-ui/icons'
import currency from 'currency.js'
import React, { Component } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { connect } from 'react-redux'
import { change, destroy, isValid, reduxForm, unregisterField } from 'redux-form'
import styled from 'styled-components'
import { push } from 'connected-react-router'

import {
  addItemToCartStart,
  cancelEditingOrder,
  clearMenuItemsForEdit,
  editOrderInCart,
} from '../../redux/modules/cart'
import { resetCheckoutError } from '../../redux/modules/checkout'
import {
  changeMenuAnswer,
  changeQuantity,
  clearPersonalize,
  getDescendantQuestionIds,
  loadMenuItemStart,
} from '../../redux/modules/personalize'
import {
  getDisplayedQuestions,
  orderSubtotal,
  personalizeFormTouched,
} from '../../redux/modules/selectors'
import ButtonSubmit from '../common/ButtonSubmit'
import Favorite from '../community/Favorite'
import parseMenuQuestionErrors from './parseMenuQuestionErrors'
import PersonalizeOrderForm, { formName, questionPrefix } from './PersonalizeOrderForm'

const PersonalizeOrderFormContainer = reduxForm({
  form: formName,
})(PersonalizeOrderForm)

export class PersonalizeOrder extends Component {
  componentDidMount() {
    const { loadMenuItemStart, menuItemId } = this.props
    loadMenuItemStart(menuItemId)
  }

  componentWillUnmount() {
    const { clearMenuItemsForEdit, clearPersonalize, destroy } = this.props
    destroy(formName)
    clearMenuItemsForEdit()
    clearPersonalize()
  }

  radioChange = (event, changeMenuAnswer, questions, answers) => {
    let targetQuestions = getDescendantQuestionIds(event.questionId, questions, answers)
    this.resetChildFormQuestions(targetQuestions)
    event.descendants = targetQuestions
    changeMenuAnswer(event)
  }

  checkboxChange = (event, changeMenuAnswer, questions, answers) => {
    if (!event.selected) {
      let targetQuestions = []
      answers[event.answerId].questions.forEach(questionId => {
        targetQuestions.push(
          questionId,
          ...getDescendantQuestionIds(questionId, questions, answers),
        )
      })
      this.resetChildFormQuestions(targetQuestions)
      event.descendants = targetQuestions
    }
    changeMenuAnswer(event)
  }

  quantityChange = event => {
    const { changeQuantity } = this.props
    changeQuantity(event.target.value)
  }

  resetChildFormQuestions = questionIds => {
    const { change } = this.props
    questionIds.forEach(id => {
      change(formName, `${questionPrefix}${id}`, '')
      unregisterField(formName, `${questionPrefix}${id}`)
    })
  }

  addItemsToCart = (itemValues, checkout) => {
    const { menuItemId, handleAddItemsToCart, quantity } = this.props
    handleAddItemsToCart(menuItemId, itemValues, checkout, quantity)
  }

  editOrder = (itemValues, checkout) => {
    const { handleEditOrder, menuItemId, menuItemForEdit, quantity } = this.props

    handleEditOrder(menuItemForEdit.orderItemId, menuItemId, itemValues, checkout, quantity)
  }

  render() {
    const {
      answers,
      changeMenuAnswer,
      checkoutCartLength,
      checkoutCartTotal,
      displayedQuestions,
      editedOrderItemPrice,
      errorsForDisplay,
      isCartSaving,
      loading,
      isEditingFromCheckout,
      handleClose,
      isEditingFromMenu,
      isEditingOrder,
      isEditingPastOrder,
      location = {},
      menuItem,
      menuItemId,
      menuId,
      menuItemForEdit,
      defaultAnswers,
      orderCartError,
      orderCartLength,
      orderCartTotal,
      orderSubtotal,
      personalizeError,
      questions,
      personalizeFormTouched,
      submitFailed,
      isValid,
      addMoreIsHidden,
      nextButtonIsHidden,
      nextButtonIsDisabled,
      addMoreIsPrimary,
      nextButtonDisplayText,
      handleNextButtonClick,
      handleAddItemButtonClick,
      enableQuantity,
      addItemDisplayText,
    } = this.props
    var nextButtonText = 'Continue to Checkout'
    var addItemText = 'Add More Items'
    if (nextButtonDisplayText !== undefined) {
      nextButtonText = nextButtonDisplayText
    }
    if (addItemDisplayText !== undefined) {
      addItemText = addItemDisplayText
    }
    return (
      <>
        {loading ? (
          <Backdrop open={true} style={{ zIndex: '1101' }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Dialog
            elevation="elevation0"
            fullScreen={640 >= window.innerWidth}
            fullWidth={true}
            keepMounted
            maxWidth="md"
            open={true}
            scroll="paper"
          >
            <DialogTitle>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box alignItems="center" display="flex">
                  <Favorite id={menuItemId} menuId={menuId} />
                  <Dotdotdot clamp={2}>
                    <Typography component="h1" variant="h5">
                      {menuItem.text}
                    </Typography>
                  </Dotdotdot>
                </Box>
                <IconButton aria-label="close" onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
              <Dotdotdot clamp={2.5}>
                <Typography component="p" variant="body1">
                  {menuItem.description}
                </Typography>
              </Dotdotdot>
            </DialogTitle>
            <DialogContent dividers>
              <PersonalizeOrderFormContainer
                answers={answers}
                checkoutCartLength={checkoutCartLength}
                checkoutCartTotal={checkoutCartTotal}
                destroyOnUnmount={false}
                displayedQuestions={displayedQuestions}
                editedOrderItemPrice={editedOrderItemPrice}
                enableReinitialize={!isEditingPastOrder}
                initialValues={menuItemForEdit && isEditingOrder ? menuItemForEdit : defaultAnswers}
                isCartSaving={isCartSaving}
                isEditingFromCheckout={isEditingFromCheckout}
                isEditingFromMenu={isEditingFromMenu}
                isEditingOrder={isEditingOrder}
                isOpen={true}
                locationId={location.deliveryLocationId}
                menuItemForEdit={menuItemForEdit}
                onCheckboxChange={event =>
                  this.checkboxChange(event, changeMenuAnswer, questions, answers)
                }
                onRadioChange={event =>
                  this.radioChange(event, changeMenuAnswer, questions, answers)
                }
                onSubmit={
                  isEditingOrder && (isEditingFromCheckout || isEditingFromMenu)
                    ? this.editOrder
                    : this.addItemsToCart
                }
                orderCartLength={orderCartLength}
                orderCartTotal={orderCartTotal}
                orderSubtotal={orderSubtotal}
                personalizeFormError={orderCartError || personalizeError}
                questions={questions}
                enableQuantity={enableQuantity}
                onQuantityChange={this.quantityChange}
              />
            </DialogContent>
            <DialogActions>
              <PersonalizeOrderFooter>
                {addMoreIsPrimary && !addMoreIsHidden ? (
                  <PersonalizeOrderActions>
                    <ButtonSubmit
                      color="secondary"
                      onClick={() => {
                        // manually trigger a form to the Personalize Order From ButtonSubmit
                        document.getElementById('add-to-checkout').click()
                        if (typeof handleNextButtonClick === 'function' && !isCartSaving) {
                          handleNextButtonClick()
                        }
                      }}
                      style={{ visibility: addMoreIsHidden || nextButtonIsHidden ? 'hidden' : '' }}
                      disabled={nextButtonIsDisabled}
                      submitting={isCartSaving}
                      variant="outlined"
                    >
                      {nextButtonText} <ArrowForwardIos style={{ fontSize: 20, paddingLeft: 4 }} />
                    </ButtonSubmit>
                    <PersonalizeOrderCheckout>
                      <Typography style={{ paddingRight: '16px' }} variant="body2">
                        Item Total:{' '}
                        {currency(orderSubtotal?.value, {
                          formatWithSymbol: true,

                          precision: 2,
                        }).format()}
                      </Typography>
                      <ButtonSubmit
                        color="primary"
                        onClick={() => {
                          // manually trigger a form to the Personalize Order From ButtonSubmit
                          document.getElementById('add-to-checkout').click()
                          if (typeof handleAddItemButtonClick === 'function' && !isCartSaving) {
                            handleAddItemButtonClick()
                          }
                        }}
                        submitting={isCartSaving}
                        variant="contained"
                        width="100%"
                      >
                        <Add fontSize="small" />
                        {addItemText}
                      </ButtonSubmit>
                    </PersonalizeOrderCheckout>
                  </PersonalizeOrderActions>
                ) : (
                  <PersonalizeOrderActions>
                    <ButtonSubmit
                      color="secondary"
                      onClick={() => {
                        // manually trigger a form to the Personalize Order From ButtonSubmit
                        document.getElementById('add-to-checkout').click()
                        if (typeof handleAddItemButtonClick === 'function' && !isCartSaving) {
                          handleAddItemButtonClick()
                        }
                      }}
                      style={{ visibility: addMoreIsHidden ? 'hidden' : '' }}
                      submitting={isCartSaving}
                      variant="outlined"
                    >
                      <Add fontSize="small" />
                      {addItemText}
                    </ButtonSubmit>
                    <PersonalizeOrderCheckout>
                      <Typography style={{ paddingRight: '16px' }} variant="body2">
                        Item Total:{' '}
                        {currency(orderSubtotal?.value, {
                          formatWithSymbol: true,

                          precision: 2,
                        }).format()}
                      </Typography>
                      <ButtonSubmit
                        color="primary"
                        onClick={() => {
                          // manually trigger a form to the Personalize Order From ButtonSubmit
                          document.getElementById('move-to-checkout').click()
                          if (typeof handleNextButtonClick === 'function' && !isCartSaving) {
                            handleNextButtonClick()
                          }
                        }}
                        submitting={isCartSaving}
                        style={{ visibility: nextButtonIsHidden ? 'hidden' : '' }}
                        disabled={nextButtonIsDisabled}
                        variant="contained"
                        width="100%"
                      >
                        {nextButtonText}{' '}
                        <ArrowForwardIos style={{ fontSize: 20, paddingLeft: 4 }} />
                      </ButtonSubmit>
                    </PersonalizeOrderCheckout>
                  </PersonalizeOrderActions>
                )}
                <Box paddingTop={2}>
                  {personalizeFormTouched &&
                    submitFailed &&
                    !isValid &&
                    errorsForDisplay?.map((error, key) => <ErrorMessage error={error} key={key} />)}
                </Box>
              </PersonalizeOrderFooter>
            </DialogActions>
          </Dialog>
        )}
      </>
    )
  }
}

const useStyles = makeStyles({
  warningIcon: {
    color: themeConWeb.color.dangerLight,
    fontSize: '18px',
    marginRight: '4px',
  },
})

const ErrorMessage = ({ error }) => {
  const classes = useStyles()
  return (
    <Box alignItems="center" display="flex" flexDirection="row" justifyContent="flex-end">
      <Warning className={classes.warningIcon} />
      <Typography color="error" name="error" variant="body1">
        {error}
      </Typography>
    </Box>
  )
}

PersonalizeOrder.defaultProps = {
  menuItem: { questions: [] },
}

const mapStateToProps = state => {
  const { error, loading } = state.personalize
  const submitFailed = state?.form?.personalize?.submitFailed
  const syncErrors = state?.form?.personalize?.syncErrors
  let menuItems = {},
    questions,
    answers,
    result
  if (state.personalize.normalizedMenuItem) {
    // eslint-disable-next-line no-extra-semi
    ;({
      entities: { answers, menuItems, questions },
      result,
    } = state.personalize.normalizedMenuItem)
  }
  let { defaultAnswers } = state.personalize
  const menuItem = menuItems[result]
  const {
    checkoutCart,
    isCartSaving,
    isEditingPastOrder,
    menuItemForEdit,
    orderCart,
    orderCartError,
  } = state.cart
  const { isAuthenticated } = state.user
  const { location } = state.location
  const editedItem = orderCart.orderItems.find(orderItem => {
    if (orderItem && orderItem.orderItemId && menuItemForEdit && menuItemForEdit.orderItemId) {
      return orderItem.orderItemId === menuItemForEdit.orderItemId
    }
    return false
  })
  const errorsForDisplay = syncErrors ? parseMenuQuestionErrors(syncErrors, questions) : []

  return {
    answers,
    checkoutCartLength: checkoutCart.orderItems.length,
    checkoutCartTotal: checkoutCart.itemSubTotal,
    displayedQuestions: getDisplayedQuestions(state),
    editedOrderItemPrice: editedItem && editedItem.fullSubTotal,
    errorsForDisplay,
    isAuthenticated,
    isCartSaving,
    isEditingPastOrder,
    isValid: isValid(formName)(state),
    loading,
    location,
    menuItem,
    menuItemForEdit,
    defaultAnswers,
    orderCartError,
    orderCartLength: orderCart.orderItems.length,
    orderCartTotal: orderCart.itemSubTotal,
    orderSubtotal: orderSubtotal(state), // This matches the item currently open in the personalize form.
    personalizeError: error,
    personalizeFormTouched: personalizeFormTouched(state),
    questions,
    submitFailed,
  }
}

const mapDispatchToProps = {
  addItemToCartStart,
  cancelEditingOrder,
  change,
  changeMenuAnswer,
  changeQuantity,
  resetCheckoutError,
  clearMenuItemsForEdit,
  clearPersonalize,
  destroy,
  editOrderInCart,
  loadMenuItemStart,
  push,
}

const PersonalizeOrderFooter = styled.div`
  @media ${themeConWeb.small} {
    padding: 8px;
  }

  padding: 32px;
  width: 100%;
`
const PersonalizeOrderActions = styled.div`
  @media ${themeConWeb.small} {
    flex-direction: column-reverse;
    align-items: center;
  }
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const PersonalizeOrderCheckout = styled.div`
  @media ${themeConWeb.small} {
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
  }
  align-items: center;
  display: flex;
`

export default connect(mapStateToProps, mapDispatchToProps)(PersonalizeOrder)
