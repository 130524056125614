import React, { useCallback } from 'react'
import { Grid, TextField } from '@material-ui/core'
import { get } from 'lodash'

import { TextMaskBirthday, TextMaskPhone } from '../../common/InputMasks'

const EditPersonalInformationFields = ({ errors, touched, values, handleBlur, handleChange }) => {
  const getError = useCallback(name => get(touched, name) && get(errors, name), [errors, touched])

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          name="firstName"
          error={!!getError('firstName')}
          helperText={getError('firstName')}
          id="firstName"
          label="First Name"
          onBlur={handleBlur}
          onChange={handleChange}
          size="small"
          value={values.firstName}
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="lastName"
          error={!!getError('lastName')}
          helperText={getError('lastName')}
          label="Last Name"
          onBlur={handleBlur}
          onChange={handleChange}
          size="small"
          value={values.lastName}
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="phone"
          error={!!getError('phone')}
          fullWidth
          helperText={getError('phone')}
          InputProps={{
            inputComponent: TextMaskPhone,
          }}
          label="Phone Number"
          onBlur={handleBlur}
          onChange={handleChange}
          size="small"
          type="tel"
          value={values.phone}
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="birthday"
          error={!!getError('birthday')}
          fullWidth
          helperText={getError('birthday')}
          InputProps={{
            inputComponent: TextMaskBirthday,
          }}
          label="Birthday"
          onBlur={handleBlur}
          onChange={handleChange}
          size="small"
          value={values.birthday}
          placeholder="MM/DD"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="email"
          error={!!getError('email')}
          fullWidth
          helperText={getError('email')}
          label="Work email"
          onBlur={handleBlur}
          onChange={handleChange}
          size="small"
          type="email"
          value={values.email}
          variant="outlined"
          color="secondary"
        />
      </Grid>
    </Grid>
  )
}

export default EditPersonalInformationFields
