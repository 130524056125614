import React, { useCallback, useRef } from 'react'
import { GoogleMap, Marker, Polygon, useLoadScript } from '@react-google-maps/api'
import { themeConWeb } from '../../util/modernThemeConweb'
import ParkingMarker from './ParkingMarker'

const mapContainerStyle = { height: '500px', width: '100%' }

const libraries = ['places']

const LocationDeliveryMap = ({
  latitude,
  longitude,
  parkingLatitude,
  parkingLongitude,
  polygonCoordinates,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  })

  const polygonRef = useRef(null)

  const onLoadPolygon = useCallback(polygon => {
    polygonRef.current = polygon
  }, [])

  const onUnmountPolygon = useCallback(() => {
    polygonRef.current = null
  }, [])

  if (loadError) {
    return <div>Error loading maps</div>
  }
  if (!isLoaded) {
    return <div>Loading Maps...</div>
  }

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={{ lat: latitude, lng: longitude }}
      zoom={17}
      mapTypeId="hybrid"
      options={{
        tilt: 0,
        mapTypeControlOptions: { mapTypeIds: ['roadmap', 'hybrid'] },
        gestureHandling: 'cooperative',
      }}
    >
      <Marker position={{ lat: latitude, lng: longitude }} />
      {parkingLatitude && parkingLongitude && (
        <ParkingMarker latitude={parkingLatitude} longitude={parkingLongitude} />
      )}
      <Polygon
        editable={false}
        draggable={false}
        path={polygonCoordinates}
        onLoad={onLoadPolygon}
        onUnmount={onUnmountPolygon}
        options={{
          strokeColor: themeConWeb.color.danger,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: themeConWeb.color.danger,
          fillOpacity: 0.35,
        }}
      />
    </GoogleMap>
  )
}

export default LocationDeliveryMap
