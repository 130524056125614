import { Dialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const DialogMarketing = withStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
    },
    '& .MuiDialogContent-root': {
      maxHeight: '500px',
    },
    '& .MuiDialogTitle-root': {
      textAlign: 'center',
    },
    '& .MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
    },
  },
}))(Dialog)

export default DialogMarketing
