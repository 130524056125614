import React from 'react'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { toUpper } from 'lodash'

import { themeConWeb } from '../../../util/modernThemeConweb'

const useStyles = makeStyles({
  root: {
    width: '100px',
    borderStyle: 'solid',
    borderWidth: '2px',
    fontWeight: 700,
  },
  active: {
    color: themeConWeb.color.success,
    borderColor: themeConWeb.color.success,
  },
  upcoming: {
    color: themeConWeb.color.oneShade2,
    borderColor: themeConWeb.color.oneShade2,
  },
  inactive: {
    color: themeConWeb.color.greyShade3,
    borderColor: themeConWeb.color.greyShade3,
  },
})

const ChipPerkStatus = ({ status }) => {
  const classes = useStyles()

  const className =
    status === 'active'
      ? classes.active
      : status === 'upcoming'
      ? classes.upcoming
      : classes.inactive

  return (
    <Chip
      label={toUpper(status)}
      className={cn(classes.root, className)}
      variant="outlined"
      size="small"
    />
  )
}

ChipPerkStatus.propTypes = {
  status: PropTypes.oneOf(['active', 'upcoming', 'inactive']),
}

export default ChipPerkStatus
