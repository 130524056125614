export const formatDisplayAddress = (street, city, state, zip) => {
  return [street, city, `${state} ${zip}`.trim()].join(', ')
}

const locationTypeOptions = {
  ManagedProperty: 'Managed Property',
  SingleCompany: 'Single Company',
  GroupOrder: 'Group Order',
  ResidentialComplex: 'Residential Complex',
  ResidentialZone: 'Residential Zone',
  ResidentialHome: 'Unknown',
}

export const locationTypeDisplayText = locationType => locationTypeOptions[locationType]

export const locationStatusOptions = {
  Prospect: 'Prospect',
  UserCreated: 'User Created',
  Unqualified: 'Unqualified',
  Preferred: 'Preferred',
  NonPreferred: 'Non-Preferred',
}

export const locationStatusDisplayText = locationStatus => locationStatusOptions[locationStatus]

export const estimatedEmployeeCountOptions = {
  LESS_THAN_50: '<50',
  BETWEEN_50_AND_250: '50-250',
  OVER_250: '250+',
}

export const estimatedEmployeeCountDisplayText = estimatedEmployeeCount =>
  estimatedEmployeeCountOptions[estimatedEmployeeCount] || 'Unknown'

export const officeLocationRelationshipTypeOptions = Object.freeze({
  SINGLE_COMPANY: 'Single Company',
  TENANT: 'Tenant',
  BUILDING_OWNER: 'Building Owner',
  LEASING_AGENT: 'Leasing Agent',
  PROPERTY_MANAGER: 'Property Manager',
})

export const officeLocationRelationshipTypeDisplayText = officeLocationRelationshipType =>
  officeLocationRelationshipTypeOptions[officeLocationRelationshipType] || 'Unknown'
