import React from 'react'

const DeliverySection = ({ HeaderComponent, children }) => {
  return (
    <>
      <HeaderComponent />
      {children}
    </>
  )
}

export default DeliverySection
