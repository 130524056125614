import { themeConWeb } from '@app/util/modernThemeConweb'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import currency from 'currency.js'
import React from 'react'

import LinkButton from '../common/LinkButton'
import Favorite from '../community/Favorite'

const useStyles = makeStyles({
  destructiveButton: {
    color: themeConWeb.color.danger,
  },
  primaryText: {
    fontSize: '14px',
  },
  quantityText: {
    fontSize: '14px',
    marginRight: '12px',
  },
  root: {
    marginBottom: '12px',
  },
  outerGrid: {
    display: 'flex',
    flexDirection: 'row',
  },
  hideOnPrint: {
    '@media print': {
      display: 'none',
    },
  },
})

const CartModifierList = ({ modifiers, specialInstructions }) => (
  <ul>
    {modifiers?.map(modifier =>
      modifier.answers?.map(({ menuItem: { displayText, menuItemId, price } }) => (
        <Typography component="li" key={menuItemId} variant="caption">
          {displayText}
          {price ? ` +${parseFloat(price).toFixed(2)}` : null}
        </Typography>
      )),
    )}
    {specialInstructions && (
      <Typography variant="caption">Special instructions: {specialInstructions}</Typography>
    )}
  </ul>
)

const CartMenuItem = ({
  deleteMenuItem,
  isDeletingMenuItem,
  editMenuItem,
  isEditingMenuItem,
  editable,
  isEditingPastOrder,
  menuId,
  menuItem: {
    fullSubTotal,
    fullSubtotal, //we are transition to lowercase T but it isn't on ever endpoint yet, so have to support both :(
    menuItem: { displayText, price },
    menuItemId,
    modifiers,
    orderItemId,
    specialInstructions,
    quantity,
  },
  showEditLinks,
  useFullSubTotal,
  showQuantity,
}) => {
  const classes = useStyles()

  const handleEditItem = () => editMenuItem(null, orderItemId, isEditingPastOrder)

  const handleDeleteItem = () => deleteMenuItem(orderItemId)

  const total = useFullSubTotal ? fullSubTotal || fullSubtotal : price

  const favoriteAndQuantity = showQuantity && !editable

  const menuItem = (
    <Grid className={classes.root} component="li" container>
      <Grid item xs={8}>
        <Box display="flex" flexDirection="row">
          {!editable && (
            <Favorite
              id={menuItemId}
              menuId={menuId}
              parentComponentName={
                window?.location?.pathname?.includes('confirmation')
                  ? 'Confirmation'
                  : 'Order History'
              }
              style={{ marginTop: '0px' }}
              className={classes.hideOnPrint}
            />
          )}
          <Typography className={classes.primaryText}>
            {displayText} {favoriteAndQuantity && quantity > 1 ? ` x ${quantity}` : ``}
          </Typography>
        </Box>
      </Grid>
      <Grid component={Box} display="flex" item justifyContent="flex-end" xs={4}>
        <Typography className={classes.primaryText}>
          {currency(total, { formatWithSymbol: true }).format()}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ marginLeft: !editable ? '1.8em' : null, marginTop: !editable ? '-0.5em' : null }}
      >
        <CartModifierList modifiers={modifiers} specialInstructions={specialInstructions} />
      </Grid>
      <Grid item xs={12}>
        {editable && showEditLinks && (
          <Box marginTop={1}>
            <Box display="inline" marginRight={3}>
              <LinkButton onClick={handleEditItem} size="x-small" submitting={isEditingMenuItem}>
                Edit
              </LinkButton>
            </Box>
            <LinkButton
              color={themeConWeb.color.dangerLight}
              onClick={handleDeleteItem}
              size="x-small"
              submitting={isDeletingMenuItem}
            >
              Remove
            </LinkButton>
          </Box>
        )}
      </Grid>
    </Grid>
  )
  if (showQuantity && !favoriteAndQuantity) {
    return (
      <Grid className={classes.outerGrid}>
        <Typography className={classes.quantityText}>{quantity}</Typography>
        {menuItem}
      </Grid>
    )
  }
  return menuItem
}

export default CartMenuItem
