import { Card, FormControlLabel, Radio, Tooltip } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { themeConWeb } from '../../../util/modernThemeConweb'

const useStyles = makeStyles({
  formControlLabel: {
    '& .MuiIconButton-root': {
      display: 'none',
    },
    marginLeft: 'inherit',
    marginRight: 'inherit',
    display: 'block',
  },
  card: {
    padding: ({ dense }) => dense && '14px',
    border: ({ checked, disabled }) =>
      `1px solid ${
        checked
          ? themeConWeb.color.brandPrimary
          : disabled
          ? themeConWeb.color.greyShade1
          : themeConWeb.color.brandSecondaryTint8
      }`,
  },
})

const RadioCardOption = props => {
  const { checked, content, dense, children, disabled, disabledReason, ...rest } = props
  const classes = useStyles({ checked, disabled, dense })
  return (
    <FormControlLabel
      {...rest}
      disabled={disabled ?? false}
      className={classes.formControlLabel}
      label={
        <Tooltip title={disabledReason ?? ''}>
          <Card variant="outlined" className={classes.card}>
            {children}
          </Card>
        </Tooltip>
      }
      control={<Radio checked={checked} color="primary" />}
    />
  )
}

export default RadioCardOption
