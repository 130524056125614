import { CircularProgress, Divider, TextField } from '@material-ui/core'
import React from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'

export const MAPS_SCRIPT_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=loadGoogleMaps`

const getHighlightedText = (text, highlight) => {
  const highlightParts = highlight.split(' ')
  const parts = text.split(' ')

  return (
    <span>
      {parts &&
        parts.map((part, i) => (
          <span
            key={i}
            style={
              highlightParts &&
              highlightParts.some(
                highlight =>
                  highlight &&
                  part
                    .replace(/[^a-z0-9]/gi, '')
                    .toLowerCase()
                    .indexOf(highlight.toLowerCase().replace(/[^a-z0-9]/gi, '')) > -1,
              )
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            {`${part} `}
          </span>
        ))}
    </span>
  )
}

class LocationSearchBox extends React.Component {
  state = { address: this.props.address || '', updated: false }

  handleChange = address => {
    this.setState({ address, updated: true })
  }

  handleSelect = address => {
    const { completeAccountToken, redirectOverride, search, updateAddress } = this.props
    this.setState({ address }, () => {
      updateAddress(address)
      search(this.state.address, !redirectOverride, null, null, completeAccountToken)
    })
  }

  static getDerivedStateFromProps(props, state) {
    if (props.address && props.address !== state.address && !state.updated) {
      return {
        address: props.address || '',
        updated: true,
      }
    }
    return null
  }

  render() {
    const {
      completeAccountToken,
      label,
      redirectOverride,
      search,
      showError,
      submitting,
    } = this.props
    return (
      <div>
        {label && <label htmlFor="searchbox">{label}</label>}
        <div className="Input--attachedSet">
          <div className="searchWrapper">
            <div className="searchBox">
              <PlacesAutocomplete
                googleCallbackName="loadGoogleMaps"
                highlightFirstSuggestion
                onChange={this.handleChange}
                onError={() => {}}
                onSelect={this.handleSelect}
                value={this.state.address}
              >
                {({ getInputProps, getSuggestionItemProps, suggestions }) => {
                  const className = suggestions.length
                    ? 'autocomplete-dropdown-container autocomplete-dropdown-container__active'
                    : 'autocomplete-dropdown-container'
                  return (
                    <div className="autocomplete-root">
                      {submitting && (
                        <CircularProgress
                          size={15}
                          style={{
                            bottom: '16px',
                            position: 'absolute',
                            right: '6px',
                            zIndex: 1,
                          }}
                        />
                      )}
                      <TextField
                        fullWidth
                        inputProps={{
                          style: {
                            borderRadius: '0px',
                          },
                        }}
                        label="Address"
                        margin="dense"
                        type="search"
                        variant="outlined"
                        color="secondary"
                        {...getInputProps({
                          autoFocus: true,
                          id: 'searchbox',
                          onKeyDown: e => {
                            if (!suggestions.length && e.keyCode === 13) {
                              search(
                                this.state.address,
                                !redirectOverride,
                                null,
                                null,
                                completeAccountToken,
                              )
                            }
                          },
                          placeholder: 'Find your location',
                        })}
                        tabIndex="6"
                      />
                      <div className={className}>
                        {suggestions.map((suggestion, i) => {
                          const className = suggestion.active
                            ? 'autocomplete-suggestion autocomplete-suggestion__active'
                            : 'autocomplete-suggestion'

                          return (
                            <div key={i}>
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                })}
                              >
                                <span>
                                  {getHighlightedText(
                                    suggestion.description,
                                    getInputProps().value,
                                  )}
                                </span>
                              </div>
                              {i < suggestions.length - 1 && <Divider light variant="middle" />}
                            </div>
                          )
                        })}
                        {suggestions && suggestions.length > 0 && (
                          <div className="autocomplete-suggestion google-attribute">
                            <span>
                              <img
                                alt="Powered by Google"
                                src="https://images.foodsby.com/third-party/powered_by_google_on_white.png"
                              />
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                }}
              </PlacesAutocomplete>
              {showError ? (
                <span className="error">Sorry, that&apos;s not a valid location.</span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LocationSearchBox
