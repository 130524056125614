import { Card } from '@material-ui/core'
import React from 'react'

import { Article } from '../common/Layout'
import TermsOfUseRewards from './TermsOfUseRewards'

const TermsOfUseRewardsPage = () => (
  <Article>
    <Card>
      <TermsOfUseRewards />
    </Card>
  </Article>
)

export default TermsOfUseRewardsPage
