import { createSelector } from 'reselect'
import { hasTeam } from '../../components/teams/teamUtils'

export const selectUserTeam = createSelector(
  [state => state.userTeam.userTeam],
  userTeam => userTeam,
)

export const selectTeamsSupported = createSelector([selectUserTeam], userTeam => {
  if (userTeam === undefined) {
    return undefined // We can't infer anything until userTeam is loaded
  }

  return Boolean(userTeam?.team) || Boolean(userTeam?.eligible)
})

export const selectHasTeam = createSelector([state => state.userTeam.userTeam], userTeam =>
  hasTeam(userTeam),
)
