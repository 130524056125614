import React from 'react'
import NumberFormat from 'react-number-format'

export function TextMaskDollarBills(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      prefix="$"
      decimalScale={2}
      fixedDecimalScale={true}
      allowNegative={false}
      onValueChange={values => onChange(values.value)}
    />
  )
}

export function TextMaskPhone(props) {
  const { inputRef, ...other } = props

  return <NumberFormat {...other} format="(###) ###-####" getInputRef={inputRef} mask=" " />
}

export const TextMaskBirthday = ({ inputRef, ...rest }) => {
  return <NumberFormat {...rest} format="##/##" getInputRef={inputRef} mask=" " />
}

export const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
