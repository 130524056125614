import {
  Box,
  Checkbox,
  DialogActions,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
  Tooltip,
} from '@material-ui/core'

import { Form, Formik } from 'formik'

import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ButtonSubmit from '../../common/ButtonSubmit'
import { TextMaskDollarBills } from '../../common/InputMasks'
import { themeConWeb } from '../../../util/modernThemeConweb'
import DialogContent from './DialogContent'
import { DAYS, PROGRAM_DIALOG_HEIGHT } from './programUtils'

const useStyles = makeStyles({
  dialogContent: {
    height: 'fit-content',
  },
  scheduleError: {
    color: themeConWeb.color.twoShade3,
  },
})

const StepProgramAmount = ({
  values,
  setFieldValue,
  isPresetSchedule,
  isPresetAmount,
  handleGetTotal,
  handleClose,
  validationSchema,
  programAmountDescription,
}) => {
  const classes = useStyles()
  const isDailyUsageSelected = values.usageType === 'DAILY'

  const handleSubmit = newValues => {
    setFieldValue('schedule', newValues.schedule)
    setFieldValue('discountAmountInPennies', newValues.discountAmountInPennies)
    handleGetTotal({
      schedule: newValues.schedule,
      discountAmountInPennies: newValues.discountAmountInPennies,
    })
    handleClose()
  }

  return (
    <Formik
      initialValues={{
        discountAmountInPennies: values.discountAmountInPennies,
        schedule: values.schedule,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {props => {
        const { setFieldValue, values: subValues, errors, touched } = props
        return (
          <Form>
            <DialogContent height={PROGRAM_DIALOG_HEIGHT}>
              <DialogContentText>{programAmountDescription}</DialogContentText>
              <Tooltip title={isPresetAmount ? 'Amount cannot be changed with this offer.' : ''}>
                <TextField
                  autoFocus
                  fullWidth
                  InputProps={{
                    inputComponent: TextMaskDollarBills,
                  }}
                  id="discountAmountInPennies"
                  name="discountAmountInPennies"
                  variant="outlined"
                  error={touched.discountAmountInPennies && Boolean(errors.discountAmountInPennies)}
                  helperText={touched.discountAmountInPennies && errors.discountAmountInPennies}
                  value={subValues.discountAmountInPennies / 100}
                  onChange={value => setFieldValue('discountAmountInPennies', value * 100)}
                  disabled={!!isPresetAmount}
                />
              </Tooltip>
              {isDailyUsageSelected && (
                <Box paddingY={4}>
                  <DialogContentText>
                    On which days will the funds be available to use?
                  </DialogContentText>
                  <FormControl component="fieldset" error={Boolean(errors.schedule)}>
                    <FormGroup>
                      {DAYS.map(day => (
                        <Fragment key={day.value}>
                          <Tooltip
                            title={
                              isPresetSchedule ? 'Schedule cannot be changed with this offer' : ''
                            }
                          >
                            <FormControlLabel
                              id={day.value}
                              label={day.label}
                              onChange={event =>
                                setFieldValue('schedule', {
                                  ...subValues.schedule,
                                  [day.value]: event.target.checked,
                                })
                              }
                              disabled={isPresetSchedule}
                              control={<Checkbox checked={subValues.schedule[day.value]} />}
                            />
                          </Tooltip>
                        </Fragment>
                      ))}
                    </FormGroup>
                  </FormControl>
                  {errors.schedule && (
                    <FormHelperText className={classes.scheduleError}>
                      {errors.schedule}
                    </FormHelperText>
                  )}
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <ButtonSubmit color="primary" variant="contained">
                Save
              </ButtonSubmit>
            </DialogActions>
          </Form>
        )
      }}
    </Formik>
  )
}

export default StepProgramAmount
