import { call, take } from 'redux-saga/effects'

import { api, attemptCompleteResetPassword, attemptResetPassword } from '../../api/api'
import { normalizeUserCreationError } from '../../util/errorUtils'
import { formSaga } from './sagas'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const VALIDATE_TOKEN = 'foodsby/changePassword/VALIDATE_TOKEN'
export const VALIDATE_TOKEN_SUCCESS = 'foodsby/changePassword/VALIDATE_TOKEN_SUCCESS'
export const VALIDATE_TOKEN_FAILURE = 'foodsby/changePassword/VALIDATE_TOKEN_FAILURE'

export const RESET_PASSWORD = 'foodsby/forgotPassword/RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'foodsby/forgotPassword/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'foodsby/forgotPassword/RESET_PASSWORD_FAILURE'

export const COMPLETE_RESET_PASSWORD = 'foodsby/forgotPassword/COMPLETE_RESET_PASSWORD'
export const COMPLETE_RESET_PASSWORD_SUCCESS =
  'foodsby/forgotPassword/COMPLETE_RESET_PASSWORD_SUCCESS'
export const COMPLETE_RESET_PASSWORD_FAILURE =
  'foodsby/forgotPassword/COMPLETE_RESET_PASSWORD_FAILURE'

export const resetPasswordStart = forgotPasswordFormData => {
  return {
    payload: { forgotPasswordFormData },
    type: RESET_PASSWORD,
  }
}

export const resetPasswordSuccess = response => {
  return {
    payload: { response },
    type: RESET_PASSWORD_SUCCESS,
  }
}

export const resetPasswordFailure = error => {
  return {
    error,
    type: RESET_PASSWORD_FAILURE,
  }
}

export const completeResetPasswordStart = resetPasswordFormData => {
  return {
    payload: { resetPasswordFormData },
    type: COMPLETE_RESET_PASSWORD,
  }
}

export const completeResetPasswordSuccess = () => {
  return {
    type: COMPLETE_RESET_PASSWORD_SUCCESS,
  }
}

export const completeResetPasswordFailure = error => {
  return {
    error,
    type: COMPLETE_RESET_PASSWORD_FAILURE,
  }
}

export const validateTokenStart = token => {
  return {
    payload: { token },
    type: VALIDATE_TOKEN,
  }
}

export const validateTokenSuccess = response => {
  return {
    payload: { response },
    type: VALIDATE_TOKEN_SUCCESS,
  }
}

export const validateTokenFailure = error => {
  return {
    error,
    type: VALIDATE_TOKEN_FAILURE,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [COMPLETE_RESET_PASSWORD]: state => {
    return {
      ...state,
      completeResetError: undefined,
      showCompleteResetFailure: false,
      showCompleteResetSuccess: false,
      submittingCompleteReset: true,
    }
  },
  [COMPLETE_RESET_PASSWORD_FAILURE]: (state, action) => {
    return {
      ...state,
      completeResetError: action.error,
      showCompleteResetFailure: true,
      showCompleteResetSuccess: false,
      submittingCompleteReset: undefined,
    }
  },
  [COMPLETE_RESET_PASSWORD_SUCCESS]: state => {
    return {
      ...state,
      showCompleteResetFailure: false,
      showCompleteResetSuccess: true,
      submittingCompleteReset: undefined,
    }
  },
  [RESET_PASSWORD]: state => {
    return {
      ...state,
      resetPasswordError: undefined,
      showResetPasswordFailure: false,
      showResetPasswordSuccess: false,
      submittingReset: true,
    }
  },
  [RESET_PASSWORD_FAILURE]: (state, action) => {
    const error = normalizeUserCreationError(action.error)
    return {
      ...state,
      resetPasswordError: error,
      showResetPasswordFailure: true,
      showResetPasswordSuccess: false,
      submittingReset: undefined,
    }
  },
  [RESET_PASSWORD_SUCCESS]: (state, action) => {
    return {
      ...state,
      response: action.payload.response,
      showResetPasswordFailure: false,
      showResetPasswordSuccess: true,
      submittingReset: undefined,
    }
  },
  [VALIDATE_TOKEN]: state => {
    return {
      ...state,
      tokenValid: false,
      validatingToken: true,
    }
  },
  [VALIDATE_TOKEN_FAILURE]: (state, action) => {
    return {
      ...state,
      response: action.error,
      tokenValid: false,
      validatingToken: false,
    }
  },
  [VALIDATE_TOKEN_SUCCESS]: (state, action) => {
    return {
      ...state,
      response: action.payload.response,
      tokenValid: true,
      validatingToken: false,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isTokenValidating: true,
  showCompleteResetFailure: false,
  showCompleteResetSuccess: false,
  showResetPasswordFailure: false,
  showResetPasswordSuccess: false,
  submittingCompleteReset: undefined,
  submittingReset: undefined,
}

export default function forgotPassword(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// -----------------------------------
export function* watchSubmitResetPassword() {
  while (true) {
    const {
      payload: { forgotPasswordFormData },
    } = yield take(RESET_PASSWORD)
    yield call(
      formSaga,
      'forgotPassword',
      attemptResetPassword,
      [forgotPasswordFormData],
      resetPasswordSuccess,
      resetPasswordFailure,
    )
  }
}
export function* watchCompleteResetPassword() {
  while (true) {
    const {
      payload: { resetPasswordFormData },
    } = yield take(COMPLETE_RESET_PASSWORD)
    yield call(
      formSaga,
      'completeForgotPassword',
      attemptCompleteResetPassword,
      [resetPasswordFormData],
      completeResetPasswordSuccess,
      completeResetPasswordFailure,
    )
  }
}
export function* watchValidateToken() {
  while (true) {
    const {
      payload: { token },
    } = yield take(VALIDATE_TOKEN)
    yield call(
      formSaga,
      'validateToken',
      attemptValidateToken,
      [token],
      validateTokenSuccess,
      validateTokenFailure,
    )
  }
}

export const attemptValidateToken = token => api.get(`api/v1/uaa/passwords/checkToken/` + token)
