import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { Delete, MoreHoriz } from '@material-ui/icons'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import EditIcon from '@material-ui/icons/Edit'
import PaymentMethodItem from '../common/PaymentMethodItem'
import { deletePaymentMethod } from '../../api/api'
import PanelHeader from '../common/PanelHeader'
import EditPaymentOptionForm from './EditPaymentOptionForm'

const Loading = () => (
  <Box padding={2}>
    <CircularProgress size={25} />
  </Box>
)

export const PaymentOptionsPanel = ({
  paymentMethods,
  loadPaymentMethodsStart,
  paymentMethodsError,
}) => {
  const dispatch = useDispatch()
  const [cardError, setCardError] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [editFieldShownFor, setEditFieldShownFor] = useState(null)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const handleDelete = async id => {
    setIsLoading(true)
    try {
      await deletePaymentMethod(id)
      setTimeout(() => {
        dispatch(loadPaymentMethodsStart())
        setIsLoading(false)
      }, 1500)
    } catch (e) {
      setCardError(e.message || 'Something went wrong while deleting this payment method')
      setIsLoading(false)
    }
  }

  return (
    <>
      <PanelHeader title="Payment Options" headerRight={isLoading ? <Loading /> : null} />
      {paymentMethods && paymentMethods.length > 0 ? (
        <List>
          {paymentMethods.map(paymentMethod => (
            <React.Fragment key={paymentMethod.providerId}>
              <ListItem
                key={paymentMethod.providerId}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Box>
                  <PaymentMethodItem paymentMethod={paymentMethod} />
                </Box>
                {isMobile ? (
                  <MobileMenuOptions
                    paymentMethodProviderId={paymentMethod.providerId}
                    handleEdit={() => setEditFieldShownFor(paymentMethod.providerId)}
                    handleDelete={() => handleDelete(paymentMethod.id)}
                  />
                ) : (
                  <DesktopActions
                    paymentMethod={paymentMethod}
                    isLoading={isLoading}
                    handleEdit={setEditFieldShownFor}
                    handleDelete={handleDelete}
                  />
                )}
              </ListItem>
              {editFieldShownFor === paymentMethod.providerId && (
                <Box mt={2}>
                  <EditPaymentOptionForm
                    paymentMethod={paymentMethod}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    setCardError={setCardError}
                    loadPaymentMethodsStart={loadPaymentMethodsStart}
                    setEditFieldShownFor={setEditFieldShownFor}
                  />
                </Box>
              )}
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Typography>You currently do not have any saved credit cards.</Typography>
      )}
      {(cardError || paymentMethodsError) && (
        <Typography color="error">{cardError ? cardError : paymentMethodsError}</Typography>
      )}
    </>
  )
}

const DesktopActions = ({ paymentMethod, isLoading, handleEdit, handleDelete }) => {
  return (
    <ListItemSecondaryAction>
      <Tooltip title="Edit">
        <IconButton onClick={() => handleEdit(paymentMethod.providerId)} disabled={isLoading}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton onClick={() => handleDelete(paymentMethod.id)} disabled={isLoading}>
          <Delete />
        </IconButton>
      </Tooltip>
    </ListItemSecondaryAction>
  )
}

const MobileMenuOptions = ({ paymentMethodProviderId, handleEdit, handleDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentPaymentMethodId, setCurrentPaymentMethodId] = useState(null)

  const handleMenuClick = (event, providerId) => {
    setAnchorEl(event.currentTarget)
    setCurrentPaymentMethodId(providerId)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setCurrentPaymentMethodId(null)
  }

  return (
    <>
      <IconButton
        edge="end"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={event => handleMenuClick(event, paymentMethodProviderId)}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl) && currentPaymentMethodId === paymentMethodProviderId}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            handleEdit()
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            handleDelete()
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

export default PaymentOptionsPanel
