import { Box, Container } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'react-redux'

import { Redirect } from 'react-router'
import styled from 'styled-components'
import Skeleton from '@material-ui/lab/Skeleton'

import PanelSubscriptions from '../components/profile/subscriptions/PanelSubscriptions'
import SubscriptionPromoHeader from '../components/subscriptions/SubscriptionPromoHeader'
import SubscriptionPromoMessage from '../components/subscriptions/SubscriptionPromoMessage'
import useSubscriptions from '../hooks/useSubscriptions'
import { homeRoute, subscriptionsPromoRoute, loginRoute } from '../routes/routes'

const SkeletonLoading = () => (
  <>
    <Skeleton height={100} width="100%" />
    <Skeleton height={100} width="100%" />
    <Skeleton height={100} width="100%" />
  </>
)

export const PageUnlimitedDelivery = ({ isCurrentUserLoading, isAuthenticated }) => {
  const theme = useTheme()
  const { subscriptionDetails, subscriptionOffer } = useSubscriptions()
  const location = useSelector(state => state?.location?.location)
  const [didSubscribe, setDidSubscribe] = useState(false)

  if ((subscriptionOffer && !subscriptionOffer.offer) || didSubscribe) {
    return <Redirect to={homeRoute.path} />
  }
  if (isCurrentUserLoading) {
    return <SkeletonLoading />
  }
  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: loginRoute.path,
          refer: subscriptionsPromoRoute.path,
        }}
      />
    )
  }

  return (
    <Component disableGutters maxWidth={false} muiTheme={theme}>
      <Box margin="auto" maxWidth="520px" paddingY={4} position="relative" zIndex="1">
        <PanelSubscriptions
          component={Container}
          header={
            <>
              <SubscriptionPromoHeader />
              <BackgroundIllustration />
            </>
          }
          location={location}
          message={
            <SubscriptionPromoMessage
              subscriptionDetails={subscriptionDetails}
              subscriptionOffer={subscriptionOffer}
            />
          }
          onSubscribeSuccess={() => setDidSubscribe(true)}
        />
      </Box>
    </Component>
  )
}

const BackgroundIllustration = () => (
  <Box alignItems="center" display="flex" justifyContent="center" marginTop="-120px" paddingY={4}>
    <img
      alt=""
      src="https://images.foodsby.com/conweb/unlimited_delivery_promo_5242020.svg"
      width="400px"
    />
  </Box>
)

const Component = styled(Container)`
  min-height: calc(100vh - 217px);
`

const mapStateToProps = state => {
  const { isAuthenticated, isCurrentUserLoading } = state.user
  return { isAuthenticated, isCurrentUserLoading }
}

export default connect(mapStateToProps)(PageUnlimitedDelivery)
