import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  FormHelperText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isNil } from 'lodash'
import cn from 'classnames'

import { getSelectionDetails, isRowSelected, getFullName } from '../admin/adminUtils'
import { themeConWeb } from '../../../util/modernThemeConweb'
import InputSearchMembers from '../../../components/teams/admin/InputSearchMembers'
import AvatarMember from '../../../components/common/AvatarMember'
import DialogContent from './DialogContent'
import { PROGRAM_DIALOG_HEIGHT } from './programUtils'
import SwitchOnOff from './SwitchOnOff'

const useStyles = makeStyles({
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  topHeaderRow: {
    backgroundColor: themeConWeb.color.greyShade1,
    color: themeConWeb.color.black,
  },
  tableContainer: {
    maxHeight: props => (props.isOneTimeGift ? '80%' : '65%'),
  },
  tableCell: {
    padding: '0px 16px',
  },
  tableBodyRow: {
    cursor: 'pointer',
  },
  checkboxCell: {
    width: '20px',
  },
  altHeaderRow: {
    backgroundColor: themeConWeb.color.greyShade2,
    color: themeConWeb.color.greyShade6,
  },
  error: {
    color: themeConWeb.color.twoShade3,
  },
  autoEnrollBox: {
    background: `${themeConWeb.color.grey} 50%`,
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
  },
  autoEnrollBoldType: {
    fontWeight: '700',
    color: themeConWeb.color.greyShade8,
  },
})

const StepProgramMembers = ({
  teamMembers,
  loading,
  handleClose,
  values,
  handleGetTotal,
  setValues,
  isOneTimeGift,
  isUnlimitedDelivery,
}) => {
  const classes = useStyles({ isOneTimeGift })
  const memberCount = teamMembers.length
  const [filteredMembers, setFilteredMembers] = useState(teamMembers)
  const [touched, setTouched] = useState(false)

  const getSelectedMembersFromIds = (selectedMembersIds, teamMembers) => {
    if (!values.members) {
      return []
    }
    return teamMembers.filter(member => {
      return selectedMembersIds.includes(member.userId)
    })
  }

  const [selectedMembers, setSelectedMembers] = useState(
    getSelectedMembersFromIds(values.members, teamMembers),
  )
  const [isAutoEnroll, setIsAutoEnroll] = useState(values.autoEnrollNewMembers)

  const columns = [
    {
      id: 'avatar',
      label: '',
    },
    {
      id: 'name',
      label: 'Name / email',
    },
  ]

  const { allPageSelected, someSelectedOnPage } = getSelectionDetails(
    teamMembers,
    teamMembers,
    selectedMembers,
  )

  const toggleSelectedMember = member => {
    if (isRowSelected(selectedMembers, member)) {
      setSelectedMembers(selectedMembers.filter(sm => sm !== member))
    } else {
      setSelectedMembers(selectedMembers.concat([member]))
    }
    setTouched(true)
  }

  const toggleAll = () => {
    teamMembers.every(member => isRowSelected(selectedMembers, member))
      ? setSelectedMembers([])
      : setSelectedMembers(teamMembers)
    setTouched(true)
  }

  const filterByNameAndEmail = (member, search) => {
    if (isNil(search)) {
      return true
    }

    const stringToCompare = `${getFullName(member)} ${member.email}`.toLowerCase()
    return stringToCompare.includes(search.toLowerCase())
  }

  const handleSearch = value => {
    setFilteredMembers(teamMembers.filter(member => filterByNameAndEmail(member, value)))
  }

  const handleSave = () => {
    if (selectedMembers.length === 0 && (isOneTimeGift || isUnlimitedDelivery)) {
      return
    }
    setValues({
      ...values,
      members: selectedMembers.map(member => member.userId),
      autoEnrollNewMembers: isAutoEnroll,
    })
    handleGetTotal &&
      handleGetTotal({
        members: selectedMembers.map(member => member.userId),
        autoEnrollNewMembers: isAutoEnroll,
      })
    handleClose()
  }

  return (
    <>
      <DialogContent height={PROGRAM_DIALOG_HEIGHT}>
        <Box marginBottom={3}>
          <Typography>
            ({selectedMembers.length} of {memberCount}) members selected
          </Typography>
        </Box>

        {teamMembers && (
          <>
            <Box marginTop={2} marginBottom={2}>
              <InputSearchMembers onChange={handleSearch} />
            </Box>
            <TableContainer elevation={0} component={Paper} className={classes.tableContainer}>
              {loading && <CircularProgress className={classes.circularProgress} />}
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map(cell => {
                      return (
                        <TableCell
                          key={cell.id}
                          className={cn([classes.tableCell, classes.topHeaderRow])}
                        >
                          {cell.label}
                        </TableCell>
                      )
                    })}
                    <TableCell
                      className={cn([
                        classes.tableCell,
                        classes.checkboxCell,
                        classes.topHeaderRow,
                      ])}
                    >
                      <Checkbox
                        indeterminate={someSelectedOnPage && !allPageSelected}
                        checked={allPageSelected}
                        onChange={toggleAll}
                        inputProps={{ 'aria-label': 'select all members' }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredMembers.map((member, index) => {
                    const labelId = `members-table-checkbox-${index}`
                    return (
                      <TableRow
                        className={classes.tableBodyRow}
                        hover
                        key={member.userId}
                        onClick={() => toggleSelectedMember(member)}
                      >
                        <TableCell id={labelId} className={classes.tableCell}>
                          <AvatarMember member={member} />
                        </TableCell>
                        <TableCell id={labelId} className={classes.tableCell}>
                          <Box margin={3}>
                            <Typography variant="body1">{getFullName(member)}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {member.email}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Checkbox
                            checked={selectedMembers.includes(member)}
                            name={`member-${member.userId}-checkbox`}
                            color="secondary"
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {!filteredMembers.length && (
              <Box marginTop={3}>
                <Typography variant="body1" align="center">
                  No members found
                </Typography>
              </Box>
            )}
          </>
        )}
        {!isOneTimeGift && (
          <Box className={classes.autoEnrollBox} marginTop={3} marginBottom={3}>
            <Box flexGrow={1}>
              <Typography variant="body1" className={classes.autoEnrollBoldType}>
                Auto-enroll new members to this perk
              </Typography>
            </Box>
            <SwitchOnOff checked={isAutoEnroll} onChange={e => setIsAutoEnroll(e.target.checked)} />
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        {touched && selectedMembers.length === 0 && !isOneTimeGift && !isUnlimitedDelivery && (
          <Box paddingY={2}>
            <FormHelperText className={classes.error}>
              Are you sure you want to create a perk without any members?
            </FormHelperText>
          </Box>
        )}
        {selectedMembers.length === 0 && (isOneTimeGift || isUnlimitedDelivery) && (
          <Box paddingY={2}>
            <FormHelperText className={classes.error}>
              Please be sure to select at least one member.
            </FormHelperText>
          </Box>
        )}
        <Button color="primary" variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </>
  )
}

export default StepProgramMembers
