import { api } from '../api/api'

// ------------------------------------
// APIs
// ------------------------------------

const SWITCHBOARD = '/api/v1/switchboard'

export const loadProfileByToken = () => api.get(`/api/v1/salad/web/users/profile`)

export const patchUserProfile = async (id, personalInfo) => {
  Object.keys(personalInfo).map(
    key => (personalInfo[key] = personalInfo[key] && personalInfo[key].trim()),
  )

  await api.put(`/api/v1/uaa/users/${id}/profiles`, personalInfo)
}

export const getUserPrivacy = userId => api.get(`/api/v1/uaa/privacy/${userId}`)

export const updateUserPrivacy = (userId, userPrivacy) =>
  api.put(`/api/v1/uaa/privacy/${userId}`, userPrivacy)

export const updateProfilePicture = (userId, image) =>
  api.patch(`/api/v1/uaa/users/${userId}/profile-image`, image)

export const getUserEmailPreferenceCenter = userId =>
  api.get(`${SWITCHBOARD}/braze/user-preference-url/${userId}`)

export const getUserSmsSubscriptionStateApi = (userId, userPhone) =>
  api.get(`${SWITCHBOARD}/braze/sms-subscription-state/${userId}/${userPhone}`)

export const updateUserSmsSubscriptionStateApi = (userId, userPhone) =>
  api.post(`${SWITCHBOARD}/braze/sms-subscription-state/${userId}/${userPhone}`)
