import { createSelector } from 'reselect'
import { LocationStatusType } from '../../util/constants'

export const selectLocationPreferred = createSelector(
  [state => state.location.location],
  location => location?.locationStatus === LocationStatusType.PREFERRED,
)

export const selectLocationNonPreferred = createSelector(
  [state => state.location.location],
  location => location?.locationStatus === LocationStatusType.NONPREFERRED,
)

export const selectLocationStatus = createSelector(
  [state => state.location.location],
  location => location?.locationStatus,
)
