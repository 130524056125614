import { Box, H2, P } from '@foodsby/nutrient'
import { themeConWeb } from '@app/util/modernThemeConweb'
import React from 'react'

export const NoDeliveriesToday = () => (
  <Box
    alignItems="center"
    display="flex"
    flex="1 1 auto"
    flexDirection="column"
    width="100%"
    mt={5}
    mb={5}
  >
    <img
      alt=""
      ariarole="place-holder"
      src="https://images.foodsby.com/conweb/calendar-grey-icon.png"
      style={{ marginBottom: '12px', width: '48px' }}
      textalign="center"
    />
    <H2 color={themeConWeb.color.greyShade4} textAlign="center">
      No deliveries scheduled
    </H2>
    <P
      color={themeConWeb.color.greyShade4}
      fontSize={themeConWeb.fontSizes.basePlus2}
      textAlign="center"
    >
      Check out the other days to see what’s ahead & order in advance!
    </P>
  </Box>
)
export default NoDeliveriesToday
