import React from 'react'
import { Typography } from '@material-ui/core'

const Captcha = () => {
  return (
    <Typography color="textSecondary" variant="caption">
      This site is protected by reCAPTCHA and the Google{' '}
      <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>{' '}
      and{' '}
      <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
        Terms of Service
      </a>{' '}
      apply.
    </Typography>
  )
}

export default Captcha
