import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Hidden, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { Form, Formik } from 'formik'
import { boolean, number, object, string } from 'yup'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { find, isNil } from 'lodash'
import { connect } from 'react-redux'
import { profileRoute } from '../../routes/routes'

import { validateOrder, createSetupIntent, getCaptcha, checkoutOrder } from '../../api/api'
import PanelHeader from '../common/PanelHeader'
import useSubscriptions from '../../hooks/useSubscriptions'
import {
  applyCouponStart,
  applyCreditStart,
  checkoutStart,
  checkoutComplete,
  checkoutRejected,
  resetCheckoutError,
} from '../../redux/modules/checkout'
import {
  loadProfileAttributesStart,
  updateUserSmsSubscriptionStatusStart,
} from '../../redux/modules/profile'
import { loadCheckoutCartStart } from '../../redux/modules/cart'
import { toggleDeliveryInstructionsDialog } from '../../redux/modules/location'
import {
  selectProfile,
  selectCardPaymentDetail,
  selectCurrentSavedLocation,
  selectSuggestedCredits,
  selectSelectedCard,
  selectSaveCard,
  selectAllPaymentMethods,
  selectPaymentMethodsIsLoading,
  selectCurrentLocationWithAddress,
} from '../../redux/modules/selectors'
import { phoneRegExp } from '../common/InputMasks'
import { createSubscriptionApi } from '../../services/subscriptions'
import { loadIsSubscribedStart } from '../../redux/modules/subscriptions'
import { pushAnalyticsEvent } from '../../util/gtmUtils'
import { isNewPaymentMethod, NEW_PAYMENT_METHOD_ID } from '../../util/paymentMethods'
import { themeConWeb } from '../../util/modernThemeConweb'
import { updateUserSmsEnrollmentAsked } from '../../services/onboarding'
import { SubscriptionStatus } from '../../util/constants'
import CheckoutDeliveryDetails from '../common/CheckoutDeliveryDetails'
import PickupInstructions from '../common/PickupInstructions'
import BillingInfo from './BillingInfo'
import Coupon from './Coupon'
import Credit from './Credit'
import Gifts from './Gift'
import Subscription from './Subscription'
import UserInfo from './UserInfo'
import Program from './Program'
import DeliveryInstructionsField from './DeliveryInstructionsField'
import { shouldHideSubscription, shouldShowSmsAutoEnrollment } from './checkout'

const useStyles = makeStyles(theme => ({
  manageCards: {
    fontSize: `${themeConWeb.fontSizes.base}`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const CheckoutForm = ({
  // Props from parent
  dropoffId,
  // Props from redux
  additionalAttributes,
  smsSubscriptionStatus,
  loadProfileAttributesStart,
  updateUserSmsSubscriptionStatusStart,
  profile,
  applyCouponStart,
  programInfo,
  couponUsage,
  creditUsage,
  applyCreditStart,
  resetCheckoutError,
  availableCredits,
  checkoutCart,
  cardPaymentDetail,
  checkoutStart,
  checkoutComplete,
  checkoutRejected,
  waitingForProcessed,
  selectedCard,
  shouldSaveCard,
  errorApplyingCoupon,
  paymentMethods,
  paymentMethodsError,
  errorApplyingCredit,
  curSavedLocation,
  isAvailableCreditsLoading,
  isCheckoutCartLoading,
  isCouponApplying,
  isCreditApplying,
  loadCheckoutCartStart,
  storedCurrencyUsage,
  suggestedCredits,
  isLoadingProgram,
  isPaymentMethodsLoading,
  loadIsSubscribedStart,
  isSubscribed,
  willSubscribe,
  deliveryDropoff,
  location,
  isDeliveryDropoffLoading,
}) => {
  const isMounted = useRef(false)
  const elements = useElements()
  const stripe = useStripe()
  const classes = useStyles()

  const { isSubscriptionEligible } = useSubscriptions()
  const [shouldSubscribe, setShouldSubscribe] = useState(false)
  const [toggleSeeMoreSms, setToggleSeeMoreSms] = useState(false)

  const schema = object().shape({
    SelectedCard: number()
      .nullable()
      .test('test', 'Please select a credit card.', val => {
        if (checkoutCart.orderTotal === 0) {
          return true
        } else {
          return val != null
        }
      }),
    User: object().shape({
      firstName: string()
        .required('First Name is required.')
        .max(35, 'First name cannot exceed 35 characters.'),
      lastName: string()
        .required('Last Name is required.')
        .max(35, 'First name cannot exceed 35 characters.'),
      phone: string()
        .required('Phone Number is required.')
        .matches(phoneRegExp, 'Phone Number must be 10 digits.'),
    }),
    isSubscribed: boolean().when('saveCard', {
      is: value => value === false,
      otherwise: boolean().notRequired(),
      then: boolean()
        .required()
        .oneOf([false], 'A credit card on file is required. Please add and save your card.'),
    }),
  })

  //if they are subscribing for the first time, we need to confirm the payment
  //method via a setup intent
  const createNewCardAndSubscription = async () => {
    const captchaToken = await getCaptcha()
    const setupIntentRes = await createSetupIntent({ captchaToken })
    if (setupIntentRes.error) {
      checkoutRejected(setupIntentRes.message)
      return
    }

    const reqBody = {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    }

    const { setupIntent, error: confirmCardError } = await stripe.confirmCardSetup(
      setupIntentRes.clientSecret,
      reqBody,
    )
    if (confirmCardError) {
      checkoutRejected(confirmCardError.message)
      return
    }

    const pm = setupIntent.payment_method
    try {
      await createSubscriptionApi(pm)
      return pm
    } catch (err) {
      return err.message
    }
  }

  const confirmPayment = async () => {
    if (!elements || !stripe) {
      checkoutRejected('Payment System not initialized. Please contact support. ')
      return
    }

    const savedCardToUse = find(paymentMethods, method => method.id === selectedCard)?.providerId

    let newPaymentMethod
    if (shouldSubscribe) {
      if (!isNewPaymentMethod(selectedCard)) {
        try {
          await createSubscriptionApi(savedCardToUse)
        } catch (err) {
          checkoutRejected(err.message)
          return
        }
      } else {
        newPaymentMethod = await createNewCardAndSubscription()
        if (!newPaymentMethod) {
          return
        }
      }
    }

    const { error: validateError, isProcessable } = await validateOrder(checkoutCart.orderId)
    if (!isProcessable) {
      checkoutRejected(validateError?.reason)
      return
    }

    if (checkoutCart.orderTotal === 0) {
      const { error: checkoutError } = await checkoutOrder(checkoutCart.orderId)
      if (checkoutError) {
        checkoutRejected(checkoutError.message)
      }
      checkoutComplete()
      return
    }

    let reqBody
    if (!isNewPaymentMethod(selectedCard)) {
      reqBody = {
        payment_method: savedCardToUse,
      }
    } else {
      if (newPaymentMethod) {
        //card created from subscription
        reqBody = {
          payment_method: newPaymentMethod,
        }
      } else {
        reqBody = {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        }
        if (shouldSaveCard) {
          //we want to be able to use this on a subscription, so off_session
          reqBody.setup_future_usage = 'off_session'
        }
      }
    }
    const { error } = await stripe.confirmCardPayment(
      cardPaymentDetail.externalClientSecret,
      reqBody,
    )

    if (error) {
      checkoutRejected(error.message)
    } else {
      //let's give the webhook two seconds to process before going to order confirmation
      //eventually we'll use websockets to handle this transition
      setTimeout(checkoutComplete, 2000)
    }
  }

  const handleChangeSubscription = value => {
    if (isMounted.current) {
      loadCheckoutCartStart(dropoffId, value)
      pushAnalyticsEvent('Subscriptions', 'Clicked Subscription Checkbox')
    }
  }

  useEffect(() => {
    isMounted.current = true
    if (isMounted.current) {
      loadIsSubscribedStart()
      loadProfileAttributesStart()
    }
    return () => {
      isMounted.current = false
    }
  }, [loadIsSubscribedStart, loadProfileAttributesStart])

  //confirm payment
  useEffect(() => {
    if (waitingForProcessed) {
      confirmPayment()
    }
    //eslint-disable-next-line
  }, [waitingForProcessed])

  const handleSubmit = async (values, { setTouched }) => {
    setShouldSubscribe(values.isSubscribed && !isSubscribed)
    if (!additionalAttributes.smsEnrollmentAsked) {
      await updateUserSmsEnrollmentAsked(profile.userId)
    }
    if (showAutoEnrollment && values.autoEnrollSms && values.User.phone) {
      const phoneNumber = values.User.phone.replace(/\D/g, '')
      updateUserSmsSubscriptionStatusStart(profile.userId, phoneNumber)
    }
    checkoutStart(values)
    setTouched({})
  }

  const handleToggleSeeMoreSms = e => {
    setToggleSeeMoreSms(!toggleSeeMoreSms)
  }

  if (isPaymentMethodsLoading || isNil(profile) || isDeliveryDropoffLoading) {
    return <SkeletonLoading />
  }

  const hideSubscription = shouldHideSubscription(checkoutCart, isSubscribed, willSubscribe)
  const showAutoEnrollment = shouldShowSmsAutoEnrollment(
    smsSubscriptionStatus,
    additionalAttributes.smsEnrollmentAsked,
  )

  return (
    <Formik
      initialValues={{
        SelectedCard: paymentMethods.length > 0 ? null : NEW_PAYMENT_METHOD_ID,
        User: {
          firstName: profile.firstName || '',
          lastName: profile.lastName || '',
          phone: profile.phone || '',
        },
        isSubscribed: willSubscribe,
        saveCard: true,
        deliveryInstructions: curSavedLocation?.deliveryInstructions || '',
        autoEnrollSms: smsSubscriptionStatus !== SubscriptionStatus.UNSUBSCRIBED,
      }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ errors, handleBlur, handleChange, setFieldTouched, setFieldValue, touched, values }) => (
        <Form>
          <Box component="section" marginBottom={4}>
            <PanelHeader title="Deliver To" />
            <UserInfo
              email={profile.username}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched}
              values={values}
              showAutoEnrollment={showAutoEnrollment}
              handleToggleSeeMoreSms={handleToggleSeeMoreSms}
              toggleSeeMoreSms={toggleSeeMoreSms}
              smsSubscriptionStatus={smsSubscriptionStatus}
            />
          </Box>
          {!isDeliveryDropoffLoading && (
            <Box paddingBottom={3}>
              <PanelHeader title="Delivery Details" />
              <CheckoutDeliveryDetails
                address={location.address}
                deliveryDate={deliveryDropoff.date}
                deliveryTime={deliveryDropoff.dropoffDisplay}
              />
              <PickupInstructions
                locationPickupInstructions={location.deliveryPickupInstructions}
              />
            </Box>
          )}
          {curSavedLocation?.deliveryInstructionsSupported && (
            <Box component="section" marginBottom={4}>
              <DeliveryInstructionsField
                handleBlur={handleBlur}
                handleChange={handleChange}
                defaultValue={curSavedLocation?.deliveryInstructions}
              />
            </Box>
          )}
          <Box component="section" marginBottom={4}>
            <PanelHeader title="Discounts" />
            <Grid container spacing={3}>
              {!hideSubscription && isSubscriptionEligible && (
                <Grid item xs={12}>
                  <Subscription
                    isSubscribed={isSubscribed}
                    dropoffId={dropoffId}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    storedCurrencyUsage={storedCurrencyUsage}
                    values={values}
                    willSubscribe={willSubscribe}
                    onChange={handleChangeSubscription}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Coupon
                  applyCouponStart={applyCouponStart}
                  couponAmount={couponUsage ? couponUsage.couponAmount : 0}
                  couponApplied={couponUsage ? couponUsage.couponApplied : false}
                  couponCode={couponUsage ? couponUsage.couponCode : null}
                  error={errorApplyingCoupon}
                  isCheckoutCartLoading={isCheckoutCartLoading}
                  isCouponApplying={isCouponApplying}
                  isDailyLink={couponUsage ? couponUsage.isDailyLink : false}
                  willSubscribe={willSubscribe}
                />
              </Grid>
              {checkoutCart.orderItems?.length > 0 && creditUsage?.creditBalance !== 0 && (
                <Grid item xs={12} md={6}>
                  <Credit
                    applyCreditStart={applyCreditStart}
                    availableCredits={availableCredits}
                    creditsApplied={creditUsage ? creditUsage.creditsApplied : false}
                    creditsUsed={creditUsage ? creditUsage.creditBalanceUsed : 0}
                    error={errorApplyingCredit}
                    isAvailableCreditsLoading={isAvailableCreditsLoading}
                    isCheckoutCartLoading={isCheckoutCartLoading}
                    isCreditApplying={isCreditApplying}
                    suggestedCredits={suggestedCredits}
                    willSubscribe={willSubscribe}
                  />
                </Grid>
              )}
              {storedCurrencyUsage?.storedCurrencyBalance > 0 && (
                <Grid item xs={12} md={6}>
                  <Gifts
                    dropoffId={dropoffId}
                    isSubscribed={isSubscribed}
                    loadCheckoutCartStart={loadCheckoutCartStart}
                    storedCurrencyUsage={storedCurrencyUsage}
                    isCheckoutCartLoading={isCheckoutCartLoading}
                  />
                </Grid>
              )}
              {programInfo && (
                <Grid item xs={12}>
                  <Program program={programInfo} loading={isLoadingProgram} />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box component="section" marginBottom={4}>
            <PanelHeader title="Payment Methods" />
            {checkoutCart?.orderTotal === 0 ? (
              <Typography variant="h5">Your meal is covered!</Typography>
            ) : (
              <BillingInfo
                showSaveCard
                clearError={resetCheckoutError}
                paymentMethods={paymentMethods}
                paymentMethodsError={paymentMethodsError}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={value => {
                  handleChange(value)
                  resetCheckoutError()
                }}
                key="billing-form"
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                CardElement={CardElement}
              />
            )}
            {values.SelectedCard !== NEW_PAYMENT_METHOD_ID && (
              <Typography className={classes.manageCards} color={themeConWeb.color.two}>
                <Link href={profileRoute.path}>Manage Cards</Link>
              </Typography>
            )}
          </Box>
          <Hidden>
            <button type="submit" id="checkoutButton" />
          </Hidden>
        </Form>
      )}
    </Formik>
  )
}

const SkeletonLoading = () => (
  <>
    <Skeleton height={100} width="100%" />
    <Skeleton height={100} width="100%" />
    <Skeleton height={100} width="100%" />
  </>
)

CheckoutForm.defaultProps = {
  profile: {},
}

const mapStateToProps = state => {
  const {
    additionalAttributes,
    errorSettingSmsSubscriptionStatus,
    smsSubscriptionStatus,
    isLoadingSmsSubscriptionStatus,
  } = state.profile
  const {
    programInfo,
    errorApplyingCoupon,
    errorApplyingCredit,
    isCouponApplying,
    isCreditApplying,
    waitingForProcessed,
    isLoadingProgram,
  } = state.checkout
  const {
    couponUsage,
    creditUsage,
    checkoutCart,
    isCheckoutCartLoading,
    storedCurrencyUsage,
    willSubscribe,
  } = state.cart
  const { isDeliveryDropoffLoading, deliveryDropoff } = state.delivery
  const { availableCredits, isAvailableCreditsLoading } = state.creditHistory
  const { isSubscribed } = state.subscriptions
  const { paymentMethodsError } = state.paymentMethods
  const profile = selectProfile(state)
  const suggestedCredits = selectSuggestedCredits(state)
  const selectedCard = selectSelectedCard(state)
  const shouldSaveCard = selectSaveCard(state)
  const curSavedLocation = selectCurrentSavedLocation(state)
  const cardPaymentDetail = selectCardPaymentDetail(state)
  const paymentMethods = selectAllPaymentMethods(state)
  const isPaymentMethodsLoading = selectPaymentMethodsIsLoading(state)
  const location = selectCurrentLocationWithAddress(state)

  return {
    additionalAttributes,
    errorSettingSmsSubscriptionStatus,
    smsSubscriptionStatus,
    isLoadingSmsSubscriptionStatus,
    profile,
    availableCredits,
    couponUsage,
    creditUsage,
    programInfo,
    checkoutCart,
    cardPaymentDetail,
    waitingForProcessed,
    selectedCard,
    shouldSaveCard,
    errorApplyingCoupon,
    paymentMethods,
    isPaymentMethodsLoading,
    paymentMethodsError,
    errorApplyingCredit,
    curSavedLocation,
    isAvailableCreditsLoading,
    isCheckoutCartLoading,
    isCouponApplying,
    isCreditApplying,
    storedCurrencyUsage,
    suggestedCredits,
    isLoadingProgram,
    isSubscribed,
    willSubscribe,
    deliveryDropoff,
    location,
    isDeliveryDropoffLoading,
  }
}

const mapDispatchToProps = {
  applyCouponStart,
  applyCreditStart,
  checkoutStart,
  checkoutComplete,
  checkoutRejected,
  loadCheckoutCartStart,
  toggleDeliveryInstructionsDialog,
  resetCheckoutError,
  loadIsSubscribedStart,
  loadProfileAttributesStart,
  updateUserSmsSubscriptionStatusStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm)
