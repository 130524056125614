/**
 * Function to determine if the current browser supports the webp format.
 * We return the result, and store it in the global window object for quick subsequent calls
 */
export function isWebpSupported() {
  return new Promise(res => {
    let result

    if (window && window.webpSupported !== undefined) {
      result = window.webpSupported
      res(result)
    }

    if (result === undefined) {
      const webP = new Image()
      webP.src =
        'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA'
      webP.onload = webP.onerror = function() {
        const result = webP.height === 2
        window.webpSupported = result
        res(result)
      }
    }
  })
}
