import { normalize, schema } from 'normalizr'

import { camelcaseKeys } from '../util/keys'

const answerSchema = new schema.Entity('answers', {})
const questionSchema = new schema.Entity('questions', {
  answers: [answerSchema],
})
answerSchema.define({ questions: [questionSchema] })
const menuItemSchema = new schema.Entity('menuItems', {
  questions: [questionSchema],
})

const normalizeMenuItem = menuItem => normalize(camelcaseKeys(menuItem), menuItemSchema)
export default normalizeMenuItem
