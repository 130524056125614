import { push } from 'connected-react-router'
import { all, call, put, select, take } from 'redux-saga/effects'

import { api } from '../../api/api'
import { locationRoute } from '../../routes/routes'
import { formatUrl } from '../../util/formatUtils'
import { apiSaga } from './sagas'
import { selectUserLocationId } from './selectors'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const CHECK_HEALTH_START = 'foodsby/checkHealth/CHECK_HEALTH_START'
export const CHECK_HEALTH_SUCCESS = 'foodsby/checkHealth/CHECK_HEALTH_SUCCESS'
export const CHECK_HEALTH_FAILURE = 'foodsby/checkHealth/CHECK_HEALTH_FAILURE'

export const checkHealthStart = () => ({
  type: CHECK_HEALTH_START,
})

export const checkHealthSuccess = () => ({
  type: CHECK_HEALTH_SUCCESS,
})

export const checkHealthFailure = () => ({
  type: CHECK_HEALTH_FAILURE,
})

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [CHECK_HEALTH_FAILURE]: state => ({
    ...state,
    health: 'bad',
    isCheckingHealth: false,
  }),
  [CHECK_HEALTH_START]: state => ({
    ...state,
    health: undefined,
    isCheckingHealth: true,
  }),
  [CHECK_HEALTH_SUCCESS]: state => ({
    ...state,
    health: 'good',
    isCheckingHealth: false,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { health: undefined }

export default function checkHealth(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------
export function* watchCheckHealthEndpoints() {
  while (true) {
    yield take(CHECK_HEALTH_START)
    try {
      yield all([
        call(apiSaga, checkAuthHealth, [], checkHealthSuccess, checkHealthFailure),
        call(apiSaga, checkApiMonolithHealth, [], checkHealthSuccess, checkHealthFailure),
      ])
      const locationId = yield select(selectUserLocationId)
      if (locationId) {
        yield put(push(formatUrl(locationRoute.path, { locationId: locationId })))
      } else {
        yield put(push(locationRoute.path))
      }
    } catch (error) {
      yield put(checkHealthFailure())
    }
  }
}

export const checkAuthHealth = () => api.get(`/api/v1/uaa/management/health`)
export const checkApiMonolithHealth = () => api.get(`/api-monolith/health/check`)
