import { Box, Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import { connect } from 'react-redux'

import { cancelEditingOrder, loadEditOrder } from '../../redux/modules/cart'
import { clearMenuItem } from '../../redux/modules/menu'
import {
  selectCurrentLocationWithAddress,
  selectMenuItemForEdit,
} from '../../redux/modules/selectors'
import { OrderCart } from '../cart/Cart'
import ButtonSubmit from '../common/ButtonSubmit'
import StickyContainer from '../common/StickyContainer'
import PersonalizeOrder from '../personalize-order/PersonalizeOrder'

const useStyles = makeStyles({
  card: {
    padding: '0',
  },
  orderInfoText: {
    fontWeight: 'bold',
  },
  divider: {
    marginBottom: '8px',
  },
})

export const OrderSidebar = ({
  deliveryDropoff,
  isAuthenticated,
  isDeliveryDropoffLoading,
  isEditingOrder,
  isEditingPastOrder,
  isCartSaving,
  isLoadingOrderForEdit,
  isLocationLoading,
  isOrderCartLoading,
  loadEditOrder,
  location,
  menuItemForEdit,
  normalizedMenuItem,
  orderCart,
  orderCartError,
  handleClose,
  handleNext,
  handleRemoveItemFromCart,
  handleAddItemsToCart,
  handleEditOrder,
  nextButtonDisplayText,
  personalizeOrderAddMoreIsPrimary,
  rewardsPercentage,
  isLoadingDeliveryDropoffRewards,
}) => {
  const classes = useStyles()

  const disableCheckout = !isAuthenticated || orderCart.orderItems.length === 0
  const isLoading =
    isLocationLoading ||
    isDeliveryDropoffLoading ||
    isOrderCartLoading ||
    isLoadingDeliveryDropoffRewards

  return (
    <StickyContainer bottomOffset={18} stickyStyle={{ paddingTop: '100px' }} topOffset={-100}>
      <Card className={classes.card}>
        {isLoading && <SkeletonLoading />}

        {!isLoading && (
          <Box padding={3}>
            {orderCart && (
              <OrderCart
                cart={orderCart}
                deleteMenuItem={handleRemoveItemFromCart}
                isCartSaving={isCartSaving}
                deliveryFee={deliveryDropoff.deliveryFeeContribution}
                editable="true"
                editMenuItem={loadEditOrder}
                isLoadingOrderForEdit={isLoadingOrderForEdit}
                isEditingPastOrder={false}
                showEditLinks="true"
                isConfirmedReserveDropoff={deliveryDropoff.isConfirmedReserveDropoff}
                rewardsPercentage={rewardsPercentage}
              />
            )}
          </Box>
        )}
      </Card>

      <ButtonSubmit
        color="primary"
        disabled={disableCheckout}
        fullWidth
        onClick={handleNext}
        errorMessage={orderCartError?.message}
        variant="contained"
      >
        {nextButtonDisplayText}
      </ButtonSubmit>

      {!isEditingPastOrder && menuItemForEdit && isEditingOrder && (
        <PersonalizeOrder
          handleClose={handleClose}
          handleAddItemsToCart={handleAddItemsToCart}
          handleEditOrder={handleEditOrder}
          isEditingFromCheckout={false}
          isEditingFromMenu="true"
          isEditingOrder={isEditingOrder}
          isEditingPastOrder={false}
          locationId={location.deliveryLocationId}
          menuItemForEdit={menuItemForEdit}
          menuItemId={menuItemForEdit.menuItemId}
          normalizedMenuItem={normalizedMenuItem}
          nextButtonDisplayText={nextButtonDisplayText}
          addMoreIsPrimary={personalizeOrderAddMoreIsPrimary}
        />
      )}
    </StickyContainer>
  )
}

const SkeletonLoading = () => (
  <>
    <Skeleton height={150} variant="rect" />
    <Box padding={3}>
      <Skeleton height={30} width="80%" />
      <Skeleton height={30} width="80%" />
      <Box marginY={2}>
        <Skeleton height={40} width="90%" />
      </Box>
      <Skeleton height={50} />
    </Box>
  </>
)

const mapStateToProps = state => {
  const { isOrderCartLoading, orderCart, orderCartError } = state.cart
  const { isAuthenticated } = state.user
  const { normalizedMenuItem } = state.personalize
  const { deliveryDropoff, isDeliveryDropoffLoading } = state.delivery
  const location = selectCurrentLocationWithAddress(state)
  const menuItemForEdit = selectMenuItemForEdit(state)
  const { isLocationLoading } = state.location
  const { isEditingOrder, isEditingPastOrder, isCartSaving, isLoadingOrderForEdit } = state.cart
  return {
    deliveryDropoff,
    isAuthenticated,
    isDeliveryDropoffLoading,
    isEditingOrder,
    isEditingPastOrder,
    isCartSaving,
    isLoadingOrderForEdit,
    isLocationLoading,
    isOrderCartLoading,
    location,
    menuItemForEdit,
    normalizedMenuItem,
    orderCart,
    orderCartError,
  }
}

const mapDispatchToProps = {
  cancelEditingOrder,
  clearMenuItem,
  loadEditOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSidebar)
