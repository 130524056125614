import { themeConWeb } from '@app/util/modernThemeConweb'
import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { ExpandMore, Warning } from '@material-ui/icons'
import React from 'react'
import { Field } from 'redux-form'

export const formName = 'personalize'
export const questionPrefix = 'q_'

const useStyles = makeStyles(theme => ({
  expansionSummaryContent: {
    marginBottom: '0 !important',
  },
  warningIcon: {
    color: themeConWeb.color.dangerLight,
    display: props => (props.error && props.touched ? 'inline' : 'none'),
    fontSize: '18px',
    marginLeft: '8px',
  },
}))

const PersonalizeOrderQuestionRow = ({ key, questionName, isExpanded, children }) => {
  const classes = useStyles()

  return (
    <ExpansionPanelElevationZero
      defaultExpanded={isExpanded}
      elevation={0}
      // If the item is required or is a bunch of options items open the expansion panel
      key={key}
      square
    >
      <ExpansionPanelSummary
        classes={{ expanded: classes.expansionSummaryContent }}
        expandIcon={<ExpandMore color="primary" />}
        id="panel1a-header"
      >
        <Box alignItems="center" display="flex" flexDirection="row">
          <Typography color="textPrimary" component="h2" variant="subtitle1">
            {questionName}
          </Typography>

          {/* Render the error with a same `name` Field as the input, in order to access `error` and `touched`  */}
          <Field component={renderError} name={`${questionPrefix}${key}`} />
        </Box>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </ExpansionPanelElevationZero>
  )
}

/**
 * https://redux-form.com/7.3.0/examples/material-ui/
 * Needed to create a wrapper component for redux forms check docs for details
 */

const renderError = ({ meta: { error, touched } }) => {
  const classes = useStyles({ error, touched }) // eslint-disable-line react-hooks/rules-of-hooks
  return <Warning className={classes.warningIcon} />
}

/**
 *  We do not have plans to reuse this component at this time. If we want to overwrite the base
 *  work work with UX if you would like to repeat this pattern else ware.
 */
const ExpansionPanelElevationZero = withStyles({
  expanded: {},
  /* Styles applied to the root element if `expanded={true}`. */
  root: {
    '&$disabled': {},
    '&$expanded': {
      '&:before': {
        opacity: 1,
      },
      margin: 0,
    },
    '&$expanded + &': {
      '&:before': {
        display: 'block',
      },
    },
    '&:first-child': {
      '&:before': {
        display: 'none',
      },
    },
    position: 'relative',
  },
})(ExpansionPanel)

export default PersonalizeOrderQuestionRow
