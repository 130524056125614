import currency from 'currency.js'
import pluralize from 'pluralize'
import { number, object, string } from 'yup'
import { isNil } from 'lodash'

export const TOTAL_DOTTED_STEPS = 3
export const GIFT_AMOUNT_OTHER = 'other'
export const gitAmountOptions = ['1500', '2500', '5000']

export const STEPS = Object.freeze({
  GIFT_DETAILS: 0,
  GIFT_RECIPIENTS: 1,
  GIFT_ADD_PAYMENT: 2,
  GIFT_PAYMENT: 3,
  COMPLETE: 4,
})

export const getStepDetails = (currentStep, hasPaymentMethod, numOfRecipients) => {
  switch (currentStep) {
    case STEPS.GIFT_DETAILS:
      return {
        step: 1,
        title: 'Gift details',
        nextStep: STEPS.GIFT_RECIPIENTS,
        previousStep: undefined,
      }
    case STEPS.GIFT_RECIPIENTS:
      return {
        step: 2,
        title: `Gift recipients (${numOfRecipients})`,
        nextStep: hasPaymentMethod ? STEPS.GIFT_PAYMENT : STEPS.GIFT_ADD_PAYMENT,
        previousStep: STEPS.GIFT_DETAILS,
      }
    case STEPS.GIFT_ADD_PAYMENT:
      return {
        step: undefined,
        title: 'Add payment method',
        nextStep: STEPS.GIFT_PAYMENT,
        previousStep: STEPS.GIFT_RECIPIENTS,
      }
    case STEPS.GIFT_PAYMENT:
      return {
        step: 3,
        title: 'Payment details',
        nextStep: STEPS.COMPLETE,
        previousStep: hasPaymentMethod ? STEPS.GIFT_RECIPIENTS : STEPS.GIFT_ADD_PAYMENT,
      }
    default:
      return {}
  }
}

export const CHECKOUT_STEPS = Object.freeze({
  GIFT_AMOUNT: 0,
  GIFT_MEMBERS: 1,
  GIFT_DETAILS: 2,
  GIFT_ADD_PAYMENT: 3,
  GIFT_DISCOUNT_CODE: 4,
})

export const offerDiscountCodeOptions = Object.freeze({
  id: 'GIFT_DISCOUNT_CODE',
  step: 4,
})

export const getCheckoutStepDetails = currentStep => {
  switch (currentStep) {
    case CHECKOUT_STEPS.GIFT_AMOUNT:
      return {
        title: 'Perk Amount',
        validationSchema: object().shape({
          discountAmountInPennies: number().min(1, 'Amount must be greater than zero.'),
        }),
      }
    case CHECKOUT_STEPS.GIFT_MEMBERS:
      return {
        title: 'Select Members',
        validationSchema: object().shape({}),
      }
    case CHECKOUT_STEPS.GIFT_DETAILS:
      return {
        title: 'Perk Details:',
        validationSchema: object().shape({
          reason: string().required('Perk reason is required.'),
          message: string(),
        }),
      }
    case CHECKOUT_STEPS.GIFT_ADD_PAYMENT:
      return {
        title: 'Select Payment Method',
        validationSchema: object().shape({}),
      }
    case CHECKOUT_STEPS.GIFT_DISCOUNT_CODE:
      return {
        title: 'Add Offer Code',
        validationSchema: object().shape({
          discountCode: string().required('Offer code is required'),
        }),
      }
    default:
      return {}
  }
}

export const isDottedStep = currentStep => currentStep !== STEPS.GIFT_ADD_PAYMENT

export const getAmountPer = (amount, otherAmount) =>
  amount !== GIFT_AMOUNT_OTHER ? Number(amount) : formatDollarsToCents(otherAmount)

export const formatCentsToPrettyDollars = cents => {
  if (isNil(cents)) {
    return ''
  }

  return currency(Number(cents), { formatWithSymbol: true })
    .divide(100)
    .format()
}

export const formatCentsToDollars = cents => {
  if (isNil(cents)) {
    return '$0'
  }

  const amount = currency(Number(cents), {
    formatWithSymbol: true,
    precision: 2,
  }).divide(100)

  if (amount.value % 1 === 0) {
    return `$${amount.value.toFixed(0)}`
  } else {
    return amount.format()
  }
}

export const formatDollarsToCents = dollars => {
  if (!dollars) {
    return ''
  }

  return currency(Number(dollars)).intValue
}

export const formatDiscountAmountText = amount =>
  amount && `${formatCentsToPrettyDollars(amount)} per member`

export const formatMembersText = members =>
  !isNil(members) && `(${members.length}) ${pluralize('Member', members.length)}`
