import React from 'react'
import { Box, TextField } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { date, object } from 'yup'

import DialogAction from '../../common/DialogAction'

const DialogEditEndDate = ({ open, defaultValue, submitting, startDate, onSubmit, onClose }) => {
  const schema = object().shape({
    endDate: date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .min(startDate, 'End date cannot be before the start date.'),
  })

  return (
    <Formik
      initialValues={{ endDate: defaultValue ?? '' }}
      onSubmit={values => onSubmit(values.endDate)}
      validationSchema={schema}
    >
      {({ values, touched, errors, setFieldValue, handleSubmit }) => {
        return (
          <DialogAction
            dialogTitle={`${defaultValue ? 'Edit' : 'Set'} End Date`}
            handleDialogOnClose={onClose}
            handleSubmit={handleSubmit}
            isSubmitting={submitting}
            show={open}
            submitText="Save"
          >
            <Form>
              <Box display="flex" justifyContent="center" marginBottom={2}>
                <TextField
                  id="endDate"
                  name="endDate"
                  type="date"
                  variant="outlined"
                  fullWidth
                  value={values.endDate}
                  onChange={e => setFieldValue('endDate', e.target.value)}
                  inputProps={{ role: 'input', min: startDate }}
                  helperText={touched.endDate && errors.endDate}
                  error={touched.endDate && Boolean(errors.endDate)}
                />
              </Box>
            </Form>
          </DialogAction>
        )
      }}
    </Formik>
  )
}

export default DialogEditEndDate
