import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'

import { isEmpty, isNil } from 'lodash'
import { addDays } from 'date-fns'
import { loadTeamMembersStart } from '../../../redux/modules/teamMembers'
import {
  loadProgramMembersStart,
  loadProgramStatsStart,
  resetPerkDetailsState,
  resetPerkMembersRemoveState,
  setPage,
  setSearch,
} from '../../../redux/modules/teamPerkDetails'
import { resetState, setMissingMembersStart } from '../../../redux/modules/teamAddPerkMembers'
import { selectTeam } from '../../../redux/selectors/teamSelectors'
import { selectMissingMembers } from '../../../redux/selectors/teamAddProgramMembersSelectors'
import { updateProgramStart } from '../../../redux/modules/teamPrograms'
import { isPaymentMethodRequiredForTeamPerks } from '../admin/adminUtils'
import CardProgramSummary from './CardProgramSummary'
import CardProgramStats from './CardProgramStats'
import CardPerkMembers from './CardPerkMembers'
import { getProgramStatus } from './utils'
import DialogAddPerkMembers from './DialogAddPerkMembers'
import EditableProgramName from './EditableProgramName'
import CardPerkAutoEnrollMembers from './CardPerkAutoEnrollMembers'
import DialogPerkDetails from './DialogPerkDetails'

const DialogProgramDetails = ({
  team,
  program,
  programMembers,
  missingMembers,
  programStats,
  isLoadingProgramStats,
  isUpdatingProgram,
  errorSettingMissingMembers,
  loadTeamMembersStart,
  loadProgramStatsStart,
  loadProgramMembersStart,
  setPage,
  setSearch,
  setMissingMembersStart,
  resetState: resetAddMembersState,
  resetPerkDetailsState,
  resetPerkMembersRemoveState,
  updateProgramStart,
  onClose,
  defaultPaymentMethod,
}) => {
  const handleClickAddMembers = () => {
    setMissingMembersStart()
  }

  const handleCloseAddMembers = () => {
    resetAddMembersState()
  }

  const handleUpdateProgramEndDate = endDate => {
    // WARNING This is a TEMPORARY fix as the end date is exclusive in the backend
    // We need to add a day here just so the program is available on the selected day
    // Note: We are subtracting a day when displaying it to the user
    const endDateInclusive = endDate ? addDays(endDate, 1) : undefined
    handleUpdateProgram({ endDate: endDateInclusive, neverExpires: isNil(endDateInclusive) })
  }

  const handleUpdateProgram = updateProgramRequest => {
    updateProgramRequest.programId = program.programId
    updateProgramStart(updateProgramRequest)
  }

  useEffect(() => {
    if (team && program.programId) {
      loadTeamMembersStart(team.id)
      loadProgramMembersStart(program.programId)
      loadProgramStatsStart(program.programId)
    }
  }, [
    team,
    program.programId,
    loadProgramMembersStart,
    loadProgramStatsStart,
    loadTeamMembersStart,
  ])

  // Clean up the state on unmount
  useEffect(() => {
    return () => {
      resetAddMembersState()
      resetPerkDetailsState()
      resetPerkMembersRemoveState()
    }
  }, [resetAddMembersState, resetPerkMembersRemoveState, resetPerkDetailsState])

  const status = getProgramStatus(program)
  return (
    <>
      <DialogPerkDetails onClose={onClose}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <EditableProgramName
              programName={program.name}
              submitting={isUpdatingProgram}
              onSubmit={handleUpdateProgram}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid item xs={12}>
              <CardProgramSummary
                program={program}
                teamPaymentCollectionMethod={team.paymentCollectionMethod}
                defaultPaymentMethod={defaultPaymentMethod}
                isUpdatingProgram={isUpdatingProgram}
                onUpdateProgram={handleUpdateProgramEndDate}
                isEndDateEditable={
                  !program.activeDiscount ||
                  !isPaymentMethodRequiredForTeamPerks(
                    !isNil(defaultPaymentMethod),
                    team.paymentCollectionMethod,
                  )
                }
                status={status}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid item xs={12}>
              <CardProgramStats programStats={programStats} loading={isLoadingProgramStats} />
            </Grid>
            <Grid item xs={12}>
              <CardPerkAutoEnrollMembers
                perk={program}
                isUpdatingPerk={isUpdatingProgram}
                onUpdatePerk={handleUpdateProgram}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CardPerkMembers
              members={programMembers}
              errorSettingMissingMembers={errorSettingMissingMembers}
              setPage={setPage}
              setSearch={setSearch}
              onClickAddMembers={handleClickAddMembers}
            />
          </Grid>
        </Grid>
      </DialogPerkDetails>
      {!isEmpty(missingMembers) && <DialogAddPerkMembers onClose={handleCloseAddMembers} />}
    </>
  )
}

const mapStateToProps = state => {
  const team = selectTeam(state)
  const missingMembers = selectMissingMembers(state)
  const { perkMembers: programMembers, programStats, isLoadingProgramStats } = state.teamPerkDetails
  const { isUpdatingProgram } = state.teamPrograms
  const { errorSettingMissingMembers } = state.teamAddPerkMembers

  return {
    team,
    programMembers,
    missingMembers,
    programStats,
    isLoadingProgramStats,
    isUpdatingProgram,
    errorSettingMissingMembers,
  }
}

const mapDispatchToProps = {
  loadTeamMembersStart,
  loadProgramMembersStart,
  loadProgramStatsStart,
  setPage,
  setSearch,
  setMissingMembersStart,
  resetState,
  resetPerkDetailsState,
  resetPerkMembersRemoveState,
  updateProgramStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogProgramDetails)
