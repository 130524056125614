import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { SimpleSpinner } from '../components/common/SimpleSpinner'
import { getQueryParams } from '../redux/modules/selectors'
import { registerSlackbot } from '../redux/modules/slackbot'
import { homeRoute } from '../routes/routes'

const PageSlack = props => {
  const { code, error, state } = props.queryParams
  const { isSlackbotLoading, registerSlackbot } = props

  useEffect(() => {
    registerSlackbot(code, state, error)
  }, [code, error, registerSlackbot, state])

  return isSlackbotLoading === undefined || isSlackbotLoading ? (
    <SimpleSpinner />
  ) : (
    <Redirect to={homeRoute.path} />
  )
}

const mapStateToProps = state => {
  const { isSlackbotLoading } = state.slackbot
  const queryParams = getQueryParams(state)
  return {
    isSlackbotLoading,
    queryParams,
  }
}

const mapDispatchToProps = {
  registerSlackbot,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageSlack)
