import React, { useCallback } from 'react'
import { Box, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { get } from 'lodash'

import { DIALOG_OPTIONS } from './savedLocationsUtils'

const EditSavedLocationsFields = ({
  errors,
  touched,
  values,
  handleBlur,
  handleChange,
  dialogToShow,
  selectedLocation,
  updateSavedLocationError,
}) => {
  const getError = useCallback(name => get(touched, name) && get(errors, name), [errors, touched])

  return (
    <Box>
      {DIALOG_OPTIONS[dialogToShow].id === DIALOG_OPTIONS.EDIT_NICKNAME.id && (
        <Box marginLeft={2} marginRight={2} marginBottom={4}>
          <TextField
            name="name"
            error={!!getError('name')}
            helperText={getError('name')}
            id="name"
            inputProps={{ 'data-testid': 'edit-nickname-field' }}
            required
            fullWidth
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Nickname…"
            size="small"
            value={values.name}
            variant="outlined"
            color="secondary"
          />
        </Box>
      )}
      {DIALOG_OPTIONS[dialogToShow].id === DIALOG_OPTIONS.EDIT_DELIVERY_INSTRUCTIONS.id &&
        selectedLocation.deliveryInstructionsSupported && (
          <Box marginLeft={2} marginRight={2} marginBottom={4}>
            <TextField
              name="deliveryInstructions"
              error={!!getError('deliveryInstructions')}
              helperText={getError('deliveryInstructions')}
              id="deliveryInstructions"
              data-testid="edit-delivery-instructions-field"
              required
              fullWidth
              onBlur={handleBlur}
              multiline
              rows={10}
              onChange={handleChange}
              placeholder="Delivery Instructions…"
              size="small"
              value={values.deliveryInstructions}
              variant="outlined"
              color="secondary"
            />
          </Box>
        )}
      {updateSavedLocationError && <Alert severity="error">{updateSavedLocationError}</Alert>}
    </Box>
  )
}

export default EditSavedLocationsFields
