import React from 'react'
import { Link, Typography } from '@material-ui/core'
import { format } from 'date-fns'

import { subscriptionTermsAndConditionsRoute } from '../../../routes/routes'
import {
  hasActiveSubscription,
  hasActiveSubscriptionTrial,
  hasRequestedSubscriptionCancelation,
  hasSubscribedBefore,
} from '../../../util/subscriptions'

const SubscriptionMessage = ({ subscription, offer }) => {
  const subscriptionFee = subscription?.subscriptionFee / 100 || offer?.offer?.price
  const nextPaymentDate = subscription?.nextPaymentDate

  if (hasRequestedSubscriptionCancelation(subscription)) {
    return (
      <Typography paragraph>
        Your cancellation request has been received. You will continue to receive your unlimited
        delivery until {format(nextPaymentDate, 'MM/DD/YYYY')}. Resubscribe below.
      </Typography>
    )
  }

  if (hasActiveSubscriptionTrial(subscription)) {
    return (
      <Typography paragraph>
        Your trial is now active! Your first payment for ${subscriptionFee} will be on{' '}
        {format(nextPaymentDate, 'MM/DD/YYYY')}.
      </Typography>
    )
  }

  if (hasActiveSubscription(subscription)) {
    return (
      <Typography paragraph>
        Your subscription will auto renew for ${subscriptionFee} on{' '}
        {format(nextPaymentDate, 'MM/DD/YYYY')}.
      </Typography>
    )
  }

  return (
    <Typography paragraph>
      {hasSubscribedBefore(offer)
        ? `Get $0 delivery fees on every order when you subscribe for $${subscriptionFee}/month. Cancel anytime in your profile.`
        : `Get $0 delivery fees on every order when you subscribe for $${subscriptionFee}/month. Enjoy your first month free! Cancel anytime in your profile.`}
      <Link href={subscriptionTermsAndConditionsRoute.path} target="_blank">
        Terms & Conditions
      </Link>
    </Typography>
  )
}

export default SubscriptionMessage
