import { isEmpty } from 'lodash'
import pluralize from 'pluralize'

import { formatAllMemberNames, isRowSelected } from '../../components/teams/admin/adminUtils'
import {
  addExternalManagerApi,
  getTeamMembersApi,
  inviteMembersApi,
  removeExternalManagerApi,
  removeMembersFromTeamApi,
} from '../../services/team'
import { selectMemberInviteMessages } from '../selectors/teamMembersSelectors'
import { selectTeam } from '../selectors/teamSelectors'
import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import { selectProfile } from './selectors'
import { enqueueSnackbar } from './snackbar'
import { loadTeamStart } from './team'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const TEAM_MEMBERS_SET = 'foodsby/teamMembers/TEAM_MEMBERS_SET'
export const PAGE_SET = 'foodsby/teamMembers/PAGE_SET'
export const SEARCH_SET = 'foodsby/teamMembers/SEARCH_SET'
export const SORT_SET = 'foodsby/teamMembers/SORT_SET'
export const MEMBERS_INVITE = 'foodsby/teamMembers/MEMBER_INVITE'
export const MEMBERS_REMOVE = 'foodsby/teamMembers/MEMBERS_REMOVE'
export const STATE_RESET_MEMBERS_INVITE = 'foodsby/teamMembers/STATE_RESET_MEMBERS_INVITE'
export const STATE_RESET_MEMBERS_REMOVE = 'foodsby/teamMembers/STATE_RESET_MEMBERS_REMOVE'
export const STATE_RESET = 'foodsby/teamMembers/STATE_RESET'
export const MEMBER_TOGGLE_SELECT = 'foodsby/teamMembers/MEMBER_TOGGLE_SELECT'
export const ONE_PAGE_MEMBERS_TOGGLE_SELECT = 'foodsby/teamMembers/ONE_PAGE_MEMBERS_TOGGLE_SELECT'
export const ALL_MEMBERS_TOGGLE_SELECT = 'foodsby/teamMembers/ALL_MEMBERS_TOGGLE_SELECT'
export const ACTIONABLE_MEMBERS_SET = 'foodsby/teamMembers/ACTIONABLE_MEMBERS_SET'
export const ACTIONABLE_MEMBER_REMOVE = 'foodsby/teamMembers/ACTIONABLE_MEMBER_REMOVE'
export const UPDATE_MEMBER_ROLE = 'foodsby/teamMembers/UPDATE_MEMBER_ROLE'

export const setTeamMembers = createAsyncAction(TEAM_MEMBERS_SET)
export const setPage = createAction(PAGE_SET)
export const setSearch = createAction(SEARCH_SET)
export const setSort = createAction(SORT_SET)
export const inviteMembers = createAsyncAction(MEMBERS_INVITE)
export const removeMembers = createAsyncAction(MEMBERS_REMOVE)
export const resetInviteMembersState = createAction(STATE_RESET_MEMBERS_INVITE)
export const resetMemberRemoveState = createAction(STATE_RESET_MEMBERS_REMOVE)
export const resetState = createAction(STATE_RESET)
export const toggleSelectMember = createAction(MEMBER_TOGGLE_SELECT)
export const toggleSelectOnePageMembers = createAction(ONE_PAGE_MEMBERS_TOGGLE_SELECT)
export const toggleSelectAllMembers = createAction(ALL_MEMBERS_TOGGLE_SELECT)
export const setActionableMembers = createAction(ACTIONABLE_MEMBERS_SET)
export const removeActionableMember = createAction(ACTIONABLE_MEMBER_REMOVE)
export const updateMemberRole = createAsyncAction(UPDATE_MEMBER_ROLE)

// ------------------------------------
// Thunks
// ------------------------------------
export const loadTeamMembersStart = teamId => {
  return dispatch => {
    return dispatch(setTeamMembers(getTeamMembersApi(teamId)))
  }
}

export const inviteMembersStart = (memberEmails, createdTeam) => {
  return async (dispatch, getState) => {
    const state = getState()
    const account = selectTeam(state)
    const accountId = account?.id || createdTeam?.id
    const profile = selectProfile(state)
    const locationId = account?.type === 'OFFICE' ? account?.location?.id : profile.locationId

    if (profile && accountId) {
      const {
        action: { payload },
      } = await dispatch(inviteMembers(inviteMembersApi(accountId, locationId, memberEmails)))

      const memberInviteMessages = selectMemberInviteMessages(payload)
      if (!isEmpty(memberInviteMessages)) {
        memberInviteMessages.forEach(message => {
          dispatch(enqueueSnackbar({ message }))
        })
      }
      await dispatch(loadTeamMembersStart(accountId))
    }
  }
}

export const removeMembersStart = members => {
  return async (dispatch, getState) => {
    if (isEmpty(members)) {
      return
    }

    const state = getState()
    const team = selectTeam(state)

    if (team) {
      const memberIds = members.map(m => m.userId)

      try {
        await dispatch(removeMembers(removeMembersFromTeamApi(team.id, memberIds)))

        const memberNames = formatAllMemberNames(members)
        dispatch(
          enqueueSnackbar({
            message: `${memberNames} ${pluralize(
              'has',
              members.length,
            )} been removed from the team.`,
          }),
        )

        await dispatch(loadTeamMembersStart(team.id))
      } catch (ex) {
        dispatch(
          enqueueSnackbar({
            message: `An error occurred. ${ex?.message}`,
          }),
        )
      }
    }
  }
}

export const updateMemberRoleStart = (memberId, memberEmail, targetRole) => {
  return async (dispatch, getState) => {
    const state = getState()
    const team = selectTeam(state)

    try {
      if (targetRole === 'Admin') {
        await dispatch(updateMemberRole(addExternalManagerApi(team.id, memberId)))
      } else {
        await dispatch(updateMemberRole(removeExternalManagerApi(team.id, memberId)))
      }

      dispatch(loadTeamStart(team.id))
      dispatch(
        enqueueSnackbar({
          message: `${memberEmail}'s role has been changed to ${targetRole}.`,
        }),
      )
    } catch (ex) {
      dispatch(
        enqueueSnackbar({
          message: ex?.message
            ? `Error updating ${memberEmail}’s role: ${ex.message}`
            : `Something went wrong updating ${memberEmail}'s role. Please try again or contact support.`,
        }),
      )
    }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PENDING(TEAM_MEMBERS_SET)]: state => {
    return {
      ...state,
      isLoadingTeamMembers: true,
    }
  },
  [FULFILLED(TEAM_MEMBERS_SET)]: (state, action) => {
    return {
      ...state,
      teamMembers: action.payload.content,
      isLoadingTeamMembers: false,
      errorLoadingTeamMembers: undefined,
    }
  },
  [REJECTED(TEAM_MEMBERS_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingTeamMembers: false,
      errorLoadingTeamMembers: action.error,
    }
  },
  [PAGE_SET]: (state, action) => {
    return {
      ...state,
      page: action.payload,
    }
  },
  [SEARCH_SET]: (state, action) => {
    return {
      ...state,
      search: action.payload,
    }
  },
  [SORT_SET]: (state, action) => {
    return {
      ...state,
      sort: action.payload,
    }
  },
  [PENDING(MEMBERS_INVITE)]: state => {
    return {
      ...state,
      isInvitingMembers: true,
    }
  },
  [FULFILLED(MEMBERS_INVITE)]: (state, action) => {
    return {
      ...state,
      isInvitingMembers: false,
      successInvitingMembers: true,
      errorInvitingMembers: undefined,
      memberInviteResults: action.payload,
    }
  },
  [REJECTED(MEMBERS_INVITE)]: (state, action) => {
    return {
      ...state,
      isInvitingMembers: false,
      successInvitingMembers: false,
      errorInvitingMembers: action.payload.message,
    }
  },
  [PENDING(MEMBERS_REMOVE)]: state => {
    return {
      ...state,
      isRemovingMembers: true,
    }
  },
  [FULFILLED(MEMBERS_REMOVE)]: state => {
    return {
      ...state,
      actionableMembers: [],
      selectedMembers: [],
      isRemovingMembers: false,
      successRemovingMembers: true,
    }
  },
  [REJECTED(MEMBERS_REMOVE)]: (state, action) => {
    return {
      ...state,
      isRemovingMembers: false,
      errorRemovingMembers: action.error,
    }
  },
  [STATE_RESET_MEMBERS_INVITE]: state => {
    return {
      ...state,
      isInvitingMembers: false,
      successInvitingMembers: false,
      errorInvitingMembers: undefined,
      memberInviteResults: undefined,
    }
  },
  [STATE_RESET_MEMBERS_REMOVE]: state => {
    return {
      ...state,
      isRemovingMembers: false,
      successRemovingMembers: false,
      errorRemovingMembers: undefined,
    }
  },
  [STATE_RESET]: () => {
    return initialState
  },
  [MEMBER_TOGGLE_SELECT]: (state, action) => {
    const { selectedMembers } = state
    const member = action.payload

    return {
      ...state,
      selectedMembers: isRowSelected(selectedMembers, member)
        ? selectedMembers.filter(m => m.userId !== member.userId)
        : [...selectedMembers, member],
    }
  },
  [ONE_PAGE_MEMBERS_TOGGLE_SELECT]: (state, action) => {
    const { selectedMembers } = state
    const { checked, rows } = action.payload

    const selectedRowsOnOtherPages = selectedMembers.filter(
      sm => !rows.map(r => r.userId).includes(sm.userId),
    )
    return {
      ...state,
      selectedMembers: checked ? [...rows, ...selectedRowsOnOtherPages] : selectedRowsOnOtherPages,
    }
  },
  [ALL_MEMBERS_TOGGLE_SELECT]: (state, action) => {
    const { checked, allMembers } = action.payload

    return {
      ...state,
      selectedMembers: checked ? [] : allMembers,
    }
  },
  [ACTIONABLE_MEMBERS_SET]: state => {
    const { selectedMembers } = state
    return {
      ...state,
      actionableMembers: selectedMembers,
    }
  },
  [ACTIONABLE_MEMBER_REMOVE]: (state, action) => {
    const { actionableMembers } = state
    const member = action.payload
    return {
      ...state,
      actionableMembers: actionableMembers.filter(m => m !== member),
    }
  },
  [PENDING(UPDATE_MEMBER_ROLE)]: state => {
    return {
      ...state,
      isUpdatingMemberRole: true,
    }
  },
  [FULFILLED(UPDATE_MEMBER_ROLE)]: state => {
    return {
      ...state,
      isUpdatingMemberRole: false,
    }
  },
  [REJECTED(UPDATE_MEMBER_ROLE)]: state => {
    return {
      ...state,
      isUpdatingMemberRole: false,
    }
  },
}

export const initialState = {
  teamMembers: [],
  page: 0,
  search: undefined,
  sort: { field: undefined, direction: undefined },
  memberInviteResults: undefined,
  selectedMembers: [],
  actionableMembers: [], // This list is initially a copy of selectedMembers but with a remove option
  // Loading states
  isLoadingTeamMembers: false,
  isInvitingMembers: false,
  isRemovingMembers: false,
  isUpdatingMemberRole: false,
  // Success states
  successInvitingMembers: false,
  successRemovingMembers: false,
  // Error states
  errorLoadingTeamMembers: undefined,
  errorInvitingMembers: undefined,
  errorRemovingMembers: undefined,
}

export default function teamMembers(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
