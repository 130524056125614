import React, { useState } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/styles'
import { Card, Typography } from '@material-ui/core'
import { Formik } from 'formik'

import {
  AccessTimeOutlined,
  AttachMoney,
  CreditCardOutlined,
  InfoOutlined,
} from '@material-ui/icons'
import { format } from 'date-fns'
import ListActionableDetails from '../../common/ListActionableDetails'
import DialogAction from '../../common/DialogAction'
import { selectTeam } from '../../../redux/selectors/teamSelectors'
import { deleteSubscribeStart } from '../../../redux/modules/subscriptions'
import { loadTeamSubscriptionStart } from '../../../redux/modules/teamSubscription'

import { formatDefaultPaymentMethodInfoText, formatDefaultPaymentMethodText } from './programUtils'
import { formatCentsToPrettyDollars } from './giftCardUtils'

// Unfortunately this is what we set the end date to instead of null
const NO_END_DATE_DATE = '9999-12-31'

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
})

const CardTeamSubscriptionSummary = ({
  teamSubscription,
  defaultPaymentMethod,
  teamPaymentCollectionMethod,
  isUpdatingSubscription,
  team,
  loadTeamSubscriptionStart,
  deleteSubscribeStart,
  onClose,
}) => {
  const classes = useStyles()

  const [showCancelSubscriptionDialog, setShowCancelSubscriptionDialog] = useState(false)
  const startDate = format(teamSubscription.startDate, 'MM/DD/YYYY')
  const defaultEndDate = formatString =>
    teamSubscription.endDate !== NO_END_DATE_DATE
      ? format(teamSubscription.endDate, formatString)
      : undefined

  const summaryItems = [
    {
      id: 'FREQUENCY',
      title: 'Available daily',
      secondaryText: 'Perk Frequency',
      image: <AccessTimeOutlined />,
    },
    {
      id: 'AMOUNT',
      title: `${formatCentsToPrettyDollars(
        teamSubscription.pricePerHeadInCents,
      )} per member / month`,
      secondaryText: 'Subscription cost',
      image: <AttachMoney />,
    },
    {
      id: 'TIME',
      title: `Start date: ${startDate} | End date: ${defaultEndDate('MM/DD/YYYY') ?? 'Not set'}`,
      secondaryText: 'Perk details',
      image: <InfoOutlined />,
      buttonText: 'Cancel Subscription',
      loading: isUpdatingSubscription,
    },
    {
      id: 'PAYMENT_METHOD',
      title: formatDefaultPaymentMethodText(defaultPaymentMethod, teamPaymentCollectionMethod),
      secondaryText: 'Default Payment Method',
      image: <CreditCardOutlined />,
      infoText: formatDefaultPaymentMethodInfoText(teamPaymentCollectionMethod),
    },
  ]

  const handleSubscriptionCancelled = () => {
    loadTeamSubscriptionStart()
    onClose()
  }

  const handleSubmitEndDate = () => {
    deleteSubscribeStart(team.id, handleSubscriptionCancelled)
    setShowCancelSubscriptionDialog(false)
  }

  return (
    <Card className={classes.root}>
      <Typography variant="h6">Details</Typography>
      <ListActionableDetails
        listItems={summaryItems}
        handleClick={() => setShowCancelSubscriptionDialog(true)}
        activeId="TIME"
      />

      <Formik initialValues={{}} onSubmit={handleSubmitEndDate}>
        {({ handleSubmit }) => {
          return (
            <DialogAction
              dialogTitle="Cancel Subscription?"
              handleDialogOnClose={() => setShowCancelSubscriptionDialog(false)}
              handleSubmit={handleSubmit}
              show={showCancelSubscriptionDialog}
              submitText="Confirm"
            />
          )
        }}
      </Formik>
    </Card>
  )
}

const mapStateToProps = state => {
  return {
    team: selectTeam(state),
  }
}

const mapDispatchToProps = {
  deleteSubscribeStart,
  loadTeamSubscriptionStart,
}

CardTeamSubscriptionSummary.defaultProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CardTeamSubscriptionSummary)
