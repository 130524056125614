import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getQueryParams } from '../../redux/modules/selectors'
import { logoutStart } from '../../redux/modules/user'
import { SimpleSpinner } from '../common/SimpleSpinner'

class Logout extends Component {
  componentDidMount() {
    this.props.logoutStart(this.props.queryPrarms, this.props.currentUser)
  }

  render() {
    return <SimpleSpinner />
  }
}
const matchStateToProps = state => {
  const queryPrarms = getQueryParams(state)
  const { currentUser } = state.user
  return {
    currentUser,
    queryPrarms,
  }
}
const mapDispatchToProps = {
  logoutStart,
}

export default connect(matchStateToProps, mapDispatchToProps)(Logout)
