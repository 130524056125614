import { Pagination } from '@foodsby/nutrient'
import { Box } from '@material-ui/core'
import cn from 'classnames'
import currency from 'currency.js'
import { differenceInCalendarDays, format } from 'date-fns'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTable from 'react-table'

import { loadAvailableCreditsStart } from '../../redux/modules/creditHistory'
import { loadCreditTransactionStart } from '../../redux/modules/credits'
import { formatCreditTransactionType } from '../../util/formatUtils'
import { SpinnerCard } from '../common/Layout'

const DEFAULT_PAGE_SIZE = 25

export class CreditsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      data: [],
      initialLoad: true,
      pageSize: DEFAULT_PAGE_SIZE,
      totalPages: 1,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.props.loadCreditTransactionStart(this.state.pageSize, this.state.currentPage - 1)
      this.props.loadAvailableCreditsStart()
    }

    if (!this.props.loading && this.props.creditTransactions) {
      this.setCreditTransactionData()
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.props.loadCreditTransactionStart(this.state.pageSize, this.state.currentPage - 1)
      this.props.loadAvailableCreditsStart()
    }

    if (prevProps.loading && !this.props.loading && this.props.creditTransactions) {
      this.setCreditTransactionData()
    }
  }

  setCreditTransactionData = () => {
    this.setState({
      // The total pages should be at most the ceiling of total elements divided by the page size
      data: this.props.creditTransactions.content,
      initialLoad: false,
      pageSize: Math.max(DEFAULT_PAGE_SIZE, this.props.creditTransactions.numberOfElements),
      totalPages: Math.ceil(this.props.creditTransactions.totalElements / this.state.pageSize),
    })
  }

  handleChange(page) {
    this.props.loadCreditTransactionStart(this.state.pageSize, page - 1)
    this.setState({
      currentPage: page,
    })
  }

  render() {
    const { availableCredits, creditTransactions, loading } = this.props

    const columns = [
      {
        Header: 'Date',
        accessor: d => format(d.occurrenceDate, 'MM/DD/YYYY'),
        id: 'date',
        maxWidth: 130,
      },
      {
        Footer: 'Total credits available',
        Header: 'Reason',
        accessor: d => formatCreditTransactionType(d.primaryTag),
        id: 'reason',
      },
      {
        Footer: currency(availableCredits, {
          formatWithSymbol: true,
        }).format(),
        Header: 'Amount',
        accessor: d => {
          const value = currency(d.amount, {
            formatWithSymbol: true,
            negativePattern: '- !#',
            pattern: '+ !#',
          }).format()
          return (
            <span
              className={cn({
                negativeValue: value.includes('-'),
                positiveValue: !value.includes('-'),
              })}
            >
              {value}
            </span>
          )
        },
        id: 'amount',
        maxWidth: 130,
      },
      {
        Header: 'Expiration',
        accessor: d => {
          const expirationDate = d.expiration.expirationDate
          const daysUtilExpiration = differenceInCalendarDays(expirationDate, new Date())
          const expiringSoon = daysUtilExpiration <= 14 && daysUtilExpiration >= 0
          const isExpired = daysUtilExpiration < 0

          return (
            <span
              className={cn({
                'expired-credit': isExpired,
                'expiring-credit': expiringSoon,
              })}
            >
              {expiringSoon && 'exp. '}
              {d.amount > 0 && format(expirationDate, 'MM/DD/YYYY')}
            </span>
          )
        },
        id: 'expires',
        maxWidth: 130,
      },
    ]
    return (
      <SpinnerCard style={{ width: '100%' }}>
        {creditTransactions?.totalElements === 0 ? (
          <Box display="flex" justsifyContent="center">
            <h5>{`You haven't received or used any credits yet.`}</h5>
          </Box>
        ) : (
          <>
            <ReactTable
              columns={columns}
              data={this.state.data}
              loading={loading}
              manual
              minRows={0}
              page={this.state.currentPage}
              pageSize={this.state.pageSize}
              resizable={false}
              showPagination={false}
              sortable={false}
            />
            <Pagination
              currentPage={this.state.currentPage}
              onChange={num => this.handleChange(num)}
              style={{ marginTop: '16px' }}
              totalPages={this.state.totalPages}
            />
          </>
        )}
      </SpinnerCard>
    )
  }
}

const mapStateToProps = state => {
  const { creditTransactions, loading } = state.credits
  const { availableCredits } = state.creditHistory
  const { currentUser, isCurrentUserLoading } = state.user

  return {
    availableCredits,
    creditTransactions,
    currentUser,
    isCurrentUserLoading,
    loading,
  }
}

const mapDispatchToProps = {
  loadAvailableCreditsStart,
  loadCreditTransactionStart,
}
export default connect(mapStateToProps, mapDispatchToProps)(CreditsTable)
