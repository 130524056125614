import { date, number, object, string } from 'yup'
import { format, compareAsc, parse } from 'date-fns'
import { capitalize, isNil } from 'lodash'
import pluralize from 'pluralize'
import { isPaymentMethodRequiredForTeamPerks } from '../admin/adminUtils'
import { formatCentsToPrettyDollars } from '../../teams/perks/giftCardUtils'

export const PROGRAM_DIALOG_HEIGHT_SMALL = '120px'
export const PROGRAM_DIALOG_HEIGHT = '540px'
export const PROGRAM_DIALOG_HEIGHT_BIG = '580px'

export const STEPS = Object.freeze({
  PROGRAM_USAGE_TYPE: 0,
  PROGRAM_AMOUNT: 1,
  PROGRAM_MEMBERS: 2,
  PROGRAM_DETAILS: 3,
  PROGRAM_ADD_PAYMENT: 4,
  PROGRAM_COMPLETE: 5,
})

export const PROGRAM_USAGE_TYPES = Object.freeze({
  DAILY: {
    id: 'DAILY',
    label: 'Daily',
  },
  WEEKLY: {
    id: 'WEEKLY',
    label: 'Weekly',
  },
  MONTHLY: {
    id: 'MONTHLY',
    label: 'Monthly',
  },
})

export const getTotalDottedSteps = (is100PercentOffer, teamPaymentCollectionMethod) =>
  isPaymentMethodRequiredForTeamPerks(is100PercentOffer, teamPaymentCollectionMethod) ? 5 : 4

export const getStepDetails = (currentStep, values) => {
  switch (currentStep) {
    case STEPS.PROGRAM_USAGE_TYPE:
      return {
        title: 'Select Frequency',
        validationSchema: object().shape({
          usageType: string().required('Perk frequency is required.'),
        }),
      }
    case STEPS.PROGRAM_AMOUNT:
      return {
        title: 'Set Amount',
        validationSchema: object().shape({
          discountAmountInPennies: number().min(1, 'Amount must be greater than zero.'),
          schedule: object().test('schedule', 'A minimum of one day must be selected.', function() {
            const schedule = this.options.parent.schedule
            const { usageType } = values
            return (
              !usageType ||
              usageType === 'WEEKLY' ||
              usageType === 'MONTHLY' ||
              (usageType === 'DAILY' && Object.values(schedule).some(day => day === true)) // at least one day must be TRUE if DAILY is selected
            )
          }),
        }),
      }
    case STEPS.PROGRAM_MEMBERS:
      return {
        title: 'Add Members',
        validationSchema: object().shape({}),
      }
    case STEPS.PROGRAM_DETAILS:
      return {
        title: 'Perk Details:',
        validationSchema: object().shape({
          name: string().required('Perk name is required.'),
          startDate: date().test('startDate', 'Start date cannot be before today.', function() {
            const today = new Date().setHours(0, 0, 0, 0)
            const startDate = parse(this.options.parent.startDate)
            return !isNil(startDate) && compareAsc(startDate, today) >= 0
          }),
          endDate: date().test('endDate', 'End date should be after start date.', function() {
            const startDate = this.options.parent.startDate
            const endDate = this.options.parent.endDate
            return isNil(endDate) || compareAsc(endDate, startDate) > 0
          }),
        }),
      }
    case STEPS.PROGRAM_ADD_PAYMENT:
      return {
        title: 'Add Payment Method',
        validationSchema: object().shape({}),
      }
    default:
      return {}
  }
}

export const getProgramUsageTypeDescription = usageType => {
  switch (usageType) {
    case PROGRAM_USAGE_TYPES.DAILY.id:
      return 'Give your members a set amount for lunch each day.'
    case PROGRAM_USAGE_TYPES.WEEKLY.id:
      return 'Give your members a set amount they can spend each week.'
    case PROGRAM_USAGE_TYPES.MONTHLY.id:
      return 'Give your members a set amount they can spend each month.'
    default:
      return 'Give your members a set amount they can spend over the course of this time period.'
  }
}

export const getProgramAmountDescription = usageType => {
  switch (usageType) {
    case PROGRAM_USAGE_TYPES.DAILY.id:
      return 'How much will each member receive each day for lunch?'
    case PROGRAM_USAGE_TYPES.WEEKLY.id:
      return 'How much will each member receive each week for lunch?'
    case PROGRAM_USAGE_TYPES.MONTHLY.id:
      return 'How much will each member receive each month for lunch?'
    default:
      return 'How much will each member receive over the selected time period for lunch?'
  }
}

export const offerRedemptionAmountDescription = usageType => {
  switch (usageType) {
    case PROGRAM_USAGE_TYPES.DAILY.id:
      return 'Amount each member will receive each day for lunch:'
    case PROGRAM_USAGE_TYPES.WEEKLY.id:
      return 'Amount each member will receive each week for lunch:'
    case PROGRAM_USAGE_TYPES.MONTHLY.id:
      return 'Amount each member will receive each month for lunch:'
    default:
      return 'Amount each member will receive over the selected time period for lunch'
  }
}

export const offerAmountContributionDescription = (
  isPercent,
  amountPerUse,
  totalLimit,
  usageType,
) => {
  const totalLimitText = totalLimit && totalLimit > 0 ? `, up to $${totalLimit} in total.` : '.'
  if (isPercent) {
    return `Foodsby is covering ${amountPerUse}% of this perk${totalLimitText}`
  }
  return `Foodsby is covering $${amountPerUse /
    100} of this perk ${usageType.toLowerCase()} for each user${totalLimitText}`
}

export const is100PercentOffer = (offerRedeeming, perkAmount) =>
  offerRedeeming &&
  offerRedeeming.amountPerUse === (offerRedeeming.discountType === 'PERCENT' ? 100 : perkAmount)

export const getPerkPaymentHelperText = (offerRedeeming, perkAmount) => {
  if (is100PercentOffer(offerRedeeming, perkAmount)) {
    const upToTotalLimit = offerRedeeming.totalLimit
      ? ` (up to $${offerRedeeming.totalLimit / 100} in total)`
      : ''
    const totalLimitDisclaimer = offerRedeeming.totalLimit ? ' or exceed the total offer limit' : ''

    return `Foodsby covers 100% of this offer${upToTotalLimit}. Your credit card will only be charged if you decide to continue providing this perk beyond the offer period${totalLimitDisclaimer}.`
  }
  return 'All Foodsby perks are charged on the first of each month.'
}

export const DAYS = [
  {
    value: 'mondayUsage',
    label: 'Monday',
  },
  {
    value: 'tuesdayUsage',
    label: 'Tuesday',
  },
  {
    value: 'wednesdayUsage',
    label: 'Wednesday',
  },
  {
    value: 'thursdayUsage',
    label: 'Thursday',
  },
  {
    value: 'fridayUsage',
    label: 'Friday',
  },
]

export const formatDefaultPaymentMethodText = (
  defaultPaymentMethod,
  teamPaymentCollectionMethod,
) => {
  if (teamPaymentCollectionMethod === 'MANUAL') {
    return 'Manual Billing'
  }

  return defaultPaymentMethod
    ? `${capitalize(defaultPaymentMethod.card.brand)} - ${defaultPaymentMethod.card.lastFour}`
    : 'No Default Payment Method'
}

export const formatDefaultPaymentMethodInfoText = teamPaymentCollectionMethod =>
  teamPaymentCollectionMethod === 'MANUAL'
    ? 'You are being invoiced on a monthly basis. Talk with your Foodsby representative if you have questions.'
    : undefined

export const formatFrequencyText = frequency =>
  frequency && frequency[0].toUpperCase() + frequency.slice(1).toLowerCase()

export const formatDiscountAmountText = amount => amount && formatCentsToPrettyDollars(amount)

export const formatMembersText = members =>
  (members?.length || members?.length === 0) &&
  `(${members.length}) ${pluralize('Member', members.length)}`

export const formatMembersSecondaryText = values => {
  if (values.members) {
    if (values.autoEnrollNewMembers) {
      return 'Auto enroll new members'
    } else {
      return 'Do not enroll new members'
    }
  }
  return
}

export const formatDetailsText = values =>
  values.name && `${values.name} | Start date: ${format(values.startDate, 'M/DD/YYYY')}`

export const formatPaymentMethodText = defaultPaymentMethod =>
  defaultPaymentMethod &&
  `${capitalize(defaultPaymentMethod.card?.brand)} | ${defaultPaymentMethod.card?.lastFour}`

export const getStepIconColor = (errorField, hasTriedSubmitting) =>
  errorField && hasTriedSubmitting ? 'error' : 'secondary'
