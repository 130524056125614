import { push } from 'connected-react-router'
import { every, first, isEmpty, isNumber } from 'lodash'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { call, put, take } from 'redux-saga/effects'

import { searchNearbyLocations } from '../../api/api'
import { locationRoute } from '../../routes/routes'
import {
  formatAddress,
  getAddressByPlaceId,
  searchByLatLngFallbackApi,
  searchByLatLngFallbackApiResidential,
} from '../../services/location'
import { formatUrl } from '../../util/formatUtils'
import { apiSaga } from '../modules/sagas'

const CANT_PARSE_ADDRESS = 'There was a problem finding this address.'
// ------------------------------------
// Action Types & Creators
// ------------------------------------
const BASE = 'foodsby/locationSearch/'
export const UPDATE = `${BASE}UPDATE`
export const SEARCH = `${BASE}SEARCH`
export const SUCCESS = `${BASE}SUCCESS`
export const FAIL = `${BASE}FAIL`
export const CLEAR_ERROR = `${BASE}CLEAR_ERROR`
export const FORMATTED_ADDRESS = `${BASE}FORMATTED_ADDRESS`
export const SUBMIT_BUILDING = `${BASE}SUBMIT_BUILDING`
export const SUBMIT_BUILDING_SUCCESS = `${BASE}SUBMIT_BUILDING_SUCCESS`
export const SUBMIT_BUILDING_FAILURE = `${BASE}SUBMIT_BUILDING_FAILURE`
export const LOAD_LOCATIONS_FROM_ID = `${BASE}LOAD_LOCATIONS_FROM_ID`
export const LOAD_LOCATIONS_BY_ID_SUCCESS = `${BASE}LOAD_LOCATIONS_BY_ID_SUCCESS`
export const LOAD_LOCATION_BY_ID_FAILURE = `${BASE}LOAD_LOCATION_BY_ID_FAILURE`
export const FORMAT_ADDRESS_FROM_ADDRESSID = `${BASE}FORMAT_ADDRESS_FROM_ADDRESSID`
export const SEARCH_BY_LATLNG = `${BASE}SEARCH_BY_LATLNG`
export const CLEAR_SEARCH = `${BASE}CLEAR_SEARCH`
export const SELECTED_KIOSK = `${BASE}SELECTED_KIOSK`

export function update(address) {
  return { payload: address, type: UPDATE }
}

export function search(address, redirect = true, lat, lng, completeAccountToken, fallback = true) {
  return {
    payload: { address, completeAccountToken, lat, lng, redirect, fallback },
    type: SEARCH,
  }
}

export function searchByLatLng(address, redirect = true) {
  return { payload: { address, redirect }, type: SEARCH_BY_LATLNG }
}

export function formattedSearchAddress(address) {
  return { payload: address, type: FORMATTED_ADDRESS }
}

export function locationSearchSucceeded({ lat, lng, locations }) {
  return {
    payload: { lat, lng, locations },
    type: SUCCESS,
  }
}

export function locationSearchFailed(response) {
  return {
    error: response,
    type: FAIL,
  }
}

export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  }
}

export function clearError() {
  return {
    type: CLEAR_ERROR,
  }
}

export const loadLocationsFromAddressIdStart = id => {
  return {
    payload: { id },
    type: LOAD_LOCATIONS_FROM_ID,
  }
}

export const loadLocationsByIdSuccess = locations => {
  return {
    payload: { locations },
    type: LOAD_LOCATIONS_BY_ID_SUCCESS,
  }
}

export const loadLocationByIdFailure = error => {
  return {
    error,
    type: LOAD_LOCATION_BY_ID_FAILURE,
  }
}

export const formatFromAddressIdStart = id => {
  return {
    payload: { id },
    type: FORMAT_ADDRESS_FROM_ADDRESSID,
  }
}

export const selectedKiosk = () => {
  return {
    type: SELECTED_KIOSK,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [CLEAR_ERROR]: state => ({
    ...state,
    error: undefined,
  }),
  [CLEAR_SEARCH]: state => ({
    ...state,
    address: undefined,
    completeAccountToken: undefined,
    lat: undefined,
    lng: undefined,
    locations: undefined,
    noResults: undefined,
  }),
  [FAIL]: (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
    submitting: undefined,
  }),
  [FORMATTED_ADDRESS]: (state, action) => ({
    ...state,
    formattedSearchAddress: action.payload,
  }),
  [LOAD_LOCATIONS_BY_ID_SUCCESS]: (state, action) => {
    const {
      payload: { locations },
    } = action
    return {
      ...state,
      error: undefined,
      isLocationLoading: false,
      locations,
    }
  },
  [LOAD_LOCATIONS_FROM_ID]: (state, action) => {
    const {
      payload: { id },
    } = action
    return {
      ...state,
      id,
      isLocationLoading: true,
    }
  },
  [LOAD_LOCATION_BY_ID_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      error,
      isLocationLoading: false,
      locations: undefined,
    }
  },
  [SEARCH]: (state, action) => {
    const { address, completeAccountToken, lat, lng } = action.payload
    return {
      ...state,
      address,
      completeAccountToken,
      error: undefined,
      lat,
      lng,
      loading: true,
      submitting: true,
    }
  },
  [SUBMIT_BUILDING]: state => ({
    ...state,
    error: undefined,
    submitting: true,
  }),
  [SUBMIT_BUILDING_FAILURE]: (state, action) => ({
    ...state,
    error: action.error.response ? action.error.data : action.error.message,
    submitting: undefined,
    success: false,
  }),
  [SUBMIT_BUILDING_SUCCESS]: state => ({
    ...state,
    address: undefined,
    formattedSearchAddress: undefined,
    submitting: undefined,
    success: true,
  }),
  [SUCCESS]: (state, action) => {
    const { lat, lng, locations } = action.payload
    return {
      ...state,
      lat,
      lng,
      loading: false,
      locations,
      noResults: lat && lng && isEmpty(locations),
      submitting: undefined,
    }
  },
  [UPDATE]: (state, action) => ({
    ...state,
    address: action.payload,
    error: undefined,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  locations: undefined,
}

export default function locationSearch(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* watchLocationSearch() {
  while (true) {
    const {
      payload: { address, lat, lng, redirect, fallback },
    } = yield take(SEARCH)

    let results
    try {
      // Geocode the given address
      const geocodedAddress = first(yield call(geocodeByAddress, address))
      if (!isEmpty(geocodedAddress)) {
        // Determine if we need to get the lat/lng from the geocoded address if it wasn't passed in the payload
        const cordinates = every({ lat, lng }, isNumber)
          ? { lat, lng }
          : yield call(getLatLng, geocodedAddress)

        // Format the geocoded address and determine how much information we have about the address
        const formattedAddressComponents = formatAddress(geocodedAddress)
        const containsAllAddressInfo = !every(formattedAddressComponents, isEmpty)

        // Make the relevant api call depending on how much address info we have.
        // NOTE: Only if we have street/city/state/zip is when we get back residential information.
        results = containsAllAddressInfo
          ? yield call(searchByLatLngFallbackApiResidential, {
              city: formattedAddressComponents.city,
              lat: cordinates.lat,
              lng: cordinates.lng,
              state: formattedAddressComponents.state,
              street: formattedAddressComponents.street,
              zip: formattedAddressComponents.zip,
              fallback,
            })
          : yield call(searchByLatLngFallbackApi, {
              lat: cordinates.lat,
              lng: cordinates.lng,
              fallback,
            })

        yield put(
          locationSearchSucceeded({ lat: cordinates.lat, lng: cordinates.lng, locations: results }),
        )
      } else {
        throw new Error(CANT_PARSE_ADDRESS)
      }
    } catch (error) {
      yield put(locationSearchFailed(error))
    } finally {
      if (redirect) {
        // If there is only one location found, redirect to that location page
        if (results?.length === 1) {
          yield put(clearSearch())
          yield put(
            push(
              formatUrl(locationRoute.path, {
                locationId: results[0].deliveryLocationId,
              }),
            ),
          )
        }
      }
    }
  }
}

export function* watchLoadLocationsFromId() {
  while (true) {
    try {
      const {
        payload: { id },
      } = yield take(LOAD_LOCATIONS_FROM_ID)
      const { address, latLng } = yield call(getAddressByPlaceId, id)

      yield call(
        apiSaga,
        searchNearbyLocations,
        [address, latLng],
        locationSearchSucceeded,
        locationSearchFailed,
      )
    } catch (ex) {
      yield put(locationSearchFailed(ex))
    }
  }
}

export function* formatFromAddressId() {
  const {
    payload: { id },
  } = yield take(FORMAT_ADDRESS_FROM_ADDRESSID)
  const { address } = yield call(getAddressByPlaceId, id)
  yield put({ payload: address, type: FORMATTED_ADDRESS })
}
