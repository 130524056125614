import React, { useState } from 'react'
import { Box, Tabs, Tab } from '@material-ui/core'
import { connect } from 'react-redux'

import {
  selectGiftTransactionDetails,
  selectGiftTransactions,
  selectInvoices,
} from '../../../redux/selectors/teamBillingHistorySelectors'
import { selectTeam } from '../../../redux/selectors/teamSelectors'
import {
  loadGiftTransactionsStart,
  loadInvoicesStart,
  downloadUsageReportStart,
  loadGiftTransactionDetailsStart,
  resetGiftTransactionDetailsState,
} from '../../../redux/modules/teamBilling'
import TabPanel from '../../common/TabPanel'
import CardTeam, { CardTeamContent, CardTeamTitle } from '../common/CardTeam'
import TableInvoices from './TableInvoices'
import TableGiftTransactions from './TableGiftTransactions'

const CardBillingHistory = ({
  team,
  invoices,
  giftTransactions,
  giftTransactionDetails,
  isLoadingTeam,
  isLoadingInvoices,
  isLoadingGiftTransactions,
  loadInvoicesStart,
  loadGiftTransactionsStart,
  downloadUsageReportStart,
  loadGiftTransactionDetailsStart,
  resetGiftTransactionDetailsState,
}) => {
  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event, value) => {
    setTabValue(value)
  }

  return (
    <CardTeam>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <CardTeamTitle>Billing History</CardTeamTitle>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Invoices" />
          <Tab label="Gifts" />
        </Tabs>
      </Box>
      <CardTeamContent>
        {!isLoadingTeam && (
          <>
            <TabPanel value={tabValue} index={0}>
              <TableInvoices
                teamId={team.id}
                invoices={invoices}
                loading={isLoadingInvoices}
                loadInvoicesStart={loadInvoicesStart}
                downloadUsageReportStart={downloadUsageReportStart}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <TableGiftTransactions
                teamId={team.id}
                giftTransactions={giftTransactions}
                giftTransactionDetails={giftTransactionDetails}
                loading={isLoadingGiftTransactions}
                loadGiftTransactionsStart={loadGiftTransactionsStart}
                loadGiftTransactionDetailsStart={loadGiftTransactionDetailsStart}
                resetGiftTransactionDetailsState={resetGiftTransactionDetailsState}
              />
            </TabPanel>
          </>
        )}
      </CardTeamContent>
    </CardTeam>
  )
}

const mapStateToProps = state => {
  return {
    team: selectTeam(state),
    isLoadingTeam: state.team.isLoadingTeam,
    isLoadingInvoices: state.teamBilling.isLoadingInvoices,
    invoices: selectInvoices(state),
    giftTransactions: selectGiftTransactions(state),
    giftTransactionDetails: selectGiftTransactionDetails(state),
    isLoadingGiftTransactions: state.teamBilling.isLoadingGiftTransactions,
  }
}

const mapDispatchToProps = {
  loadInvoicesStart,
  loadGiftTransactionsStart,
  downloadUsageReportStart,
  loadGiftTransactionDetailsStart,
  resetGiftTransactionDetailsState,
}

export default connect(mapStateToProps, mapDispatchToProps)(CardBillingHistory)
