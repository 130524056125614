import React, { useMemo } from 'react'
import { InputAdornment, TextField } from '@material-ui/core'
import { SearchRounded } from '@material-ui/icons'

const InputSearchMembers = ({ onChange }) =>
  useMemo(() => {
    return (
      <TextField
        variant="outlined"
        placeholder="Search members"
        fullWidth
        size="small"
        onChange={e => onChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRounded />
            </InputAdornment>
          ),
        }}
      />
    )
  }, [onChange])

export default InputSearchMembers
