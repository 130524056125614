import { addDays, format } from 'date-fns'
import { api, API_MONO, monolithCache } from '../api/api'
import { getCurrentDate } from '../util/dateUtils'
import { pascalcaseKeys } from '../util/keys'

export const getDeliveriesForLocationApi = (locationId, date) => {
  const today = getCurrentDate()
  const sixDaysFromToday = new Date(format(addDays(today, 6), 'MM/DD/YYYY'))
  const formattedDate = new Date(format(date, 'MM/DD/YYYY'))
  if (formattedDate > sixDaysFromToday) {
    return []
  }

  return api.get(`${API_MONO}/location/${locationId}/schedule?day=${date}&duration=1`, null, {
    cache: monolithCache,
  })
}

export const getDeliveryRewardsForLocationApi = (locationId, date) => {
  const today = getCurrentDate()
  const sixDaysFromToday = new Date(format(addDays(today, 6), 'MM/DD/YYYY'))
  const formattedDate = new Date(format(date, 'MM/DD/YYYY'))
  if (formattedDate > sixDaysFromToday) {
    return []
  }

  return api.get(`${API_MONO}/location/${locationId}/rewards?day=${date}`, null, {
    cache: monolithCache,
  })
}

export const getDeliveryDropoffApi = deliveryDropoffId =>
  api.get(`${API_MONO}/deliveries/dropoff/${deliveryDropoffId}`)

export const createCateringDeliveryApi = request =>
  api.post(`${API_MONO}/deliveries/catering`, pascalcaseKeys(request))

export const updateReserveDeliveryApi = request =>
  api.put(`${API_MONO}/deliveries/reserve`, pascalcaseKeys(request))

export const setReserveFeeApi = (request, deliveryDropoffId) =>
  api.patch(
    `${API_MONO}/deliveries/reserve/set-reserve-fee?deliveryDropoffId=${deliveryDropoffId}`,
    pascalcaseKeys(request),
  )

export const getReserveFeeApi = deliveryDropoffId =>
  api.get(`${API_MONO}/deliveries/reserve/reserve-fee?deliveryDropoffId=${deliveryDropoffId}`)

export const getCateringDeliveryDateAndTimeApi = (storeId, locationId) =>
  api.get(`${API_MONO}/deliveries/catering?storeId=${storeId}&locationId=${locationId}`)

export const getReserveDeliveryDateAndTimeApi = (storeId, locationId) =>
  api.get(`${API_MONO}/deliveries/reserve?storeId=${storeId}&locationId=${locationId}`)
