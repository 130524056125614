import currency from 'currency.js'
import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { formatCentsToPrettyDollars } from '../components/teams/perks/giftCardUtils'
import { UNKNOWN_CUSTOMER } from './constants'

export const ReserveFeeNotice = ({ isReserveConfirmedDropoff }) => {
  const handleClick = event => {
    event.stopPropagation()
  }

  return (
    <div onClick={handleClick}>
      <p>
        The delivery fee ensures exclusive delivery to your location at a set time and varies by
        restaurant choice and other factors.{' '}
        {!isReserveConfirmedDropoff && 'Split the fee to earn back credits when others order.'}
      </p>
    </div>
  )
}

export const SplitDeliveryFeeNotice = ({ deliveryFeeContribution, createdByName }) => {
  const handleClick = event => {
    event.stopPropagation()
  }

  if (createdByName === 'You') {
    return (
      <div onClick={handleClick}>
        <p>
          You scheduled a delivery to your location and established a{' '}
          {currency(deliveryFeeContribution, {
            formatWithSymbol: true,
            precision: deliveryFeeContribution % 1 === 0 ? 0 : 2,
          }).format()}{' '}
          delivery fee to offset your initial payment. When others place their orders, the delivery
          fees they pay will be credited back to you.
        </p>
      </div>
    )
  }

  return (
    <div onClick={handleClick}>
      <p>
        {createdByName === UNKNOWN_CUSTOMER ? `A ${UNKNOWN_CUSTOMER}` : createdByName} has scheduled
        a delivery at your location and established a{' '}
        {currency(deliveryFeeContribution, {
          formatWithSymbol: true,
          precision: deliveryFeeContribution % 1 === 0 ? 0 : 2,
        }).format()}{' '}
        delivery fee to offset their initial payment. When you place an order, your delivery fee is
        credited to the customer who secured and paid for this reservation.
      </p>
    </div>
  )
}

export const CreditsEarnedNotice = ({
  reserveCreditsEarned,
  reserveDeliveryFee,
  currentRewardsInCents,
  currentRewardsPercentage,
  countOfOrdersOnDropoff,
  rewardsEligibleSubtotalInCents,
  numberOfOrdersPerRewardsPercentage,
  rewardsDistributed,
  isPastCutoff,
}) => {
  const shouldShowRewardsTiers =
    !rewardsDistributed && !isPastCutoff && numberOfOrdersPerRewardsPercentage != null
  return (
    <>
      <Grid container spacing={2}>
        {reserveCreditsEarned !== null && reserveDeliveryFee > 0 && (
          <Grid item>
            <Box display={'flex'} flexDirection={'row'} marginBottom={'5px'}>
              <Typography component={'p'} style={{ fontWeight: 500 }}>
                Delivery Fees: {currency(reserveCreditsEarned, { formatWithSymbol: true }).format()}
              </Typography>
              <Typography style={{ marginLeft: '3px' }}>
                {`(${currency(reserveDeliveryFee, {
                  formatWithSymbol: true,
                }).format()} x ${countOfOrdersOnDropoff - 1} orders)`}
              </Typography>
            </Box>
            <Typography component={'p'}>
              Receive {currency(reserveDeliveryFee, { formatWithSymbol: true }).format()} in credits
              with each order on this delivery.
            </Typography>
          </Grid>
        )}
        {currentRewardsInCents != null &&
          reserveCreditsEarned !== null &&
          reserveDeliveryFee > 0 && <Box marginTop={'1.5em'} />}
        {currentRewardsInCents != null && (
          <Grid item>
            <Typography component={'p'} style={{ fontWeight: 500 }}>
              Delivery Rewards: {formatCentsToPrettyDollars(currentRewardsInCents)} (
              {Math.round(currentRewardsPercentage * 100)}% x{' '}
              {rewardsEligibleSubtotalInCents <= 0
                ? '$0'
                : formatCentsToPrettyDollars(rewardsEligibleSubtotalInCents)}
              )
            </Typography>
            <Box marginTop={'0.5em'}>
              <Typography>
                Rewards are based on the number of placed orders and the restaurant, calculated on
                the order total minus fees and taxes.
              </Typography>
            </Box>
            {shouldShowRewardsTiers && (
              <Box marginTop={'0.5em'}>{formatRates(numberOfOrdersPerRewardsPercentage)}</Box>
            )}
          </Grid>
        )}
      </Grid>
    </>
  )
}

export function formatRates(numberOfOrdersPerRewardsPercentage) {
  if (numberOfOrdersPerRewardsPercentage === undefined) {
    return null
  }
  const entries = Object.entries(numberOfOrdersPerRewardsPercentage)

  let minOrders = entries[0][0]
  let maxOrders = entries[entries.length - 1][0]
  let minPercentage = entries[0][1]
  let maxPercentage = entries[entries.length - 1][1]
  let middleRangeStart = parseInt(minOrders) + 1
  let middleRangeEnd = maxOrders - 1
  let middleMinPercentage = entries[1][1]
  let middleMaxPercentage = entries[entries.length - 2][1]

  return (
    <div>
      <p>{`${minOrders} order = ${(minPercentage * 100).toFixed(0)}%`}</p>
      <p>{`${middleRangeStart} - ${middleRangeEnd} orders = ${(middleMinPercentage * 100).toFixed(
        0,
      )} - ${(middleMaxPercentage * 100).toFixed(0)}%`}</p>
      <p>{`${maxOrders}+ orders = ${(maxPercentage * 100).toFixed(0)}%`}</p>
    </div>
  )
}

export const findTotalCredits = (reserveCreditsEarned, rewardsCreditsEarned) => {
  if (rewardsCreditsEarned == null || isNaN(rewardsCreditsEarned)) {
    return reserveCreditsEarned
  } else if (reserveCreditsEarned == null || isNaN(reserveCreditsEarned)) {
    return rewardsCreditsEarned
  } else {
    return rewardsCreditsEarned + reserveCreditsEarned
  }
}
