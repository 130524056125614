import React from 'react'
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { Form, Formik } from 'formik'
import { format } from 'date-fns'

import ButtonSubmit from '../../common/ButtonSubmit'
import DialogContent from './DialogContent'
import { PROGRAM_DIALOG_HEIGHT } from './programUtils'

const StepProgramDetails = ({
  values,
  handleClose,
  handleGetTotal,
  setValues,
  isRedeemingOffer,
  offerDiscountEndDate,
  validationSchema,
}) => {
  const helperText =
    'Your perk name is for internal use only and will not be viewable by your members.'

  const handleSubmit = newValues => {
    setValues({
      ...values,
      name: newValues.name,
      startDate: newValues.startDate,
      endDateRequested: newValues.endDateRequested,
      endDate: newValues.endDate,
    })
    handleGetTotal({
      name: newValues.name,
      startDate: newValues.startDate,
      endDateRequested: newValues.endDateRequested,
      endDate: newValues.endDate,
    })
    handleClose()
  }

  return (
    <Formik
      initialValues={{
        name: values.name,
        startDate: values.startDate || format(new Date(), 'YYYY-MM-DD'),
        endDateRequested: values.endDateRequested,
        endDate: values.endDate,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {props => {
        const { setFieldValue, values: subValues, errors, touched } = props

        const toggleEndDate = isChecked => {
          setFieldValue('endDateRequested', isChecked)
          setFieldValue('endDate', '')
        }

        return (
          <Form>
            <DialogContent height={PROGRAM_DIALOG_HEIGHT}>
              <DialogContentText>What do you want to name your perk?</DialogContentText>
              <TextField
                autoFocus
                fullWidth
                id="name"
                name="name"
                variant="outlined"
                label="Perk Name"
                value={subValues.name}
                onChange={event => setFieldValue('name', event.target.value)}
                error={touched.name && Boolean(errors.name)}
                helperText={(touched.name && errors.name) || helperText}
              />

              <Box paddingY={4}>
                <DialogContentText>When would you like your perk to start?</DialogContentText>
                <Tooltip
                  title={isRedeemingOffer ? 'Start date cannot be changed with this offer.' : ''}
                >
                  <TextField
                    fullWidth
                    id="startDate"
                    type="date"
                    variant="outlined"
                    defaultValue={subValues.startDate}
                    onChange={event => setFieldValue('startDate', event.target.value)}
                    error={touched.startDate && Boolean(errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                    disabled={isRedeemingOffer}
                  />
                </Tooltip>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={subValues.endDateRequested}
                    onChange={event => toggleEndDate(event.target.checked)}
                    name="setEnd"
                    disabled={isRedeemingOffer}
                  />
                }
                label="Set an end date?"
              />
              {subValues.endDateRequested && (
                <TextField
                  autoFocus={subValues.endDateRequested}
                  fullWidth
                  id="endDate"
                  type="date"
                  variant="outlined"
                  defaultValue={subValues.endDate}
                  onChange={event => setFieldValue('endDate', event.target.value)}
                  error={touched.endDate && Boolean(errors.endDate)}
                  helperText={touched.endDate && errors.endDate}
                  disabled={isRedeemingOffer}
                />
              )}
              {isRedeemingOffer && offerDiscountEndDate && (
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Foodsby contribution ends {offerDiscountEndDate}
                  </Typography>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <ButtonSubmit color="primary" variant="contained">
                Save
              </ButtonSubmit>
            </DialogActions>
          </Form>
        )
      }}
    </Formik>
  )
}

export default StepProgramDetails
