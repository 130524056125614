import { Box, InputError, RadioOption } from '@foodsby/nutrient'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  Typography,
} from '@material-ui/core'
import React from 'react'

export const SelfCancelError = ({ selfCancelError }) => (
  <InputError visible={selfCancelError && selfCancelError !== null}>
    {selfCancelError.message || selfCancelError}
  </InputError>
)

export const SuccessMessage = () => (
  <div className="panel">
    <p className="panel-title">Thank you for contacting us.</p>
    <Box mb={3}>
      <p>A dedicated Foodsby Team Member will contact you as soon as possible.</p>
    </Box>
  </div>
)

export const ErrorMessage = () => (
  <InputError visible={true}>
    Sorry. Something went wrong with your submission. Please try again in a few minutes.
  </InputError>
)

export const ConfirmMessage = ({ cancelRejected, confirmingCancel }) => (
  <Dialog open maxWidth="xs">
    <DialogTitle>Confirm Order Cancelation</DialogTitle>
    <DialogContent>
      <DialogContentText>
        This will cancel your entire order. If you used credits on this order, they will be
        refunded.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="secondary" onClick={cancelRejected}>
        Undo
      </Button>
      <Button color="primary" onClick={confirmingCancel}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
)

const ContactUsLightForm = ({ handleChange, handleSubmit, reasons }) => {
  return (
    <form className="panel-form" onSubmit={handleSubmit}>
      <Box ml={'1em'} mb={'1.5em'}>
        {reasons &&
          reasons.map((reason, i) => (
            <RadioOption
              className="panel-options"
              id={reason.label}
              key={i}
              label={reason.label}
              name="reasonOption"
              onChange={handleChange}
              required
              value={reason.id}
            />
          ))}
      </Box>
      <Box>
        <Typography>Comments:</Typography>
      </Box>
      <TextField
        multiline
        id="comment"
        minRows={4}
        inputProps={{ maxLength: 1000, style: { resize: 'none' } }}
        margin="normal"
        name="comment"
        onChange={handleChange}
        required
        variant="outlined"
      />
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button color="secondary" variant="contained" type="submit">
          Submit
        </Button>
      </Box>
    </form>
  )
}
export default ContactUsLightForm
