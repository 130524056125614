import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardActions, CardContent, CardHeader } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const StretchedCard = withStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',

    '& .MuiCardHeader-root': {
      paddingBottom: theme.spacing(1),
    },
    '& .MuiCardContent-root': {
      paddingTop: theme.spacing(1),
    },
  },
}))(Card)

const CardPerk = ({ title, description, buttonComponentType, buttonText, onClick }) => {
  return (
    <StretchedCard>
      <CardHeader title={title} />
      <CardContent>{description}</CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          component={buttonComponentType}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </CardActions>
    </StretchedCard>
  )
}

CardPerk.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonComponentType: PropTypes.oneOf(['button', 'a']),
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

CardPerk.defaultProps = {
  buttonComponentType: 'a',
}

export default CardPerk
