import React from 'react'
import { LinkOff } from '@material-ui/icons'

import LayoutMessage from '../layouts/LayoutMessage'
import ButtonBackToHome from '../components/common/ButtonBackToHome'

const PageNotFound = () => {
  return (
    <LayoutMessage
      title="Page Not Found"
      description="Sorry, the page you are looking for either does not exist, has been moved, or is temporarily unavailable."
      Icon={<LinkOff />}
      Action={<ButtonBackToHome buttonProps={{ variant: 'contained', color: 'primary' }} />}
    />
  )
}

export default PageNotFound
