import { isEmpty } from 'lodash'
import { object } from 'yup'
import pluralize from 'pluralize'

import { capitalize, isNil } from 'lodash'

export const isSubscriptionCanceled = subscription => !isEmpty(subscription?.endDate)

export const CHECKOUT_STEPS = Object.freeze({
  SUBSCRIPTION_MEMBERS: 0,
  SUBSCRIPTION_ADD_PAYMENT: 1,
})

export const getCheckoutStepDetails = currentStep => {
  switch (currentStep) {
    case CHECKOUT_STEPS.SUBSCRIPTION_MEMBERS:
      return {
        title: 'Select Members',
        validationSchema: object().shape({}),
      }
    case CHECKOUT_STEPS.SUBSCRIPTION_ADD_PAYMENT:
      return {
        title: 'Select Payment Method',
        validationSchema: object().shape({}),
      }
    default:
      return {}
  }
}

export const formatPaymentMethodText = defaultPaymentMethod =>
  defaultPaymentMethod &&
  `${capitalize(defaultPaymentMethod.card?.brand)} | ${defaultPaymentMethod.card?.lastFour}`

export const formatMembersText = members =>
  !isNil(members) && `(${members.length}) ${pluralize('Member', members.length)}`

export const getStepIconColor = (errorField, hasTriedSubmitting) =>
  errorField && hasTriedSubmitting ? 'error' : 'secondary'
