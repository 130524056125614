import { Box, Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ChevronRight } from '@material-ui/icons'
import { isEmpty } from 'lodash'
import pluralize from 'pluralize'
import React, { useState } from 'react'

import GoogleMap from './GoogleMap'
import RequestFoodsbyForm from './RequestFoodsbyForm'

const useStyles = makeStyles({
  title: {
    fontSize: '32px',
    fontWeight: 600,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '20px',
    fontWeight: 400,
    textAlign: 'center',
  },
})

const LocationsList = ({
  goToLocation,
  isOpenId,
  locations,
  onClickOverride,
  onToggleOpen,
  locationListButtonText = 'Select this location',
}) => (
  <div className="searchLocationsList" id="searchLocationsList">
    {locations.map((location, i) => {
      const selected = isOpenId === location.deliveryLocationId
      return (
        <Box
          border="solid 1px #e1e1e1"
          borderRadius="4px"
          className="searchLocationListItem"
          data-selected={selected}
          id={`searchLocation-${location.deliveryLocationId}`}
          key={i}
          marginBottom={3}
          onClick={() =>
            onClickOverride ? onClickOverride(location) : goToLocation(location.deliveryLocationId)
          }
          onMouseOut={() => onToggleOpen(location.deliveryLocationId)}
          onMouseOver={() => onToggleOpen(location.deliveryLocationId)}
          padding="8px"
          textAlign="left"
        >
          <Typography gutterBottom noWrap variant="subtitle2">
            {location.locationName}
          </Typography>
          <Typography display="block" noWrap variant="caption">
            {location.deliveryLine1}
          </Typography>
          <Typography display="block" gutterBottom noWrap variant="caption">
            {location.lastLine}
          </Typography>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                color="primary"
                disableElevation
                endIcon={<ChevronRight />}
                onClick={e => {
                  e.stopPropagation()
                  onClickOverride
                    ? onClickOverride(location)
                    : goToLocation(location.deliveryLocationId)
                }}
                size="small"
              >
                {locationListButtonText}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )
    })}
  </div>
)

const SelectLocation = ({
  address,
  lat,
  lng,
  locations = [],
  onClickOverride,
  locationListButtonText,
}) => {
  const classes = useStyles()
  const [isOpenId, setIsOpenId] = useState()
  const onToggleOpen = id => {
    if (id === isOpenId) {
      setIsOpenId(undefined)
    } else {
      setIsOpenId(id)
    }
  }

  const locationsFound = !isEmpty(locations)
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          {locationsFound
            ? `There ${pluralize('is', locations.length)} ${
                locations.length
              } Foodsby delivery ${pluralize('location', locations.length)} near ${address}`
            : "Sorry, it looks like we don't service your area yet."}
        </Typography>
        {!locationsFound && (
          <Typography className={classes.subtitle}>
            Please request Foodsby and we will get in touch with you.
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        <GoogleMap
          isOpenId={isOpenId}
          lat={lat}
          lng={lng}
          locations={locations || []}
          onClickOverride={onClickOverride}
          onToggleOpen={onToggleOpen}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        {locationsFound && (
          <LocationsList
            locationListButtonText={locationListButtonText}
            isOpenId={isOpenId}
            locations={locations}
            onClickOverride={onClickOverride}
            onToggleOpen={onToggleOpen}
          />
        )}

        {!locationsFound && <RequestFoodsbyForm />}
      </Grid>
    </Grid>
  )
}

export default SelectLocation
