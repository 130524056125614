export const ROWS_PER_PAGE = 10

export const LocationType = Object.freeze({
  GROUP_ORDER: 'GroupOrder',
  MANAGED_PROPERTY: 'ManagedProperty',
  RESIDENTIAL_COMPLEX: 'ResidentialComplex',
  RESIDENTIAL_HOME: 'ResidentialHome',
  RESIDENTIAL_ZONE: 'ResidentialZone',
  SINGLE_COMPANY: 'SingleCompany',
})

export const FOODSBY_ADDRESS = Object.freeze({
  STREET: '3001 Broadway St NE',
  CITY: 'Minneapolis',
  STATE: 'MN',
  ZIP: '55413',
})

export const UNKNOWN_CUSTOMER = 'Foodsby Customer'

export const SubscriptionStatus = Object.freeze({
  UNKNOWN: 'unknown',
  SUBSCRIBED: 'subscribed',
  UNSUBSCRIBED: 'unsubscribed',
})

export const LocationStatusType = Object.freeze({
  PROSPECT: 'Prospect',
  USERCREATED: 'UserCreated',
  UNQUALIFIED: 'Unqualified',
  PREFERRED: 'Preferred',
  NONPREFERRED: 'NonPreferred',
})
