export const NEW_PAYMENT_METHOD_ID = -1

export const isDefaultPaymentMethod = (paymentMethods, paymentMethodId) =>
  paymentMethodId === getDefaultPaymentOrUndefined(paymentMethods)?.id

export const isNewPaymentMethod = paymentMethodId => paymentMethodId === NEW_PAYMENT_METHOD_ID

export const getDefaultPaymentOrUndefined = paymentMethods =>
  paymentMethods?.find(pm => pm.default === true)

export const hasPaymentMethods = paymentMethods => paymentMethods?.length > 0
