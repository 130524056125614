import { createSelector } from 'reselect'

export const selectTeamPrograms = createSelector(
  [state => state.teamPrograms.programs],
  programs => programs,
)

export const selectProgramUsageTypes = createSelector(
  [state => state.teamPrograms.programUsageTypes],
  programUsageTypes => programUsageTypes,
)
