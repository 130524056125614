import { Button, TextField, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import currency from 'currency.js'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

const useStyles = makeStyles(theme => ({
  inputRoot: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  applyButton: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    height: 40,
  },
}))

export const Coupon = ({
  applyCouponStart,
  couponAmount,
  couponApplied,
  couponCode,
  error,
  isCheckoutCartLoading,
  isCouponApplying,
  willSubscribe,
}) => {
  const classes = useStyles()
  const [value, setValue] = useState('')

  const handleApplyCoupon = event => {
    event.preventDefault()
    applyCouponStart(value.trim(), willSubscribe)
  }

  const handleChangeCoupon = () => {
    applyCouponStart('', willSubscribe)
    setValue('')
  }

  return (
    <Box display="flex" alignItems="flex-start">
      <TextField
        disabled={couponApplied}
        label="Coupon Code"
        fullWidth
        variant="outlined"
        color="secondary"
        size="small"
        id="coupon"
        onChange={evt => setValue(evt.target.value)}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            handleApplyCoupon(e)
          }
        }}
        value={couponApplied ? couponCode : value}
        InputProps={{
          classes: {
            root: classes.inputRoot,
          },
        }}
        error={Boolean(error)}
        helperText={
          error ||
          `${currency(couponAmount, {
            formatWithSymbol: true,
          }).format()} applied`
        }
      />
      <Button
        variant="contained"
        disableElevation
        color="secondary"
        classes={{ root: classes.applyButton }}
        disabled={isCheckoutCartLoading || isCouponApplying}
        onClick={couponApplied ? handleChangeCoupon : handleApplyCoupon}
      >
        {couponApplied ? 'Remove' : 'Apply'}
      </Button>
    </Box>
  )
}

Coupon.propTypes = {
  applyCouponStart: PropTypes.func,
  error: PropTypes.string,
  isCheckoutCartLoading: PropTypes.bool,
}

export default Coupon
