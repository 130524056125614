import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useHistory } from 'react-router'

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'

import { AttachMoney, CreditCardOutlined, InfoOutlined, People } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import { array, number, object, string } from 'yup'
import { isEmpty } from 'lodash'
import LayoutInnerPage, { HeaderInnerPage } from '../layouts/LayoutInnerPage'
import { formatUrl } from '../util/formatUtils'
import ListActionableDetails from '../components/common/ListActionableDetails'
import Captcha from '../components/common/Captcha'
import { teamPerksRoute, loginRoute } from '../routes/routes'

import { selectTeam, selectDefaultTeamPaymentMethod } from '../redux/selectors/teamSelectors'

import { formatPaymentMethodText, getStepIconColor } from '../components/teams/perks/programUtils'

import {
  getCheckoutStepDetails,
  formatDiscountAmountText,
  formatMembersText,
  offerDiscountCodeOptions,
} from '../components/teams/perks/giftCardUtils'

import { NEW_PAYMENT_METHOD_ID } from '../util/paymentMethods'

import ButtonDialogClose from '../components/teams/common/ButtonDialogClose'
import StepProgramAmount from '../components/teams/perks/StepProgramAmount'
import StepProgramMembers from '../components/teams/perks/StepProgramMembers'
import StepProgramGiftDetails from '../components/teams/perks/StepProgramGiftDetails'
import StepAddPaymentMethod from '../components/teams/perks/StepAddPaymentMethod'
import StepProgramDiscountCode from '../components/teams/perks/StepProgramDiscountCode'

import { selectTeamMembers } from '../redux/selectors/teamMembersSelectors'

import { loadTeamMembersStart } from '../redux/modules/teamMembers'
import { enqueueSnackbar } from '../redux/modules/snackbar'
import { loadTeamPaymentMethodsStart } from '../redux/modules/team'
import { createGiftStart, validateDiscountCodeStart } from '../redux/modules/teamGiftCards'
import { formatCentsToPrettyDollars } from '../components/teams/perks/giftCardUtils'
import useTeamPaymentMethod from '../hooks/useTeamPaymentMethod'
import ButtonSubmit from '../components/common/ButtonSubmit'

const useStyles = makeStyles(theme => ({
  perkSummary: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.common.white,
    minWidth: '400px',
    height: '100%',
    marginRight: '32px',
    marginTop: '32px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: '250px',
      marginRight: '0px',
      marginTop: '32px',
    },
  },
  perkSteps: {
    minWidth: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 'auto',
    },
  },
  recurringPerkPageContainer: {
    flexFlow: 'row wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: theme.spacing(3),
    },
  },
}))

const PageOneTimePerk = ({
  isAuthenticated,
  team,
  teamMembers,
  isLoadingTeamMembers,
  isCreatingGiftCards,
  isValidatingDiscount,
  errorCreatingGiftCards,
  errorValidatingDiscount,
  totalChargeAmount,
  loadTeamMembersStart,
  paymentMethods,
  isUpdatingDefaultPaymentMethod,
  loadTeamPaymentMethodsStart,
  isLoadingPaymentMethods,
  defaultPaymentMethod,
  validateDiscountCodeStart,
  createGiftStart,
  discountCode,
  codeDiscountAmount,
}) => {
  const [dialogToShow, setDialogToShow] = useState(null)
  const confirmedTeamMembers = teamMembers.filter(
    member => member.status === 'CONFIRMED' || member.status === 'INVITED',
  )
  const {
    submitting: isSubmittingPaymentMethod,
    error: errorPaymentMethods,
    handleSubmitTeamPaymentMethod,
  } = useTeamPaymentMethod({
    teamId: team.id,
    onSuccess: () => {},
  })

  const classes = useStyles()
  const history = useHistory()

  const handleItemClicked = perkOption => {
    setDialogToShow(perkOption)
  }

  const handleSubmit = values => {
    const path = formatUrl(teamPerksRoute.path, { accountId: team?.id })
    createGiftStart(createPayload(values), () => history.push(path))
  }

  const onCouponCodeValidationRequested = (values, code) => {
    const payload = createPayload({ ...values, discountCode: code })
    validateDiscountCodeStart(payload)
  }

  const createPayload = values => {
    const { reason, message } = values
    return {
      userIds: values.members,
      amountPer: values.discountAmountInPennies,
      reason,
      message,
      discountCode: values.discountCode,
    }
  }

  const handleGetTotalAndClose = values => {
    handleGetTotal(values, {})
    setDialogToShow(null)
  }

  const handleClearDiscountCode = (values, setFieldValue) => {
    validateDiscountCodeStart(createPayload({ ...values, discountCode: undefined }))
    setFieldValue && setFieldValue('discountCode', undefined)
  }

  const handleClose = () => {
    setDialogToShow(null)
  }

  useEffect(() => {
    if (team) {
      loadTeamMembersStart(team.id)
    }
  }, [loadTeamMembersStart, team])

  useEffect(() => {
    handleClearDiscountCode()
  }, [])

  useEffect(() => {
    if (paymentMethods?.length === 0 && team && isLoadingPaymentMethods) {
      loadTeamPaymentMethodsStart(team.id)
    }
  }, [paymentMethods, loadTeamPaymentMethodsStart, isLoadingPaymentMethods, team])

  if (!isAuthenticated) {
    return <Redirect to={loginRoute.path} />
  }

  const getNextStep = (values, ONE_TIME_PERK_OPTIONS) => {
    if (!values.discountAmountInPennies) {
      return ONE_TIME_PERK_OPTIONS[0].id
    } else if (!values.members) {
      return ONE_TIME_PERK_OPTIONS[1].id
    } else if (!values.reason) {
      return ONE_TIME_PERK_OPTIONS[2].id
    } else if (
      (!values.SelectedCard || values.SelectedCard < 0) &&
      !isFree(codeDiscountAmount, values.discountAmountInPennies, values.members?.length)
    ) {
      return ONE_TIME_PERK_OPTIONS[3].id
    }
  }

  const isReadyToAddDiscountCode = (values, ONE_TIME_PERK_OPTIONS) => {
    return ![ONE_TIME_PERK_OPTIONS[0].id, ONE_TIME_PERK_OPTIONS[1].id].includes(
      getNextStep(values, ONE_TIME_PERK_OPTIONS),
    )
  }

  const handleGetTotal = (newValues, previousValues) => {
    const params = { ...previousValues, ...newValues }
    validateDiscountCodeStart(createPayload(params))
  }

  const formatDetailRow = (title, value) => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      margin={3}
      marginLeft={0}
    >
      <Typography variant="body2">{title}</Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  )

  const isFree = (codeDiscountAmount, discountAmountInPennies, memberAmount) => {
    return codeDiscountAmount >= discountAmountInPennies * memberAmount
  }

  const mainSchema = object().shape({
    discountAmountInPennies: number()
      .moreThan(0)
      .required(' '),
    members: array().test('members', ' ', function() {
      return !!this.options.parent.members
    }),
    reason: string().required(' '),
    SelectedCard: number().test('SelectedCard', ' ', function() {
      const SelectedCard = this.options.parent.SelectedCard
      const discountAmountInPennies = this.options.parent.discountAmountInPennies
      const members = this.options.parent.members
      return (
        isFree(codeDiscountAmount, discountAmountInPennies, members?.length) || SelectedCard > 0
      )
    }),
  })
  return (
    <LayoutInnerPage
      HeaderComponent={
        <HeaderInnerPage
          showBackButton
          backButtonRoute={formatUrl(teamPerksRoute.path, { accountId: team?.id })}
        >
          Create One-Time Perk
        </HeaderInnerPage>
      }
      MainComponent={
        <>
          {paymentMethods && (defaultPaymentMethod || paymentMethods.length === 0) && (
            <Formik
              initialValues={{
                discountAmountInPennies: 0,
                members: null,
                reason: '',
                message: '',
                discountCode: undefined,
                SelectedCard: defaultPaymentMethod?.id ?? NEW_PAYMENT_METHOD_ID,
              }}
              onSubmit={handleSubmit}
              validationSchema={mainSchema}
            >
              {props => {
                const { values, errors, submitCount, setFieldValue } = props
                const hasTriedSubmitting = submitCount > 0
                const { title, validationSchema } = getCheckoutStepDetails(dialogToShow?.step)

                const ONE_TIME_PERK_OPTIONS = [
                  {
                    id: 'AMOUNT',
                    step: 0,
                    title:
                      formatDiscountAmountText(values.discountAmountInPennies) ||
                      'How much would you like to give each member?',
                    secondaryText: 'Perk Amount',
                    image: (
                      <AttachMoney
                        color={getStepIconColor(errors.discountAmountInPennies, hasTriedSubmitting)}
                      />
                    ),
                    buttonText: 'EDIT',
                  },
                  {
                    id: 'MEMBERS',
                    step: 1,
                    title:
                      formatMembersText(values.members) ||
                      'Who would you like to give the perk to?',
                    secondaryText: 'Perk Members',
                    image: <People color={getStepIconColor(errors.members, hasTriedSubmitting)} />,
                    buttonText: 'EDIT',
                  },
                  {
                    id: 'DETAILS',
                    step: 2,
                    title: values.reason || 'Perk Details',
                    secondaryText: 'Occasion & personal message (optional)',
                    image: (
                      <InfoOutlined color={getStepIconColor(errors.reason, hasTriedSubmitting)} />
                    ),
                    buttonText: 'EDIT',
                  },
                  {
                    id: 'PAYMENT_METHOD',
                    step: 3,
                    title:
                      formatPaymentMethodText(defaultPaymentMethod) || 'Default Payment Method',
                    secondaryText: !defaultPaymentMethod ? '' : 'Default Payment Method',

                    image: (
                      <CreditCardOutlined
                        color={getStepIconColor(errors.SelectedCard, hasTriedSubmitting)}
                      />
                    ),
                    buttonText: 'EDIT',
                  },
                ]

                return (
                  <Form>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      className={classes.recurringPerkPageContainer}
                    >
                      <Box marginRight={4} width={'50%'} className={classes.perkSteps}>
                        <Box marginTop={4}>
                          <ListActionableDetails
                            listItems={ONE_TIME_PERK_OPTIONS}
                            handleClick={handleItemClicked}
                            activeId={getNextStep(values, ONE_TIME_PERK_OPTIONS)}
                          />
                        </Box>
                        <Dialog open={!!dialogToShow} fullWidth maxWidth="sm">
                          <>
                            <DialogTitle>
                              {title}
                              <ButtonDialogClose onClick={() => handleGetTotalAndClose(values)} />
                            </DialogTitle>
                            {dialogToShow?.id === ONE_TIME_PERK_OPTIONS[0].id && (
                              <StepProgramAmount
                                handleClose={handleClose}
                                handleGetTotal={newValues => handleGetTotal(newValues, values)}
                                validationSchema={validationSchema}
                                programAmountDescription="How much would you like to give each member?"
                                {...props}
                              />
                            )}
                            {dialogToShow?.id === ONE_TIME_PERK_OPTIONS[1].id && (
                              <StepProgramMembers
                                teamMembers={confirmedTeamMembers}
                                loading={isLoadingTeamMembers}
                                handleClose={handleClose}
                                handleGetTotal={newValues => handleGetTotal(newValues, values)}
                                isOneTimeGift
                                {...props}
                              />
                            )}
                            {dialogToShow?.id === ONE_TIME_PERK_OPTIONS[2].id && (
                              <StepProgramGiftDetails
                                handleClose={handleClose}
                                validationSchema={validationSchema}
                                handleGetTotal={newValues => handleGetTotal(newValues, values)}
                                {...props}
                              />
                            )}
                            {dialogToShow?.id === ONE_TIME_PERK_OPTIONS[3].id && (
                              <StepAddPaymentMethod
                                team={team}
                                paymentMethods={paymentMethods}
                                paymentMethodsError={errorPaymentMethods}
                                submitting={
                                  isSubmittingPaymentMethod || isUpdatingDefaultPaymentMethod
                                }
                                defaultPaymentMethod={defaultPaymentMethod}
                                validationSchema={validationSchema}
                                handleGetTotal={newValues => handleGetTotal(newValues, values)}
                                handleConfirm={values =>
                                  handleSubmitTeamPaymentMethod(values.SelectedCard)
                                }
                                handleClose={handleClose}
                                {...props}
                              />
                            )}
                            {dialogToShow?.id === offerDiscountCodeOptions.id && (
                              <StepProgramDiscountCode
                                handleClose={handleClose}
                                handleGetTotal={newValues => handleGetTotal(newValues, values)}
                                validationSchema={validationSchema}
                                isValidatingDiscount={isValidatingDiscount}
                                errorValidatingDiscount={errorValidatingDiscount}
                                updateDiscountCode={code =>
                                  onCouponCodeValidationRequested(values, code)
                                }
                                discountCode={discountCode}
                                {...props}
                              />
                            )}
                          </>
                        </Dialog>
                      </Box>
                      <Box
                        width={'35%'}
                        padding={4}
                        minWidth="400px"
                        className={classes.perkSummary}
                      >
                        <Box marginBottom={4}>
                          <Typography variant="h6">Details</Typography>
                        </Box>
                        {formatDetailRow('Type', 'One-Time Perk')}
                        {!!values.discountAmountInPennies &&
                          formatDetailRow(
                            'Amount',
                            `${formatCentsToPrettyDollars(
                              values.discountAmountInPennies,
                            )} per member`,
                          )}
                        {(!!values.members?.length || values.members?.length === 0) &&
                          formatDetailRow('Members', values.members?.length)}
                        <Divider />
                        {!discountCode &&
                          formatDetailRow(
                            'Add Offer Code',
                            <Tooltip
                              title={
                                !isReadyToAddDiscountCode(values, ONE_TIME_PERK_OPTIONS)
                                  ? 'Please provide perk amount and perk members before adding an offer code'
                                  : ''
                              }
                            >
                              <Box marginTop={3} marginBottom={3}>
                                <Button
                                  onClick={() => setDialogToShow(offerDiscountCodeOptions)}
                                  color="secondary"
                                  variant="contained"
                                  disabled={
                                    !isReadyToAddDiscountCode(values, ONE_TIME_PERK_OPTIONS)
                                  }
                                >
                                  CODE
                                </Button>
                              </Box>
                            </Tooltip>,
                          )}

                        {discountCode && (
                          <Box marginTop={3} marginBottom={3}>
                            {formatDetailRow(
                              <Box display="flex" alignItems="center" justifyContent="flex-end">
                                <Box>
                                  <Typography>{discountCode}</Typography>
                                </Box>
                                <IconButton
                                  size="small"
                                  onClick={() => handleClearDiscountCode(values, setFieldValue)}
                                >
                                  <Clear color="action" fontSize="small" />
                                </IconButton>
                              </Box>,
                              <Typography>
                                {formatCentsToPrettyDollars(-codeDiscountAmount)}
                              </Typography>,
                            )}
                          </Box>
                        )}
                        <Divider />
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          margin={3}
                          marginLeft={0}
                          marginBottom={0}
                        >
                          <Box display="flex">
                            <Typography variant="body1">Total</Typography>
                          </Box>
                          <Typography>{formatCentsToPrettyDollars(totalChargeAmount)}</Typography>
                        </Box>
                        <Box marginTop={3}>
                          <Captcha />
                        </Box>
                        <Box marginTop={3}>
                          <ButtonSubmit
                            color="primary"
                            width="100%"
                            variant="contained"
                            submitting={isCreatingGiftCards}
                          >
                            Purchase Perk
                          </ButtonSubmit>
                        </Box>
                        {errorCreatingGiftCards && (
                          <Box margin={2} marginTop={3} marginBottom={3}>
                            <Typography color="error" variant="caption">
                              {errorCreatingGiftCards}
                            </Typography>
                          </Box>
                        )}
                        {!isEmpty(errors) && hasTriedSubmitting && (
                          <Box margin={2} marginTop={3} marginBottom={3}>
                            <Typography color="error" variant="caption">
                              Please complete all steps before purchasing.
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Form>
                )
              }}
            </Formik>
          )}
        </>
      }
    />
  )
}

const mapStateToProps = state => {
  const {
    isCreatingGiftCards,
    isValidatingDiscount,
    errorCreatingGiftCards,
    errorValidatingDiscount,
    totalChargeAmount,
    discountCode,
    discountAmountInPennies: codeDiscountAmount,
  } = state.teamGiftCards

  const { paymentMethods, isUpdatingDefaultPaymentMethod, isLoadingPaymentMethods } = state.team

  return {
    isAuthenticated: state.user?.isAuthenticated,
    team: selectTeam(state),
    isLoadingTeamMembers: state.teamMembers.isLoadingTeamMembers,
    teamMembers: selectTeamMembers(state),
    isCreatingGiftCards,
    isValidatingDiscount,
    errorCreatingGiftCards,
    errorValidatingDiscount,
    totalChargeAmount,
    paymentMethods,
    isUpdatingDefaultPaymentMethod,
    isLoadingPaymentMethods,
    defaultPaymentMethod: selectDefaultTeamPaymentMethod(state),
    discountCode,
    codeDiscountAmount,
  }
}

const mapDispatchToProps = {
  loadTeamMembersStart,
  enqueueSnackbar,
  createGiftStart,
  loadTeamPaymentMethodsStart,
  validateDiscountCodeStart,
}

PageOneTimePerk.defaultProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PageOneTimePerk)
