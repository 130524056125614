export const DIALOG_OPTIONS = Object.freeze({
  EDIT_NICKNAME: {
    id: 'EDIT_NICKNAME',
    title: 'Edit Nickname',
    confirmText: 'SAVE',
    cancelText: 'CANCEL',
  },
  EDIT_DELIVERY_INSTRUCTIONS: {
    id: 'EDIT_DELIVERY_INSTRUCTIONS',
    title: 'Edit Delivery Instructions',
    confirmText: 'SAVE',
    cancelText: 'CANCEL',
  },
  REMOVE_LOCATION: {
    id: 'REMOVE_LOCATION',
    title: 'Remove Location?',
    confirmText: 'CONFIRM',
    cancelText: null,
  },
  NEW_LOCATION: {
    id: 'NEW_LOCATION',
    title: 'Search New Location',
    confirmText: null,
    cancelText: null,
  },
  SAVE_LOCATION: {
    id: 'SAVE_LOCATION',
    title: 'Save this location',
    confirmText: 'Save',
    cancelText: null,
  },
})
