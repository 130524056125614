import { addDays } from 'date-fns'
import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import {
  addProgramMembersApiV3,
  createProgramApi,
  getAvailableOffersApi,
  getProgramDiscountAmountApi,
  getProgramsApi,
  getProgramUsageTypesApi,
  updateProgramApi,
} from '../../services/team'
import { selectTeam } from '../selectors/teamSelectors'
import { enqueueSnackbar } from './snackbar'
import { setSelectedProgram } from './teamPerkDetails'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const CURRENT_STEP_SET = 'foodsby/teamPrograms/CURRENT_STEP_SET'
export const PROGRAM_FORM_VALUES_SET = 'foodsby/teamPrograms/PROGRAM_FORM_VALUES_SET'
export const PROGRAMS_SET = 'foodsby/teamPrograms/PROGRAMS_SET'
export const PROGRAM_USAGE_TYPES_SET = 'foodsby/teamPrograms/PROGRAM_USAGE_TYPES_SET'
export const PROGRAM_CREATE = 'foodsby/teamPrograms/PROGRAM_CREATE'
export const PROGRAM_MEMBERS_ADD = 'foodsby/teamPrograms/PROGRAM_MEMBERS_ADD'
export const PROGRAM_UPDATE = '/foodsby/teamPrograms/PROGRAM_UPDATE'
export const AVAILABLE_OFFERS_SET = '/foodsby/teamPrograms/AVAILABLE_OFFERS_SET'
export const REDEEMING_OFFER_SET = '/foodsby/teamPrograms/REDEEMING_OFFER_SET'
export const PROGRAM_DISCOUNT_AMOUNT_SET = '/foodsby/teamPrograms/PROGRAM_DISCOUNT_AMOUNT_SET'

export const setCurrentStep = createAction(CURRENT_STEP_SET)
export const setProgramFormValues = createAction(PROGRAM_FORM_VALUES_SET)
export const setPrograms = createAsyncAction(PROGRAMS_SET)
export const setProgramsFulfilled = createAction(FULFILLED(PROGRAMS_SET))
export const setProgramUsageTypes = createAsyncAction(PROGRAM_USAGE_TYPES_SET)
export const createProgram = createAsyncAction(PROGRAM_CREATE)
export const addProgramMembers = createAsyncAction(PROGRAM_MEMBERS_ADD)
export const updateProgramPending = createAction(PENDING(PROGRAM_UPDATE))
export const updateProgramFulfilled = createAction(FULFILLED(PROGRAM_UPDATE))
export const updateProgramRejected = createAction(REJECTED(PROGRAM_UPDATE))
export const setAvailableOffers = createAsyncAction(AVAILABLE_OFFERS_SET)
export const setProgramDiscountAmount = createAsyncAction(PROGRAM_DISCOUNT_AMOUNT_SET)
export const setOfferRedeeming = createAction(REDEEMING_OFFER_SET)

// ------------------------------------
// Thunks
// ------------------------------------
export const loadProgramsStart = (accountId, page, pageSize) => async dispatch =>
  await dispatch(setPrograms(getProgramsApi(accountId, page, pageSize)))

export const loadProgramUsageTypesStart = () => async dispatch =>
  await dispatch(setProgramUsageTypes(getProgramUsageTypesApi()))

export const loadAvailableOffersStart = accountId => async dispatch =>
  await dispatch(setAvailableOffers(getAvailableOffersApi(accountId)))

export const loadProgramDiscountAmountStart = programValues => async dispatch =>
  await dispatch(setProgramDiscountAmount(getProgramDiscountAmountApi(programValues)))

export const updateProgramStart = (updateProgramRequest = { programId: undefined }) => {
  return async (dispatch, getState) => {
    const state = getState()
    const team = selectTeam(state)
    const snackBarMessage = message => sendSnackbarMessage(dispatch, message)
    if (updateProgramRequest.programId && updateProgramRequest.programId > 0) {
      try {
        dispatch(updateProgramPending())

        //only send properties to the api that it needs
        const updateProgramApiRequest = {}
        for (let prop in updateProgramRequest) {
          switch (prop) {
            case 'createSnackbarSuccessMessage':
            case 'programId':
              break
            default:
              updateProgramApiRequest[prop] = updateProgramRequest[prop]
              break
          }
        }
        await updateProgramApi(updateProgramRequest.programId, updateProgramApiRequest)

        const updatedPrograms = await getProgramsApi(team.id)
        const updatedProgram = updatedPrograms.find(
          p => p.programId === updateProgramRequest.programId,
        )
        dispatch(setSelectedProgram(updatedProgram))
        dispatch(setProgramsFulfilled(updatedPrograms))
        dispatch(updateProgramFulfilled())
        if (updateProgramRequest.createSnackbarSuccessMessage) {
          snackBarMessage(updateProgramRequest.createSnackbarSuccessMessage(updatedProgram))
        }
      } catch (ex) {
        dispatch(updateProgramRejected())
        snackBarMessage(
          'Something went wrong updating the perk. Please try again or contact support.',
        )
      }
    } else {
      snackBarMessage(
        'Perk could not be updated. Program information is invalid. Please try again or contact support.',
      )
    }
  }
}

export const createProgramStart = (onSuccess, values) => {
  return async (dispatch, getState) => {
    const state = getState()
    const team = selectTeam(state)

    const programRequest = {
      name: values.name,
      displayName: `${team.name} Perk`,
      discountAmountInPennies: values.discountAmountInPennies,
      startDate: values.startDate,
      endDate: values.endDateRequested ? addDays(values.endDate, 1) : null,
      autoEnrollNewMembers: values.autoEnrollNewMembers,
      schedule: {
        sundayUsage: values.schedule.sundayUsage,
        mondayUsage: values.schedule.mondayUsage,
        tuesdayUsage: values.schedule.tuesdayUsage,
        wednesdayUsage: values.schedule.wednesdayUsage,
        thursdayUsage: values.schedule.thursdayUsage,
        fridayUsage: values.schedule.fridayUsage,
        saturdayUsage: values.schedule.saturdayUsage,
      },
      usageType: values.usageType,
      accountDiscountOfferId: values.accountDiscountOfferId,
    }
    const programMembersRequest = {
      userIds: values.members,
      notifyUsers: values.notifyUsers,
    }

    // TODO One action (createProgram) can be used here
    // for making both of the create program & the add program members calls
    // and delete the addProgramMembers action from this module
    const response = await dispatch(createProgram(createProgramApi(team.id, programRequest)))
    await dispatch(
      addProgramMembers(addProgramMembersApiV3(response.value.programId, programMembersRequest)),
    )
    dispatch(loadProgramsStart(team.id))

    if (programRequest.accountDiscountOfferId) {
      dispatch(loadAvailableOffersStart(team.id))
    }

    sendSnackbarMessage(
      dispatch,
      `Your perk, "${programRequest.name}", has been created and is active.`,
    )

    onSuccess && onSuccess()
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CURRENT_STEP_SET]: (state, action) => {
    return {
      ...state,
      currentStep: action.payload,
    }
  },
  [PENDING(PROGRAMS_SET)]: state => {
    return {
      ...state,
      isLoadingPrograms: true,
    }
  },
  [FULFILLED(PROGRAMS_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingPrograms: false,
      programs: action.payload,
      errorLoadingPrograms: undefined,
    }
  },
  [REJECTED(PROGRAMS_SET)]: state => {
    return {
      ...state,
      isLoadingPrograms: false,
      errorLoadingPrograms:
        'Something went wrong when creating the perk. Please try again later or contact Support.',
    }
  },
  [PENDING(PROGRAM_USAGE_TYPES_SET)]: state => {
    return {
      ...state,
      isLoadingProgramUsageTypes: true,
    }
  },
  [FULFILLED(PROGRAM_USAGE_TYPES_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingProgramUsageTypes: false,
      programUsageTypes: action.payload,
      errorLoadingProgramUsageTypes: undefined,
    }
  },
  [REJECTED(PROGRAM_USAGE_TYPES_SET)]: state => {
    return {
      ...state,
      isLoadingProgramUsageTypes: false,
      errorLoadingProgramUsageTypes:
        'Something went wrong when loading program usage types. Please try again later or contact Support.',
    }
  },
  [PENDING(AVAILABLE_OFFERS_SET)]: state => {
    return {
      ...state,
      isLoadingOffers: true,
    }
  },
  [FULFILLED(AVAILABLE_OFFERS_SET)]: (state, action) => {
    return {
      ...state,
      availableOffers: action.payload ?? [],
      isLoadingOffers: false,
      errorLoadingOffers: undefined,
    }
  },
  [REJECTED(AVAILABLE_OFFERS_SET)]: state => {
    return {
      ...state,
      isLoadingOffers: false,
      errorLoadingOffers:
        'Something went wrong when loading offers. Please try again later or contact support.',
    }
  },
  [PENDING(PROGRAM_DISCOUNT_AMOUNT_SET)]: state => {
    return {
      ...state,
      isLoadingProgramDiscountAmount: true,
      errorLoadingProgramDiscountAmount: undefined,
    }
  },
  [FULFILLED(PROGRAM_DISCOUNT_AMOUNT_SET)]: (state, action) => {
    return {
      ...state,
      programDiscountAmount: action.payload ?? {},
      isLoadingProgramDiscountAmount: false,
      errorLoadingProgramDiscountAmount: undefined,
    }
  },
  [REJECTED(PROGRAM_DISCOUNT_AMOUNT_SET)]: state => {
    return {
      ...state,
      isLoadingProgramDiscountAmount: false,
      errorLoadingProgramDiscountAmount:
        'Something went wrong when loading the total amount. Please try again later or contact support.',
    }
  },
  [PENDING(PROGRAM_CREATE)]: state => {
    return {
      ...state,
      isCreatingProgram: true,
      errorCreatingProgram: undefined,
    }
  },
  [FULFILLED(PROGRAM_CREATE)]: (state, action) => {
    return {
      ...state,
      isCreatingProgram: false,
      createdProgram: action.payload,
      errorCreatingProgram: undefined,
    }
  },
  [REJECTED(PROGRAM_CREATE)]: (state, action) => {
    return {
      ...state,
      isCreatingProgram: false,
      errorCreatingProgram: action.payload.message,
    }
  },
  [PENDING(PROGRAM_MEMBERS_ADD)]: state => {
    return {
      ...state,
      isAddingProgramMembers: true,
      errorAddingProgramMembers: undefined,
    }
  },
  [FULFILLED(PROGRAM_MEMBERS_ADD)]: state => {
    return {
      ...state,
      isAddingProgramMembers: false,
      errorAddingProgramMembers: undefined,
    }
  },
  [REJECTED(PROGRAM_MEMBERS_ADD)]: (state, action) => {
    return {
      ...state,
      isAddingProgramMembers: false,
      errorAddingProgramMembers: action.payload.message,
    }
  },
  [PENDING(PROGRAM_UPDATE)]: state => {
    return {
      ...state,
      isUpdatingProgram: true,
    }
  },
  [FULFILLED(PROGRAM_UPDATE)]: state => {
    return {
      ...state,
      isUpdatingProgram: false,
    }
  },
  [REJECTED(PROGRAM_UPDATE)]: state => {
    return {
      ...state,
      isUpdatingProgram: false,
    }
  },
  [PROGRAM_FORM_VALUES_SET]: (state, action) => {
    return {
      ...state,
      programFormValues: action.payload,
    }
  },
  [REDEEMING_OFFER_SET]: (state, action) => {
    return {
      ...state,
      offerRedeeming: action.payload,
    }
  },
}

export const initialState = {
  currentStep: undefined,
  programs: [],
  programUsageTypes: [],
  createdProgram: undefined,
  availableOffers: [],
  programFormValues: undefined,
  // Loading states
  isLoadingPrograms: true,
  isLoadingProgramUsageTypes: true,
  isCreatingProgram: false,
  isUpdatingProgram: false,
  isLoadingOffers: true,
  // Success states
  // Error states
  errorLoadingPrograms: undefined,
  errorLoadingProgramUsageTypes: undefined,
  errorCreatingProgram: undefined,
  errorLoadingOffers: undefined,
  offerRedeeming: undefined,
}

export default function teamPrograms(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Utils
// ------------------------------------
const sendSnackbarMessage = (dispatch, message) => {
  dispatch(
    enqueueSnackbar({
      message,
    }),
  )
}
