export const NOTIFICATION_START = 'foodsby/slackbot/NOTIFICATION_START'

export const NOTIFICATION_ACK = 'foodsby/slackbot/NOTIFICATION_ACK'

export const notificationStart = (message, error) => {
  return {
    payload: { error, message },
    type: NOTIFICATION_START,
  }
}

export const notificationAck = () => {
  return {
    type: NOTIFICATION_ACK,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [NOTIFICATION_ACK]: state => {
    return {
      ...state,
      error: null,
      message: null,
    }
  },
  [NOTIFICATION_START]: (state, action) => {
    return {
      ...state,
      error: action?.payload?.error,
      message: action?.payload?.message,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {}

export default function successNotification(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
