import { Box } from '@material-ui/core'
import React from 'react'
import { themeConWeb } from '../../util/modernThemeConweb'

export const SectionHeaderWrapper = ({ children }) => {
  return (
    <Box
      display="block"
      flexDirection="row"
      alignItems="end"
      paddingTop={4}
      paddingBottom={'25px'}
      style={{
        position: 'sticky',
        top: '55px',
        backgroundColor: themeConWeb.color.grey,
        zIndex: 999,
        marginLeft: '-5px',
        paddingLeft: '5px',
        marginBottom: '5px',
      }}
    >
      {children}
    </Box>
  )
}

export default SectionHeaderWrapper
