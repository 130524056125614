import { format, getDay, isToday } from 'date-fns'

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const shortenedDays = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']

export const getDayName = date => {
  if (isToday(date)) {
    return 'today'
  } else {
    return days[getDay(date)]
  }
}

export const getShortDayName = date => {
  if (isToday(date)) {
    return 'today'
  } else {
    return shortenedDays[getDay(date)]
  }
}

export const formatTimeSpanStr = (timeSpanString, formatStr) => {
  const [hours, minutes, seconds] = timeSpanString.split(':').map(Number)
  const date = new Date(2000, 0, 1, hours, minutes, seconds)

  return format(date, formatStr)
}

export const formatAsMonthDayOrWeekday = dateStr => {
  var date = parseDateFromCompactFormat(dateStr)

  if (isToday(date)) {
    return 'Today, ' + format(date, 'MMMM D')
  } else {
    return format(date, 'dddd, MMMM D')
  }
}

export const getCurrentDate = () => new Date()

export const parseDateFromCompactFormat = date => {
  // date is "YYYYMMDD"
  var year = parseInt(date.substring(0, 4), 10)
  var month = parseInt(date.substring(4, 6), 10) - 1 // month is 0-indexed
  var day = parseInt(date.substring(6, 8), 10)
  return new Date(year, month, day)
}
