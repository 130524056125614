import { concat, difference } from 'lodash'
import { call, put, select, take } from 'redux-saga/effects'

import { loadMenuItem } from '../../api/api'
import normalizeMenuItem from '../../normalizer/normalizers'
import { normalizeErrorResponse } from '../../util/errorUtils'
import { apiSaga } from './sagas'
import { selectDefaultAnswers, selectMenuItemForEdit } from './selectors'

export const LOAD_MENU_ITEM = 'foodsby/menu/LOAD_MENU_ITEM'
export const LOAD_MENU_ITEM_SUCCESS = 'foodsby/menu/LOAD_MENU_ITEM_SUCCESS'
export const LOAD_MENU_ITEM_FAILURE = 'foodsby/menu/LOAD_MENU_ITEM_FAILURE'
export const SHOW_SUB_QUESTIONS_FOR_INITIAL_ANSWERS =
  'foodsby/menu/SHOW_SUBQUESTIONS_FOR_INITIAL_ANSWERS'
export const CHANGE_MENU_ANSWER = 'foodsby/menu/CHANGE_MENU_ANSWER'
export const ADD_MENU_MODIFIERS = 'foodsby/menu/ADD_MENU_MODIFIERS'
export const CHANGE_QUANTITY = 'foodsby/menu/CHANGE_QUANTITY'
export const CLEAR_PERSONALIZE = 'foodsby/menu/CLEAR_PERSONALIZE'

export const loadMenuItemStart = itemId => {
  return {
    payload: { itemId },
    type: LOAD_MENU_ITEM,
  }
}

export const loadMenuItemSuccess = menuItem => {
  return {
    payload: { menuItem },
    type: LOAD_MENU_ITEM_SUCCESS,
  }
}

export const loadMenuItemFailure = error => {
  return {
    error,
    type: LOAD_MENU_ITEM_FAILURE,
  }
}

export const showSubQuestionsForInitialAnswers = initialAnswers => {
  return {
    payload: { initialAnswers },
    type: SHOW_SUB_QUESTIONS_FOR_INITIAL_ANSWERS,
  }
}

export const changeMenuAnswer = changeDetail => {
  return {
    changeDetail,
    type: CHANGE_MENU_ANSWER,
  }
}

export const addMenuModifiers = modifiers => {
  return {
    payload: { modifiers },
    type: ADD_MENU_MODIFIERS,
  }
}

export const changeQuantity = quantity => {
  return {
    payload: { quantity },
    type: CHANGE_QUANTITY,
  }
}
export const clearPersonalize = () => {
  return {
    type: CLEAR_PERSONALIZE,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ADD_MENU_MODIFIERS]: (state, action) => {
    const displayedQuestions = [...state.displayedQuestions, ...action.payload.modifiers]
    return {
      ...state,
      displayedQuestions,
    }
  },
  [CHANGE_MENU_ANSWER]: (state, action) => {
    let displayedQuestions = state.displayedQuestions.slice()
    const change = action.changeDetail
    const answers = state.normalizedMenuItem.entities.answers
    // Remove orphaned leaves for radio deselect
    if (change.descendants) {
      displayedQuestions = difference(displayedQuestions, change.descendants)
    }

    if (change.selected) {
      displayedQuestions = concat(displayedQuestions, answers[change.answerId].questions)
    } else {
      displayedQuestions = difference(displayedQuestions, answers[change.answerId].questions)
    }
    return {
      ...state,
      displayedQuestions: displayedQuestions,
    }
  },
  [SHOW_SUB_QUESTIONS_FOR_INITIAL_ANSWERS]: (state, action) => {
    let { displayedQuestions } = state
    let { initialAnswers } = action.payload

    Object.entries(initialAnswers)
      .filter(([questionId, answerIds]) => questionId.startsWith('q_'))
      .forEach(([questionId, answerIds]) => {
        answerIds.forEach(answerId => {
          const answer = state.normalizedMenuItem.entities.answers[answerId]
          displayedQuestions = concat(displayedQuestions, answer.questions)
        })
      })

    return {
      ...state,
      displayedQuestions,
    }
  },
  [CHANGE_QUANTITY]: (state, action) => {
    return {
      ...state,
      quantity: action.payload.quantity,
    }
  },
  [CLEAR_PERSONALIZE]: () => {
    return {
      ...initialState,
      loading: false,
    }
  },
  [LOAD_MENU_ITEM]: state => {
    return {
      ...state,
      displayedQuestions: [],
      loading: true,
    }
  },
  [LOAD_MENU_ITEM_FAILURE]: (state, action) => {
    const message = normalizeErrorResponse(action.error.response, action.error.message)
    return {
      ...state,
      displayedQuestions: [],
      error: { message },
      loading: false,
      normalizedMenuItem: initialState.normalizedMenuItem,
    }
  },
  [LOAD_MENU_ITEM_SUCCESS]: (state, action) => {
    const { menuItem } = action.payload
    const normalizedMenuItem = normalizeMenuItem(menuItem)
    let displayedQuestions = menuItem.questions.map(q => q.id)
    let defaultAnswers = {}
    if (normalizedMenuItem.entities.answers) {
      defaultAnswers = Object.entries(normalizedMenuItem.entities.answers).reduce(
        (all, [answerId, answer]) => {
          if (answer.autoSelected) {
            const key = `q_${answer.parentId}`
            all[key] = all[key] || []
            all[key].push(`${answerId}`)
          }

          return all
        },
        {},
      )
    }
    defaultAnswers.quantity = 1

    return {
      ...state,
      error: undefined,
      loading: false,
      normalizedMenuItem,
      defaultAnswers,
      displayedQuestions,
    }
  },
}

const initialState = {
  displayedQuestions: [],
  loading: true,
  normalizedMenuItem: {
    entities: {
      answers: {},
      menuItems: {},
      questions: {},
    },
  },
  defaultAnswers: {},
}

export default function personalize(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------
export function* watchLoadMenuItem() {
  while (true) {
    const {
      payload: { itemId },
    } = yield take(LOAD_MENU_ITEM)
    yield call(apiSaga, loadMenuItem, [itemId], loadMenuItemSuccess, loadMenuItemFailure)
  }
}

export function* watchLoadMenuItemSuccess() {
  while (true) {
    yield take(LOAD_MENU_ITEM_SUCCESS)
    const menuItemForEdit = yield select(selectMenuItemForEdit)
    if (menuItemForEdit && Object.keys(menuItemForEdit).length > 0) {
      yield put(showSubQuestionsForInitialAnswers(menuItemForEdit))
    } else {
      const defaultAnswers = yield select(selectDefaultAnswers)
      yield put(showSubQuestionsForInitialAnswers(defaultAnswers))
    }
  }
}

// ------------------------------------
// Helpers - "Treewalker - Texas Ranger"
// ------------------------------------
export const getDescendantQuestionIds = (questionId, questions, answers) => {
  let result = []
  const walkTree = (result, questionId, questions, answers) => {
    questions[questionId].answers.forEach(answerId => {
      result.push(...answers[answerId].questions)
      answers[answerId].questions.forEach(subQuestionId =>
        walkTree(result, subQuestionId, questions, answers),
      )
    })
  }
  walkTree(result, questionId, questions, answers)
  return result
}
