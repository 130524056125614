import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Card, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import SwitchOnOff from './SwitchOnOff'

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
})

const CardPerkAutoEnrollMembers = ({ perk, isUpdatingPerk, onUpdatePerk }) => {
  const classes = useStyles()
  const [switchedOn, setSwitchedOn] = useState(perk.autoEnrollNewMembers)
  const [updateInProgress, setUpdateInProgress] = useState(false)

  useEffect(() => {
    if (!isUpdatingPerk) {
      setUpdateInProgress(false)
    }
  }, [isUpdatingPerk])

  useEffect(() => {
    if (!updateInProgress) {
      setSwitchedOn(perk.autoEnrollNewMembers)
    }
  }, [perk.autoEnrollNewMembers, updateInProgress])

  const onSwitchChanged = e => {
    setSwitchedOn(e.target.checked)
    if (!updateInProgress && e.target.checked !== perk.autoEnrollNewMembers) {
      setUpdateInProgress(true)
      onUpdatePerk({
        autoEnrollNewMembers: e.target.checked,
        createSnackbarSuccessMessage: p =>
          p.autoEnrollNewMembers
            ? `Now auto-enrolling members into ${p.name}`
            : `Auto-enrolling disabled for ${p.name}`,
      })
    }
  }

  return (
    <Card className={classes.root}>
      <Box display="flex" alignItems="center" className={classes.root}>
        <Box flexGrow={1} display="flex" alignItems="center">
          <Box>
            <Typography variant="h6">Auto-enroll new members</Typography>
          </Box>
        </Box>
        <Box>
          <SwitchOnOff
            name="autoEnrollNewMembers"
            disabled={isUpdatingPerk}
            checked={switchedOn}
            onChange={onSwitchChanged}
          />
        </Box>
      </Box>
    </Card>
  )
}

CardPerkAutoEnrollMembers.propTypes = {
  perk: PropTypes.shape({
    autoEnrollNewMembers: PropTypes.bool.isRequired,
  }),
  isUpdatingPerk: PropTypes.bool.isRequired,
  onUpdatePerk: PropTypes.func.isRequired,
}

export default CardPerkAutoEnrollMembers
