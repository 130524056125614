import React, { useEffect, useState } from 'react'
import { Button, TextField, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import currency from 'currency.js'
import PropTypes from 'prop-types'

import { TextMaskDollarBills } from '../common/InputMasks'

const useStyles = makeStyles({
  inputRoot: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  applyButton: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    height: 40,
  },
})

export const Credit = ({
  applyCreditStart,
  availableCredits,
  creditsApplied,
  creditsUsed,
  error,
  isAvailableCreditsLoading,
  isCheckoutCartLoading,
  isCreditApplying,
  suggestedCredits,
  willSubscribe,
}) => {
  const classes = useStyles()
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(suggestedCredits)
  }, [suggestedCredits])

  const handleApplyCredit = event => {
    event.preventDefault()
    applyCreditStart(value, willSubscribe)
  }

  const handleChangeCredit = () => {
    applyCreditStart(0, willSubscribe)
  }

  return (
    <Box display="flex" alignItems="flex-start">
      <TextField
        disabled={creditsApplied}
        label="Credit Amount"
        fullWidth
        variant="outlined"
        color="secondary"
        size="small"
        id="credit"
        name="credit"
        InputProps={{
          classes: {
            root: classes.inputRoot,
          },
          inputComponent: TextMaskDollarBills,
        }}
        onKeyDown={e => e.keyCode === 13 && handleApplyCredit(e)}
        onChange={val => setValue(val)}
        value={creditsApplied ? creditsUsed : value}
        error={Boolean(error)}
        helperText={
          error ||
          (!isAvailableCreditsLoading &&
            availableCredits &&
            `of available: ${currency(availableCredits, {
              formatWithSymbol: true,
            }).format()}`)
        }
      />
      <Button
        variant="contained"
        disableElevation
        color="secondary"
        classes={{ root: classes.applyButton }}
        disabled={isCheckoutCartLoading || isCreditApplying}
        onClick={creditsApplied ? handleChangeCredit : handleApplyCredit}
      >
        {creditsApplied ? 'Remove' : 'Apply'}
      </Button>
    </Box>
  )
}

Credit.propTypes = {
  applyCreditStart: PropTypes.func,
  availableCredits: PropTypes.number,
  creditsApplied: PropTypes.bool,
  creditsUsed: PropTypes.number,
  error: PropTypes.string,
  isAvailableCreditsLoading: PropTypes.bool,
  isCheckoutCartLoading: PropTypes.bool,
  isCreditApplying: PropTypes.bool,
  suggestedCredits: PropTypes.number,
}

export default Credit
