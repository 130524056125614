import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import currency from 'currency.js'
import { isEmpty, isNumber } from 'lodash'
import React from 'react'

const useStyles = makeStyles(theme => ({
  card: {
    padding: '0px',
  },
}))

const transformPerkEnum = programUsageType => {
  if (programUsageType === 'DAILY') {
    return ' DAILY'
  } else if (programUsageType === 'WEEKLY') {
    return ' WEEKLY'
  } else if (programUsageType === 'MONTHLY') {
    return ' MONTHLY'
  }
  return ' '
}

const WalletSummary = ({ credits, gifts, program }) => {
  const classes = useStyles()
  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Box marginBottom={3}>
            <Typography component="h2" variant="h6">
              Balances
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {!isEmpty(program) && (
          <Grid item xs={12} lg>
            <Card className={classes.card} variant="outlined">
              <CardContent>
                <Typography variant="subtitle2">
                  {program?.programName}
                  {' ('}
                  {currency(program?.originalDiscountAmountInPennies / 100, {
                    formatWithSymbol: true,
                  }).format()}
                  {transformPerkEnum(program?.programUsageType)}
                  {')'}
                </Typography>
                <Box
                  alignItems="baseline"
                  display="flex"
                  justifyContent="center"
                  style={{ padding: '16px 0px' }}
                >
                  <Typography display="inline" variant="h2">
                    {currency(program?.discountAmountInPennies / 100, {
                      formatWithSymbol: true,
                    }).format()}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}
        {!isEmpty(gifts) && (
          <Grid item xs={12} lg>
            <Card className={classes.card} variant="outlined">
              <CardContent>
                <Typography variant="subtitle2">Gifts</Typography>
                <Typography align="center" style={{ padding: '16px 0px' }} variant="h2">
                  {currency(gifts?.balance / 100, { formatWithSymbol: true }).format()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
        {isNumber(credits) && (
          <Grid item xs={12} lg>
            <Card className={classes.card} variant="outlined">
              <CardContent>
                <Typography variant="subtitle2">Credits</Typography>
                <Typography align="center" style={{ padding: '16px 0px' }} variant="h2">
                  {currency(credits, { formatWithSymbol: true }).format()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default WalletSummary
