import React from 'react'
import { Box, Typography, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'
import { themeConWeb } from '@app/util/modernThemeConweb'
import currency from 'currency.js'
import { findTotalCredits, CreditsEarnedNotice } from '../../util/reserveUtils'

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: 'black',
    fontWeight: 400,
    padding: '8px',
    '& p, & a': {
      color: '#fff !important',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
}))

export const CreditsEarned = ({
  reserveCreditsEarned,
  reserveDeliveryFee,
  currentRewardsInCents,
  currentRewardsPercentage,
  countOfOrdersOnDropoff,
  deliveryDropoffRewards,
  rewardsEligibleSubtotalInCents,
  rewardsDistributed,
  isPastCutoff,
}) => {
  const classes = useStyles()
  const totalCredits = findTotalCredits(reserveCreditsEarned, currentRewardsInCents / 100.0)
  const numberOfOrdersPerRewardsPercentage = deliveryDropoffRewards
    ? deliveryDropoffRewards.numberOfOrdersPerPercentage
    : null
  const showCreditsEarnedTooltip =
    (currentRewardsInCents != null && currentRewardsPercentage != null) ||
    (reserveCreditsEarned !== null && reserveDeliveryFee > 0)
  return (
    <Box marginBottom={'2em'}>
      <Box marginBottom={'2em'}>
        <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus2, fontWeight: 500 }}>
          Summary of Orders & Credits Earned
        </Typography>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus5, fontWeight: 700 }}>
          {currency(totalCredits, { formatWithSymbol: true }).format()}
        </Typography>
        <Box display={'flex'} alignItems={'center'}>
          <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus2, fontWeight: 500 }}>
            Credits Earned
          </Typography>
          {showCreditsEarnedTooltip && (
            <Tooltip
              interactive
              enterTouchDelay={0}
              disableFocusListener
              title={
                <CreditsEarnedNotice
                  reserveCreditsEarned={reserveCreditsEarned}
                  reserveDeliveryFee={reserveDeliveryFee}
                  currentRewardsInCents={currentRewardsInCents}
                  currentRewardsPercentage={currentRewardsPercentage}
                  countOfOrdersOnDropoff={countOfOrdersOnDropoff}
                  rewardsEligibleSubtotalInCents={rewardsEligibleSubtotalInCents}
                  numberOfOrdersPerRewardsPercentage={numberOfOrdersPerRewardsPercentage}
                  rewardsDistributed={rewardsDistributed}
                  isPastCutoff={isPastCutoff}
                />
              }
              classes={{ tooltip: classes.customTooltip }}
            >
              <InfoIcon
                fontSize="small"
                style={{
                  color: 'ABADB2',
                  marginLeft: '3px',
                  marginBottom: '4px',
                  transform: 'scale(0.8)',
                }}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  )
}
