import React from 'react'

import { Box, Typography, useMediaQuery, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { formatUrl } from '../../util/formatUtils'

import { locationManagerRoute } from '../../routes/routes'
import { updateAndSaveUserLocationStart } from '../../redux/modules/user'
import { DIALOG_OPTIONS } from './savedLocationsUtils'

const useStyles = makeStyles(theme => ({
  menuRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(2),
  },
  removeOrManageLocation: {
    color: theme.palette.error.main,
    fontSize: '14px',
  },
}))

const OptionsCell = ({
  id,
  setDialogToShow,
  handleSelectLocation,
  shouldShowLocationManagerLink,
}) => {
  const classes = useStyles()
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const handleGoToLocationManager = () => {
    goToLocationManager(id)
  }

  const handleRemoveLocation = () => {
    handleSelectLocation(id)
    setDialogToShow(DIALOG_OPTIONS.REMOVE_LOCATION.id)
  }

  const goToLocationManager = locationId => {
    window.location.href = formatUrl(locationManagerRoute.path, { locationId: locationId })
  }

  return (
    <Box
      data-testid="option-cell"
      marginLeft={2}
      marginRight={4}
      display="flex"
      alignItems="center"
      flexDirection={mobileView ? 'column-reverse' : 'row'}
      justifyContent="flex-end"
    >
      <Box marginLeft={2} marginRight={2}>
        {shouldShowLocationManagerLink ? (
          <Link className={classes.menuRow} onClick={handleGoToLocationManager}>
            <Typography className={classes.removeOrManageLocation}>Manage</Typography>
          </Link>
        ) : (
          <Link className={classes.menuRow} onClick={handleRemoveLocation}>
            <Typography className={classes.removeOrManageLocation}>Remove</Typography>
          </Link>
        )}
      </Box>
    </Box>
  )
}

const mapDispatchToProps = {
  updateAndSaveUserLocationStart,
}

export default connect(null, mapDispatchToProps)(OptionsCell)
