import React, { useCallback } from 'react'
import { get } from 'lodash'
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core'

const CateringUtensils = ({ values, handleChange, errors, handleBlur, touched }) => {
  const getError = useCallback(name => get(touched, name) && get(errors, name), [errors, touched])

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} xs={12}>
        <FormControlLabel
          checked={values.User.utensilsRequested}
          name="User.utensilsRequested"
          control={<Checkbox color="primary" />}
          label={'Request Utensils & Napkins'}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.User.utensilsRequested}
          error={!!getError('User.utensilsRequested')}
          helperText={getError('User.utensilsRequested')}
        />
      </Grid>
      {values.User.utensilsRequested && (
        <Grid item sm={3} xs={3}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            id="User.cateringHeadcount"
            label="Headcount"
            name="User.cateringHeadcount"
            onBlur={handleBlur}
            onChange={handleChange}
            error={!!getError('User.cateringHeadcount')}
            helperText={getError('User.cateringHeadcount')}
            color="secondary"
            value={values.User.cateringHeadcount}
            InputLabelProps={{ shrink: true }}
            type={'number'}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default CateringUtensils
