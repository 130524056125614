import React from 'react'
import { Box, Chip, Grid, Typography } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import { format } from 'date-fns'
import { Skeleton } from '@material-ui/lab'
import { formatTenDigitPhoneNumber } from '../../../util/formatUtils'

const NO_VALUE_TEXT = 'Not provided'

const useStyles = makeStyles({
  nameText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

const PersonalInformation = ({
  loading,
  email,
  firstName,
  lastName,
  phone,
  birthday,
  verifiedEmail,
}) => {
  const classes = useStyles()

  if (loading) {
    return <SkeletonLoading />
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="caption">First Name</Typography>
        <Typography variant="body1" className={classes.nameText}>
          {firstName ? firstName : NO_VALUE_TEXT}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption">Last Name</Typography>
        <Typography variant="body1" className={classes.nameText}>
          {lastName ? lastName : NO_VALUE_TEXT}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption">Phone Number</Typography>
        <Typography variant="body1">
          {phone ? formatTenDigitPhoneNumber(phone) : NO_VALUE_TEXT}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption">Birthday</Typography>
        <Typography variant="body1">
          {birthday ? format(birthday, 'MM/DD') : NO_VALUE_TEXT}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">Email</Typography>
        <Box display="flex" flexDirection="row">
          <Typography variant="body1">{email ? email : NO_VALUE_TEXT}</Typography>&nbsp;&nbsp;
          {verifiedEmail && (
            <Chip color="secondary" icon={<CheckCircle />} label="Verified" size="small" />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

const SkeletonLoading = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Skeleton height="50px" />
    </Grid>
    <Grid item xs={12}>
      <Skeleton height="50px" />
    </Grid>
  </Grid>
)

export default PersonalInformation
