import { Box } from '@material-ui/core'
import React from 'react'
import CardTeam from '../common/CardTeam'
import PricingPlan from './PricingPlan'
const CardPreferredStatus = ({ preferredType }) => (
  <CardTeam>
    <Box display={'flex'} alignItems="center" justifyContent={'center'} flexDirection="column">
      <PricingPlan preferredType={preferredType} />
    </Box>
  </CardTeam>
)
export default CardPreferredStatus
