import React, { useEffect } from 'react'
import { format, parse } from 'date-fns'
import { Link } from '@material-ui/core'

import { Skeleton } from '@material-ui/lab'
import { isEmpty } from 'lodash'
import LinkButton from '../../common/LinkButton'
import TableSimple from '../../common/TableSimple'
import { formatCentsToPrettyDollars } from '../perks/giftCardUtils'
import { ROWS_PER_PAGE } from '../../../util/constants'
import { formatOneWordEnumToPascal, isInvoiceCanceled, isInvoicePaid } from './adminUtils'

const TableInvoices = ({
  teamId,
  invoices: { content, number: page, totalElements },
  loadInvoicesStart,
  downloadUsageReportStart,
}) => {
  const handleDownloadUsageReport = (invoiceId, usageReportLocation) => {
    downloadUsageReportStart(invoiceId, usageReportLocation.split('/').pop())
  }

  const handlePageChange = (e, page) => {
    loadInvoicesStart(teamId, page, ROWS_PER_PAGE)
  }

  const columns = [
    {
      id: 'billingPeriodEndDate',
      label: 'Period end',
    },
    {
      id: 'total',
      label: 'Amount',
    },
    {
      id: 'dueDate',
      label: 'Due date',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'usage',
      label: 'Usage',
    },
    {
      id: 'actions',
      label: 'Actions',
    },
  ]

  const rowCells = content?.map(invoice => ({
    id: invoice.id,
    cells: [
      {
        labelId: 'billingPeriodEndDate',
        Component: format(parse(invoice.billingPeriodEndDate), 'M/D/YYYY'),
      },
      {
        labelId: 'total',
        Component: formatCentsToPrettyDollars(invoice.total),
      },
      {
        labelId: 'dueDate',
        Component: invoice.dueDate && format(parse(invoice.dueDate), 'M/D/YYYY'),
      },
      { labelId: 'status', Component: formatOneWordEnumToPascal(invoice.status) },
      {
        labelId: 'usage',
        Component: invoice.usageReportLocation && (
          <LinkButton
            color="primary"
            fontWeight="regular"
            onClick={() => handleDownloadUsageReport(invoice.id, invoice.usageReportLocation)}
          >
            Download
          </LinkButton>
        ),
      },
      {
        labelId: 'actions',
        Component: invoice.paymentUrl && (
          <Link href={invoice.paymentUrl} target="_blank" rel="noopener noreferrer">
            {isInvoicePaid(invoice.status) || isInvoiceCanceled(invoice.status) ? 'View' : 'Pay'}
          </Link>
        ),
      },
    ],
  }))

  return (
    <TableSimple
      columns={columns}
      rowCells={rowCells}
      rowsPerPage={ROWS_PER_PAGE}
      totalElements={totalElements}
      page={page}
      ariaLabel="billing history invoices table"
      noDataText="No invoices found"
      onChangePage={handlePageChange}
    />
  )
}

const LoadableTableInvoices = props => {
  const { teamId, loading, invoices, loadInvoicesStart } = props

  useEffect(() => {
    if (teamId) {
      loadInvoicesStart(teamId, 0, ROWS_PER_PAGE)
    }
  }, [loadInvoicesStart, teamId])

  if (loading) {
    return <Skeleton variant="rect" height="200px" />
  }

  return !isEmpty(invoices) && <TableInvoices {...props} />
}

export default LoadableTableInvoices
