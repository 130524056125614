import React from 'react'
import { Warning } from '@material-ui/icons'

import LayoutMessage from '../layouts/LayoutMessage'
import ButtonBackToHome from '../components/common/ButtonBackToHome'

const PageError = () => {
  return (
    <LayoutMessage
      title="Page Error"
      description="Something went wrong while displaying this page. We are looking into the issue. Please try again later."
      Icon={<Warning />}
      Action={<ButtonBackToHome buttonProps={{ variant: 'contained', color: 'primary' }} />}
    />
  )
}

export default PageError
