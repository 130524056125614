import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { isEmpty } from 'lodash'

export const SubscriptionPromoMessage = ({ subscriptionOffer }) => {
  const loading = isEmpty(subscriptionOffer)
  if (loading) {
    return <SkeletonLoadingSubscriptionText />
  }

  const eligibleForTrial = !isEmpty(subscriptionOffer?.offer?.trialInformation)
  const bodyText = `Get $0 service fees on every order when you subscribe for $${subscriptionOffer?.offer?.price}/month.`
  const bodyTextAlt = eligibleForTrial
    ? 'Try it free for 30 days -- Cancel any time.'
    : 'Cancel any time.'
  return (
    <Box marginBottom={3}>
      <Grid container justifyContent="center">
        <Grid item>
          <Typography variant="body1">
            {bodyText} {bodyTextAlt}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

const SkeletonLoadingSubscriptionText = () => (
  <Box marginTop={3}>
    <Skeleton height={48} width={480} />
    <Skeleton height={48} width={320} />
  </Box>
)

export default SubscriptionPromoMessage
