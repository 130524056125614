import React from 'react'
import { Box } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import styled from 'styled-components'
import { themeConWeb } from '../../util/modernThemeConweb'

const Component = styled.div`
  margin-bottom: 20px;
  & .-delivery-details {
    margin-left: 20px;
  }
  p {
    font-style: normal;
    color: ${themeConWeb.color.greyShade3};
    font-size: ${themeConWeb.fontSizes.base};
    margin-bottom: 5px;
    margin-top: 2px;
    line-height: 14px;
  }
`

const PickupInstructions = ({ locationPickupInstructions }) => {
  return (
    <Component>
      <Box display="flex" justifyContent="left" alignItems="center" paddingBottom={2}>
        <Box>
          <LocationOnIcon />
        </Box>
        <Box className="-delivery-details">
          {locationPickupInstructions}
          <p>Pickup Instructions</p>
        </Box>
      </Box>
    </Component>
  )
}

export default PickupInstructions
