import { api } from '../api/api'
import { pascalcaseKeys } from '../util/keys'

export const API_MONO = '/api-monolith'

export const getProgramForUser = date =>
  api.get(`/api/v2/accounts/programs/best-available?date=${date}`)

export const submitCoupon = (dropoffId, couponCode) =>
  api.post(
    `${API_MONO}/cart/dropoff/${dropoffId}/coupon`,
    pascalcaseKeys({
      couponCode,
    }),
  )

export const submitCredit = (dropoffId, creditAmount) =>
  api.post(
    `${API_MONO}/cart/dropoff/${dropoffId}/credit`,
    pascalcaseKeys({
      creditAmount,
    }),
  )

export const submitGift = (dropoffId, storedCurrencyAmount) =>
  api.post(
    `${API_MONO}/cart/dropoff/${dropoffId}/stored-currency`,
    pascalcaseKeys({
      storedCurrencyAmount,
    }),
  )

export const createOrUpdateCateringDeliveryInstructionsApi = (
  deliveryDropoffId,
  dropoffInstructions,
) => {
  api.post(
    `${API_MONO}/checkout/catering/instructions`,
    pascalcaseKeys({
      deliveryDropoffId,
      dropoffInstructions,
    }),
  )
}
