import React from 'react'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { push } from 'connected-react-router'

import { selectQuery } from '../../../redux/selectors/routerSelectors'
import people from '../../../assets/people.svg'
import giftBox from '../../../assets/gift-box.svg'
import DialogValueProps from './DialogValueProps'

const DialogTeamValueProps = ({ query, push }) => {
  const open = query?.createTeam

  const handleGetStarted = () => {
    push({
      search: queryString.stringify({
        createTeam: undefined,
        joinTeam: 'true',
      }),
    })
  }

  const handleClose = () => {
    push({ search: queryString.stringify({ createTeam: undefined }) })
  }

  if (!open) {
    return null
  }

  return (
    <DialogValueProps
      title="Join your team on Foodsby"
      description="Lunch is something you look forward to on a busy day at the office. Make it even better by joining your team."
      valueProps={[
        {
          id: 1,
          image: people,
          primaryText: 'Discounts & Offers',
          secondaryText:
            '30% off unlimited Foodsby Unlimited subscriptions, $0 service fee on group orders, & more!',
        },
        {
          id: 2,
          image: giftBox,
          primaryText: 'Team Perks',
          secondaryText:
            'Cover some or all of the cost of lunch for your team members and enjoy lunch together!',
        },
      ]}
      action={
        <Button variant="contained" color="primary" onClick={handleGetStarted}>
          Get started
        </Button>
      }
      onClose={handleClose}
    />
  )
}

const mapStateToProps = state => {
  const query = selectQuery(state)

  return {
    query,
  }
}

const mapDispatchToProps = {
  push,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogTeamValueProps)
