import { Box, Paper, Typography } from '@material-ui/core'
import React from 'react'

const CardLocation = ({ children }) => {
  return (
    <Box mb={2}>
      <Paper variant="outlined" elevation={0}>
        <Box padding={4}>{children}</Box>
      </Paper>
    </Box>
  )
}

export const CardLocationContent = ({ children }) => {
  return <Box marginTop={3}>{children}</Box>
}

export const CardLocationTitle = ({ children, ...rest }) => {
  return (
    <Typography variant="h6" {...rest}>
      {children}
    </Typography>
  )
}

export default CardLocation
