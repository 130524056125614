import { InputError } from '@foodsby/nutrient'
import { Box, Button, Divider } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { selectProfile } from '../../redux/modules/selectors'

import { normalizeProfileImageErrors } from '../../util/errorUtils'
import { Segment } from '../common/Layout'
import PanelHeader from '../common/PanelHeader'
import {
  updatePersonalInfoStart,
  updateProfilePictureStart,
  updatePasswordStart,
  setPersonalInfoEditMode,
  setPasswordEditMode,
} from '../../redux/modules/profile'
import DialogChangeEmail from './personal-information/DialogChangeEmail'
import DialogChangePassword from './personal-information/DialogChangePassword'
import EditPersonalInformationForm from './personal-information/EditPersonalInformationForm'
import { ProfileImage } from './ProfilePicture'
import PersonalInformation from './personal-information/PersonalInformation'
import {
  formatBirthdayToDateTime,
  formatBirthdayToFormValue,
} from './personal-information/birthday'

export const PersonalInformationPanel = ({
  profile: { avatarId, firstName, lastName, phone, birthday, username, verifiedEmail },
  isWebpSupported,
  isUpdatingPassword,
  isUpdatingPersonalInfo,
  isLoadingProfile,
  errorUpdatingPassword,
  errorUpdatingPersonalInfo,
  successUpdatingPassword,
  updateProfilePictureError,
  updateProfilePictureLoading,
  passwordEditMode,
  personalInfoEditMode,
  updatePersonalInfoStart,
  updateProfilePictureStart,
  updatePasswordStart,
  setPasswordEditMode,
  setPersonalInfoEditMode,
}) => {
  const [showEmailChangeDialog, setShowEmailChangeDialog] = useState(false)
  const [submitValues, setSubmitValues] = useState(null)

  const handleSubmit = values => {
    const birthday = formatBirthdayToDateTime(values.birthday)

    if (values.email !== username) {
      // Show the dialog for confirmation
      setShowEmailChangeDialog(true)
      // Set the submit values as a state just so we can access them upon the password confirmation in DialogChangeEmail
      setSubmitValues({ ...values, birthday })
    } else {
      updatePersonalInfoStart({ ...values, birthday })
    }
  }

  const handleSubmitAndEmailChange = values => {
    updatePersonalInfoStart(submitValues, values.password)
  }

  useEffect(() => {
    if (!errorUpdatingPersonalInfo && !personalInfoEditMode) {
      setShowEmailChangeDialog(false)
    }
  }, [errorUpdatingPersonalInfo, personalInfoEditMode])

  return (
    <Segment>
      <PanelHeader
        headerRight={
          !personalInfoEditMode && (
            <Button color="primary" onClick={() => setPersonalInfoEditMode(true)} size="small">
              Edit Profile
            </Button>
          )
        }
        title="Personal Information"
      />

      <div className="row">
        <div className="profile-image-container col-sm-5 center-xs col-xs-12">
          <ProfileImage
            avatarId={avatarId}
            isWebpSupported={isWebpSupported}
            name={firstName && lastName && `${firstName} ${lastName}`}
            updateProfilePictureLoading={updateProfilePictureLoading}
            updateProfilePictureStart={updateProfilePictureStart}
          />
          {updateProfilePictureError && (
            <InputError>
              {normalizeProfileImageErrors(updateProfilePictureError.message || '')
                .slice(0, 1)
                .map((error, i) => (
                  <div key={i}>{error}</div>
                ))}
            </InputError>
          )}
        </div>
        <div className="col-xs-12 col-sm-7">
          {personalInfoEditMode ? (
            <EditPersonalInformationForm
              initialValues={{
                email: username,
                firstName: firstName ?? '',
                lastName: lastName ?? '',
                phone: phone ?? '',
                birthday: formatBirthdayToFormValue(birthday),
              }}
              onSubmit={handleSubmit}
              onCancel={() => setPersonalInfoEditMode(false)}
              submitting={isUpdatingPersonalInfo}
              error={errorUpdatingPersonalInfo}
            />
          ) : (
            <PersonalInformation
              loading={isLoadingProfile}
              email={username}
              firstName={firstName}
              lastName={lastName}
              phone={phone}
              birthday={birthday}
              verifiedEmail={verifiedEmail}
            />
          )}
          <Box marginY={3}>
            <Divider />
          </Box>
          <Button color="primary" onClick={() => setPasswordEditMode(true)} size="small">
            Change Password
          </Button>
          {passwordEditMode && (
            <DialogChangePassword
              onClose={() => setPasswordEditMode(false)}
              onSubmit={updatePasswordStart}
              submitting={isUpdatingPassword}
              error={errorUpdatingPassword}
            />
          )}
          {successUpdatingPassword && (
            <Alert severity="success">Your password has been successfully changed.</Alert>
          )}
        </div>
      </div>
      {showEmailChangeDialog && (
        <DialogChangeEmail
          onClose={() => setShowEmailChangeDialog(false)}
          onSubmit={handleSubmitAndEmailChange}
          submitting={isUpdatingPersonalInfo}
          errorUpdatingPersonalInfo={errorUpdatingPersonalInfo}
          visible={showEmailChangeDialog}
        />
      )}
    </Segment>
  )
}

PersonalInformationPanel.defaultProps = {
  profile: {},
}

const mapStateToProps = state => {
  const {
    changePasswordFailed,
    isUpdatingPassword,
    isLoadingProfile,
    errorUpdatingPersonalInfo,
    errorUpdatingPassword,
    updateProfilePictureError,
    updateProfilePictureLoading,
    isUpdatingPersonalInfo,
    successUpdatingPassword,
    passwordEditMode,
    personalInfoEditMode,
  } = state.profile
  const { isWebpSupported } = state.browser
  const profile = selectProfile(state)

  return {
    changePasswordFailed,
    isUpdatingPassword,
    isLoadingProfile,
    isUpdatingPersonalInfo,
    isWebpSupported,
    profile,
    errorUpdatingPersonalInfo,
    errorUpdatingPassword,
    updateProfilePictureError,
    updateProfilePictureLoading,
    successUpdatingPassword,
    passwordEditMode,
    personalInfoEditMode,
  }
}

const mapDispatchToProps = {
  updatePersonalInfoStart,
  updateProfilePictureStart,
  updatePasswordStart,
  setPersonalInfoEditMode,
  setPasswordEditMode,
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformationPanel)
