import React from 'react'

import DialogLoginOrJoin from '../login/DialogLoginOrJoin'

/*
 * This component force opens the signup/login dialog if the user is not authenticated
 */
const AuthedComponent = ({ children, isAuthenticated, locationId }) => {
  return (
    <>
      {children}
      <DialogLoginOrJoin locationId={locationId} forceOpen={!isAuthenticated} />
    </>
  )
}

export default AuthedComponent
