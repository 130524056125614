import React from 'react'
import { Box } from '@material-ui/core'

import { themeConWeb } from '@app/util/modernThemeConweb'
import LinkButton from '../../common/LinkButton'

const ToolboxAction = ({ Icon, label, onClick }) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <LinkButton
        onClick={onClick}
        color={'white'}
        Icon={Icon}
        iconColor={themeConWeb.color.greyShade3}
        underline={false}
      >
        {label}
      </LinkButton>
    </Box>
  )
}

export default ToolboxAction
