import React, { useEffect } from 'react'
import { Button, DialogActions, DialogContent, RadioGroup, Typography } from '@material-ui/core'
import { Form } from 'formik'
import { Lock } from '@material-ui/icons'

import RadioCardOption from '../perks/RadioCardOption'
import ButtonSubmit from '../../common/ButtonSubmit'
import { getOnlyPotentialTeam, POTENTIAL_TEAM_TYPES } from '../teamUtils'

const FormJoinTeam = ({
  potentialTeams,
  values,
  errors,
  classes,
  submitting,
  setFieldValue,
  onChange,
  onCreateNewTeam,
}) => {
  useEffect(() => {
    const onlyPotentialTeam = getOnlyPotentialTeam(potentialTeams)
    if (onlyPotentialTeam) {
      const id =
        onlyPotentialTeam.type === POTENTIAL_TEAM_TYPES.COMPANY
          ? onlyPotentialTeam.companyId
          : onlyPotentialTeam.accountId
      onChange(id, onlyPotentialTeam.type, onlyPotentialTeam.name, setFieldValue)
    }
  }, [onChange, potentialTeams, setFieldValue])

  return (
    <Form>
      <DialogContent>
        <RadioGroup aria-label="team to join" name="id" value={values.id}>
          {potentialTeams.companiesWithoutTeam.map(company => (
            <RadioCardOption
              dense
              key={company.companyId}
              value={company.companyId}
              checked={values.id === company.companyId}
              onChange={e =>
                onChange(e.target.value, POTENTIAL_TEAM_TYPES.COMPANY, company.name, setFieldValue)
              }
            >
              <span className={classes.radioOptionLabel}>{company.name}</span>
            </RadioCardOption>
          ))}
          {potentialTeams.joinableTeams.map(team => (
            <RadioCardOption
              dense
              key={team.accountId}
              value={team.accountId}
              checked={values.id === team.accountId}
              onChange={e =>
                onChange(e.target.value, POTENTIAL_TEAM_TYPES.TEAM, team.name, setFieldValue)
              }
            >
              <span className={classes.radioOptionLabel}>{team.name}</span>
            </RadioCardOption>
          ))}
          {potentialTeams.notJoinableTeams.map(team => (
            <RadioCardOption
              dense
              key={team.accountId}
              disabled
              disabledReason="This team is not joinable."
            >
              <span className={classes.radioOptionLabelDisabled}>
                {team.name} <Lock />
              </span>
            </RadioCardOption>
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onCreateNewTeam} className={classes.createTeamButton}>
          Create new team
        </Button>
        <ButtonSubmit variant="contained" color="primary" submitting={submitting}>
          Join team
        </ButtonSubmit>
      </DialogActions>
      {errors.id && (
        <Typography align="center" color="error">
          {errors.id}
        </Typography>
      )}
    </Form>
  )
}

export default FormJoinTeam
