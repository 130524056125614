import React from 'react'
import { connect } from 'react-redux'

import { Avatar } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import PropTypes from 'prop-types'

const StyledAvatar = withStyles({
  root: {
    width: ({ width }) => width,
    height: ({ height }) => height,
  },
})(Avatar)

const AvatarMember = ({ member, isWebpSupported, height, width }) => {
  return (
    <StyledAvatar
      data-testid="avatar-member"
      key={member.userId}
      height={height}
      width={width}
      src={
        member.avatarId &&
        `${process.env.REACT_APP_IMAGE_LAMBDA}/profiles/${member.avatarId}.${
          isWebpSupported ? 'webp' : 'jpeg'
        }?height=50&width=50`
      }
      variant="circular"
    />
  )
}

const mapStateToProps = state => {
  return { isWebpSupported: state.browser?.isWebpSupported }
}

const mapDispatchToProps = {}

AvatarMember.defaultProps = {
  height: '40px',
  width: '40px',
}

AvatarMember.propTypes = {
  member: PropTypes.shape({
    userId: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    status: PropTypes.string,
    confirmedAt: PropTypes.string,
    avatarId: PropTypes.string,
  }),
  height: PropTypes.string,
  width: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(AvatarMember)
