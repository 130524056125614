import { Button, H5, P } from '@foodsby/nutrient'
import { themeConWeb } from '@app/util/modernThemeConweb'
import currency from 'currency.js'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Typography, Button as MuiButton } from '@material-ui/core'

import { useMyReferralWithEnabledDetails } from '../../hooks/referral'
import { copyToClipboard } from '../../util/formatUtils'
import { pushAnalyticsEvent } from '../../util/gtmUtils'
import { BRAZE_CUSTOM_EVENTS, publishBrazeEvent } from '../../util/braze'

export const CopyReferral = ({
  buttonText = 'Copy code',
  labelText = 'Refer and Earn',
  referralAndEnabledDetails,
  ...rest
}) => {
  const [copied, setCopied] = useState(false)
  const [pushedEvent, setPushedEvent] = useState(false)
  const clipboardText = referralAndEnabledDetails?.dailyLinkEnabledDetails
    ? `Have you ordered lunch? Order with my link and you will earn credits on today's order: ${referralAndEnabledDetails?.referral?.shareUrl}`
    : `Have you tried Foodsby? Sign up with my link and you will get ${currency(
        referralAndEnabledDetails?.referralEnabledDetails?.rewardAmountPennies / 100,
        { formatWithSymbol: true },
      ).format()} off your first order: ${referralAndEnabledDetails?.referral?.shareUrl}`

  useEffect(() => {
    if (!pushedEvent) {
      if (referralAndEnabledDetails?.dailyLinkEnabledDetails) {
        setPushedEvent(true)
        pushAnalyticsEvent('Referral', 'View Daily Link Component')
      } else if (referralAndEnabledDetails?.referralEnabledDetails) {
        setPushedEvent(true)
        pushAnalyticsEvent('Referral', 'View Referral Component')
      }
    }
  }, [referralAndEnabledDetails]) // eslint-disable-line

  const handleClick = () => {
    setCopied(true)
    copyToClipboard(clipboardText)
    pushAnalyticsEvent(
      'Referral',
      'Copy Referral',
      referralAndEnabledDetails?.dailyLinkEnabledDetails ? 'Daily Link' : 'Referral',
    )
    publishBrazeEvent(BRAZE_CUSTOM_EVENTS.REFERRAL_COPIED_LINK)
  }

  return (
    <div {...rest}>
      <div className="referral-container referral center-xs">
        <div className="row share-container">
          <span className="link-visible referral-link">
            {referralAndEnabledDetails?.referral?.shareUrl}
          </span>
        </div>
        <div>
          <Button
            className="copy-referral"
            disabled={!referralAndEnabledDetails?.referral?.shareUrl}
            onClick={handleClick}
            style={{
              textTransform: 'uppercase',
              color: 'red',
              backgroundColor: 'white',
              border: 'none',
            }}
          >
            {buttonText}
          </Button>
        </div>
        {copied && (
          <p className="copied-text">
            Copied. Now go share via email, text, Slack, Teams, and more.
          </p>
        )}
      </div>
    </div>
  )
}

export const ConfirmationCopyReferral = ({ referralAndEnabledDetails, storeName, ...rest }) => {
  const [copied, setCopied] = useState(false)
  const [pushedEvent, setPushedEvent] = useState(false)
  const clipboardText = `Let's both earn rewards—order from ${storeName} on Foodsby today! ${referralAndEnabledDetails?.referral?.shareUrl}`

  useEffect(() => {
    if (!pushedEvent) {
      if (referralAndEnabledDetails?.dailyLinkEnabledDetails) {
        setPushedEvent(true)
        pushAnalyticsEvent('Referral', 'View Daily Link Component')
      } else if (referralAndEnabledDetails?.referralEnabledDetails) {
        setPushedEvent(true)
        pushAnalyticsEvent('Referral', 'View Referral Component')
      }
    }
  }, [referralAndEnabledDetails])

  const handleClick = () => {
    setCopied(true)
    copyToClipboard(clipboardText)
    pushAnalyticsEvent(
      'Referral',
      'Copy Referral',
      referralAndEnabledDetails?.dailyLinkEnabledDetails ? 'Daily Link' : 'Referral',
    )
    publishBrazeEvent(BRAZE_CUSTOM_EVENTS.REFERRAL_COPIED_LINK)
  }

  return (
    <Box
      {...rest}
      style={{
        width: '70%',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        style={{
          border: '2px solid black',
          borderRadius: '3px',
          textAlign: 'center',
          padding: '15px 40px',
        }}
      >
        <Typography style={{ fontSize: themeConWeb.fontSizes.basePlus1 }}>
          {referralAndEnabledDetails?.referral?.shareUrl}
        </Typography>
      </Box>
      <Box marginTop={'1em'} marginBottom={'-1em'}>
        <MuiButton
          disabled={!referralAndEnabledDetails?.referral?.shareUrl}
          onClick={handleClick}
          style={{
            textTransform: 'uppercase',
            backgroundColor: `${themeConWeb.color.twoShade2}`,
            color: 'white',
            border: 'none',
            alignSelf: 'center',
          }}
        >
          Copy Link & Share
        </MuiButton>
      </Box>
      {copied && (
        <p style={{ paddingTop: '25px', color: `${themeConWeb.color.success}` }}>Link copied!</p>
      )}
    </Box>
  )
}

export const Referral = ({ showImage = true, showTitle = true }) => {
  const { loading, referralAndEnabledDetails } = useMyReferralWithEnabledDetails()

  const titleText = referralAndEnabledDetails?.dailyLinkEnabledDetails
    ? 'Share and earn!'
    : 'Refer and earn!'
  const bodyText = referralAndEnabledDetails?.dailyLinkEnabledDetails
    ? 'Earn credits each time someone orders with your link. Share it with your friends, your coworkers, or neighbors in your community.'
    : `Invite your friends to Foodsby and you’ll both get a ${currency(
        referralAndEnabledDetails?.referralEnabledDetails?.rewardAmountPennies / 100,
        { formatWithSymbol: true },
      ).format()} credit when they
    place their first order and it's delivered.`

  return (
    <ReferralComponent>
      {referralAndEnabledDetails?.dailyLinkEnabledDetails && showImage && (
        <div className="-imgContainer">
          <img
            alt=""
            src="https://images.foodsby.com/api/resize/conweb/people_share_1.png.png?height=150&width=150&fit=contain&background=transparent"
            width="150"
          />
        </div>
      )}
      <div className="-copyContainer">
        {!loading && (
          <>
            {showTitle && <H5 className="-title">{titleText}</H5>}
            <P className="-body">{bodyText}</P>
            <CopyReferral
              buttonText="Copy Link"
              className="--confirmation"
              labelText=""
              referralAndEnabledDetails={referralAndEnabledDetails}
            />
          </>
        )}
      </div>
    </ReferralComponent>
  )
}

export const ReferralLink = ({ referralAndEnabledDetails }) => {
  const [copied, setCopied] = useState(false)
  const [pushedEvent, setPushedEvent] = useState(false)
  const clipboardText = referralAndEnabledDetails?.dailyLinkEnabledDetails
    ? `Have you ordered lunch? Order with my link and you will earn credits on today's order: ${referralAndEnabledDetails?.referral?.shareUrl}`
    : `Have you tried Foodsby? Sign up with my link and you will get ${currency(
        referralAndEnabledDetails?.referralEnabledDetails?.rewardAmountPennies / 100,
        { formatWithSymbol: true },
      ).format()} off your first order: ${referralAndEnabledDetails?.referral?.shareUrl}`

  useEffect(() => {
    if (!pushedEvent) {
      if (referralAndEnabledDetails?.dailyLinkEnabledDetails) {
        setPushedEvent(true)
        pushAnalyticsEvent('Referral', 'View Daily Link Component')
      } else if (referralAndEnabledDetails?.referralEnabledDetails) {
        setPushedEvent(true)
        pushAnalyticsEvent('Referral', 'View Referral Component')
      }
    }
  }, [referralAndEnabledDetails]) // eslint-disable-line

  const handleClick = () => {
    setCopied(true)
    copyToClipboard(clipboardText)
    pushAnalyticsEvent(
      'Referral',
      'Copy Referral',
      referralAndEnabledDetails?.dailyLinkEnabledDetails ? 'Daily Link' : 'Referral',
    )
    publishBrazeEvent(BRAZE_CUSTOM_EVENTS.REFERRAL_COPIED_LINK)
  }

  return (
    <>
      <LinkDiv>
        <span style={{ paddingLeft: '5px' }}>{referralAndEnabledDetails?.referral?.shareUrl} </span>
        <CopyButton disabled={!referralAndEnabledDetails?.referral?.shareUrl} onClick={handleClick}>
          Copy
        </CopyButton>
      </LinkDiv>
      {copied && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ color: 'grey', paddingTop: '5px' }}>Copied!</p>
        </div>
      )}
    </>
  )
}

const LinkDiv = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 8px 12px;
  &:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
  &:focus-within {
    border-color: #3f51b5; /* Replace with your primary color */
  }
`

const CopyButton = styled(MuiButton)`
  && {
    text-transform: uppercase;
    color: red;
    background-color: white;
    border: none;
    padding: 0;
    margin-left: 10px;
    min-width: auto;
  }
`

const ReferralComponent = styled.div`
  display: flex;

  & .-imgContainer {
    padding-right: calc(${themeConWeb.baseUnit} * 8);
  }

  & .-title {
    margin-bottom: 8px;
  }

  & .-body {
    font-size: ${themeConWeb.fontSizes.basePlus1};
    margin-bottom: 8px;
    line-height: 1.5;
  }

  & .--confirmation {
    & .copy-referral {
      font-family: 'Roboto', sans-serif;
      background-color: white;
      color: ${themeConWeb.color.white};
      font-size: ${themeConWeb.fontSizes.baseMinus1};
      border-radius: 0 4px 4px 0;
      letter-spacing: 0px;
      margin: 0;
      min-height: auto;
      height: 36px;
      max-width: 100px;
      white-space: nowrap;

      &:hover {
        background-color: ${themeConWeb.color.brandPrimaryShade1};
        color: ${themeConWeb.color.white};
      }
    }

    & .copied-text {
      color: ${themeConWeb.color.success};
      border-bottom: 1px solid ${themeConWeb.color.success};
    }
  }

  @media ${themeConWeb.small} {
    flex-wrap: wrap;

    & .-imgContainer {
      padding: 0;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      flex-basis: 100%;
    }
  }
`
