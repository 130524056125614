import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import Footer from '../components/common/Footer'
import Header from '../components/common/header/Header'
import { Sidebar } from '../components/common/Layout'
import { selectProfile, selectUserLocationId } from '../redux/modules/selectors'
import { logoutStart } from '../redux/modules/user'
import { pushGTMUserInfo } from '../util/gtmUtils'
import ErrorBoundary from '../components/common/ErrorBoundary'

export class LayoutSidebar extends Component {
  componentDidMount() {
    pushGTMUserInfo()
  }
  componentDidUpdate() {}

  render() {
    const { children, isAuthenticated, nav, sidebarRight } = this.props
    let { locationId } = this.props
    if (locationId == null || locationId === '') {
      locationId = Number(this.props.match.params.locationId || 0)
    }
    return (
      <ErrorBoundary>
        <div className="main">
          <Header />

          <div className="content">
            {/* will only render if passed in on props */}
            {renderNav({
              NavContent: nav,
              locationId,
              match: this.props.match,
            })}

            {/* children should be wrapped in <Article> if using sidebarRight */}
            {children}

            {/* will only render if passed in on props */}
            {renderSidebar({
              SidebarContents: sidebarRight,
              locationId,
              match: this.props.match,
            })}

            <aside className="content-cards-parent" />
          </div>

          <Footer isAuthenticated={isAuthenticated} />
        </div>
      </ErrorBoundary>
    )
  }
}

const renderNav = ({ NavContent, ...rest }) => {
  if (!NavContent) {
    return null
  }

  return React.isValidElement(NavContent) ? NavContent : <NavContent {...rest} />
}

/**
 * Will detect if multiple components were passed in and render accordingly.
 * @param {any} SidebarContents 0 to many components to stuff in the sidebar
 */
const renderSidebar = ({ SidebarContents, ...rest }) => {
  let children = null

  // if null or empty array is passed in, don't render sidebar
  if (isEmpty(SidebarContents)) {
    return children
  } else if (SidebarContents instanceof Array) {
    children = SidebarContents.map((Content, i) => <Content {...rest} key={i} />)
  } else {
    children = <SidebarContents {...rest} />
  }
  return <Sidebar>{children}</Sidebar>
}

const mapStateToProps = state => {
  const { currentUser, isAuthenticated, isCurrentUserLoading } = state.user
  let locationId = selectUserLocationId(state)
  const profile = selectProfile(state)
  const { requestCount } = state.friends

  return {
    currentUser,
    isAuthenticated,
    isCurrentUserLoading,
    locationId,
    profile,
    requestCount,
  }
}

const mapDispatchToProps = {
  logoutStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSidebar)
