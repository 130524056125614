import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'

/*
    Use this component if you need to display an important error message (e.g. checkout failure)
    that should block the app until the user acknowledges the message
*/
const DialogError = ({ message, onClose, title, visible }) => {
  return (
    <Dialog onClose={onClose} open={visible}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Dismiss</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogError
