import { isEmpty } from 'lodash'
import { useState } from 'react'

export const useHubspotSubmitLead = hubspotUrl => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(undefined)

  const submitForm = values => {
    console.debug('Submitting to hubspot', values)
    if (!isEmpty(values) && !loading) {
      setError(undefined)
      setLoading(true)
      setSuccess(false)

      const formData = new FormData()
      Object.keys(values).forEach(value => {
        formData.set(value, values[value])
      })

      const httpRequest = createXMLHttpRequest()
      httpRequest.open('POST', hubspotUrl)
      httpRequest.send(formData)
    }
  }

  const createXMLHttpRequest = () => {
    const xhr = new XMLHttpRequest()
    xhr.timeout = 30000

    const onFormComplete = errorMessage => {
      setLoading(false)
      setSuccess(!errorMessage)
      setError(errorMessage)
    }

    xhr.onload = e => {
      console.debug('Success')
      const response = JSON.parse(e.target.response)
      onFormComplete(
        response && response.status === 'success' ? undefined : 'Error submitting request',
      )
    }
    xhr.onerror = () => {
      onFormComplete('Error submitting request')
    }
    xhr.ontimeout = () => {
      onFormComplete('Request timed out, check your internet and try again')
    }
    return xhr
  }

  return { loading, submitForm, success, error }
}
