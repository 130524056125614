import React from 'react'
import { GoogleMap, InfoWindow, Marker, withGoogleMap } from 'react-google-maps'
import { compose, withProps } from 'recompose'

import Action from '../common/Action'
import { MAPS_SCRIPT_URL } from './LocationSearchBox'

const smoothScroll = elem => {
  const container = document.getElementById('searchLocationsList')
  const elemPosition = container.offsetTop
  container.scrollTop = elem.offsetTop - elemPosition
}

const LocationMapDetails = ({ goToLocation, location, onClickOverride }) => (
  <div
    className="searchLocationInfoWindow"
    onClick={() =>
      onClickOverride ? onClickOverride(location) : goToLocation(location.deliveryLocationId)
    }
    role="button"
  >
    <div className="searchLocationName">{location.locationName}</div>

    {location.deliveryLine1 && (
      <>
        <br />
        {location.deliveryLine1}
      </>
    )}
    {location.lastLine && (
      <>
        <br />
        {location.lastLine}
      </>
    )}
    {(location.deliveryLine1 || location.lastLine) && (
      <>
        <br />
        <br />
      </>
    )}
    <Action
      onClick={() =>
        onClickOverride ? onClickOverride(location) : goToLocation(location.deliveryLocationId)
      }
    >
      Select this location
    </Action>
  </div>
)

const GoogleMapComponent = compose(
  withProps({
    containerElement: <div style={{ minHeight: `340px`, height: '100%' }} />,
    googleMapURL: MAPS_SCRIPT_URL,
    loadingElement: (
      <div style={{ minHeight: `340px`, height: '100%' }}>Google Maps is loading...</div>
    ),
    mapElement: <div style={{ minHeight: `340px`, height: '100%' }} />,
  }),
  withGoogleMap,
)(props => {
  const { goToLocation, isOpenId, lat, lng, locations, onClickOverride, onToggleOpen } = props

  // if no lat/lng is passed in, use a default one so that the map doesn't look broken.
  const latLng =
    lat && lng ? { lat: Number(lat), lng: Number(lng) } : { lat: 44.9756871, lng: -93.2701042 }

  const handleToggle = (id, scrollTo, selected) => {
    if (scrollTo) {
      const elem = document.getElementById(`searchLocation-${id}`)
      elem.classList.remove('searchLocationsList-flash')
      if (!selected) {
        onToggleOpen(id)
      }
      smoothScroll(elem)
      elem.classList.add('searchLocationsList-flash')
    } else {
      onToggleOpen(id)
    }
  }

  return (
    <GoogleMap
      center={latLng}
      options={{
        controlSize: 25,
        mapTypeControlOptions: {
          mapTypeIds: ['roadmap'],
        },
        maxZoom: 18,
        minZoom: 13,
        streetViewControl: false,
        styles: [
          {
            elementType: 'labels.text',
            featureType: 'poi',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.business',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            elementType: 'labels.icon',
            featureType: 'road',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
        ],
      }}
      zoom={14}
    >
      {locations &&
        locations.length > 0 &&
        locations.map((loc, i) => {
          const selected = isOpenId === loc.deliveryLocationId
          return (
            <Marker
              icon={`https://maps.google.com/mapfiles/ms/icons/${
                selected ? 'green' : 'red'
              }-dot.png`}
              key={i}
              onClick={() => {
                handleToggle(loc.deliveryLocationId, true, selected)
              }}
              onMouseOver={() => {
                !selected && handleToggle(loc.deliveryLocationId, false)
              }}
              position={{ lat: loc.latitude, lng: loc.longitude }}
            >
              {selected && (
                <InfoWindow
                  defaultOptions={{ disableAutoPan: true }}
                  onCloseClick={() => handleToggle(loc.deliveryLocationId)}
                >
                  <LocationMapDetails
                    goToLocation={goToLocation}
                    location={loc}
                    onClickOverride={onClickOverride}
                  />
                </InfoWindow>
              )}
            </Marker>
          )
        })}
    </GoogleMap>
  )
})

export default GoogleMapComponent
