import React from 'react'
import { IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}))

const ButtonDialogClose = ({ onClick }) => {
  const classes = useStyles()
  return (
    <IconButton
      aria-label="close"
      className={classes.closeButton}
      onClick={onClick}
      data-testid="close"
    >
      <Close />
    </IconButton>
  )
}

export default ButtonDialogClose
