import { Box, useMediaQuery } from '@material-ui/core'
import React, { Component } from 'react'
import scriptLoader from 'react-async-script-loader'
import { connect } from 'react-redux'

import { format, subDays } from 'date-fns'
import { MAPS_SCRIPT_URL } from '../components/location-search/LocationSearchBox'
import { loadDeliveryScheduleStart, loadDeliveryRewardsStart } from '../redux/modules/delivery'
import { loadDeliveryRewardsTiersStart } from '../redux/modules/deliveryDropoffRewards'
import { loadCateringStoresStart } from '../redux/modules/cateringStores'
import { loadLocationsUserManagesStart } from '../redux/modules/locationManager'
import { loadReserveStoresStart } from '../redux/modules/reserveStores'
import { addFavoritesForMenusStart, getFavoritesForMenusCount } from '../redux/modules/favorite'
import { loadFutureOrdersStart } from '../redux/modules/orderDetail'
import { selectCurrentLocationWithAddress } from '../redux/modules/selectors'
import { pushAnalyticsEvent } from '../util/gtmUtils'
import { selectDeliveries } from '../redux/selectors/deliverySelectors'
import { loadOfficesStart } from '../redux/modules/offices'
import { selectCateringStores } from '../redux/selectors/cateringStoreSelectors'
import { selectLocationStatus } from '../redux/selectors/locationSelectors'
import { LocationStatusType } from '../util/constants'
import { UnqualifiedLocationMessage } from '../components/home/LocationStatusMessages'
import { getFriendsOrdersStart } from '../redux/modules/friends'
import LayoutInnerHomePage from '../layouts/LayoutInnerHomePage'
import MobileWidgetSkeleton from '../components/home/MobileWidgetSkeleton'
import FeedbackLink from '../components/home/FeedbackLink'
import { selectDateStart } from '../redux/modules/delivery'
import { isSelectedDeliveryDateActive } from '../redux/selectors/deliverySelectors'
import { selectOfficeAccounts } from '../redux/selectors/accountSelectors'
import HomeDialogs from '../components/home/HomeDialogs'
import HomeSnackbars from '../components/home/HomeSnackbars'
import HomeUnlockPreferredPricing from '../components/home/HomeUnlockPreferredPricing'
import HomeSidebar from '../components/home/HomeSidebar'
import DailyDeliverySection from '../components/home/DailyDeliverySection'
import CateringDeliverySection from '../components/home/CateringDeliverySection'
import ReserveDeliverySection from '../components/home/ReserveDeliverySection'
import { DAYS_TO_FETCH_ORDER_DETAILS, findOrdersByDate } from '../components/home/utils/orderUtils'

function withMediaQuery(Component) {
  return function WrappedComponent(props) {
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'))
    return <Component {...props} isDesktop={isDesktop} />
  }
}

export function deliveriesUniqueByRestaurantId(deliveries) {
  const sortedDeliveries = deliveries.sort(delivery => delivery.deliveryId)
  const restaurantIds = new Set()
  const deliveriesUniqueByRestaurantId = []

  for (const delivery of sortedDeliveries) {
    if (restaurantIds.has(delivery.restaurantId)) {
      continue
    }
    deliveriesUniqueByRestaurantId.push(delivery)
    restaurantIds.add(delivery.restaurantId)
  }

  return deliveriesUniqueByRestaurantId
}

export function compareDeliveries(deliveryA, deliveryB) {
  const reserveDeliveryComparison = compareIsReserveDelivery(
    deliveryA.deliveryType,
    deliveryB.deliveryType,
  )
  const pastCutoffComparison = compareIsPastCutoff(deliveryA.isPastCutoff, deliveryB.isPastCutoff)
  const weightLeftComparison = compareWeightLeft(deliveryA.weightLeft, deliveryB.weightLeft)
  const dropoffDateComparison = compareDateStrings(deliveryA.dropoffDate, deliveryB.dropoffDate)
  const cutoffDateComparison = compareDateStrings(deliveryA.cutoffDate, deliveryB.cutoffDate)

  return (
    reserveDeliveryComparison ||
    pastCutoffComparison ||
    weightLeftComparison ||
    dropoffDateComparison ||
    cutoffDateComparison
  )
}

function compareIsReserveDelivery(deliveryTypeA, deliveryTypeB) {
  if (deliveryTypeA === deliveryTypeB || (deliveryTypeA !== 6 && deliveryTypeB !== 6)) {
    return 0
  }

  if (deliveryTypeA !== 6 && deliveryTypeB === 6) {
    return 1
  }

  return -1
}

function compareIsPastCutoff(isPastCutoffA, isPastCutoffB) {
  if (isPastCutoffA === isPastCutoffB) {
    return 0
  }

  if (isPastCutoffA < isPastCutoffB) {
    return -1
  }

  return 1
}

function compareWeightLeft(weightLeftA, weightLeftB) {
  if (weightLeftA === 0) {
    return 1
  }

  if (weightLeftB === 0) {
    return -1
  }

  return 0
}

function compareDateStrings(dateA, dateB) {
  return new Date(dateA) - new Date(dateB)
}
class Home extends Component {
  async componentDidMount() {
    let {
      match: {
        params: { locationId },
      },
    } = this.props
    const { isAuthenticated } = this.props
    locationId = Number(locationId)

    this.fetchDeliveries(locationId)
    this.fetchRewards(locationId)
    this.fetchRewardsTiers()
    this.fetchCateringStores(locationId)
    this.fetchReserveStores(locationId)
    if (isAuthenticated) {
      this.fetchOffices(locationId)
      this.fetchFutureOrders(locationId, DAYS_TO_FETCH_ORDER_DETAILS)
      this.fetchFriends(locationId)
      this.fetchLocationsUserManages()
    }
    pushAnalyticsEvent('Ordering', 'View Deliveries', undefined, locationId)
  }

  componentDidUpdate(prevProps) {
    const { isAuthenticated } = this.props
    let {
      match: {
        params: { locationId = 0 },
      },
    } = this.props
    locationId = Number(locationId)
    if (Number(prevProps.match.params.locationId) !== locationId) {
      this.fetchDeliveries(locationId)
      this.fetchRewards(locationId)
      this.fetchRewardsTiers()
      this.fetchCateringStores(locationId)
      this.fetchReserveStores(locationId)
      this.fetchFutureOrders(locationId, DAYS_TO_FETCH_ORDER_DETAILS)
      this.fetchOffices(locationId)
      this.fetchFriends(locationId)
      this.fetchLocationsUserManages()
    }
    if (prevProps.isAuthenticated === false && isAuthenticated === true) {
      this.fetchFutureOrders(locationId, DAYS_TO_FETCH_ORDER_DETAILS)
      this.fetchFriends(locationId)
      this.fetchLocationsUserManages()
    }
    if (this.props.deliveries && this.props.deliveries !== prevProps.deliveries) {
      this.fetchFavoritesForMenus()
    }
    if (this.props.selectedDate !== prevProps.selectedDate) {
      this.fetchFriends(locationId)
    }
  }

  fetchDeliveries = locationId => {
    const { loadDeliveryScheduleStart, selectedDate } = this.props
    loadDeliveryScheduleStart(locationId, selectedDate)
  }

  fetchRewards = locationId => {
    const { loadDeliveryRewardsStart, selectedDate } = this.props
    loadDeliveryRewardsStart(locationId, selectedDate)
  }

  fetchRewardsTiers = () => {
    const { loadDeliveryRewardsTiersStart } = this.props
    loadDeliveryRewardsTiersStart()
  }

  fetchFriends = locationId => {
    const { getFriendsOrdersStart, selectedDate } = this.props
    getFriendsOrdersStart(locationId, format(subDays(selectedDate, 0), 'YYYY-MM-DD'))
  }

  fetchCateringStores = locationId => {
    const { loadCateringStoresStart } = this.props
    loadCateringStoresStart(locationId)
  }

  fetchReserveStores = locationId => {
    const { loadReserveStoresStart } = this.props
    loadReserveStoresStart(locationId)
  }

  fetchLocationsUserManages = () => {
    const { loadLocationsUserManagesStart } = this.props
    loadLocationsUserManagesStart()
  }

  fetchFavoritesForMenus = async () => {
    const { addFavoritesForMenusStart, deliveries, isAuthenticated } = this.props
    const menuIds = deliveries.map(d => d.menuId)
    if (menuIds.length > 0 && isAuthenticated) {
      const result = await getFavoritesForMenusCount(menuIds)
      addFavoritesForMenusStart(result)
    }
  }

  fetchOffices = locationId => {
    const { loadOfficesStart } = this.props
    loadOfficesStart(locationId)
  }

  fetchFutureOrders = (locationId, numberOfDaysInFuture) => {
    const { loadFutureOrdersStart } = this.props
    loadFutureOrdersStart(format(new Date(), 'YYYYMMDD'), locationId, numberOfDaysInFuture)
  }

  render() {
    const {
      deliveries,
      isLoadingDeliveries,
      isScriptLoadSucceed,
      isScriptLoaded,
      isDesktop,
      cateringStores,
      reserveStores,
      locationsUserManages,
      isLoadingLocationsUserManages,
      activationDate,
      selectDateStart,
      errorLoading,
      isSelectedDayActive,
      selectedDate,
      isLoadingCateringStores,
      errorLoadingCateringStores,
      isLoadingReserveStores,
      errorLoadingReserveStores,
      futureOrders,
      loadingFutureOrders,
      rewards,
      isLoadingRewards,
      offices,
      isLoadingDeliveryRewardsTiers,
      deliveryRewardsTiers,
      locationStatus,
    } = this.props
    let {
      friendsOrdered,
      match: {
        params: { locationId },
      },
    } = this.props
    locationId = Number(locationId)

    const deduplicatedDeliveries = deliveriesUniqueByRestaurantId(deliveries)
    const sortedDeliveries = deduplicatedDeliveries.sort(compareDeliveries)

    const selectedDateOrders = findOrdersByDate(futureOrders, selectedDate)

    return (
      <>
        <LayoutInnerHomePage
          HeaderComponent={
            <>
              <MobileWidgetSkeleton
                isDesktop={isDesktop}
                loading={
                  isLoadingDeliveries || loadingFutureOrders || isLoadingLocationsUserManages
                }
                WidgetComponent={() => (
                  <HomeSidebar
                    selectDateStart={selectDateStart}
                    locationId={locationId}
                    selectedDate={selectedDate}
                    futureOrders={futureOrders}
                    loadingFutureOrders={loadingFutureOrders}
                    offices={offices}
                    showUpcomingOrders={true}
                    locationsUserManages={locationsUserManages}
                    isLoadingLocationsUserManages={isLoadingLocationsUserManages}
                  />
                )}
              />
            </>
          }
          MainComponent={
            <>
              {locationStatus === LocationStatusType.UNQUALIFIED ? (
                <UnqualifiedLocationMessage />
              ) : (
                <DailyDeliverySection
                  deliveries={sortedDeliveries}
                  friendsOrdered={friendsOrdered}
                  locationId={locationId}
                  selectedDate={selectedDate}
                  isSelectedDayActive={isSelectedDayActive}
                  activationDate={activationDate}
                  isLoadingDeliveries={isLoadingDeliveries}
                  errorLoading={errorLoading}
                  rewards={rewards}
                  isLoadingRewards={isLoadingRewards}
                  selectDateStart={selectDateStart}
                  selectedDateOrders={selectedDateOrders}
                  locationStatus={locationStatus}
                />
              )}

              <ReserveDeliverySection
                reserveStores={reserveStores}
                isLoadingReserveStores={isLoadingReserveStores}
                errorLoadingReserveStores={errorLoadingReserveStores}
                locationId={locationId}
                showLimitedStores={true}
                showSeeAll={true}
                deliveryRewardsTiers={deliveryRewardsTiers}
                isLoadingDeliveryRewardsTiers={isLoadingDeliveryRewardsTiers}
              />

              <CateringDeliverySection
                cateringStores={cateringStores}
                locationId={locationId}
                isLoadingCateringStores={isLoadingCateringStores}
                errorLoadingCateringStores={errorLoadingCateringStores}
                showLimitedStores={true}
                showSeeAll={true}
              />

              {!isLoadingDeliveries && !isLoadingDeliveryRewardsTiers && (
                <Box className="center-xs" marginTop={4} marginBottom={4}>
                  <FeedbackLink />
                </Box>
              )}
            </>
          }
          SidebarComponent={
            <HomeSidebar
              selectDateStart={selectDateStart}
              locationId={locationId}
              selectedDate={selectedDate}
              futureOrders={futureOrders}
              loadingFutureOrders={loadingFutureOrders}
              showUpcomingOrders={true}
              locationsUserManages={locationsUserManages}
              isLoadingLocationsUserManages={isLoadingLocationsUserManages}
            />
          }
          RightSidebarComponents={isDesktop ? [() => <HomeUnlockPreferredPricing />] : []}
        />
        <HomeDialogs
          locationId={locationId}
          isScriptLoaded={isScriptLoaded}
          isScriptLoadSucceed={isScriptLoadSucceed}
        />
        <HomeSnackbars />
      </>
    )
  }
}

const mapStateToProps = state => {
  const deliveries = selectDeliveries(state)
  const {
    isLoadingDeliveries,
    selectedDate,
    errorLoading,
    rewards,
    isLoadingRewards,
  } = state.delivery
  const { locationsUserManages, isLoadingLocationsUserManages } = state.locationManager
  const { isLoadingDeliveryRewardsTiers, deliveryRewardsTiers } = state.deliveryDropoffRewards
  const { isAuthenticated } = state.user
  const location = selectCurrentLocationWithAddress(state)
  const locationStatus = selectLocationStatus(state)
  const { futureOrders, loadingFutureOrders } = state.orderDetail
  const offices = selectOfficeAccounts(state)
  const cateringStores = selectCateringStores(state)
  const { reserveStores, isLoadingReserveStores, errorLoadingReserveStores } = state.reserveStores
  const { friendsOrdered } = state.friends
  const { isLoadingCateringStores, errorLoadingCateringStores } = state.cateringStores
  const { location: { activationDate } = {} } = state.location
  const isSelectedDayActive = isSelectedDeliveryDateActive(state)
  const { isWebpSupported } = state.browser

  return {
    deliveries,
    isAuthenticated,
    isLoadingDeliveries,
    friendsOrdered,
    location,
    futureOrders,
    loadingFutureOrders,
    selectedDate,
    offices,
    cateringStores,
    locationsUserManages,
    isLoadingLocationsUserManages,
    reserveStores,
    activationDate,
    errorLoading,
    isSelectedDayActive,
    isWebpSupported,
    isLoadingCateringStores,
    errorLoadingCateringStores,
    isLoadingReserveStores,
    errorLoadingReserveStores,
    rewards,
    isLoadingDeliveryRewardsTiers,
    deliveryRewardsTiers,
    isLoadingRewards,
    locationStatus,
  }
}

const mapDispatchToProps = {
  addFavoritesForMenusStart,
  getFriendsOrdersStart,
  loadDeliveryScheduleStart,
  loadDeliveryRewardsStart,
  loadCateringStoresStart,
  loadReserveStoresStart,
  loadOfficesStart,
  selectDateStart,
  loadFutureOrdersStart,
  loadLocationsUserManagesStart,
  loadDeliveryRewardsTiersStart,
}

export default scriptLoader(MAPS_SCRIPT_URL)(
  connect(mapStateToProps, mapDispatchToProps)(withMediaQuery(Home)),
)
