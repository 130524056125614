import { Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import React from 'react'

export const PaymentMethodDetail = ({ handleBlur, handleChange, values }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.saveCard}
              onChange={e => {
                handleChange(e)
              }}
              name="saveCard"
              color="primary"
              onBlur={handleBlur}
            />
          }
          label="Save card for future use"
        />
      </Grid>
    </Grid>
  )
}
export default PaymentMethodDetail
