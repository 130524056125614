import React from 'react'

const EditableContext = React.createContext(null)

export const useEditable = () => {
  const editableData = React.useContext(EditableContext)
  if (editableData == null) {
    throw Error('DisplayMode and EditMode components must have an Editable component parent')
  }
  return editableData
}

export const DisplayMode = ({ children }) => {
  const { isEditing } = useEditable()
  return isEditing ? <></> : <>{children}</>
}

export const EditMode = ({ children }) => {
  const { isEditing } = useEditable()
  return isEditing ? <>{children}</> : <></>
}

export const Editable = ({ children, editing }) => {
  return (
    <EditableContext.Provider value={{ isEditing: editing }}>{children}</EditableContext.Provider>
  )
}
