import { call, put, take } from 'redux-saga/effects'

import { api } from '../../api/api'
import { apiSaga } from './sagas'

const ACCESS_DENIED_ERROR = {
  text: 'You have cancelled adding the Foodsbot to your Slack.',
  title: '',
}

const FOODSBY_ERROR = {
  text: 'There was an internal problem on our end. Foodsbot has not been added to your Slack.',
  title: 'Please romaine calm 🥬.',
}

export const REGISTER_SLACKBOT = 'foodsby/slackbot/REGISTER_SLACKBOT'
export const REGISTER_SLACKBOT_SUCCESS = 'foodsby/slackbot/REGISTER_SLACKBOT_SUCCESS'
export const REGISTER_SLACKBOT_FAILURE = 'foodsby/slackbot/REGISTER_SLACKBOT_FAILURE'
export const ACKNOWLEDGE_SLACKBOT_REGISTRATION =
  'foodsby/slackbot/ACKNOWLEDGE_SLACKBOT_REGISTRATION'

export const registerSlackbot = (code, state, error) => {
  return {
    payload: { code, error, state },
    type: REGISTER_SLACKBOT,
  }
}

export const registerSlackbotSuccess = () => {
  return {
    type: REGISTER_SLACKBOT_SUCCESS,
  }
}

export const registerSlackbotFailure = error => {
  return {
    payload: { error },
    type: REGISTER_SLACKBOT_FAILURE,
  }
}

export const acknowledgeSlackbotRegistration = () => {
  return {
    type: ACKNOWLEDGE_SLACKBOT_REGISTRATION,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [ACKNOWLEDGE_SLACKBOT_REGISTRATION]: state => {
    return {
      ...state,
      isSlackbotLoading: false,
      slackbotRegistered: undefined,
    }
  },
  [REGISTER_SLACKBOT]: state => {
    return {
      ...state,
      isSlackbotLoading: true,
    }
  },
  [REGISTER_SLACKBOT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.error === 'access_denied' ? ACCESS_DENIED_ERROR : FOODSBY_ERROR,
      isSlackbotLoading: false,
      slackbotRegistered: false,
    }
  },
  [REGISTER_SLACKBOT_SUCCESS]: state => {
    return {
      ...state,
      isSlackbotLoading: false,
      slackbotRegistered: true,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {}

export default function slackbot(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* watchRegisterSlackbot() {
  while (true) {
    const {
      payload: { code, error, state },
    } = yield take(REGISTER_SLACKBOT)
    yield code && state
      ? call(
          apiSaga,
          slackbotRegistration,
          [code, state],
          registerSlackbotSuccess,
          registerSlackbotFailure,
        )
      : put(registerSlackbotFailure(error))
  }
}

// ------------------------------------
// APIs
// ------------------------------------

export const slackbotRegistration = (code, state) =>
  api.post(`/api/v1/foodsbot/slack/auth`, { code, state })
