import { Box, Card, Fab, Modal, Button } from '@material-ui/core'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import { Skeleton } from '@material-ui/lab'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { push } from 'connected-react-router'
import PersonalizeOrder from '../components/personalize-order/PersonalizeOrder'
import {
  Menu,
  MenuPastOrders,
  MenuPopularItems,
  NavRestaurantCategories,
  CateringValidationModal,
  CateringOrderSidebar,
} from '../components/place-order'
import RestaurantDetails from '../components/common/RestaurantDetails'
import {
  cancelEditingOrder,
  loadCateringOrderCartStart,
  loadEditOrder,
  addItemToCateringCartStart,
  editCateringOrderInCart,
  removeItemFromCateringOrderCartStart,
  deleteCateringOrderCartStart,
} from '../redux/modules/cart'
import { loadFavoriteStart, loadPopularMenuItems } from '../redux/modules/favorite'
import { clearMenuItem, loadCateringMenuStart, selectMenuItem } from '../redux/modules/menu'
import { addPastOrderToCateringCart, loadPastCateringOrdersStart } from '../redux/modules/pastorder'
import {
  getMenuInfoForNewPopularItems,
  getMenuInfoForPopularItems,
} from '../redux/modules/selectors'
import { pushAnalyticsEvent } from '../util/gtmUtils'
import AuthedComponent from '../components/common/AuthedComponent'
import LayoutInnerPage, { HeaderInnerPage } from '../layouts/LayoutInnerPage'
import { formatUrl } from '../util/formatUtils'
import { locationRoute, pickCateringDeliveryDateAndTimeRoute } from '../routes/routes'
import { loadOfficesStart } from '../redux/modules/offices'
import { selectOfficeAccounts } from '../redux/selectors/accountSelectors'
import { resetCheckoutError } from '../redux/modules/checkout'
import { loadCateringStoreStart } from '../redux/modules/catering'
import { loadCateringDeliveryDateAndTimeStart } from '../redux/modules/delivery'
import {
  selectFormattedCateringDeliveryDate,
  selectFormattedCateringDeliveryTime,
} from '../redux/selectors/deliverySelectors'

export class PagePlaceCateringOrder extends Component {
  constructor(props) {
    super(props)
    this.props.clearMenuItem()
    this.state = {
      openMobileOrderCart: false,
      isMobile: window.innerWidth <= 960,
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleOpenMobileOrderCart = this.handleOpenMobileOrderCart.bind(this)
    this.handleCloseMobileOrderCart = this.handleCloseMobileOrderCart.bind(this)
  }

  updateWindowDimensions = () => {
    this.setState({ isMobile: window.innerWidth <= 960 })
  }

  handleOpenMobileOrderCart() {
    this.setState({ openMobileOrderCart: true })
  }

  handleCloseMobileOrderCart() {
    this.setState({ openMobileOrderCart: false })
  }

  handleClose() {
    this.props.clearMenuItem()
    this.props.cancelEditingOrder()
  }

  getStoreIdAndLocationId = () => {
    let {
      match: {
        params: { storeId = 0, locationId = 0 },
      },
    } = this.props
    storeId = Number(storeId)
    locationId = Number(locationId)
    return { storeId, locationId }
  }

  handleAddItemsToCart = (menuItemId, itemValues, checkout) => {
    const { addItemToCateringCartStart } = this.props
    const { storeId, locationId } = this.getStoreIdAndLocationId()
    addItemToCateringCartStart(locationId, menuItemId, storeId, itemValues, checkout)
  }

  handleEditOrder = (orderItemId, menuItemId, itemValues, checkout) => {
    const { resetCheckoutError, editCateringOrderInCart } = this.props

    const { storeId, locationId } = this.getStoreIdAndLocationId()

    checkout && resetCheckoutError()

    editCateringOrderInCart(
      locationId,
      menuItemId,
      storeId,
      itemValues,
      orderItemId,
      true,
      checkout,
    )
  }

  handleNext = () => {
    const { storeId, locationId } = this.getStoreIdAndLocationId()
    const { push } = this.props

    push(
      formatUrl(pickCateringDeliveryDateAndTimeRoute.path, {
        locationId: locationId,
        storeId: storeId,
      }),
    )
  }

  handleRemoveItemFromCart = orderItemId => {
    const { storeId, locationId } = this.getStoreIdAndLocationId()
    const { removeItemFromCateringOrderCartStart } = this.props

    removeItemFromCateringOrderCartStart(orderItemId, storeId, locationId)
  }

  handleAddPastOrderToCart = order => {
    const { addPastOrderToCateringCart } = this.props
    const { storeId, locationId } = this.getStoreIdAndLocationId()
    addPastOrderToCateringCart(storeId, locationId, order)
  }

  handleRestartOrder = () => {
    const { storeId, locationId } = this.getStoreIdAndLocationId()
    const { deleteCateringOrderCartStart } = this.props
    deleteCateringOrderCartStart(storeId, locationId)
  }

  componentDidMount() {
    window && window.scrollTo(0, 0)
    const {
      loadFavoriteStart,
      loadCateringMenuStart,
      loadOfficesStart,
      loadCateringOrderCartStart,
      loadPastCateringOrdersStart,
      loadCateringDeliveryDateAndTimeStart,
      loadPopularMenuItems,
      loadCateringStoreStart,
    } = this.props

    const { storeId, locationId } = this.getStoreIdAndLocationId()
    window.addEventListener('resize', this.updateWindowDimensions)

    if (storeId && locationId) {
      loadCateringStoreStart(storeId, locationId)
      loadCateringMenuStart(storeId, locationId)
      loadCateringOrderCartStart(storeId, locationId)
      loadPopularMenuItems()
      loadPastCateringOrdersStart(storeId)
      loadCateringDeliveryDateAndTimeStart(storeId, locationId)
      loadFavoriteStart()
      loadOfficesStart(locationId)
    }
    pushAnalyticsEvent('Catering Ordering', 'View Menu', undefined, storeId)
  }

  componentDidUpdate(prevProps) {
    const {
      isAuthenticated,
      loadCateringStoreStart,
      loadFavoriteStart,
      loadCateringMenuStart,
      loadCateringOrderCartStart,
      loadPastCateringOrdersStart,
    } = this.props

    const { storeId, locationId } = this.getStoreIdAndLocationId()

    if (storeId && locationId) {
      loadCateringDeliveryDateAndTimeStart(storeId, locationId)
    }

    if (
      storeId !==
      Number(
        prevProps.match.params.storeId || locationId !== Number(prevProps.match.params.locationId),
      )
    ) {
      loadCateringStoreStart(storeId, locationId)
      loadCateringMenuStart(storeId, locationId)
      loadCateringOrderCartStart(storeId, locationId)
    }
    if (isAuthenticated !== prevProps.isAuthenticated) {
      loadCateringStoreStart(storeId, locationId)
      loadPastCateringOrdersStart(storeId)
      loadFavoriteStart()
      loadCateringMenuStart(storeId, locationId)
      loadCateringOrderCartStart(storeId, locationId)
    }
  }

  componentWillUnmount() {
    this.props.cancelEditingOrder()
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const {
      isAuthenticated,
      isEditingFromMenu,
      isEditingOrder,
      isEditingPastOrder,
      isMenuLoading,
      isOrderCartLoading,
      isPastOrderLoading,
      isWebpSupported,
      isCateringStoreLoading,
      loadEditOrder,
      menu,
      menuError,
      menuItemForEdit,
      menuItemId,
      isLoadingCateringDeliveryDateTime,
      newPopularItems,
      pastOrderError,
      pastOrders,
      selectMenuItem,
      cateringStore,
      cateringStoreError,
      orderCart,
    } = this.props

    const { locationId } = this.getStoreIdAndLocationId()

    const isLoading =
      isMenuLoading ||
      isCateringStoreLoading ||
      isMenuLoading ||
      isOrderCartLoading ||
      isLoadingCateringDeliveryDateTime

    const { openMobileOrderCart, isMobile } = this.state

    let orderItemCount = orderCart?.orderItems?.length

    return (
      <AuthedComponent isAuthenticated={isAuthenticated} locationId={locationId}>
        <LayoutInnerPage
          HeaderComponent={
            <>
              {isMobile && (
                <>
                  <Fab
                    style={{
                      position: 'fixed',
                      zIndex: 1000,
                      backgroundColor: '#bb2f1c',
                      color: 'white',
                      margin: 0,
                      top: 75,
                      right: 20,
                      bottom: 'auto',
                      left: 'auto',
                    }}
                    onClick={this.handleOpenMobileOrderCart}
                  >
                    <ShoppingCartOutlinedIcon />
                    <span style={{ marginLeft: 5 }}>{orderItemCount}</span>
                  </Fab>
                  <Modal open={openMobileOrderCart} onClose={this.handleCloseMobileOrderCart}>
                    <div style={{ maxHeight: '80vh', paddingTop: '80px' }}>
                      <Button
                        style={{
                          position: 'fixed',
                          color: 'black',
                          margin: 10,
                          right: 10,
                          top: 95,
                          zIndex: 1001,
                        }}
                        onClick={this.handleCloseMobileOrderCart}
                      >
                        <ClearOutlinedIcon />
                      </Button>
                      <CateringOrderSidebar
                        shouldDisplayNextButton={true}
                        nextButtonDisplayText={'Continue to select Date & Time'}
                        handleClose={this.handleClose}
                        handleNext={this.handleNext}
                        handleEditOrder={this.handleEditOrder}
                        handleAddItemsToCart={this.handleAddItemsToCart}
                        handleRemoveItemFromCart={this.handleRemoveItemFromCart}
                        personalizeOrderAddMoreIsPrimary
                        handleNextButtonClick={this.handleNext}
                        showRestaurantLogo={false}
                        personalizeOrderNextButtonIsHidden={true}
                      />
                    </div>
                  </Modal>
                </>
              )}
              <HeaderInnerPage
                showBackButton
                backButtonRoute={formatUrl(locationRoute.path, { locationId })}
              >
                Back
              </HeaderInnerPage>
            </>
          }
          MainComponent={
            <Card style={{ paddingTop: '10px' }}>
              <Box marginBottom={4}>
                <Box marginY={3}>
                  <RestaurantDetails
                    logoLink={cateringStore?.logoUrl}
                    name={cateringStore?.restaurantName}
                    loading={isCateringStoreLoading}
                    isCatering={true}
                    isReserve={false}
                    operationalHoursStart={cateringStore?.operationalHoursStart}
                    operationalHoursEnd={cateringStore?.operationalHoursEnd}
                    operationalDaysOfWeek={cateringStore?.operationalDaysOfWeek}
                    cateringLeadTime={cateringStore?.cateringLeadTime}
                  />
                </Box>
                <NavRestaurantCategories isMenuLoading={isMenuLoading} />
              </Box>
              {isLoading ? (
                <>
                  <LoadingState />
                  <LoadingState />
                </>
              ) : (
                <>
                  {/* Show past orders if you have them */}
                  {pastOrders && pastOrders.length > 0 && (
                    <MenuPastOrders
                      {...{
                        addPastOrderToCart: this.handleAddPastOrderToCart,
                        editPastOrder: (
                          hash,
                          orderItemId,
                          isEditingPastOrder,
                          userId,
                          menuItemId,
                        ) => {
                          loadEditOrder(hash, orderItemId, isEditingPastOrder, userId, menuItemId)
                        },
                        isPastOrderLoading,
                        pastOrderError,
                        pastOrders,
                      }}
                    />
                  )}
                  {/* Show popular items if your building has */}
                  {newPopularItems && newPopularItems.length >= 1 && (
                    <MenuPopularItems
                      onSelectMenuItem={selectMenuItem}
                      popularItems={newPopularItems}
                    />
                  )}
                  <Menu
                    isWebpSupported={isWebpSupported}
                    menuData={menu}
                    onSelectMenuItem={selectMenuItem}
                  />
                </>
              )}
            </Card>
          }
          SidebarComponent={
            !isMobile && (
              <CateringOrderSidebar
                shouldDisplayNextButton={true}
                nextButtonDisplayText={'Continue to select Date & Time'}
                handleClose={this.handleClose}
                handleNext={this.handleNext}
                handleEditOrder={this.handleEditOrder}
                handleAddItemsToCart={this.handleAddItemsToCart}
                handleRemoveItemFromCart={this.handleRemoveItemFromCart}
                personalizeOrderAddMoreIsPrimary
                handleNextButtonClick={this.handleNext}
                showRestaurantLogo={false}
                personalizeOrderNextButtonIsHidden={true}
              />
            )
          }
        />
        {/* Global Page Components */}
        {menuItemId && !isEditingPastOrder && !isEditingOrder && (
          <PersonalizeOrder
            handleClose={this.handleClose}
            handleAddItemsToCart={this.handleAddItemsToCart}
            handleEditOrder={this.handleEditOrder}
            menuId={menu && menu.menuId}
            menuItemForEdit={menuItemForEdit}
            menuItemId={menuItemId}
            nextButtonDisplayText={'Continue to select Date & Time'}
            addMoreIsPrimary
            handleNextButtonClick={this.handleNext}
            nextButtonIsHidden={true}
            enableQuantity
            addItemDisplayText={'Add to cart'}
          />
        )}
        {isEditingPastOrder && menuItemForEdit && isEditingOrder && (
          <PersonalizeOrder
            handleClose={this.handleClose}
            handleAddItemsToCart={this.handleAddItemsToCart}
            handleEditOrder={this.handleEditOrder}
            isEditingFromMenu={isEditingFromMenu}
            isEditingOrder={isEditingOrder}
            menuId={menu.menuId}
            menuItemForEdit={menuItemForEdit}
            menuItemId={menuItemForEdit.menuItemId}
            nextButtonDisplayText={'Continue to select Date & Time'}
            addMoreIsPrimary
            handleNextButtonClick={this.handleNext}
            nextButtonIsHidden={true}
            enableQuantity
            addItemDisplayText={'Add to cart'}
          />
        )}
        {!isLoading && (menuError || cateringStoreError) && (
          <CateringValidationModal locationId={locationId} />
        )}
      </AuthedComponent>
    )
  }
}

// should these live in the Page components or in the child?
const LoadingState = () => {
  return (
    <Box>
      <Box display="flex">
        <Skeleton height={80} width={250} />
      </Box>
      <Skeleton height={3} />
      <Box display="flex" justifyContent="space-between">
        <Skeleton height={50} width={150} />
        <Skeleton height={40} width={120} />
      </Box>
      <Skeleton />
      <Skeleton />
      <Skeleton width={200} />
      <Skeleton height={3} />
      <Box display="flex" justifyContent="space-between">
        <Skeleton height={50} width={150} />
        <Skeleton height={40} width={120} />
      </Box>
      <Skeleton />
      <Skeleton />
      <Skeleton width={200} />
      <Skeleton height={3} />
      <Box display="flex" justifyContent="space-between">
        <Skeleton height={50} width={150} />
        <Skeleton height={40} width={120} />
      </Box>
      <Skeleton />
      <Skeleton />
      <Skeleton width={200} />
      <Skeleton height={3} />
      <Box display="flex" justifyContent="space-between">
        <Skeleton height={50} width={150} />
        <Skeleton height={40} width={120} />
      </Box>
      <Skeleton />
      <Skeleton />
      <Skeleton width={200} />
    </Box>
  )
}

const mapStateToProps = state => {
  const { isMenuLoading, isOrderCartLoading, menu, menuItemId, error: menuError } = state.menu
  const { errorMessage: pastOrderError, isPastOrderLoading, pastOrders } = state.pastorder
  const {
    isEditingFromMenu,
    isEditingOrder,
    isEditingPastOrder,
    menuItemForEdit,
    isCartSaving,
    orderCart,
  } = state.cart
  const { isLoadingFavorites, isLoadingNewPopularMenuItems, isLoadingPopularItems } = state.favorite
  const { isCateringStoreLoading, cateringStore, error: cateringStoreError } = state.catering
  const { currentUser, isAuthenticated, isCurrentUserLoading } = state.user
  const popularItems = getMenuInfoForPopularItems(state)
  const newPopularItems = getMenuInfoForNewPopularItems(state)
  const deliveryError = state.delivery.errorLoading
  const { isWebpSupported } = state.browser
  const offices = selectOfficeAccounts(state)
  const cateringDeliveryDate = selectFormattedCateringDeliveryDate(state)
  const cateringDeliveryTime = selectFormattedCateringDeliveryTime(state)
  const isLoadingCateringDeliveryDateTime = state.delivery.isLoadingCateringDeliveryDateTime

  return {
    currentUser,
    deliveryError,
    isAuthenticated,
    isCurrentUserLoading,
    isEditingFromMenu,
    isEditingOrder,
    isEditingPastOrder,
    isLoadingFavorites,
    isLoadingNewPopularMenuItems,
    isLoadingPopularItems,
    isMenuLoading,
    isOrderCartLoading,
    isPastOrderLoading,
    isWebpSupported,
    isCateringStoreLoading,
    cateringStore,
    cateringStoreError,
    menu,
    menuError,
    menuItemForEdit,
    isCartSaving,
    menuItemId,
    cateringDeliveryDate,
    cateringDeliveryTime,
    isLoadingCateringDeliveryDateTime,
    newPopularItems,
    pastOrderError,
    pastOrders,
    popularItems,
    offices,
    orderCart,
  }
}

const mapDispatchToProps = {
  addPastOrderToCateringCart,
  addItemToCateringCartStart,
  cancelEditingOrder,
  clearMenuItem,
  editCateringOrderInCart,
  loadEditOrder,
  loadFavoriteStart,
  loadCateringMenuStart,
  loadCateringOrderCartStart,
  loadPastCateringOrdersStart,
  loadPopularMenuItems,
  loadCateringStoreStart,
  selectMenuItem,
  loadOfficesStart,
  removeItemFromCateringOrderCartStart,
  deleteCateringOrderCartStart,
  loadCateringDeliveryDateAndTimeStart,
  push,
  resetCheckoutError,
}

export default connect(mapStateToProps, mapDispatchToProps)(PagePlaceCateringOrder)
