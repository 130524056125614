import { themeConWeb } from '@app/util/modernThemeConweb'
import { Box, Button, Typography } from '@material-ui/core'
import cn from 'classnames'
import currency from 'currency.js'
import { format, parse } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { confirmationRoute } from '../../routes/routes'
import { formatUrl } from '../../util/formatUtils'
import { orderStateText } from '../home/utils/orderUtils'
import Action from '../common/Action'
import LocationPopup from '../location-search/LocationPopup'
import LocationSearchContainer from '../location-search/LocationSearchContainer'
import LinkPopup from './LinkPopup'

export const LocationBuilding = ({
  address,
  clearError,
  completeAccountToken,
  currentUser,
  hideBuildingIcon,
  multipleLocations,
  name,
  redirectOverride,
  showError,
  showLink,
  style,
}) => (
  <div className="location-building" style={style}>
    {!hideBuildingIcon && (
      <span className="location-building_icon">
        <i className="icon-office secondary" style={{ color: themeConWeb.color.greyShade3 }} />
      </span>
    )}
    <div className="location-building_address" typeof="schema:PostalAddress">
      <div>
        <span className="location-building_address-name">
          {name}{' '}
          {showLink &&
          currentUser &&
          currentUser.originalLocationId &&
          currentUser.originalLocationId !== currentUser.deliveryLocationId ? (
            <LocationPopup
              deliveryAddress={address}
              linkText="Change"
              locationName={name}
              showError={showError}
              styles={{ fontSize: '12px' }}
            />
          ) : (
            showLink && (
              <LinkPopup clearError={clearError} linkText="Change" styles={{ fontSize: '12px' }}>
                <LocationSearchContainer
                  completeAccountToken={completeAccountToken}
                  deliveryAddress={address}
                  locationName={name}
                  multipleLocations={multipleLocations}
                  redirectOverride={redirectOverride}
                  showError={showError}
                  styles={{ paddingLeft: '0' }}
                />
              </LinkPopup>
            )
          )}
        </span>
      </div>
      {address && address.street && (
        <div className="location-building_address-street">
          <span>{address.street}</span>
        </div>
      )}
      {address && address.city && (
        <div className="location-building_address-citystatezip">
          <span>{address.city}</span>, <abbr>{address.state}</abbr> <span>{address.zipcode}</span>
        </div>
      )}
    </div>
  </div>
)

LocationBuilding.defaultProps = {
  address: {},
}

export const LocationPickup = ({ pickupInstruction, style }) => (
  <div className="location-pickup" style={style}>
    <span className="location-pickup_icon">
      <i className="icon-location secondary" style={{ color: themeConWeb.color.greyShade3 }} />
    </span>
    <span className="location-pickup_description">
      {pickupInstruction !== null && pickupInstruction !== 'undefined'
        ? `${pickupInstruction}`
        : 'Pickup Instructions Coming Soon!'}
    </span>
  </div>
)

export const LocationTime = ({ dateTime, deliveryTimeDisplay, multiLine = true, style }) => (
  <div className="location-time" style={style}>
    <span className="location-time_icon" style={{ fontSize: '32px' }}>
      <i className="icon-clock secondary" style={{ color: themeConWeb.color.greyShade3 }} />
    </span>
    <div className="location-time_datetime">
      Delivery Time:
      {multiLine ? <br /> : ' '}
      <strong className="location-time_time">
        {deliveryTimeDisplay ?? format(parse(dateTime), 'h:mm A')}
      </strong>
      <br />
      <span className="location-time_date">{format(parse(dateTime), 'dddd, MM/DD/YY')}</span>
    </div>
  </div>
)

export const LocationCredits = ({ creditsEarned, style, ...rest }) => (
  <div className="location-credits" style={style} {...rest}>
    <span className="location-credits_icon" style={{ color: '#00a5b3' }}>
      $
    </span>
    <span className="location-credits_label">
      Credits Earned
      <br />
      <strong>{currency(creditsEarned, { formatWithSymbol: true }).format()}</strong>
    </span>
  </div>
)

const logoBaseOptions = '?height=200&width=200&fit=contain&background=white'
export const LocationLogo = ({
  className,
  logoAlt,
  logoLink = '',
  logoOptions = logoBaseOptions,
}) => {
  const logoBase = logoLink.replace('foodsby.com/', 'foodsby.com/api/resize/')
  return (
    <Box display="flex" justifyContent="center">
      {logoLink == null || logoLink.indexOf('foodsby.com/logos') === -1 ? (
        <img alt={logoAlt} src={logoLink} />
      ) : (
        <picture>
          <source
            alt={logoAlt}
            srcSet={encodeURI(`${logoBase}.webp${logoOptions}`)}
            type="image/webp"
          />
          <source
            alt={logoAlt}
            srcSet={encodeURI(`${logoBase}.jpg${logoOptions}`)}
            type="image/jpeg"
          />
          <img
            alt={logoAlt}
            className={cn('location-logo', className)}
            src={`${logoBase}.jpg${logoOptions}`}
          />
        </picture>
      )}
    </Box>
  )
}

export const LocationTitle = ({ title }) => (
  <div className="location-info">
    <h1 className="location-info_title">{title}</h1>
  </div>
)

const OrderStatusText = ({ statusText = '', order = {} }) =>
  statusText?.includes(orderStateText.CREATED) || statusText?.includes(orderStateText.ON_TIME) ? (
    <div className="-onTime">
      <strong>On time</strong> for{' '}
      <Typography display="inline" variant="h6">
        {order.expectedDeliveryTimeDisplay ?? format(parse(order.orderDateTime), 'h:mm A')}
      </Typography>
    </div>
  ) : statusText?.includes(orderStateText.DELIVERED) ? (
    <div className="-delivered">
      <span>Delivered</span> at{' '}
      <Typography display="inline" variant="h6">
        {format(parse(order.deliveredDateTime), 'h:mm A')}
      </Typography>
    </div>
  ) : statusText?.includes(orderStateText.CANCELED) ? (
    <div className="-canceled">Canceled</div>
  ) : statusText?.includes(orderStateText.LATE) ? (
    <div className="-late">
      Delivery is running late. Now estimated at{' '}
      <Typography display="inline" variant="h6">
        {order.expectedDeliveryTimeDisplay ?? format(parse(order.orderDateTime), 'h:mm A')}
      </Typography>
    </div>
  ) : null

// TODO: Replace with new designs in confirmation / order history page when able.
export const LocationOrderStatusOld = ({
  extraClasses,
  isConfirmation,
  locationId,
  locationStatusClasses,
  order,
  showManage,
  statusText,
}) => (
  <div className="location-order-status">
    {statusText && (
      <>
        <div className={cn('location-status', locationStatusClasses)}>
          <span className="location-status_icon">
            {statusText.indexOf(orderStateText.LATE) > -1 ? (
              <i className="icon-warning warning" />
            ) : statusText.indexOf(orderStateText.CANCELED) > -1 ? (
              <i className="icon-times-circle error" />
            ) : (
              <i className="icon-check-circle successIcon" />
            )}
          </span>
          {order && order.orderState ? (
            <span className={cn('location-status_text', extraClasses)}>
              Status: <strong>{orderStateText[order.orderState]}</strong>{' '}
              {showManage && locationId && (
                <Link
                  to={{
                    pathname: formatUrl(confirmationRoute.path, {
                      locationId: locationId,
                      orderId: order.orderId,
                    }),
                  }}
                >
                  <Action>Manage</Action>
                </Link>
              )}
              <br />
              {order.orderState === 'DELIVERED' ? (
                <span>
                  Time Delivered:{' '}
                  <strong>{format(parse(order.deliveredDateTime), 'h:mm A')}</strong>
                </span>
              ) : order.orderState === 'CANCELED' ? null : (
                <span>
                  Estimated Delivery:{' '}
                  <strong>
                    {order.expectedDeliveryTimeDisplay ??
                      format(parse(order.orderDateTime), 'h:mm A')}
                  </strong>
                </span>
              )}
            </span>
          ) : (
            <>
              <span className={cn('location-status_text', extraClasses)}>{statusText}</span>
              {isConfirmation && (
                <div className="row middle-xs status-message">
                  <div className="col-xs-12 center-xs">
                    {statusText.indexOf(orderStateText.CANCELED) > -1 ? (
                      <p>An order cancellation confirmation has been sent to your email</p>
                    ) : (
                      <p>You will receive a notification when your food arrives.</p>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    )}
  </div>
)

export const LocationOrderStatus = ({ locationId, order, statusText }) => {
  const cancelledOrderCount = useSelector(
    state =>
      state?.orderDetail?.orders?.filter(
        d =>
          d.deliveryDropoffId === order.deliveryDropoffId &&
          d.statusText?.includes(orderStateText.CANCELED),
      ).length,
  )
  const isDelivered = statusText?.includes(orderStateText.DELIVERED)
  const isCanceled = statusText?.includes(orderStateText.CANCELED)
  return (
    <LocationOrderStatusComponent>
      <Typography variant="h5">{order.restaurantName}</Typography>
      <OrderStatusText order={order} statusText={statusText} />
      {cancelledOrderCount > 0 && !statusText?.includes(orderStateText.CANCELED) && (
        <div className="-otherOrders">
          <hr />
          {cancelledOrderCount} other order{cancelledOrderCount > 1 && 's'} canceled
        </div>
      )}
      <Box marginTop={2}>
        <Button
          color={isDelivered || isCanceled ? 'default' : 'primary'}
          component={Link}
          to={formatUrl(confirmationRoute.path, {
            locationId: locationId,
            orderId: order.orderId,
          })}
          variant={isDelivered || isCanceled ? 'outlined' : 'contained'}
        >
          {isDelivered || isCanceled ? 'Order details' : 'Manage'}
        </Button>
      </Box>
    </LocationOrderStatusComponent>
  )
}

const LocationOrderStatusComponent = styled.div`
  display: contents;

  & .-onTime {
    & strong {
      font-family: ${themeConWeb.fonts.primary};
      color: ${themeConWeb.color.success};
    }
  }

  & .-delivered {
    & span {
      color: ${themeConWeb.color.success};
      font-weight: bold;
    }
  }

  & .-canceled {
    margin: 6px 0;
    color: ${themeConWeb.color.dangerLight};
    font-weight: bold;
  }

  & .-late {
    & span {
      font-weight: bold;
    }
  }

  & .successIcon {
    font-size: 16px;
    margin-right: 4px;
  }

  & .-otherOrders {
    color: ${themeConWeb.color.greyShade4};
    font-size: 14px;

    & hr {
      margin-top: calc(${themeConWeb.baseUnit} * 4);
      max-width: 50%;
      margin-left: inherit;
    }
  }

  // responsive styles
  @media screen and ${themeConWeb.small} {
    & .-title {
      font-size: 18px;
      margin: 0 calc(${themeConWeb.baseUnit} * 2) 0 0;
    }
  }
`

export const LocationContainer = ({ children, style }) => (
  <div className="location-content" style={style}>
    {children}
  </div>
)

export const Location = ({ children, style }) => (
  <div className="location" style={style}>
    {children}
  </div>
)

export default Location
