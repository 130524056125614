import { Button, Typography } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React from 'react'
import { Link } from 'react-router-dom'
import CardTeam, { CardTeamContent, CardTeamTitle } from '../common/CardTeam'
import { formatUrl } from '../../../util/formatUtils'
import { teamMembersRoute, teamPaymentsRoute, teamPerksRoute } from '../../../routes/routes'

const actions = [
  {
    title: 'Add a Perk',
    link: teamPerksRoute.path,
  },
  {
    title: 'Add Member',
    link: teamMembersRoute.path,
  },
  {
    title: 'View/Pay Invoice',
    link: teamPaymentsRoute.path,
  },
  {
    title: 'Add payment method',
    link: teamPaymentsRoute.path,
  },
]

const CardCommonActions = ({ accountId }) => {
  const formatPath = path => formatUrl(path, { accountId })
  return (
    <CardTeam>
      <CardTeamTitle>Common Actions</CardTeamTitle>
      {actions.map(action => (
        <CardTeamContent key={action.title}>
          <Button
            component={Link}
            to={formatPath(action.link)}
            startIcon={<InfoOutlinedIcon />}
            style={{ textTransform: 'none' }}
          >
            <Typography>{action.title}</Typography>
          </Button>
        </CardTeamContent>
      ))}
    </CardTeam>
  )
}

export default CardCommonActions
