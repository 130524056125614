import LRUCache from 'lru-cache'
import { api } from '../api/api'

const ACCOUNTS_V2 = '/api/v2/accounts'

export const cache = new LRUCache({ maxAge: 90000 }) // 90 seconds

export const getOfficesListApi = locationId =>
  api.get(`${ACCOUNTS_V2}/offices?locationId=${locationId}`)

export const getAllPreferredOfficesForWhichUserIsAnAdmin = () =>
  api.get(`${ACCOUNTS_V2}/offices/preferred`)

export const getEmailDomains = accountId =>
  api.get(`${ACCOUNTS_V2}/offices/${accountId}/email-domains`)
