import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { format, parse } from 'date-fns'
import { themeConWeb } from '@app/util/modernThemeConweb'

export const DateAndRestaurant = ({ dateTime, restaurant, orderId }) => {
  const formattedDate = format(parse(dateTime), 'dddd, MMMM D, YYYY')

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography style={{ marginBottom: '5px' }}>Order Confirmation #{orderId}</Typography>
      <Typography
        variant="h3"
        style={{ fontSize: themeConWeb.fontSizes.basePlus3, fontWeight: 500 }}
      >
        {restaurant}
      </Typography>
      <Typography
        variant="overline"
        style={{ textTransform: 'uppercase', color: themeConWeb.color.greyShade3 }}
      >
        {formattedDate}
      </Typography>
    </Box>
  )
}
